const data = {
    type: 'Topology',
    arcs: [
        [
            [4031, 1809],
            [-1, 25],
            [17, 8],
            [26, -20],
            [52, 103],
            [27, 2],
        ],
        [
            [4152, 1927],
            [1, -25],
            [27, -1],
            [-2, -46],
            [-24, -74],
            [13, -26],
            [-15, -62],
            [9, -16],
            [-17, -86],
            [-28, -46],
            [-26, -5],
            [-29, -59],
        ],
        [
            [4061, 1481],
            [-43, 0],
            [12, 193],
            [1, 135],
        ],
        [
            [4921, 172],
            [-79, 38],
            [-29, 35],
            [17, 30],
            [-5, 38],
            [-40, 41],
            [-57, 33],
            [-50, 22],
            [-9, 50],
            [-38, 31],
            [9, -50],
            [-28, -41],
            [-33, 48],
            [-47, 17],
            [-19, 34],
            [1, 52],
            [19, 54],
            [-41, 24],
            [33, 33],
        ],
        [
            [4525, 661],
            [22, 22],
            [95, -45],
            [33, 22],
            [45, -14],
            [24, -36],
            [43, -11],
            [34, 36],
        ],
        [
            [4821, 635],
            [37, -93],
            [55, -68],
            [68, -73],
        ],
        [
            [4981, 401],
            [-56, -15],
            [1, -69],
            [30, -26],
            [-21, -20],
            [5, -31],
            [-12, -34],
            [-7, -34],
        ],
        [
            [4225, 1053],
            [-16, -40],
            [-83, 2],
            [-52, 17],
            [-60, 34],
            [-80, 10],
            [-40, 37],
        ],
        [
            [3894, 1113],
            [4, 25],
            [49, 43],
            [28, 19],
            [-8, 20],
            [34, 11],
        ],
        [
            [4001, 1231],
            [42, -15],
            [31, -34],
            [44, -27],
            [5, -23],
            [63, 20],
            [30, -12],
            [19, -18],
            [-10, -69],
        ],
        [
            [3894, 1113],
            [-74, 30],
            [-90, 3],
            [-66, 34],
            [-77, 70],
        ],
        [
            [3587, 1250],
            [3, 50],
            [17, 40],
            [-21, 32],
            [70, 139],
            [185, 1],
            [3, 58],
            [-23, 11],
            [-16, 37],
            [-53, 39],
            [-54, 57],
            [65, 0],
            [0, 97],
            [135, 0],
            [133, -2],
        ],
        [
            [4061, 1481],
            [47, -31],
            [13, 25],
            [42, -21],
        ],
        [
            [4163, 1454],
            [-66, -66],
            [-68, -47],
            [-10, -33],
            [12, -34],
            [-30, -43],
        ],
        [
            [4294, 985],
            [-25, 53],
            [-44, 15],
        ],
        [
            [4163, 1454],
            [15, -7],
            [32, 30],
            [41, 2],
            [13, -14],
            [23, 9],
            [66, -16],
            [67, 5],
            [46, 19],
            [17, 19],
            [46, -9],
            [35, -11],
            [37, 4],
            [29, 14],
            [66, -23],
            [23, -4],
            [44, -32],
            [41, -38],
            [53, -27],
            [38, -47],
        ],
        [
            [4895, 1328],
            [-50, 4],
            [-20, -23],
            [-50, -23],
            [-36, 0],
            [-32, -21],
            [-29, 7],
            [-24, 26],
            [-15, -5],
            [-19, -40],
            [-14, 1],
            [-2, -35],
            [-50, -47],
            [-27, -20],
            [-15, -22],
            [-42, 35],
            [-31, -46],
            [-30, 1],
            [-34, -4],
            [3, -84],
            [-21, -1],
            [-18, -39],
            [-45, -7],
        ],
        [
            [4525, 661],
            [-50, 54],
            [-67, 69],
            [-32, 57],
            [-61, 54],
            [-72, 77],
            [16, 27],
            [24, -26],
            [11, 12],
        ],
        [
            [4895, 1328],
            [-13, -16],
            [-7, -38],
            [14, -63],
            [-33, -58],
            [-15, -69],
            [-5, -75],
            [8, -44],
            [4, -77],
            [-22, -17],
            [-14, -73],
            [10, -45],
            [-30, -43],
            [7, -47],
            [22, -28],
        ],
        [
            [4981, 401],
            [52, -62],
            [-3, -36],
            [57, -7],
            [14, 13],
            [40, -41],
            [70, 12],
            [62, 43],
            [87, 34],
            [49, 52],
            [80, -10],
            [-6, -17],
            [81, -6],
            [64, -30],
            [47, -51],
            [54, -47],
            [-17, -25],
            [33, -101],
            [-27, -51],
            [-47, 13],
            [-18, -83],
            [-48, 49],
            [-31, 92],
            [36, 46],
            [-37, 11],
            [-27, 57],
            [-71, 47],
            [-63, -11],
            [-30, -59],
            [-58, -43],
            [-31, -6],
            [-14, -35],
            [68, -93],
            [-39, -22],
            [-21, -25],
            [-67, -9],
            [-25, 102],
            [-19, -29],
            [-47, 10],
            [-29, 69],
            [-59, 11],
            [-38, 20],
            [-61, 0],
            [-5, -37],
            [-16, 26],
        ],
        [
            [3587, 1250],
            [-163, 184],
            [-75, 56],
            [-117, 44],
            [-81, -12],
            [-115, -64],
            [-73, -17],
            [-102, 45],
            [-108, 32],
            [-134, 79],
            [-108, 24],
            [-163, 79],
            [-121, 82],
            [-36, 45],
            [-81, 11],
            [-147, 54],
            [-60, 78],
            [-155, 97],
            [-72, 107],
            [-34, 84],
            [48, 16],
            [-15, 49],
            [33, 44],
            [1, 59],
            [-49, 77],
            [-13, 68],
            [-48, 86],
            [-127, 170],
            [-145, 134],
            [-70, 106],
            [-124, 70],
            [-26, 42],
            [22, 105],
            [-74, 40],
            [-85, 83],
            [-36, 119],
            [-77, 14],
            [-84, 90],
            [-67, 83],
            [-7, 54],
            [-77, 129],
            [-51, 131],
            [2, 65],
            [-104, 68],
            [-48, -7],
            [-82, 47],
            [-23, -70],
            [23, -82],
            [14, -128],
            [50, -71],
            [107, -117],
            [23, -40],
            [22, -13],
            [19, -58],
            [26, 2],
            [29, -110],
            [44, -44],
            [30, -60],
            [91, -87],
            [48, -159],
            [42, -75],
            [40, -80],
            [8, -90],
            [70, -6],
            [58, -77],
            [52, -77],
            [-4, -30],
            [-60, -63],
            [-26, 1],
            [-38, 104],
            [-94, 97],
            [-104, 83],
            [-74, 43],
            [5, 125],
            [-22, 93],
            [-68, 53],
            [-99, 76],
            [-19, -22],
            [-37, 44],
            [-89, 42],
            [-84, 99],
            [10, 13],
            [59, -10],
            [54, 64],
            [5, 77],
            [-111, 122],
            [-84, 47],
            [-53, 107],
            [-54, 112],
            [-66, 137],
            [-59, 153],
            [164, 13],
            [183, 19],
            [-14, -33],
            [218, -83],
            [328, -121],
            [287, 1],
            [114, 0],
            [0, 71],
            [250, 0],
            [52, -61],
            [74, -54],
            [85, -75],
            [48, -89],
            [36, -94],
            [75, -52],
            [119, -51],
            [91, 135],
            [118, 3],
            [101, -68],
            [72, -117],
            [50, -100],
            [85, -97],
            [32, -120],
            [40, -80],
            [113, -53],
            [102, -38],
            [56, 5],
            [-56, -150],
            [-25, -123],
            [-11, -228],
            [-13, -84],
            [24, -93],
            [45, -83],
            [29, -133],
            [96, -127],
            [33, -98],
            [56, -84],
            [153, -45],
            [60, -72],
            [126, 48],
            [110, 17],
            [108, 31],
            [91, 29],
            [91, 70],
            [35, 100],
            [11, 143],
            [25, 50],
            [98, 45],
            [152, 40],
            [128, -6],
            [87, 14],
            [35, -36],
            [-5, -82],
            [-78, -102],
            [-34, -104],
            [27, -30],
            [-22, -73],
            [-36, -134],
            [-37, 44],
            [-30, -3],
        ],
    ],
    transform: {
        scale: [0.006942592515230634, 0.005852717236630708],
        translate: [-117.12776000000001, 7.220541],
    },
    objects: {
        countries: {
            type: 'GeometryCollection',
            geometries: [
                {
                    arcs: [[0, 1, 2]],
                    type: 'Polygon',
                    properties: { name: 'Belize', country: 'BZ' },
                },
                {
                    arcs: [[3, 4, 5, 6]],
                    type: 'Polygon',
                    properties: { name: 'Costa Rica', country: 'CR' },
                },
                {
                    arcs: [[7, 8, 9]],
                    type: 'Polygon',
                    properties: { name: 'El Salvador', country: 'SV' },
                },
                {
                    arcs: [[10, 11, -3, 12, 13, -9]],
                    type: 'Polygon',
                    properties: { name: 'Guatemala', country: 'GT' },
                },
                {
                    arcs: [[14, -10, -14, 15, 16]],
                    type: 'Polygon',
                    properties: { name: 'Honduras', country: 'HN' },
                },
                {
                    arcs: [[17, -17, 18, -5]],
                    type: 'Polygon',
                    properties: { name: 'Nicaragua', country: 'NI' },
                },
                {
                    arcs: [[-7, 19]],
                    type: 'Polygon',
                    properties: { name: 'Panama', country: 'PA' },
                },
                {
                    arcs: [[-1, -12, 20]],
                    type: 'Polygon',
                    properties: { name: 'Mexico', country: 'MX' },
                },
            ],
        },
    },
};

export default data;
