const data = {
    type: 'Topology',
    arcs: [
        [
            [5421, 7381],
            [-24, 45],
            [-1, 178],
            [-108, 78],
            [-92, 57],
        ],
        [
            [5196, 7739],
            [41, 30],
            [77, -61],
            [90, 6],
            [74, -28],
            [66, 51],
            [34, 85],
            [107, 39],
            [89, -46],
            [-29, -81],
        ],
        [
            [5745, 7734],
            [-10, -81],
            [106, -77],
            [-64, -87],
            [80, -131],
            [-46, -99],
            [62, -86],
            [-28, -75],
            [102, -79],
            [-26, -59],
            [-64, -67],
            [-148, -147],
            [-126, -9],
            [-121, -43],
            [-113, -24],
            [-40, 63],
            [-67, 38],
            [16, 114],
            [-34, 104],
            [33, 67],
            [63, 72],
            [158, 126],
            [46, 24],
            [-7, 49],
            [-96, 54],
        ],
        [
            [136, 52],
            [-26, -44],
            [-34, -8],
            [-9, 32],
            [-15, 5],
            [-22, -31],
            [-30, 24],
            [18, 49],
            [6, 53],
            [11, 49],
            [-26, 68],
            [-6, 79],
            [36, 99],
            [24, -12],
            [51, -28],
            [73, -97],
            [11, -47],
            [-41, -106],
            [-21, -85],
        ],
        [
            [4429, 4628],
            [-22, -89],
            [-32, 23],
            [-16, 78],
            [14, 43],
            [45, 44],
            [11, -99],
        ],
        [
            [4294, 5341],
            [-9, -82],
        ],
        [
            [4285, 5259],
            [-19, -20],
            [-32, 11],
            [3, -29],
            [-51, -65],
            [-1, -52],
            [33, 18],
            [24, -51],
        ],
        [
            [4242, 5071],
            [-3, -33],
            [20, -43],
            [-24, -35],
            [18, -89],
            [37, -15],
            [-7, -50],
        ],
        [
            [4283, 4806],
            [-63, -65],
            [-136, 31],
            [-101, -37],
            [-8, -70],
        ],
        [
            [3975, 4665],
            [-80, -15],
            [-78, 52],
            [-25, -25],
            [-127, 53],
            [-28, 45],
        ],
        [
            [3637, 4775],
            [36, 69],
            [13, 230],
            [-71, 121],
            [-51, 58],
            [-106, 45],
            [-7, 84],
            [90, 25],
            [116, -30],
            [-22, 131],
            [65, -50],
            [161, 90],
            [21, 95],
            [60, 23],
        ],
        [
            [3942, 5666],
            [10, -40],
            [33, -2],
            [32, -47],
            [48, -54],
            [35, 9],
            [61, -53],
        ],
        [
            [4161, 5479],
            [15, -10],
            [20, 3],
        ],
        [
            [4196, 5472],
            [33, -30],
            [99, -22],
            [-34, -79],
        ],
        [
            [3942, 5666],
            [56, 23],
            [0, 0],
        ],
        [
            [3998, 5689],
            [0, 0],
            [50, -9],
            [64, 24],
            [44, -51],
            [38, -27],
        ],
        [
            [4194, 5626],
            [-8, -77],
        ],
        [
            [4186, 5549],
            [-18, -5],
            [-7, -65],
        ],
        [
            [4646, 6181],
            [-42, -93],
            [-72, 65],
            [-10, 48],
            [102, 38],
            [22, -58],
        ],
        [
            [4454, 6109],
            [-44, -18],
            [-52, 15],
        ],
        [
            [4358, 6106],
            [-28, 65],
            [-2, 118],
            [11, 31],
            [20, 34],
            [61, 7],
            [24, 32],
            [56, 33],
            [-2, -59],
            [-21, -38],
            [8, -32],
            [38, -18],
            [-17, -43],
            [-21, 12],
            [-49, -83],
            [18, -56],
        ],
        [
            [4490, 5175],
            [-35, 3],
            [-31, 21],
            [10, 29],
            [-2, 20],
        ],
        [
            [4432, 5248],
            [21, 6],
            [35, -32],
            [9, 31],
            [61, -5],
            [50, 21],
            [33, -4],
            [22, -24],
            [6, 20],
            [-10, 75],
            [25, 15],
            [24, 53],
        ],
        [
            [4708, 5404],
            [52, -37],
            [39, 47],
            [24, 9],
            [54, -35],
            [32, 6],
            [32, -22],
            [-6, -15],
            [7, -40],
            [-5, -47],
            [-39, 0],
            [13, -25],
            [-22, -75],
            [-14, -19],
            [-60, -3],
            [-35, -26],
            [-57, 9],
        ],
        [
            [4723, 5131],
            [-99, 30],
            [-15, 40],
            [-69, -20],
            [-8, -22],
            [-42, 16],
        ],
        [
            [3975, 4665],
            [4, -67],
            [-66, -77],
            [-88, -24],
            [-6, -39],
            [-43, -64],
            [-27, -94],
            [27, -66],
            [-40, -52],
            [-15, -75],
            [-52, -23],
            [-49, -88],
            [-87, -2],
            [-66, 2],
            [-44, -41],
            [-26, -43],
            [-34, 9],
            [-25, 39],
            [-20, 67],
            [-65, 18],
        ],
        [
            [3253, 4045],
            [-5, 38],
            [25, 43],
            [10, 31],
            [-24, 35],
            [19, 76],
            [-28, 69],
            [30, 9],
            [3, 55],
            [11, 17],
            [1, 90],
            [32, 31],
            [-19, 58],
            [-41, 4],
            [-11, -15],
            [-41, 0],
            [-18, 57],
            [-28, -17],
            [-25, -29],
        ],
        [
            [3144, 4597],
            [4, 82],
            [-29, 50],
            [98, 83],
            [85, -21],
            [93, 1],
            [73, -20],
            [58, 6],
            [111, -3],
        ],
        [
            [4196, 5472],
            [4, 50],
            [-14, 27],
        ],
        [
            [4194, 5626],
            [-11, 121],
            [41, 0],
            [18, 44],
            [17, 106],
            [-13, 39],
        ],
        [
            [4246, 5936],
            [13, 24],
            [58, 6],
            [13, -25],
            [47, 57],
            [-16, 43],
            [-3, 65],
        ],
        [
            [4454, 6109],
            [2, -45],
            [70, -27],
            [-1, -41],
            [70, 22],
            [39, 32],
            [78, -46],
            [33, -37],
            [16, -58],
            [-20, -31],
            [26, -41],
            [17, -62],
            [-6, -40],
            [29, -74],
            [-31, -12],
            [-18, 14],
            [-18, -22],
            [-49, -23],
            [-26, -28],
            [-50, -26],
            [12, -34],
            [7, -49],
            [35, -27],
            [39, -50],
        ],
        [
            [4432, 5248],
            [-74, 35],
            [-14, -25],
            [-59, 1],
        ],
        [
            [4490, 5175],
            [-5, -47],
            [-30, -20],
            [-52, 15],
            [-15, -47],
            [-33, -3],
            [-12, 18],
            [-38, -39],
            [-34, -6],
            [-29, 25],
        ],
        [
            [3340, 5980],
            [12, -82],
            [-53, -103],
            [-122, -69],
            [-98, 18],
            [56, 120],
            [-36, 118],
            [94, 90],
            [52, 54],
        ],
        [
            [3245, 6126],
            [15, -62],
            [-15, -62],
            [43, 2],
            [52, -24],
        ],
        [
            [2766, 7433],
            [-16, -75],
            [78, -78],
            [-90, -88],
            [-199, -80],
            [-60, -21],
            [-91, 17],
            [-193, 37],
            [68, 51],
            [-150, 56],
            [122, 23],
            [-3, 34],
            [-145, 26],
            [47, 76],
            [105, 17],
            [107, -79],
            [105, 63],
            [87, -32],
            [113, 61],
            [115, -8],
        ],
        [
            [4841, 4175],
            [-24, -90],
            [10, -36],
            [-15, -60],
            [-53, 44],
            [-35, 12],
            [-96, 59],
            [10, 59],
            [80, -10],
            [71, 12],
            [52, 10],
        ],
        [
            [4405, 4519],
            [42, -82],
            [-10, -152],
            [-31, 7],
            [-29, -39],
            [-26, 31],
            [-3, 139],
            [-15, 66],
            [38, -6],
            [34, 36],
        ],
        [
            [4723, 5131],
            [-8, -57],
            [17, -49],
            [-55, 17],
            [-56, -41],
            [4, -58],
            [-9, -32],
            [23, -59],
            [65, -58],
            [34, -96],
            [77, -93],
            [54, 1],
            [17, -26],
            [-19, -23],
            [62, -41],
            [51, -35],
            [59, -60],
            [7, -22],
            [-13, -41],
            [-38, 54],
            [-60, 19],
            [-29, -75],
            [50, -43],
            [-9, -60],
            [-29, -7],
            [-36, -99],
            [-29, -9],
            [0, 36],
            [14, 61],
            [15, 25],
            [-27, 67],
            [-21, 58],
            [-29, 15],
            [-20, 49],
            [-44, 21],
            [-30, 47],
            [-52, 7],
            [-54, 52],
            [-63, 75],
            [-47, 67],
            [-21, 114],
            [-35, 13],
            [-56, 39],
            [-32, -16],
            [-40, -54],
            [-28, -8],
        ],
        [
            [3245, 6126],
            [58, 5],
            [74, -72],
            [-37, -79],
        ],
        [
            [3561, 6530],
            [-74, -125],
            [71, 16],
            [75, 0],
            [-18, -94],
            [-62, -104],
            [72, -7],
            [5, -12],
            [62, -136],
            [47, -19],
            [42, -132],
            [20, -45],
            [84, -22],
            [-9, -74],
            [-35, -34],
            [28, -59],
            [-62, -61],
            [-93, 1],
            [-117, -32],
            [-33, 23],
            [-45, -54],
            [-64, 13],
            [-49, -44],
            [-37, 23],
            [102, 121],
            [62, 25],
            [-1, 0],
            [-108, 20],
            [-19, 46],
            [72, 35],
            [-38, 63],
            [13, 75],
            [103, -10],
            [0, 0],
            [10, 67],
            [-46, 71],
            [-1, 1],
            [-84, 21],
            [-17, 31],
            [25, 51],
            [-22, 32],
            [-37, -54],
            [-5, 111],
            [-34, 59],
            [25, 119],
            [53, 94],
            [56, -9],
            [83, 9],
        ],
        [
            [3998, 5689],
            [35, 32],
            [61, 170],
            [94, 48],
            [58, -3],
        ],
        [
            [3253, 4045],
            [-27, -30],
            [-37, 16],
            [-35, -13],
            [10, 91],
            [-6, 70],
            [-31, 11],
            [-17, 44],
            [6, 75],
            [27, 42],
            [5, 47],
            [15, 69],
            [-2, 49],
            [-14, 42],
            [-3, 39],
        ],
        [
            [5421, 7381],
            [-119, -33],
            [-67, -80],
            [11, -71],
            [-110, -93],
            [-134, -99],
            [-50, -162],
            [49, -82],
            [66, -64],
            [-63, -130],
            [-72, -27],
            [-27, -194],
            [-39, -108],
            [-84, 11],
            [-39, -91],
            [-80, -5],
            [-22, 109],
            [-58, 131],
            [-52, 163],
        ],
        [
            [4531, 6556],
            [30, 66],
            [58, 79],
            [23, 136],
            [-44, 59],
            [-5, 153],
            [45, 108],
            [69, -2],
            [24, 46],
            [-25, 39],
            [107, 163],
            [69, 128],
            [46, 82],
            [66, -1],
            [18, 65],
            [131, -19],
            [10, 76],
            [43, 5],
        ],
        [
            [4531, 6556],
            [-47, 71],
            [-136, -134],
            [-92, -27],
            [-96, 59],
            [-24, 124],
            [-22, 266],
            [63, 75],
            [183, 97],
            [136, 119],
            [127, 160],
            [166, 223],
            [116, 87],
            [190, 145],
            [151, 50],
            [114, -6],
            [105, 96],
            [126, -5],
            [124, 23],
            [217, -85],
            [-89, -30],
            [75, -73],
            [-117, -46],
            [-56, -11],
        ],
        [
            [5478, 8748],
            [-155, -47],
            [-122, 27],
            [48, 30],
            [-42, 37],
            [143, 23],
            [28, -43],
            [100, -27],
        ],
        [
            [5030, 8962],
            [228, -86],
            [-174, -46],
            [-39, -85],
            [-60, -21],
            [-33, -96],
            [-83, -5],
            [-149, 71],
            [63, 41],
            [-104, 33],
            [-135, 98],
            [-53, 90],
            [188, 41],
            [38, -40],
            [98, 2],
            [27, 39],
            [101, 4],
            [87, -40],
        ],
        [
            [5528, 9043],
            [135, -40],
            [-102, -62],
            [-201, -14],
            [-204, 19],
            [-12, 32],
            [-99, 2],
            [-76, 53],
            [214, 32],
            [100, -28],
            [70, 35],
            [175, -29],
        ],
    ],
    transform: {
        scale: [0.01446794120640842, 0.008664435084499279],
        translate: [-54.52475419779975, 2.053389187016037],
    },
    objects: {
        countries: {
            type: 'GeometryCollection',
            geometries: [
                {
                    arcs: [[0, 1, 2]],
                    type: 'Polygon',
                    properties: { name: 'Finland', country: 'FI' },
                },
                {
                    arcs: [[[3]], [[4]], [[5, 6, 7, 8, 9, 10, 11, 12, 13]]],
                    type: 'MultiPolygon',
                    properties: { name: 'France', country: 'FR' },
                },
                {
                    arcs: [[-12, 14, 15, 16, 17]],
                    type: 'Polygon',
                    properties: { name: 'Belgium', country: 'BE' },
                },
                {
                    arcs: [[[18]], [[19, 20]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Denmark', country: 'DK' },
                },
                {
                    arcs: [[21, 22, 23, 24]],
                    type: 'Polygon',
                    properties: { name: 'Austria', country: 'AT' },
                },
                {
                    arcs: [[-10, 25, 26, 27]],
                    type: 'Polygon',
                    properties: { name: 'Spain', country: 'ES' },
                },
                {
                    arcs: [[-6, -14, 28, -17, 29, 30, -20, 31, -23, 32]],
                    type: 'Polygon',
                    properties: { name: 'Germany', country: 'DE' },
                },
                {
                    arcs: [[-7, -33, -22, 33]],
                    type: 'Polygon',
                    properties: { name: 'Switzerland', country: 'CH' },
                },
                {
                    arcs: [[34, 35]],
                    type: 'Polygon',
                    properties: { name: 'Ireland', country: 'IE' },
                },
                { arcs: [[36]], type: 'Polygon', properties: { name: 'Iceland', country: 'IS' } },
                {
                    arcs: [[[37]], [[38]], [[-8, -34, -25, 39]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Italy', country: 'IT' },
                },
                {
                    arcs: [[-13, -18, -29]],
                    type: 'Polygon',
                    properties: { name: 'Luxembourg', country: 'LU' },
                },
                {
                    arcs: [[[-36, 40]], [[41]]],
                    type: 'MultiPolygon',
                    properties: { name: 'United Kingdom', country: 'GB' },
                },
                {
                    arcs: [[-16, 42, -30]],
                    type: 'Polygon',
                    properties: { name: 'Netherlands', country: 'NL' },
                },
                {
                    arcs: [[-27, 43]],
                    type: 'Polygon',
                    properties: { name: 'Portugal', country: 'PT' },
                },
                {
                    arcs: [[-1, 44, 45]],
                    type: 'Polygon',
                    properties: { name: 'Sweden', country: 'SE' },
                },
                {
                    arcs: [[[-2, -46, 46]], [[47]], [[48]], [[49]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Norway', country: 'NO' },
                },
            ],
        },
    },
};

export default data;
