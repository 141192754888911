// geojson from https://gist.github.com/minikomi/4043986 -- edited in QGIS
const data = {
    type: 'Topology',
    objects: {
        countries: {
            type: 'GeometryCollection',
            geometries: [
                {
                    type: 'MultiPolygon',
                    arcs: [[[0, 1, 2, 3, 4, 5]]],
                    properties: {
                        name: 'Niigata',
                        state: 'JP-15',
                        country: 'JPN',
                    },
                },
                {
                    type: 'MultiPolygon',
                    arcs: [[[6]], [[7, 8, 9, 10, 11, 12]]],
                    properties: {
                        name: 'Hyogo',
                        state: 'JP-28',
                        country: 'JPN',
                    },
                },
                {
                    type: 'MultiPolygon',
                    arcs: [[[13, 14, 15, 16]]],
                    properties: {
                        name: 'Shimane',
                        state: 'JP-32',
                        country: 'JPN',
                    },
                },
                {
                    type: 'MultiPolygon',
                    arcs: [[[-11, 17, 18, 19]]],
                    properties: {
                        name: 'Okayama',
                        state: 'JP-33',
                        country: 'JPN',
                    },
                },
                {
                    type: 'MultiPolygon',
                    arcs: [[[20, 21, -15]]],
                    properties: {
                        name: 'Yamaguchi',
                        state: 'JP-35',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[22]],
                    properties: {
                        name: 'Hokkaido',
                        state: 'JP-01',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[23, 24, 25]],
                    properties: {
                        name: 'Aomori',
                        state: 'JP-02',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[26, 27, 28, 29, -26]],
                    properties: {
                        name: 'Akita',
                        state: 'JP-05',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[30, -27, -25, 31]],
                    properties: {
                        name: 'Iwate',
                        state: 'JP-03',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[32, 33, -6, 34, -29]],
                    properties: {
                        name: 'Yamagata',
                        state: 'JP-06',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[35, 36, -33, -28, -31]],
                    properties: {
                        name: 'Miyagi',
                        state: 'JP-04',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[-37, 37, 38, 39, 40, -1, -34]],
                    properties: {
                        name: 'Fukushima',
                        state: 'JP-07',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[41, 42, 43, 44]],
                    properties: {
                        name: 'Ishikawa',
                        state: 'JP-17',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[45, 46, 47, -40]],
                    properties: {
                        name: 'Tochigi',
                        state: 'JP-09',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[-41, -48, 48, 49, -2]],
                    properties: {
                        name: 'Gunma',
                        state: 'JP-10',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[-50, 50, 51, 52, 53, 54, 55, -3]],
                    properties: {
                        name: 'Nagano',
                        state: 'JP-20',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[56, -4, -56, 57, -45]],
                    properties: {
                        name: 'Toyama',
                        state: 'JP-16',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[58, 59, 60, -46, -39]],
                    properties: {
                        name: 'Ibaraki',
                        state: 'JP-08',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[-55, 61, 62, 63, 64, -42, -58]],
                    properties: {
                        name: 'Gifu',
                        state: 'JP-21',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[-43, -65, 65, 66, 67]],
                    properties: {
                        name: 'Fukui',
                        state: 'JP-18',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[-47, -61, 68, 69, 70, -51, -49]],
                    properties: {
                        name: 'Saitama',
                        state: 'JP-11',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[71, 72, -69, -60]],
                    properties: {
                        name: 'Chiba',
                        state: 'JP-12',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[-71, 73, 74, 75, -52]],
                    properties: {
                        name: 'Yamanashi',
                        state: 'JP-19',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[-73, 76, 77, -74, -70]],
                    properties: {
                        name: 'Tokyo',
                        state: 'JP-13',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[-64, 78, 79, -66]],
                    properties: {
                        name: 'Shiga',
                        state: 'JP-25',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[-78, 80, 81, -75]],
                    properties: {
                        name: 'Kanagawa',
                        state: 'JP-14',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[-82, 82, 83, -53, -76]],
                    properties: {
                        name: 'Shizuoka',
                        state: 'JP-22',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[-54, -84, 84, 85, -62]],
                    properties: {
                        name: 'Aichi',
                        state: 'JP-23',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[-86, 86, 87, 88, 89, -79, -63]],
                    properties: {
                        name: 'Mie',
                        state: 'JP-24',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[90, 91, -9, 92, 93]],
                    properties: {
                        name: 'Osaka',
                        state: 'JP-27',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[94, -89, 95, -94]],
                    properties: {
                        name: 'Nara',
                        state: 'JP-29',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[-91, -96, -88, 96]],
                    properties: {
                        name: 'Wakayama',
                        state: 'JP-30',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[-67, -80, -90, -95, -93, -8, 97]],
                    properties: {
                        name: 'Kyoto',
                        state: 'JP-26',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[-12, -20, 98, -17, 99]],
                    properties: {
                        name: 'Tottori',
                        state: 'JP-31',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[-99, -19, 100, -21, -14]],
                    properties: {
                        name: 'Hiroshima',
                        state: 'JP-34',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[101, 102, 103]],
                    properties: {
                        name: 'Kagawa',
                        state: 'JP-37',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[104, 105, 106, -104]],
                    properties: {
                        name: 'Tokushima',
                        state: 'JP-36',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[-102, -107, 107, 108]],
                    properties: {
                        name: 'Ehime',
                        state: 'JP-38',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[109, 110, 111, 112, 113]],
                    properties: {
                        name: 'Fukuoka',
                        state: 'JP-40',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[-106, 114, -108]],
                    properties: {
                        name: 'Kochi',
                        state: 'JP-39',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[115, 116, 117, -114]],
                    properties: {
                        name: 'Oita',
                        state: 'JP-44',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[118, 119, 120, -112]],
                    properties: {
                        name: 'Saga',
                        state: 'JP-41',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[-120, 121]],
                    properties: {
                        name: 'Nagasaki',
                        state: 'JP-42',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[122, 123, 124, -110, -118]],
                    properties: {
                        name: 'Kumamoto',
                        state: 'JP-43',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[125, 126, -124]],
                    properties: {
                        name: 'Kagoshima',
                        state: 'JP-46',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[127]],
                    properties: {
                        name: 'Okinawa',
                        state: 'JP-47',
                        country: 'JPN',
                    },
                },
                {
                    type: 'Polygon',
                    arcs: [[128, -126, -123, -117]],
                    properties: {
                        name: 'Miyazaki',
                        state: 'JP-45',
                        country: 'JPN',
                    },
                },
            ],
        },
    },
    arcs: [
        [
            [139.74152, 37.820354],
            [139.55017, 37.627266],
            [139.59137, 37.52007],
            [139.21986, 37.44046],
            [139.16818, 37.233402],
            [139.25616, 37.15624],
            [139.2422, 36.94464],
        ],
        [
            [139.2422, 36.94464],
            [139.1001, 37.05568],
            [138.96916, 36.97572],
            [138.93285, 36.834305],
            [138.69785, 36.741077],
        ],
        [
            [138.69785, 36.741077],
            [138.69437, 36.85386],
            [138.5278, 37.027393],
            [138.26, 36.86538],
            [138.0072, 36.82488],
            [137.87067, 36.91287],
            [137.76591, 36.764122],
        ],
        [
            [137.76591, 36.764122],
            [137.63498, 36.980957],
        ],
        [
            [137.63498, 36.980957],
            [137.91782, 37.06266],
            [138.10008, 37.174744],
            [138.27081, 37.191154],
            [138.54317, 37.363644],
            [138.7202, 37.55883],
            [138.81831, 37.78544],
            [139.0774, 37.950245],
            [139.22125, 37.992146],
            [139.43495, 38.185932],
            [139.4517, 38.361916],
            [139.55087, 38.545227],
        ],
        [
            [139.55087, 38.545227],
            [139.6249, 38.522182],
            [139.7087, 38.404163],
            [139.90143, 38.290684],
            [139.68565, 38.168476],
            [139.62943, 37.905205],
            [139.74152, 37.820354],
        ],
        [
            [134.73863, 34.186565],
            [134.66252, 34.266525],
            [134.79869, 34.446693],
            [134.99876, 34.607662],
            [134.90588, 34.425743],
            [134.9593, 34.267223],
            [134.73863, 34.186565],
        ],
        [
            [134.87062, 35.659004],
            [135.05254, 35.416683],
            [134.92404, 35.312634],
            [135.15764, 35.25851],
            [135.20792, 35.15865],
            [135.38669, 35.125828],
            [135.37378, 35.042377],
        ],
        [
            [135.37378, 35.042377],
            [135.46945, 34.925755],
            [135.45688, 34.676796],
        ],
        [
            [135.45688, 34.676796],
            [135.30464, 34.722885],
            [134.9883, 34.639786],
            [134.66147, 34.790974],
            [134.34932, 34.732315],
        ],
        [
            [134.34932, 34.732315],
            [134.25644, 34.853127],
            [134.26866, 35.010254],
            [134.40553, 35.23756],
        ],
        [
            [134.40553, 35.23756],
            [134.51831, 35.27457],
            [134.37585, 35.608376],
        ],
        [
            [134.37585, 35.608376],
            [134.5445, 35.66878],
            [134.87062, 35.659004],
        ],
        [
            [133.14398, 35.0731],
            [132.87477, 35.09859],
            [132.53958, 34.792023],
            [132.24069, 34.798306],
            [132.1513, 34.714508],
            [132.06541, 34.47358],
        ],
        [
            [132.06541, 34.47358],
            [131.95752, 34.30703],
            [131.82169, 34.300392],
            [131.70157, 34.431683],
            [131.72252, 34.58322],
            [131.68517, 34.675053],
        ],
        [
            [131.68517, 34.675053],
            [131.81052, 34.68797],
            [132.0874, 34.928547],
            [132.31192, 35.0532],
            [132.43343, 35.185883],
            [132.62373, 35.27946],
            [132.62791, 35.417034],
            [132.97255, 35.512707],
            [133.10837, 35.6],
            [133.27213, 35.57905],
            [133.3273, 35.46557],
        ],
        [
            [133.3273, 35.46557],
            [133.32486, 35.453346],
            [133.30461, 35.260258],
            [133.15411, 35.21277],
            [133.14398, 35.0731],
        ],
        [
            [134.34932, 34.732315],
            [133.96104, 34.509895],
            [133.77737, 34.44914],
            [133.67332, 34.52107],
            [133.4537, 34.47288],
        ],
        [
            [133.4537, 34.47288],
            [133.38072, 34.802845],
            [133.27213, 35.054596],
        ],
        [
            [133.27213, 35.054596],
            [133.51096, 35.189377],
            [133.5993, 35.341614],
            [133.8308, 35.24629],
            [134.00015, 35.349644],
            [134.18172, 35.16668],
            [134.40553, 35.23756],
        ],
        [
            [132.06541, 34.47358],
            [132.15234, 34.232655],
            [132.23267, 34.22602],
        ],
        [
            [132.23267, 34.22602],
            [132.16562, 33.850666],
            [131.71379, 34.05353],
            [131.54271, 33.99068],
            [131.40373, 34.055626],
            [131.35555, 33.960304],
            [131.1848, 33.92469],
            [131.04758, 34.05458],
            [130.8964, 33.933067],
            [130.87685, 34.278744],
            [131.00813, 34.41387],
            [131.39081, 34.413174],
            [131.60275, 34.657944],
            [131.68517, 34.675053],
        ],
        [
            [141.42975, 43.413326],
            [141.33128, 43.727577],
            [141.58267, 43.874577],
            [141.6623, 44.013546],
            [141.64972, 44.30999],
            [141.75027, 44.43534],
            [141.79147, 44.660206],
            [141.73177, 44.904972],
            [141.57814, 45.17104],
            [141.656, 45.364475],
            [141.84036, 45.420345],
            [141.94023, 45.523],
            [142.53102, 45.02788],
            [142.74261, 44.76391],
            [143.01532, 44.54498],
            [143.53732, 44.25587],
            [143.80898, 44.173817],
            [144.221, 44.10014],
            [144.30339, 43.981773],
            [144.4483, 43.938126],
            [144.78525, 43.930096],
            [145.18748, 44.191624],
            [145.32994, 44.342464],
            [145.36801, 44.270184],
            [145.0953, 43.89029],
            [145.06982, 43.753418],
            [145.19237, 43.62073],
            [145.38371, 43.26039],
            [145.49545, 43.26493],
            [145.65327, 43.388535],
            [145.82018, 43.383648],
            [145.56424, 43.274357],
            [145.50523, 43.166817],
            [145.11835, 43.124214],
            [145.07889, 43.035877],
            [144.78035, 42.930428],
            [144.19899, 42.98769],
            [143.91966, 42.869675],
            [143.57503, 42.61583],
            [143.32327, 42.294243],
            [143.34108, 42.194035],
            [143.24123, 41.923428],
            [142.96434, 42.114075],
            [142.48004, 42.27015],
            [142.17522, 42.453114],
            [141.89622, 42.578117],
            [141.57814, 42.621063],
            [141.15111, 42.431816],
            [141.00305, 42.295643],
            [140.7115, 42.57707],
            [140.47652, 42.581608],
            [140.30298, 42.37944],
            [140.28377, 42.264915],
            [140.54704, 42.11512],
            [140.70836, 42.13502],
            [140.96326, 41.915745],
            [141.18916, 41.81728],
            [140.96884, 41.707294],
            [140.63329, 41.80855],
            [140.443, 41.66574],
            [140.4367, 41.530964],
            [140.2087, 41.39374],
            [140.0816, 41.418182],
            [139.98837, 41.549816],
            [140.14584, 41.911903],
            [140.05472, 42.083347],
            [139.77608, 42.25793],
            [139.86931, 42.662617],
            [140.03412, 42.688454],
            [140.18706, 42.82044],
            [140.26178, 42.76178],
            [140.52924, 43.02575],
            [140.32707, 43.21884],
            [140.4849, 43.372475],
            [140.80019, 43.19056],
            [141.01912, 43.2356],
            [141.15843, 43.14342],
            [141.42485, 43.32499],
            [141.42975, 43.413326],
        ],
        [
            [139.94438, 40.426895],
            [139.86302, 40.60951],
            [140.00269, 40.739746],
            [140.24257, 40.78514],
            [140.29355, 40.86859],
            [140.35255, 41.255817],
            [140.47093, 41.178654],
            [140.64447, 41.180397],
            [140.70451, 40.85253],
            [140.86304, 40.884304],
            [140.87247, 41.013145],
            [141.13748, 40.867893],
            [141.2824, 41.15351],
            [141.16821, 41.274326],
            [141.06276, 41.177956],
            [140.78867, 41.124184],
            [140.78552, 41.243946],
            [140.91507, 41.54388],
            [141.3421, 41.353935],
            [141.47444, 41.429703],
            [141.39796, 41.148624],
            [141.39658, 40.879417],
            [141.45139, 40.639885],
            [141.68604, 40.44959],
        ],
        [
            [141.68604, 40.44959],
            [141.53938, 40.34414],
            [141.3201, 40.36998],
            [140.95836, 40.245674],
        ],
        [
            [140.95836, 40.245674],
            [140.98315, 40.425495],
            [140.8833, 40.5079],
            [140.64726, 40.401054],
            [140.36407, 40.47543],
            [140.3386, 40.436672],
            [139.94438, 40.426895],
        ],
        [
            [140.95836, 40.245674],
            [140.86618, 40.171654],
            [140.85606, 39.935963],
            [140.78658, 39.865433],
            [140.80682, 39.607395],
            [140.66193, 39.41186],
            [140.81102, 39.17757],
            [140.7768, 38.95515],
        ],
        [
            [140.7768, 38.95515],
            [140.55438, 38.879032],
        ],
        [
            [140.55438, 38.879032],
            [140.36374, 39.02289],
            [140.20416, 39.033363],
            [140.06589, 39.130783],
            [139.8749, 39.11856],
        ],
        [
            [139.8749, 39.11856],
            [140.01456, 39.36647],
            [140.068, 39.689102],
            [139.9573, 39.897903],
            [139.75653, 39.852165],
            [139.69753, 39.990433],
            [139.80821, 39.95412],
            [139.97371, 40.106358],
            [140.02853, 40.353916],
            [139.94438, 40.426895],
        ],
        [
            [141.63785, 38.969467],
            [141.48735, 38.975056],
            [141.41891, 38.778122],
            [141.1504, 38.781963],
            [141.09978, 38.883224],
            [140.7768, 38.95515],
        ],
        [
            [141.68604, 40.44959],
            [141.83792, 40.2359],
            [141.83337, 40.100773],
            [141.94582, 40.000908],
            [142.07431, 39.552227],
            [141.97934, 39.318287],
            [141.81277, 39.109135],
            [141.84909, 39.024635],
            [141.63785, 38.969467],
        ],
        [
            [140.55438, 38.879032],
            [140.6483, 38.764507],
            [140.55788, 38.53615],
            [140.62317, 38.45235],
            [140.47966, 38.269733],
            [140.41785, 38.075596],
            [140.28342, 37.97364],
        ],
        [
            [140.28342, 37.97364],
            [140.23035, 37.74319],
            [139.98978, 37.757504],
            [139.94054, 37.825245],
            [139.74152, 37.820354],
        ],
        [
            [139.55087, 38.545227],
            [139.76106, 38.78825],
            [139.8749, 39.11856],
        ],
        [
            [141.63785, 38.969467],
            [141.64064, 38.90103],
            [141.44789, 38.64439],
            [141.54077, 38.487614],
            [141.43393, 38.39404],
            [141.09804, 38.384262],
            [140.96954, 38.18244],
            [140.93672, 37.891235],
        ],
        [
            [140.93672, 37.891235],
            [140.72896, 37.782993],
            [140.68567, 37.887398],
            [140.28342, 37.97364],
        ],
        [
            [140.93672, 37.891235],
            [141.01353, 37.740746],
            [141.03763, 37.34898],
            [140.97478, 36.977463],
            [140.79845, 36.855606],
        ],
        [
            [140.79845, 36.855606],
            [140.5893, 36.9422],
            [140.47755, 36.792755],
            [140.26073, 36.93382],
        ],
        [
            [140.26073, 36.93382],
            [140.252, 37.022858],
            [139.96463, 37.150303],
            [139.46916, 36.968037],
            [139.38536, 36.908676],
        ],
        [
            [139.38536, 36.908676],
            [139.2422, 36.94464],
        ],
        [
            [136.79628, 36.296936],
            [136.8497, 36.239323],
            [136.75717, 36.08569],
        ],
        [
            [136.75717, 36.08569],
            [136.34201, 36.17787],
            [136.246, 36.29065],
        ],
        [
            [136.246, 36.29065],
            [136.44852, 36.443935],
            [136.75508, 36.839195],
            [136.77115, 36.998066],
            [136.67442, 37.145065],
            [136.76137, 37.363297],
            [137.33295, 37.52601],
            [137.2757, 37.33257],
            [136.95236, 37.233402],
            [136.88428, 37.069996],
            [137.05676, 37.099674],
            [137.05641, 36.95721],
        ],
        [
            [137.05641, 36.95721],
            [136.98903, 36.963146],
            [136.89824, 36.919502],
            [136.79628, 36.721176],
            [136.79628, 36.296936],
        ],
        [
            [140.26073, 36.93382],
            [140.28552, 36.705463],
            [140.18845, 36.395054],
            [139.85359, 36.308456],
            [139.7052, 36.200565],
        ],
        [
            [139.7052, 36.200565],
            [139.6626, 36.217674],
        ],
        [
            [139.6626, 36.217674],
            [139.47859, 36.2697],
            [139.3686, 36.38702],
            [139.48558, 36.575222],
            [139.33124, 36.63074],
            [139.38536, 36.908676],
        ],
        [
            [139.6626, 36.217674],
            [139.46672, 36.18625],
            [139.13048, 36.275986],
            [139.04562, 36.125496],
            [138.7202, 35.990017],
        ],
        [
            [138.7202, 35.990017],
            [138.64024, 36.03506],
            [138.60358, 36.275288],
            [138.64478, 36.411114],
            [138.4042, 36.431713],
            [138.46112, 36.628647],
            [138.69785, 36.741077],
        ],
        [
            [138.7202, 35.990017],
            [138.73172, 35.906216],
        ],
        [
            [138.73172, 35.906216],
            [138.363, 35.94777],
            [138.19191, 35.784706],
            [138.22159, 35.641197],
        ],
        [
            [138.22159, 35.641197],
            [138.14546, 35.552162],
            [138.14513, 35.3678],
            [137.82074, 35.211372],
        ],
        [
            [137.82074, 35.211372],
            [137.5812, 35.19496],
            [137.56516, 35.284348],
        ],
        [
            [137.56516, 35.284348],
            [137.63812, 35.398525],
            [137.46144, 35.75817],
            [137.34343, 35.79483],
            [137.59692, 36.008522],
            [137.55711, 36.103848],
            [137.65384, 36.31719],
            [137.59029, 36.386673],
        ],
        [
            [137.59029, 36.386673],
            [137.74986, 36.583954],
            [137.76591, 36.764122],
        ],
        [
            [137.05641, 36.95721],
            [136.98833, 36.873062],
            [137.19154, 36.756092],
            [137.33261, 36.762028],
            [137.43176, 36.92404],
            [137.63498, 36.980957],
        ],
        [
            [137.59029, 36.386673],
            [137.39265, 36.45546],
            [137.1472, 36.44254],
            [137.01067, 36.28716],
            [136.87938, 36.36677],
            [136.79628, 36.296936],
        ],
        [
            [140.79845, 36.855606],
            [140.61478, 36.468727],
            [140.5645, 36.2697],
            [140.66228, 35.998398],
            [140.87737, 35.71592],
        ],
        [
            [140.87737, 35.71592],
            [140.70941, 35.83324],
            [140.46638, 35.91669],
            [140.15459, 35.84162],
            [139.89201, 35.982685],
            [139.789, 36.100353],
            [139.77992, 36.09372],
        ],
        [
            [139.77992, 36.09372],
            [139.7052, 36.200565],
        ],
        [
            [137.56516, 35.284348],
            [137.43701, 35.222546],
            [137.20027, 35.25013],
            [136.9782, 35.41913],
            [136.76765, 35.36047],
            [136.67093, 35.14538],
        ],
        [
            [136.67093, 35.14538],
            [136.51834, 35.25013],
            [136.41814, 35.214867],
        ],
        [
            [136.41814, 35.214867],
            [136.44537, 35.387005],
            [136.27881, 35.661102],
        ],
        [
            [136.27881, 35.661102],
            [136.32945, 35.769344],
            [136.78197, 35.795532],
            [136.82527, 35.8933],
            [136.75717, 36.08569],
        ],
        [
            [136.27881, 35.661102],
            [136.15346, 35.694622],
            [136.17267, 35.562637],
            [135.81548, 35.408302],
            [135.77113, 35.352436],
        ],
        [
            [135.77113, 35.352436],
            [135.53719, 35.374084],
            [135.47607, 35.562637],
        ],
        [
            [135.47607, 35.562637],
            [135.5309, 35.48931],
            [135.74005, 35.49385],
            [135.84201, 35.622345],
            [136.05011, 35.65796],
            [136.10318, 35.774582],
            [135.96178, 35.985477],
            [136.246, 36.29065],
        ],
        [
            [139.77992, 36.09372],
            [139.90388, 35.860126],
            [139.8749, 35.794483],
        ],
        [
            [139.8749, 35.794483],
            [139.39235, 35.763756],
            [139.28516, 35.836033],
            [139.0135, 35.893997],
            [138.94681, 35.851746],
        ],
        [
            [138.94681, 35.851746],
            [138.73172, 35.906216],
        ],
        [
            [140.87737, 35.71592],
            [140.61618, 35.664246],
            [140.4786, 35.5553],
            [140.39586, 35.37758],
            [140.39272, 35.190773],
            [140.13887, 35.118843],
            [139.9374, 34.91004],
            [139.82356, 35.189724],
            [139.90108, 35.421574],
            [140.1228, 35.59441],
            [139.99048, 35.685543],
            [139.89131, 35.622692],
        ],
        [
            [139.89131, 35.622692],
            [139.8749, 35.794483],
        ],
        [
            [138.94681, 35.851746],
            [139.1336, 35.67018],
        ],
        [
            [139.1336, 35.67018],
            [139.09032, 35.511307],
            [138.91957, 35.398876],
        ],
        [
            [138.91957, 35.398876],
            [138.68912, 35.35488],
            [138.58507, 35.44252],
            [138.5362, 35.197407],
            [138.40176, 35.197056],
            [138.26, 35.315075],
            [138.24254, 35.583237],
            [138.22159, 35.641197],
        ],
        [
            [139.89131, 35.622692],
            [139.79913, 35.513054],
        ],
        [
            [139.79913, 35.513054],
            [139.53062, 35.639454],
            [139.48662, 35.50188],
            [139.40282, 35.579395],
            [139.1336, 35.67018],
        ],
        [
            [136.41814, 35.214867],
            [136.45724, 35.159695],
            [136.36855, 34.902706],
            [136.1821, 34.883156],
            [136.02531, 34.78818],
        ],
        [
            [136.02531, 34.78818],
            [135.8696, 34.88874],
            [135.85632, 35.148174],
            [135.77113, 35.352436],
        ],
        [
            [139.79913, 35.513054],
            [139.63782, 35.454395],
            [139.72859, 35.21591],
            [139.62769, 35.164932],
            [139.57286, 35.28854],
            [139.25513, 35.28784],
            [139.11964, 35.135605],
        ],
        [
            [139.11964, 35.135605],
            [138.91957, 35.398876],
        ],
        [
            [139.11964, 35.135605],
            [139.13222, 34.87338],
            [138.84799, 34.601376],
            [138.74779, 34.68832],
            [138.81831, 35.11326],
            [138.56377, 35.093704],
            [138.54213, 35.001873],
            [138.36754, 34.913185],
            [138.20483, 34.664227],
            [138.23625, 34.59474],
            [137.98834, 34.665276],
            [137.48833, 34.674355],
        ],
        [
            [137.48833, 34.674355],
            [137.50963, 34.83148],
            [137.64162, 34.887695],
            [137.80573, 35.105576],
            [137.82074, 35.211372],
        ],
        [
            [137.48833, 34.674355],
            [137.02917, 34.587757],
            [137.3305, 34.747677],
            [137.22472, 34.823795],
            [137.05292, 34.7798],
            [136.93805, 34.869186],
            [136.94643, 34.693558],
            [136.85913, 34.744534],
            [136.8298, 34.908295],
            [136.8787, 35.092308],
            [136.71283, 35.06053],
        ],
        [
            [136.71283, 35.06053],
            [136.67093, 35.14538],
        ],
        [
            [136.71283, 35.06053],
            [136.54802, 34.77212],
            [136.53685, 34.609406],
            [136.92722, 34.446346],
            [136.90488, 34.274555],
            [136.768, 34.26024],
            [136.70515, 34.350674],
            [136.60425, 34.255352],
            [136.28789, 34.15095],
            [136.28824, 34.018616],
            [136.08643, 33.86184],
            [136.0145, 33.719727],
        ],
        [
            [136.0145, 33.719727],
            [135.85912, 33.81575],
            [135.86226, 33.896755],
        ],
        [
            [135.86226, 33.896755],
            [136.10388, 34.022457],
            [136.13321, 34.249763],
            [136.07315, 34.391525],
            [136.22156, 34.51653],
            [136.05011, 34.58322],
            [136.04802, 34.7379],
        ],
        [
            [136.04802, 34.7379],
            [136.02531, 34.78818],
        ],
        [
            [135.64578, 34.380352],
            [135.62483, 34.38105],
        ],
        [
            [135.62483, 34.38105],
            [135.11295, 34.270714],
            [135.09619, 34.31017],
            [135.22502, 34.346485],
            [135.46875, 34.592648],
            [135.45688, 34.676796],
        ],
        [
            [135.37378, 35.042377],
            [135.67894, 34.89433],
            [135.73203, 34.77561],
        ],
        [
            [135.73203, 34.77561],
            [135.71422, 34.778057],
            [135.64578, 34.380352],
        ],
        [
            [135.73203, 34.77561],
            [135.89299, 34.706127],
            [136.04802, 34.7379],
        ],
        [
            [135.86226, 33.896755],
            [135.60527, 33.901295],
            [135.54277, 34.07134],
            [135.67162, 34.221134],
            [135.64578, 34.380352],
        ],
        [
            [136.0145, 33.719727],
            [135.96387, 33.57657],
            [135.7645, 33.478455],
            [135.51624, 33.523846],
            [135.33362, 33.668053],
            [135.40205, 33.707855],
            [135.0672, 33.879997],
            [135.09409, 34.065403],
            [135.20198, 34.148155],
            [135.12083, 34.272415],
            [135.62483, 34.38105],
        ],
        [
            [134.87062, 35.659004],
            [135.22957, 35.77877],
            [135.31232, 35.698463],
            [135.19884, 35.56787],
            [135.47607, 35.562637],
        ],
        [
            [133.27213, 35.054596],
            [133.14398, 35.0731],
        ],
        [
            [133.3273, 35.46557],
            [133.5944, 35.530163],
            [134.041, 35.517242],
            [134.37585, 35.608376],
        ],
        [
            [133.4537, 34.47288],
            [133.36885, 34.36569],
            [133.23721, 34.40759],
            [132.96591, 34.336708],
            [132.64258, 34.198437],
            [132.51653, 34.25221],
            [132.53154, 34.35137],
            [132.35522, 34.35696],
            [132.23267, 34.22602],
        ],
        [
            [133.69289, 34.009888],
            [133.60349, 34.03782],
        ],
        [
            [133.60349, 34.03782],
            [133.67856, 34.220085],
            [133.93416, 34.38943],
            [134.26482, 34.346134],
            [134.44499, 34.208565],
        ],
        [
            [134.44499, 34.208565],
            [134.44359, 34.20507],
            [134.17613, 34.1726],
            [133.69289, 34.009888],
        ],
        [
            [134.44499, 34.208565],
            [134.64786, 34.17574],
            [134.58604, 34.032234],
            [134.74911, 33.832508],
            [134.36781, 33.62126],
            [134.31615, 33.54235],
        ],
        [
            [134.31615, 33.54235],
            [134.19847, 33.557365],
            [134.17822, 33.675034],
            [134.06648, 33.687256],
            [134.03577, 33.825176],
            [133.90413, 33.790607],
            [133.6632, 33.87825],
        ],
        [
            [133.6632, 33.87825],
            [133.69289, 34.009888],
        ],
        [
            [133.6632, 33.87825],
            [133.60942, 33.87825],
            [133.19637, 33.790607],
            [133.0176, 33.477406],
            [132.80948, 33.456802],
            [132.89853, 33.308758],
            [132.66597, 33.10554],
            [132.66039, 32.91734],
        ],
        [
            [132.66039, 32.91734],
            [132.51479, 32.938988],
            [132.4296, 33.148838],
            [132.55843, 33.223907],
            [132.42819, 33.29898],
            [132.35417, 33.484386],
            [132.15828, 33.36672],
            [132.0476, 33.36497],
            [132.39537, 33.523495],
            [132.65656, 33.696335],
            [132.718, 33.904087],
            [132.94846, 34.136635],
            [133.15482, 33.916656],
            [133.33394, 33.989635],
            [133.50119, 33.965195],
            [133.60349, 34.03782],
        ],
        [
            [130.84576, 33.103096],
            [130.68515, 33.166645],
            [130.42432, 33.003586],
        ],
        [
            [130.42432, 33.003586],
            [130.35239, 33.137318],
        ],
        [
            [130.35239, 33.137318],
            [130.53815, 33.339485],
            [130.27348, 33.476707],
            [130.04128, 33.46658],
        ],
        [
            [130.04128, 33.46658],
            [130.24101, 33.611835],
            [130.40442, 33.59298],
            [130.52872, 33.884186],
            [130.87125, 33.9418],
            [131.08669, 33.631737],
            [131.18759, 33.60101],
        ],
        [
            [131.18759, 33.60101],
            [131.17538, 33.583202],
            [131.17712, 33.507435],
            [130.98404, 33.50429],
            [130.83809, 33.340183],
            [130.84576, 33.103096],
        ],
        [
            [134.31615, 33.54235],
            [134.1838, 33.24032],
            [133.9331, 33.48299],
            [133.59302, 33.511974],
            [133.29727, 33.37894],
            [133.24734, 33.193184],
            [133.00362, 32.981586],
            [132.94426, 32.77977],
            [132.63211, 32.79024],
            [132.66039, 32.91734],
        ],
        [
            [131.18759, 33.60101],
            [131.43585, 33.568188],
            [131.50464, 33.6684],
            [131.67015, 33.66281],
            [131.75151, 33.536762],
            [131.70856, 33.402683],
            [131.50919, 33.35415],
            [131.51651, 33.26581],
            [131.90933, 33.259872],
            [131.80702, 33.120556],
            [131.94704, 33.044437],
            [132.01443, 32.82551],
            [131.88698, 32.73752],
        ],
        [
            [131.88698, 32.73752],
            [131.78957, 32.8304],
            [131.54166, 32.755325],
            [131.3311, 32.82935],
        ],
        [
            [131.3311, 32.82935],
            [131.11322, 33.179565],
            [130.98334, 33.134872],
            [130.99171, 33.020344],
            [130.84576, 33.103096],
        ],
        [
            [130.35239, 33.137318],
            [130.25497, 33.18725],
            [130.15161, 33.10275],
            [130.22006, 32.953304],
        ],
        [
            [130.22006, 32.953304],
            [130.06154, 32.98403],
            [129.81851, 33.182358],
            [129.79686, 33.339134],
        ],
        [
            [129.79686, 33.339134],
            [129.91278, 33.544792],
            [130.04128, 33.46658],
        ],
        [
            [130.22006, 32.953304],
            [130.09819, 32.85449],
            [130.31293, 32.872993],
            [130.351, 32.667686],
            [130.17711, 32.583885],
            [130.18234, 32.785004],
            [129.96237, 32.759518],
            [129.81049, 32.584583],
            [129.84714, 32.714474],
            [129.63171, 32.943176],
            [129.68549, 33.091225],
            [129.82724, 32.966225],
            [129.79301, 32.861473],
            [130.00531, 32.826557],
            [129.89462, 33.047928],
            [129.74588, 33.057007],
            [129.56223, 33.2068],
            [129.5741, 33.368813],
            [129.79686, 33.339134],
        ],
        [
            [131.3311, 32.82935],
            [131.01161, 32.511257],
            [131.11427, 32.3262],
            [131.10135, 32.151615],
            [130.7302, 32.09854],
        ],
        [
            [130.7302, 32.09854],
            [130.59401, 32.18269],
            [130.3674, 32.16104],
        ],
        [
            [130.3674, 32.16104],
            [130.5769, 32.432693],
            [130.65791, 32.63591],
            [130.46622, 32.60204],
            [130.6223, 32.78396],
            [130.42432, 33.003586],
        ],
        [
            [130.7302, 32.09854],
            [131.0563, 31.631353],
            [131.1904, 31.621227],
            [131.16666, 31.448738],
        ],
        [
            [131.16666, 31.448738],
            [131.01965, 31.353416],
            [131.13417, 31.272758],
            [130.9184, 31.105158],
            [130.6621, 30.988886],
            [130.80316, 31.325483],
            [130.7019, 31.46131],
            [130.82132, 31.64637],
            [130.6209, 31.691063],
            [130.5172, 31.467594],
            [130.66978, 31.257046],
            [130.51964, 31.159979],
            [130.46307, 31.24273],
            [130.22145, 31.245523],
            [130.33144, 31.634497],
            [130.1757, 31.780449],
            [130.23752, 32.127174],
            [130.3674, 32.16104],
        ],
        [
            [129.24142, 30.745073],
            [129.19743, 30.863441],
            [129.357, 31.10716],
            [129.78403, 31.461567],
            [129.88634, 31.431887],
            [129.79869, 31.304441],
            [129.39645, 31.101574],
            [129.42194, 31.006601],
            [129.24142, 30.745073],
        ],
        [
            [131.88698, 32.73752],
            [131.70926, 32.61426],
            [131.46938, 31.929544],
            [131.49033, 31.769974],
            [131.3332, 31.379953],
            [131.16666, 31.448738],
        ],
    ],
    bbox: [129.19743, 30.745073, 145.82018, 45.523],
};

export default data;
