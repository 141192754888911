const data = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {
                name: 'Czech Rep.',
                country: 'CZ',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [16.960288120194576, 48.5969823268506],
                        [16.49928266771877, 48.78580801044511],
                        [16.02964725105022, 48.73389903420793],
                        [15.253415561593982, 49.039074205107575],
                        [14.901447381254057, 48.964401760445824],
                        [14.33889773932472, 48.5553052842072],
                        [13.595945672264437, 48.87717194273715],
                        [13.031328973043431, 49.30706818297324],
                        [12.521024204161192, 49.547415269562734],
                        [12.415190870827445, 49.96912079528057],
                        [12.240111118222558, 50.266337795607285],
                        [12.966836785543194, 50.484076443069085],
                        [13.338131951560285, 50.73323436136435],
                        [14.056227654688172, 50.92691762959429],
                        [14.307013380600637, 51.117267767941414],
                        [14.570718214586066, 51.002339382524276],
                        [15.01699588385867, 51.10667409932158],
                        [15.490972120839727, 50.78472992614321],
                        [16.23862674323857, 50.69773265237984],
                        [16.176253289462267, 50.42260732685791],
                        [16.719475945714436, 50.21574656839354],
                        [16.868769158605655, 50.47397370055603],
                        [17.55456709155112, 50.36214590107641],
                        [17.64944502123899, 50.049038397819956],
                        [18.392913852622172, 49.98862864847075],
                        [18.853144158613617, 49.49622976337764],
                        [18.554971144289482, 49.49501536721878],
                        [18.399993523846177, 49.31500051533004],
                        [18.170498488037964, 49.271514797556435],
                        [18.104972771891852, 49.04398346617531],
                        [17.913511590250465, 48.996492824899086],
                        [17.88648481616181, 48.90347524677371],
                        [17.545006951577108, 48.80001902932537],
                        [17.101984897538898, 48.81696889911711],
                        [16.960288120194576, 48.5969823268506],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Austria',
                country: 'AT',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [16.979666782304037, 48.123497015976305],
                        [16.90375410326726, 47.71486562762833],
                        [16.340584344150415, 47.71290192320123],
                        [16.534267612380376, 47.49617096616912],
                        [16.202298211337364, 46.85238597267696],
                        [16.011663852612656, 46.6836107448117],
                        [15.137091912504985, 46.65870270444703],
                        [14.63247155117483, 46.43181732846955],
                        [13.806475457421527, 46.509306138691215],
                        [12.376485223040817, 46.76755910906985],
                        [12.153088006243054, 47.11539317482645],
                        [11.16482791509327, 46.94157949481273],
                        [11.048555942436536, 46.75135854754634],
                        [10.44270145024663, 46.89354625099743],
                        [9.932448357796659, 46.92072805438296],
                        [9.479969516649021, 47.10280996356337],
                        [9.632931756232978, 47.34760122332999],
                        [9.59422610844635, 47.52505809182027],
                        [9.896068149463188, 47.580196845075704],
                        [10.402083774465211, 47.30248769793916],
                        [10.544504021861627, 47.56639923765377],
                        [11.426414015354737, 47.523766181012974],
                        [12.141357456112788, 47.703083401065776],
                        [12.620759718484491, 47.67238760028441],
                        [12.932626987365948, 47.467645575544],
                        [13.02585127122049, 47.637583523135824],
                        [12.884102817443903, 48.28914581968792],
                        [13.243357374737, 48.416114813829054],
                        [13.595945672264437, 48.87717194273715],
                        [14.33889773932472, 48.5553052842072],
                        [14.901447381254057, 48.964401760445824],
                        [15.253415561593982, 49.039074205107575],
                        [16.02964725105022, 48.73389903420793],
                        [16.49928266771877, 48.78580801044511],
                        [16.960288120194576, 48.5969823268506],
                        [16.879982944413, 48.47001333270947],
                        [16.979666782304037, 48.123497015976305],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Bulgaria',
                country: 'BG',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [22.65714969248299, 44.23492300066128],
                        [22.944832391051847, 43.82378530534713],
                        [23.33230228037632, 43.89701080990471],
                        [24.100679152124172, 43.74105133724785],
                        [25.569271681426926, 43.68844472917472],
                        [26.065158725699746, 43.94349376075126],
                        [27.242399529740908, 44.175986029632405],
                        [27.970107049275075, 43.81246816667521],
                        [28.558081495891997, 43.70746165625813],
                        [28.03909508638472, 43.293171698574184],
                        [27.67389773937805, 42.577892361006214],
                        [27.99672041190539, 42.00735871028779],
                        [27.135739373490477, 42.14148489030134],
                        [26.117041863720797, 41.82690460872456],
                        [26.106138136507212, 41.32889883072778],
                        [25.197201368925445, 41.23448598893053],
                        [24.49264489105803, 41.583896185872035],
                        [23.692073601992348, 41.30908091894385],
                        [22.952377150166452, 41.33799388281115],
                        [22.88137373219743, 41.99929718685026],
                        [22.380525750424592, 42.32025950781509],
                        [22.54501183440962, 42.46136200618804],
                        [22.43659467946128, 42.580321153323936],
                        [22.60480146657133, 42.898518785161144],
                        [22.986018507588483, 43.211161200526966],
                        [22.50015669118028, 43.64281443946099],
                        [22.410446404721597, 44.00806346289995],
                        [22.65714969248299, 44.23492300066128],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Belarus',
                country: 'BY',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [23.48412763844985, 53.91249766704114],
                        [24.450683628037037, 53.905702216194754],
                        [25.536353794056993, 54.28242340760253],
                        [25.7684326514798, 54.84696259217509],
                        [26.58827924979039, 55.16717560487167],
                        [26.494331495883753, 55.615106919977634],
                        [27.10245975109453, 55.783313707087686],
                        [28.176709425577993, 56.16912995057881],
                        [29.229513380660308, 55.918344224666356],
                        [29.371571893030673, 55.670090643936184],
                        [29.896294386522356, 55.78946320253041],
                        [30.873909132620007, 55.55097646750341],
                        [30.971835971813135, 55.08154775656404],
                        [30.757533807098717, 54.81177094178432],
                        [31.38447228366374, 54.157056382862436],
                        [31.79142418796224, 53.97463857687212],
                        [31.731272820774507, 53.79402944601202],
                        [32.405598585751164, 53.618045355842035],
                        [32.69364301934604, 53.35142080343212],
                        [32.304519484188226, 53.1327261419729],
                        [31.49764367038293, 53.1674268662569],
                        [31.305200636528014, 53.07399587667321],
                        [31.54001834486226, 52.74205231384636],
                        [31.785998162571587, 52.101677964885454],
                        [30.927549269338982, 52.04235342061438],
                        [30.619454380014844, 51.822806098022376],
                        [30.555117221811457, 51.31950348571566],
                        [30.157363722460897, 51.41613841410147],
                        [29.254938185347925, 51.368234361366895],
                        [28.99283532076353, 51.602044379271476],
                        [28.61761274589225, 51.42771393493484],
                        [28.24161502453657, 51.57222707783907],
                        [27.454066196408434, 51.59230337178447],
                        [26.337958611768556, 51.83228872334793],
                        [25.327787713327005, 51.91065603291855],
                        [24.553106316839518, 51.888461005249184],
                        [24.00507775238421, 51.61744395609446],
                        [23.52707075368437, 51.57845408793023],
                        [23.508002150168693, 52.02364655212473],
                        [23.199493849386187, 52.486977444053664],
                        [23.799198846133375, 52.69109935160657],
                        [23.80493493011778, 53.089731350306074],
                        [23.527535841575002, 53.470121568406555],
                        [23.48412763844985, 53.91249766704114],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Belgium',
                country: 'BE',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [3.314971144228537, 51.345780951536085],
                        [4.047071160507527, 51.26725861266857],
                        [4.973991326526914, 51.475023708698124],
                        [5.606975945670001, 51.037298488969775],
                        [6.156658155958779, 50.80372101501058],
                        [6.043073357781111, 50.128051662794235],
                        [5.782417433300906, 50.09032786722122],
                        [5.674051954784829, 49.529483547557504],
                        [4.799221632515809, 49.985373033236385],
                        [4.286022983425084, 49.907496649772554],
                        [3.588184441755686, 50.37899241800358],
                        [3.123251580425801, 50.780363267614575],
                        [2.658422071960274, 50.79684804951574],
                        [2.513573032246143, 51.14850617126183],
                        [3.314971144228537, 51.345780951536085],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Albania',
                country: 'AL',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [20.590247430104906, 41.855404161133606],
                        [20.463175083099202, 41.51508901627533],
                        [20.605181919037364, 41.08622630468522],
                        [21.0200403174764, 40.84272695572588],
                        [20.999989861747224, 40.58000397395397],
                        [20.674996779063633, 40.43499990494303],
                        [20.615000441172754, 40.11000682225938],
                        [20.15001590341052, 39.62499766698397],
                        [19.980000441170144, 39.69499339452341],
                        [19.960001661873207, 39.91500580500605],
                        [19.406081984136733, 40.250773423822466],
                        [19.319058872157143, 40.72723012955356],
                        [19.40354983895429, 41.40956574153546],
                        [19.540027296637106, 41.71998607031276],
                        [19.37176883309496, 41.877547512370654],
                        [19.304486118250793, 42.19574514420782],
                        [19.738051385179627, 42.688247382165564],
                        [19.801613396898688, 42.50009349219084],
                        [20.0707, 42.58863],
                        [20.283754510181893, 42.32025950781508],
                        [20.52295, 42.21787],
                        [20.590247430104906, 41.855404161133606],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Switzerland',
                country: 'CH',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [9.59422610844635, 47.52505809182027],
                        [9.632931756232978, 47.34760122332999],
                        [9.479969516649021, 47.10280996356337],
                        [9.932448357796659, 46.92072805438296],
                        [10.44270145024663, 46.89354625099743],
                        [10.363378126678612, 46.48357127540986],
                        [9.92283654139038, 46.31489940040919],
                        [9.182881707403055, 46.44021474871698],
                        [8.966305779667806, 46.036931871111186],
                        [8.489952426801324, 46.005150865251686],
                        [8.31662967289438, 46.16364248309086],
                        [7.755992058959833, 45.82449005795931],
                        [7.273850945676656, 45.776947740250776],
                        [6.843592970414504, 45.99114655210061],
                        [6.500099724970425, 46.42967275652944],
                        [6.022609490593537, 46.27298981382047],
                        [6.037388950229001, 46.725778713561866],
                        [6.768713820023606, 47.2877082383037],
                        [6.736571079138059, 47.541801255882845],
                        [7.192202182655507, 47.44976552997102],
                        [7.46675906742223, 47.62058197691181],
                        [8.317301466514152, 47.61357982033626],
                        [8.522611932009765, 47.830827541691285],
                        [9.59422610844635, 47.52505809182027],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Bosnia and Herz.',
                country: 'BA',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [19.00548628101012, 44.86023366960916],
                        [19.36803, 44.863],
                        [19.11761, 44.42307000000011],
                        [19.59976, 44.03847],
                        [19.454, 43.56810000000013],
                        [19.21852, 43.52384],
                        [19.03165, 43.43253],
                        [18.70648, 43.20011],
                        [18.56, 42.65],
                        [17.674921502358984, 43.02856252702361],
                        [17.297373488034452, 43.44634064388736],
                        [16.91615644701733, 43.66772247982567],
                        [16.456442905348865, 44.04123973243128],
                        [16.23966027188453, 44.35114329688571],
                        [15.750026075918981, 44.81871165626256],
                        [15.959367303133376, 45.233776760430935],
                        [16.318156772535872, 45.00412669532591],
                        [16.534939406000206, 45.21160757097772],
                        [17.002146030351014, 45.233776760430935],
                        [17.861783481526402, 45.067740383477144],
                        [18.553214145591653, 45.08158966733145],
                        [19.00548628101012, 44.86023366960916],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'United Kingdom',
                country: 'GB',
            },
            geometry: {
                type: 'MultiPolygon',
                coordinates: [
                    [
                        [
                            [-5.661948614921897, 54.55460317648385],
                            [-6.197884894220977, 53.86756500916334],
                            [-6.953730231137996, 54.073702297575636],
                            [-7.572167934591079, 54.05995636658599],
                            [-7.366030646178785, 54.595840969452695],
                            [-7.572167934591079, 55.1316222194549],
                            [-6.733847011736145, 55.1728600124238],
                            [-5.661948614921897, 54.55460317648385],
                        ],
                    ],
                    [
                        [
                            [-3.005004848635281, 58.63500010846633],
                            [-4.073828497728016, 57.55302480735525],
                            [-3.055001796877661, 57.69001902936095],
                            [-1.959280564776918, 57.68479970969951],
                            [-2.219988165689301, 56.87001740175353],
                            [-3.119003058271118, 55.973793036515474],
                            [-2.085009324543023, 55.90999848085127],
                            [-2.005675679673857, 55.80490285035023],
                            [-1.11499101399221, 54.62498647726539],
                            [-0.4304849918542, 54.46437612570216],
                            [0.184981316742039, 53.32501414653103],
                            [0.469976840831777, 52.92999949809197],
                            [1.681530795914739, 52.739520168664],
                            [1.559987827164377, 52.09999848083601],
                            [1.050561557630914, 51.806760565795685],
                            [1.449865349950301, 51.28942780212196],
                            [0.550333693045502, 50.765738837275876],
                            [-0.78751746255864, 50.77498891865622],
                            [-2.489997524414377, 50.50001862243124],
                            [-2.956273972984036, 50.696879991247016],
                            [-3.617448085942328, 50.22835561787272],
                            [-4.542507900399244, 50.34183706318566],
                            [-5.245023159191135, 49.95999990498108],
                            [-5.776566941745301, 50.15967763935682],
                            [-4.309989793301838, 51.21000112568916],
                            [-3.414850633142123, 51.42600861266925],
                            [-3.422719467108323, 51.42684816740609],
                            [-4.984367234710874, 51.593466091510976],
                            [-5.267295701508885, 51.99140045837458],
                            [-4.222346564134853, 52.301355699261364],
                            [-4.770013393564113, 52.840004991255626],
                            [-4.579999152026915, 53.49500377055517],
                            [-3.093830673788659, 53.404547400669685],
                            [-3.092079637047106, 53.404440822963544],
                            [-2.945008510744344, 53.984999701546684],
                            [-3.614700825433034, 54.600936773292574],
                            [-3.63000545898933, 54.615012925833014],
                            [-4.844169073903004, 54.790971177786844],
                            [-5.082526617849226, 55.06160065369937],
                            [-4.719112107756644, 55.50847260194348],
                            [-5.047980922862109, 55.78398550070752],
                            [-5.586397670911139, 55.31114614523682],
                            [-5.644998745130181, 56.275014960344805],
                            [-6.149980841486354, 56.78500967063354],
                            [-5.786824713555291, 57.81884837506465],
                            [-5.009998745127575, 58.63001333275005],
                            [-4.211494513353557, 58.55084503847917],
                            [-3.005004848635281, 58.63500010846633],
                        ],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Germany',
                country: 'DE',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [9.921906365609232, 54.983104153048025],
                        [9.9395797054529, 54.596641954153256],
                        [10.950112338920519, 54.363607082733154],
                        [10.939466993868448, 54.00869334575258],
                        [11.956252475643282, 54.19648550070116],
                        [12.518440382546714, 54.47037059184799],
                        [13.647467075259499, 54.0755109727059],
                        [14.119686313542559, 53.75702912049103],
                        [14.353315463934168, 53.248171291713106],
                        [14.074521111719434, 52.98126251892535],
                        [14.4375997250022, 52.624850165408304],
                        [14.685026482815713, 52.089947414755216],
                        [14.607098422919648, 51.745188096719964],
                        [15.016995883858781, 51.10667409932171],
                        [14.570718214586122, 51.00233938252438],
                        [14.307013380600665, 51.11726776794137],
                        [14.056227654688314, 50.92691762959435],
                        [13.338131951560397, 50.73323436136428],
                        [12.96683678554325, 50.48407644306917],
                        [12.240111118222671, 50.26633779560723],
                        [12.415190870827473, 49.96912079528062],
                        [12.521024204161336, 49.54741526956275],
                        [13.031328973043514, 49.30706818297324],
                        [13.595945672264577, 48.877171942737164],
                        [13.243357374737116, 48.41611481382903],
                        [12.884102817443873, 48.28914581968786],
                        [13.025851271220517, 47.63758352313595],
                        [12.932626987366064, 47.467645575544],
                        [12.620759718484521, 47.672387600284424],
                        [12.141357456112871, 47.70308340106578],
                        [11.426414015354851, 47.52376618101306],
                        [10.544504021861597, 47.5663992376538],
                        [10.402083774465325, 47.30248769793916],
                        [9.896068149463188, 47.580196845075704],
                        [9.594226108446376, 47.5250580918202],
                        [8.522611932009795, 47.83082754169135],
                        [8.317301466514095, 47.61357982033627],
                        [7.466759067422288, 47.62058197691192],
                        [7.593676385131062, 48.33301911070373],
                        [8.099278598674855, 49.01778351500343],
                        [6.658229607783709, 49.20195831969164],
                        [6.186320428094177, 49.463802802114515],
                        [6.242751092156993, 49.90222565367873],
                        [6.043073357781111, 50.128051662794235],
                        [6.156658155958779, 50.80372101501058],
                        [5.988658074577813, 51.851615709025054],
                        [6.589396599970826, 51.852029120483394],
                        [6.842869500362383, 52.22844025329755],
                        [7.092053256873896, 53.14404328064489],
                        [6.905139601274129, 53.48216217713064],
                        [7.100424838905268, 53.69393219666267],
                        [7.936239454793962, 53.74829580343379],
                        [8.121706170289485, 53.52779246684429],
                        [8.800734490604668, 54.020785630908904],
                        [8.572117954145368, 54.39564647075405],
                        [8.526229282270208, 54.96274363872516],
                        [9.282048780971136, 54.83086538351631],
                        [9.921906365609232, 54.983104153048025],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Spain',
                country: 'ES',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-9.034817674180246, 41.88057058365967],
                        [-8.984433152695672, 42.59277517350627],
                        [-9.392883673530648, 43.0266246608127],
                        [-7.978189663108309, 43.748337714200986],
                        [-6.754491746436756, 43.567909450853925],
                        [-5.411886359061596, 43.574239813809676],
                        [-4.347842779955783, 43.40344920508504],
                        [-3.51753170410609, 43.4559007838613],
                        [-1.901351284177764, 43.42280202897834],
                        [-1.502770961910528, 43.03401439063043],
                        [0.338046909190581, 42.57954600683954],
                        [0.701590610363894, 42.7957343613326],
                        [1.826793247087153, 42.34338471126569],
                        [2.985998976258458, 42.47301504166986],
                        [3.039484083680549, 41.892120266276905],
                        [2.091841668312185, 41.22608856868309],
                        [0.810524529635188, 41.01473196060934],
                        [0.721331007499401, 40.678318386389236],
                        [0.106691521819869, 40.12393362076202],
                        [-0.278711310212941, 39.30997813573272],
                        [0.111290724293838, 38.73851430923303],
                        [-0.467123582349103, 38.29236583104115],
                        [-0.683389451490598, 37.642353827457825],
                        [-1.438382127274849, 37.443063666324214],
                        [-2.146452602538119, 36.67414419203728],
                        [-3.415780808923387, 36.65889964451118],
                        [-4.368900926114719, 36.677839056946155],
                        [-4.995219285492211, 36.32470815687964],
                        [-5.377159796561457, 35.946850083961465],
                        [-5.866432257500904, 36.02981659600606],
                        [-6.236693894872175, 36.367677110330334],
                        [-6.520190802425404, 36.94291331638732],
                        [-7.453725551778092, 37.09778758396607],
                        [-7.537105475281024, 37.42890432387623],
                        [-7.166507941099865, 37.803894354802225],
                        [-7.029281175148796, 38.07576406508977],
                        [-7.374092169616318, 38.37305858006492],
                        [-7.098036668313128, 39.03007274022378],
                        [-7.498632371439725, 39.62957103124181],
                        [-7.066591559263529, 39.71189158788277],
                        [-7.026413133156595, 40.184524237624245],
                        [-6.864019944679385, 40.33087189387483],
                        [-6.851126674822552, 41.11108266861753],
                        [-6.389087693700915, 41.381815497394655],
                        [-6.668605515967656, 41.883386949219584],
                        [-7.251308966490824, 41.91834605566505],
                        [-7.422512986673795, 41.79207469335983],
                        [-8.013174607769912, 41.790886135417125],
                        [-8.263856980817792, 42.28046865495034],
                        [-8.67194576662672, 42.13468943945496],
                        [-9.034817674180246, 41.88057058365967],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'France',
                country: 'FR',
            },
            geometry: {
                type: 'MultiPolygon',
                coordinates: [
                    [
                        [
                            [-52.55642473001839, 2.504705308437053],
                            [-52.93965715189498, 2.124857692875622],
                            [-53.418465135295264, 2.053389187016037],
                            [-53.554839240113495, 2.334896551925965],
                            [-53.77852067728889, 2.376702785650053],
                            [-54.08806250671728, 2.105556545414629],
                            [-54.52475419779975, 2.311848863123785],
                            [-54.27122962097578, 2.738747870286943],
                            [-54.18428402364474, 3.194172268075235],
                            [-54.01150387227682, 3.622569891774858],
                            [-54.399542202356514, 4.212611395683481],
                            [-54.47863298197922, 4.896755682795643],
                            [-53.95804460307093, 5.756548163267809],
                            [-53.618452928264844, 5.646529038918402],
                            [-52.88214128275408, 5.409850979021599],
                            [-51.82334286152593, 4.565768133966145],
                            [-51.65779741067888, 4.156232408053029],
                            [-52.24933753112398, 3.241094468596287],
                            [-52.55642473001839, 2.504705308437053],
                        ],
                    ],
                    [
                        [
                            [9.560016310269134, 42.15249197037957],
                            [9.229752231491773, 41.38000682226445],
                            [8.77572309737536, 41.58361196549444],
                            [8.54421268070783, 42.25651662858308],
                            [8.746009148807588, 42.62812185319396],
                            [9.390000848028905, 43.00998484961474],
                            [9.560016310269134, 42.15249197037957],
                        ],
                    ],
                    [
                        [
                            [3.588184441755715, 50.37899241800358],
                            [4.28602298342514, 49.907496649772554],
                            [4.799221632515753, 49.98537303323633],
                            [5.674051954784885, 49.52948354755745],
                            [5.897759230176376, 49.44266714130717],
                            [6.186320428094206, 49.46380280211446],
                            [6.658229607783539, 49.20195831969155],
                            [8.099278598674772, 49.01778351500337],
                            [7.593676385131062, 48.33301911070373],
                            [7.46675906742223, 47.620581976911865],
                            [7.192202182655535, 47.44976552997099],
                            [6.736571079138088, 47.54180125588289],
                            [6.768713820023634, 47.28770823830368],
                            [6.037388950228972, 46.72577871356191],
                            [6.022609490593567, 46.272989813820516],
                            [6.500099724970454, 46.42967275652944],
                            [6.843592970414562, 45.99114655210067],
                            [6.802355177445662, 45.70857982032867],
                            [7.096652459347837, 45.333098863295874],
                            [6.749955275101711, 45.02851797136759],
                            [7.007562290076663, 44.25476675066139],
                            [7.549596388386163, 44.12790110938482],
                            [7.435184767291843, 43.69384491634918],
                            [6.529245232783068, 43.12889232031836],
                            [4.556962517931396, 43.39965098731158],
                            [3.10041059735272, 43.075200507167125],
                            [2.985998976258486, 42.47301504166989],
                            [1.826793247087181, 42.34338471126566],
                            [0.701590610363922, 42.79573436133265],
                            [0.338046909190581, 42.579546006839564],
                            [-1.502770961910471, 43.03401439063049],
                            [-1.901351284177735, 43.42280202897834],
                            [-1.384225226232957, 44.02261037859017],
                            [-1.193797573237362, 46.014917710954876],
                            [-2.225724249673789, 47.06436269793821],
                            [-2.963276129559574, 47.570326646507965],
                            [-4.491554938159481, 47.95495433205642],
                            [-4.592349819344747, 48.68416046812695],
                            [-3.295813971357745, 48.901692409859635],
                            [-1.616510789384932, 48.644421291694584],
                            [-1.933494025063254, 49.776341864615766],
                            [-0.98946895995536, 49.347375800160876],
                            [1.338761020522753, 50.12717316344526],
                            [1.6390010921385, 50.946606350297515],
                            [2.513573032246171, 51.14850617126185],
                            [2.658422071960331, 50.79684804951566],
                            [3.123251580425716, 50.78036326761452],
                            [3.588184441755715, 50.37899241800358],
                        ],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Estonia',
                country: 'EE',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [24.312862583114622, 57.79342357037698],
                        [24.42892785004216, 58.38341339785328],
                        [24.061198357853186, 58.25737457949341],
                        [23.42656009287668, 58.612753404364625],
                        [23.339795363058645, 59.18724030215338],
                        [24.604214308376182, 59.46585378685502],
                        [25.86418908051664, 59.61109039981134],
                        [26.949135776484525, 59.445803331125774],
                        [27.981114129353244, 59.47538808861287],
                        [28.13169925305175, 59.30082510033092],
                        [27.42016645682494, 58.72458120384424],
                        [27.71668582531572, 57.79189911562437],
                        [27.28818484875151, 57.47452830670383],
                        [26.463532342237787, 57.47638865826633],
                        [25.602809685984365, 57.84752879498657],
                        [25.16459354014927, 57.97015696881519],
                        [24.312862583114622, 57.79342357037698],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Denmark',
                country: 'DK',
            },
            geometry: {
                type: 'MultiPolygon',
                coordinates: [
                    [
                        [
                            [12.69000613775563, 55.609990953180784],
                            [12.089991082414741, 54.80001455343793],
                            [11.043543328504228, 55.364863796604254],
                            [10.903913608451631, 55.77995473898875],
                            [12.370904168353292, 56.111407375708836],
                            [12.69000613775563, 55.609990953180784],
                        ],
                    ],
                    [
                        [
                            [10.912181837618363, 56.458621324277914],
                            [10.667803989309988, 56.08138336854722],
                            [10.369992710011985, 56.19000722922473],
                            [9.649984978889307, 55.469999498102055],
                            [9.921906365609175, 54.98310415304806],
                            [9.282048780971136, 54.83086538351616],
                            [8.526229282270236, 54.96274363872499],
                            [8.120310906617588, 55.517722683323626],
                            [8.08997684086225, 56.540011705137594],
                            [8.256581658571264, 56.8099693874303],
                            [8.543437534223386, 57.110002753316905],
                            [9.42446902836761, 57.17206614849948],
                            [9.775558709358563, 57.447940782289656],
                            [10.580005730846153, 57.73001658795485],
                            [10.546105991262692, 57.215732733786155],
                            [10.250000034230226, 56.89001618105047],
                            [10.369992710011985, 56.609981594460834],
                            [10.912181837618363, 56.458621324277914],
                        ],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Finland',
                country: 'FI',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [28.591929559043194, 69.06477692328666],
                        [28.445943637818658, 68.36461294216404],
                        [29.977426385220607, 67.69829702419266],
                        [29.054588657352326, 66.94428620062193],
                        [30.21765, 65.80598],
                        [29.544429559046986, 64.94867157659048],
                        [30.44468468600371, 64.20445343693909],
                        [30.035872430142714, 63.55281362573855],
                        [31.51609215671112, 62.86768748641288],
                        [31.139991082490894, 62.35769277612441],
                        [30.211107212044446, 61.78002777774969],
                        [28.069997592895277, 60.503516547275844],
                        [26.25517296723697, 60.4239606797625],
                        [24.496623976344523, 60.05731639265165],
                        [22.869694858499457, 59.846373196036225],
                        [22.290763787533592, 60.39192129174154],
                        [21.322244093519316, 60.72016998965952],
                        [21.544866163832694, 61.7053294948718],
                        [21.05921105315369, 62.60739329695874],
                        [21.536029493910803, 63.18973501245587],
                        [22.442744174903993, 63.81781037053129],
                        [24.730511508897536, 64.90234365504082],
                        [25.398067661243942, 65.11142650009373],
                        [25.294043003040404, 65.53434642197045],
                        [23.903378533633802, 66.00692739527962],
                        [23.565879754335583, 66.39605093043743],
                        [23.53947309743444, 67.93600861273525],
                        [21.978534783626117, 68.6168456081807],
                        [20.645592889089528, 69.10624726020087],
                        [21.244936150810673, 69.37044302029307],
                        [22.356237827247412, 68.84174144151491],
                        [23.66204959483076, 68.89124746365054],
                        [24.735679152126725, 68.64955678982146],
                        [25.68921268077636, 69.09211375596904],
                        [26.17962202322624, 69.82529897732614],
                        [27.732292107867863, 70.16419302029625],
                        [29.01557295097197, 69.76649119737799],
                        [28.591929559043194, 69.06477692328666],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Ireland',
                country: 'IE',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-6.197884894220991, 53.86756500916336],
                        [-6.03298539877761, 53.15316417094435],
                        [-6.788856573910849, 52.260117906292336],
                        [-8.56161658368356, 51.669301255899356],
                        [-9.977085740590269, 51.82045482035307],
                        [-9.16628251793078, 52.86462881124268],
                        [-9.688524542672454, 53.8813626165853],
                        [-8.327987433292009, 54.66451894796863],
                        [-7.572167934591064, 55.13162221945487],
                        [-7.366030646178785, 54.59584096945272],
                        [-7.572167934591064, 54.059956366586],
                        [-6.953730231138067, 54.073702297575636],
                        [-6.197884894220991, 53.86756500916336],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Greece',
                country: 'GR',
            },
            geometry: {
                type: 'MultiPolygon',
                coordinates: [
                    [
                        [
                            [23.699980096133004, 35.70500438083553],
                            [24.24666507334868, 35.368022365860156],
                            [25.02501549652888, 35.42499563246198],
                            [25.769207797964185, 35.35401805270908],
                            [25.745023227651586, 35.179997666966216],
                            [26.290002882601723, 35.29999034274792],
                            [26.16499759288766, 35.004995429009796],
                            [24.724982130642303, 34.91998769788961],
                            [24.735007358506945, 35.08499054619759],
                            [23.51497846852811, 35.27999156345098],
                            [23.699980096133004, 35.70500438083553],
                        ],
                    ],
                    [
                        [
                            [26.604195590936282, 41.562114569661105],
                            [26.29460208507578, 40.93626129817426],
                            [26.056942172965506, 40.824123440100834],
                            [25.447677036244187, 40.85254547786147],
                            [24.92584842296094, 40.94706167252323],
                            [23.714811232200816, 40.687129218095116],
                            [24.407998894964066, 40.1249929876241],
                            [23.899967889102584, 39.96200552017558],
                            [23.3429993018608, 39.96099782974579],
                            [22.81398766448896, 40.476005153966554],
                            [22.62629886240478, 40.25656118423919],
                            [22.849747755634805, 39.65931081802577],
                            [23.3500272966526, 39.19001129816726],
                            [22.973099399515547, 38.97090322524966],
                            [23.530016310324953, 38.51000112563847],
                            [24.025024855248944, 38.21999298761645],
                            [24.040011020613605, 37.655014553369426],
                            [23.115002882589152, 37.92001129816222],
                            [23.409971958111072, 37.409990749657396],
                            [22.774971958108633, 37.30501007745656],
                            [23.15422529469862, 36.422505804992056],
                            [22.490028110451107, 36.41000010837746],
                            [21.670026482843696, 36.8449864771942],
                            [21.295010613701574, 37.644989325504696],
                            [21.120034213961333, 38.31032339126273],
                            [20.730032179454582, 38.769985256498785],
                            [20.217712029712857, 39.340234686839636],
                            [20.15001590341052, 39.62499766698403],
                            [20.615000441172782, 40.11000682225943],
                            [20.674996779063633, 40.434999904943055],
                            [20.99998986174728, 40.58000397395397],
                            [21.02004031747643, 40.84272695572588],
                            [21.674160597426976, 40.93127452245798],
                            [22.05537763844427, 41.14986583105269],
                            [22.597308383889015, 41.130487168943205],
                            [22.76177, 41.3048],
                            [22.952377150166566, 41.33799388281122],
                            [23.692073601992462, 41.30908091894386],
                            [24.49264489105803, 41.58389618587205],
                            [25.197201368925533, 41.23448598893066],
                            [26.106138136507184, 41.32889883072784],
                            [26.117041863720914, 41.82690460872473],
                            [26.604195590936282, 41.562114569661105],
                        ],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Hungary',
                country: 'HU',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [16.202298211337364, 46.85238597267696],
                        [16.534267612380376, 47.49617096616912],
                        [16.340584344150415, 47.71290192320123],
                        [16.90375410326726, 47.71486562762833],
                        [16.979666782304037, 48.123497015976305],
                        [17.48847293464982, 47.86746613218621],
                        [17.857132602620027, 47.758428860050365],
                        [18.696512892336926, 47.880953681014404],
                        [18.77702477384767, 48.081768296900634],
                        [19.17436486173989, 48.11137889260387],
                        [19.661363559658497, 48.26661489520866],
                        [19.769470656013112, 48.202691148463614],
                        [20.239054396249347, 48.32756724709692],
                        [20.473562045989866, 48.56285004332181],
                        [20.801293979584926, 48.623854071642384],
                        [21.872236362401736, 48.31997081155002],
                        [22.08560835133485, 48.42226430927179],
                        [22.640819939878753, 48.15023956968735],
                        [22.710531447040495, 47.88219391538941],
                        [22.099767693782834, 47.6724392767167],
                        [21.62651492685387, 46.99423777931816],
                        [21.02195234547125, 46.3160879583519],
                        [20.220192498462836, 46.127468980486555],
                        [19.596044549241583, 46.17172984474454],
                        [18.82983808764996, 45.90887767189193],
                        [18.45606245288286, 45.759481106136136],
                        [17.630066359129557, 45.95176911069419],
                        [16.8825150895953, 46.38063182228444],
                        [16.564808383864857, 46.50375092221983],
                        [16.370504998447416, 46.8413272161665],
                        [16.202298211337364, 46.85238597267696],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Croatia',
                country: 'HR',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [18.829838087650046, 45.908877671891844],
                        [19.072768995854176, 45.52151113543209],
                        [19.39047570158459, 45.236515611342384],
                        [19.00548628101012, 44.86023366960916],
                        [18.553214145591653, 45.08158966733145],
                        [17.861783481526402, 45.067740383477144],
                        [17.002146030351014, 45.233776760430935],
                        [16.534939406000206, 45.21160757097772],
                        [16.318156772535872, 45.00412669532591],
                        [15.959367303133376, 45.233776760430935],
                        [15.750026075918981, 44.81871165626256],
                        [16.23966027188453, 44.35114329688571],
                        [16.456442905348865, 44.04123973243128],
                        [16.91615644701733, 43.66772247982567],
                        [17.297373488034452, 43.44634064388736],
                        [17.674921502358984, 43.02856252702361],
                        [18.56, 42.65],
                        [18.450016310304818, 42.47999136002932],
                        [17.509970330483327, 42.849994615239154],
                        [16.930005730871642, 43.20999848080038],
                        [16.015384555737683, 43.50721548112722],
                        [15.174453973052096, 44.243191229827914],
                        [15.376250441151795, 44.31791535092208],
                        [14.920309279040508, 44.73848399512946],
                        [14.901602410550877, 45.07606028907611],
                        [14.258747592839995, 45.233776760430935],
                        [13.952254672917034, 44.80212352149687],
                        [13.656975538801191, 45.13693512631596],
                        [13.67940311041582, 45.48414907488501],
                        [13.715059848697251, 45.500323798192426],
                        [14.4119682145855, 45.46616567644742],
                        [14.595109490627918, 45.63494090431282],
                        [14.935243767972963, 45.471695054702764],
                        [15.327674594797427, 45.452316392593325],
                        [15.323953891672431, 45.731782538427694],
                        [15.671529575267641, 45.8341535507979],
                        [15.768732944408612, 46.23810822202353],
                        [16.564808383864943, 46.50375092221981],
                        [16.882515089595415, 46.38063182228444],
                        [17.630066359129557, 45.9517691106941],
                        [18.45606245288286, 45.75948110613615],
                        [18.829838087650046, 45.908877671891844],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Italy',
                country: 'IT',
            },
            geometry: {
                type: 'MultiPolygon',
                coordinates: [
                    [
                        [
                            [15.520376010813834, 38.23115509699147],
                            [15.160242954171736, 37.44404551853782],
                            [15.309897902089006, 37.1342194687318],
                            [15.099988234119449, 36.6199872909954],
                            [14.335228712632016, 36.996630967754754],
                            [13.826732618879928, 37.10453135838019],
                            [12.431003859108813, 37.61294993748381],
                            [12.570943637755136, 38.12638113051968],
                            [13.741156447004585, 38.03496552179536],
                            [14.76124922044616, 38.143873602850505],
                            [15.520376010813834, 38.23115509699147],
                        ],
                    ],
                    [
                        [
                            [9.210011834356266, 41.20999136002422],
                            [9.809975213264977, 40.5000088567661],
                            [9.669518670295673, 39.177376410471794],
                            [9.21481774255949, 39.240473334300134],
                            [8.80693566247973, 38.90661774347847],
                            [8.428302443077115, 39.17184703221662],
                            [8.38825320805094, 40.378310858718805],
                            [8.15999840661766, 40.95000722916379],
                            [8.709990675500109, 40.89998444270523],
                            [9.210011834356266, 41.20999136002422],
                        ],
                    ],
                    [
                        [
                            [12.376485223040843, 46.76755910906987],
                            [13.806475457421556, 46.50930613869119],
                            [13.698109978905478, 46.016778062517375],
                            [13.937630242578335, 45.591015936864665],
                            [13.141606479554298, 45.73669179949541],
                            [12.328581170306306, 45.38177806251485],
                            [12.383874952858605, 44.88537425391908],
                            [12.261453484759159, 44.600482082694015],
                            [12.589237094786483, 44.091365871754476],
                            [13.526905958722494, 43.5877273626379],
                            [14.029820997787027, 42.76100779883248],
                            [15.142569614327956, 41.955139675456905],
                            [15.926191033601896, 41.96131500911574],
                            [16.169897088290412, 41.74029490820342],
                            [15.889345737377797, 41.5410822617182],
                            [16.785001661860576, 41.179605617836586],
                            [17.519168735431208, 40.87714345963224],
                            [18.376687452882575, 40.35562490494266],
                            [18.4802470231954, 40.168866278639825],
                            [18.293385044028096, 39.81077444107325],
                            [17.738380161213286, 40.2776710068303],
                            [16.869595981522338, 40.44223460546385],
                            [16.448743116937322, 39.79540070246648],
                            [17.1714896989715, 39.42469981542072],
                            [17.05284061042934, 38.9028712021373],
                            [16.635088331781844, 38.8435724960824],
                            [16.100960727613057, 37.98589874933418],
                            [15.684086948314501, 37.90884918878703],
                            [15.687962680736321, 38.214592800441864],
                            [15.891981235424707, 38.750942491199226],
                            [16.109332309644312, 38.96454702407769],
                            [15.718813510814641, 39.544072374014945],
                            [15.413612501698822, 40.04835683853517],
                            [14.998495721098237, 40.17294871679093],
                            [14.70326826341477, 40.604550279292624],
                            [14.060671827865264, 40.78634796809544],
                            [13.627985060285397, 41.188287258461656],
                            [12.88808190273042, 41.25308950455562],
                            [12.10668257004491, 41.70453481705741],
                            [11.191906365614187, 42.35542531998967],
                            [10.511947869517797, 42.931462510747224],
                            [10.200028924204048, 43.920006822274615],
                            [9.702488234097814, 44.03627879493132],
                            [8.88894616052687, 44.36633616797954],
                            [8.428560825238577, 44.23122813575242],
                            [7.850766635783201, 43.76714793555524],
                            [7.435184767291843, 43.69384491634918],
                            [7.549596388386163, 44.12790110938482],
                            [7.007562290076663, 44.25476675066139],
                            [6.749955275101711, 45.02851797136759],
                            [7.096652459347837, 45.333098863295874],
                            [6.802355177445662, 45.70857982032867],
                            [6.843592970414562, 45.99114655210067],
                            [7.273850945676685, 45.77694774025076],
                            [7.755992058959833, 45.82449005795928],
                            [8.31662967289438, 46.163642483090854],
                            [8.489952426801295, 46.00515086525175],
                            [8.966305779667834, 46.036931871111165],
                            [9.182881707403112, 46.44021474871698],
                            [9.922836541390353, 46.31489940040919],
                            [10.363378126678668, 46.483571275409844],
                            [10.442701450246602, 46.893546250997446],
                            [11.048555942436508, 46.7513585475464],
                            [11.164827915093326, 46.94157949481274],
                            [12.153088006243081, 47.11539317482644],
                            [12.376485223040843, 46.76755910906987],
                        ],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Kosovo',
                country: 'KOS',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [20.76216, 42.05186],
                        [20.71731000000011, 41.84711],
                        [20.59023, 41.85541],
                        [20.52295, 42.21787],
                        [20.28374, 42.3202500000001],
                        [20.0707, 42.58863],
                        [20.25758, 42.81275000000011],
                        [20.49679, 42.88469],
                        [20.63508, 43.21671],
                        [20.81448, 43.27205],
                        [20.95651, 43.13094],
                        [21.143395, 43.06868500000012],
                        [21.27421, 42.90959],
                        [21.43866, 42.86255],
                        [21.63302, 42.67717],
                        [21.77505, 42.6827],
                        [21.66292, 42.43922],
                        [21.54332, 42.3202500000001],
                        [21.57663598940212, 42.24522439706186],
                        [21.35270000000014, 42.2068],
                        [20.76216, 42.05186],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Lithuania',
                country: 'LT',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [22.731098667092652, 54.327536932993326],
                        [22.65105187347254, 54.582740993866736],
                        [22.75776370615526, 54.85657440858138],
                        [22.315723504330577, 55.015298570365864],
                        [21.268448927503467, 55.190481675835315],
                        [21.055800408622414, 56.03107636171106],
                        [22.201156853939494, 56.33780182557948],
                        [23.878263787539964, 56.273671373105266],
                        [24.860684441840757, 56.37252838807963],
                        [25.000934279080894, 56.16453074810484],
                        [25.533046502390334, 56.10029694276603],
                        [26.494331495883753, 55.615106919977634],
                        [26.58827924979039, 55.16717560487167],
                        [25.7684326514798, 54.84696259217509],
                        [25.536353794056993, 54.28242340760253],
                        [24.450683628037037, 53.905702216194754],
                        [23.48412763844985, 53.91249766704114],
                        [23.24398725758951, 54.22056671814914],
                        [22.731098667092652, 54.327536932993326],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Macedonia',
                country: 'MK',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [20.59023, 41.85541],
                        [20.71731000000011, 41.84711],
                        [20.76216, 42.05186],
                        [21.35270000000014, 42.2068],
                        [21.57663598940212, 42.24522439706186],
                        [21.917080000000112, 42.30364],
                        [22.38052575042468, 42.32025950781508],
                        [22.881373732197346, 41.999297186850356],
                        [22.952377150166512, 41.33799388281119],
                        [22.76177, 41.3048],
                        [22.597308383889015, 41.130487168943205],
                        [22.05537763844427, 41.14986583105269],
                        [21.674160597426976, 40.93127452245795],
                        [21.0200403174764, 40.84272695572588],
                        [20.60518, 41.08622],
                        [20.46315, 41.5150900000001],
                        [20.59023, 41.85541],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Iceland',
                country: 'IS',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-14.508695441129236, 66.45589223903141],
                        [-14.739637417041605, 65.8087482774403],
                        [-13.60973222497981, 65.12667104761987],
                        [-14.909833746794902, 64.36408193628868],
                        [-17.794438035543422, 63.67874909123385],
                        [-18.656245896874992, 63.49638296167582],
                        [-19.97275468594276, 63.64363495549153],
                        [-22.762971971110158, 63.960178941495386],
                        [-21.778484259517683, 64.40211579045551],
                        [-23.95504391121911, 64.89112986923348],
                        [-22.184402635170358, 65.0849681667603],
                        [-22.227423265053332, 65.37859365504272],
                        [-24.326184047939336, 65.61118927678847],
                        [-23.65051469572309, 66.26251902939522],
                        [-22.134922451250883, 66.41046865504687],
                        [-20.57628373867955, 65.73211212835143],
                        [-19.05684160000159, 66.27660085719477],
                        [-17.79862382655905, 65.99385325790978],
                        [-16.167818976292125, 66.52679230413587],
                        [-14.508695441129236, 66.45589223903141],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Luxembourg',
                country: 'LU',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [6.043073357781111, 50.128051662794235],
                        [6.242751092156993, 49.90222565367873],
                        [6.186320428094177, 49.463802802114515],
                        [5.897759230176405, 49.44266714130703],
                        [5.674051954784829, 49.529483547557504],
                        [5.782417433300906, 50.09032786722122],
                        [6.043073357781111, 50.128051662794235],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Latvia',
                country: 'LV',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [21.055800408622414, 56.03107636171106],
                        [21.09042361825797, 56.78387278912293],
                        [21.581866489353672, 57.41187063254993],
                        [22.524341261492875, 57.75337433535076],
                        [23.318452996522097, 57.00623647727487],
                        [24.12072960785343, 57.02569265403277],
                        [24.312862583114622, 57.79342357037698],
                        [25.16459354014927, 57.97015696881519],
                        [25.602809685984365, 57.84752879498657],
                        [26.463532342237787, 57.47638865826633],
                        [27.28818484875151, 57.47452830670383],
                        [27.77001590344093, 57.24425812441123],
                        [27.855282016722526, 56.75932648378429],
                        [28.176709425577993, 56.16912995057881],
                        [27.10245975109453, 55.783313707087686],
                        [26.494331495883753, 55.615106919977634],
                        [25.533046502390334, 56.10029694276603],
                        [25.000934279080894, 56.16453074810484],
                        [24.860684441840757, 56.37252838807963],
                        [23.878263787539964, 56.273671373105266],
                        [22.201156853939494, 56.33780182557948],
                        [21.055800408622414, 56.03107636171106],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Moldova',
                country: 'MD',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [26.619336785597795, 48.22072622333347],
                        [26.857823520624805, 48.368210761094495],
                        [27.522537469195154, 48.467119452501116],
                        [28.259546746541844, 48.15556224221342],
                        [28.670891147585163, 48.1181485052341],
                        [29.12269819511303, 47.84909516050646],
                        [29.05086795422733, 47.51022695575249],
                        [29.41513512545274, 47.34664520933257],
                        [29.559674106573112, 46.928582872091326],
                        [29.908851759569302, 46.67436066343146],
                        [29.838210076626297, 46.52532583270169],
                        [30.02465864433537, 46.42393667254503],
                        [29.75997195813639, 46.34998769793536],
                        [29.170653924279886, 46.3792623968287],
                        [29.072106967899295, 46.517677720722496],
                        [28.862972446414062, 46.43788930926383],
                        [28.93371748222162, 46.2588304713725],
                        [28.659987420371575, 45.93998688413164],
                        [28.485269402792767, 45.5969070501459],
                        [28.233553501099042, 45.48828318946837],
                        [28.0544429867754, 45.944586086605625],
                        [28.160017937947714, 46.37156260841722],
                        [28.128030226359044, 46.810476386088254],
                        [27.551166212684848, 47.40511709247083],
                        [27.233872918412743, 47.82677094175638],
                        [26.924176059687568, 48.123264472030996],
                        [26.619336785597795, 48.22072622333347],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Montenegro',
                country: 'ME',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [19.801613396898688, 42.50009349219084],
                        [19.738051385179627, 42.688247382165564],
                        [19.3044900000001, 42.19574],
                        [19.37177000000014, 41.87755],
                        [19.16246, 41.95502],
                        [18.88214, 42.28151],
                        [18.45, 42.48],
                        [18.56, 42.65],
                        [18.70648, 43.20011],
                        [19.03165, 43.43253],
                        [19.21852, 43.52384],
                        [19.48389, 43.35229],
                        [19.63, 43.21377997027054],
                        [19.95857, 43.10604],
                        [20.3398, 42.89852],
                        [20.25758, 42.81275000000011],
                        [20.0707, 42.58863],
                        [19.801613396898688, 42.50009349219084],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Norway',
                country: 'NO',
            },
            geometry: {
                type: 'MultiPolygon',
                coordinates: [
                    [
                        [
                            [28.165547316202915, 71.18547435168051],
                            [31.29341840996548, 70.45378774685992],
                            [30.005435011522792, 70.1862588568849],
                            [31.10107872897512, 69.55808014594486],
                            [29.399580519332886, 69.15691600206307],
                            [28.591929559043194, 69.0647769232867],
                            [29.01557295097197, 69.76649119737797],
                            [27.73229210786789, 70.16419302029628],
                            [26.1796220232263, 69.82529897732616],
                            [25.68921268077639, 69.09211375596902],
                            [24.73567915212672, 68.64955678982145],
                            [23.662049594830762, 68.89124746365053],
                            [22.356237827247412, 68.84174144151494],
                            [21.24493615081073, 69.37044302029312],
                            [20.64559288908958, 69.10624726020085],
                            [20.025268995857914, 69.06513865831272],
                            [19.878559604581255, 68.40719432237262],
                            [17.99386844246439, 68.56739126247734],
                            [17.729181756265348, 68.01055186631623],
                            [16.76887861498554, 68.01393667263139],
                            [16.108712192456835, 67.3024555528369],
                            [15.108411492583059, 66.19386688909543],
                            [13.55568973150909, 64.78702769638147],
                            [13.919905226302205, 64.44542064071611],
                            [13.57191613124877, 64.04911408146967],
                            [12.57993533697393, 64.06621898055835],
                            [11.93056928879423, 63.128317572676984],
                            [11.992064243221535, 61.800362453856565],
                            [12.631146681375242, 61.29357168237009],
                            [12.3003658382749, 60.11793284773006],
                            [11.468271925511175, 59.432393296946],
                            [11.027368605196926, 58.856149400459394],
                            [10.356556837616097, 59.46980703392538],
                            [8.382000359743643, 58.31328847923328],
                            [7.048748406613299, 58.07888418235728],
                            [5.665835402050419, 58.58815542259367],
                            [5.308234490590735, 59.66323191999382],
                            [4.992078077829007, 61.970998033284275],
                            [5.912900424837885, 62.614472968182696],
                            [8.553411085655766, 63.45400828719647],
                            [10.527709181366788, 64.48603831649748],
                            [12.358346795306375, 65.87972585719316],
                            [14.761145867581604, 67.81064158799515],
                            [16.43592736172897, 68.56320547146169],
                            [19.184028354578516, 69.81744415961782],
                            [21.378416375420613, 70.25516937934606],
                            [23.023742303161583, 70.20207184516626],
                            [24.546543409938522, 71.03049673123724],
                            [26.370049676221807, 70.98626170519537],
                            [28.165547316202915, 71.18547435168051],
                        ],
                    ],
                    [
                        [
                            [24.72412, 77.85385],
                            [22.49032, 77.44493],
                            [20.72601, 77.67704],
                            [21.41611, 77.93504],
                            [20.8119, 78.25463],
                            [22.88426, 78.45494],
                            [23.28134, 78.07954],
                            [24.72412, 77.85385],
                        ],
                    ],
                    [
                        [
                            [18.25183, 79.70175],
                            [21.54383, 78.95611],
                            [19.02737, 78.5626],
                            [18.47172, 77.82669],
                            [17.59441, 77.63796],
                            [17.1182, 76.80941],
                            [15.91315, 76.77045],
                            [13.76259, 77.38035],
                            [14.66956, 77.73565],
                            [13.1706, 78.02493],
                            [11.22231, 78.8693],
                            [10.44453, 79.65239],
                            [13.17077, 80.01046],
                            [13.71852, 79.66039],
                            [15.14282, 79.67431],
                            [15.52255, 80.01608],
                            [16.99085, 80.05086],
                            [18.25183, 79.70175],
                        ],
                    ],
                    [
                        [
                            [25.447625359811894, 80.40734039989451],
                            [27.4075057309135, 80.05640574820046],
                            [25.92465050629818, 79.51783397085455],
                            [23.02446577321362, 79.4000117052291],
                            [20.075188429451885, 79.56682322866726],
                            [19.897266473070914, 79.84236196564751],
                            [18.462263624757924, 79.85988027619442],
                            [17.368015170977458, 80.31889618602702],
                            [20.455992059010697, 80.59815562613224],
                            [21.9079447771154, 80.35767934846209],
                            [22.919252557067438, 80.65714427359349],
                            [25.447625359811894, 80.40734039989451],
                        ],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Poland',
                country: 'PL',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [15.01699588385867, 51.10667409932158],
                        [14.607098422919535, 51.745188096719964],
                        [14.685026482815688, 52.0899474147552],
                        [14.4375997250022, 52.62485016540838],
                        [14.074521111719491, 52.98126251892543],
                        [14.353315463934138, 53.24817129171297],
                        [14.119686313542587, 53.75702912049103],
                        [14.802900424873458, 54.05070628520575],
                        [16.36347700365573, 54.513158677785725],
                        [17.622831658608675, 54.85153595643291],
                        [18.62085859546164, 54.68260569927078],
                        [18.696254510175464, 54.43871877706929],
                        [19.660640089606403, 54.42608388937393],
                        [20.892244500418624, 54.31252492941253],
                        [22.731098667092652, 54.327536932993326],
                        [23.24398725758951, 54.22056671814914],
                        [23.48412763844985, 53.91249766704114],
                        [23.527535841575002, 53.470121568406555],
                        [23.80493493011778, 53.089731350306074],
                        [23.799198846133375, 52.69109935160657],
                        [23.199493849386187, 52.486977444053664],
                        [23.508002150168693, 52.02364655212473],
                        [23.52707075368437, 51.57845408793023],
                        [24.029985792748903, 50.70540660257518],
                        [23.922757195743262, 50.42488108987875],
                        [23.426508416444392, 50.30850576435745],
                        [22.518450148211603, 49.47677358661974],
                        [22.776418898212626, 49.02739533140962],
                        [22.558137648211755, 49.085738023467144],
                        [21.607808058364213, 49.47010732685409],
                        [20.887955356538413, 49.32877228453583],
                        [20.41583947111985, 49.43145335549977],
                        [19.825022820726872, 49.21712535256923],
                        [19.320712517990472, 49.571574001659194],
                        [18.909574822676316, 49.435845852244576],
                        [18.853144158613617, 49.49622976337764],
                        [18.392913852622172, 49.98862864847075],
                        [17.64944502123899, 50.049038397819956],
                        [17.55456709155112, 50.36214590107641],
                        [16.868769158605655, 50.47397370055603],
                        [16.719475945714436, 50.21574656839354],
                        [16.176253289462267, 50.42260732685791],
                        [16.23862674323857, 50.69773265237984],
                        [15.490972120839727, 50.78472992614321],
                        [15.01699588385867, 51.10667409932158],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Netherlands',
                country: 'NL',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [6.074182570020923, 53.510403347378144],
                        [6.905139601274129, 53.48216217713064],
                        [7.092053256873896, 53.14404328064489],
                        [6.842869500362383, 52.22844025329755],
                        [6.589396599970826, 51.852029120483394],
                        [5.988658074577813, 51.851615709025054],
                        [6.156658155958779, 50.80372101501058],
                        [5.606975945670001, 51.037298488969775],
                        [4.973991326526914, 51.475023708698124],
                        [4.047071160507527, 51.26725861266857],
                        [3.314971144228537, 51.34575511331991],
                        [3.830288527043137, 51.62054454203195],
                        [4.705997348661185, 53.09179840759776],
                        [6.074182570020923, 53.510403347378144],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Romania',
                country: 'RO',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [22.710531447040495, 47.88219391538941],
                        [23.142236362406802, 48.09634105080695],
                        [23.76095828623741, 47.985598456405455],
                        [24.40205610525038, 47.98187775328042],
                        [24.866317172960578, 47.73752574318831],
                        [25.20774336111299, 47.89105642352747],
                        [25.9459411964024, 47.987148749374214],
                        [26.19745039236693, 48.22088125263035],
                        [26.619336785597795, 48.22072622333347],
                        [26.924176059687568, 48.123264472030996],
                        [27.233872918412743, 47.82677094175638],
                        [27.551166212684848, 47.40511709247083],
                        [28.128030226359044, 46.810476386088254],
                        [28.160017937947714, 46.37156260841722],
                        [28.0544429867754, 45.944586086605625],
                        [28.233553501099042, 45.48828318946837],
                        [28.679779493939378, 45.304030870131704],
                        [29.149724969201653, 45.46492544207245],
                        [29.603289015427432, 45.293308010431126],
                        [29.626543409958767, 45.03539093686239],
                        [29.141611769331835, 44.82021027279904],
                        [28.837857700320203, 44.913873806328056],
                        [28.558081495891997, 43.70746165625813],
                        [27.970107049275075, 43.81246816667521],
                        [27.242399529740908, 44.175986029632405],
                        [26.065158725699746, 43.94349376075126],
                        [25.569271681426926, 43.68844472917472],
                        [24.100679152124172, 43.74105133724785],
                        [23.33230228037632, 43.89701080990471],
                        [22.944832391051847, 43.82378530534713],
                        [22.65714969248299, 44.23492300066128],
                        [22.4740084164406, 44.40922760678177],
                        [22.705725538837356, 44.57800283464702],
                        [22.459022251075936, 44.7025171982543],
                        [22.14508792490281, 44.47842234962059],
                        [21.562022739353605, 44.7689472519655],
                        [21.483526238702233, 45.18117015235778],
                        [20.874312778413355, 45.416375433934235],
                        [20.762174920339987, 45.734573065771436],
                        [20.220192498462836, 46.127468980486555],
                        [21.02195234547125, 46.3160879583519],
                        [21.62651492685387, 46.99423777931816],
                        [22.099767693782834, 47.6724392767167],
                        [22.710531447040495, 47.88219391538941],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Portugal',
                country: 'PT',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-9.034817674180246, 41.88057058365967],
                        [-8.67194576662672, 42.13468943945496],
                        [-8.263856980817792, 42.28046865495034],
                        [-8.013174607769912, 41.790886135417125],
                        [-7.422512986673795, 41.79207469335983],
                        [-7.251308966490824, 41.91834605566505],
                        [-6.668605515967656, 41.883386949219584],
                        [-6.389087693700915, 41.381815497394655],
                        [-6.851126674822552, 41.11108266861753],
                        [-6.864019944679385, 40.33087189387483],
                        [-7.026413133156595, 40.184524237624245],
                        [-7.066591559263529, 39.71189158788277],
                        [-7.498632371439725, 39.62957103124181],
                        [-7.098036668313128, 39.03007274022378],
                        [-7.374092169616318, 38.37305858006492],
                        [-7.029281175148796, 38.07576406508977],
                        [-7.166507941099865, 37.803894354802225],
                        [-7.537105475281024, 37.42890432387623],
                        [-7.453725551778092, 37.09778758396607],
                        [-7.855613165711985, 36.83826854099627],
                        [-8.382816127953689, 36.97888011326246],
                        [-8.898856980820327, 36.86880931248078],
                        [-8.746101446965554, 37.65134552667661],
                        [-8.839997524439879, 38.26624339451761],
                        [-9.287463751655224, 38.3584858261586],
                        [-9.526570603869715, 38.73742910415491],
                        [-9.446988898140232, 39.39206614842837],
                        [-9.048305223008427, 39.75509308527877],
                        [-8.977353481471681, 40.15930613866581],
                        [-8.768684047877102, 40.76063894303019],
                        [-8.79085323733031, 41.18433401139126],
                        [-8.99078935386757, 41.54345937760364],
                        [-9.034817674180246, 41.88057058365967],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Russia',
                country: 'RU',
            },
            geometry: {
                type: 'MultiPolygon',
                coordinates: [
                    [
                        [
                            [143.64800744036287, 50.74760040954151],
                            [144.65414757708564, 48.976390692737596],
                            [143.17392785051723, 49.30655141865037],
                            [142.5586682476501, 47.861575018904915],
                            [143.53349246640406, 46.83672801369249],
                            [143.5052771343726, 46.13790761980948],
                            [142.74770063697392, 46.74076487892657],
                            [142.0920300640545, 45.96675527605879],
                            [141.90692508358504, 46.80592886004655],
                            [142.0184428244709, 47.780132961612935],
                            [141.90444461483506, 48.85918854429956],
                            [142.13580000220568, 49.61516307229746],
                            [142.1799833518153, 50.95234243428192],
                            [141.59407596249002, 51.93543488220254],
                            [141.68254601457366, 53.30196645772878],
                            [142.60693403541075, 53.762145087287905],
                            [142.2097489768154, 54.22547597921687],
                            [142.654786411713, 54.36588084575388],
                            [142.91461551327657, 53.704577541714734],
                            [143.26084760963207, 52.74076040303905],
                            [143.23526777564766, 51.75666026468875],
                            [143.64800744036287, 50.74760040954151],
                        ],
                    ],
                    [
                        [
                            [22.731098667092652, 54.327536932993326],
                            [20.892244500418652, 54.312524929412575],
                            [19.660640089606403, 54.426083889373984],
                            [19.888481479581344, 54.8661603867715],
                            [21.2684489275035, 55.19048167583528],
                            [22.315723504330606, 55.0152985703659],
                            [22.757763706155288, 54.85657440858142],
                            [22.651051873472568, 54.58274099386671],
                            [22.731098667092652, 54.327536932993326],
                        ],
                    ],
                    [
                        [
                            [-175.01425, 66.58435],
                            [-174.33983, 66.33556],
                            [-174.57182, 67.06219],
                            [-171.85731, 66.91308],
                            [-169.89958, 65.97724],
                            [-170.89107, 65.54139],
                            [-172.53025, 65.43791],
                            [-172.555, 64.46079],
                            [-172.95533, 64.25269],
                            [-173.89184, 64.2826],
                            [-174.65392, 64.63125],
                            [-175.98353, 64.92288],
                            [-176.20716, 65.35667],
                            [-177.22266, 65.52024],
                            [-178.35993, 65.39052],
                            [-178.90332, 65.74044],
                            [-178.68611, 66.11211],
                            [-179.88377, 65.87456],
                            [-179.43268, 65.40411],
                            [-180, 64.97970870219837],
                            [-180, 68.96363636363635],
                            [-177.55, 68.2],
                            [-174.92825, 67.20589],
                            [-175.01425, 66.58435],
                        ],
                    ],
                    [
                        [
                            [180.00000000000014, 70.83219920854668],
                            [178.9034250000001, 70.78114],
                            [178.7253, 71.0988],
                            [180.00000000000014, 71.51571433642826],
                            [180.00000000000014, 70.83219920854668],
                        ],
                    ],
                    [
                        [
                            [-178.69378, 70.89302],
                            [-180, 70.83219920854668],
                            [-180, 71.51571433642826],
                            [-179.871875, 71.55762],
                            [-179.02433, 71.55553],
                            [-177.577945, 71.26948],
                            [-177.663575, 71.13277],
                            [-178.69378, 70.89302],
                        ],
                    ],
                    [
                        [
                            [143.60385, 73.21244],
                            [142.08763, 73.20544],
                            [140.038155, 73.31692],
                            [139.86312, 73.36983],
                            [140.81171, 73.76506],
                            [142.06207, 73.85758],
                            [143.48283, 73.47525],
                            [143.60385, 73.21244],
                        ],
                    ],
                    [
                        [
                            [150.73167, 75.08406],
                            [149.575925, 74.68892],
                            [147.977465, 74.778355],
                            [146.11919, 75.17298],
                            [146.358485, 75.49682],
                            [148.22223, 75.345845],
                            [150.73167, 75.08406],
                        ],
                    ],
                    [
                        [
                            [145.086285, 75.562625],
                            [144.3, 74.82],
                            [140.61381, 74.84768],
                            [138.95544, 74.61148],
                            [136.97439, 75.26167],
                            [137.51176, 75.94917],
                            [138.831075, 76.13676],
                            [141.471615, 76.09289],
                            [145.086285, 75.562625],
                        ],
                    ],
                    [
                        [
                            [57.5356925799924, 70.72046397570216],
                            [56.94497928246395, 70.63274323188668],
                            [53.6773751157842, 70.76265778266847],
                            [53.41201663596539, 71.2066616889202],
                            [51.60189456564572, 71.47475901965049],
                            [51.45575361512422, 72.01488108996514],
                            [52.47827518088357, 72.22944163684096],
                            [52.444168735570855, 72.77473135038485],
                            [54.42761355979766, 73.62754751249759],
                            [53.50828982932515, 73.74981395130015],
                            [55.90245893740766, 74.62748647734533],
                            [55.631932814359715, 75.08141225859717],
                            [57.86864383324885, 75.60939036732321],
                            [61.170044386647504, 76.25188345000814],
                            [64.49836836127022, 76.43905548776928],
                            [66.2109770038551, 76.80978221303124],
                            [68.15705976753483, 76.93969676381292],
                            [68.85221113472512, 76.54481130645462],
                            [68.18057254422766, 76.23364166940911],
                            [64.637326287703, 75.73775462513623],
                            [61.58350752141476, 75.2608845079468],
                            [58.47708214705338, 74.30905630156283],
                            [56.98678551618801, 73.33304352486624],
                            [55.419335971910954, 72.37126760526598],
                            [55.622837762276305, 71.54059479439033],
                            [57.5356925799924, 70.72046397570216],
                        ],
                    ],
                    [
                        [
                            [106.97013000000013, 76.97419],
                            [107.24000000000015, 76.48],
                            [108.1538, 76.72335000000015],
                            [111.07726000000017, 76.71],
                            [113.33151, 76.22224],
                            [114.13417, 75.84764],
                            [113.88539, 75.32779000000014],
                            [112.77918, 75.03186],
                            [110.1512500000002, 74.47673],
                            [109.4, 74.18],
                            [110.64, 74.04],
                            [112.11919, 73.78774000000011],
                            [113.01954000000026, 73.97693000000015],
                            [113.52958000000032, 73.33505000000011],
                            [113.96881, 73.59488],
                            [115.56782, 73.75285],
                            [118.77633000000023, 73.58772],
                            [119.02, 73.12],
                            [123.20066000000011, 72.97122],
                            [123.25777000000018, 73.73503000000011],
                            [125.38000000000018, 73.56],
                            [126.97644, 73.56549],
                            [128.59126, 73.03871],
                            [129.05157, 72.39872],
                            [128.46000000000012, 71.98],
                            [129.7159900000002, 71.19304],
                            [131.28858000000028, 70.78699000000012],
                            [132.25350000000017, 71.83630000000011],
                            [133.85766000000032, 71.38642000000016],
                            [135.56193, 71.65525000000014],
                            [137.49755, 71.34763],
                            [138.23409000000018, 71.62803],
                            [139.86983000000012, 71.48783000000014],
                            [139.14791, 72.4161900000001],
                            [140.46817, 72.84941000000013],
                            [149.5, 72.2],
                            [150.3511800000002, 71.60643],
                            [152.96890000000022, 70.84222],
                            [157.00688, 71.03141],
                            [158.99779, 70.86672],
                            [159.83031000000025, 70.45324],
                            [159.70866, 69.72198],
                            [160.94053000000034, 69.4372800000001],
                            [162.27907000000013, 69.64204],
                            [164.05248000000014, 69.66823],
                            [165.94037000000023, 69.47199],
                            [167.83567, 69.58269],
                            [169.5776300000002, 68.6938],
                            [170.81688000000028, 69.01363],
                            [170.0082000000002, 69.65276],
                            [170.4534500000003, 70.09703],
                            [173.64391000000026, 69.81743],
                            [175.72403000000023, 69.87725000000023],
                            [178.6, 69.4],
                            [180.00000000000014, 68.96363636363657],
                            [180.00000000000014, 64.97970870219848],
                            [179.99281, 64.97433],
                            [178.70720000000026, 64.53493],
                            [177.41128000000018, 64.60821],
                            [178.31300000000024, 64.07593],
                            [178.9082500000002, 63.251970000000135],
                            [179.37034, 62.982620000000104],
                            [179.48636, 62.56894],
                            [179.22825000000014, 62.30410000000015],
                            [177.3643, 62.5219],
                            [174.56929000000022, 61.76915],
                            [173.68013, 61.65261],
                            [172.15, 60.95],
                            [170.6985000000001, 60.33618],
                            [170.3308500000003, 59.88177],
                            [168.90046, 60.57355],
                            [166.29498000000032, 59.788550000000214],
                            [165.84000000000023, 60.16],
                            [164.87674, 59.7316],
                            [163.53929000000014, 59.86871],
                            [163.21711000000025, 59.21101],
                            [162.0173300000001, 58.24328],
                            [162.05297, 57.83912],
                            [163.19191, 57.61503000000011],
                            [163.05794000000017, 56.159240000000125],
                            [162.12958000000023, 56.12219],
                            [161.70146, 55.285680000000156],
                            [162.11749000000017, 54.85514],
                            [160.36877000000032, 54.34433],
                            [160.02173000000022, 53.20257],
                            [158.5309400000002, 52.958680000000236],
                            [158.23118, 51.94269],
                            [156.7897900000003, 51.01105],
                            [156.42000000000016, 51.7],
                            [155.99182, 53.15895],
                            [155.43366000000012, 55.38103000000012],
                            [155.91442000000032, 56.767920000000146],
                            [156.75815, 57.3647],
                            [156.8103500000001, 57.83204],
                            [158.3643300000002, 58.05575],
                            [160.15064000000012, 59.31477000000012],
                            [161.87204, 60.34300000000013],
                            [163.66969, 61.1409],
                            [164.47355000000013, 62.55061],
                            [163.2584200000002, 62.46627],
                            [162.65791, 61.6425],
                            [160.1214800000001, 60.54423],
                            [159.30232, 61.77396],
                            [156.7206800000001, 61.43442],
                            [154.21806000000035, 59.75818000000013],
                            [155.04375, 59.14495],
                            [152.81185, 58.88385],
                            [151.26573000000025, 58.78089],
                            [151.33815000000013, 59.50396],
                            [149.78371, 59.65573000000014],
                            [148.54481, 59.16448],
                            [145.48722, 59.33637],
                            [142.19782000000018, 59.03998],
                            [138.95848000000032, 57.08805],
                            [135.12619, 54.72959],
                            [136.70171, 54.603550000000126],
                            [137.19342, 53.97732],
                            [138.1647, 53.755010000000254],
                            [138.80463, 54.25455000000011],
                            [139.90151, 54.18968000000018],
                            [141.34531, 53.08957000000012],
                            [141.37923, 52.23877],
                            [140.5974200000002, 51.2396700000001],
                            [140.51308, 50.04553000000013],
                            [140.06193000000022, 48.44671000000017],
                            [138.5547200000002, 46.99965],
                            [138.21971, 46.30795],
                            [136.86232, 45.14350000000019],
                            [135.5153500000002, 43.989],
                            [134.86939000000027, 43.39821],
                            [133.53687000000028, 42.81147],
                            [132.90627000000015, 42.79849],
                            [132.27807000000027, 43.28456000000011],
                            [130.93587000000014, 42.55274],
                            [130.78, 42.22000000000019],
                            [130.64000000000019, 42.395],
                            [130.6338664084098, 42.90301463477056],
                            [131.144687941615, 42.92998973242695],
                            [131.28855512911562, 44.111519680348266],
                            [131.02519000000026, 44.96796],
                            [131.8834542176596, 45.32116160743652],
                            [133.09712000000022, 45.14409],
                            [133.7696439963132, 46.116926988299156],
                            [134.1123500000002, 47.21248000000014],
                            [134.50081, 47.578450000000146],
                            [135.0263114767868, 48.47822988544391],
                            [133.37359581922803, 48.18344167743484],
                            [132.50669000000013, 47.78896],
                            [130.98726000000013, 47.79013],
                            [130.58229332898267, 48.729687404976204],
                            [129.3978178244205, 49.440600084015614],
                            [127.65740000000038, 49.76027],
                            [127.28745568248493, 50.73979726826545],
                            [126.93915652883786, 51.3538941514059],
                            [126.56439904185699, 51.7842554795327],
                            [125.94634891164647, 52.79279857035695],
                            [125.06821129771045, 53.161044826868924],
                            [123.57147, 53.4588],
                            [122.24574791879307, 53.43172597921369],
                            [121.00308475147037, 53.25140106873124],
                            [120.1770886577169, 52.75388621684121],
                            [120.725789015792, 52.51622630473091],
                            [120.7382, 51.96411],
                            [120.18208000000018, 51.64355],
                            [119.27939, 50.58292],
                            [119.28846072802585, 50.14288279886196],
                            [117.8792444194265, 49.51098338479704],
                            [116.67880089728621, 49.888531399121405],
                            [115.48569542853144, 49.80517731383475],
                            [114.96210981655038, 50.14024730081513],
                            [114.36245649623534, 50.248302720737485],
                            [112.89773969935439, 49.54356537535699],
                            [111.58123091028668, 49.37796824807767],
                            [110.66201053267886, 49.13012807880585],
                            [109.40244917199672, 49.29296051695769],
                            [108.47516727095127, 49.28254771585071],
                            [107.86817589725112, 49.79370514586588],
                            [106.88880415245532, 50.27429596618029],
                            [105.8865914245869, 50.406019192092174],
                            [104.62158, 50.275320000000164],
                            [103.67654544476036, 50.089966132195144],
                            [102.25589000000011, 50.51056000000011],
                            [102.06521, 51.25991],
                            [100.88948042196265, 51.51685578063842],
                            [99.98173221232356, 51.63400625264395],
                            [98.8614905131005, 52.04736603454671],
                            [97.82573978067452, 51.01099518493325],
                            [98.23176150919173, 50.42240062112873],
                            [97.25976000000023, 49.72605],
                            [95.81402000000017, 49.977460000000114],
                            [94.81594933469879, 50.01343333597088],
                            [94.14756635943561, 50.48053660745716],
                            [93.10421, 50.49529],
                            [92.23471154171969, 50.80217072204175],
                            [90.71366743364078, 50.331811835321105],
                            [88.80556684769559, 49.47052073831247],
                            [87.75126427607685, 49.29719798440556],
                            [87.35997033076269, 49.21498078062916],
                            [86.82935672398966, 49.82667470966813],
                            [85.5412699726825, 49.69285858824816],
                            [85.11555952346211, 50.11730296487763],
                            [84.41637739455304, 50.311399644565824],
                            [83.93511478061893, 50.88924551045358],
                            [83.38300377801247, 51.069182847693895],
                            [81.94598554883994, 50.81219594990633],
                            [80.56844689323546, 51.38833649352844],
                            [80.03555952344172, 50.864750881547224],
                            [77.80091556184433, 53.40441498474754],
                            [76.52517947785478, 54.177003485727134],
                            [76.89110029491346, 54.49052440044193],
                            [74.38482000000013, 53.54685000000011],
                            [73.42567874542053, 53.489810289109755],
                            [73.50851606638437, 54.035616766976595],
                            [72.22415001820221, 54.37665538188679],
                            [71.1801310566095, 54.133285224008254],
                            [70.86526655465516, 55.169733588270105],
                            [69.06816694527289, 55.3852501491435],
                            [68.1691003762589, 54.97039175070438],
                            [65.6668700000001, 54.601250000000164],
                            [65.17853356309595, 54.35422781027208],
                            [61.43660000000013, 54.00625],
                            [60.97806644068325, 53.66499339457914],
                            [61.699986199800634, 52.97999644633427],
                            [60.739993117114544, 52.71998647725775],
                            [60.92726850774025, 52.447548326215006],
                            [59.96753380721557, 51.960420437215674],
                            [61.58800337102414, 51.272658799843185],
                            [61.33742435084101, 50.79907013610426],
                            [59.93280724471557, 50.842194118851836],
                            [59.64228234237057, 50.545442206415714],
                            [58.36332000000013, 51.06364],
                            [56.77798, 51.04355],
                            [55.71694000000011, 50.62171000000015],
                            [54.532878452376195, 51.02623973245937],
                            [52.32872358583106, 51.718652248738096],
                            [50.76664839051219, 51.69276235615987],
                            [48.702381626181044, 50.60512848571284],
                            [48.577841424357615, 49.874759629915644],
                            [47.549480421749394, 50.454698391311126],
                            [46.75159630716277, 49.35600576435374],
                            [47.0436715024766, 49.152038886097586],
                            [46.46644575377629, 48.39415233010493],
                            [47.31524000000016, 47.71585],
                            [48.05725, 47.74377],
                            [48.694733514201886, 47.0756281601779],
                            [48.593250000000154, 46.561040000000105],
                            [49.101160000000135, 46.39933],
                            [48.64541000000011, 45.80629],
                            [47.67591, 45.64149000000012],
                            [46.68201, 44.6092000000001],
                            [47.59094, 43.66016000000013],
                            [47.49252, 42.98658],
                            [48.58437000000018, 41.80888],
                            [47.98728315612604, 41.4058192001944],
                            [47.81566572448466, 41.151416124021345],
                            [47.373315464066394, 41.21973236751114],
                            [46.686070591016716, 41.827137152669906],
                            [46.40495079934894, 41.860675157227426],
                            [45.7764, 42.09244000000024],
                            [45.470279168485916, 42.50278066667005],
                            [44.53762291848207, 42.711992702803684],
                            [43.93121000000011, 42.5549600000001],
                            [43.755990000000196, 42.74083],
                            [42.39440000000016, 43.2203],
                            [40.92219000000014, 43.38215000000014],
                            [40.07696495947985, 43.553104153002494],
                            [39.955008579271095, 43.434997666999294],
                            [38.68, 44.28],
                            [37.53912000000011, 44.65721],
                            [36.67546000000013, 45.24469],
                            [37.40317, 45.4045100000001],
                            [38.23295, 46.24087],
                            [37.67372, 46.63657],
                            [39.14767, 47.04475000000013],
                            [39.12120000000013, 47.26336],
                            [38.22353803889948, 47.10218984637598],
                            [38.25511233902981, 47.54640045835697],
                            [38.77057, 47.82562000000024],
                            [39.738277622238996, 47.89893707945208],
                            [39.89562000000015, 48.23241],
                            [39.67465, 48.783820000000134],
                            [40.08078901546949, 49.30742991799937],
                            [40.069040000000115, 49.60105],
                            [38.59498823421356, 49.92646190042373],
                            [38.010631137857075, 49.91566152607473],
                            [37.39345950699524, 50.38395335550368],
                            [36.626167840325394, 50.225590928745135],
                            [35.35611616388812, 50.57719737405915],
                            [35.37791, 50.77394],
                            [35.02218305841794, 51.2075723333715],
                            [34.2248157081544, 51.255993150428935],
                            [34.14197838719061, 51.566413479206204],
                            [34.39173058445723, 51.768881740925906],
                            [33.75269982273588, 52.33507457133166],
                            [32.71576053236716, 52.23846548116216],
                            [32.41205813978777, 52.28869497334977],
                            [32.15944000000022, 52.061250000000115],
                            [31.78597, 52.10168],
                            [31.54001834486226, 52.74205231384644],
                            [31.30520063652798, 53.0739958766733],
                            [31.49764, 53.16743000000014],
                            [32.304519484188376, 53.132726141972846],
                            [32.693643019346126, 53.35142080343214],
                            [32.405598585751164, 53.618045355842014],
                            [31.731272820774592, 53.79402944601202],
                            [31.791424187962406, 53.974638576872195],
                            [31.384472283663822, 54.15705638286238],
                            [30.75753380709878, 54.811770941784395],
                            [30.971835971813245, 55.081547756564134],
                            [30.87390913262007, 55.55097646750351],
                            [29.89629438652244, 55.7894632025305],
                            [29.37157189303079, 55.67009064393628],
                            [29.22951338066039, 55.91834422466641],
                            [28.17670942557794, 56.16912995057879],
                            [27.855282016722526, 56.75932648378438],
                            [27.770015903440992, 57.244258124411196],
                            [27.288184848751655, 57.47452830670392],
                            [27.71668582531578, 57.79189911562445],
                            [27.420150000000202, 58.72457000000014],
                            [28.131699253051863, 59.300825100331],
                            [27.98112, 59.47537],
                            [29.1177, 60.02805000000012],
                            [28.07, 60.50352000000015],
                            [30.211107212044652, 61.78002777774969],
                            [31.139991082491036, 62.357692776124445],
                            [31.51609215671127, 62.867687486412905],
                            [30.035872430142803, 63.552813625738565],
                            [30.444684686003736, 64.20445343693908],
                            [29.544429559047018, 64.94867157659056],
                            [30.21765, 65.80598],
                            [29.054588657352383, 66.94428620062203],
                            [29.977426385220696, 67.69829702419275],
                            [28.44594363781877, 68.36461294216399],
                            [28.591929559043365, 69.0647769232867],
                            [29.39955, 69.15692000000018],
                            [31.10108000000011, 69.55811],
                            [32.13272000000026, 69.90595000000025],
                            [33.77547, 69.30142000000012],
                            [36.51396, 69.06342],
                            [40.292340000000166, 67.9324],
                            [41.059870000000124, 67.4571300000001],
                            [41.12595000000019, 66.79158000000012],
                            [40.01583, 66.26618000000013],
                            [38.38295, 65.9995300000001],
                            [33.918710000000175, 66.75961],
                            [33.18444, 66.63253],
                            [34.81477, 65.90015000000014],
                            [34.87857425307877, 65.4362128770482],
                            [34.94391000000015, 64.41437000000016],
                            [36.23129, 64.10945],
                            [37.01273000000012, 63.84983000000011],
                            [37.14197000000016, 64.33471],
                            [36.539579035089815, 64.76446],
                            [37.17604000000014, 65.14322000000013],
                            [39.59345, 64.52079000000018],
                            [40.43560000000011, 64.76446],
                            [39.76260000000016, 65.49682],
                            [42.0930900000001, 66.47623],
                            [43.01604000000012, 66.41858],
                            [43.94975000000014, 66.06908],
                            [44.53226, 66.75634000000014],
                            [43.69839, 67.35245],
                            [44.18795000000014, 67.95051],
                            [43.45282, 68.57079],
                            [46.25000000000014, 68.25],
                            [46.82134000000016, 67.68997],
                            [45.55517, 67.56652],
                            [45.5620200000001, 67.01005000000019],
                            [46.34915000000015, 66.6676700000001],
                            [47.894160000000255, 66.88455000000016],
                            [48.13876, 67.52238],
                            [50.22766000000016, 67.99867000000013],
                            [53.71743000000018, 68.85738000000012],
                            [54.47171, 68.80815],
                            [53.48582000000013, 68.20131],
                            [54.72628, 68.09702],
                            [55.44268000000014, 68.43866],
                            [57.317020000000156, 68.46628],
                            [58.80200000000021, 68.88082],
                            [59.94142000000019, 68.2784400000001],
                            [61.07784000000018, 68.94069],
                            [60.03, 69.52],
                            [60.55, 69.85],
                            [63.50400000000016, 69.54739],
                            [64.888115, 69.23483500000013],
                            [68.51216000000014, 68.09233000000017],
                            [69.18068, 68.61563000000012],
                            [68.16444, 69.14436],
                            [68.13522, 69.35649],
                            [66.93008000000012, 69.45461000000012],
                            [67.25976, 69.92873],
                            [66.72492000000014, 70.70889000000014],
                            [66.69466, 71.02897000000024],
                            [68.54006000000011, 71.93450000000024],
                            [69.19636000000011, 72.84336000000016],
                            [69.94, 73.04000000000013],
                            [72.58754, 72.7762900000001],
                            [72.79603, 72.22006],
                            [71.8481100000001, 71.40898],
                            [72.47011, 71.09019],
                            [72.79188, 70.39114],
                            [72.56470000000022, 69.02085],
                            [73.66787, 68.4079],
                            [73.2387, 67.7404],
                            [71.28000000000011, 66.32000000000016],
                            [72.42301000000018, 66.17267000000018],
                            [72.82077, 66.53267],
                            [73.92099000000016, 66.78946000000013],
                            [74.1865100000002, 67.28429],
                            [75.052, 67.76047000000017],
                            [74.46926000000016, 68.32899],
                            [74.93584000000013, 68.98918],
                            [73.84236, 69.07146],
                            [73.60187000000022, 69.62763],
                            [74.3998, 70.63175],
                            [73.1011, 71.44717000000026],
                            [74.89082000000022, 72.12119],
                            [74.65926, 72.83227],
                            [75.15801000000019, 72.85497000000011],
                            [75.68351, 72.30056000000013],
                            [75.28898000000012, 71.33556],
                            [76.35911, 71.15287000000015],
                            [75.90313000000017, 71.87401],
                            [77.57665000000011, 72.26717],
                            [79.65202000000014, 72.32011],
                            [81.5, 71.75],
                            [80.61071000000013, 72.58285000000012],
                            [80.51109, 73.6482],
                            [82.25, 73.85000000000011],
                            [84.65526, 73.80591000000018],
                            [86.82230000000024, 73.93688],
                            [86.00956, 74.45967000000014],
                            [87.16682000000017, 75.11643],
                            [88.31571000000011, 75.14393],
                            [90.26, 75.64],
                            [92.90058, 75.77333],
                            [93.23421000000016, 76.0472],
                            [95.86000000000016, 76.14],
                            [96.67821, 75.91548],
                            [98.92254000000023, 76.44689],
                            [100.75967000000023, 76.43028],
                            [101.03532, 76.86189],
                            [101.99084000000013, 77.2875400000002],
                            [104.3516000000001, 77.69792],
                            [106.06664000000013, 77.37389],
                            [104.70500000000024, 77.1274],
                            [106.97013000000013, 76.97419],
                        ],
                    ],
                    [
                        [
                            [105.07547, 78.30689],
                            [99.43814, 77.921],
                            [101.2649, 79.23399],
                            [102.08635, 79.34641],
                            [102.837815, 79.28129],
                            [105.37243, 78.71334],
                            [105.07547, 78.30689],
                        ],
                    ],
                    [
                        [
                            [51.13618655783128, 80.54728017854093],
                            [49.79368452332071, 80.4154277615482],
                            [48.89441124857754, 80.3395667589437],
                            [48.754936557821765, 80.17546824820084],
                            [47.586119012244154, 80.01018117951533],
                            [46.502825962109654, 80.24724681265437],
                            [47.07245527526291, 80.55942414012945],
                            [44.846958042181114, 80.58980988231718],
                            [46.79913862487123, 80.77191762971364],
                            [48.318477410684665, 80.78400991486996],
                            [48.522806023966695, 80.51456899690015],
                            [49.09718956889091, 80.75398590770843],
                            [50.03976769389462, 80.91888540315182],
                            [51.52293297710369, 80.6997256538019],
                            [51.13618655783128, 80.54728017854093],
                        ],
                    ],
                    [
                        [
                            [99.93976, 78.88094],
                            [97.75794, 78.7562],
                            [94.97259, 79.044745],
                            [93.31288, 79.4265],
                            [92.5454, 80.14379],
                            [91.18107, 80.34146],
                            [93.77766, 81.0246],
                            [95.940895, 81.2504],
                            [97.88385, 80.746975],
                            [100.186655, 79.780135],
                            [99.93976, 78.88094],
                        ],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Slovakia',
                country: 'SK',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [18.853144158613617, 49.49622976337764],
                        [18.909574822676316, 49.435845852244576],
                        [19.320712517990472, 49.571574001659194],
                        [19.825022820726872, 49.21712535256923],
                        [20.41583947111985, 49.43145335549977],
                        [20.887955356538413, 49.32877228453583],
                        [21.607808058364213, 49.47010732685409],
                        [22.558137648211755, 49.085738023467144],
                        [22.28084191253356, 48.82539215758067],
                        [22.08560835133485, 48.42226430927179],
                        [21.872236362401736, 48.31997081155002],
                        [20.801293979584926, 48.623854071642384],
                        [20.473562045989866, 48.56285004332181],
                        [20.239054396249347, 48.32756724709692],
                        [19.769470656013112, 48.202691148463614],
                        [19.661363559658497, 48.26661489520866],
                        [19.17436486173989, 48.11137889260387],
                        [18.77702477384767, 48.081768296900634],
                        [18.696512892336926, 47.880953681014404],
                        [17.857132602620027, 47.758428860050365],
                        [17.48847293464982, 47.86746613218621],
                        [16.979666782304037, 48.123497015976305],
                        [16.879982944413, 48.47001333270947],
                        [16.960288120194576, 48.5969823268506],
                        [17.101984897538898, 48.81696889911711],
                        [17.545006951577108, 48.80001902932537],
                        [17.88648481616181, 48.90347524677371],
                        [17.913511590250465, 48.996492824899086],
                        [18.104972771891852, 49.04398346617531],
                        [18.170498488037964, 49.271514797556435],
                        [18.399993523846177, 49.31500051533004],
                        [18.554971144289482, 49.49501536721878],
                        [18.853144158613617, 49.49622976337764],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Serbia',
                country: 'RS',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [20.87431277841341, 45.41637543393432],
                        [21.48352623870221, 45.18117015235788],
                        [21.562022739353722, 44.76894725196564],
                        [22.145087924902896, 44.47842234962059],
                        [22.459022251075965, 44.70251719825444],
                        [22.70572553883744, 44.57800283464701],
                        [22.474008416440654, 44.40922760678177],
                        [22.657149692483074, 44.234923000661354],
                        [22.410446404721597, 44.008063462900054],
                        [22.500156691180223, 43.642814439461006],
                        [22.986018507588483, 43.2111612005271],
                        [22.60480146657136, 42.898518785161116],
                        [22.43659467946139, 42.58032115332394],
                        [22.54501183440965, 42.46136200618804],
                        [22.38052575042468, 42.32025950781508],
                        [21.917080000000112, 42.30364],
                        [21.57663598940212, 42.24522439706186],
                        [21.54332, 42.3202500000001],
                        [21.66292, 42.43922],
                        [21.77505, 42.6827],
                        [21.63302, 42.67717],
                        [21.43866, 42.86255],
                        [21.27421, 42.90959],
                        [21.143395, 43.06868500000012],
                        [20.95651, 43.13094],
                        [20.81448, 43.27205],
                        [20.63508, 43.21671],
                        [20.49679, 42.88469],
                        [20.25758, 42.81275000000011],
                        [20.3398, 42.89852],
                        [19.95857, 43.10604],
                        [19.63, 43.21377997027054],
                        [19.48389, 43.35229],
                        [19.21852, 43.52384],
                        [19.454, 43.56810000000013],
                        [19.59976, 44.03847],
                        [19.11761, 44.42307000000011],
                        [19.36803, 44.863],
                        [19.00548, 44.86023],
                        [19.39047570158459, 45.236515611342384],
                        [19.072768995854176, 45.52151113543209],
                        [18.82982, 45.90888],
                        [19.59604454924164, 46.171729844744554],
                        [20.220192498462893, 46.12746898048658],
                        [20.762174920339987, 45.734573065771485],
                        [20.87431277841341, 45.41637543393432],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Slovenia',
                country: 'SI',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [13.806475457421527, 46.509306138691215],
                        [14.63247155117483, 46.43181732846955],
                        [15.137091912504985, 46.65870270444703],
                        [16.011663852612656, 46.6836107448117],
                        [16.202298211337364, 46.85238597267696],
                        [16.370504998447416, 46.8413272161665],
                        [16.564808383864857, 46.50375092221983],
                        [15.768732944408551, 46.23810822202345],
                        [15.671529575267556, 45.83415355079788],
                        [15.323953891672403, 45.73178253842768],
                        [15.327674594797427, 45.45231639259323],
                        [14.935243767972935, 45.471695054702685],
                        [14.595109490627804, 45.634940904312714],
                        [14.411968214585414, 45.46616567644746],
                        [13.715059848697221, 45.50032379819237],
                        [13.937630242578306, 45.59101593686462],
                        [13.698109978905478, 46.01677806251735],
                        [13.806475457421527, 46.509306138691215],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Sweden',
                country: 'SE',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [22.183173455501926, 65.72374054632017],
                        [21.21351687997722, 65.02600535751527],
                        [21.369631381930958, 64.41358795842429],
                        [19.77887576669022, 63.60955434839504],
                        [17.84777916837521, 62.74940013289681],
                        [17.119554884518124, 61.34116567651097],
                        [17.83134606290639, 60.63658336042741],
                        [18.78772179533209, 60.081914374422595],
                        [17.86922488777634, 58.9537661810587],
                        [16.829185011470088, 58.71982697207339],
                        [16.447709588291474, 57.041118069071885],
                        [15.879785597403783, 56.10430186626866],
                        [14.666681349352075, 56.200885118222175],
                        [14.100721062891465, 55.40778107362265],
                        [12.942910597392057, 55.36173737245058],
                        [12.625100538797028, 56.30708018658197],
                        [11.787942335668674, 57.44181712506307],
                        [11.027368605196866, 58.85614940045936],
                        [11.468271925511146, 59.43239329694604],
                        [12.3003658382749, 60.11793284773003],
                        [12.631146681375183, 61.293571682370136],
                        [11.992064243221563, 61.80036245385656],
                        [11.93056928879423, 63.12831757267698],
                        [12.579935336973932, 64.06621898055833],
                        [13.571916131248711, 64.04911408146971],
                        [13.919905226302204, 64.44542064071608],
                        [13.55568973150909, 64.78702769638151],
                        [15.108411492583002, 66.19386688909547],
                        [16.108712192456778, 67.30245555283689],
                        [16.768878614985482, 68.01393667263139],
                        [17.729181756265348, 68.01055186631628],
                        [17.993868442464333, 68.56739126247736],
                        [19.878559604581255, 68.40719432237258],
                        [20.025268995857886, 69.0651386583127],
                        [20.645592889089528, 69.10624726020087],
                        [21.978534783626117, 68.6168456081807],
                        [23.53947309743444, 67.93600861273525],
                        [23.565879754335583, 66.39605093043743],
                        [23.903378533633802, 66.00692739527962],
                        [22.183173455501926, 65.72374054632017],
                    ],
                ],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Ukraine',
                country: 'UA',
            },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [31.785998162571587, 52.101677964885454],
                        [32.15941206231267, 52.06126699483322],
                        [32.41205813978763, 52.28869497334975],
                        [32.71576053236697, 52.23846548116205],
                        [33.7526998227357, 52.335074571331695],
                        [34.39173058445701, 51.76888174092579],
                        [34.14197838719039, 51.56641347920623],
                        [34.22481570815427, 51.25599315042895],
                        [35.02218305841788, 51.20757233337145],
                        [35.37792361831512, 50.77395539001034],
                        [35.35611616388795, 50.577197374059054],
                        [36.62616784032534, 50.225590928745135],
                        [37.39345950699507, 50.38395335550359],
                        [38.010631137856905, 49.91566152607463],
                        [38.59498823421342, 49.92646190042363],
                        [40.06905846533911, 49.6010554062817],
                        [40.08078901546935, 49.307429917999286],
                        [39.67466393408753, 48.78381846780187],
                        [39.89563235856758, 48.23240509703143],
                        [39.738277622238826, 47.89893707945198],
                        [38.7705847511412, 47.825608222029814],
                        [38.25511233902975, 47.546400458356814],
                        [38.22353803889942, 47.10218984637588],
                        [37.42513715998999, 47.022220567404204],
                        [36.75985477066439, 46.698700263040934],
                        [35.82368452326483, 46.64596446388707],
                        [34.96234174982388, 46.27319651954964],
                        [35.020787794745985, 45.65121898048466],
                        [35.51000857925317, 45.40999339454619],
                        [36.52999799983016, 45.46998973243706],
                        [36.33471276219916, 45.113215643893966],
                        [35.23999922052812, 44.939996242851606],
                        [33.882511020652885, 44.36147858334407],
                        [33.326420932760044, 44.56487702084489],
                        [33.54692426934946, 45.03477081967489],
                        [32.4541744321055, 45.32746613217608],
                        [32.630804477679135, 45.519185695978905],
                        [33.58816206231839, 45.85156850848024],
                        [33.29856733575471, 46.080598456397844],
                        [31.74414025241518, 46.333347886737386],
                        [31.675307244602408, 46.70624502215554],
                        [30.748748813609097, 46.583100084004],
                        [30.377608676888883, 46.03241018328567],
                        [29.603289015427432, 45.293308010431126],
                        [29.149724969201653, 45.46492544207245],
                        [28.679779493939378, 45.304030870131704],
                        [28.233553501099042, 45.48828318946837],
                        [28.485269402792767, 45.5969070501459],
                        [28.659987420371575, 45.93998688413164],
                        [28.93371748222162, 46.2588304713725],
                        [28.862972446414062, 46.43788930926383],
                        [29.072106967899295, 46.517677720722496],
                        [29.170653924279886, 46.3792623968287],
                        [29.75997195813639, 46.34998769793536],
                        [30.02465864433537, 46.42393667254503],
                        [29.838210076626297, 46.52532583270169],
                        [29.908851759569302, 46.67436066343146],
                        [29.559674106573112, 46.928582872091326],
                        [29.41513512545274, 47.34664520933257],
                        [29.05086795422733, 47.51022695575249],
                        [29.12269819511303, 47.84909516050646],
                        [28.670891147585163, 48.1181485052341],
                        [28.259546746541844, 48.15556224221342],
                        [27.522537469195154, 48.467119452501116],
                        [26.857823520624805, 48.368210761094495],
                        [26.619336785597795, 48.22072622333347],
                        [26.19745039236693, 48.22088125263035],
                        [25.9459411964024, 47.987148749374214],
                        [25.20774336111299, 47.89105642352747],
                        [24.866317172960578, 47.73752574318831],
                        [24.40205610525038, 47.98187775328042],
                        [23.76095828623741, 47.985598456405455],
                        [23.142236362406802, 48.09634105080695],
                        [22.710531447040495, 47.88219391538941],
                        [22.640819939878753, 48.15023956968735],
                        [22.08560835133485, 48.42226430927179],
                        [22.28084191253356, 48.82539215758067],
                        [22.558137648211755, 49.085738023467144],
                        [22.776418898212626, 49.02739533140962],
                        [22.518450148211603, 49.47677358661974],
                        [23.426508416444392, 50.30850576435745],
                        [23.922757195743262, 50.42488108987875],
                        [24.029985792748903, 50.70540660257518],
                        [23.52707075368437, 51.57845408793023],
                        [24.00507775238421, 51.61744395609446],
                        [24.553106316839518, 51.888461005249184],
                        [25.327787713327005, 51.91065603291855],
                        [26.337958611768556, 51.83228872334793],
                        [27.454066196408434, 51.59230337178447],
                        [28.24161502453657, 51.57222707783907],
                        [28.61761274589225, 51.42771393493484],
                        [28.99283532076353, 51.602044379271476],
                        [29.254938185347925, 51.368234361366895],
                        [30.157363722460897, 51.41613841410147],
                        [30.555117221811457, 51.31950348571566],
                        [30.619454380014844, 51.822806098022376],
                        [30.927549269338982, 52.04235342061438],
                        [31.785998162571587, 52.101677964885454],
                    ],
                ],
            },
        },
    ],
};
export default data;
