const data = {
    type: 'Topology',
    arcs: [
        [
            [2128, 1450],
            [-41, -113],
            [-19, -92],
            [-7, -171],
            [-11, -63],
            [19, -69],
            [33, -63],
            [21, -99],
            [71, -95],
            [25, -73],
            [41, -63],
            [113, -34],
            [44, -54],
            [94, 36],
            [81, 13],
            [80, 23],
            [67, 22],
            [67, 52],
            [26, 75],
            [8, 107],
            [19, 38],
            [72, 33],
            [113, 30],
            [94, -5],
            [64, 11],
            [26, -27],
            [-4, -62],
            [-57, -76],
            [-25, -78],
            [19, -22],
            [-15, -55],
            [-27, -100],
            [-27, 33],
            [-22, -2],
            [-21, -2],
            [-38, -77],
            [-19, 15],
            [-13, -6],
            [1, -19],
            [-99, 2],
            [-99, -1],
            [0, -72],
            [-48, 0],
            [39, -43],
            [40, -29],
            [12, -28],
            [17, -8],
            [-3, -43],
            [-137, -1],
            [-51, -104],
            [15, -24],
            [-12, -30],
            [-3, -37],
            [-120, 138],
            [-55, 41],
            [-87, 34],
            [-59, -10],
            [-86, -48],
            [-54, -12],
            [-75, 33],
            [-80, 25],
            [-99, 58],
            [-80, 18],
            [-121, 60],
            [-89, 61],
            [-27, 34],
            [-59, 8],
            [-109, 40],
            [-44, 59],
            [-115, 72],
            [-53, 81],
            [-26, 62],
            [36, 13],
            [-11, 36],
            [25, 33],
            [0, 45],
            [-36, 57],
            [-9, 51],
            [-36, 64],
            [-94, 128],
            [-107, 100],
            [-52, 79],
            [-91, 53],
            [-20, 31],
            [16, 79],
            [-54, 30],
            [-63, 62],
            [-26, 89],
            [-58, 11],
            [-61, 67],
            [-50, 62],
            [-5, 40],
            [-57, 97],
            [-38, 98],
            [2, 49],
            [-77, 50],
            [-36, -5],
            [-61, 35],
            [-17, -52],
            [18, -61],
            [10, -96],
            [37, -53],
            [79, -88],
            [17, -30],
            [17, -9],
            [14, -44],
            [19, 1],
            [21, -82],
            [32, -33],
            [23, -45],
            [67, -65],
            [35, -119],
            [32, -56],
            [29, -60],
            [6, -67],
            [52, -5],
            [42, -58],
            [39, -57],
            [-3, -23],
            [-45, -47],
            [-18, 1],
            [-28, 78],
            [-70, 72],
            [-77, 62],
            [-54, 33],
            [3, 93],
            [-16, 70],
            [-51, 39],
            [-73, 57],
            [-14, -16],
            [-27, 33],
            [-66, 31],
            [-62, 75],
            [7, 9],
            [44, -7],
            [40, 48],
            [4, 57],
            [-82, 92],
            [-63, 35],
            [-39, 80],
            [-39, 84],
            [-50, 102],
            [-43, 115],
            [121, 10],
            [135, 14],
            [-10, -25],
            [161, -62],
            [243, -90],
            [212, 1],
            [84, 0],
            [0, 52],
            [185, 0],
            [39, -45],
            [54, -40],
            [63, -57],
            [36, -66],
            [26, -71],
            [55, -38],
            [89, -39],
            [67, 101],
            [87, 3],
            [75, -51],
            [53, -88],
            [37, -75],
            [63, -73],
            [23, -89],
            [30, -60],
            [83, -40],
            [76, -28],
            [41, 4],
        ],
    ],
    transform: {
        scale: [0.00939150483642097, 0.007816853551078695],
        translate: [-117.12775999999984, 14.538828640190957],
    },
    objects: {
        countries: {
            type: 'GeometryCollection',
            geometries: [
                { arcs: [[0]], type: 'Polygon', properties: { name: 'Mexico', country: 'MX' } },
            ],
        },
    },
};

export default data;
