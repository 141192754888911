// New state, Jammu and Kashmir, added using data from OpenStreetMaps. Border line is currently
// rendering as it's own polygon within the India multipolygon using QGIS to draw and buffer the line.
import i18n from 'i18next';

// These state values are arbitraty mappings from GeoNames
// See https://prod-gitlab.sprinklr.com/david.boyd/spr-display-geo/-/blob/master/admin1Codes.txt
export const states = [
    { label: i18n.t('All'), value: 'all' },
    { label: i18n.t('Andaman, Nicobar Islands'), value: '01' },
    { label: i18n.t('Andhra Pradesh'), value: '02' },
    { label: i18n.t('Arunāchal Pradesh'), value: '30' },
    { label: i18n.t('Assam'), value: '03' },
    { label: i18n.t('Bengal'), value: '28' },
    { label: i18n.t('Bihār'), value: '34' },
    { label: i18n.t('Chandīgarh'), value: '05' },
    { label: i18n.t('Chhattisgarh'), value: '37' },
    { label: i18n.t('Daman, Diu'), value: '32' },
    { label: i18n.t('Dādra, Nagar Haveli'), value: '06' },
    { label: i18n.t('Delhi'), value: '07' },
    { label: i18n.t('Goa'), value: '33' },
    { label: i18n.t('Gujarāt'), value: '09' },
    { label: i18n.t('Haryana'), value: '10' },
    { label: i18n.t('Himachal Pradesh'), value: '11' },
    { label: i18n.t('Jharkhand'), value: '38' },
    { label: i18n.t('Karnātaka'), value: '19' },
    { label: i18n.t('Kashmir'), value: '12' },
    { label: i18n.t('Kerala'), value: '13' },
    { label: i18n.t('Madhya Pradesh'), value: '35' },
    { label: i18n.t('Mahārāshtra'), value: '16' },
    { label: i18n.t('Manipur'), value: '17' },
    { label: i18n.t('Meghālaya'), value: '18' },
    { label: i18n.t('Mizoram'), value: '31' },
    { label: i18n.t('Nāgāland'), value: '20' },
    { label: i18n.t('Orissa'), value: '21' },
    { label: i18n.t('Puducherry'), value: '22' },
    { label: i18n.t('Punjab'), value: '23' },
    { label: i18n.t('Rajasthan'), value: '24' },
    { label: i18n.t('Sikkim'), value: '29' },
    { label: i18n.t('Tamil Nādu'), value: '25' },
    { label: i18n.t('Tripura'), value: '26' },
    { label: i18n.t('Uttar Pradesh'), value: '36' },
    { label: i18n.t('Uttarakhand'), value: '39' },
];

// TopoJSON: up to 80% small than GeoJSON format we used to use
//   https://github.com/topojson/topojson
const data = {
    type: 'Topology',
    arcs: [
        [
            [71372, 3845],
            [10, -78],
            [52, 7],
            [59, -276],
            [-11, -101],
            [82, -111],
            [15, -110],
            [40, -140],
            [-54, -52],
            [-56, -124],
            [12, -59],
            [-23, -100],
            [43, -26],
            [-35, -96],
            [-118, 29],
            [-17, -103],
            [-53, -75],
            [-60, 25],
            [19, 132],
            [-77, 82],
            [-35, 80],
            [18, 53],
            [-106, 126],
            [10, 106],
            [-129, 145],
            [-80, 5],
            [-40, 330],
            [55, 39],
            [-2, 106],
            [32, 39],
            [132, -6],
            [59, 69],
            [147, 9],
            [1, 42],
            [110, 33],
        ],
        [
            [70996, 4437],
            [34, -135],
            [89, -66],
            [-88, -189],
            [-127, -72],
            [-27, -73],
            [-59, -51],
            [-54, 76],
            [-8, 109],
            [28, 45],
            [-16, 118],
            [86, 55],
            [128, 38],
            [14, 145],
        ],
        [
            [70072, 6127],
            [81, -52],
            [-19, -93],
            [75, -21],
            [88, -92],
            [35, -116],
            [-34, -71],
            [-57, 79],
            [-123, 10],
            [-46, -57],
            [-100, 55],
            [9, 69],
            [-80, 20],
            [-24, 87],
            [72, 145],
            [51, -13],
            [72, 50],
        ],
        [
            [70536, 6136],
            [44, -9],
            [54, -95],
            [-23, -184],
            [-185, 164],
            [97, 87],
            [13, 37],
        ],
        [
            [70623, 6420],
            [29, -47],
            [10, -209],
            [-58, 85],
            [-28, 152],
            [47, 19],
        ],
        [
            [70478, 6729],
            [50, -19],
            [1, -86],
            [-113, -149],
            [123, -163],
            [14, -63],
            [-124, -95],
            [-6, -95],
            [-40, -5],
            [-16, 107],
            [105, 45],
            [-22, 93],
            [-91, -18],
            [-69, 136],
            [-11, 149],
            [99, 68],
            [11, 69],
            [89, 26],
        ],
        [
            [69364, 7090],
            [48, -26],
            [-48, -203],
            [80, -98],
            [110, -53],
            [14, -90],
            [-92, 16],
            [-120, 61],
            [-105, 154],
            [0, 99],
            [27, 95],
            [86, 45],
        ],
        [
            [70727, 7724],
            [19, -128],
            [33, -15],
            [23, -166],
            [-45, -1],
            [-5, 140],
            [-43, 108],
            [18, 62],
        ],
        [
            [68423, 9675],
            [87, -90],
            [35, -145],
            [-57, -116],
            [-220, 1],
            [-35, 65],
            [3, 196],
            [124, 4],
            [63, 85],
        ],
        [
            [67666, 14484],
            [119, -100],
            [8, -150],
            [73, -72],
            [-14, -130],
            [41, -105],
            [0, -68],
            [-124, -130],
            [-35, -71],
            [13, -60],
            [65, -48],
            [-103, -169],
            [-88, -21],
            [-180, 108],
            [-91, -50],
            [-49, 37],
            [70, 116],
            [30, 90],
            [-33, 98],
            [-77, 53],
            [5, 153],
            [-18, 181],
            [102, 22],
            [51, 95],
            [80, 71],
            [89, 124],
            [66, 26],
        ],
        [
            [68016, 16265],
            [73, -56],
            [48, -167],
            [-57, -110],
            [78, -21],
            [-6, -53],
            [-145, -68],
            [-109, 2],
            [-49, 56],
            [17, 102],
            [96, 8],
            [3, 106],
            [-33, 52],
            [14, 77],
            [70, 72],
        ],
        [
            [66840, 16504],
            [142, -20],
            [13, -146],
            [-67, -27],
            [-94, 9],
            [-24, 57],
            [30, 127],
        ],
        [
            [67733, 16538],
            [76, -34],
            [-38, -89],
            [-45, -1],
            [7, 124],
        ],
        [
            [69071, 17235],
            [120, 5],
            [-39, -95],
            [-90, 53],
            [9, 37],
        ],
        [
            [68928, 17789],
            [95, -13],
            [13, -90],
            [81, -148],
            [47, -148],
            [-88, 12],
            [-78, 118],
            [-58, 23],
            [-36, 90],
            [-75, 14],
            [-8, 60],
            [107, 82],
        ],
        [
            [68988, 18008],
            [35, -13],
            [-14, -169],
            [-102, 76],
            [-6, 101],
            [87, 5],
        ],
        [
            [69090, 18148],
            [21, -172],
            [51, -66],
            [-129, 1],
            [-3, 94],
            [-121, 43],
            [3, 81],
            [83, -28],
            [95, 47],
        ],
        [
            [69205, 18306],
            [46, -47],
            [24, -137],
            [-5, -120],
            [-62, -58],
            [-91, 138],
            [55, 164],
            [33, 60],
        ],
        [
            [68182, 18377],
            [40, -197],
            [59, 41],
            [57, -20],
            [-5, -62],
            [-49, -124],
            [-8, -67],
            [113, -167],
            [-38, -31],
            [-15, -93],
            [-86, -40],
            [-27, -68],
            [45, -151],
            [41, 96],
            [85, 18],
            [29, -37],
            [-26, -98],
            [22, -58],
            [-87, -450],
            [-102, -33],
            [-45, -41],
            [38, -77],
            [80, 29],
            [33, -32],
            [-11, -160],
            [-62, -151],
            [-6, -121],
            [-83, -27],
            [-96, 0],
            [-49, 50],
            [13, 82],
            [-121, 124],
            [1, 114],
            [-35, 90],
            [11, 73],
            [-33, 80],
            [-96, 10],
            [10, 151],
            [-31, 162],
            [-57, -7],
            [-30, 63],
            [35, 138],
            [44, 81],
            [64, 60],
            [37, -47],
            [21, -113],
            [70, -50],
            [-16, 152],
            [17, 48],
            [4, 193],
            [35, 160],
            [-5, 120],
            [60, 221],
            [121, 164],
            [34, 2],
        ],
        [
            [68265, 18396],
            [87, -38],
            [4, -137],
            [-112, 19],
            [-3, 114],
            [24, 42],
        ],
        [
            [68851, 18509],
            [-54, -63],
            [-57, 63],
            [35, 34],
            [76, -34],
        ],
        [
            [68392, 18594],
            [91, 7],
            [57, -37],
            [110, 1],
            [51, -122],
            [-78, -34],
            [34, -59],
            [-51, -67],
            [-15, -120],
            [-74, -36],
            [-64, -121],
            [-15, -74],
            [-79, -58],
            [-70, 63],
            [0, 42],
            [71, 226],
            [10, 148],
            [-75, 51],
            [112, 116],
            [-15, 74],
        ],
        [
            [71350, 18623],
            [91, -153],
            [22, -90],
            [-150, 23],
            [-33, 117],
            [70, 103],
        ],
        [
            [68604, 18881],
            [104, -52],
            [-64, -38],
            [-40, 90],
        ],
        [
            [68889, 18897],
            [-35, -42],
            [-28, -117],
            [-39, 14],
            [40, 158],
            [62, -13],
        ],
        [
            [68732, 20356],
            [28, -96],
            [55, 1],
            [70, -222],
            [-53, -74],
            [70, -77],
            [46, -108],
            [-31, -108],
            [-26, -213],
            [54, -191],
            [-71, -226],
            [-74, -84],
            [-127, -53],
            [-134, 32],
            [24, -88],
            [100, -129],
            [34, -89],
            [-150, -61],
            [-65, 37],
            [-104, 10],
            [-65, -53],
            [-82, 1],
            [-49, 105],
            [-10, 246],
            [31, 205],
            [-40, 66],
            [41, 122],
            [-6, 107],
            [24, 125],
            [81, 103],
            [-10, 155],
            [-55, 185],
            [9, 115],
            [39, 4],
            [90, 99],
            [62, -2],
            [-3, 81],
            [90, 14],
            [9, -26],
            [18, -1],
            [14, 16],
            [11, -15],
            [-9, -22],
            [4, -10],
            [10, -1],
            [83, 20],
            [67, 100],
        ],
        [
            [68166, 20575],
            [65, -35],
            [7, -266],
            [-19, -74],
            [-69, -82],
            [-49, -116],
            [-63, 193],
            [42, 57],
            [16, 207],
            [70, 116],
        ],
        [
            [69095, 22268],
            [29, -77],
            [11, -121],
            [-29, -139],
            [10, -111],
            [38, -42],
            [35, -128],
            [45, -57],
            [-19, -55],
            [-90, 2],
            [-36, 63],
            [-66, 24],
            [-73, -84],
            [100, -50],
            [91, -140],
            [29, -75],
            [-67, -160],
            [14, -114],
            [-19, -203],
            [-67, -111],
            [-99, -5],
            [-28, -42],
            [-124, 114],
            [-34, -93],
            [10, -113],
            [-69, 0],
            [-16, -108],
            [-56, -34],
            [7, -90],
            [35, -61],
            [-40, -11],
            [-18, 0],
            [-22, -7],
            [-3, 15],
            [5, 13],
            [0, 16],
            [-118, 27],
            [-2, 134],
            [-25, 43],
            [24, 87],
            [-26, 60],
            [16, 225],
            [64, 33],
            [-29, 153],
            [61, 177],
            [-10, 66],
            [64, 10],
            [-78, 107],
            [38, 261],
            [46, 58],
            [-8, 71],
            [94, 53],
            [-28, 50],
            [8, 82],
            [66, 8],
            [10, 57],
            [70, 14],
            [-29, 102],
            [128, 22],
            [80, 62],
            [62, -47],
            [38, 69],
        ],
        [
            [69057, 22566],
            [-3, -93],
            [-78, -2],
            [-13, 64],
            [94, 31],
        ],
        [
            [75238, 60413],
            [-3, 76],
            [-56, 66],
            [8, 60],
            [66, 83],
            [-77, 41],
            [-84, 193],
            [126, 61],
            [9, 102],
            [-44, 40],
            [-61, 161],
            [0, 155],
        ],
        [
            [75122, 61451],
            [147, -37],
            [170, 148],
            [158, 92],
            [111, 16],
            [39, 35],
            [105, 16],
            [29, 160],
            [-26, 53],
            [78, 106],
            [132, 73],
            [110, -57],
            [45, -63],
            [131, 21],
            [132, 62],
            [349, 67],
            [139, 39],
            [35, -81],
            [70, -6],
            [24, 80],
            [157, 73],
            [20, 58],
            [125, 111],
            [-117, 179],
            [-110, 29],
            [-142, -8],
            [-18, 80],
            [15, 237],
            [-53, -2],
            [-115, 86],
            [8, 61],
            [-72, 14],
            [-52, 155],
            [-26, 172],
            [50, 63],
            [-21, 48],
            [118, 149],
            [81, 72],
            [69, 23],
            [127, 129],
            [93, 117],
            [-10, 45],
            [70, 76],
            [-422, 27],
            [-609, -58],
            [-116, -101],
            [-59, -28],
            [-78, -91],
            [-367, -116],
            [-188, 85],
            [-223, -66],
            [-513, -171],
            [-37, -35],
            [-143, -19],
            [-287, -86],
            [-64, -6],
            [-33, -86],
            [-78, -40],
            [-72, 5],
            [-145, -102],
            [-124, 13],
            [-164, -108],
            [-134, -22],
            [-50, -47],
            [-132, -32],
            [-72, -53],
            [-130, -20],
            [-91, 89],
            [-148, -71],
            [-222, 44],
            [-146, 119],
            [-34, -94],
            [10, -72],
            [83, -53],
            [7, -102],
            [-283, -128],
            [8, -36],
            [-204, -179],
            [-72, -119],
            [-170, -84],
            [-169, -178],
            [-107, -94],
            [-93, -124],
            [-167, -142],
            [51, -88],
            [32, -119],
            [-75, -29],
            [-253, -144],
            [-121, -140],
            [-254, -53],
            [-162, -13],
            [-161, -27],
            [-56, 43],
            [-279, 28],
            [-225, -21],
            [-229, -50],
            [-210, -34],
            [-48, -34],
            [-64, 24],
            [-137, -21],
            [-62, 39],
            [-222, 100],
            [-3, 58],
            [-123, 68],
            [-286, 74],
            [-155, -21],
            [-153, 35],
            [-40, -82],
            [3, -64],
            [-169, -76],
            [-87, -8],
            [-134, 22],
            [-164, -17],
            [-129, -100],
            [-131, 27],
            [-139, -34],
            [-75, -62],
            [-60, 29],
            [-166, -62],
            [-240, 7],
        ],
        [
            [66533, 61016],
            [23, 112],
            [-38, 220],
            [-65, 37],
            [-45, 91],
            [-52, 0],
            [-48, 78],
            [-14, 106],
            [39, 68],
            [-32, 98],
            [87, 51],
            [38, 99],
            [-11, 62],
            [-72, 49],
            [84, 45],
            [86, -23],
            [43, 68],
            [-162, 100],
            [-34, 153],
            [23, 59],
            [-43, 105],
            [-62, 65],
            [4, 62],
            [-220, -56],
            [-250, 64],
            [-57, -40],
            [-153, -11],
            [-48, 24],
            [-119, 2],
            [-66, 83],
            [-102, -56],
            [-164, 163],
            [-91, 130],
            [42, 47],
            [-53, 96],
            [94, 145],
            [89, 66],
            [21, 191],
            [-9, 87],
            [-82, 57],
            [-91, -16],
            [-55, 92],
            [67, 40],
            [103, -19],
            [174, 8],
            [68, -101],
            [145, -55],
            [228, 13],
            [90, -206],
            [51, -44],
            [184, 20],
            [31, 64],
            [78, 56],
            [199, -32],
            [279, 227],
            [123, 58],
            [153, 16],
            [33, -24],
            [49, -135],
            [63, -82],
            [202, 46],
            [8, 58],
            [86, 23],
            [84, -64],
            [134, 104],
            [64, 10],
            [81, -66],
            [45, 9],
            [126, 165],
            [177, 66],
            [89, 98],
            [107, 163],
            [-123, 170],
            [-91, 42],
            [50, 161],
            [285, 190],
            [46, 55],
            [61, -82],
            [205, 89],
            [50, 88],
            [143, 78],
            [99, -91],
            [94, 95],
            [-34, 107],
            [204, 21],
            [83, 58],
            [191, -26],
            [82, 66],
            [-10, 61],
            [34, 113],
            [-115, 74],
            [108, 151],
            [129, 78],
            [83, 178],
            [-16, 97],
            [78, 26],
            [13, 74],
            [220, 56],
            [96, 66],
            [78, -64],
            [60, 88],
            [80, -66],
            [229, -16],
            [348, 97],
            [121, -60],
            [191, 113],
            [59, -10],
            [108, -80],
            [85, 1],
            [88, 136],
            [6, 82],
            [92, 69],
            [-44, 66],
            [40, 63],
            [-13, 63],
            [97, 35],
            [136, 153],
            [213, 142],
            [48, 108],
            [6, 84],
            [112, 185],
            [118, 93],
            [84, 92],
            [174, 7],
            [38, 20],
            [24, 95],
            [163, 107],
            [176, -29],
            [176, 35],
            [44, 134],
            [104, 77],
            [48, 137],
            [137, -59],
            [94, -70],
            [154, -150],
            [86, -124],
            [0, -132],
            [138, 54],
            [70, 3],
            [129, -63],
            [137, -8],
            [121, -77],
            [-18, 107],
            [98, -51],
            [162, -24],
            [65, -82],
            [155, -21],
            [204, 3],
            [-2, -121],
            [47, -30],
            [100, 14],
            [120, -48],
            [178, -36],
            [123, 8],
            [99, 155],
            [39, 155],
            [120, -42],
            [-5, 253],
            [113, 8],
            [37, 125],
            [91, -35],
            [116, 63],
            [80, 85],
            [70, 6],
            [51, 78],
            [89, 79],
            [37, -53],
            [138, 45],
            [114, 109],
            [210, -24],
            [161, 111],
            [113, 108],
            [104, -5],
            [57, -85],
            [102, -81],
            [100, -159],
            [-8, -103],
            [56, -45],
            [88, -147],
            [44, -29],
            [118, 8],
            [55, 68],
            [173, 71],
            [51, -79],
            [-97, -218],
            [-33, -32],
            [-241, -30],
            [-118, -65],
            [-48, -93],
            [-86, -53],
            [-126, -21],
            [74, -79],
            [95, -190],
            [-11, -58],
            [-100, -89],
            [60, -92],
            [171, 68],
            [183, 203],
            [148, 96],
            [247, 15],
            [249, 121],
            [-134, -247],
            [29, -83],
            [94, 0],
            [49, -129],
            [-5, -108],
            [131, -181],
            [68, -69],
            [20, -96],
            [-83, -126],
            [-150, -29],
            [-171, -79],
            [69, -118],
            [-140, -111],
            [-123, -127],
            [-128, -5],
            [-84, -88],
            [102, -58],
            [16, -89],
            [-282, -182],
            [108, -35],
            [43, -55],
            [140, 22],
            [26, -87],
            [56, -56],
            [65, 19],
            [24, 118],
            [75, 79],
            [143, -2],
            [137, 102],
            [109, 38],
            [183, -1],
            [48, -44],
            [212, -103],
            [46, -121],
            [145, -19],
            [201, 55],
            [76, -119],
            [75, -16],
            [121, -70],
            [74, 31],
            [81, 92],
            [113, 34],
            [92, -39],
            [93, -85],
            [27, -71],
            [65, -24],
            [90, 16],
            [46, -73],
            [113, 5],
            [258, -148],
            [-6, -74],
            [-115, -110],
            [-18, -42],
            [40, -83],
            [-79, -76],
            [4, -68],
            [150, -5],
            [32, -85],
            [59, -14],
            [-57, -141],
            [1, -198],
            [29, -62],
            [-67, -33],
            [-59, 26],
            [-97, 105],
            [-145, -60],
            [-150, -132],
            [-118, -121],
            [-100, -71],
            [-61, -14],
            [-17, -126],
            [-80, 28],
            [-144, -55],
            [-105, -200],
            [-54, 7],
            [-83, -53],
            [-25, -73],
            [-61, -31],
            [45, -93],
            [5, -89],
            [60, -80],
            [-67, -121],
            [-3, -76],
            [59, -35],
            [22, -68],
            [67, -33],
            [10, -86],
            [78, -90],
            [17, -74],
            [104, -65],
            [8, -53],
            [140, -120],
            [3, -78],
            [59, -35],
            [31, -71],
            [63, -18],
            [61, -122],
            [-70, -98],
            [-105, 53],
            [-128, -28],
            [-91, 89],
            [-127, 71],
            [-183, 26],
            [-145, 70],
            [-8, 57],
            [74, 51],
            [-15, 91],
            [-82, 37],
            [-16, 75],
            [-91, 68],
            [-41, 72],
            [-81, 29],
            [-91, -1],
            [-78, 48],
            [-51, -43],
            [-206, 21],
            [-54, -84],
            [-108, -55],
            [-86, -79],
            [-86, 32],
            [-185, 2],
            [-117, -44],
            [-76, 17],
            [-45, -34],
            [-103, 38],
            [-89, -66],
            [-123, 16],
            [-46, -43],
            [-152, -45],
            [-354, -169],
            [-55, -123],
            [-104, -125],
            [-62, -124],
            [-222, -140],
            [-110, -15],
            [-62, 19],
            [-153, -167],
            [-16, -164],
            [-67, -60],
            [-74, -2],
            [-85, 68],
            [-102, -89],
            [-11, -88],
            [-59, -82],
            [-183, 48],
            [-86, -71],
            [-88, -169],
            [-48, -13],
            [-119, -158],
            [-160, -54],
            [-54, 1],
            [-123, -64],
            [-84, 19],
            [-33, 69],
            [-63, 0],
        ],
        [
            [60310, 57070],
            [22, -142],
            [-67, -35],
        ],
        [
            [60265, 56893],
            [45, 177],
        ],
        [
            [75122, 61451],
            [-115, -108],
            [-103, -66],
            [-85, -89],
            [-137, -42],
            [-44, -37],
            [-101, -21],
            [-78, 73],
            [-121, -4],
            [-76, -29],
            [-98, -148],
            [-84, -77],
            [-53, -142],
            [-79, -32],
            [-48, -66],
            [-69, -24],
            [-126, -90],
            [-181, -36],
            [-77, 16],
            [-3, -69],
            [-132, 24],
            [-70, -27],
            [-61, -81],
            [-84, -11],
            [-37, -90],
            [-124, -65],
            [14, -96],
            [-51, -156],
            [-202, -147],
            [-85, 5],
            [-30, 240],
            [-120, -152],
            [-146, -148],
            [-32, -83],
            [9, -126],
            [-41, -82],
            [-156, -99],
            [-104, -193],
            [-49, -11],
            [-48, -155],
            [-62, -48],
            [-21, -114],
            [-56, -186],
            [15, -30],
            [-70, -89],
            [-31, -103],
            [3, -95],
            [75, -140],
            [-63, -61],
            [-125, -52],
            [-91, -121],
            [-86, 50],
            [-76, -46],
            [-16, -61],
            [-64, -34],
            [-43, 93],
            [50, 184],
            [-35, 141],
            [-99, -61],
            [-172, -82],
            [51, -163],
            [-80, -70],
            [-81, -15],
            [-54, -56],
            [-20, -71],
            [-106, -66],
            [-109, -89],
            [-3, -74],
            [-167, -163],
            [-51, -3],
            [-131, -67],
            [-124, -152],
            [-112, -59],
            [158, -184],
            [0, -48],
            [75, -47],
            [110, -32],
            [60, -181],
            [-73, -103],
            [61, -101],
        ],
        [
            [70332, 56408],
            [-196, -135],
            [-141, -237],
            [-6, -107],
            [-41, -143],
            [-34, -13],
            [-73, -127],
            [-81, 6],
            [-61, -132],
            [46, -109],
            [-52, -68],
            [24, -72],
            [-55, -98],
            [-105, -113],
            [-8, -116],
            [-99, -40],
            [-124, 29],
            [-67, -271],
            [30, -124],
            [-39, -55],
            [6, -106],
            [27, -84],
            [-123, -109],
            [0, -73],
            [-47, -66],
            [36, -63],
            [-46, -135],
            [19, -52],
            [-107, -24],
        ],
        [
            [69015, 53771],
            [-55, 20],
            [-125, -42],
            [-68, 50],
            [-136, -37],
            [-44, -52],
            [-120, 116],
            [-107, 288],
            [-97, -198],
            [-108, -280],
            [-182, -64],
            [-40, -237],
            [-65, -24],
            [-105, 3],
            [-58, -208],
            [-116, -37],
            [-70, -72],
            [-73, 45],
            [-10, 90],
            [-40, 32],
            [10, 171],
            [-349, -3],
        ],
        [
            [67057, 53332],
            [-233, -8],
            [142, 328],
            [26, 76],
            [-47, 140],
            [-71, 71],
            [0, 125],
        ],
        [
            [66874, 54064],
            [23, 281],
            [66, 256],
            [97, 140],
            [-13, 138],
            [-43, 136],
            [-94, 54],
            [3, 178],
            [193, -8],
            [96, -84],
            [46, 9],
            [81, -77],
            [271, 55],
            [27, 109],
            [-24, 71],
            [-95, 28],
            [-66, 61],
            [-31, 85],
            [4, 103],
        ],
        [
            [67415, 55599],
            [112, 74],
            [53, 149],
            [156, -12],
            [-43, 63],
            [157, 19],
            [110, -41],
            [63, 96],
            [92, 90],
            [196, 74],
            [89, -12],
            [66, 53],
            [-86, 34],
            [-7, 104],
            [62, 52],
            [-20, 133],
            [-67, 7],
            [-69, 113],
            [-49, 3],
            [-125, 124],
            [-63, 42],
            [-123, -39],
            [-24, 155],
            [-46, 67],
            [111, 62],
            [64, 61],
            [45, 138],
            [-203, -92],
            [-75, 62],
            [-7, 109],
            [-168, 45],
            [-91, 160],
            [-86, 16],
            [-49, 153],
            [-124, 18],
            [-91, -57],
            [-191, -46],
            [-94, -9],
            [-187, -120],
            [-38, 124],
            [25, 103],
            [-38, 113],
            [6, 85],
            [77, 113],
            [0, 58],
            [123, 88],
            [-182, 39],
            [-10, 87],
            [26, 56],
            [97, 61],
            [153, 128],
            [51, 106],
            [-91, -10],
            [-175, 49],
            [-126, -97],
            [-110, 8],
            [-113, -47],
            [-172, 24],
            [-35, -43],
            [-123, -32],
            [-142, 45],
            [-38, 77],
            [18, 118],
            [-157, 63],
            [-81, -94],
            [-164, -80],
            [-22, -84],
            [-10, -222],
            [-140, -142],
            [-59, 26],
            [-51, 90],
            [22, 56],
            [-36, 63],
            [20, 106],
            [-173, 15],
            [-53, -153],
            [-91, -50],
            [-138, -287],
            [-77, -51],
            [-78, 35],
            [-223, -63],
            [-160, -248],
            [-55, 3],
            [-88, -70],
            [-97, 43],
            [-26, 110],
            [64, 188],
            [-139, 26],
            [-89, -55],
            [-151, -16],
            [-48, 68],
            [-80, -76],
            [-54, 10],
            [35, 169],
            [-129, 8],
            [-27, 137],
            [-207, 51],
            [-38, -27],
            [-136, 17],
            [-65, -20],
            [-54, -97],
            [-104, 21],
            [-2, 74],
            [-74, 32],
            [-239, -101],
            [4, 59],
            [-138, 60],
            [-21, -55],
            [-128, -109],
            [24, 164],
            [-79, 111],
            [-80, 56],
            [-134, -77],
            [-89, 74],
            [-89, -11],
            [-96, -65],
            [-17, -40],
            [-106, -30],
            [-205, -29],
            [-276, 9],
            [-61, -84],
            [-78, -38],
            [-154, -198],
            [-155, -107],
            [8, -106],
            [-92, -101],
            [-77, -36],
            [118, -117],
            [29, -81],
            [159, -101],
            [39, -45],
            [-45, -70],
            [-81, -29],
            [-241, -48],
            [-45, -40],
        ],
        [
            [60306, 57091],
            [45, 187],
            [-37, 143],
            [-98, 158],
            [-21, 67],
            [40, 63],
            [-45, 152],
            [-40, 22],
            [105, 211],
            [82, 89],
            [14, 71],
            [-151, -8],
            [59, 135],
            [-75, 63],
            [-32, 91],
            [-81, 137],
            [12, 37],
            [-116, 168],
            [-83, 22],
        ],
        [
            [59884, 58899],
            [-19, 179],
            [16, 92],
            [54, 35],
            [16, 70],
            [107, 103],
            [1, 67],
            [126, 37],
            [85, 54],
            [-35, 74],
            [70, 111],
            [-48, 116],
            [33, 238],
            [37, 42],
            [-17, 288],
            [-42, 58],
        ],
        [
            [60268, 60463],
            [16, 97],
            [75, -35],
            [190, -5],
            [124, 32],
            [146, -15],
            [203, 58],
            [169, 63],
            [-16, 98],
            [33, 84],
            [88, 73],
            [178, -28],
            [59, 7],
            [96, 74],
            [26, 58],
            [151, 16],
            [430, -296],
            [185, -25],
            [51, -50],
            [85, 18],
            [57, -35],
            [115, 19],
            [170, -3],
            [263, 13],
            [276, 27],
            [161, -24],
            [99, 113],
            [111, -29],
            [142, 9],
            [228, -19],
            [91, -58],
            [114, -16],
            [121, 64],
            [79, 100],
            [196, -129],
            [39, -6],
            [273, 45],
            [92, 48],
            [153, -48],
            [134, 21],
            [209, 116],
            [83, 71],
            [60, 105],
            [123, 11],
            [158, -84],
            [88, -85],
            [194, -29],
            [116, 59],
            [31, 73],
        ],
        [
            [55397, 59985],
            [73, -77],
            [92, 25],
            [56, 66],
            [134, -35],
            [-151, -109],
            [39, -67],
            [78, -14],
            [63, -50],
            [31, -98],
            [-71, -74],
            [22, -44],
            [102, 7],
            [43, -78],
            [-80, -38],
            [-94, -99],
            [-8, -41],
            [-130, -81],
            [-91, -26],
            [-195, -207],
            [-103, -12],
            [-107, -108],
            [-83, 41],
            [0, -131],
            [-27, -74],
            [-129, 21],
            [-198, -137],
            [-44, -175],
            [13, -63],
            [-66, -94],
            [45, -170],
            [244, -57],
            [-66, -56],
            [64, -52],
            [-27, -101],
            [102, -63],
            [18, -43],
            [100, -73],
            [198, -95],
            [-42, -132],
            [8, -132],
            [-46, -36],
            [13, -85],
            [118, -119],
            [-21, -79],
            [-161, 24],
            [-134, 129],
            [-88, -6],
            [-65, -84],
            [-86, -11],
            [-18, -110],
            [-82, -3],
            [-71, -89],
            [-62, 44],
            [-63, -157],
            [71, -67],
            [-20, -98],
            [132, -102],
            [59, -18],
            [-3, -127],
            [-74, -56],
            [19, -87],
            [-118, 47],
        ],
        [
            [54510, 56149],
            [-11, 75],
            [-210, 29],
            [-65, 156],
            [-215, 4],
            [-110, 67],
            [-56, -9],
            [-26, -102],
            [-157, -3],
            [39, -87],
            [-63, -71],
            [-2, -135],
            [-48, 9],
            [-175, 87],
            [-65, -61],
            [-126, 49],
            [-43, -211],
            [-29, -47],
            [-18, -131],
            [-90, 44],
            [-69, -55],
            [-62, 11],
            [-97, -74],
            [-91, -156],
            [25, -56],
            [-16, -213],
            [31, -93],
            [-31, -68],
            [-114, -45],
            [-80, -113],
            [14, -85],
            [-22, -100],
            [21, -60],
            [-75, -156],
            [-30, -135],
            [17, -50],
            [-113, -2],
            [-124, 74],
            [-82, -3],
            [-106, -129],
            [-120, -104],
            [-49, 51],
            [-80, -13],
            [-6, 91],
            [-40, 59],
            [-105, -1],
            [-23, -75],
            [-215, -83],
            [-64, 19],
            [-127, 95],
            [-46, -79],
            [-80, -39],
            [-22, -70],
            [-64, 1],
            [-92, -148],
            [-5, -174],
            [-64, -8],
            [-11, -132],
            [-43, -23],
            [-86, 31],
            [-118, 106],
            [-54, 78],
            [-96, -7],
            [-70, 105],
            [51, 95],
            [-30, 68],
            [6, 75],
            [-30, 81],
            [-215, 14],
            [-139, -21],
            [-112, 82],
            [-20, 87],
            [43, 99],
            [-78, 175],
            [-118, 47],
            [-43, 77],
            [-115, -37],
            [-156, -98],
            [-112, 63],
            [-91, 135],
            [-138, 27],
            [-48, -43],
            [-92, 13],
            [-112, 68],
            [-94, -189],
            [3, -84],
            [-124, -158],
            [33, -159],
            [-20, -109],
            [-155, 8],
            [-89, 53],
            [-43, -57],
            [19, -54],
            [-77, -42],
            [-19, -53],
            [-83, -8],
            [-27, 62],
            [-170, -43],
            [-83, 26],
            [-56, -36],
            [-290, -40],
            [-153, -139],
            [-197, -21],
            [46, -99],
            [-152, -60],
            [-63, -81],
            [-75, 17],
            [25, 62],
            [-118, 49],
            [-116, -39],
            [-61, -106],
            [-181, -10],
            [-112, 248],
            [-100, 37],
            [16, 119],
            [-39, 41],
            [-98, -21],
            [-10, -39],
            [-105, -23],
            [-48, -69],
            [-129, -48],
            [-57, -182],
            [-160, 21],
            [-79, 32],
            [-74, -111],
            [-67, 6],
            [-12, -124],
            [-66, -135],
            [-79, 61],
            [-29, 89],
            [-104, -19],
            [15, 78],
            [-77, 26],
            [-36, 65],
            [-128, 17],
            [-16, 102],
            [-103, 58],
            [1, 87],
            [77, 27],
            [-7, 94],
            [-73, 127],
            [-65, -5],
            [-31, -77],
            [-105, 37],
            [-134, -24],
            [-52, -71],
            [-121, -107],
            [-59, 66],
            [1, 87],
            [-83, 126],
            [-35, 103],
            [-63, 48],
            [-90, 26],
            [-54, -121],
            [-123, -141],
            [-167, -42],
            [-30, 13],
            [-163, -23],
            [-138, -56],
            [-90, -16],
            [-97, 30],
            [-228, -6],
            [-161, 37],
            [-122, 3],
        ],
        [
            [42578, 54132],
            [52, 64],
            [48, 194],
            [-102, 104],
            [43, 79],
            [-65, 78],
            [-27, 93],
            [-75, -13],
            [-117, 123],
            [-67, 27],
            [10, 142],
            [-62, 87],
            [-48, 116],
            [18, 153],
            [-39, 24],
            [0, 135],
            [-65, 15],
            [10, 93],
            [82, 296],
            [-28, 26],
            [19, 119],
            [107, 23],
            [59, 122],
            [92, 31],
            [49, -23],
            [55, 89],
            [78, 11],
            [102, 79],
            [183, 56],
            [27, -12],
            [100, 103],
            [83, 17],
            [66, 74],
            [108, 26],
            [94, -16],
            [57, 98],
            [99, 13],
            [-13, 76],
            [243, 287],
            [65, 16],
            [194, 141],
            [174, 65],
            [-19, 167],
            [105, 83],
            [166, -5],
            [75, -55],
            [16, -90],
            [48, -24],
            [204, -4],
            [78, 26],
            [20, 192],
            [80, 18],
            [108, -42],
            [24, -86],
            [131, 42],
            [48, -84],
            [64, 17],
            [76, -39],
            [116, 68],
            [-8, 103],
            [196, 16],
            [-71, 54],
            [59, 92],
            [-96, 79],
            [-111, 63],
            [-56, -19],
            [5, 125],
            [-63, -16],
            [-59, 52],
            [-149, 8],
            [-64, 113],
            [-158, 82],
            [-167, -33],
            [-142, 100],
            [-108, 52],
            [-82, -5],
            [-14, 77],
            [-84, 63],
            [-128, 145],
            [-116, 8],
            [-19, 94],
            [-67, 40],
            [27, 51],
            [-72, 78],
            [88, 122],
            [110, -19],
            [103, 48],
            [7, 60],
            [124, -4],
            [67, 163],
            [-40, 61],
            [11, 103],
            [-151, 34],
            [-155, 15],
            [-70, 51],
            [-56, 98],
            [-91, -20],
            [-226, 46],
            [14, 165],
            [64, 51],
            [99, -37],
            [24, 58],
            [73, -19],
            [132, 53],
            [4, 53],
            [98, 142],
            [-11, 88],
            [198, -8],
            [43, -42],
            [96, 2],
            [196, -74],
            [70, 50],
            [78, -2],
            [247, 34],
            [-34, 129],
            [-212, 37],
            [-20, 93],
            [-56, 108],
            [-156, -66],
            [-50, 78],
            [78, 149],
            [-1, 105],
            [-66, 72],
            [-54, 4],
            [-91, -50],
            [-20, -55],
            [-104, 60],
            [-3, 88],
            [-81, 23],
            [-24, -45],
            [-113, 40],
            [-13, 116],
            [-34, 50],
            [40, 121],
            [-71, 157],
            [-82, 49],
            [72, 100],
            [-24, 27],
            [-159, -55],
            [-37, 98],
            [73, 134],
            [48, 47],
            [-103, 164],
            [-128, 39],
            [-16, 72],
            [62, 47],
            [13, 69],
            [-43, 41],
            [-148, -55],
            [-70, 46],
            [75, 60],
        ],
        [
            [43574, 62338],
            [55, 92],
            [-138, 132],
            [24, 58],
            [196, -5],
            [55, 26],
            [134, -32],
            [117, -8],
            [103, 53],
            [19, 80],
            [90, 50],
            [43, 66],
            [90, -58],
            [15, -42],
            [156, -56],
            [3, -52],
            [120, 0],
            [46, -61],
            [16, -93],
            [175, -61],
            [233, -13],
            [287, -57],
            [108, -8],
            [167, -37],
            [141, -233],
            [43, -95],
            [-12, -200],
            [-46, -187],
            [-57, -53],
            [-11, -76],
            [153, -51],
            [172, -84],
            [40, 52],
            [78, -49],
            [63, 57],
            [75, -73],
            [128, -66],
            [169, -30],
            [48, -52],
            [-23, -73],
            [85, -12],
            [87, -121],
            [-14, -53],
            [110, -18],
            [107, 61],
            [252, -6],
            [-12, -115],
            [-35, -43],
            [85, -158],
            [274, -24],
            [80, -31],
            [98, 47],
            [104, 97],
            [126, -31],
            [189, 93],
            [63, 73],
            [180, 56],
            [191, -35],
            [134, -82],
            [27, -65],
            [-34, -330],
            [51, -105],
            [168, -58],
            [44, -64],
            [93, 16],
            [19, -114],
            [150, 114],
            [89, 19],
            [33, 100],
            [55, 28],
            [108, -10],
            [43, 31],
            [269, -113],
            [47, -69],
            [141, 32],
            [75, -73],
            [97, 68],
            [249, 8],
            [21, -32],
            [126, -58],
            [157, -47],
            [128, -77],
            [104, -3],
            [98, -128],
            [125, -33],
            [47, -57],
            [161, -47],
            [118, -77],
            [106, 98],
            [138, -51],
            [207, 61],
            [-1, 74],
            [97, 3],
            [8, 81],
            [226, 51],
            [85, 116],
            [71, 37],
            [58, -391],
            [181, -134],
            [85, 25],
            [162, 1],
            [55, -19],
            [1, -100],
            [67, 2],
            [140, -76],
            [48, 35],
            [-5, 73],
            [91, 96],
            [112, 7],
            [137, 55],
            [199, -62],
            [104, -72],
            [183, -3],
            [73, 67],
            [0, 57],
            [244, -78],
            [29, 124],
            [52, 50],
            [153, -96],
            [27, 80],
            [115, 41],
            [64, -87],
            [-13, -45],
            [132, -99],
            [66, -14],
            [54, -111],
            [245, 232],
            [27, 90],
            [-24, 125],
            [36, 89],
        ],
        [
            [24022, 72275],
            [-18, -70],
            [39, -75],
            [-45, -83],
        ],
        [
            [23998, 72047],
            [-72, -47],
            [-137, 61],
            [-83, 66],
            [2, 44],
            [-70, 92],
            [111, 49],
            [80, 65],
            [61, -73],
            [41, 34],
            [91, -63],
        ],
        [
            [36697, 34583],
            [-193, 16],
            [-170, 0],
            [-172, 100],
            [-91, 16],
            [-172, -103],
            [-67, -89],
            [-115, 10],
            [-78, 141],
            [-29, 221],
            [-25, 26],
            [-53, 349],
            [-49, 139],
            [3, 93],
            [64, 60],
            [3, 72],
            [-202, -98],
            [-107, -5],
            [-37, 195],
            [27, 76],
            [-95, 22],
            [-16, -120],
            [-59, -76],
            [-97, -8],
            [-78, 24],
            [-4, 137],
            [142, 71],
            [-172, 343],
            [-25, 130],
            [-63, 41],
            [-28, 98],
            [-83, 55],
            [-51, 135],
            [-88, 76],
            [-191, 119],
            [-59, 85],
            [-64, 34],
            [-105, 0],
            [-191, -97],
            [-40, 33],
            [-72, -54],
            [-62, 103],
            [-64, 186],
            [-79, 95],
        ],
        [
            [33590, 37234],
            [-75, 90],
            [86, 37],
            [78, 92],
            [129, 50],
            [-29, 132],
            [-200, 245],
            [11, 138],
            [76, 119],
            [-17, 52],
            [73, 53],
            [45, 106],
            [-29, 68],
            [67, 111],
            [100, 53],
            [-11, 106],
            [32, 76],
            [86, 21],
            [72, 55],
            [70, 172],
            [121, 57],
            [49, 75],
            [77, 68],
            [68, -58],
            [5, -71],
            [51, -121],
            [38, -19],
            [130, 63],
            [59, -42],
            [-5, -73],
            [183, -1],
            [48, 63],
            [202, 135],
            [-75, 82],
            [-68, 127],
            [130, 45],
            [91, 4],
            [69, 64],
            [-35, 156],
            [-77, 15],
            [-89, 119],
            [-97, -15],
            [-58, 73],
            [-119, 68],
            [-166, 1],
            [-16, 47],
            [27, 195],
            [-91, 77],
            [-141, 56],
            [-118, 107],
            [12, 108],
            [-73, 50],
            [-116, -2],
            [-67, -103],
            [-145, 3],
            [-6, 65],
            [94, -17],
            [79, 52],
            [42, 84],
            [62, 34],
            [-23, 72],
            [-78, 29],
            [-47, -29],
            [-115, 47],
            [9, 66],
            [104, 59],
            [73, -54],
            [90, -28],
            [51, 18],
            [65, 148],
            [-30, 65],
            [36, 180],
            [-32, 58],
            [16, 71],
            [-46, 42],
            [-202, 59],
            [-67, -43],
            [-93, 40],
            [4, 110],
            [51, 17],
            [-85, 158],
            [206, 79],
            [151, 3],
            [85, 108],
            [208, 53],
            [-40, 148],
            [-44, 47],
            [48, 119],
            [27, 458],
            [-96, 67],
            [-166, -85],
            [-52, 6],
            [-53, 89],
            [22, 66],
            [82, 63],
            [167, 51],
            [-51, 95],
            [-48, 232],
            [37, 83],
            [-19, 199],
            [-40, 124],
            [-212, -8],
            [-99, 237],
            [70, 116],
            [-36, 143],
            [60, 216],
            [145, 31],
            [32, 58],
            [113, 6],
            [117, 63],
            [98, 79],
            [94, 164],
            [-44, 58],
        ],
        [
            [34672, 44834],
            [76, 121],
            [42, 121],
            [86, 169],
            [-22, 69],
            [23, 126],
            [-59, 188],
            [36, 39],
            [-42, 158],
            [46, 66],
            [8, 106],
            [39, 74],
            [60, 5],
            [57, -53],
            [80, 24],
            [57, 166],
            [0, 121],
            [23, 85],
            [-40, 32],
            [22, 174],
            [97, 189],
            [29, 143],
            [35, 16],
            [19, 103],
            [53, 56],
            [92, -22],
            [42, -90],
            [-7, -70],
            [101, 8],
            [13, 97],
            [-48, 106],
            [81, 7],
            [27, 188],
            [-17, 92],
            [82, 42],
            [99, 8],
            [82, 139],
            [4, 144],
            [-41, 115],
            [27, 167],
            [105, 44],
            [62, 93],
            [62, -16],
            [5, -59],
            [81, -31],
            [90, 61],
            [67, 77],
            [100, -27],
            [21, 102],
            [153, -55],
            [28, -177],
            [72, 11],
            [8, 72],
            [80, 65],
            [94, -7],
            [36, 76],
            [63, 50],
            [116, -15],
            [17, 57],
            [128, -42],
            [112, 114],
            [-23, 92],
            [104, 119],
            [57, -8],
            [105, 68],
            [82, 21],
            [-56, 145],
            [105, 117],
            [-64, 198],
            [23, 113],
            [173, 29],
            [83, 31],
            [147, 158],
            [73, 27],
            [-8, 151],
            [-52, 92],
            [54, 107],
            [116, 24],
            [135, -13],
            [93, 100],
            [150, -34],
            [48, 79],
            [51, 29],
            [-39, 69],
            [15, 182],
            [76, 122],
            [54, 44],
            [-4, 109],
            [-77, 28],
            [-62, 87],
            [-93, 3],
            [-16, 101],
            [-210, -40],
            [0, 76],
            [-146, 40],
            [3, 69],
            [-61, 54],
            [-1, 90],
            [-93, 103],
            [-102, -40],
            [-145, -21],
            [-83, 109],
            [-114, 10],
            [-75, 37],
            [-101, -121],
            [-107, 24],
            [-19, -85],
            [-47, -31],
            [-72, 55],
            [-8, 126],
            [-70, 3],
            [-21, 98],
            [92, 32],
            [29, 177],
            [83, 0],
            [18, 67],
            [66, 29],
            [39, 82],
            [-50, 104],
            [-86, 58],
            [11, 79],
            [-46, 92],
            [-76, 52],
            [25, 143],
            [152, 64],
            [98, -114],
            [15, -66],
            [67, -64],
            [127, -79],
            [110, -13],
            [139, 74],
            [91, 26],
            [62, 85],
            [94, -60],
            [140, 29],
            [96, -58],
            [28, -64],
            [246, -6],
            [181, 38],
            [90, -71],
            [67, 42],
            [117, -40],
            [252, -19],
            [209, 27],
            [61, -64],
            [86, -13],
            [79, 30],
            [10, 94],
            [105, -12],
            [121, 47],
            [86, 92],
            [-40, 53],
            [26, 50],
            [131, 55],
            [127, -7],
            [167, 118],
        ],
        [
            [40657, 52491],
            [114, -47],
            [90, -106],
            [201, -110],
            [231, 31],
            [69, -24],
            [185, 43],
            [49, 60],
            [123, 30],
            [60, 115],
            [13, 85],
            [167, 98],
            [47, 157],
            [86, 71],
        ],
        [
            [42092, 52894],
            [165, -4],
            [50, -53],
            [68, 5],
            [62, -119],
            [173, -50],
            [7, -171],
            [74, -96],
            [28, -120],
            [58, -6],
            [32, -82],
            [208, -53],
            [45, -70],
            [65, -37],
            [93, -153],
            [9, -146],
            [-49, -63],
            [104, -100],
            [63, -143],
            [129, -42],
            [318, -65],
            [-15, 91],
            [21, 83],
            [105, 25],
            [73, -31],
            [51, -60],
            [7, -161],
            [-38, -130],
            [-31, -20],
            [-78, -132],
            [27, -59],
            [-33, -155],
            [108, -61],
            [40, 66],
            [62, -5],
            [77, -127],
            [-56, -259],
            [16, -131],
            [-77, -124],
            [15, -43],
            [91, -92],
            [60, 8],
            [118, -135],
            [-16, -94],
            [54, -124],
            [66, -51],
            [30, 149],
            [107, -133],
            [170, -44],
            [148, 52],
            [109, -11],
            [52, -107],
            [-38, -190],
            [-153, -71],
            [-30, -145],
            [-54, -35],
            [-15, -69],
            [-70, 5],
            [-97, -87],
            [19, -134],
            [-48, -71],
            [-49, -2],
            [-132, -87],
            [-192, 7],
            [-91, -123],
            [-74, -20],
            [-45, -52],
            [-6, -142],
        ],
        [
            [43982, 48295],
            [-75, -54],
            [183, -112],
            [-5, -90],
            [-72, -84],
            [-36, -99],
            [-94, -34],
            [-145, 5],
            [-51, -58],
            [-99, 11],
            [-108, -81],
            [-5, -58],
            [-64, -17],
            [-73, -95],
            [-72, -47],
            [-147, 13],
            [-132, -60],
            [-71, -84],
            [-39, -111],
            [-135, -169],
            [119, -100],
            [-46, -32],
            [-126, 2],
            [-13, -95],
            [42, -47],
            [-37, -126],
            [145, -107],
            [35, -44],
            [-79, -114],
            [33, -57],
            [-27, -66],
            [-120, 7],
            [16, -111],
            [-125, 43],
            [-44, -101],
            [24, -94],
            [-79, -138],
            [-67, 0],
            [-34, -55],
            [58, -58],
            [119, -47],
            [-56, -98],
            [-62, 37],
            [0, 90],
            [-169, -106],
            [-49, -118],
            [-56, -253],
            [21, -120],
            [57, -23],
            [66, -106],
            [1, -111],
            [-56, -63],
            [-291, 103],
            [-42, -126],
            [35, -185],
            [-68, 24],
            [-80, -75],
            [15, -79],
            [-34, -65],
            [-41, -180],
            [-86, -23],
            [-117, -88],
            [-220, 53],
            [-82, 76],
            [-87, 33],
            [-215, 16],
            [-65, -38],
            [-108, 40],
            [-77, -13],
            [-84, -51],
            [-69, 51],
            [-202, -1],
            [-137, -25],
            [25, -140],
            [-99, -109],
            [37, -81],
            [-49, -88],
            [-64, -57],
            [-81, -130],
            [-193, -123],
            [-16, -154],
            [-50, -31],
            [-122, -27],
            [-40, 105],
            [-93, 4],
            [-58, 58],
            [-37, -127],
            [14, -146],
            [-11, -91],
            [30, -88],
            [-8, -87],
            [64, -108],
            [5, -92],
            [-70, -158],
            [-51, -63],
            [68, -90],
            [88, -38],
            [22, -166],
            [86, 13],
            [29, -92],
            [-54, -66],
            [1, -76],
            [-46, -121],
            [80, -75],
            [16, -91],
            [-64, -42],
            [24, -178],
            [-22, -68],
            [-77, -101],
            [35, -74],
            [13, -203],
            [225, -55],
            [71, -48],
            [117, -5],
            [106, -65],
            [90, -8],
            [100, 36],
            [140, -20],
            [27, -27],
            [-28, -224],
            [28, -167],
            [-33, -65],
            [-147, -5],
            [-75, -112],
            [-113, -29],
            [-17, 149],
            [70, 97],
            [-188, 61],
            [-86, 10],
            [-64, 43],
            [-92, 8],
            [-150, -61],
            [-137, -151],
            [-115, 156],
            [-102, 256],
            [-86, 77],
            [-73, -51],
            [-56, 1],
            [-147, 92],
            [-43, -6],
            [-162, 116],
            [-24, -44],
            [-96, -45],
            [-6, 61],
            [-54, 21],
            [-55, 79],
            [-109, 71],
            [-125, -163],
            [-81, -59],
            [-64, -213],
            [61, -146],
            [108, -61],
            [174, -70],
            [-8, -54],
            [62, -118],
            [202, -13],
            [13, -109],
            [-37, -46],
            [-17, -99],
            [38, -231],
            [-46, -119],
            [32, -130],
            [-65, -108],
            [194, 24],
            [13, -142],
            [62, -108],
            [142, 16],
            [35, -50],
            [-46, -135],
            [-67, -51],
            [105, -47],
            [-34, -121],
            [-44, -74],
            [9, -200],
            [50, -35],
            [14, -74],
            [-30, -73],
            [63, -51],
            [50, -110],
            [-40, -53],
            [78, -114],
            [50, -322],
            [-74, 4],
            [-119, -59],
            [-35, -115],
            [19, -64],
            [-54, -164],
            [-150, -52],
            [-37, -127],
            [-124, 21],
            [-218, -113],
            [-21, -151],
            [-89, 21],
            [-75, -42],
            [183, -102],
            [-21, -79],
            [-129, -6],
            [-11, -82],
            [-94, -32],
            [-37, -84],
            [-244, -226],
            [-47, -191],
            [-243, -18],
            [7, -68],
            [-123, -45],
            [-62, 2],
            [-73, -81],
            [-110, -41],
            [-5, -65],
            [-59, -155],
            [49, -77],
            [-40, -188],
            [-95, -185],
            [6, -160],
            [-87, -151],
            [-21, -134],
            [-115, 34],
            [7, -119],
            [-39, -116],
        ],
        [
            [13971, 41318],
            [8, -72],
            [-104, -134],
            [-52, 63],
            [-126, 18],
            [-53, 41],
            [-113, 10],
            [-73, -35],
            [-77, 96],
            [-87, 37],
            [36, 79],
            [-43, 160],
        ],
        [
            [13287, 41581],
            [-1, 63],
            [-80, 37],
            [-49, 62],
            [62, 73],
            [80, -63],
            [44, 60],
            [155, 0],
            [25, 96],
            [99, 3],
            [29, 87],
            [113, -112],
            [97, -15],
            [-110, -151],
            [-99, -94],
            [-86, -19],
            [5, -171],
            [57, 31],
            [75, -42],
            [90, 58],
            [-29, 66],
            [110, 2],
            [131, -45],
            [-82, -74],
            [5, -92],
            [43, -23],
        ],
        [
            [12980, 42333],
            [-61, -98],
            [19, -89],
            [-65, -105],
        ],
        [
            [12873, 42041],
            [19, 184],
            [88, 108],
        ],
        [
            [7700, 43083],
            [42, -74],
            [-236, 13],
        ],
        [
            [7506, 43022],
            [21, 45],
            [109, 37],
            [64, -21],
        ],
        [
            [7401, 43154],
            [0, -3],
        ],
        [
            [7401, 43151],
            [-40, -64],
            [84, -34],
        ],
        [
            [7445, 43053],
            [-86, -40],
            [-268, 45],
            [-149, 79],
            [-45, 46],
        ],
        [
            [6897, 43183],
            [30, 82],
            [-16, 153],
            [43, 113],
            [66, 84],
            [5, 72],
            [74, 153],
            [75, -82],
            [145, -1],
            [21, 22],
            [131, -38],
            [62, -150],
            [-49, -87],
            [-122, -47],
            [26, -76],
            [113, 4],
            [10, -110],
            [-86, -39],
            [-24, -82],
        ],
        [
            [25018, 66756],
            [51, -11],
            [35, -81],
            [-64, -95],
            [182, -188],
            [129, -58],
            [59, -76],
            [-46, -137],
            [70, -105],
            [-82, -35],
            [-31, -103],
            [118, -152],
        ],
        [
            [25439, 65715],
            [-87, -53],
            [-79, 21],
            [-142, -82],
            [37, -119],
            [-98, -45],
            [-110, -15],
            [-65, 81],
            [-67, 19],
            [-48, 98],
            [18, 50],
            [-99, 78],
            [-43, -18],
            [-113, 56],
            [-29, -54],
            [-103, 38],
            [-67, -42],
            [-180, 31],
            [-118, 97],
            [-13, 74],
            [78, 56],
            [48, 103],
            [91, 2],
            [35, 90],
            [64, 42],
            [-13, 98],
            [24, 85],
            [-35, 166],
            [12, 50],
            [104, 47],
            [147, 0],
            [49, 105],
            [94, 4],
            [126, -27],
            [29, -69],
            [132, 74],
        ],
        [
            [16502, 28309],
            [132, 7],
            [6, 51],
            [134, -58],
            [88, 12],
            [35, -108],
            [-38, -148],
            [51, -34],
            [41, -84],
            [-86, -106],
            [73, -134],
            [-19, -56],
            [40, -124],
            [107, -41],
            [-15, -111],
            [51, -106],
            [-181, -53],
            [-37, -94],
            [108, -41],
            [55, -128],
            [-90, -119],
            [10, -68],
            [-33, -51],
            [17, -95],
            [41, -85],
            [-113, -144],
            [35, -45],
            [-90, -84],
            [-104, -24],
            [-49, 44],
            [-104, -100],
            [-100, -23],
            [-45, -45],
        ],
        [
            [16422, 26114],
            [-151, 66],
            [19, 113],
            [-46, 122],
            [-112, 55],
            [-72, 140],
            [-100, 37],
            [-22, 90],
            [49, 29],
            [21, 79],
            [-94, 368],
            [-55, 170],
            [-57, 110],
            [-91, -28],
            [-92, 49],
            [22, 80],
            [77, 13],
            [108, -30],
            [11, 95],
            [-72, -8],
            [-33, 69],
            [-131, -2],
            [10, 126],
            [-105, -11],
            [-47, 191],
            [-54, 95],
            [3, 82],
            [-124, 298],
            [-22, 13],
        ],
        [
            [15262, 28525],
            [169, -16],
            [20, 34],
            [106, -21],
            [95, 48],
            [126, 168],
            [59, -81],
            [-19, -51],
            [80, -29],
            [102, -2],
            [71, -158],
            [13, -170],
            [75, -57],
            [70, -14],
            [32, 48],
            [115, 16],
            [126, 69],
        ],
        [
            [7401, 43151],
            [44, -98],
        ],
        [
            [7506, 43022],
            [-11, 3],
            [-42, 4],
            [0, 50],
            [-52, 75],
        ],
        [
            [6897, 43183],
            [-78, 0],
            [-167, 53],
            [-98, 66],
            [-137, 57],
            [-210, 114],
            [-89, 90],
            [-80, 20],
            [-123, 77],
            [-261, 208],
            [-193, 164],
            [-54, 66],
            [-107, 77],
            [-277, 294],
            [-636, 756],
            [-166, 186],
            [39, 75],
            [108, 68],
            [-59, 168],
            [-127, 25],
            [-21, -87],
            [97, -130],
            [-25, -85],
            [-77, 27],
            [-241, 254],
            [-49, 2],
            [-139, 119],
            [-176, 181],
            [-102, 72],
            [-161, 174],
            [-67, 39],
            [-230, 198],
            [-100, 98],
            [-489, 538],
            [-214, 250],
            [-80, 107],
            [11, 36],
            [-55, 148],
            [52, 68],
            [5, 90],
            [34, 72],
            [108, 142],
            [199, 114],
            [-13, -72],
            [-86, -66],
            [70, -103],
            [112, 40],
            [41, -29],
            [187, 65],
            [25, -92],
            [-60, -42],
            [-51, -203],
            [129, -122],
            [89, -15],
            [145, 47],
            [121, 85],
            [16, 74],
            [220, -42],
            [101, 57],
            [92, 8],
            [5, 51],
            [94, 87],
            [-3, 119],
            [78, -64],
            [25, -109],
            [112, -54],
            [151, 31],
            [27, 101],
            [96, 7],
            [-23, 159],
            [83, 33],
            [58, -13],
            [143, -158],
            [40, 5],
            [61, 135],
            [89, -15],
            [135, 79],
            [10, 42],
            [60, 71],
            [26, -1],
            [5, 48],
            [105, 43],
            [177, 34],
            [103, -24],
            [220, 14],
            [69, 99],
            [32, 92],
            [55, 37],
            [74, 117],
            [-19, 76],
            [43, 87],
            [130, 92],
            [23, 111],
            [39, 27],
            [50, 126],
            [119, 177],
            [74, 31],
            [54, 111],
            [116, 157],
            [54, 145],
            [-43, 21],
            [-106, -21],
            [-112, -106],
            [-11, -180],
            [-30, -57],
            [-106, -40],
            [-111, 61],
            [-48, -16],
            [-145, 98],
            [-73, -80],
            [-91, -2],
            [-104, -32],
            [-92, -90],
            [-324, -27],
            [-106, -21],
            [-139, -81],
            [-100, 23],
            [-189, -66],
            [-125, -89],
            [-16, -76],
            [-99, -161],
            [-105, 55],
            [-145, 31],
            [-67, -25],
            [-125, 78],
            [-79, 13],
            [-140, -49],
            [-75, 20],
            [-104, 72],
            [-252, 79],
            [-145, -26],
            [-193, 44],
            [-197, 108],
            [-228, 156],
            [-158, 77],
            [-212, 127],
            [-277, 179],
            [-137, 52],
            [-177, 145],
            [-112, 61],
            [-90, 108],
            [-115, 101],
            [76, 18],
            [70, 52],
            [-23, 132],
            [55, 43],
            [-89, 150],
            [-167, 93],
            [5, 90],
            [-50, 17],
            [-56, 104],
            [-57, 41],
            [-30, 463],
            [76, 78],
            [30, 77],
            [80, 84],
            [109, 43],
            [97, 78],
            [79, 128],
            [72, 50],
            [161, 50],
            [-38, 52],
            [-158, 19],
            [-76, -69],
            [-133, 5],
            [-62, -53],
            [-115, -190],
            [-19, 43],
            [-30, 35],
            [27, 52],
            [-8, 48],
            [80, 70],
            [57, 114],
            [115, 66],
            [-69, 56],
            [-25, 5],
            [-54, -1],
            [0, 19],
            [-7, 8],
            [-36, 6],
            [-21, 36],
            [27, 45],
            [563, -3],
            [28, 214],
            [13, 723],
            [24, 29],
            [150, 40],
            [59, -290],
            [140, -27],
            [67, 174],
            [70, 53],
            [65, -114],
            [120, -26],
            [201, 114],
            [317, -96],
            [210, 100],
            [97, 22],
            [144, -32],
            [194, 22],
            [193, -54],
            [277, 66],
            [225, -211],
            [119, -87],
            [281, -23],
            [242, -9],
            [311, 16],
            [70, 40],
            [78, 95],
            [93, 200],
            [110, 46],
            [242, 49],
            [167, 66],
            [202, 38],
            [249, 99],
            [322, 96],
            [102, -55],
            [-54, -125],
            [-58, -40],
            [45, -173],
            [-43, -58],
            [59, -72],
            [172, -45],
            [196, -12],
            [222, 5],
            [133, 71],
            [114, 29],
            [-24, 98],
            [207, 161],
            [107, 42],
            [41, -40],
            [94, 2],
            [115, 99],
            [94, 28],
            [33, 66],
            [-41, 50],
            [-73, 16],
            [-179, -8],
            [-59, 26],
            [21, 233],
            [-46, 53],
            [4, 169],
            [58, 92],
            [70, 23],
            [170, 122],
        ],
        [
            [8103, 54578],
            [34, -35],
            [497, -177],
            [80, 103],
            [84, 32],
            [67, -95],
            [115, 48],
            [81, 61],
            [104, 44],
            [134, 14],
            [223, -24],
            [124, -109],
            [151, 63],
            [82, 0],
            [108, 40],
            [80, -21],
            [-3, -115],
            [97, -20],
            [60, 25],
            [-16, 76],
            [24, 66],
            [153, -29],
            [23, -103],
            [92, 32],
            [73, 55],
            [0, 61],
            [164, 81],
            [90, -24],
            [0, -129],
            [87, -57],
            [96, 2],
            [30, -53],
            [67, -20],
            [123, 70],
            [124, -31],
            [7, -68],
            [-72, -54],
            [57, -87],
            [66, -34],
            [173, 39],
            [80, -150],
            [97, 22],
            [75, -43],
            [-32, -95],
            [54, -66],
            [24, -90],
            [126, 32],
            [-27, 93],
            [118, 158],
            [129, -92],
            [193, -48],
            [97, 11],
            [2, -119],
            [36, -81],
            [70, -87],
            [232, -9],
            [146, 22],
            [91, -43],
            [65, -73],
            [148, 95],
            [29, 49],
            [-65, 50],
            [73, 82],
            [-27, 87],
            [89, 92],
            [26, -50],
            [81, 0],
            [120, 85],
            [36, -109],
            [-3, -107],
            [-59, -75],
            [73, -21],
            [45, -54],
            [147, -53],
            [86, 73],
            [57, -32],
            [-57, -89],
            [-67, 3],
            [-38, -98],
            [-79, 19],
            [-17, -80],
            [-64, -52],
            [-67, -121],
            [-25, -104],
            [113, -131],
            [134, -83],
            [145, -39],
            [-64, -153],
            [143, -100],
            [107, 47],
            [101, 103],
            [22, 153],
            [97, -8],
            [0, -71],
            [123, -106],
            [-10, -155],
            [31, -69],
            [-4, -150],
            [-92, -52],
            [-75, -146],
            [35, -97],
            [-32, -88],
            [42, -73],
            [67, 50],
            [57, -13],
            [84, -77],
            [79, -126],
            [86, -35],
            [-24, -179],
            [70, -72],
            [122, 124],
            [136, -7],
            [115, -82],
            [-29, -45],
            [-2, -95],
            [-36, -127],
            [60, -50],
            [-41, -50],
            [-30, -129],
            [199, 10],
            [36, -108],
            [47, 17],
            [84, 99],
            [53, -70],
            [121, 36],
            [111, -148],
            [77, -173],
            [124, 15],
            [22, 92],
            [43, 22],
            [79, -127],
            [118, -15],
            [44, -109],
            [161, -2],
            [81, -85],
            [10, -110],
            [-40, -59],
            [5, -82],
            [78, -60],
            [78, -16],
            [69, 114],
            [117, -38],
            [61, -66],
            [46, -108],
            [-16, -70],
            [112, -93],
        ],
        [
            [17050, 49872],
            [14, -58],
            [59, -35],
            [61, -147],
            [-82, -47],
            [110, -157],
            [55, -31],
            [49, 55],
            [92, 16],
            [32, -29],
            [45, -160],
            [-40, -127],
            [-72, -16],
            [2, -69],
            [-100, -160],
            [-55, -254],
            [-55, -21],
            [-239, 32],
            [-115, -98],
            [-64, -135],
            [-158, -102],
            [-83, -22],
            [-119, 72],
            [-112, -21],
            [-3, -74],
            [118, -24],
            [-21, -126],
            [63, 13],
            [12, -106],
            [50, -37],
            [49, 96],
            [42, -40],
            [86, 13],
            [141, -14],
            [75, -42],
            [27, -94],
            [-94, -46],
            [-116, -99],
            [-21, -61],
            [-157, 31],
            [-63, 114],
            [-110, -34],
            [-38, -216],
            [54, -51],
            [-6, -135],
            [131, -26],
            [9, -235],
            [18, -103],
            [105, 14],
            [-21, -121],
            [-70, -17],
            [-102, -105],
            [-1, -48],
            [148, -44],
            [-21, -93],
        ],
        [
            [16559, 46648],
            [-277, -92],
            [-66, -48],
            [-400, -197],
            [-105, 0],
            [-40, -77],
            [43, -116],
            [10, -87],
            [123, -92],
            [22, -126],
            [-37, -66],
            [-110, -34],
            [-46, 20],
            [-116, -42],
            [103, -79],
            [26, -130],
            [76, -168],
            [212, 73],
            [104, 9],
            [24, 52],
            [118, 32],
            [446, 19],
            [59, -87],
            [86, 60],
            [92, -40],
            [56, 66],
            [125, -55],
            [-23, -129],
            [-62, -47],
            [-229, -61],
            [-106, 24],
            [-68, -40],
            [-56, 26],
            [-56, -37],
            [-113, 22],
            [-67, -14],
            [-13, -76],
            [-248, -95],
            [-37, -280],
            [-159, -102],
            [-166, 15],
            [13, -73],
            [-46, -59],
            [8, -145],
            [-74, 27],
            [-47, -50],
            [-106, 8],
            [-12, -83],
            [-66, 35],
            [-89, 5],
            [-126, -56],
            [3, 59],
            [-71, 5],
            [47, -102],
            [82, 4],
            [98, -58],
            [45, 34],
            [73, -28],
            [34, -183],
            [51, 0],
            [52, -60],
            [64, 8],
            [24, -76],
            [250, -38],
            [-27, -147],
            [56, -51],
            [40, -84],
            [0, -110],
            [38, -25],
            [-11, -207],
            [25, -77],
            [-79, -55],
            [-89, 23],
            [-72, -108],
            [-58, 24],
            [10, -229],
            [-112, -66],
            [-54, 4],
            [-107, -102],
            [-130, 32],
            [-99, -45],
            [-81, 49],
            [-40, 132],
            [-88, 58],
            [-78, 8],
            [-91, 74],
            [-87, -57],
            [-4, 94],
            [-41, 90],
            [-67, -6],
            [-52, -145],
            [-101, -36],
            [67, -80],
            [35, -87],
            [113, -34],
            [-12, -256],
            [-77, -84],
            [-72, -151],
            [-70, -46],
            [48, -51],
            [62, -288],
            [-48, -73],
            [19, -161],
            [-141, -21],
            [-180, 37],
            [-48, -40],
            [-2, -114],
            [-86, -78],
            [-129, -16],
        ],
        [
            [13287, 41581],
            [-118, 30],
            [-122, 12],
            [-34, -54],
            [-60, -1],
            [-46, -71],
            [-13, -79],
            [-75, -24],
            [-40, -53],
            [-139, 17],
        ],
        [
            [12640, 41358],
            [26, 186],
            [-5, 91],
            [29, 178],
            [54, 37],
            [2, 82],
            [87, 56],
            [40, 53],
        ],
        [
            [12980, 42333],
            [78, 127],
            [-3, 111],
            [30, 100],
            [-30, 158],
            [-32, 27],
            [-67, 268],
            [16, 66],
            [-62, 46],
            [11, 50],
            [-129, 284],
            [-19, 75],
            [-51, -1],
            [-82, 182],
            [-38, 151],
            [78, 76],
            [-129, 32],
            [-16, 79],
            [45, 116],
            [-97, 52],
            [-45, -13],
            [-2, -15],
            [34, 21],
            [92, -42],
            [-65, -148],
            [-61, 169],
            [-16, -175],
            [-90, 3],
            [-21, 388],
            [-61, 79],
            [-11, 180],
            [88, 113],
            [3, 90],
            [108, 132],
            [-52, 21],
            [54, 132],
            [25, 121],
            [-132, -56],
            [-79, 124],
            [20, 101],
            [62, 108],
            [125, 32],
            [89, 71],
            [53, -38],
            [78, 32],
            [98, -15],
            [-141, 131],
            [-71, -32],
            [-317, 6],
            [-138, -66],
            [-64, 64],
            [-18, 97],
            [72, 85],
            [22, 144],
            [47, 93],
            [-144, 103],
            [-40, 219],
            [13, 173],
            [112, 452],
            [60, 111],
            [91, 74],
            [158, -1],
            [115, -82],
            [161, -31],
            [116, 184],
            [52, 30],
            [166, -69],
            [-94, 169],
            [-245, -148],
            [-179, 18],
            [-151, 137],
            [-80, -15],
            [-97, -71],
            [-113, -9],
            [-72, -60],
            [-54, -98],
            [-37, 9],
            [55, 123],
            [-66, 0],
            [-97, 170],
            [-54, -48],
            [1, -167],
            [-44, -73],
            [-51, 49],
            [-43, -55],
            [65, -49],
            [-9, -69],
            [-85, -22],
            [-94, -89],
            [-118, -448],
            [-80, 57],
            [26, 93],
            [-85, 85],
            [4, -130],
            [-34, -161],
            [102, -169],
            [11, -308],
            [29, -79],
            [-62, -17],
            [89, -179],
            [72, -179],
            [-94, -145],
            [-50, -219],
            [-54, -134],
            [-95, -129],
            [-220, -256],
            [-31, -111],
            [-79, -119],
            [81, -77],
            [11, -50],
            [-164, -86],
            [-24, -33],
            [-156, -53],
            [-110, -70],
            [-142, -30],
            [-88, -53],
            [-202, -94],
            [-102, -109],
            [-110, 4],
            [-115, -70],
            [-113, -23],
            [-6, -48],
            [-297, -89],
            [-41, 16],
            [-105, -171],
            [-105, -59],
            [-97, -2],
            [-91, -43],
            [-136, -23],
            [-336, -166],
            [-115, -22],
            [-33, -69],
            [-176, -4],
            [-15, -51],
            [-80, -20],
            [-167, 42],
            [-82, -50],
        ],
        [
            [3987, 48117],
            [80, -99],
            [-54, -55],
            [-126, 63],
            [5, 50],
            [95, 41],
        ],
        [
            [4797, 48390],
            [36, -22],
            [27, -47],
            [0, -32],
            [-73, -19],
            [-56, 1],
            [15, 95],
            [51, 24],
        ],
        [
            [5049, 48506],
            [51, -74],
            [-55, -38],
            [-122, -26],
            [8, 96],
            [118, 42],
        ],
        [
            [6271, 49841],
            [-67, -153],
            [-32, 50],
            [99, 103],
        ],
        [
            [1012, 50602],
            [17, -34],
            [112, -7],
            [29, -82],
            [-156, -53],
            [17, 85],
            [-19, 91],
        ],
        [
            [774, 51086],
            [61, -66],
            [-27, -154],
            [-51, 5],
            [-48, 127],
            [65, 88],
        ],
        [
            [800, 51426],
            [8, -64],
            [-46, -65],
            [-74, 31],
            [112, 98],
        ],
        [
            [456, 52032],
            [110, -60],
            [78, 32],
            [-29, -140],
            [8, -67],
            [-78, -203],
            [-89, -79],
            [-32, -86],
            [-145, -56],
            [-86, 69],
            [-58, -25],
            [-132, 29],
            [2, 101],
            [202, 32],
            [34, 60],
            [157, 172],
            [-8, 86],
            [66, 135],
        ],
        [
            [316, 52087],
            [12, -8],
            [27, -47],
            [-19, -119],
            [24, -89],
            [-80, -21],
            [-78, -63],
            [-27, 31],
            [23, 34],
            [8, 71],
            [-12, 24],
            [39, 108],
            [55, 19],
            [-3, 26],
            [-19, 13],
            [50, 21],
        ],
        [
            [840, 52108],
            [73, -81],
            [-29, -43],
            [8, -28],
            [20, -11],
            [-15, -58],
            [-81, -53],
            [-140, 17],
            [52, 91],
            [-25, 93],
            [137, 73],
        ],
        [
            [559, 52244],
            [59, -22],
            [62, -95],
            [-39, -81],
            [-69, -61],
            [-142, 58],
            [-19, 81],
            [148, 120],
        ],
        [
            [150, 52251],
            [8, -53],
            [-50, -58],
            [-49, 71],
            [91, 40],
        ],
        [
            [709, 52420],
            [18, -11],
            [-10, -155],
            [-70, -50],
            [-29, 23],
            [-59, 22],
            [-110, -58],
            [0, 126],
            [85, 8],
            [175, 95],
        ],
        [
            [948, 52441],
            [19, -35],
            [42, -5],
            [6, -10],
            [-1, -16],
            [57, 0],
            [8, -23],
            [-93, -116],
            [-78, -54],
            [-84, 32],
            [-11, 67],
            [130, 96],
            [5, 64],
        ],
        [
            [800, 52576],
            [54, -58],
            [46, -140],
            [-105, -98],
            [-73, -26],
            [6, 155],
            [-17, 13],
            [-61, -8],
            [59, 132],
            [91, 30],
        ],
        [
            [657, 52586],
            [3, -105],
            [-82, -113],
            [-145, -35],
            [-6, -137],
            [-29, -122],
            [-65, 13],
            [-22, 3],
            [-34, -13],
            [-16, -10],
            [0, -8],
            [21, -13],
            [0, -17],
            [-61, -20],
            [-33, -109],
            [11, -40],
            [-33, -78],
            [35, -69],
            [-80, -44],
            [-121, 136],
            [51, 106],
            [-10, 139],
            [18, 72],
            [53, 0],
            [54, 66],
            [70, 166],
            [131, -5],
            [44, 29],
            [16, 113],
            [73, 61],
            [69, -50],
            [88, 84],
        ],
        [
            [24022, 72275],
            [49, 83],
            [-51, 115],
            [-75, 31],
            [-90, 98],
            [3, 87],
        ],
        [
            [23858, 72689],
            [113, 70],
            [33, -123],
            [83, -51],
            [139, 79],
            [68, -126],
            [2, -50],
            [73, -76],
            [123, 8],
            [43, -209],
            [94, 19],
            [155, -51],
            [141, -123],
            [24, -146],
            [-12, -115],
            [-128, -91],
            [72, -86],
            [132, -35],
            [49, -134],
            [136, 11],
            [33, -51],
            [131, -37],
            [99, 24],
            [30, -58],
            [65, 13],
            [42, -74],
            [86, 58],
            [76, -2],
            [-52, -106],
            [158, 39],
            [51, 91],
            [92, -148],
            [88, -37],
        ],
        [
            [26097, 71172],
            [48, -40],
            [-61, -100],
            [2, -74],
            [-107, -71],
            [-101, -156],
            [-63, -129],
            [-74, -60],
            [-65, 0],
            [-35, -64],
            [9, -103],
            [-113, -53],
            [-114, -82],
            [-172, -47],
            [6, -42],
            [-62, -132],
            [-196, -250],
            [8, -85],
            [-75, -164],
            [-10, -70],
            [-94, -51],
            [-16, -77],
            [77, -92],
            [-56, -108],
            [25, -63],
            [-55, -34],
            [-67, -133],
            [67, -139],
            [-69, -58],
            [107, -137],
            [-14, -87],
            [59, -42],
            [-40, -58],
            [38, -111],
            [-61, -21],
            [10, -79],
            [72, -13],
            [22, -82],
            [-67, -127],
            [37, -103],
            [-45, -27],
            [35, -134],
            [-36, -82],
            [33, -57],
            [-40, -96],
            [93, -69],
            [14, -86],
            [143, -124],
            [-65, -127],
            [40, -74],
            [-22, -45],
            [55, -60],
            [-97, -32],
            [13, -66],
        ],
        [
            [25439, 65715],
            [62, -32],
            [105, -114],
            [76, -11],
            [29, -68],
            [108, -53],
            [-16, -57],
            [71, -104],
            [-78, -49],
            [82, -99],
            [-9, -78],
            [56, -77],
            [8, -71],
            [62, -113],
            [-18, -59],
            [-91, -21],
            [0, -118],
            [-71, -55],
            [12, -159],
            [161, -163],
            [2, -79],
            [-46, -99],
            [-142, 3],
            [3, -111],
            [-76, -47],
            [-56, 47],
            [-58, -37],
            [31, -66],
            [-203, 1],
            [-75, -85],
            [-103, -69],
        ],
        [
            [25265, 63672],
            [-113, -62],
            [-35, 36],
            [-93, 1],
            [-46, 54],
            [-62, -12],
            [-8, -75],
            [-129, -4],
            [-80, 70],
            [-94, 30],
            [-41, -95],
            [151, -145],
            [-68, -27],
            [-59, 59],
            [-106, -19],
            [-41, -103],
            [-24, -143],
            [-102, 51],
            [-68, -53],
            [-50, 68],
            [64, 61],
            [-72, 55],
            [19, 193],
            [34, 145],
            [52, -5],
            [-1, 174],
            [-21, 89],
            [18, 158],
            [-18, 54],
            [78, 214],
            [42, 211],
            [-56, 15],
            [-161, 126],
            [-56, 95],
            [-84, 0],
            [-120, -91],
            [3, -106],
            [-102, -30],
            [-44, -50],
            [-82, 8],
            [-76, -100],
            [-96, -16],
            [10, -139],
            [25, -74],
            [-170, -27],
            [-147, -111],
            [-43, 22],
            [21, 174],
            [-75, -11],
            [-54, 34],
            [-101, -2],
            [39, 116],
            [-35, 71],
            [113, -11],
            [-70, 125],
            [-99, 7],
            [-214, -25],
            [-19, 66],
            [-124, 50],
            [-85, -45],
            [216, -116],
            [-67, -61],
            [16, -100],
            [44, -21],
            [-46, -104],
            [-56, -24],
            [-73, 24],
            [0, 56],
            [-110, 63],
            [-48, -40],
            [-156, 6],
            [72, -84],
            [-82, -24],
            [-55, -85],
            [71, -58],
            [-27, -182],
            [80, -32],
            [-24, -52],
            [45, -97],
            [-93, -117],
            [-124, 74],
            [-18, 63],
            [-94, 40],
            [-22, -52],
            [-84, -8],
            [-114, 44],
            [-92, 5],
            [5, 130],
            [-42, 79],
            [16, 74],
            [-36, 77],
            [52, 141],
            [101, 0],
            [-24, 69],
            [-67, 68],
            [-115, 29],
            [115, 96],
            [48, 68],
            [92, 66],
            [131, -2],
            [-62, 150],
            [-115, 52],
            [20, 114],
            [-90, 43],
            [-83, 124],
            [-81, 20],
            [-13, 69],
            [-140, 63],
            [-104, 21],
            [-89, 46],
            [-69, 128],
            [-119, 43],
            [3, 42],
            [-129, 35],
            [-69, 100],
            [-100, 37],
            [-56, 174],
            [-148, 24],
            [-45, 102],
            [-25, 243],
            [-40, 116],
            [-49, 44],
            [39, 141],
            [-70, 68],
            [2, 113],
            [-61, 80],
            [86, 45],
            [53, 99],
            [-16, 104],
            [-105, 26],
            [-105, -10],
            [16, 73],
            [-31, 67],
            [-137, 17],
            [0, 116],
            [43, 51],
            [-99, 42],
            [23, 79],
            [89, 55],
            [-37, 117],
            [32, 34],
            [-54, 68],
            [-81, -47],
            [-15, -63],
            [-84, 29],
            [29, 124],
            [-16, 42],
            [-118, -56],
            [-16, -57],
            [-101, -6],
            [-31, -50],
            [-80, 26],
            [-43, 71],
            [-89, -65],
            [-193, -40],
            [-152, 174],
            [-90, -58],
            [-106, 29],
            [-8, 106],
            [-65, 135],
            [-217, 110],
            [-110, -13],
            [-94, -111],
            [-122, -19],
            [-56, 45],
            [-171, 9],
            [-24, -119],
            [-127, -8],
            [11, 74],
            [-75, 95],
            [-9, 57],
            [-107, 54],
            [9, 116],
            [123, -17],
            [91, 211],
            [-112, 70],
            [-3, 87],
            [23, 202],
            [70, 193],
            [6, 92],
            [-188, 3],
            [-35, -35],
            [-150, 6],
            [-19, 127],
            [75, 113],
            [113, 39],
            [59, 75],
            [-56, 108],
            [-79, 15],
            [38, 104],
        ],
        [
            [17595, 69888],
            [151, -51],
            [35, -32],
            [150, -32],
            [0, 53],
            [116, 79],
            [40, 64],
            [78, -27],
            [145, 37],
            [70, 53],
            [134, -98],
            [63, 0],
            [145, -52],
            [54, -113],
            [73, -37],
            [57, -98],
            [131, 74],
            [80, -8],
            [33, 97],
            [70, -45],
            [-6, -84],
            [-41, -68],
            [46, -122],
            [63, 8],
            [160, 96],
            [51, -25],
            [-8, -97],
            [92, -129],
            [-92, -61],
            [-30, -55],
            [4, -92],
            [-82, -32],
            [39, -116],
            [134, -67],
            [-28, -70],
            [74, -90],
            [147, 29],
            [38, 79],
            [-67, 44],
            [75, 83],
            [29, 111],
            [123, 124],
            [47, -6],
            [51, 145],
            [130, 74],
            [1, 63],
            [172, -98],
            [139, -25],
            [21, -64],
            [165, 29],
            [7, 56],
            [113, -1],
            [87, -50],
            [24, 155],
            [118, 9],
            [66, 39],
            [67, -53],
            [65, 24],
            [124, -184],
            [93, 12],
            [102, -70],
            [106, 0],
            [88, 55],
            [105, -6],
            [91, 93],
            [10, 72],
            [106, -27],
            [64, 60],
            [77, 3],
            [76, 79],
            [96, 27],
            [-8, 89],
            [-120, 26],
            [-9, 151],
            [68, -5],
            [-41, 174],
            [27, 90],
            [88, 132],
            [1, 82],
            [-73, 57],
            [17, 82],
            [112, -108],
            [118, -2],
            [99, -25],
            [55, 53],
            [115, -2],
            [32, 89],
            [-57, 74],
            [51, 45],
            [82, -56],
            [-7, -94],
            [71, -135],
            [109, -10],
            [28, -61],
            [91, 18],
            [59, 46],
            [96, -56],
            [83, 47],
            [28, 116],
            [-17, 103],
            [44, 103],
            [-76, 58],
            [-59, 82],
            [223, 188],
            [75, 17],
            [113, 103],
            [-105, 98],
            [134, 97],
            [165, -46],
            [69, 66],
            [89, -74],
            [91, -135],
            [83, 77],
            [-104, 139],
            [12, 155],
            [51, 12],
            [20, 91],
            [-97, 45],
            [80, 170],
            [-39, 62],
            [-114, 59],
            [12, 90],
            [-122, -33],
            [3, 74],
        ],
        [
            [23858, 72689],
            [-121, 97],
            [-39, 69],
            [-122, 20],
            [-163, 98],
            [10, 51],
            [-40, 94],
            [73, 164],
            [-100, 52],
            [25, 67],
            [-22, 92],
            [124, 89],
            [-180, 194],
            [-68, -70],
            [-98, 79],
            [-60, -28],
            [-62, 106],
            [-107, 46],
            [-104, 154],
            [-1, 55],
            [-88, 53],
            [-119, -39],
            [38, -66],
            [1, -101],
            [-79, -55],
            [-266, -61],
            [-93, 5],
            [-19, 101],
            [-73, 34],
            [-15, 85],
            [63, 65],
            [-80, 113],
            [-56, 149],
            [-68, 123],
            [-225, 319],
            [6, 46],
            [-76, 123],
            [-91, 222],
            [-64, 105],
            [86, 19],
            [-9, 71],
            [-153, 295],
            [-163, 93],
            [-113, 19],
            [-171, 142],
            [-65, -3],
            [-51, 68],
            [-119, 53],
            [-142, -34],
            [-56, 47],
            [89, 51],
            [125, 135],
            [3, 89],
            [-101, 26],
            [8, 143],
            [149, 85],
            [167, 28],
            [298, 307],
            [80, 57],
            [148, 39],
            [25, 38],
            [-52, 69],
            [-168, 147],
            [-12, 64],
            [59, 160],
        ],
        [
            [21361, 77547],
            [143, 196],
            [-69, 108],
            [37, 221],
            [-107, 166],
            [-148, 84],
            [-72, 80],
            [-20, 68],
            [57, 104],
            [164, 2],
            [93, -42],
            [92, -92],
            [233, 99],
            [181, 148],
            [32, 104],
            [163, 54],
            [236, 27],
            [51, 58],
            [54, 150],
            [234, 156],
            [70, 72],
            [236, -8],
            [75, 47],
            [143, 40],
            [109, -16],
            [36, -88],
            [77, -33],
            [194, 55],
            [93, -6],
            [83, 132],
            [9, 87],
            [94, -39],
            [69, 21],
            [-66, -219],
            [15, -51],
            [135, -128],
            [70, 8],
            [112, -235],
            [124, -58],
            [101, -71],
            [103, 29],
            [110, -75],
            [78, 40],
            [94, -19],
            [-1, -66],
            [59, -129],
            [68, 5],
            [128, -57],
            [168, -125],
            [47, 3],
            [44, -90],
            [98, 8],
            [6, 90],
            [57, 71],
            [129, 8],
            [82, -65],
            [83, 73],
            [97, 3],
            [172, 150],
            [87, 6],
            [114, 53],
            [31, 78],
            [133, -42],
            [216, -193],
            [-79, -116],
            [175, -74],
            [100, -68],
            [18, -76],
            [142, -98],
            [-42, -185],
            [48, -41],
            [156, -254],
            [165, 0],
            [23, 108],
            [66, -10],
            [71, 107],
            [64, -58],
            [54, 80],
            [96, 21],
            [38, -39],
            [115, 36],
            [32, 53],
            [164, 53],
            [-22, 73],
            [159, 75],
            [77, -9],
            [15, -120],
            [-41, -136],
            [22, -99],
            [48, -43],
            [-158, -113],
            [-97, -19],
            [7, -142],
            [-58, -63],
            [89, -93],
            [200, 156],
            [79, -52],
            [106, -62],
            [-29, -99],
            [67, -24],
            [43, -64],
            [113, -21],
            [-69, -121],
            [-71, -55],
            [-19, -61],
            [8, -172],
            [-69, -92],
            [121, -10],
            [167, 16],
            [139, -87],
            [3, -88],
            [-54, -87],
            [61, -102],
            [143, -46],
            [13, -55],
            [103, -124],
            [133, -122],
            [94, -12],
            [-72, -219],
            [-56, -116],
            [-13, -143],
            [-76, -61],
            [-5, -62],
            [120, -294],
            [126, -7],
            [12, -82],
            [118, -98],
            [-9, -79],
            [-72, -71],
            [-132, -80],
            [-80, -23],
            [-40, -56],
            [26, -100],
            [78, 9],
            [142, -109],
            [-7, -103],
            [-46, -44],
            [142, -62],
            [37, -78],
            [-32, -71],
            [108, -82],
            [32, -90],
            [76, -34],
        ],
        [
            [29803, 73715],
            [8, -72],
            [72, -36],
            [30, -83],
            [72, -33],
            [16, -106],
            [53, -80],
            [-152, -37],
            [-107, 29],
            [-66, -33],
            [-171, 126],
            [-65, 167],
            [-128, -30],
            [-274, 9],
            [-41, 65],
            [-99, 47],
            [-94, -7],
            [-81, -79],
            [-187, -8],
            [-11, 50],
            [-131, 113],
            [-73, 8],
            [-65, 74],
            [-200, 2],
            [-81, -50],
            [-100, -107],
            [-72, 7],
            [-62, -40],
            [-102, 24],
            [-155, -113],
            [-173, -21],
            [-63, -84],
            [-106, 24],
            [-40, 41],
            [-147, -58],
            [-65, -100],
            [-75, -39],
            [-24, -87],
            [-105, -79],
            [54, -143],
            [-80, -101],
            [-116, 19],
            [-59, -79],
            [35, -79],
            [132, 10],
            [-27, -157],
            [-108, 26],
            [-37, -88],
            [35, -42],
            [-110, -166],
            [-46, -31],
            [148, -169],
            [-22, -72],
            [91, -58],
            [25, -71],
            [-119, -100],
            [60, -108],
            [67, -37],
            [79, 11],
            [17, -66],
            [-35, -77],
            [-89, -21],
            [-295, -163],
            [-86, -37],
            [-73, 5],
            [-115, -59],
            [0, -37],
        ],
        [
            [26065, 71229],
            [32, -57],
        ],
        [
            [54510, 56149],
            [-46, -199],
            [5, -154],
            [57, -68],
            [200, -105],
            [108, -120],
            [8, -36],
            [153, -145],
            [33, -116],
            [-19, -43],
            [-153, -74],
            [-38, -39],
            [-89, -200],
            [-74, -104],
            [171, -49],
            [-40, -125],
            [70, -47],
            [-27, -143],
            [23, -55],
            [-63, -92],
            [-110, -34],
            [-145, 34],
            [8, -206],
            [53, -68],
            [-83, -106],
            [27, -145],
            [-100, -142],
            [-15, -87],
            [-81, -50],
            [-127, -116],
            [-94, 7],
            [-27, -108],
            [92, -18],
            [72, -88],
            [-21, -74],
            [-172, 38],
            [-132, -5],
            [70, -85],
            [-64, -24],
            [-33, -103],
            [-87, 0],
            [-117, 80],
            [-15, -186],
            [27, -86],
            [-84, -34],
            [-89, -95],
            [-137, 71],
            [-93, 20],
            [-54, -41],
            [-61, 28],
            [48, 77],
            [-162, 3],
            [-85, 29],
            [-30, -51],
            [73, -158],
            [91, -42],
            [-19, -63],
            [21, -127],
            [-77, -47],
            [-57, 76],
            [3, -211],
            [-70, -2],
            [6, 87],
            [-97, 0],
            [-143, 32],
            [-16, -51],
            [100, -90],
            [-49, -73],
            [-118, 15],
            [-137, 48],
            [-64, 60],
            [-198, 82],
            [-55, -65],
            [-53, 68],
            [-80, 40],
            [-142, -169],
            [-129, -71],
            [51, -61],
            [-68, -259],
            [-148, -28],
            [-77, 57],
            [-174, -42],
            [-161, -60],
            [-173, -96],
            [-131, 17],
            [-100, -11],
            [-109, -127],
            [-134, -129],
            [-17, -232],
            [-144, -58],
            [-210, -24],
            [-11, 60],
            [-115, 56],
            [-102, 6],
            [35, 78],
            [-33, 67],
            [27, 71],
            [-40, 53],
            [-282, 39],
            [-73, -63],
            [84, -208],
            [-59, -6],
            [-228, -95],
            [-89, 47],
            [-89, 14],
            [-42, -74],
            [12, -119],
            [43, -5],
            [46, -90],
            [-81, -36],
            [15, -101],
            [-28, -77],
            [-97, -118],
            [24, -208],
            [62, 8],
            [55, -103],
            [104, -87],
            [81, 0],
            [105, 49],
            [153, -7],
            [21, -93],
            [132, -60],
            [66, 7],
            [62, -37],
            [-8, -52],
            [113, -145],
            [70, 7],
            [32, -68],
            [137, 10],
            [51, 45],
            [147, -65],
            [76, 24],
            [91, -66],
            [65, 60],
            [131, -27],
            [96, 12],
            [41, -67],
            [-70, -90],
            [-48, 32],
            [-105, -90],
            [86, -55],
            [-84, -105],
            [3, -93],
            [-50, -152],
            [106, -48],
            [70, -109],
            [196, -33],
            [87, -64],
            [104, -45],
            [51, -53],
            [-6, -168],
            [47, -64],
            [152, 13],
            [138, -18],
            [24, -68],
            [56, -19],
            [14, -201],
            [-122, -13],
            [51, -139],
            [116, -18],
            [108, -59],
            [-43, -208],
            [122, -76],
            [44, -50],
            [-8, -85],
            [-182, 26],
            [-79, -126],
            [-123, -29],
            [-68, 21],
        ],
        [
            [51550, 47406],
            [-114, 13],
            [-94, 47],
            [-8, 76],
            [-156, 59],
            [-30, 47],
            [-150, 14],
            [-70, 111],
            [-210, -103],
            [-198, 116],
            [-182, 232],
            [-63, 41],
            [-125, 29],
            [-49, 61],
            [-280, 58],
            [-91, 153],
            [-85, 59],
            [-28, -83],
            [-91, -75],
            [-74, -25],
            [-43, -129],
            [110, -98],
            [43, 6],
            [37, -124],
            [-14, -56],
            [-66, -71],
            [75, -26],
            [-6, -71],
            [-134, -179],
            [158, -169],
            [-89, -154],
            [22, -55],
            [-167, -274],
            [-39, 0],
            [-95, -119],
            [-306, 2],
            [-66, 32],
            [-12, 54],
            [63, 81],
            [-24, 43],
            [50, 76],
            [118, 0],
            [-80, 97],
            [-221, -152],
            [-114, -29],
            [-103, 123],
            [-148, -47],
            [-294, 123],
            [-210, 116],
            [-131, -5],
            [-4, -87],
            [-107, 0],
            [-136, -131],
            [-94, -196],
            [-71, -5],
            [-13, 93],
            [-193, 89],
            [-60, -21],
            [-58, 89],
            [-151, 40],
            [-67, -21],
            [-50, -68],
            [-60, 32],
            [119, 62],
            [5, 122],
            [123, 224],
            [3, 119],
            [96, 56],
            [25, 67],
            [-78, 8],
            [19, 70],
            [-51, 21],
            [24, 87],
            [-24, 103],
            [-48, 91],
            [45, 82],
            [-61, 19],
            [-253, -105],
            [-121, -27],
            [-26, -42],
            [-78, -6],
            [-4, 72],
            [-118, -45],
            [-96, 48],
            [-135, -4],
            [-26, -81],
            [-228, 0],
            [-83, 42],
            [-360, -40],
            [-78, -37],
            [-5, -57],
            [-68, -11],
            [-61, -77],
            [-87, -25],
            [-63, 15],
            [-231, -34],
            [-63, 29],
            [-56, 76],
            [-147, -5],
            [-114, 85],
            [-47, 57],
            [5, 135],
            [-88, 21],
            [-133, 105],
            [-153, 30],
        ],
        [
            [42092, 52894],
            [71, 72],
            [82, 216],
            [-5, 88],
            [70, 102],
            [-18, 80],
            [-55, 58],
            [76, 71],
            [86, 28],
            [26, 74],
            [-37, 90],
            [-86, 24],
            [5, 73],
            [-54, 53],
            [33, 133],
            [91, 34],
            [201, 42],
        ],
        [
            [26028, 35994],
            [148, -41],
            [-84, -108],
            [33, -69],
            [-41, -82],
            [27, -77],
            [49, -31],
            [0, -196],
            [-84, -23],
            [-59, -66],
            [19, -55],
            [150, -50],
            [131, -141],
            [-27, -66],
            [-67, -7],
            [-8, -121],
            [-88, 16],
            [19, -83],
            [-70, -39],
            [-35, -103],
            [-80, -10],
            [-59, -130],
            [139, -63],
            [21, -39],
            [-63, -64],
            [-125, -27],
            [-102, -49],
            [-34, -122],
            [14, -74],
            [-30, -147],
            [140, -45],
            [32, -74],
            [72, 95],
            [59, -69],
            [102, 27],
            [18, -76],
            [81, -30],
            [100, -2],
            [86, -67],
            [-11, -75],
            [-99, 25],
            [-103, -33],
            [-2, -75],
            [-103, 30],
            [-2, -72],
            [-110, 32],
            [-64, -40],
            [30, -189],
            [-180, -50],
            [-62, -164],
            [56, -37],
            [-206, -163],
            [-55, -169],
            [44, -71],
            [77, -40],
            [162, -56],
            [102, -282],
            [-57, -48],
            [3, -66],
            [-43, -44],
            [11, -103],
            [-40, -24],
            [20, -190],
            [-12, -58],
            [43, -81],
            [-12, -56],
            [-83, -40],
            [-40, -142],
            [126, -16],
            [-10, -89],
            [-135, -45],
            [46, -74],
            [107, 18],
            [-50, -106],
            [8, -76],
            [-98, -47],
            [-15, -137],
            [-97, -17],
            [-41, -81],
            [-115, -10],
            [-204, -37],
            [37, -111],
            [55, -77],
            [190, -77],
            [56, 9],
            [108, -51],
            [73, 35],
            [122, 10],
            [103, -23],
            [46, -50],
            [136, -72],
            [17, -45],
            [-185, -124],
            [-63, -7],
            [-39, -51],
            [21, -182],
            [-32, -84],
            [10, -124],
            [44, -124],
            [-33, -124],
            [54, -84],
            [13, -113],
            [-16, -122],
            [-116, 19],
            [-115, 42],
            [-139, -26],
            [-62, 31],
            [-199, 10],
            [-92, 32],
            [-190, -23],
            [-104, -29],
            [-186, -90],
            [-121, -162],
            [-15, -83],
            [99, -4],
            [-3, -133],
            [-43, -78],
            [0, -76],
            [83, -11],
            [-2, -89],
            [136, -92],
            [-24, -100],
            [-97, 82],
            [-34, -35],
            [-118, -34],
            [-8, -154],
            [7, -221],
            [-155, -27],
            [99, -124],
            [59, -39],
            [3, -97],
            [43, -51],
            [-16, -73],
            [67, -61],
            [19, -55],
            [112, 24],
            [16, -109],
            [90, -11],
            [-17, -197],
            [64, -145],
            [-47, -179],
            [-55, -38],
            [-7, -76],
            [-44, -114],
            [-77, -18],
            [-9, -66],
            [-106, 18],
            [18, 69],
            [-127, 0],
            [-96, -48],
            [-148, 51],
            [-102, -5],
            [-47, 81],
            [-175, 100],
            [-68, -76],
            [33, -140],
            [-76, -98],
            [17, -39],
            [217, -24],
            [65, 6],
            [-13, -211],
            [-55, -178],
            [-18, -131],
            [-148, -16],
            [49, -130],
            [-75, -176],
            [-30, -226],
            [50, -51],
            [59, -148],
            [122, -55],
            [56, -101],
            [120, 29],
            [184, -18],
            [-41, -95],
            [-40, -2],
            [-6, -132],
            [-80, 40],
            [-83, -67],
            [-13, -113],
            [56, -93],
            [123, -41],
            [3, -69],
            [-52, -27],
            [36, -94],
            [183, -26],
            [128, 25],
            [72, -87],
            [95, 33],
            [7, 194],
            [54, 124],
            [57, 22],
            [123, -69],
            [135, 2],
            [99, 46],
            [-1, -159],
            [220, -19],
            [-10, -115],
            [59, -38],
            [19, -73],
            [88, 34],
            [-40, 166],
            [-72, 101],
            [54, 99],
            [133, -58],
            [4, -92],
            [98, 17],
            [55, -67],
            [-24, -95],
            [56, -163],
            [-28, -61],
            [-102, 39],
            [-206, 3],
            [-35, -105],
            [-80, -14],
            [11, -55],
            [104, 39],
            [16, -46],
            [-64, -91],
            [-95, -54],
            [-34, -90],
            [159, -43],
            [8, -98],
            [96, 9],
            [24, -98],
            [-59, -21],
            [30, -121],
            [-196, 5],
            [-16, 47],
            [56, 88],
            [-53, 23],
            [29, 92],
            [-57, 17],
            [-56, 107],
            [-96, -52],
            [-20, 63],
            [-80, -64],
            [-134, -10],
            [-65, 16],
            [-48, -52],
            [-54, 21],
            [-37, 128],
            [-84, -10],
            [-187, 42],
            [0, 88],
            [-38, 26],
            [27, 234],
            [-172, 8],
            [-113, -52],
            [-73, 3],
            [-21, -80],
            [164, -55],
            [24, -79],
            [46, -16],
            [10, -79],
            [-93, -14],
            [-19, -73],
            [175, -119],
            [-14, -72],
            [131, -91],
            [14, -82],
            [-67, -11],
            [-30, -126],
            [-104, -117],
            [66, -197],
            [52, -27],
            [24, 108],
            [42, -2],
            [70, -88],
            [97, 62],
            [78, -29],
            [121, 12],
            [13, 50],
            [-48, 83],
            [22, 44],
            [-63, 127],
            [71, 21],
            [-25, 98],
            [75, 4],
            [-23, -116],
            [50, -15],
            [92, 113],
            [40, -3],
            [29, -160],
            [157, 53],
            [36, -91],
            [90, 54],
            [58, -38],
            [143, -13],
            [-44, -81],
            [117, -40],
            [32, -122],
            [-47, -139],
            [12, -58],
            [74, 5],
            [11, 76],
            [134, 9],
            [-23, 108],
            [54, -1],
            [24, -68],
            [93, 29],
            [91, 87],
            [156, 51],
            [70, -48],
            [92, 119],
            [114, 18],
            [14, 69],
            [78, 24],
            [70, 60],
            [-59, 166],
            [22, 37],
            [133, -11],
            [138, -86],
            [109, 89],
            [62, -10],
            [14, -90],
            [-100, -66],
            [17, -134],
            [48, -13],
            [54, 121],
            [147, -27],
            [8, 100],
            [84, -44],
            [11, -50],
            [87, -3],
            [30, -93],
            [-26, -54],
            [29, -75],
            [-92, -127],
            [67, -68],
            [-10, -74],
            [-110, -60],
            [38, -40],
            [185, -2],
            [42, -77],
            [90, -21],
            [0, -63],
            [157, -58],
            [173, 26],
            [88, -18],
            [100, 12],
            [50, -44],
            [-85, -206],
            [-13, -126],
            [24, -177],
            [-44, -104],
            [116, -68],
            [-16, -82],
            [105, 38],
            [19, -54],
            [96, -13],
            [85, -49],
            [184, -16],
            [29, -27],
            [-48, -84],
            [-20, -129],
            [44, -95],
            [-84, -14],
            [-8, -61],
            [-75, -33],
            [14, -101],
            [-25, -93],
            [-149, -62],
            [29, -153],
            [-11, -45],
            [-97, 15],
            [-3, -131],
            [39, -66],
            [-51, -34],
            [-81, 47],
            [-27, -29],
            [-92, 97],
            [-51, -115],
            [-31, 10],
            [-36, -132],
            [-113, 27],
            [-59, -25],
            [1, -102],
            [-35, -146],
            [-24, -29],
        ],
        [
            [27894, 19880],
            [-277, 51],
            [-18, 50],
            [-79, 47],
            [-61, 79],
            [-116, 32],
            [-115, -90],
            [-64, 75],
            [-70, -69],
            [-67, 28],
            [53, 82],
            [-38, 67],
            [-88, -45],
            [-159, 3],
            [-69, -112],
            [-35, -113],
            [-46, -64],
            [37, -42],
            [-74, -47],
            [-22, -122],
            [-129, -39],
            [-24, -66],
            [-77, -2],
            [-38, 68],
            [-178, -47],
            [20, -106],
            [-43, -47],
            [-33, -137],
            [24, -148],
            [131, -87],
            [-28, -211],
            [-31, -148],
            [-101, -85],
            [-33, -79],
            [-105, -82],
            [-71, 11],
            [-63, -32],
            [-21, -76],
            [8, -98],
            [127, -53],
            [75, 32],
            [48, -23],
            [136, 24],
            [143, -22],
            [196, -60],
            [38, -111],
            [75, -58],
            [-57, -116],
            [-72, -45],
            [-86, -127],
            [-72, -166],
            [-76, -37],
            [-175, -47],
            [-71, 19],
            [-68, -27],
            [-91, 24],
            [-41, -116],
            [-63, 2],
            [8, -148],
            [-71, -197],
            [-51, -27],
            [-160, 47],
            [-81, -57],
            [-115, 115],
            [-119, 11],
            [-40, -55],
            [-104, -2],
            [-212, -61],
            [-49, -113],
            [-56, 4],
            [-97, 154],
            [-102, 74],
            [-167, -62],
            [-125, 6],
            [-31, -51],
            [-21, -120],
            [-77, -75],
            [-65, -145],
            [-2, -58],
            [61, -79],
            [-41, -52],
            [-55, 39],
            [-181, 27],
            [-351, -9],
            [-48, -10],
            [-151, 37],
            [-37, 167],
            [-100, 81],
            [-147, -126],
            [-79, 12],
        ],
        [
            [22894, 16699],
            [-59, 69],
            [3, 71],
            [44, 42],
            [-41, 83],
            [-89, -40],
            [-104, -16],
            [-68, 39],
            [-29, 91],
            [-70, 73],
            [-156, -2],
            [-37, 57],
            [-6, 85],
            [-55, 44],
            [-210, -37],
            [-1, 347],
            [-98, -27],
            [-119, -82],
            [-148, -23],
            [-278, 45],
            [-145, 126],
            [8, 55],
            [-76, 80],
            [-13, 128],
            [-91, 12],
            [-127, -48],
            [-93, 118],
            [-44, -36],
            [-55, 44],
            [-36, 108],
            [-161, 24],
            [-123, 142],
            [14, 33],
            [-95, 152],
            [-59, 67],
            [-145, 18],
            [-58, 144],
            [32, 74],
            [-119, 56],
            [-35, 60],
            [3, 130],
            [102, -13],
            [57, 55],
            [-27, 88],
            [-92, -14],
            [-26, -66],
            [-87, -37],
            [-16, 98],
            [-85, 21],
            [-65, 61],
            [-29, 103],
            [85, 132],
            [-67, 36],
            [-109, -137],
            [-67, 27],
            [-35, 150],
            [-129, -16],
            [-14, 64],
            [32, 66],
            [-136, 34],
            [-180, -46],
            [5, 159],
            [-129, -3],
            [-33, 72],
            [47, 63],
            [-8, 74],
            [-97, -38],
            [-46, 21],
            [-166, -68],
            [-76, -5],
        ],
        [
            [18564, 19886],
            [-128, 287],
            [-67, 349],
            [-110, 516],
            [-84, 347],
            [11, 50],
            [-48, 137],
            [-44, 53],
            [-10, 297],
            [-35, 270],
            [-56, 314],
            [-30, 36],
            [-61, 328],
            [-49, 208],
            [-56, 160],
            [-59, 114],
            [-60, 8],
            [-94, 108],
            [-37, 106],
            [-38, 256],
            [-59, 252],
            [-37, 13],
            [-111, 336],
            [-23, 160],
            [-35, 136],
            [-92, 242],
            [3, 52],
            [-124, -2],
            [-13, 95],
            [-88, 171],
            [7, 196],
            [-91, 179],
            [-156, -13],
            [-93, 58],
            [-44, 63],
            [-96, 24],
            [-48, 53],
            [94, 39],
            [-5, 61],
            [-76, 169],
        ],
        [
            [16502, 28309],
            [43, 147],
            [-18, 63],
            [91, 79],
            [83, 3],
            [56, 82],
            [73, -100],
            [84, 26],
            [50, -42],
            [164, 71],
            [68, 98],
            [-17, 44],
            [51, 103],
            [-94, 3],
            [45, 79],
            [81, -5],
            [-19, 79],
            [119, 147],
            [80, 206],
            [11, 71],
            [-75, 29],
            [-142, -63],
            [-52, 71],
            [91, 91],
            [86, 58],
            [151, -69],
            [22, 73],
            [-16, 87],
            [19, 114],
            [42, 21],
            [-13, 100],
            [-67, 69],
            [-172, 32],
            [-3, 68],
            [-85, -15],
            [-30, 52],
            [-87, -7],
            [-39, 144],
            [-32, 38],
            [55, 136],
            [-12, 151],
            [-47, -8],
            [-75, 71],
            [-51, 135],
            [84, 24],
            [70, 73],
            [149, -77],
            [30, 24],
            [21, 125],
            [70, 12],
            [33, 46],
            [88, 18],
            [-21, 140],
            [35, 23],
            [50, -97],
            [156, 16],
            [-5, -119],
            [29, -93],
            [39, -25],
            [185, 87],
            [150, 99],
            [-96, 64],
            [32, 76],
            [69, 34],
            [5, 117],
            [106, 2],
            [120, 95],
            [198, 34],
            [14, 30],
            [190, -1],
            [-23, 45],
            [-9, 204],
            [100, 71],
            [13, 76],
            [-72, 55],
            [163, 11],
            [51, 48],
            [122, -21],
            [129, 26],
            [2, -77],
            [121, -82],
            [8, -55],
            [85, -28],
            [31, -64],
            [131, -5],
            [30, 65],
            [77, -5],
            [40, 38],
            [5, 231],
            [168, -3],
            [139, 69],
            [100, -37],
            [102, 63],
            [61, -57],
            [10, -59],
            [103, 61],
            [112, -8],
            [0, 124],
            [108, -10],
            [3, -97],
            [97, -54],
            [93, 38],
            [-27, 57],
            [9, 151],
            [-68, 32],
            [92, 71],
            [-19, 123],
            [-119, 137],
            [0, 75],
            [90, 81],
            [-17, 166],
            [-70, 3],
            [-73, 90],
            [20, 114],
            [-81, 26],
            [-16, 92],
            [105, 27],
            [-1, 87],
            [55, 161],
            [126, -67],
            [29, -121],
            [46, -13],
            [81, 40],
            [91, -45],
            [35, -80],
            [82, -26],
            [28, 145],
            [96, -36],
            [8, -54],
            [99, 50],
            [-2, -153],
            [104, -86],
            [191, 41],
            [43, 37],
            [132, 3],
            [45, -63],
            [64, 93],
            [52, 12],
            [118, -74],
            [54, 46],
            [129, 20],
            [115, -20],
            [16, -75],
            [115, -4],
            [73, 60],
            [24, -114],
            [83, 3],
            [80, 105],
            [-8, 62],
            [-128, 18],
            [32, 172],
            [-112, -8],
            [-6, 107],
            [53, 11],
            [-10, 113],
            [41, 72],
            [-60, 19],
            [-22, 153],
            [234, 26],
            [36, 116],
            [155, 74],
            [5, 127],
            [103, 7],
            [0, 127],
            [101, 11],
            [27, -172],
            [49, 11],
            [47, 141],
            [63, 0],
            [11, -78],
            [69, -37],
            [21, -84],
            [78, -22],
            [5, 74],
            [70, 124],
            [4, 108],
            [56, -18],
            [102, 58],
            [34, 81],
            [-62, 71],
            [-79, 17],
            [8, 119],
            [72, 2],
            [24, -64],
            [220, 61],
            [76, -13],
            [88, 129],
            [18, 140],
            [-4, 138],
            [94, 13],
            [5, 211],
            [-86, 16],
            [88, 202],
            [103, 5],
            [18, -58],
            [73, -12],
            [70, 31],
            [24, -61],
            [142, 0],
            [21, 45],
            [-58, 79],
            [122, 35],
            [51, 134],
            [55, 84],
            [80, -12],
            [11, 105],
            [48, 90],
            [37, 185],
            [202, 89],
            [142, -27],
            [16, -84],
            [86, -10],
            [-22, -166],
            [-85, -93],
            [126, -26],
            [80, 19],
            [1, -70],
            [80, -63],
            [131, 63],
            [20, 74],
            [83, -45],
        ],
        [
            [22894, 16699],
            [-5, -99],
            [-115, -8],
            [-27, -83],
            [-202, -86],
            [-74, 84],
            [-126, -84],
            [47, -116],
            [-26, -17],
            [67, -131],
            [57, 3],
            [120, -87],
            [35, 41],
            [76, -31],
            [243, -193],
            [250, -87],
            [5, -89],
            [-75, -172],
            [-187, -90],
            [-8, -89],
            [174, 3],
            [43, -34],
            [158, 20],
            [126, -32],
            [205, 132],
            [83, -71],
            [-16, -65],
            [-99, -77],
            [31, -72],
            [122, -68],
            [15, -74],
            [-32, -60],
            [64, -114],
            [-126, 24],
            [-59, -45],
            [-35, -108],
            [-50, -56],
            [-20, -119],
            [132, -55],
            [89, -58],
            [113, 1],
            [125, -58],
            [116, -180],
            [80, 0],
            [25, -84],
            [-62, -187],
            [-56, -99],
            [42, -25],
            [8, -99],
            [-189, -10],
            [28, -110],
            [39, -11],
            [-12, -137],
            [20, -26],
            [-31, -111],
            [-10, -153],
            [-29, -67],
            [24, -82],
            [66, -79],
            [-32, -63],
            [-21, -124],
            [33, -32],
            [83, -5],
            [85, -145],
            [247, -37],
            [161, 82],
            [20, 76],
            [75, 62],
            [115, 42],
            [134, 105],
            [166, -18],
            [-23, -100],
            [101, -151],
            [1, -85],
            [82, -50],
            [-43, -79],
            [-10, -150],
            [-86, -26],
            [-116, -71],
            [44, -77],
            [125, -155],
            [-24, -37],
            [57, -114],
            [-110, -118],
            [-48, -83],
            [-16, -82],
            [70, -68],
            [-12, -85],
            [41, -73],
            [-68, -26],
            [-5, -169],
            [-50, -18],
            [4, -85],
            [-72, -109],
            [-29, -137],
            [86, 13],
            [102, -97],
            [107, -18],
            [120, 78],
            [46, -76],
            [83, -79],
            [0, -39],
            [127, -88],
            [-67, -148],
            [-57, 12],
            [-83, -128],
            [-3, -160],
            [-150, -174],
            [-21, -193],
            [21, -45],
            [-46, -53],
            [3, -139],
            [-53, -74],
            [-102, -85],
            [-43, -128],
            [-75, -22],
            [-59, -73],
            [134, -190],
            [72, -156],
            [94, -72],
            [19, -105],
            [-81, -101],
            [-8, -63],
            [-107, -118],
            [8, -103],
            [53, -171],
            [83, -82],
            [0, -42],
            [65, -79],
            [38, -98],
            [-49, -105],
            [-70, -37],
            [-88, 0],
            [53, -148],
            [-58, -9],
            [-25, -75],
            [-74, -91],
            [-9, -161],
            [-78, 3],
            [-86, -74],
        ],
        [
            [24752, 6921],
            [-249, 214],
            [-64, 31],
            [-43, 103],
            [-229, 285],
            [-491, 636],
            [-70, 113],
            [-167, 225],
            [-71, 68],
            [-117, 66],
            [-29, 56],
            [-62, 298],
            [-301, 672],
            [-200, 472],
            [-63, 257],
            [-40, 215],
            [-50, 513],
            [-65, 374],
            [-65, 251],
            [63, -6],
            [21, -89],
            [77, -37],
            [-18, -82],
            [116, -53],
            [34, -111],
            [78, -50],
            [-30, -61],
            [33, -160],
            [-22, -113],
            [76, -67],
            [0, -63],
            [-67, -137],
            [-38, -208],
            [10, -40],
            [401, 10],
            [-231, 88],
            [32, 155],
            [-32, 87],
            [-43, 14],
            [32, 144],
            [-83, 14],
            [19, 118],
            [-60, 153],
            [48, 47],
            [-2, 86],
            [-89, 205],
            [-31, -76],
            [-65, 12],
            [-14, 70],
            [-61, 84],
            [-62, 139],
            [6, 75],
            [-60, 112],
            [24, 95],
            [-109, 117],
            [-22, -108],
            [73, -172],
            [12, -134],
            [-51, -21],
            [-118, 446],
            [-128, 374],
            [-64, 243],
            [-94, 247],
            [-221, 470],
            [-250, 591],
            [-93, 440],
            [-121, 468],
            [-86, 171],
            [-182, 538],
            [-154, 296],
            [-177, 112],
            [-174, 480],
            [-71, 149],
            [-160, 169],
            [-52, 21],
            [-31, 79],
            [-103, 119],
            [-105, 41],
            [-190, 267],
            [-24, 123],
            [59, 183],
        ],
        [
            [19802, 17834],
            [-45, 29],
            [91, 140],
            [32, -26],
            [78, 67],
            [-91, 66],
            [-161, -123],
            [-114, 26],
            [-43, -29],
            [11, -201],
            [16, -21],
        ],
        [
            [19576, 17762],
            [-80, -42],
            [-43, 79],
            [-41, 227],
            [-96, 204],
            [-53, 60],
            [-129, 332],
            [-87, 198],
            [-74, 84],
            [-94, 199],
            [-180, 458],
            [-135, 325],
        ],
        [
            [34672, 44834],
            [-109, -34],
            [-66, 16],
            [-83, 97],
            [-75, 32],
            [-50, 60],
            [-197, 37],
            [-15, -82],
            [-122, 19],
            [-27, 77],
            [62, 91],
            [-31, 78],
            [-70, 76],
            [-14, 87],
            [-91, 52],
            [-48, 100],
            [-70, 6],
            [-97, 130],
            [-110, -1],
            [-91, 42],
            [-122, -86],
            [-71, 12],
            [-153, -158],
            [-105, 6],
            [-91, -63],
            [-60, 58],
            [-124, -4],
            [-31, -86],
            [-164, 20],
            [-87, 46],
            [-94, 102],
            [-165, 59],
            [-134, -63],
            [-73, -8],
            [-21, -54],
            [-117, -10],
            [-90, -35],
            [-110, 1],
            [8, 63],
            [-94, 71],
            [-20, 66],
            [38, 40],
            [-67, 139],
            [-83, 38],
            [-131, 10],
            [-46, -47],
            [-306, 57],
            [-75, 69],
            [-63, -8],
            [-48, -98],
            [-3, -91],
            [-192, 29],
            [-19, -61],
            [-103, -87],
            [-262, -22],
            [-97, 46],
            [-96, -69],
            [-74, -5],
            [-2, -83],
            [53, -223],
            [-101, 39],
            [-30, -42],
            [-113, 11],
            [-231, 0],
            [-104, -74],
            [-112, 52],
            [-130, 6],
            [-45, -19],
            [-100, 26],
            [-69, 82],
            [-145, 40],
            [-78, -66],
            [-115, -5],
            [-46, 174],
            [2, 111],
            [-142, 37],
            [16, -54],
            [-46, -54],
            [-67, 37],
            [-50, -43],
            [-134, 50],
            [-4, -63],
            [-150, -51],
            [-96, 16],
            [-33, -176],
            [-293, -174],
            [-56, -11],
            [-175, -94],
            [-110, -11],
            [-7, -35],
            [-111, -24],
            [-276, 98],
            [-18, -63],
            [-129, -19],
            [-24, -50],
            [-92, -23],
            [-3, 53],
            [-94, -12],
            [-67, 59],
            [-126, -13],
            [2, -69],
            [-147, 47],
            [-172, -12],
            [-50, 231],
            [-84, 48],
            [-49, 126],
            [6, 75],
            [96, 42],
            [130, -6],
            [172, -73],
            [114, 37],
            [-98, 132],
            [-36, 237],
            [-47, 92],
            [-125, 140],
            [-133, 42],
            [-135, -21],
            [-177, 15],
            [-167, -4],
            [27, -67],
            [-82, -62],
            [-92, 7],
            [-48, -69],
            [-239, 90],
            [-145, -10],
            [-27, -35],
            [-174, -82],
            [-293, -234],
            [-110, 40],
            [-51, -62],
            [-107, 11],
            [-43, -102],
            [15, -79],
            [-62, -35],
            [79, -97],
            [-20, -67],
            [-114, -68],
            [-31, -100],
            [-177, -114],
            [-120, -116],
            [100, -137],
            [-7, -101],
            [-45, 0],
            [-53, -160],
            [-178, 37],
            [-85, -48],
            [-106, 26],
            [-45, -134],
            [-62, -108],
            [-113, -23],
            [-7, -37],
            [-90, -50],
            [-265, -4],
            [-35, 50],
            [-238, -10],
            [-133, 106],
            [12, 40],
            [-73, 73],
            [38, 39],
            [109, -21],
            [-7, 154],
            [-52, 28],
            [38, 75],
            [-85, 110],
            [-84, 224],
            [-127, -63],
            [-50, 37],
            [-139, 34],
            [-44, 50],
            [-145, -13],
            [-81, 30],
            [-153, -49],
            [-86, 20],
            [-118, -12],
            [-147, 16],
            [-92, -31],
            [-122, 2],
            [-28, 35],
            [-198, -61],
            [-67, 43],
            [-156, 21],
            [-234, -25],
            [-85, 22],
            [-140, 5],
            [-5, 56],
            [-132, -24],
            [-96, 15],
            [-250, 111],
            [-65, 58],
            [-46, 130],
            [-90, 148],
            [-111, 21],
            [-88, 65],
            [-167, 46],
            [-65, 57],
            [-106, 11],
            [-110, -69],
            [-116, 6],
            [-234, 55],
            [-118, 68],
            [-188, 32],
            [-13, 55],
            [-67, 11],
            [-27, 90],
            [-110, 28],
            [16, 112],
            [-37, 116],
            [23, 42],
            [16, 189],
            [27, 64],
            [-94, 135],
            [-117, 37],
            [-20, 142],
            [-24, 42],
            [-131, -31],
            [-119, -132],
            [-128, -27],
            [-22, -81],
            [-246, -35],
            [-159, 84],
        ],
        [
            [17050, 49872],
            [70, 24],
            [119, 119],
            [118, -19],
            [94, -58],
            [126, 10],
            [64, 37],
            [30, 88],
            [172, 37],
            [130, 110],
            [39, 56],
            [131, -19],
            [83, 50],
            [-139, 47],
            [74, 41],
            [-58, 86],
            [-78, 10],
            [-115, -34],
            [-47, 61],
            [-79, -2],
            [-54, 42],
            [-52, -34],
            [-42, 105],
            [35, 119],
            [74, 57],
            [1, 104],
            [72, 31],
            [41, 92],
            [126, 16],
            [29, 48],
            [204, 105],
            [88, 69],
            [79, -27],
            [136, 67],
            [95, 144],
            [56, 48],
            [94, 21],
            [-43, 111],
            [0, 148],
            [45, 45],
            [-2, 91],
            [-33, 95],
            [-48, 8],
            [14, 128],
            [-28, 66],
            [27, 128],
            [73, 140],
            [60, 38],
            [26, 87],
            [57, 76],
            [-38, 124],
            [-54, 111],
            [-57, 31],
            [-32, 105],
            [-75, 50],
            [-58, 124],
            [72, 49],
            [-33, 81],
            [-51, 48],
            [-62, -22],
            [-106, 21],
            [-119, -16],
            [-42, 69],
            [74, 61],
            [1, 148],
            [77, 121],
            [126, 122],
            [-118, 120],
            [-99, -26],
            [-61, 16],
            [-116, 98],
            [89, 39],
            [26, 80],
            [-24, 97],
            [116, 40],
            [-19, 76],
            [94, 101],
            [-11, 44],
            [-104, -2],
            [39, 118],
            [-27, 47],
            [106, 67],
            [153, -280],
            [82, -39],
            [116, 18],
            [56, 111],
            [98, 24],
            [-17, 129],
            [75, 0],
            [-21, 119],
            [-119, -9],
            [-180, 19],
            [-131, 47],
            [-8, 116],
            [-80, 133],
            [-6, 120],
            [24, 80],
            [81, -5],
            [14, -85],
            [93, 8],
            [33, -32],
            [0, -86],
            [101, -12],
            [-11, -50],
            [189, -10],
            [86, -45],
            [83, 84],
            [45, -23],
            [81, 26],
            [-10, 224],
            [63, 59],
            [73, 16],
            [-26, 108],
            [51, 73],
            [77, -55],
            [304, 34],
            [110, -15],
            [5, -48],
            [-91, -53],
            [51, -127],
            [-54, -163],
            [-83, 56],
            [-57, -35],
            [-16, -73],
            [138, -56],
            [40, 50],
            [129, -27],
            [145, 45],
            [-19, -86],
            [-325, -141],
            [-46, 101],
            [-118, 32],
            [24, 100],
            [-136, -32],
            [126, -182],
            [-83, -3],
            [-68, -92],
            [8, -84],
            [92, -72],
            [109, -8],
            [103, -34],
            [410, -82],
            [87, 14],
            [144, 65],
            [131, 9],
            [87, -101],
            [128, 38],
            [59, 78],
            [172, 93],
            [122, 16],
            [147, -104],
            [-30, -179],
            [43, 1],
            [25, -159],
            [59, -19],
            [29, -74],
            [75, -15],
            [-11, -77],
            [49, -36],
            [-54, -96],
            [17, -37],
            [-23, -134],
            [-117, -66],
            [-91, -3],
            [-46, 40],
            [8, 84],
            [-80, -20],
            [-140, -159],
            [5, -163],
            [77, -108],
            [89, 10],
            [43, -39],
            [-21, -207],
            [-88, -25],
            [-82, -236],
            [97, -15],
            [28, -72],
            [115, -5],
            [-3, -105],
            [-137, -29],
            [2, -76],
            [-51, -111],
            [-173, -83],
            [-87, 186],
            [-77, -43],
            [-19, -53],
            [-180, -2],
            [5, 52],
            [-110, 42],
            [-49, 50],
            [-63, -129],
            [-75, -70],
            [-22, -174],
            [86, -29],
            [45, -54],
            [164, -100],
            [8, -123],
            [46, -43],
            [94, 16],
            [93, -19],
            [105, -78],
            [32, 44],
            [-23, 92],
            [88, 21],
            [16, 109],
            [-81, 37],
            [9, 79],
            [74, -6],
            [86, -61],
            [57, -76],
            [204, 114],
            [72, -27],
            [269, 140],
            [11, 119],
            [-54, 68],
            [0, 82],
            [83, 13],
            [102, 59],
            [46, 70],
            [255, 61],
            [5, 104],
            [-38, 216],
            [87, 111],
            [-33, 110],
            [75, 42],
            [44, 105],
            [58, -62],
            [-32, -75],
            [32, -152],
            [116, -13],
            [80, -33],
            [36, 59],
            [78, 42],
            [99, -8],
            [163, -113],
            [32, 50],
            [82, -30],
            [29, 35],
            [126, -84],
            [41, -108],
            [158, 52],
            [-24, 95],
            [118, 84],
            [8, 61],
            [172, 22],
            [66, -64],
            [-40, -47],
            [-38, -114],
            [130, -94],
            [94, 33],
            [39, -23],
            [62, -113],
            [103, -1],
            [78, 40],
            [121, -24],
            [47, 169],
            [76, 29],
            [-76, 97],
            [-118, 114],
            [-104, 259],
            [35, 23],
            [-19, 117],
            [24, 176],
            [-102, 190],
            [88, 48],
            [55, -5],
            [28, -67],
            [66, 63],
            [53, -36],
            [-11, -138],
            [126, -79],
            [91, 37],
            [87, 80],
            [23, 81],
            [51, -7],
            [38, 110],
            [-13, 227],
            [-113, 211],
            [-131, 48],
            [-24, 83],
            [-116, -33],
            [-214, 12],
            [5, 51],
            [-122, 164],
            [97, 42],
            [161, -5],
            [87, 31],
            [62, 66],
            [-98, 45],
            [32, 65],
            [-147, 128],
            [-8, 165],
            [35, 67],
            [53, 0],
            [188, 63],
            [43, 63],
            [258, -56],
            [105, 32],
            [38, 58],
            [83, -2],
            [32, 74],
            [142, -11],
            [116, 27],
            [113, -104],
            [126, 40],
            [107, 71],
            [11, 193],
            [77, 15],
            [-32, 96],
            [-121, 68],
            [-39, 64],
            [71, 99],
            [-31, 80],
            [-56, 11],
            [34, 89],
            [-32, 60],
            [48, 72],
            [-156, 68],
            [-238, -184],
            [-5, -122],
            [-78, -57],
            [-102, 5],
            [-105, 63],
            [-111, 14],
            [-101, -43],
            [-43, -69],
            [-181, -12],
            [-18, -53],
            [-164, 126],
            [-140, 24],
            [-195, -43],
            [-17, 59],
            [-74, 34],
            [-165, -6],
            [-125, 101],
            [-94, 26],
            [-38, 124],
            [-67, 30],
            [-14, 91],
            [-110, 164],
            [-29, 145],
            [15, 156],
            [-43, 44],
            [31, 70],
            [-92, 65],
            [13, 67],
            [124, 39],
            [-13, 102],
            [19, 93],
            [190, 227],
            [96, -3],
            [27, 90],
            [92, 16],
            [163, -27],
            [81, 56],
            [-5, 60],
            [96, 6],
            [67, 77],
            [59, 118],
            [-27, 53],
            [111, 78],
            [48, 107],
            [142, 37],
            [154, 92],
            [83, 113],
            [201, 55],
            [49, 104],
            [142, 38],
            [83, -23],
            [201, 116],
            [115, 113],
            [27, 91],
            [134, 75],
            [184, -20],
            [-12, 121],
            [132, 23],
            [132, 1],
            [80, 74],
            [132, 39],
            [77, 84],
            [124, 79],
            [65, -26],
            [90, 42],
            [35, 80],
            [76, 31],
            [129, 5],
            [32, 51],
            [-11, 78],
            [60, 45],
            [102, 16],
            [69, 132],
            [100, -10],
            [80, 42],
            [65, 74],
            [267, -47],
            [3, 152],
            [63, 32],
            [-46, 116],
            [67, 39],
            [75, -33],
            [93, 28],
            [-51, 75],
            [135, 28],
        ],
        [
            [27864, 60850],
            [127, -47],
            [74, 127],
            [166, 28],
            [58, -7],
            [64, -125],
            [112, 11],
            [91, -108],
            [159, -29],
            [140, -80],
            [101, -4],
            [52, 57],
            [105, -18],
            [51, 68],
            [107, 21],
            [72, -21],
            [59, -76],
            [135, -3],
            [150, -177],
            [108, 25],
            [123, -50],
            [0, -98],
            [99, 66],
            [49, -34],
            [-39, -77],
            [50, -106],
            [-63, -49],
            [43, -58],
            [0, -67],
            [99, -44],
            [76, -95],
            [8, -53],
            [-46, -68],
            [60, -45],
            [67, 33],
            [94, -36],
            [-25, -82],
            [-104, -34],
            [24, -80],
            [78, -70],
            [26, -109],
            [-78, -5],
            [-56, -80],
            [-57, -9],
            [-47, -159],
            [-106, -95],
            [-5, -121],
            [-113, -1],
            [-43, -49],
            [162, -162],
            [-57, -25],
            [-105, -107],
            [-4, -134],
            [-78, -119],
            [-108, -99],
            [21, -114],
            [-74, -13],
            [-38, -77],
            [61, -32],
            [-7, -94],
            [-130, 84],
            [11, -92],
            [-58, -1],
            [-67, -108],
            [-79, -37],
            [38, -82],
            [84, -31],
            [56, -105],
            [0, -84],
            [-46, -82],
            [-170, -42],
            [-63, 12],
            [-94, -37],
            [-79, -82],
            [-123, 62],
            [-64, -67],
            [-95, 1],
            [-184, 45],
            [-89, -79],
            [-65, 25],
            [-46, -82],
            [35, -155],
            [-77, -26],
            [-22, -50],
            [-142, -66],
            [-6, -77],
            [-107, -108],
            [80, -35],
            [22, -55],
            [-64, -76],
            [102, -37],
            [48, -93],
            [-19, -47],
            [56, -71],
            [28, -89],
            [156, -109],
            [-52, -36],
            [6, -59],
            [-99, 6],
            [-63, -64],
            [-115, -61],
            [35, -163],
            [-30, -82],
            [-106, -92],
            [-208, -153],
            [-42, -56],
            [-81, -31],
            [-13, -108],
            [59, -16],
            [109, -192],
            [1, -119],
            [69, -88],
            [46, -105],
            [-30, -60],
            [36, -98],
            [-40, -183],
            [-87, -33],
            [43, -149],
            [65, -18],
            [11, -105],
            [266, -201],
            [-100, -116],
            [42, -89],
            [122, -101],
            [137, 71],
            [15, 82],
            [78, 38],
            [20, 84],
            [69, 78],
            [147, -99],
            [71, -11],
            [109, -177],
            [102, -65],
            [41, -82],
            [83, -30],
            [76, 53],
            [78, -18],
            [85, -179],
            [59, -3],
            [13, 71],
            [132, 14],
            [64, 44],
            [-7, 72],
            [86, 57],
            [-6, 82],
            [169, 161],
            [-3, 50],
            [59, 158],
            [-16, 56],
            [-105, -13],
            [-88, 60],
            [-35, 72],
            [119, -9],
            [5, 198],
            [-89, 82],
            [-88, 169],
            [-52, 5],
            [-88, -95],
            [-83, -3],
            [-68, -42],
            [-69, 27],
            [-33, 168],
            [89, 128],
            [-24, 181],
            [2, 84],
            [44, 58],
            [-44, 135],
            [-190, 95],
            [-77, 24],
            [-41, 121],
            [-80, 51],
            [48, 290],
            [-60, 87],
            [-64, 20],
            [-11, 172],
            [-90, 204],
            [20, 91],
            [-133, 85],
            [-87, 11],
            [-98, -61],
            [-68, 77],
            [111, 18],
            [50, 50],
            [49, -53],
            [54, 13],
            [24, 71],
            [118, 61],
            [5, 72],
            [73, 116],
            [80, 47],
            [158, 35],
            [30, -56],
            [-116, -109],
            [143, -34],
            [-17, -52],
            [89, -27],
            [80, 27],
            [-16, 132],
            [19, 76],
            [-113, 95],
            [4, 79],
            [83, -40],
            [93, 21],
            [180, -91],
            [8, 44],
            [-64, 142],
            [118, 24],
            [-22, 58],
            [47, 46],
            [112, 0],
            [45, -85],
            [-71, -40],
            [53, -66],
            [-16, -76],
            [-73, -63],
            [72, -53],
            [86, 29],
            [28, -53],
            [13, -139],
            [-123, 13],
            [-83, 36],
            [-73, -5],
            [-102, -102],
            [-14, -62],
            [-110, -111],
            [116, -174],
            [30, 1],
            [45, 145],
            [-16, 97],
            [80, 87],
            [127, -41],
            [110, -120],
            [-172, -50],
            [-33, -71],
            [-69, 3],
            [27, -103],
            [-40, -74],
            [28, -81],
            [130, 142],
            [80, 29],
            [83, -63],
            [49, 49],
            [-76, 77],
            [40, 93],
            [70, -1],
            [39, -115],
            [85, -27],
            [-89, -113],
            [30, -37],
            [-40, -88],
            [102, 22],
            [18, 63],
            [73, 10],
            [80, -37],
            [-32, -57],
            [70, -95],
            [127, 20],
            [53, 29],
            [113, -34],
            [-2, 77],
            [36, 53],
            [71, -119],
            [69, 32],
            [36, 60],
            [-84, 109],
            [-16, 63],
            [43, 86],
            [48, -62],
            [84, 33],
            [-3, 124],
            [-70, -34],
            [-78, 56],
            [-85, -5],
            [12, 90],
            [95, 89],
            [142, -21],
            [-30, -47],
            [87, -143],
            [18, 74],
            [83, -42],
            [-3, -48],
            [92, -60],
            [22, -61],
            [-90, -35],
            [-72, -113],
            [14, -145],
            [131, -35],
            [150, -73],
            [75, 55],
            [-8, 69],
            [100, 127],
            [229, -108],
            [95, -16],
            [203, 47],
            [244, -124],
            [93, 163],
            [-24, 138],
            [32, 45],
            [-51, 50],
            [76, 52],
            [59, -23],
            [155, 55],
            [134, 14],
            [43, 92],
            [22, 121],
            [137, 0],
            [37, 37],
            [121, -45],
            [89, 108],
            [80, 18],
            [3, 66],
            [158, -5],
            [78, 59],
            [99, -96],
            [-18, -97],
            [25, -71],
            [-25, -108],
            [25, -43],
            [82, -10],
            [46, -82],
            [123, -87],
            [60, -135],
            [-102, -32],
            [-107, -66],
            [-121, -189],
            [-63, -37],
            [-42, -97],
            [131, -75],
            [150, 63],
            [73, 135],
            [129, 29],
            [53, -36],
            [30, -72],
            [89, 53],
            [-41, 84],
            [154, -71],
            [99, 61],
            [43, 195],
            [78, -98],
            [-38, -68],
            [91, -16],
            [91, -113],
            [148, 50],
            [135, -29],
            [0, 66],
            [-157, 58],
            [-5, 73],
            [94, 37],
            [188, -32],
            [65, 109],
            [-17, 71],
            [94, -13],
            [98, -69],
            [-111, -119],
            [16, -152],
            [-83, -58],
            [-34, -63],
            [59, -77],
            [-87, -85],
            [-46, -87],
            [153, -23],
            [221, 107],
            [45, -99],
            [132, 4],
            [73, 43],
            [137, -8],
            [39, -81],
            [108, -90],
            [75, 111],
            [16, 78],
            [124, -57],
            [-11, 165],
            [51, 33],
            [115, 179],
            [-45, 108],
            [34, 32],
            [31, 140],
            [171, 23],
            [52, -16],
            [112, -124],
            [108, 26],
            [24, -110],
            [131, -61],
            [25, 105],
            [-43, 29],
            [40, 82],
            [75, 8],
            [38, 98],
            [94, 29],
            [43, -79],
            [106, -37],
            [-77, -56],
            [112, -97],
            [-104, -76],
            [75, -58],
            [70, 54],
            [86, -31],
            [11, -61],
            [96, 27],
            [209, -124],
            [105, 26],
            [124, -50],
            [78, -56],
            [24, -182],
            [-21, -105],
            [66, -68],
            [107, -58],
            [111, 29],
            [167, -80],
            [47, -5],
            [84, -85],
            [56, 35],
            [76, -35],
            [137, 14],
            [-36, -98],
            [118, -28],
            [14, -153],
            [-41, -79],
            [114, 21],
            [63, -50],
            [17, -106],
            [88, -11],
            [86, -65],
            [134, -11],
            [-15, 111],
            [23, 63],
            [-29, 76],
            [52, 61],
            [185, -85],
            [88, 8],
            [30, -78],
            [380, 141],
            [84, -71],
            [-11, -78],
            [205, -21],
            [8, -128],
            [78, 6],
            [12, -137],
            [-152, -32],
            [-83, 45],
            [-1, -127],
            [57, -40],
            [-35, -68],
            [5, -145],
            [-51, -119],
            [30, -42],
            [118, 6],
            [10, -235],
            [-37, -71],
            [-3, -93],
            [-64, -37],
            [26, -160],
            [-43, -85],
            [-90, 15],
            [-86, -28],
            [123, -109],
            [19, -47],
            [131, -23],
            [-26, -174],
            [153, -35],
            [19, -111],
        ],
        [
            [33590, 37234],
            [-58, -50],
            [-124, -10],
            [-38, -64],
            [-86, 59],
            [-144, -54],
            [-67, 72],
            [-100, 55],
            [-94, 133],
            [-225, 52],
            [-105, 134],
            [88, 21],
            [38, 62],
            [5, 229],
            [-27, 108],
            [9, 135],
            [-24, 62],
            [-97, 8],
            [-56, -28],
            [-27, 71],
            [-8, 127],
            [35, 53],
            [99, 57],
            [101, 100],
            [-55, 79],
            [-8, 67],
            [71, 168],
            [44, 40],
            [-11, 108],
            [45, 193],
            [-77, 115],
            [-1, 51],
            [-96, 98],
            [-91, 26],
            [-138, 105],
            [-32, 92],
            [-110, 85],
            [-104, 16],
            [-76, -58],
            [-204, -26],
            [-50, -27],
            [-59, -168],
            [-97, 10],
            [-64, 110],
            [-76, -20],
            [-5, -69],
            [-120, -61],
            [-79, 106],
            [-78, -1],
            [-118, 83],
            [-85, 36],
            [-75, -24],
            [-25, 70],
            [-139, 62],
            [-51, -247],
            [-59, -47],
            [-19, -74],
            [27, -59],
            [-81, -18],
            [-56, 27],
            [-79, 97],
            [-101, 36],
            [-5, 43],
            [-108, 10],
            [-35, 40],
            [-91, -16],
            [-113, 117],
            [-41, 108],
            [15, 92],
            [-68, 48],
            [-78, 7],
            [-69, -37],
            [-90, 3],
            [12, 118],
            [-48, 178],
            [-106, 0],
            [-64, 62],
            [-129, 1],
            [-61, -37],
            [-41, 55],
            [-230, 68],
            [-258, 37],
            [-91, -83],
            [-47, 68],
            [-134, 19],
            [-86, 39],
            [-37, 126],
            [-134, 9],
            [-72, 87],
            [-46, -185],
            [126, -29],
            [0, -76],
            [59, -20],
            [21, -73],
            [-62, -137],
            [0, -59],
            [-82, -68],
            [-84, -2],
            [-45, -77],
            [50, -26],
            [65, -145],
            [0, -95],
            [-40, -40],
            [7, -156],
            [36, -113],
            [-41, -48],
            [-67, 14],
            [-61, -69],
            [-76, 11],
            [-102, -111],
            [-22, -124],
            [49, -63],
            [-38, -153],
            [0, -109],
            [-94, -32],
            [-64, 46],
            [-124, 24],
            [-69, -43],
            [-32, 90],
            [-95, 89],
            [-72, 22],
            [-102, 95],
            [-102, -69],
            [-144, -45],
            [4, -53],
            [106, -21],
            [-90, -90],
            [-64, -168],
            [13, -97],
            [-43, -64],
            [31, -53],
            [-9, -82],
            [-75, 51],
            [-41, -16],
            [-8, -137],
            [-99, 31],
            [-13, -97],
            [29, -137],
            [137, 2],
            [-11, -95],
            [113, 8],
            [-37, -119],
            [115, -8],
            [49, -119],
            [125, -75],
            [-4, -57],
            [-98, 20],
            [-195, -64],
            [-40, -113],
            [-102, -216],
            [-103, -14],
            [-46, -39],
            [-8, -96],
            [36, -62],
            [13, -95],
            [-54, -109],
            [-43, 8],
            [-153, -79],
            [-46, 79],
            [-123, -19],
            [-73, -235],
            [33, -70],
            [-76, -72],
            [-16, -89],
            [-79, -66],
            [18, -74],
            [72, -24],
            [48, -66],
            [-58, -48],
        ],
        [
            [15262, 28525],
            [-43, 86],
            [-40, 140],
            [-67, 146],
            [-43, 23],
            [-68, 143],
            [-212, 89],
            [-50, 122],
            [-27, 163],
            [-37, 24],
            [-31, 205],
            [-63, 240],
            [-51, 135],
            [-17, 116],
            [-34, 46],
            [-33, 173],
            [-53, 8],
            [-35, 243],
            [-65, 122],
            [-57, 155],
            [84, 76],
            [-54, 45],
            [81, 111],
            [-51, 58],
            [-8, 132],
            [-70, 122],
            [0, 110],
            [30, 118],
            [-55, 27],
            [-4, 135],
            [-43, 119],
            [7, 128],
            [36, 109],
            [-36, 132],
            [20, 78],
            [-11, 141],
            [-35, 45],
            [-71, 253],
            [-67, 166],
            [-66, 69],
            [120, 78],
            [-64, 43],
            [3, 63],
            [-36, 92],
            [-85, 8],
            [21, 72],
            [0, 131],
            [25, 27],
            [-32, 121],
            [-111, 111],
            [-6, 65],
            [62, 17],
            [-98, 184],
            [18, 47],
            [-54, 104],
            [60, 60],
            [-75, 233],
            [-33, 58],
            [-70, 229],
            [-48, 35],
            [-5, 89],
            [-63, 37],
            [-21, 134],
            [-45, 66],
            [18, 64],
            [-61, 187],
            [-32, 27],
            [24, 152],
            [-16, 100],
            [-103, 82],
            [-27, 169],
            [100, -29],
            [6, -68],
            [80, 0],
            [171, -61],
            [-24, 126],
            [-55, -16],
            [-211, 135],
            [-18, 66],
            [-108, 61],
            [28, 108],
            [-41, 19],
            [-11, 123],
            [-29, 50],
            [45, 193],
            [47, 34],
            [-122, 298],
            [-38, 5],
            [-42, 162],
            [26, 73],
            [-4, 204],
            [102, 39],
            [45, -35],
            [75, 101],
            [41, -76],
            [69, 28],
            [-13, 153],
            [-99, -50],
            [-43, 14],
            [-52, 100],
            [40, 77],
            [109, 26],
            [-62, 76],
            [43, 48],
            [129, 21],
            [45, 31],
            [-43, 84],
            [-25, 143],
            [-82, -2],
            [-24, -92],
            [-100, -78],
            [-158, 8],
            [-49, -215],
            [-58, 16],
            [-66, 71],
            [39, 66],
            [-8, 60],
            [75, 95],
            [-40, 21],
            [8, 151],
            [-22, 87],
            [-96, 111],
            [34, 105],
            [-56, 69],
            [19, 87],
            [3, 149],
            [42, 82],
            [-116, 130],
            [9, 114],
            [-43, 144],
            [96, 79],
            [-107, 71],
            [-53, 93],
            [-17, 122],
            [41, 26],
            [-44, 118],
            [-24, 121],
            [11, 62],
            [-65, 131],
            [-21, 191],
            [-72, 49],
            [75, 101],
            [-10, 158],
            [-33, 45],
            [143, 118],
            [15, 37],
            [-38, 243],
            [51, 32],
            [31, 156],
        ],
        [
            [70332, 56408],
            [8, -89],
            [94, -9],
            [-18, -65],
            [164, -19],
            [34, -57],
            [91, -74],
            [221, 376],
            [14, 88],
            [53, -9],
            [78, 75],
            [19, 63],
            [96, 55],
            [78, 119],
            [14, 56],
            [-138, 89],
            [43, 66],
            [111, 18],
            [46, 54],
            [72, -16],
            [83, 33],
            [204, -36],
            [43, 44],
            [88, 11],
            [-5, 60],
            [164, -107],
            [62, -77],
            [131, -27],
            [38, 72],
            [110, 8],
            [-3, -60],
            [89, -98],
            [142, 20],
            [80, -26],
            [342, 170],
            [-9, 67],
            [143, 132],
            [48, -4],
            [135, 91],
            [93, 135],
            [67, -31],
            [-58, -122],
            [21, -31],
            [-78, -141],
            [21, -134],
            [-18, -79],
            [190, -127],
            [94, -36],
            [65, 8],
        ],
        [
            [73694, 56844],
            [-17, -114],
            [-126, -95],
            [-24, -148],
            [-66, -139],
            [2, -64],
            [-38, -148],
            [44, -62],
            [82, -12],
            [46, -47],
            [272, -110],
            [16, -212],
            [-24, -111],
            [-81, -94],
            [-32, -72],
            [12, -100],
            [-40, -98],
            [-98, -78],
            [-43, -72],
            [-44, -187],
            [22, -56],
            [-62, -126],
            [-54, -32],
            [-113, 16],
            [0, -123],
            [-83, -167],
            [-99, -100],
            [-56, -19],
            [-100, -240],
            [-50, -69],
            [-35, -165],
            [-35, -82],
            [-95, -124],
            [-72, -10],
            [5, -135],
            [-40, -113],
            [-51, -40],
            [-32, -175],
            [-59, -36],
            [-8, -227],
            [-40, -130],
            [-142, -231],
            [4, -80],
            [-72, -26],
            [-22, -221],
            [-109, -29],
            [-73, 147],
            [-126, 21],
            [-92, 98],
            [-136, 0],
            [-44, 47],
            [-160, 13],
            [-83, -49],
            [-161, -14],
            [-41, 100],
            [-86, 106],
            [-62, -14],
            [-246, 43],
            [-100, -10],
            [-19, -108],
            [-219, -37],
            [-43, -38],
            [-119, 67],
            [-14, 84],
            [-64, 82],
            [-30, 99],
            [-50, 51],
            [-172, 53],
            [-54, -22],
            [8, -107],
        ],
        [
            [69951, 52753],
            [-136, -4],
            [-98, -52],
            [30, 113],
            [-104, 40],
            [-27, -105],
            [-115, 10],
            [-14, 63],
            [-62, 37],
            [-51, -71],
            [-77, 31],
            [-62, 83],
            [-71, -35],
            [-91, 31],
            [-99, 80],
            [54, 92],
            [51, 166],
            [-48, 104],
            [5, 78],
            [75, 111],
            [-38, 82],
            [8, 127],
            [-66, 37],
        ],
        [
            [60310, 57070],
            [6, 14],
            [-10, 7],
        ],
        [
            [67415, 55599],
            [-218, 62],
            [-24, 83],
            [-78, -24],
            [-226, 81],
            [-250, 128],
            [-21, 65],
            [-185, 66],
            [-213, -42],
            [-185, 34],
            [-179, -27],
            [-57, 12],
            [-164, -38],
            [-59, 32],
            [-53, -84],
            [-86, 23],
            [-32, -58],
            [-143, -47],
            [-104, 60],
            [-73, 85],
            [-62, -68],
            [-126, -13],
            [-109, -33],
            [-137, 93],
            [-130, 3],
            [-196, 47],
            [-94, 63],
            [-50, -28],
            [-159, 15],
            [-169, -18],
            [-155, 11],
            [-423, -107],
            [-123, 9],
            [-91, -22],
            [-108, 19],
            [-82, 61],
            [-126, -61],
            [-98, -21],
            [-104, 93],
            [-84, -45],
            [-114, 10],
            [-245, -13],
            [-148, -77],
            [-107, 43],
            [-92, 12],
            [-126, 88],
            [-56, -13],
            [-110, 31],
            [-223, 39],
            [-65, 32],
            [-153, 3],
            [-174, 113],
            [-144, 56],
            [-82, 52],
            [-77, -10],
            [-116, 27],
            [-48, -50],
            [-80, -6],
            [-97, 179],
            [-11, 104],
            [94, 265],
        ],
        [
            [69951, 52753],
            [14, -66],
            [-13, -138],
            [39, -15],
            [38, -93],
            [94, -45],
            [-8, -126],
            [10, -182],
            [30, -32],
            [-18, -111],
            [37, -184],
            [76, -87],
            [-46, -83],
            [15, -208],
            [-26, -37],
            [10, -102],
            [-56, -75],
            [-26, -284],
            [45, -68],
            [-134, -96],
            [37, -65],
            [-2, -220],
            [45, -94],
            [-59, -215],
            [56, -34],
            [-80, -63],
            [-19, -113],
            [-70, -124],
            [-86, -80],
            [-137, -4],
            [-130, 145],
            [-205, -25],
            [-10, -137],
            [51, -58],
            [-14, -49],
            [57, -146],
            [-67, -60],
            [-47, -88],
            [-15, -136],
            [-48, -16],
            [37, -193],
            [-57, -103],
            [63, -203],
            [61, -48],
            [26, -107],
            [-83, -90],
            [41, -84],
            [10, -148],
            [32, -50],
            [82, -21],
            [43, -69],
            [18, -137],
            [13, -290],
            [-32, -90],
            [-120, -8],
            [43, -187],
            [-89, -9],
            [-37, 64],
            [-113, 26],
            [-91, -47],
            [31, -84],
            [-23, -77],
            [51, -106],
            [-137, 9],
            [-43, -145],
            [24, -207],
            [-59, -4],
            [-59, 129],
            [-68, -27],
            [-75, -155],
            [-59, 137],
            [-54, 52],
            [-13, 90],
            [-59, 5],
            [-89, 96],
            [-120, 45],
            [-113, 113],
            [-83, 11],
            [-38, -326],
            [-25, -79],
            [-61, 1],
            [-59, -75],
            [-70, -25],
            [-19, 226],
            [-96, 217],
            [121, 41],
            [-27, 104],
            [-21, 218],
            [-31, 93],
            [4, 92],
            [-60, 206],
            [-22, 208],
            [-45, 266],
            [-29, 296],
            [-40, 150],
            [-90, 164],
            [-20, 100],
            [-54, 80],
            [0, 134],
            [-76, 52],
            [-34, 67],
            [-119, 77],
            [16, 205],
            [35, 140],
            [-82, 152],
            [20, 143],
            [-29, 108],
            [-2, 119],
            [134, 13],
            [-99, 230],
            [29, 95],
            [-67, 79],
            [3, 84],
            [-55, 61],
            [-40, 224],
            [-39, 76],
            [15, 61],
            [-50, 204],
            [-55, 54],
            [41, 148],
            [-75, 98],
        ],
        [
            [66977, 51808],
            [-25, 251],
            [81, 153],
            [61, -14],
            [58, 143],
            [-49, 68],
            [-3, 103],
            [46, 58],
            [-32, 80],
            [26, 171],
            [4, 261],
            [-9, 114],
            [-77, 65],
            [-1, 71],
        ],
        [
            [75238, 60413],
            [-87, -109],
            [-67, -16],
            [-75, -70],
            [-2, -112],
            [-110, -95],
            [16, -53],
            [-145, -184],
            [112, -126],
            [-10, -64],
            [85, -39],
            [-21, -98],
            [8, -179],
            [-29, -53],
            [2, -105],
            [60, -70],
            [-41, -33],
            [-32, -116],
            [32, -77],
            [-3, -103],
            [108, -6],
            [54, -42],
            [-57, -144],
            [-131, -138],
            [-32, -57],
            [-135, -62],
            [-81, -78],
            [-37, -140],
            [96, -179],
            [9, -140],
            [-54, -61],
            [-67, -8],
            [-72, -100],
            [-135, -116],
            [-40, -143],
            [-92, -81],
            [47, -59],
            [-143, -12],
            [-46, -37],
            [-67, -159],
            [-110, -42],
            [-234, -45],
            [-18, -18],
        ],
        [
            [46090, 38261],
            [-85, 52],
            [141, 42],
            [-56, -94],
        ],
        [
            [50291, 41131],
            [62, -37],
            [-37, -54],
            [-94, 33],
            [69, 58],
        ],
        [
            [51961, 43207],
            [256, -14],
            [77, 14],
            [46, -83],
            [-16, -78],
            [-185, 0],
            [-115, -61],
            [-111, -113],
            [-70, 50],
            [-19, -77],
            [-128, 10],
            [72, 96],
            [78, 223],
            [115, 33],
        ],
        [
            [51550, 47406],
            [-29, -108],
            [8, -100],
            [102, 2],
            [107, 27],
            [44, -114],
            [115, -47],
            [169, -35],
            [135, -8],
            [106, -110],
            [62, -12],
            [46, -152],
            [-80, -142],
            [-20, -95],
            [90, -124],
            [179, -14],
            [-5, 138],
            [64, 37],
            [70, -1],
            [52, 40],
            [2, 84],
            [96, -26],
            [111, 2],
            [46, -97],
            [-46, -74],
            [27, -114],
            [101, -166],
            [102, -41],
            [95, 0],
            [59, -54],
            [196, -16],
            [75, -158],
            [-6, -179],
            [38, -108],
        ],
        [
            [53661, 45641],
            [-51, -5],
            [-166, -146],
            [-57, -21],
            [-223, 19],
            [-268, -26],
            [-258, -111],
            [-80, -82],
            [-251, -174],
            [-287, -303],
            [-128, -232],
            [-3, -50],
            [-61, -80],
            [2, -156],
            [97, -242],
            [85, -113],
            [60, -175],
            [136, -269],
            [28, -105],
            [-33, -124],
            [-212, 36],
            [-150, -75],
            [-41, -69],
            [8, -71],
            [-137, -230],
            [156, 5],
            [75, 19],
            [142, 127],
            [95, 42],
            [255, -42],
            [-427, -285],
            [-247, -182],
            [-68, -88],
            [-74, -147],
            [0, -150],
            [136, -45],
            [22, -83],
            [-59, -81],
            [-147, -130],
            [-113, -83],
            [-159, -61],
            [-230, -109],
            [-130, -95],
            [17, -41],
            [-54, -153],
            [-169, -174],
            [-11, -119],
            [-67, -69],
            [-108, 18],
            [-54, 138],
            [-96, 71],
            [-88, 23],
            [-40, -26],
            [-20, -69],
            [41, -31],
            [109, 18],
            [94, -136],
            [94, -74],
            [-185, -51],
            [-190, -100],
            [-268, -90],
            [-239, -66],
            [-256, -49],
            [-300, -85],
            [-507, -180],
            [-446, -232],
            [-232, -105],
            [-346, -203],
            [-434, -320],
            [-94, -83],
            [-57, -88],
            [-110, -87],
            [-167, -105],
            [-276, -235],
            [-116, -122],
            [-187, -250],
            [-88, 21],
            [-62, 68],
            [-57, -65],
            [46, -66],
            [-72, 69],
            [36, 70],
            [-115, 38],
            [-11, -100],
            [-144, -14],
            [-17, -60],
            [46, -59],
            [70, 24],
            [26, -93],
            [-115, 8],
            [-45, -105],
            [-52, -2],
            [0, 126],
            [-67, -42],
            [-123, -31],
            [-89, -85],
            [-16, -76],
            [-118, 57],
            [32, -100],
            [-70, -82],
            [36, -71],
            [-52, -69],
            [-67, -21],
            [-66, -94],
            [-78, 37],
            [27, -151],
            [-33, -56],
            [-89, -31],
            [-45, 27],
            [-105, -51],
            [-83, 40],
            [-102, -55],
            [-57, 18],
            [-188, -85],
            [-74, 17],
            [-48, 94],
            [-113, 61],
            [-68, -13],
            [-209, 48],
            [-105, 5],
            [-16, 60],
            [-57, 37],
            [-24, 167],
            [-61, 71],
            [-30, 140],
            [-65, 83],
            [-146, -100],
            [-58, 27],
            [-59, 171],
            [-77, 90],
            [-35, 113],
            [-70, 82],
            [-69, -61],
            [29, -81],
            [-62, -50],
            [-11, -76],
            [-75, 2],
            [-46, -63],
            [-59, -11],
            [-23, 116],
            [-79, 31],
            [-50, -17],
            [43, -202],
            [-73, 0],
            [40, -120],
            [-56, -32],
            [-76, 87],
            [-18, 53],
            [-129, -5],
            [-77, 36],
            [-60, -116],
            [132, -39],
            [-36, -95],
            [111, -143],
            [30, -110],
            [-108, -77],
            [-223, -52],
            [-37, -95],
            [-226, -37],
            [-145, 83],
            [-153, -195],
            [-75, -150],
            [-110, -44],
            [24, -98],
            [-33, -48],
            [119, -156],
            [104, -7],
            [-102, -161],
            [-98, -85],
            [58, -39],
            [81, -124],
            [-75, -63],
            [-132, 13],
            [-43, -58],
            [-206, 3],
            [-33, 105],
            [-199, 119],
            [-55, 18],
            [-63, -56],
            [30, -87],
            [-8, -105],
            [-48, -66],
            [-193, -102],
            [-92, -16],
            [-92, -180],
            [-118, 80],
            [0, 184],
            [31, 135],
            [-124, 66],
            [-56, 57],
            [50, 87],
            [-69, 14],
            [-17, 177],
            [-115, 33],
            [-21, 62],
            [-102, -114],
            [-30, -59],
            [-81, -75],
            [-101, -120],
            [66, -110],
            [-23, -55],
            [-87, -90],
            [110, -11],
            [24, -36],
            [-74, -103],
            [-84, -13],
            [-10, -146],
            [-75, -60],
            [12, -59],
            [106, -39],
            [-43, -56],
            [-1, -140],
            [28, -34],
            [-19, -102],
            [-89, -22],
            [-45, 59],
            [-82, -74],
            [35, -71],
            [-10, -70],
            [-70, -18],
            [-126, 84],
            [-54, 4],
            [-38, 91],
            [-198, 33],
            [-57, 37],
            [-34, -59],
            [-99, 37],
            [-63, -39],
            [1, -63],
            [-185, -114],
            [-374, -138],
            [-70, -92],
            [-203, -127],
            [-41, 69],
            [-70, -24],
            [-58, -108],
            [-87, -71],
            [-101, 35],
            [-57, -32],
            [-202, -39],
            [-32, 58],
            [-169, -53],
            [-69, 7],
        ],
        [
            [32441, 14711],
            [-10, -470],
        ],
        [
            [32431, 14241],
            [-87, -10],
            [-47, 176],
            [-84, 53],
            [-128, 24],
            [-49, 45],
            [-16, 113],
            [65, 2],
            [71, 80],
            [86, -37],
            [51, 55],
            [148, -31],
        ],
        [
            [32393, 17517],
            [-38, -169],
            [-66, -214],
        ],
        [
            [32289, 17134],
            [-38, 86],
            [-87, 57],
            [-69, -31],
            [-104, 20],
            [88, 109],
            [-113, 105],
            [49, 71],
            [-19, 67],
            [-146, 4],
            [14, 61],
            [123, 59],
            [-4, -62],
            [43, -55],
            [123, 26],
            [-44, -115],
            [39, -53],
            [157, 101],
            [27, -67],
            [65, 0],
        ],
        [
            [19802, 17834],
            [-35, -58],
            [-75, -5],
            [-25, -72],
            [-91, 63],
        ],
        [
            [39128, 31334],
            [-120, 58],
            [-100, 18],
            [49, 59],
            [65, -53],
            [168, 13],
            [52, -24],
            [0, -2],
            [-76, -16],
            [-38, -53],
        ],
        [
            [17595, 69888],
            [-320, 10],
            [-316, 28],
            [-105, -4],
            [-510, 41],
            [-494, 3],
            [-29, 76],
            [48, 167],
            [56, 40],
            [122, 174],
            [12, 197],
        ],
        [
            [16059, 70620],
            [-24, 195],
            [-66, 119],
            [-103, 133],
            [-59, 36],
            [-8, 64],
            [84, 97],
            [69, 13],
            [11, 109],
            [84, -1],
            [74, 135],
            [285, 200],
            [2, 72],
            [89, 90],
            [75, 10],
            [127, 114],
            [97, 141],
            [118, 67],
            [-25, 86],
            [118, 141],
            [24, 78],
            [156, 14],
            [-18, 95],
            [149, 76],
            [4, 42],
            [139, 116],
            [72, 3],
            [118, 88],
            [-19, 34],
            [60, 157],
            [49, 14],
            [80, -69],
            [156, 30],
            [75, 52],
            [32, 68],
            [-43, 98],
            [-147, 19],
            [-95, -24],
            [-11, -98],
            [-115, -15],
            [-6, 82],
            [-107, 57],
            [-13, 125],
            [57, 198],
            [-30, 71],
            [53, 78],
            [60, 0],
            [118, 286],
            [86, 78],
            [48, 109],
            [-193, 171],
            [74, 22],
            [3, 118],
            [-62, 161],
            [-171, 290],
            [3, 64],
            [94, 36],
            [-22, 56],
            [46, 111],
            [-11, 41],
            [266, 235],
            [144, 43],
            [16, 97],
            [73, -60],
            [183, 71],
            [45, 94],
            [138, 107],
            [163, 100],
            [96, -30],
            [106, -78],
            [147, 37],
            [49, 52],
            [141, 72],
            [183, 0],
            [22, 91],
            [51, 14],
            [77, -93],
            [90, 74],
            [32, 85],
            [72, 36],
            [37, 135],
            [114, 108],
            [10, 88],
            [-75, 58],
            [-9, 99],
            [-40, 43],
            [25, 23],
            [131, -47],
            [102, -13],
            [162, 44],
            [-16, -65],
            [134, -56],
            [24, 122],
            [58, 61],
            [132, 62],
            [153, 19],
            [178, 87],
            [94, 167],
            [93, -3],
            [71, 34],
            [93, 148],
            [96, 113],
        ],
        [
            [8103, 54578],
            [-95, 90],
            [-104, 253],
            [-87, 158],
            [-163, 238],
            [-56, 174],
            [-87, 437],
            [-236, 232],
            [-140, 146],
            [-42, 136],
            [-194, 198],
            [38, 375],
            [-34, 16],
            [15, 398],
            [-42, 106],
            [-54, 28],
            [-126, -10],
            [-191, -79],
            [-118, 0],
            [-252, -35],
            [-68, 14],
            [-250, 89],
            [-37, 27],
            [-77, 168],
            [-114, 112],
            [-70, 42],
            [-37, 73],
            [-163, 232],
            [-43, 119],
            [-28, 270],
            [38, 115],
            [142, 199],
            [38, 84],
            [37, 145],
            [-16, 181],
            [33, 125],
            [-8, 243],
            [23, 157],
            [-45, 185],
            [-49, 50],
            [-120, 63],
            [-115, 27],
            [-114, -2],
            [-297, -46],
            [-175, 1],
            [-110, 28],
            [-121, 62],
            [-140, 116],
            [-553, 240],
            [-42, 36],
            [-67, 167],
            [48, 274],
            [33, 340],
            [145, 353],
            [59, 116],
            [222, 214],
            [408, 314],
            [137, 125],
            [207, 290],
            [222, 157],
            [24, 38],
            [231, 574],
            [137, 196],
            [141, 150],
            [265, 175],
            [177, 182],
            [314, 75],
            [142, -5],
            [91, -37],
            [272, -260],
            [30, -46],
            [18, -221],
            [166, -280],
            [55, -74],
            [171, -37],
            [244, -10],
            [353, 164],
            [486, 195],
            [179, 52],
            [381, 69],
            [338, -2],
            [154, 15],
            [199, -3],
            [665, 257],
            [41, 263],
            [53, 216],
            [180, 246],
            [363, 280],
            [163, 160],
            [67, 122],
            [260, 770],
            [67, 100],
            [164, 188],
            [376, 189],
            [362, 217],
            [253, 131],
            [519, 209],
            [43, 27],
            [158, 343],
            [217, 250],
            [164, 279],
            [150, 290],
            [223, 372],
            [193, 617],
            [126, 457],
            [75, 68],
            [553, 212],
            [396, 100],
            [110, 37],
            [123, 114],
            [359, 252],
        ],
        [
            [25265, 63672],
            [80, -54],
            [-8, -91],
            [25, -58],
            [-22, -70],
            [87, -14],
            [23, -160],
            [-86, -120],
            [39, -44],
            [44, -209],
            [98, -18],
            [48, -106],
            [101, -58],
            [-40, -108],
            [46, -76],
            [97, -42],
            [85, -9],
            [127, -113],
            [88, -47],
            [116, 34],
            [-19, -145],
            [97, -143],
            [-6, -70],
            [86, -43],
            [-51, -72],
            [-112, -2],
            [-62, -100],
            [13, -32],
            [-147, -40],
            [-71, -81],
            [132, -29],
            [-19, -84],
            [110, -59],
            [162, -18],
            [38, 77],
            [91, -141],
            [-32, -63],
            [-138, -34],
            [-174, -106],
            [-269, -78],
            [-90, -85],
            [-78, -23],
            [-14, -70],
            [59, -70],
            [-29, -95],
            [101, -48],
            [46, 53],
            [48, 176],
            [62, -76],
            [245, 37],
            [134, 63],
            [42, 50],
            [122, 22],
            [-14, 65],
            [37, 56],
            [114, 44],
            [295, -95],
            [53, -46],
            [54, 73],
            [91, -44],
            [57, 38],
            [142, -12],
            [78, 21],
            [124, -38],
            [74, 74],
            [6, 79],
            [67, 6],
            [260, -68],
            [79, -6],
            [-36, -90],
            [-120, -68],
            [13, -119],
        ],
        [
            [57194, 61757],
            [-94, -13],
            [-17, 63],
            [-77, 43],
            [-68, -53],
            [-114, 76],
            [-76, 19],
            [-198, -58],
            [5, -71],
            [-75, -74],
            [-80, -32],
            [-33, -71],
            [-62, -24],
            [-124, 50],
            [-66, 0],
            [-165, 88],
            [-77, 7],
            [-124, -34],
            [-51, 47],
            [-97, -2],
            [-58, -60],
            [-60, 8],
            [-141, 71],
            [-8, 70],
            [-70, 141],
            [-135, 8],
        ],
        [
            [55129, 61956],
            [44, 91],
            [-1, 62],
            [83, 176],
            [-48, 111],
            [9, 114],
            [61, 55],
            [-5, 73],
            [-60, 124],
            [100, 199],
            [8, 82],
            [83, 70],
            [43, 114],
            [54, 45],
            [26, 193],
            [55, 42],
            [51, 113],
            [-59, 93],
            [69, 87],
            [-67, 46],
            [-124, 20],
            [-32, 112],
            [72, 149],
            [95, -54],
            [83, 64],
            [81, -2],
            [32, -51],
            [101, 56],
            [68, 7],
            [91, 53],
            [142, -29],
            [105, 87],
            [99, 34],
            [56, 74],
            [52, 11],
            [89, -35],
            [117, 141],
            [116, 53],
            [57, 2],
            [82, -88],
            [54, 14],
            [180, -16],
            [135, -124],
            [83, -45],
            [32, -71],
            [-13, -172],
            [64, -20],
            [59, -87],
            [2, -164],
            [-70, -60],
            [-8, -116],
            [27, -114],
            [-47, -113],
            [4, -91],
            [-104, -78],
            [-11, -112],
            [-108, -113],
            [60, -105],
            [-14, -213],
            [73, -146],
            [80, -76],
            [51, 21],
            [62, -64],
            [30, -81],
            [91, -30],
            [-64, -149],
            [-76, -48],
            [-104, 15],
            [-94, -47],
            [0, -77],
            [-44, -113],
            [-75, -29],
            [-18, -69],
        ],
        [
            [30932, 9920],
            [63, -49],
            [-54, -79],
            [58, -98],
            [205, -174],
            [-38, -6],
            [-209, 138],
            [-124, 52],
            [-155, 37],
            [-1, 72],
            [130, 4],
            [61, 77],
            [64, 26],
        ],
        [
            [27894, 19880],
            [24, -94],
            [-24, -50],
            [-71, -22],
            [14, -58],
            [76, 40],
            [104, 6],
            [72, -111],
            [51, -9],
            [165, -112],
            [254, 17],
            [-5, 130],
            [99, 21],
            [3, 177],
            [83, -148],
            [78, 52],
            [-19, 91],
            [97, 113],
            [30, 206],
            [94, 221],
            [61, 14],
            [-73, 160],
            [84, 129],
            [120, -41],
            [25, 152],
            [102, -32],
            [107, 22],
            [64, 66],
            [221, -18],
            [5, -144],
            [89, 140],
            [88, -13],
            [100, 35],
            [51, -53],
            [148, -56],
            [43, -70],
            [99, 29],
            [134, -48],
            [49, 23],
            [-1, 109],
            [65, 106],
            [59, 21],
            [34, 110],
            [124, 10],
            [8, -50],
            [68, -16],
            [144, 29],
            [-7, 109],
            [102, 64],
            [101, 0],
            [-37, 153],
            [40, 144],
            [-46, -3],
            [-86, 50],
            [121, 56],
            [103, 6],
            [225, -38],
            [16, -94],
            [67, -85],
            [54, -8],
            [45, 61],
            [188, 42],
            [43, -74],
            [-37, -61],
            [204, -120],
            [109, 97],
            [-171, 110],
            [67, 41],
            [22, 67],
            [74, -60],
            [55, 64],
            [142, -3],
            [147, 87],
            [59, 8],
            [79, 111],
            [-60, 89],
            [86, 39],
            [-3, 96],
            [94, 24],
            [29, 82],
            [-45, 115],
            [154, -15],
            [67, 45],
            [86, -93],
            [56, -8],
            [88, -69],
            [178, 8],
            [85, -47],
            [36, -52],
            [47, -170],
            [108, -52],
            [-6, 165],
            [46, -81],
            [45, -211],
            [-8, -163],
            [-91, -315],
            [-27, -60],
            [11, -142],
            [-62, -108],
            [-8, -91],
            [-50, -223],
            [-33, -257],
            [-7, -157],
            [20, -80],
            [-153, -477],
            [-93, -262],
            [-24, -157],
            [-113, -190],
            [-174, -204],
            [-111, -211],
            [-81, -36],
            [14, -77],
            [-292, -464],
            [-105, -246],
            [-27, -116],
        ],
        [
            [32289, 17134],
            [-33, -189],
            [-37, -132],
            [-49, -332],
            [11, -137],
            [148, -346],
            [42, -185],
            [25, -5],
            [2, -203],
            [27, -145],
            [27, -272],
            [-11, -477],
        ],
        [
            [32431, 14241],
            [-1, -155],
            [14, -505],
            [27, -497],
            [51, -322],
            [-46, -56],
            [-88, -32],
            [-255, -2],
            [48, 113],
            [-88, -8],
            [-67, 37],
            [-164, -10],
            [-54, 73],
            [-38, -81],
            [17, -55],
            [-621, 57],
            [-72, -65],
            [-111, -33],
            [-98, -68],
            [-76, -84],
            [37, -53],
            [-112, -97],
            [-41, -238],
            [73, -93],
            [-2, -102],
            [-269, -290],
            [-21, -59],
            [-81, -110],
            [8, -56],
            [-84, -79],
            [-94, -161],
            [-51, -23],
            [-165, -224],
            [-57, -169],
            [-70, -66],
            [-29, -100],
            [-25, -227],
            [-39, -75],
            [82, -147],
            [103, -126],
            [150, -137],
            [121, -80],
            [125, -32],
            [98, 8],
            [69, -34],
            [-211, -58],
            [-320, 40],
            [-148, -22],
            [-57, -49],
            [-125, 12],
            [-288, -110],
            [-59, -11],
            [-46, -63],
            [-144, 19],
            [-62, -45],
            [13, -72],
            [-99, -13],
            [-107, -60],
            [-63, 16],
            [-265, -30],
            [-129, -42],
            [-174, -150],
            [-212, -159],
            [-99, -63],
            [-78, -74],
            [-70, -116],
            [-43, -111],
            [-17, -311],
            [87, -19],
            [-68, -66],
            [-42, -96],
            [-148, -214],
            [2, -75],
            [83, -23],
            [20, -45],
            [-44, -211],
            [22, -55],
            [-119, -130],
            [-63, -94],
            [-28, -111],
            [-90, -67],
            [-117, -55],
            [-75, -76],
            [-147, -82],
            [-33, -37],
            [-247, -92],
            [-45, -101],
            [-102, -86],
            [-88, -34],
            [-287, -45],
            [-103, -43],
            [-42, -44],
            [-3, -87],
            [-65, -35],
            [-351, 74],
            [-165, 55],
            [-131, 27],
            [-21, 52],
            [-113, 66],
            [-199, 137],
            [-38, 53],
            [-220, 174],
        ],
        [
            [66977, 51808],
            [-57, -13],
            [-2, -93],
            [-51, -94],
            [-43, 13],
            [13, 90],
            [-105, 78],
            [-82, 32],
            [-178, -229],
            [-45, -27],
            [-97, 48],
            [-92, 81],
            [-53, 119],
            [-59, -7],
            [-13, -83],
            [-41, -50],
            [59, -300],
            [-8, -182],
            [41, -59],
            [-95, -102],
            [1, -68],
            [-106, 12],
            [-149, -113],
            [-17, -66],
            [-100, -121],
            [-104, -217],
            [80, -197],
            [41, -167],
            [56, -136],
            [-32, -48],
            [-126, -31],
            [-61, -143],
            [-78, -55],
            [-82, 16],
            [-36, -41],
            [-79, -4],
            [-91, -103],
            [-171, 73],
            [-44, 67],
            [19, 100],
            [-107, 208],
            [15, 42],
            [-63, 90],
            [23, 105],
            [-47, 45],
            [-89, 156],
            [-99, 5],
            [-73, -100],
            [-11, -204],
            [51, -136],
            [33, -137],
            [-55, -22],
            [-67, 35],
            [-69, 110],
            [-38, 145],
            [-26, 19],
            [2, 168],
            [-16, 111],
            [-78, 195],
            [33, 99],
            [-47, 33],
            [-24, 128],
            [-66, 110],
            [-17, 121],
            [-72, 29],
            [-65, 72],
            [-30, 147],
            [-69, 58],
            [-17, 98],
            [36, 84],
            [86, -18],
            [-16, 119],
            [-118, -3],
            [-8, 93],
            [47, 84],
            [108, 0],
            [64, 52],
            [9, 122],
            [54, 81],
            [-70, 174],
            [34, 71],
            [89, 8],
            [-29, 64],
            [43, 92],
            [71, 53],
            [151, -35],
            [84, 124],
            [15, 66],
            [-53, 87],
            [-5, 75],
            [96, 12],
            [150, -41],
            [75, 2],
            [151, -65],
            [142, 13],
            [105, 65],
            [29, 195],
            [44, 51],
            [137, -103],
            [145, -26],
            [-51, 303],
            [275, -143],
            [82, -69],
            [47, -108],
            [60, 16],
            [7, 85],
            [82, 279],
            [-60, 198],
            [102, 61],
            [48, -72],
            [67, 20],
            [-56, 123],
            [89, 1],
            [239, -17],
            [64, 24],
            [54, 117],
            [96, 20],
            [-51, 142],
            [52, 157],
            [93, -79],
            [67, -8],
        ],
        [
            [26065, 71229],
            [202, 16],
            [105, -73],
            [104, -135],
            [113, -40],
            [171, -99],
            [290, -100],
            [33, -48],
            [-68, -72],
            [-23, -84],
            [-212, -269],
            [-94, 18],
            [-78, -155],
            [43, -43],
            [-92, -108],
            [-2, -105],
            [-62, -69],
            [42, -61],
            [-83, -136],
            [76, -40],
            [34, -127],
            [62, -70],
            [-19, -202],
            [110, -54],
            [-15, -45],
            [118, -30],
            [110, 87],
            [72, -12],
            [115, 49],
            [11, -79],
            [89, -132],
            [-2, -81],
            [-78, -69],
            [101, -16],
            [-16, -89],
            [150, -8],
            [30, 121],
            [97, 18],
            [34, 74],
            [121, 98],
            [12, 52],
            [77, 9],
            [76, 89],
            [106, -18],
            [199, 156],
            [79, 113],
            [152, -64],
            [282, -95],
            [56, -149],
            [11, -116],
            [52, -78],
            [128, -126],
            [175, -95],
            [152, -113],
            [90, -1],
            [166, -95],
            [317, -57],
            [-9, -95],
            [-110, -111],
            [-120, -45],
            [-81, -79],
            [-77, 19],
            [-87, -58],
            [5, -50],
            [86, -75],
            [154, -100],
            [50, 26],
            [89, -34],
            [99, -137],
            [16, -113],
            [110, -40],
            [62, 45],
            [94, -142],
            [33, 95],
            [168, 8],
            [78, -58],
            [114, -35],
            [-20, -110],
            [25, -64],
            [59, -26],
            [-29, -69],
            [39, -32],
            [98, 38],
            [94, -66],
            [114, -122],
            [197, -37],
            [145, -58],
            [-16, -135],
            [43, -87],
            [268, 22],
            [70, 78],
            [59, -34],
            [-8, -94],
            [115, 58],
            [75, 4],
            [131, -55],
            [121, 88],
            [180, 37],
            [79, -58],
            [-4, -111],
            [132, 36],
            [-22, -108],
            [133, -68],
            [64, -59],
            [50, -112],
            [162, -27],
            [72, 58],
            [73, 134],
            [-41, 48],
            [92, 103],
        ],
        [
            [33008, 66664],
            [174, -21],
            [185, -161],
            [134, -24],
            [65, -44],
            [-26, -51],
            [56, -48],
            [115, -37],
            [29, -89],
            [79, -42],
            [29, -53],
            [169, 16],
            [81, -42],
            [72, -116],
            [97, -79],
            [-40, 316],
            [137, 74],
            [91, -35],
            [-17, -54],
            [132, -70],
            [105, 4],
            [92, -183],
            [125, -7],
            [68, -29],
            [42, -112],
            [103, -25],
            [50, -53],
            [140, 20],
            [45, -28],
            [3, -111],
            [137, -32],
            [22, -27],
            [136, 30],
            [108, -116],
            [154, -90],
            [294, -53],
            [57, -82],
            [13, -142],
            [146, -169],
            [77, -61],
            [29, -187],
            [140, 29],
            [0, 74],
            [134, -7],
            [75, -99],
            [78, -66],
            [-16, -87],
            [430, -237],
            [188, -42],
            [225, -198],
            [115, -53],
            [189, -129],
            [110, -5],
            [131, 200],
            [271, -3],
            [81, -118],
            [62, -53],
            [94, 0],
            [132, -57],
            [142, -104],
            [102, -94],
            [152, -71],
            [84, -64],
            [24, -51],
            [196, -112],
            [163, 39],
            [135, -2],
            [190, 68],
            [157, 15],
            [82, -84],
            [-10, -76],
            [77, -88],
            [-16, -57],
            [47, -84],
            [-38, -112],
            [15, -131],
            [200, -13],
            [63, 16],
            [117, -14],
            [118, 13],
            [61, -77],
            [293, -76],
            [367, 13],
            [87, -103],
            [158, -107],
            [18, -100],
            [92, -51],
            [128, 32],
            [92, 95],
            [40, 76],
            [-38, 105],
            [41, 56],
            [-13, 69],
            [338, -19],
            [238, -5],
            [119, -48],
            [279, -147],
            [199, -80],
            [120, -89],
        ],
        [
            [29803, 73715],
            [27, 21],
            [37, 210],
            [142, 53],
            [72, 127],
            [78, 81],
            [6, 85],
            [56, 43],
            [54, -40],
            [147, -58],
            [95, -114],
            [40, -79],
            [123, -102],
            [-2, -50],
            [75, -69],
            [-40, -124],
            [80, -24],
            [72, -145],
            [-16, -43],
            [46, -92],
            [72, -55],
            [157, -34],
            [28, -106],
            [41, -15],
            [19, -114],
            [233, 11],
            [115, -146],
            [-20, -50],
            [150, -87],
            [191, 121],
            [120, -12],
            [135, 71],
            [42, -38],
            [137, -46],
            [127, 4],
            [69, -155],
            [49, -10],
            [50, -87],
            [73, 0],
            [147, -70],
            [84, -71],
            [54, 1],
            [70, -72],
            [13, -55],
            [81, -60],
            [157, 96],
            [70, -75],
            [16, -66],
            [90, 4],
            [40, -73],
            [-49, -125],
            [-110, -79],
            [81, -73],
            [5, -183],
            [81, -49],
            [104, 28],
            [252, -141],
            [90, 13],
            [64, -74],
            [172, -45],
            [126, -85],
            [70, 18],
            [29, 64],
            [77, -19],
            [154, -108],
            [34, 1],
            [194, -127],
            [80, -79],
            [35, -77],
            [108, -84],
            [293, -93],
            [215, -50],
            [72, -57],
            [26, -137],
            [-297, -62],
            [-41, 90],
            [-53, 24],
            [-97, -127],
            [19, -123],
            [-92, -83],
            [-85, -21],
            [-60, -81],
            [-74, -53],
            [-52, -134],
            [-53, -3],
            [-119, -117],
            [-147, 9],
            [-81, -30],
            [-77, -89],
            [5, -82],
            [-52, -119],
            [-123, -87],
            [-59, -89],
            [-134, 36],
            [-88, -46],
            [-20, -87],
            [-78, -30],
            [8, -89],
            [49, -146],
            [83, -97],
            [-31, -109],
            [-71, -78],
            [-107, -69],
            [6, -95],
            [-135, -84],
            [-41, -111],
            [-115, -6],
            [6, -108],
            [80, -42],
            [21, -147],
            [87, -61],
            [-52, -254],
            [16, -94],
            [-141, 42],
            [54, -214],
            [-89, -73],
            [-138, 42],
            [-106, -83],
            [-44, -94],
            [-42, -253],
            [-52, -13],
            [-99, -180],
            [-8, -214],
            [19, -47],
        ],
        [
            [56487, 45617],
            [90, -48],
            [4, -121],
            [-71, -2],
            [-68, 40],
            [45, 131],
        ],
        [
            [57425, 45638],
            [161, -81],
            [21, -79],
            [-128, -6],
            [-71, 79],
            [17, 87],
        ],
        [
            [57130, 45725],
            [21, -79],
            [57, -31],
            [-17, -19],
            [17, 10],
            [2, 14],
            [-13, 10],
            [-41, 17],
            [-8, 100],
            [103, 24],
            [4, -96],
            [68, -82],
            [-30, -33],
            [-107, -3],
            [3, 37],
            [-8, -9],
            [-17, -47],
            [-91, 50],
            [11, 101],
            [46, 36],
        ],
        [
            [56598, 45730],
            [129, -81],
            [7, -66],
            [-101, -71],
            [-38, 71],
            [-73, 39],
            [76, 108],
        ],
        [
            [57790, 45738],
            [62, 9],
            [19, -53],
            [-20, -38],
            [-88, 12],
            [27, 70],
        ],
        [
            [56706, 45884],
            [63, -148],
            [-18, -66],
            [-97, 48],
            [52, 166],
        ],
        [
            [57841, 45924],
            [14, -12],
            [12, -89],
            [-18, -18],
            [2, -34],
            [17, -11],
            [77, 16],
            [19, -145],
            [-78, -8],
            [-21, 121],
            [-13, 7],
            [-65, -5],
            [-34, 166],
            [53, -12],
            [35, 24],
        ],
        [
            [57347, 45926],
            [0, -124],
            [-29, -22],
            [-52, 24],
            [-53, -4],
            [-25, 31],
            [-50, 27],
            [80, 0],
            [11, 31],
            [118, 37],
        ],
        [
            [55969, 45926],
            [47, -2],
            [63, -80],
            [8, -82],
            [-47, -84],
            [-87, 23],
            [30, 106],
            [-14, 119],
        ],
        [
            [56365, 45936],
            [47, -102],
            [-55, -158],
            [-84, 89],
            [-3, 113],
            [95, 58],
        ],
        [
            [57041, 45945],
            [18, -74],
            [106, -98],
            [-44, -45],
            [-107, 132],
            [27, 85],
        ],
        [
            [55672, 45970],
            [-14, -134],
            [70, -13],
            [19, -87],
            [-73, -92],
            [-67, 232],
            [65, 94],
        ],
        [
            [58048, 45971],
            [93, -159],
            [3, -87],
            [-121, -49],
            [-66, 70],
            [2, 125],
            [-22, 60],
            [111, 40],
        ],
        [
            [57824, 45986],
            [116, -79],
            [-5, -111],
            [-29, -28],
            [-41, -1],
            [8, 61],
            [-10, 80],
            [-39, 78],
        ],
        [
            [56622, 46013],
            [69, -11],
            [43, -97],
            [-56, -18],
            [5, -80],
            [-75, -74],
            [-67, 211],
            [81, 69],
        ],
        [
            [57559, 46039],
            [86, -57],
            [78, -104],
            [-56, -166],
            [-100, 84],
            [-35, 95],
            [27, 148],
        ],
        [
            [56249, 45999],
            [46, -84],
            [-43, -32],
            [-6, -92],
            [59, -92],
            [-124, -42],
            [-21, 195],
            [52, 53],
            [37, 94],
        ],
        [
            [55717, 46089],
            [143, -29],
            [0, -69],
            [110, -165],
            [-52, -103],
            [-10, -117],
            [45, -47],
            [-40, -50],
            [-120, 3],
            [-8, 79],
            [-79, 84],
            [49, 45],
            [-5, 98],
            [-17, 18],
            [-51, -2],
            [36, 81],
            [-25, 51],
            [24, 123],
        ],
        [
            [57682, 46087],
            [57, -11],
            [49, -23],
            [13, -32],
            [2, -61],
            [36, -34],
            [-31, -22],
            [-61, 11],
            [-78, 55],
            [13, 117],
        ],
        [
            [56120, 46095],
            [0, -122],
            [89, -28],
            [-111, -75],
            [-56, 66],
            [32, 50],
            [-18, 79],
            [26, 29],
            [38, 1],
        ],
        [
            [57900, 46097],
            [140, -102],
            [-99, -37],
            [-14, 49],
            [-38, 46],
            [11, 44],
        ],
        [
            [57417, 46100],
            [81, -13],
            [-58, -134],
            [1, -78],
            [54, -133],
            [-55, -66],
            [-87, 57],
            [40, 90],
            [-12, 95],
            [-104, 84],
            [27, 48],
            [61, 5],
            [52, 45],
        ],
        [
            [56722, 46134],
            [5, -48],
            [8, -26],
            [81, -32],
            [-67, -65],
            [-63, 145],
            [36, 26],
        ],
        [
            [57883, 46156],
            [-2, -98],
            [37, -48],
            [-20, -55],
            [-79, 37],
            [35, 32],
            [-15, 24],
            [-38, -22],
            [-9, 26],
            [-15, 16],
            [16, 50],
            [90, 38],
        ],
        [
            [56799, 46171],
            [37, -50],
            [-4, -98],
            [-98, 50],
            [8, 66],
            [9, 17],
            [48, 15],
        ],
        [
            [56378, 46166],
            [46, 8],
            [-22, -158],
            [-33, -75],
            [-61, -20],
            [-29, 181],
            [99, 64],
        ],
        [
            [57095, 46221],
            [31, -77],
            [89, -65],
            [-18, -80],
            [64, -21],
            [-53, -52],
            [16, -39],
            [-6, -22],
            [-43, -5],
            [-33, 16],
            [-7, -21],
            [67, -46],
            [-19, -23],
            [-102, 93],
            [-45, 157],
            [59, 185],
        ],
        [
            [56617, 46229],
            [69, -48],
            [-19, -97],
            [-106, -122],
            [-55, 93],
            [1, 64],
            [110, 110],
        ],
        [
            [56276, 46230],
            [51, -62],
            [-108, -105],
            [-51, 76],
            [108, 91],
        ],
        [
            [57406, 46210],
            [6, -107],
            [-51, -46],
            [-73, 51],
            [96, 155],
            [22, -53],
        ],
        [
            [57293, 46039],
            [-85, -34],
            [21, 148],
            [58, -34],
            [6, -80],
        ],
        [
            [57650, 46274],
            [13, -8],
            [19, 0],
            [-7, 35],
            [23, 5],
            [6, 24],
            [18, 5],
            [14, -21],
            [19, -40],
            [2, -52],
            [-96, -96],
            [13, 37],
            [-13, 11],
            [27, 24],
            [14, 42],
            [-20, -30],
            [1, -12],
            [-27, -24],
            [15, -10],
            [-48, -106],
            [-15, 74],
            [-43, 95],
            [26, 8],
            [22, 24],
            [37, 15],
        ],
        [
            [56451, 46300],
            [44, -65],
            [-40, -42],
            [-72, 7],
            [-13, 53],
            [81, 47],
        ],
        [
            [56749, 46345],
            [67, -57],
            [-22, -51],
            [-88, 11],
            [43, 97],
        ],
        [
            [56023, 46375],
            [56, -16],
            [28, -172],
            [53, -26],
            [-7, -55],
            [-55, -3],
            [-40, -19],
            [-10, -27],
            [15, -86],
            [-110, -11],
            [-5, 126],
            [-61, 88],
            [-49, 7],
            [-11, 38],
            [16, 20],
            [116, 48],
            [21, 42],
            [-10, 26],
            [8, 12],
            [45, 8],
        ],
        [
            [57484, 46388],
            [-1, -22],
            [15, -5],
            [-33, 118],
            [-35, 62],
            [139, -9],
            [79, 40],
            [158, -84],
            [13, -93],
            [-59, -39],
            [-21, -37],
            [-5, 0],
            [-20, 36],
            [-2, -13],
            [-14, -15],
            [-5, -18],
            [-24, -8],
            [-22, -22],
            [-32, -16],
            [-37, -29],
            [-33, 121],
            [-45, 6],
            [43, -11],
            [6, -128],
            [48, -77],
            [-16, -56],
            [-156, 56],
            [13, 195],
            [46, 48],
        ],
        [
            [55484, 46417],
            [56, -130],
            [38, -248],
            [-48, -153],
            [4, -116],
            [-58, -68],
            [-52, -9],
            [-149, 35],
            [-57, 32],
            [-13, 73],
            [19, 127],
            [50, 48],
            [28, 127],
            [141, 255],
            [41, 27],
        ],
        [
            [57298, 46427],
            [9, -16],
            [-17, -13],
            [8, -14],
            [27, 20],
            [13, -11],
            [71, -9],
            [-22, -113],
            [-73, -36],
            [-82, 52],
            [32, 101],
            [19, 26],
            [15, 13],
        ],
        [
            [57827, 46470],
            [143, -88],
            [-29, -132],
            [-122, -39],
            [-70, 108],
            [79, 82],
            [-1, 69],
        ],
        [
            [56832, 46483],
            [15, -11],
            [1, -21],
            [21, -29],
            [-10, -16],
            [39, 0],
            [20, -8],
            [-39, -243],
            [-47, 24],
            [-3, 90],
            [-40, 65],
            [-32, 29],
            [75, 120],
        ],
        [
            [57376, 46493],
            [65, -42],
            [-27, -63],
            [-64, 8],
            [26, 97],
        ],
        [
            [55397, 59985],
            [-16, 48],
            [71, 53],
            [21, 139],
            [56, 40],
            [19, 134],
            [54, 108],
            [5, 145],
            [-45, 290],
            [-78, 62],
            [0, 113],
            [-59, 68],
            [34, 85],
            [-118, 78],
            [-19, 75],
            [-112, 15],
            [-51, 51],
            [-6, 84],
            [-91, 68],
            [3, 54],
            [59, 103],
            [5, 158],
        ],
        [
            [57194, 61757],
            [134, -34],
            [72, -55],
            [122, -26],
            [24, -206],
            [-22, -79],
            [6, -156],
            [47, 11],
            [60, 97],
            [99, -50],
            [24, -68],
            [-38, -43],
            [119, -53],
            [121, 50],
            [61, -102],
            [100, -16],
            [51, -110],
            [-5, -67],
            [74, -79],
            [122, 35],
            [48, -38],
            [128, 35],
            [46, -11],
            [188, 106],
            [178, 24],
            [16, -56],
            [163, -21],
            [30, -84],
            [102, -9],
            [91, 46],
            [107, -50],
            [240, -87],
            [-31, -72],
            [-77, -61],
            [123, -21],
            [30, 59],
            [94, -4],
            [65, -44],
            [80, -5],
            [61, -63],
            [84, 46],
            [137, -33],
        ],
        [
            [59884, 58899],
            [-64, 28],
            [-97, 122],
            [-64, 29],
            [10, -142],
            [-48, -3],
            [-70, -111],
            [91, -69],
            [52, -137],
            [-181, -110],
            [-11, -153],
            [-66, -40],
            [-36, 86],
            [-101, 17],
            [-115, -5],
            [-95, 50],
            [-54, 70],
            [-45, -57],
            [-96, -35],
            [-95, 85],
            [-187, 79],
            [-20, 111],
            [-67, 58],
            [-90, 0],
            [-105, 58],
            [-54, 110],
            [18, 161],
            [-53, 55],
            [-48, 103],
            [83, 29],
            [-102, 98],
            [-13, 150],
            [-73, 45],
            [-76, -7],
            [-48, 53],
            [-78, 12],
            [-29, 88],
            [-72, 29],
            [-35, -92],
            [-73, -72],
            [-21, -85],
            [247, -123],
            [-9, -90],
            [70, -32],
            [105, 8],
            [6, -102],
            [-30, -40],
            [-161, 11],
            [-105, -11],
            [-75, 127],
            [-150, 5],
            [-78, -156],
            [-95, 68],
            [8, 119],
            [-50, 32],
            [-118, -32],
            [0, 96],
            [-33, 58],
            [-147, -27],
            [-32, 113],
            [-99, 129],
            [-146, 64],
            [-112, 23],
            [-22, 75],
            [-62, -3],
            [-7, 71],
            [-89, 31],
            [-17, 70],
            [-150, 28],
            [-40, 233],
            [-46, -11],
            [-66, -98],
            [-70, -237],
            [-49, -97],
            [76, -70],
            [36, 95],
            [108, -46],
            [196, -32],
            [27, -129],
            [86, -127],
            [-46, -38],
            [-179, -9],
            [-81, -58],
            [-33, -56],
            [-104, -51],
            [-40, -54],
            [3, -178],
            [-89, -53],
            [-194, -36],
            [-48, -53],
            [-148, -73],
            [-13, -107],
            [-40, -49],
            [75, -85],
            [-158, -219],
            [-54, -160],
            [-70, -59],
            [57, -95],
            [19, -94],
            [-23, -69],
            [145, -140],
            [78, 66],
            [46, -29],
            [188, 58],
            [5, -82],
            [51, -19],
            [138, -115],
            [109, -64],
            [-11, -50],
            [89, -74],
            [137, -26],
            [-23, -137],
            [67, -95],
            [220, -188],
            [204, -62],
            [113, -70],
            [98, -2],
            [28, 76],
            [86, 14],
            [71, -27],
            [87, 87],
            [46, -102],
            [48, -3],
            [27, -93],
            [-41, -158],
            [51, -21],
            [-5, -86],
            [78, -43],
            [38, -69],
            [56, 35],
            [38, -79],
            [104, -3],
            [92, -35],
            [-87, -102],
            [3, -121],
            [-59, -56],
            [-43, -95],
            [-69, 50],
            [-60, -8],
            [-80, 84],
            [-122, -112],
            [-157, 39],
            [-105, 71],
            [-108, -27],
            [-158, 14],
            [-105, -77],
            [-62, 50],
            [-211, 50],
            [-95, -54],
            [54, -127],
            [-57, -72],
            [28, -137],
            [-43, -94],
            [43, -17],
            [-41, -92],
            [-37, -8],
            [-76, -110],
            [12, -67],
            [-114, 9],
            [-53, -29],
            [-43, -82],
            [48, -84],
            [-78, -3],
            [-134, 37],
            [-10, 73],
            [-92, 114],
            [-140, -11],
            [-110, -106],
            [46, -55],
            [29, -102],
            [-155, -148],
            [-124, -183],
            [-21, -102],
            [-132, -127],
            [94, -97],
            [170, -269],
            [112, -116],
            [80, -43],
            [81, -95],
            [166, -39],
            [175, -167],
            [57, -31],
            [121, -13],
            [128, 26],
            [193, -106],
            [65, -7],
            [70, -134],
            [35, 87],
            [167, -56],
            [126, 24],
            [136, -53],
            [40, -85],
            [-13, -203],
            [-91, -92],
            [30, -139],
            [43, -66],
            [-9, -88],
            [38, -28],
            [14, -207],
            [-19, -74],
            [21, -73],
            [-24, -58],
            [-186, -88],
            [-93, -65],
            [-30, 36],
            [-129, -41],
            [72, -125],
            [-58, -82],
            [-27, -187],
            [-21, -28],
            [29, -130],
            [-35, -71],
            [88, -56],
            [-16, -78],
            [157, 26],
            [48, -171],
            [64, -32],
            [32, -63],
            [64, -27],
            [67, -79],
            [103, 48],
            [58, -48],
            [-31, -116],
            [-70, 3],
            [-30, -159],
            [25, -58],
            [-73, -53],
            [30, -90],
            [-140, -73],
            [75, -68],
            [-10, -50],
            [75, -43],
            [62, 3],
            [45, -61],
            [80, -3],
            [46, 82],
            [61, -58],
            [168, 14],
            [107, -80],
            [78, 56],
            [8, -92],
            [-59, 0],
            [-65, -61],
            [-19, -88],
            [-94, -95],
            [-77, -29],
            [-9, -79],
            [33, -116],
            [-27, -174],
            [80, -58],
            [33, -208],
            [102, 0],
            [61, -103],
            [-26, -140],
            [-105, -84],
            [0, -92],
            [107, -124],
            [-80, -146],
            [2, -55],
            [72, -35],
            [-59, -57],
            [6, -56],
            [-116, -23],
            [-75, -51],
            [-37, -98],
            [-11, -162],
            [-37, -56],
            [-68, 56],
            [-10, 75],
            [-57, -2],
            [33, -110],
            [48, -38],
            [80, 0],
            [22, -65],
            [-19, -127],
            [-46, -14],
            [-26, 3],
            [-20, -8],
            [-39, -84],
            [7, -132],
            [-32, -23],
            [0, -19],
            [-7, -27],
            [-14, -4],
            [-38, -22],
            [-80, -63],
            [-25, -100],
            [-98, 44],
            [-73, -15],
            [-95, -145],
            [-53, -145],
            [45, -183],
            [-59, -66],
            [-45, -148],
            [45, -91],
            [-34, -90],
            [-68, -50],
            [-43, 23],
            [39, -39],
            [-38, -155],
            [-49, -12],
            [-40, 66],
            [88, 140],
            [-48, 26],
            [8, -70],
            [-56, -85],
            [-81, 59],
            [28, 94],
            [-98, -29],
            [-43, 6],
            [11, 60],
            [-58, -19],
            [-25, -28],
            [-22, 16],
            [-23, -17],
            [-52, -47],
            [-32, -134],
            [19, -104],
            [-80, -17],
            [-25, 168],
            [-82, 19],
            [-25, -9],
            [-14, -12],
            [-16, -71],
            [-104, -38],
            [-24, -23],
            [-3, -25],
            [67, -134],
            [-183, 27],
            [-45, 161],
            [-104, 171],
            [37, 121],
            [-64, 116],
            [69, 63],
            [99, 156],
            [28, 159],
            [-38, 174],
            [-62, 108],
            [-198, 65],
            [-124, 3],
            [89, 274],
            [-71, -58],
            [-10, -92],
            [-73, -95],
            [-64, 6],
            [-118, 71],
            [-67, 98],
            [-3, 197],
            [-70, -100],
            [11, -142],
            [96, -82],
            [51, -79],
            [79, -27],
            [45, 27],
            [86, -58],
            [113, 7],
            [81, -21],
            [161, -215],
            [-73, -109],
            [-233, -151],
            [-84, -25],
            [-202, -451],
            [-114, -109],
            [-33, -60],
            [-91, -43],
            [-178, -147],
            [-115, -116],
            [-101, 8],
            [-38, -54],
            [-197, -79],
            [-303, -55],
            [-217, -71],
        ],
        [
            [56345, 46504],
            [40, -32],
            [63, -26],
            [19, -101],
            [-100, -82],
            [-33, -57],
            [-139, 87],
            [21, 118],
            [47, 42],
            [7, 45],
            [44, -3],
            [31, 9],
        ],
        [
            [57100, 46511],
            [26, -23],
            [55, -8],
            [-17, 11],
            [-34, -1],
            [-33, 55],
            [19, 38],
            [24, -35],
            [62, 19],
            [124, -19],
            [42, -50],
            [-50, -76],
            [-9, -27],
            [-16, -2],
            [17, 18],
            [-3, 14],
            [-13, 5],
            [-30, -29],
            [-13, 24],
            [-39, 7],
            [-23, 45],
            [-6, 3],
            [25, -51],
            [50, -42],
            [-29, -78],
            [3, -33],
            [34, -13],
            [-75, -63],
            [-69, -18],
            [-82, 139],
            [60, 190],
        ],
        [
            [56883, 46553],
            [74, -25],
            [-39, -95],
            [0, -21],
            [-54, -4],
            [8, 21],
            [-19, 16],
            [-2, 27],
            [-14, 23],
            [46, 58],
        ],
        [
            [57177, 46722],
            [11, -61],
            [138, -95],
            [-105, 14],
            [-79, -16],
            [-29, 50],
            [8, 105],
            [56, 3],
        ],
        [
            [56938, 46836],
            [156, -55],
            [-105, -166],
            [-128, 28],
            [8, 125],
            [69, 68],
        ],
        [
            [55495, 46857],
            [-52, -111],
            [-115, -163],
            [-97, -48],
            [67, 219],
            [67, 60],
            [130, 43],
        ],
        [
            [57363, 46836],
            [178, -13],
            [-62, 82],
            [134, 7],
            [-3, -78],
            [-67, -11],
            [64, -5],
            [21, -127],
            [0, -116],
            [-142, -29],
            [-88, 60],
            [-135, 11],
            [-69, 44],
            [-6, 67],
            [33, 95],
            [112, 44],
            [30, -31],
        ],
        [
            [57929, 46928],
            [68, -5],
            [77, -103],
            [28, -79],
            [-41, -151],
            [-92, -24],
            [-86, 69],
            [52, 74],
            [-121, 55],
            [-19, 62],
            [51, 97],
            [83, 5],
        ],
        [
            [57361, 46957],
            [55, -29],
            [1, -69],
            [-143, 30],
            [87, 68],
        ],
        [
            [57524, 47010],
            [30, -97],
            [-57, 2],
            [-29, -11],
            [-86, 58],
            [142, 48],
        ],
        [
            [57338, 47028],
            [6, -55],
            [-70, -52],
            [-89, 33],
            [86, 127],
            [67, -53],
        ],
        [
            [57377, 47139],
            [83, -18],
            [51, -103],
            [-78, -26],
            [-89, 36],
            [-18, 79],
            [51, 32],
        ],
        [
            [57510, 47266],
            [57, -6],
            [102, -161],
            [-16, -54],
            [-124, -9],
            [-70, 98],
            [51, 132],
        ],
        [
            [57745, 47331],
            [98, -120],
            [-10, -100],
            [-65, -156],
            [52, -63],
            [-32, -61],
            [20, -87],
            [60, -50],
            [2, -82],
            [73, -59],
            [-80, -42],
            [-170, 101],
            [-51, 152],
            [8, 48],
            [-40, 155],
            [81, 98],
            [-59, 104],
            [29, 81],
            [84, 81],
        ],
        [
            [57890, 47361],
            [31, -11],
            [25, -139],
            [75, -43],
            [-49, -114],
            [20, -113],
            [-211, 17],
            [49, 52],
            [46, 177],
            [-54, 82],
            [-13, 74],
            [81, 18],
        ],
        [
            [57036, 47387],
            [102, -42],
            [93, -5],
            [67, -66],
            [-64, -69],
            [-85, -37],
            [-35, -58],
            [-94, -68],
            [-1, -87],
            [113, -63],
            [30, -53],
            [-56, -43],
            [-157, 55],
            [-32, 167],
            [-50, 71],
            [51, 167],
            [118, 131],
        ],
        [
            [57452, 47331],
            [15, -136],
            [-144, -51],
            [-135, -128],
            [-29, -70],
            [-97, 24],
            [-5, 38],
            [91, 79],
            [40, 86],
            [60, 22],
            [99, 103],
            [72, -40],
            [33, 73],
        ],
        [
            [57696, 47435],
            [61, -9],
            [19, -81],
            [-102, -43],
            [-43, 45],
            [65, 88],
        ],
        [
            [57363, 47604],
            [35, -122],
            [-67, -39],
            [14, -59],
            [58, -34],
            [-32, -42],
            [-75, -8],
            [-62, 47],
            [-53, 11],
            [15, 93],
            [59, 58],
            [33, 20],
            [16, 3],
            [6, 10],
            [53, 62],
        ],
        [
            [57467, 47836],
            [116, -58],
            [56, -92],
            [73, -40],
            [-22, -98],
            [67, -111],
            [-106, -2],
            [-38, -142],
            [-84, -14],
            [-35, 100],
            [-147, 10],
            [-3, 35],
            [64, 61],
            [-5, 69],
            [-61, 87],
            [7, 110],
            [30, 74],
            [38, -2],
            [50, 13],
        ],
        [
            [57841, 47894],
            [-3, -166],
            [46, -8],
            [-9, -117],
            [103, -81],
            [19, -95],
            [80, -95],
            [-51, -125],
            [-77, 54],
            [-12, 121],
            [-142, 24],
            [-57, 118],
            [20, 306],
            [27, 18],
            [10, 61],
            [46, -15],
        ],
        [
            [57600, 48377],
            [23, -82],
            [-37, -98],
            [10, -43],
            [-10, -18],
            [13, -19],
            [8, -42],
            [21, -33],
            [-8, -64],
            [44, -37],
            [15, 21],
            [-20, 69],
            [-41, 47],
            [-3, 27],
            [-24, 31],
            [13, 30],
            [-8, 21],
            [49, 91],
            [-49, 135],
            [67, 13],
            [98, -42],
            [63, -200],
            [47, -42],
            [7, -116],
            [-96, -64],
            [3, -94],
            [-43, -56],
            [-16, -127],
            [-98, 72],
            [-32, 128],
            [54, 35],
            [14, 21],
            [-100, -66],
            [-7, 77],
            [-57, 31],
            [0, 249],
            [14, 92],
            [86, 53],
        ],
        [
            [25868, 29180],
            [189, -71],
            [192, -89],
            [79, -3],
            [196, -11],
            [178, -9],
            [97, 9],
            [91, 27],
            [54, 29],
            [74, -4],
            [200, -63],
            [50, -36],
            [194, -68],
            [66, 17],
            [79, 52],
            [145, 51],
            [172, 74],
            [49, 27],
            [33, 39],
            [0, 52],
            [-4, 34],
            [-1, 91],
            [5, 56],
            [33, 25],
            [93, -17],
            [62, 31],
            [60, 46],
            [125, 63],
            [166, 20],
            [188, -45],
            [82, -9],
            [100, 44],
            [54, 36],
            [32, -2],
            [40, -8],
            [67, -65],
            [122, -84],
            [85, -30],
            [95, 11],
            [107, 61],
            [59, 53],
            [29, 34],
            [6, 72],
            [-11, 93],
            [7, 37],
            [18, 27],
            [29, 11],
            [37, -12],
            [11, -19],
            [4, -59],
            [8, -64],
            [19, -25],
            [29, -6],
            [50, 28],
            [19, 39],
            [-4, 64],
            [1, 94],
            [21, 69],
            [50, 66],
            [322, -38],
            [103, 48],
            [131, -21],
            [100, 1],
            [80, 30],
            [36, 117],
            [45, 85],
            [-7, 136],
            [3, 96],
            [12, 105],
            [-19, 146],
            [-13, 136],
            [73, 72],
            [95, 78],
            [94, 41],
            [103, 28],
            [46, -1],
            [49, -3],
            [138, 65],
            [86, 75],
            [216, 35],
            [250, 72],
            [83, 57],
            [173, 38],
            [73, 36],
            [66, 5],
            [117, -43],
            [99, -7],
            [39, -27],
            [43, -62],
            [32, -55],
            [28, -29],
            [48, -12],
            [88, 50],
            [93, 100],
            [85, 74],
            [62, 141],
            [51, 117],
            [47, 42],
            [45, 19],
            [26, 5],
            [-4, 19],
            [-38, 159],
            [-23, 43],
            [-34, 33],
            [-19, -6],
            [-16, -10],
            [-30, -23],
            [-37, 5],
            [-19, 27],
            [-8, 25],
            [23, 53],
            [40, 60],
            [11, 38],
            [169, 14],
            [93, 77],
            [87, 81],
            [49, 81],
            [48, -145],
            [119, -32],
            [110, -75],
            [44, -138],
            [70, -137],
            [161, -32],
            [-74, -68],
            [3, -84],
            [87, 38],
            [100, 7],
            [59, -105],
            [262, -79],
            [117, -20],
            [231, 6],
            [-187, 105],
            [-103, 114],
            [43, 120],
            [-90, 202],
            [-180, -55],
            [24, 77],
            [-110, 7],
            [-144, 0],
            [-87, 100],
            [57, 43],
            [56, 58],
            [-65, 146],
            [75, 51],
            [48, -91],
            [68, -71],
            [87, 64],
            [96, 62],
            [-27, 96],
            [114, 64],
            [93, 52],
            [109, -59],
            [83, -75],
            [16, -106],
            [138, 2],
            [155, -31],
            [112, -35],
            [100, 6],
            [12, 57],
            [65, 58],
            [-40, 58],
            [1, 80],
            [73, 60],
            [80, 47],
            [1, 78],
            [48, 45],
            [86, -36],
            [90, -27],
            [111, 22],
            [68, 74],
            [106, -4],
            [106, 19],
            [91, 37],
            [40, 130],
            [9, 76],
            [98, 38],
            [112, -4],
            [85, 57],
            [59, 97],
            [58, 64],
            [83, -81],
            [136, 27],
            [81, 61],
            [75, 58],
            [39, 64],
            [73, 125],
            [-6, 111],
            [-8, 88],
            [68, 74],
            [-11, 71],
            [39, 100],
            [93, 84],
            [24, 117],
            [53, 96],
            [94, 55],
            [98, 66],
            [105, 64],
            [107, 54],
            [85, 51],
            [107, 47],
            [-124, 206],
        ],
        [
            [37772, 34937],
            [68, 43],
            [47, 39],
            [39, 16],
            [71, -7],
            [85, 23],
            [104, 83],
            [88, -44],
            [107, 93],
            [87, 89],
            [99, 57],
            [203, 0],
            [141, -100],
            [59, -20],
            [67, 8],
            [41, 40],
            [19, 62],
            [7, 29],
            [38, 7],
            [127, -72],
            [23, 149],
            [-1, 109],
            [4, 30],
            [46, 47],
            [12, 36],
            [-58, 68],
            [3, 39],
            [22, 21],
            [6, 137],
            [45, 74],
            [79, 48],
            [26, 24],
            [-18, 35],
            [-44, -3],
            [-113, -1],
            [-17, 14],
            [8, 23],
            [69, 97],
            [-92, 123],
            [11, 91],
            [52, 133],
            [145, 73],
            [49, 60],
            [64, 50],
            [77, 6],
            [86, -25],
            [59, -56],
            [36, -56],
            [22, -134],
            [8, -59],
            [39, -87],
            [27, -66],
            [32, -92],
            [40, -141],
            [59, -131],
            [44, -89],
            [46, 36],
            [28, 103],
            [55, 26],
            [122, 53],
            [140, 69],
            [60, 65],
            [34, 51],
            [19, 98],
            [0, 140],
            [190, -86],
            [38, -30],
            [16, -41],
            [5, -69],
            [43, -4],
            [36, -13],
            [21, -16],
            [49, 1],
            [148, 59],
            [134, 11],
            [40, 32],
            [-9, 39],
            [-81, 119],
            [-2, 26],
            [7, 17],
            [100, 98],
            [-11, 115],
            [-31, 32],
            [-23, 23],
            [6, 34],
            [-2, 29],
            [-16, 19],
            [-32, -1],
            [-32, -19],
            [-30, -15],
            [-21, 14],
            [4, 32],
            [51, 65],
            [47, 106],
            [87, 85],
            [77, 86],
            [72, 103],
            [96, 87],
            [136, -9],
            [32, -31],
            [15, -39],
            [27, -11],
            [39, 22],
            [9, 20],
            [11, 35],
            [31, 25],
            [85, 30],
            [90, 63],
            [85, 47],
            [61, 20],
            [29, 17],
            [20, 35],
            [26, 30],
            [42, 13],
            [12, 30],
            [-40, 20],
            [-41, 43],
            [-46, 86],
            [-9, 81],
            [-7, 35],
            [-21, 13],
            [-25, -16],
            [-24, -51],
            [-11, -45],
            [-28, -15],
            [-15, 32],
            [-5, 69],
            [-16, 43],
            [-1, 40],
            [24, 40],
            [98, 24],
            [119, 8],
            [70, -58],
            [34, -44],
            [42, -24],
            [12, 59],
            [-8, 55],
            [14, 25],
            [38, 27],
            [-5, 44],
            [29, 107],
            [104, -105],
            [13, -40],
            [10, -15],
            [36, 18],
            [29, 38],
            [13, 39],
            [-16, 53],
            [-3, 29],
            [16, 25],
            [27, -8],
            [23, -19],
            [19, -25],
            [33, 18],
            [24, 111],
            [47, 77],
            [11, 33],
            [28, 7],
            [34, -39],
            [20, -92],
            [43, -130],
            [51, -88],
            [78, -133],
            [46, -92],
            [56, -80],
            [46, -36],
            [33, -3],
            [11, 27],
            [-33, 61],
            [-36, 133],
            [90, 49],
            [62, -29],
            [23, -64],
            [7, -84],
            [6, -39],
            [9, -37],
            [38, -27],
            [30, -88],
            [53, -125],
            [52, -75],
            [164, 3],
            [115, -10],
            [149, -44],
            [67, -40],
            [78, -78],
            [40, -29],
            [129, 43],
            [103, 54],
            [121, -26],
            [62, -13],
            [87, 28],
            [96, 61],
            [30, 105],
            [-41, 76],
            [7, 33],
            [19, 23],
            [36, -10],
            [36, -46],
            [31, -15],
            [13, 45],
            [1, 25],
            [45, 35],
            [120, 37],
            [-13, 126],
            [15, 63],
            [4, 41],
            [-25, 38],
            [1, 24],
            [19, 12],
            [25, -9],
            [29, -19],
            [34, 4],
            [85, 59],
            [127, 58],
            [48, 37],
            [70, -13],
            [25, -45],
            [76, -4],
            [109, 92],
            [-99, 71],
            [-52, 7],
            [-31, 56],
            [38, 57],
            [88, 1],
            [154, -65],
            [121, -3],
            [137, -75],
            [-12, -28],
            [-92, -132],
            [-102, -120],
            [-86, -131],
            [-60, -48],
            [-83, -75],
            [-49, -71],
            [-54, -85],
            [-90, -160],
            [-68, -90],
            [-126, -83],
            [-92, -108],
            [-76, -112],
            [-44, -42],
            [-111, -129],
            [-75, -116],
            [-43, -48],
            [-68, -34],
            [-25, -23],
            [-120, -136],
            [-92, -87],
            [-114, -77],
            [-106, -142],
            [-33, -70],
            [-10, -67],
            [-21, -38],
            [-55, -51],
            [-97, -59],
            [-271, -137],
            [-385, -182],
            [-307, -179],
            [-246, -159],
            [-146, -100],
            [-62, -57],
            [-81, -94],
            [-191, -166],
            [-102, -117],
            [-24, -55],
            [-30, -107],
            [-41, -95],
            [-48, -73],
            [-105, -128],
            [-153, -161],
            [-86, -50],
            [-10, 5],
            [-37, 6],
            [-14, 10],
            [-12, 6],
            [-11, 11],
            [-38, 5],
            [-8, -8],
            [-10, -14],
            [4, -18],
            [13, -14],
            [36, -24],
            [27, -24],
            [9, -15],
            [-1, -16],
            [-17, -29],
            [-109, -57],
            [-182, -92],
            [-164, -62],
            [-209, -138],
            [-193, -86],
            [-154, -89],
            [-275, -124],
            [-239, -139],
            [-184, -100],
            [-235, -189],
            [-222, -180],
            [-133, -132],
            [-94, -91],
            [-75, -80],
            [-35, -57],
            [-61, -64],
            [-35, -48],
            [-12, -59],
            [-16, -59],
            [-27, -52],
            [-47, -37],
            [-2, -16],
            [14, -36],
            [27, -40],
            [58, -46],
            [93, -41],
            [59, -1],
            [31, 16],
            [31, 41],
            [12, 86],
            [5, 70],
            [-23, 56],
            [-38, 40],
            [-6, 29],
            [20, 7],
            [26, -9],
            [24, -23],
            [33, -74],
            [22, -69],
            [-5, -135],
            [-29, -111],
            [-20, -132],
            [-35, -116],
            [-55, -41],
            [-16, -6],
            [-10, -2],
            [-7, -7],
            [-1, -15],
            [9, -6],
            [28, -9],
            [18, -8],
            [18, -14],
            [8, -13],
            [-7, -19],
            [-61, -82],
            [-3, -59],
            [15, -74],
            [19, -49],
            [0, -57],
            [-11, -40],
            [-7, 0],
            [-14, 13],
            [-3, 7],
            [0, 36],
            [-8, 47],
            [-4, 20],
            [-4, 6],
            [-15, 2],
            [-17, -9],
            [-6, -23],
            [-7, 1],
            [-19, 15],
            [-11, 4],
            [-14, -6],
            [-4, -8],
            [5, -13],
            [13, -15],
            [9, -14],
            [5, -13],
            [-5, -15],
            [-17, -15],
            [-20, -16],
            [-37, -14],
            [-11, -11],
            [-6, -12],
            [7, -10],
            [15, -8],
            [18, 0],
            [13, 3],
            [33, 5],
            [15, 0],
            [12, -6],
            [0, -20],
            [-24, -22],
            [-87, -9],
            [-143, -25],
            [-137, -71],
            [-296, -150],
            [-91, -42],
            [-91, -23],
            [-38, -17],
            [-90, -34],
            [-183, -79],
            [-259, -111],
            [-119, -58],
            [-79, -12],
            [-48, 4],
            [-53, 34],
            [-100, 3],
            [-138, 20],
            [-75, 25],
            [-233, -8],
            [-172, -7],
            [-21, 5],
            [-7, 19],
            [-1, 17],
            [9, 21],
            [32, 23],
            [-3, 15],
            [-20, 4],
            [-34, -21],
            [-64, -26],
            [-82, -3],
            [-37, -11],
            [-50, -41],
            [-41, -20],
            [-50, -34],
            [-81, -41],
            [-9, -13],
            [3, -8],
            [16, -10],
            [24, -20],
            [10, -19],
            [2, -9],
            [2, -42],
            [0, -24],
            [-75, -95],
            [-84, -145],
            [-30, -123],
            [-25, -135],
            [-21, -61],
            [-38, -40],
            [-43, -96],
            [-14, -63],
            [-6, -113],
            [-18, -38],
            [-38, -21],
            [-150, -102],
            [-162, -138],
            [-75, -117],
            [15, -74],
            [25, -74],
            [-18, -51],
            [-5, -5],
            [-14, -24],
            [-124, -84],
            [-65, -7],
            [-269, -45],
            [-68, -6],
            [-11, 74],
            [30, 154],
            [-2, 74],
            [-14, 38],
            [-53, 76],
            [-95, 67],
            [-85, 35],
            [-66, 23],
            [-17, 4],
            [-16, -8],
            [-11, -20],
            [-26, -35],
            [-76, -6],
            [-29, -8],
            [-132, -19],
            [-52, -20],
            [2, 11],
            [25, 62],
            [-3, 9],
            [-15, -3],
            [-23, -12],
            [-33, -26],
            [-47, -28],
            [-33, 0],
            [-50, -7],
            [-9, -7],
            [0, -6],
            [10, -16],
            [14, -13],
            [-3, -20],
            [-48, -30],
            [-56, -27],
            [-134, -66],
            [-186, -112],
            [-127, -131],
            [-94, -145],
            [-117, -279],
            [-18, -58],
            [-21, -63],
            [-8, -17],
            [-31, -36],
            [-35, -14],
            [-31, -22],
            [-13, -32],
            [92, 11],
            [9, -14],
            [5, -53],
            [-187, -233],
            [-139, -178],
            [-52, -139],
            [0, -62],
            [4, -72],
            [6, -61],
            [-9, -32],
            [-10, -8],
            [-16, 2],
            [-38, 21],
            [-27, 3],
            [-11, -1],
            [-9, -11],
            [-2, -9],
            [10, -19],
            [23, -22],
            [18, -18],
            [8, -11],
            [3, -11],
            [-18, -71],
            [-43, -139],
            [-3, -93],
            [18, -98],
            [2, -222],
            [17, -64],
            [19, -47],
            [27, -97],
            [22, -87],
            [11, -129],
            [16, -130],
            [15, -61],
            [21, -33],
            [65, -63],
            [40, -70],
            [29, -88],
            [6, -66],
            [50, -111],
            [18, -52],
            [3, -106],
            [-16, -105],
            [-4, -208],
            [10, -165],
            [-10, -96],
            [-37, -52],
            [-28, -50],
            [-20, -82],
            [-7, -66],
            [-24, -36],
            [-26, -18],
            [-57, 13],
            [-44, -2],
            [129, -95],
            [1, -43],
            [-13, -86],
            [5, -110],
            [17, -104],
            [36, -131],
            [79, -192],
            [65, -122],
            [34, -93],
            [57, -158],
            [57, -70],
            [12, -56],
            [-6, -104],
            [-16, -84],
            [-20, -113],
            [3, -162],
            [12, -89],
            [36, -101],
            [50, -116],
            [65, -148],
            [31, -71],
            [4, -35],
            [-21, -13],
            [-79, -2],
            [-220, 91],
            [-37, -17],
            [-38, -30],
            [-24, 8],
            [-2, 35],
            [-9, 44],
            [-19, 37],
            [-41, -4],
            [-48, -23],
            [-26, 3],
            [34, -124],
            [-76, 45],
            [-82, 175],
            [-127, 5],
            [-1, -31],
            [29, -26],
            [28, -71],
            [-70, -79],
            [-57, -66],
            [-26, -74],
            [-31, -6],
            [-24, 26],
            [-39, 11],
            [-23, -15],
            [35, -107],
            [-69, -86],
            [-66, -47],
            [-78, -51],
            [-84, -31],
            [-131, -28],
            [-93, 57],
            [-34, -6],
            [-52, -39],
            [-16, -57],
            [26, -60],
            [57, -49],
            [51, -87],
            [-97, -4],
            [-61, 32],
            [-65, 46],
            [-26, 40],
            [16, 73],
            [-17, 38],
            [-51, 29],
            [-61, -8],
            [-90, -68],
            [-61, -25],
            [-55, -11],
            [-30, 33],
            [-59, 66],
            [-111, 90],
            [-105, 14],
            [-73, 1],
            [-71, -16],
            [-65, -32],
            [-32, -33],
            [9, -35],
            [127, -32],
            [-46, -142],
            [76, -31],
            [17, -36],
            [-6, -20],
            [-27, -21],
            [-120, -26],
            [-48, -11],
            [-13, -20],
            [16, -77],
            [-114, -44],
            [-176, 8],
            [-3, -23],
            [68, -140],
            [8, -57],
            [-54, -103],
            [-127, -12],
            [-59, 12],
            [-76, 36],
            [-60, 18],
            [-60, -11],
            [-102, -22],
            [-97, 32],
            [-158, 10],
            [-107, 6],
            [-104, -37],
            [-108, 12],
            [-33, 15],
            [-16, 47],
            [-6, 49],
            [6, 57],
            [-32, 10],
            [-107, -31],
            [-157, 0],
            [-45, -37],
            [-50, -11],
            [-80, -5],
            [-36, -20],
            [-25, -37],
            [-98, -67],
            [-109, -39],
            [-29, -16],
            [-54, -38],
            [22, -40],
            [63, -69],
            [-39, -89],
            [-63, -86],
            [-17, -79],
            [-20, -127],
            [-60, -124],
            [-74, -58],
            [-15, -44],
            [22, -93],
            [-27, -48],
            [-25, 12],
            [-13, 36],
            [-7, 36],
            [-20, 62],
            [-19, 14],
            [-26, -11],
            [-22, -23],
            [28, -73],
            [-31, -84],
            [-28, -25],
            [5, -91],
            [0, -37],
            [-51, -25],
            [-83, -31],
            [-145, 19],
            [-107, 102],
            [-126, 136],
            [-28, -6],
            [-39, -39],
            [-131, -14],
            [74, 93],
            [31, 44],
            [-9, 88],
            [45, 130],
            [79, 134],
            [71, 102],
            [56, 22],
            [29, 33],
            [-3, 27],
            [10, 33],
            [32, 12],
            [43, -19],
            [98, -70],
            [98, -64],
            [48, -31],
            [21, 0],
            [11, 43],
            [-28, 38],
            [-63, 58],
            [-45, 43],
            [5, 22],
            [42, 19],
            [34, 6],
            [36, 129],
            [28, 110],
            [3, 24],
            [29, 17],
            [60, 24],
            [39, 22],
            [16, 44],
            [29, 19],
            [24, 39],
            [-26, 27],
            [-88, 83],
            [65, 14],
            [30, 0],
            [61, 34],
            [33, 23],
            [-8, 46],
            [-41, 43],
            [20, 63],
            [41, 80],
            [-4, 103],
            [-13, 35],
            [-32, -3],
            [-19, -30],
            [-44, -42],
            [-44, -4],
            [-53, 43],
            [-32, 67],
            [-95, 23],
            [-96, -46],
            [-37, 8],
            [-42, 15],
            [-15, 38],
            [18, 44],
            [-19, 10],
            [-61, 16],
            [-8, 29],
            [13, 78],
            [25, 123],
            [31, 243],
            [18, 195],
            [-145, 25],
            [-224, -2],
            [-184, -44],
            [24, 102],
            [-24, 41],
            [-63, 33],
            [-11, 36],
            [-46, 9],
            [-109, -13],
            [-96, 45],
            [-66, 67],
            [8, 33],
            [42, 28],
            [69, 2],
            [37, 50],
            [-29, 48],
            [3, 44],
            [53, 72],
            [21, 133],
            [-40, 98],
            [-81, 49],
            [-71, 36],
            [-34, -33],
            [-95, -30],
            [-121, -22],
            [-97, -101],
            [-44, 44],
            [-17, 42],
            [17, 56],
            [84, 81],
            [30, 104],
            [2, 67],
            [-27, 5],
            [-24, -16],
            [-42, -50],
            [-63, -48],
            [-34, 10],
            [-125, 22],
            [-120, 19],
            [-24, -6],
            [-4, -36],
            [32, -48],
            [38, -45],
            [-3, -55],
            [-33, -48],
            [-114, -71],
            [-124, -72],
            [-68, -54],
            [-2, -46],
            [13, -57],
            [-32, -8],
            [-33, 37],
            [-24, 54],
            [-32, 24],
            [-74, -22],
            [-28, -123],
            [-162, -28],
            [-37, -29],
            [-57, 26],
            [-14, 43],
            [-21, 17],
            [-13, -28],
            [-7, -41],
            [-22, -56],
            [-78, -10],
            [-111, -27],
            [4, 105],
            [18, 123],
            [14, 87],
            [-66, 20],
            [-26, 33],
            [-31, 62],
            [-142, 26],
            [-119, 36],
            [-28, 26],
            [-34, -12],
            [-54, -50],
            [-50, -18],
            [-17, 27],
            [-30, 72],
            [-21, 52],
            [-41, -6],
            [-76, -61],
            [-60, 138],
            [-35, -33],
            [-9, -55],
            [11, -49],
            [-32, -17],
            [-45, -18],
            [46, -61],
            [42, -97],
            [0, -126],
            [-104, 6],
            [-107, 15],
            [-28, -50],
            [-39, -15],
            [-110, 68],
            [-81, -92],
            [-19, 13],
            [-19, 61],
            [-24, 110],
            [45, 99],
            [88, 114],
            [59, 73],
            [-2, 69],
            [-84, 73],
            [-31, 104],
            [-123, 59],
            [-56, 70],
            [47, 58],
            [45, 69],
            [-23, 58],
            [-77, 66],
            [-87, 57],
            [-19, 22],
            [18, 35],
            [60, 34],
            [74, 49],
            [40, 12],
            [145, -53],
            [29, -136],
            [4, -158],
            [36, -47],
            [65, -23],
            [106, -16],
            [107, -78],
            [81, -17],
            [94, 0],
            [135, 25],
            [155, -1],
            [115, -88],
            [34, -66],
            [4, -115],
            [-11, -66],
            [49, -32],
            [66, 4],
            [62, 25],
            [34, 112],
            [15, 44],
            [-12, 49],
            [-46, 23],
            [-70, -8],
            [-27, 17],
            [11, 49],
            [4, 58],
            [-47, 6],
            [-81, 34],
            [63, 112],
            [66, 86],
            [-12, 43],
            [-24, 3],
            [-45, -29],
            [-27, 24],
            [19, 28],
            [59, 47],
            [39, 62],
            [119, 36],
            [140, -33],
            [77, 27],
            [7, 47],
            [-22, 125],
            [-78, 143],
            [-72, 69],
            [-41, 67],
            [-66, 42],
            [-51, 2],
            [-31, -40],
            [-9, -87],
            [97, -227],
            [-44, -50],
            [-65, -8],
            [-51, 32],
            [3, 38],
            [31, 69],
            [-79, 78],
            [-141, 31],
            [-8, 28],
            [0, 98],
            [-32, 7],
            [-85, -33],
            [-102, 4],
            [-102, 64],
            [-56, 13],
            [-29, -28],
            [-43, -98],
            [1, -103],
            [-75, -133],
            [-53, 23],
            [-84, 34],
            [-148, -7],
            [-103, 6],
            [-61, 26],
            [-8, 58],
            [37, 94],
            [-43, 17],
            [-126, 78],
            [-13, 74],
            [25, 44],
            [80, 67],
            [73, 73],
            [11, 53],
            [65, 39],
            [10, 93],
            [-123, 46],
            [-53, -8],
            [-57, -51],
            [-22, -53],
            [-13, 24],
            [-105, 133],
            [-95, 83],
            [-91, 81],
            [-24, 107],
            [15, 131],
            [72, 131],
            [-14, 154],
            [-12, 61],
            [55, 15],
            [81, -11],
            [52, 43],
            [10, 71],
            [29, 115],
            [28, 201],
            [-17, 93],
            [-131, -7],
            [-174, 41],
            [47, 89],
            [16, 75],
            [34, 85],
            [69, 41],
            [80, -31],
            [131, -122],
            [153, -14],
            [46, -21],
            [72, 8],
            [41, 54],
            [65, 42],
            [48, -36],
            [31, -153],
            [137, 73],
            [58, 124],
            [34, 96],
            [28, 91],
            [14, 158],
            [-21, 101],
            [-51, 92],
            [30, 40],
            [44, 46],
            [-16, 24],
            [-59, 51],
            [-131, 103],
            [-97, 93],
            [-34, 147],
            [-54, 92],
            [-79, 94],
            [-69, 68],
            [-2, 48],
            [36, 29],
            [106, -86],
            [25, 38],
            [5, 142],
            [-4, 139],
            [5, 137],
            [40, 60],
            [67, 29],
            [52, -13],
            [60, -45],
            [19, 38],
            [-46, 49],
            [-80, 34],
            [-94, 147],
            [52, 145],
            [1, 148],
            [71, 64],
            [128, 78],
            [-12, 81],
            [155, 69],
            [257, 35],
            [240, 10],
            [259, -7],
            [153, -16],
        ],
        [
            [25868, 29180],
            [5, 0],
            [-9, 133],
            [-13, 60],
            [-3, 65],
            [45, 137],
            [-37, 127],
            [-14, 64],
            [10, 81],
            [38, 87],
            [31, 140],
            [104, 54],
            [47, -2],
            [72, 7],
            [9, 38],
            [-31, 80],
            [0, 45],
            [-26, 41],
            [-36, -1],
            [-39, -38],
            [-42, -69],
            [-161, 154],
            [-248, 60],
            [-280, 28],
            [-76, 92],
            [-14, 58],
            [-1, 61],
            [35, 25],
            [143, 31],
            [141, 51],
            [79, 40],
            [32, 59],
            [-10, 31],
            [-50, 30],
            [-1, 38],
            [49, 9],
            [139, 87],
            [21, 120],
            [-25, 29],
            [-67, 9],
            [-47, -4],
            [3, 37],
            [27, 32],
            [55, 5],
            [52, 7],
            [-5, 68],
            [-36, 74],
            [-90, -64],
            [18, 115],
            [51, 70],
            [51, 47],
            [-2, 53],
            [-9, 126],
            [-6, 123],
            [-1, 61],
            [14, 169],
            [43, 152],
            [-44, 124],
            [-16, 64],
            [41, 38],
            [21, 38],
            [-50, 17],
            [-123, 6],
            [-105, 39],
            [-16, 225],
            [67, 42],
            [73, 63],
            [95, 131],
            [52, 103],
            [84, 144],
            [89, 109],
            [141, 74],
            [101, 92],
            [71, 5],
            [97, 1],
            [35, 19],
            [3, 31],
            [-14, 17],
            [-42, 22],
            [-94, 54],
            [-96, 86],
            [-149, 7],
            [-101, -20],
            [-52, 2],
            [-95, 28],
            [-57, 43],
            [-1, 72],
            [11, 134],
            [11, 51],
            [19, 77],
            [51, 41],
            [72, 20],
            [160, 31],
            [-150, 129],
            [-44, 48],
            [-4, 40],
            [35, 31],
            [52, 19],
            [49, -16],
            [20, 27],
            [4, 40],
            [77, 78],
            [65, 117],
            [30, 13],
            [50, 58],
            [55, 66],
            [47, 69],
            [-1, 43],
            [-31, 37],
            [-93, 49],
            [-92, -109],
            [-72, 9],
            [-45, 22],
            [-31, 30],
            [-27, 65],
            [9, 46],
            [105, 92],
            [65, 108],
            [19, 56],
            [-29, 97],
            [-25, 57],
            [-27, 61],
            [8, 119],
            [57, 79],
            [25, 29],
            [-13, 20],
            [-55, 31],
            [-51, 30],
            [3, 68],
            [-21, 21],
            [-98, 75],
            [33, 144],
            [-30, 41],
            [15, 29],
            [40, 25],
            [50, -9],
            [5, 18],
            [-11, 45],
            [29, 49],
            [66, 88],
            [-39, 102],
            [35, 27],
            [57, 19],
            [95, 8],
            [110, -47],
            [120, 27],
            [90, 52],
            [65, 60],
            [18, 73],
            [27, 86],
            [-122, 128],
            [63, 70],
            [23, 67],
            [47, 87],
            [48, 89],
            [108, 133],
            [-21, 115],
            [-2, 84],
            [32, 101],
            [-85, 19],
            [-8, 45],
            [-79, 10],
            [-104, 96],
            [-44, 100],
            [59, 36],
            [65, 12],
            [28, 69],
            [52, 19],
            [51, -3],
            [31, 110],
            [42, 103],
            [-48, 85],
            [-12, 50],
            [46, 63],
            [111, 48],
            [-112, 98],
            [11, 32],
            [34, 22],
            [69, 29],
            [82, 47],
            [130, -32],
            [115, -32],
            [-26, -73],
            [11, -34],
            [55, -1],
            [59, -16],
            [17, -74],
            [32, -36],
            [46, 8],
            [94, 23],
            [127, -25],
            [39, 46],
            [14, 117],
            [11, 164],
            [-11, 120],
            [5, 37],
            [27, 33],
            [62, 15],
            [68, 10],
            [30, 20],
            [34, 45],
            [55, 17],
            [35, 30],
            [5, 90],
            [-33, 130],
            [32, 97],
            [49, 108],
            [-46, 78],
            [-57, 107],
            [7, 112],
            [67, 48],
            [94, 24],
            [37, 47],
            [5, 62],
            [-38, 128],
            [-74, 132],
            [-39, 82],
            [-3, 110],
            [55, 23],
            [58, -22],
            [120, -103],
            [120, -116],
            [118, -230],
            [118, -11],
            [81, 14],
            [177, 16],
            [116, -27],
            [214, 101],
            [67, -4],
            [55, -24],
            [18, -71],
            [19, -84],
            [77, -148],
            [105, -112],
            [119, -67],
            [36, -56],
            [-2, -39],
            [-4, -40],
            [59, -2],
            [71, -13],
            [61, -73],
            [136, -7],
            [177, -48],
            [159, -43],
            [110, -131],
            [85, -17],
            [53, 18],
            [0, 31],
            [8, 106],
            [55, 108],
            [26, 119],
            [10, 52],
            [39, 14],
            [96, -135],
            [143, -8],
            [65, -13],
            [75, -56],
            [100, -72],
            [163, -27],
            [47, 75],
            [58, 0],
            [59, -33],
            [52, -36],
            [52, 10],
            [91, 19],
            [136, 79],
            [138, 25],
            [108, 40],
            [93, 66],
            [41, -48],
            [89, -130],
            [88, -72],
            [138, -49],
            [63, -93],
            [29, -80],
            [29, -177],
            [8, -145],
            [-60, -158],
            [-29, -91],
            [13, -92],
            [0, -86],
            [-60, -70],
            [-127, -83],
            [-21, -30],
            [-6, -82],
            [20, -85],
            [39, -53],
            [73, 4],
            [57, -1],
            [40, -32],
            [6, -34],
            [8, -63],
            [5, -148],
            [11, -157],
            [-16, -104],
            [-57, -80],
            [3, -35],
            [42, -70],
            [79, -36],
            [68, -45],
            [143, -98],
            [159, -66],
            [101, -7],
            [218, 57],
            [127, 42],
            [33, -36],
            [51, -64],
            [40, -104],
            [28, -74],
            [8, -35],
            [15, -22],
            [51, 6],
            [46, 3],
            [45, -41],
            [20, 30],
            [47, -10],
            [44, 10],
            [4, 7],
            [89, 44],
            [44, -9],
            [58, 13],
            [33, -28],
            [36, -6],
            [8, -8],
            [4, -16],
            [8, -11],
            [10, -8],
            [20, -20],
            [2, -12],
            [7, -11],
            [24, -8],
            [48, -31],
            [12, -8],
            [16, -24],
            [38, -12],
            [38, -24],
            [16, -12],
            [27, -33],
            [9, -22],
            [21, -8],
            [16, -14],
            [15, 2],
            [6, -11],
            [4, -23],
            [-7, -12],
            [3, -13],
            [6, -14],
            [31, -43],
            [8, -20],
            [32, -18],
            [10, -10],
            [11, -4],
            [10, -8],
            [2, -6],
            [18, -8],
            [5, -10],
            [2, -15],
            [25, -13],
            [8, 1],
            [11, -14],
            [-11, -4],
            [-6, -16],
            [-5, -28],
            [61, -40],
            [15, 12],
            [12, 2],
            [11, -5],
            [6, -14],
            [-5, -11],
            [-8, -14],
            [-23, -23],
            [1, -13],
            [7, -11],
            [11, -10],
            [5, -10],
            [-4, -13],
            [-1, -19],
            [11, -32],
            [23, -33],
            [22, -49],
            [4, -15],
            [3, -34],
            [-2, -8],
            [19, -14],
            [5, -18],
            [11, -19],
            [7, -6],
            [10, -2],
            [9, -11],
            [20, -38],
            [10, -15],
            [12, -1],
            [10, -4],
            [8, -12],
            [1, -12],
            [-3, -10],
            [-9, -10],
            [-40, -20],
            [-36, -20],
            [-65, -30],
            [-3, -40],
            [1, -32],
            [6, -65],
            [28, -20],
            [49, -6],
            [69, 4],
            [30, 6],
            [36, 35],
            [-1, 15],
            [20, 11],
            [3, 15],
            [-3, 59],
            [2, 18],
            [-3, 19],
            [4, 17],
            [15, 7],
            [15, 1],
            [13, -2],
            [15, -15],
            [54, -6],
            [8, -13],
            [16, -41],
            [0, -12],
            [-6, -7],
            [-12, -6],
            [-12, -1],
            [-22, 5],
            [-2, -8],
            [2, -13],
            [10, -27],
            [0, -15],
            [-2, -29],
            [12, -49],
            [-3, -14],
            [2, -11],
            [8, -12],
            [8, -7],
            [1, -11],
            [54, 0],
            [33, 5],
            [21, 1],
            [31, 11],
            [56, 32],
            [11, 8],
            [3, 8],
            [20, 6],
            [16, 21],
            [12, 8],
            [32, 4],
            [23, 0],
            [-1, -29],
            [4, -18],
            [-7, -25],
            [-14, -16],
            [-50, -43],
            [-5, -18],
            [6, -39],
            [0, -16],
            [-4, -20],
            [-7, -20],
            [-5, -10],
            [23, -26],
            [32, -65],
            [6, -18],
            [-10, -34],
            [4, -21],
            [13, -37],
            [4, -25],
            [8, -29],
            [3, -47],
            [12, -79],
            [4, -12],
            [2, -17],
            [9, -14],
            [3, -35],
            [21, -10],
            [6, -15],
            [22, -144],
            [7, -76],
            [23, -63],
            [54, -78],
            [15, -11],
            [21, -6],
            [30, -3],
            [28, 4],
            [21, 6],
            [16, 25],
            [22, 24],
            [5, 23],
            [24, 18],
            [32, 18],
            [34, 14],
            [106, 71],
            [47, -10],
            [21, 1],
            [23, -7],
            [60, -41],
            [19, -11],
            [21, -8],
            [72, -42],
            [27, -4],
            [96, -2],
            [48, 8],
            [30, 0],
            [26, -4],
            [26, 1],
            [36, -2],
            [27, -7],
            [20, -9],
            [27, -4],
            [2, 8],
            [-5, 23],
            [-1, 18],
            [3, 7],
            [192, 5],
            [283, 20],
            [194, 62],
            [99, 67],
            [96, 64],
            [51, 29],
            [137, 39],
        ],
        [
            [16434, 15376],
            [-18, -3],
            [-36, -1],
            [-15, 3],
            [-30, 9],
            [-28, 12],
            [-10, 6],
            [-27, 21],
            [-19, 10],
            [-25, 19],
            [-42, 30],
            [-22, 20],
            [-22, 24],
            [-31, 43],
            [-9, 17],
            [-9, 10],
            [-25, 45],
            [-11, 31],
            [-5, 20],
            [-2, 25],
            [3, 20],
            [10, 25],
            [15, 20],
            [15, 11],
            [18, 8],
            [19, 5],
            [24, 0],
            [15, -3],
            [18, -7],
            [24, -12],
            [28, -15],
            [26, -10],
            [28, -15],
            [33, -22],
            [20, -17],
            [10, -7],
            [19, -18],
            [29, -32],
            [31, -38],
            [19, -28],
            [27, -50],
            [9, -25],
            [5, -29],
            [1, -26],
            [-5, -20],
            [-7, -14],
            [-16, -23],
            [-14, -11],
            [-18, -8],
        ],
        [
            [10156, 17212],
            [2, -25],
            [-4, -12],
            [-11, -4],
            [-17, 7],
            [-7, 5],
            [-25, 10],
            [-22, 7],
            [-21, 2],
            [-32, -2],
            [-15, 3],
            [-18, 6],
            [-9, 6],
            [-11, 12],
            [-7, 14],
            [-6, 24],
            [-30, 54],
            [-2, 7],
            [-5, 65],
            [-7, 25],
            [-3, 34],
            [-9, 31],
            [-5, 10],
            [-35, 26],
            [-13, 20],
            [-3, 14],
            [0, 14],
            [8, 13],
            [7, 5],
            [5, 9],
            [27, 32],
            [21, 20],
            [13, 22],
            [14, 13],
            [6, 8],
            [15, 14],
            [21, 3],
            [14, -7],
            [13, -27],
            [12, -18],
            [10, -27],
            [6, -20],
            [3, -21],
            [5, -28],
            [8, -20],
            [6, -20],
            [22, -37],
            [16, -36],
            [23, -63],
            [3, -31],
            [15, -70],
            [7, -29],
            [3, -16],
            [12, -22],
        ],
        [
            [11324, 11906],
            [-6, -3],
            [-30, 0],
            [-21, -12],
            [-10, 4],
            [-7, 15],
            [-9, 12],
            [0, 15],
            [4, 13],
            [11, 49],
            [3, 15],
            [9, 22],
            [29, 34],
            [14, 13],
            [26, 15],
            [7, 15],
            [3, 14],
            [9, 17],
            [12, 11],
            [9, 5],
            [34, 27],
            [13, 7],
            [15, 16],
            [21, 16],
            [10, 20],
            [7, 10],
            [9, 15],
            [7, 7],
            [11, -1],
            [13, -23],
            [4, -33],
            [6, -26],
            [9, -15],
            [4, -18],
            [-9, -23],
            [-8, -25],
            [-6, -12],
            [-15, -21],
            [-7, -12],
            [-10, -32],
            [-9, -15],
            [-21, -19],
            [-17, -31],
            [-13, -12],
            [-21, -8],
            [-23, -10],
            [-24, -15],
            [-33, -21],
        ],
        [
            [11085, 16211],
            [-15, -1],
            [-34, 7],
            [-19, 9],
            [-16, 14],
            [-4, 6],
            [-4, 21],
            [-9, 14],
            [-11, 24],
            [-1, 16],
            [1, 14],
            [-3, 27],
            [0, 12],
            [-1, 15],
            [5, 14],
            [12, 18],
            [9, 9],
            [5, 10],
            [9, 28],
            [15, 23],
            [11, 10],
            [8, 19],
            [10, 15],
            [16, 12],
            [7, 0],
            [42, -20],
            [5, -17],
            [6, -8],
            [2, -9],
            [-5, -49],
            [-4, -33],
            [-6, -24],
            [3, -29],
            [3, -24],
            [-2, -21],
            [2, -20],
            [13, -52],
            [1, -7],
            [-6, -10],
            [-7, -5],
            [-15, -5],
            [-23, -3],
        ],
        [
            [10853, 15342],
            [-9, -32],
            [-5, -23],
            [-8, -29],
            [-4, -32],
            [-4, -18],
            [-5, -14],
            [-9, -18],
            [-11, -18],
            [-18, -12],
            [-27, -10],
            [-14, -1],
            [-9, 3],
            [-23, 17],
            [-15, 24],
            [-11, 26],
            [-19, 34],
            [-4, 10],
            [-9, 13],
            [-12, 13],
            [-23, 35],
            [-9, 9],
            [-5, 10],
            [-9, 10],
            [1, 11],
            [7, 0],
            [16, -4],
            [14, -7],
            [29, -11],
            [8, -1],
            [26, 3],
            [82, 13],
            [16, 6],
            [14, 9],
            [14, 11],
            [21, 22],
            [15, 30],
            [5, 6],
            [12, 0],
            [3, -5],
            [-4, -34],
            [-11, -27],
            [-1, -9],
            [-5, -10],
        ],
        [
            [10312, 18521],
            [-12, 0],
            [-15, 8],
            [-6, 21],
            [-1, 16],
            [7, 31],
            [-4, 26],
            [-12, 26],
            [-6, 18],
            [-4, 33],
            [4, 22],
            [-5, 65],
            [-4, 17],
            [-1, 30],
            [4, 9],
            [14, 12],
            [11, 5],
            [20, 3],
            [45, -36],
            [19, -8],
            [10, -12],
            [7, -16],
            [-3, -44],
            [2, -22],
            [-1, -28],
            [-7, -22],
            [-3, -35],
            [-7, -29],
            [-19, -45],
            [-17, -34],
            [-16, -11],
        ],
        [
            [13544, 7012],
            [5, -1],
            [20, 3],
            [7, -6],
            [18, 5],
            [13, -6],
            [-2, -14],
            [-14, -8],
            [-7, -14],
            [-12, -18],
            [-12, -30],
            [0, -34],
            [4, -11],
            [-21, -21],
            [-6, -7],
            [-16, -15],
            [-13, -18],
            [-12, -5],
            [-15, 0],
            [-24, 4],
            [-23, 6],
            [-19, 6],
            [-16, 13],
            [-8, 15],
            [-3, 17],
            [2, 9],
            [-2, 39],
            [10, 14],
            [18, 12],
            [18, 8],
            [17, 14],
            [6, 2],
            [20, 17],
            [23, 13],
            [6, 6],
            [10, 4],
            [11, -2],
            [17, 3],
        ],
        [
            [15167, 12028],
            [-11, 1],
            [-5, 4],
            [-15, 17],
            [-10, 9],
            [-27, 32],
            [2, 9],
            [0, 13],
            [-4, 20],
            [-3, 27],
            [1, 13],
            [18, 28],
            [23, 22],
            [6, 15],
            [18, 21],
            [1, 10],
            [6, 5],
            [7, 11],
            [13, 16],
            [2, 6],
            [13, 16],
            [8, 0],
            [7, -28],
            [5, -7],
            [0, -14],
            [-8, -32],
            [-11, -24],
            [-5, -6],
            [-7, -17],
            [-2, -14],
            [-3, -30],
            [-3, -8],
            [-7, -41],
            [0, -25],
            [1, -27],
            [-2, -13],
            [-8, -9],
        ],
        [
            [12388, 13554],
            [8, -7],
            [5, -9],
            [3, -15],
            [-5, -18],
            [-13, -8],
            [-10, -2],
            [-14, -6],
            [-36, -29],
            [-14, -18],
            [-9, 1],
            [-3, 5],
            [-3, 18],
            [2, 14],
            [6, 11],
            [13, 16],
            [18, 27],
            [11, 21],
            [11, 10],
            [6, 2],
            [10, -3],
            [14, -10],
        ],
        [
            [15234, 14249],
            [20, -2],
            [16, 2],
            [11, -2],
            [35, 1],
            [10, -6],
            [2, -9],
            [-13, -8],
            [-26, -8],
            [-13, -8],
            [-21, -7],
            [-16, -2],
            [-25, 5],
            [-8, 9],
            [-4, 11],
            [0, 16],
            [7, 7],
            [13, 2],
            [12, -1],
        ],
        [
            [11060, 14226],
            [-19, 6],
            [15, 9],
            [9, 16],
            [17, 22],
            [11, 21],
            [6, 16],
            [13, 26],
            [14, 52],
            [4, 11],
            [21, 22],
            [11, -1],
            [3, -9],
            [-7, -21],
            [-14, -26],
            [-18, -25],
            [-19, -33],
            [-10, -31],
            [-16, -24],
            [-1, -9],
            [-14, -18],
            [-6, -4],
        ],
        [
            [12789, 15535],
            [-1, -17],
            [-9, -22],
            [-13, -37],
            [-14, -54],
            [-3, -7],
            [-14, -19],
            [-4, -9],
            [-7, -22],
            [-7, -19],
            [-6, 6],
            [8, 24],
            [4, 9],
            [22, 69],
            [11, 44],
            [16, 38],
            [7, 11],
            [10, 5],
        ],
        [
            [13396, 16140],
            [-16, 11],
            [-10, 21],
            [-8, 41],
            [1, 9],
            [9, 7],
            [13, -3],
            [6, -8],
            [9, -17],
            [4, -37],
            [2, -13],
            [-10, -11],
        ],
        [
            [12588, 16804],
            [-2, -10],
            [-7, -11],
            [-19, -25],
            [-7, -1],
            [-17, 14],
            [-1, 12],
            [3, 6],
            [9, 2],
            [-4, 10],
            [14, 18],
            [15, 7],
            [12, 2],
            [3, -11],
            [1, -13],
        ],
        [
            [12596, 15106],
            [-5, 1],
            [-7, 10],
            [-2, 15],
            [6, 17],
            [9, 11],
            [19, 15],
            [9, 2],
            [8, -2],
            [1, -12],
            [-7, -19],
            [-14, -20],
            [-16, -14],
            [-1, -4],
        ],
        [
            [11398, 14622],
            [-2, -7],
            [8, -22],
            [-6, -4],
            [-14, 2],
            [-3, 5],
            [7, 27],
            [10, -1],
        ],
        [
            [11498, 14634],
            [-19, -16],
            [-11, -6],
            [-3, 4],
            [4, 15],
            [26, 5],
            [3, -2],
        ],
        [
            [12074, 14141],
            [-7, 4],
            [9, 6],
            [7, -5],
            [-9, -5],
        ],
        [
            [18209, 77295],
            [-50, 13],
            [-53, 59],
            [-30, 89],
            [-62, 116],
            [-15, 55],
            [6, 90],
            [76, 81],
            [-24, 70],
            [-35, 92],
            [76, 215],
            [22, 74],
            [0, 77],
            [-75, -22],
            [-69, -14],
            [16, -107],
            [-61, -63],
            [-126, -38],
            [-160, 5],
            [-84, 48],
            [-148, 32],
            [-116, -16],
            [-192, 40],
            [-99, 109],
            [-104, 60],
            [-279, 120],
            [-160, 37],
            [-175, 80],
            [-50, 2],
            [-30, 11],
            [-37, 70],
            [-54, 36],
            [-124, 72],
            [-250, 33],
            [-33, -67],
            [-42, -27],
            [-49, 39],
            [-57, 83],
            [-106, 39],
            [-22, 54],
            [-67, 61],
            [-149, 58],
            [-108, 29],
            [-37, 40],
            [-33, 74],
            [-22, 133],
            [10, 168],
            [-21, 187],
            [-53, 166],
            [6, 118],
            [66, 183],
            [19, 134],
            [-22, 190],
            [-85, 191],
            [-27, 108],
            [27, 116],
            [17, 98],
            [-54, 99],
            [-7, 111],
            [14, 105],
            [35, 98],
            [-58, 109],
            [-51, 128],
            [-50, 155],
            [-9, 52],
            [0, 178],
            [-36, 235],
            [-4, 184],
            [-14, 115],
            [20, 66],
            [-227, 205],
            [43, 92],
            [54, 275],
            [29, 103],
            [68, 47],
            [152, 22],
            [127, 32],
            [126, -29],
            [103, -33],
            [36, 30],
            [19, 82],
            [48, 98],
            [-53, 127],
            [144, 159],
            [55, 89],
            [54, 45],
            [92, 9],
            [112, 18],
            [101, 43],
            [162, 24],
            [105, 30],
            [7, 47],
            [23, 61],
            [70, 24],
            [77, 55],
            [26, 116],
            [40, 127],
            [21, 73],
            [62, 37],
            [36, 53],
            [-37, 49],
            [-28, 54],
            [45, 44],
            [68, 102],
            [-66, 92],
            [-82, 15],
            [-85, 33],
            [-70, 53],
            [-121, 22],
            [-134, 71],
            [-144, 22],
            [-90, 49],
            [-80, 31],
            [-45, -15],
            [-78, -54],
            [-73, -8],
            [-38, 33],
            [1, 43],
            [4, 72],
            [-17, 125],
            [-39, 109],
            [37, 147],
            [37, 32],
            [83, 78],
            [98, 98],
            [13, 53],
            [-69, 50],
            [-155, 40],
            [-142, -115],
            [-396, 18],
            [-107, 3],
            [-169, 191],
            [-75, 73],
            [-91, 49],
            [-166, 61],
            [-126, 66],
            [-118, 91],
            [-87, 53],
            [-110, 85],
            [-22, 56],
            [-19, 96],
            [57, 155],
            [-87, 68],
            [-105, 56],
            [-62, -3],
            [-66, -36],
            [-164, 0],
            [-162, -51],
            [-58, 67],
            [-138, 48],
            [-74, -17],
            [-84, -80],
            [-60, -21],
            [-168, -17],
            [-141, 6],
            [-114, 31],
            [-159, 112],
            [-86, 73],
            [-15, 51],
            [38, 49],
            [76, 88],
            [41, 82],
            [-15, 82],
            [-121, 26],
            [13, 114],
            [42, 81],
            [9, 71],
            [-25, 49],
            [-17, 50],
            [36, 103],
            [166, 149],
            [54, 9],
            [83, -30],
            [71, 14],
            [83, 73],
            [93, 42],
            [75, 39],
            [103, 134],
            [159, 49],
            [14, 26],
            [0, 115],
            [8, 76],
            [56, 41],
            [81, 8],
            [125, 26],
            [27, 143],
            [170, 63],
            [111, 162],
            [-11, 91],
            [-24, 133],
            [16, 75],
            [123, 6],
            [164, 64],
            [127, -29],
            [309, 48],
            [115, 54],
            [181, 12],
            [173, -57],
            [284, -9],
            [28, -29],
            [63, -28],
            [104, -12],
            [202, 56],
            [119, -30],
            [162, -17],
            [102, 16],
            [-25, 63],
            [-108, 82],
            [-88, 97],
            [-160, 84],
            [-172, 143],
            [-20, 88],
            [162, -17],
            [208, -40],
            [199, -33],
            [65, -3],
            [78, -31],
            [64, -46],
            [129, -101],
            [94, -54],
            [117, 5],
            [131, 141],
            [149, 114],
            [52, 27],
            [232, 32],
            [158, 95],
            [187, 111],
            [82, 7],
            [200, -24],
            [59, -25],
            [72, -59],
            [58, 53],
            [12, 115],
            [-46, 109],
            [98, 47],
            [219, 51],
            [64, 1],
            [143, -162],
            [150, 33],
            [57, -42],
            [210, -262],
            [167, 87],
            [124, 20],
            [70, -14],
            [74, -33],
            [34, -12],
            [248, 197],
            [85, 138],
            [113, 55],
            [176, 13],
            [74, -24],
            [65, -231],
            [112, -36],
            [7, -120],
            [122, -120],
            [30, -108],
            [139, -100],
            [42, -24],
            [144, -55],
            [115, -54],
            [55, -48],
            [33, -43],
            [100, -136],
            [131, -127],
            [85, -110],
            [81, -79],
            [173, -60],
            [172, -99],
            [142, -49],
            [251, -43],
            [156, -31],
            [37, -14],
            [65, -55],
            [33, -40],
            [101, -208],
            [103, -62],
            [74, -7],
            [97, -4],
            [166, 21],
            [302, -223],
            [15, -95],
            [109, -134],
            [45, -49],
            [149, -30],
            [159, -49],
            [88, 38],
            [42, -1],
            [36, -4],
            [53, -34],
            [49, -111],
            [72, -77],
            [86, -62],
            [95, -52],
            [13, -77],
            [-20, -33],
            [-128, -165],
            [-11, -48],
            [15, -106],
            [30, -52],
            [124, -151],
            [120, -41],
            [111, -89],
            [158, -39],
            [129, -3],
            [139, 13],
            [92, 17],
            [142, -24],
            [150, -120],
            [157, -30],
            [176, -28],
            [175, -23],
            [101, -57],
            [54, -67],
            [12, -69],
            [22, -96],
            [59, -38],
            [4, -63],
            [8, -73],
            [-55, -32],
            [-108, 24],
            [-144, 9],
            [-83, 33],
            [57, -58],
            [9, -78],
            [154, -93],
            [82, -19],
            [101, -14],
            [149, 66],
            [160, -43],
            [159, 30],
            [50, -5],
            [77, -14],
            [54, 16],
            [64, 135],
            [134, -37],
            [106, -25],
            [68, -24],
            [75, -31],
            [90, -17],
            [102, 16],
            [0, 124],
            [44, 178],
            [110, -1],
            [98, -30],
            [75, -116],
            [76, 14],
            [75, -41],
            [124, 24],
            [32, 91],
            [20, 129],
            [81, 81],
            [43, 13],
            [84, 1],
            [83, 158],
            [145, 24],
            [142, 28],
            [145, 100],
            [38, 29],
            [74, 0],
            [116, -36],
            [185, 67],
            [105, 87],
            [115, 92],
            [101, 24],
            [210, -3],
            [367, -8],
            [133, 101],
            [80, -12],
            [167, -109],
            [211, -65],
            [176, 56],
            [72, 73],
            [30, 72],
            [-28, 97],
            [93, 156],
            [142, 30],
            [151, -4],
            [103, -7],
            [74, -35],
            [30, -137],
            [154, -93],
            [217, -58],
            [258, -78],
            [243, -114],
            [196, -58],
            [95, 0],
            [82, 2],
            [272, 127],
            [149, -51],
            [85, -142],
            [104, -176],
            [35, -51],
            [91, -63],
            [135, -60],
            [230, -100],
            [110, -66],
            [51, -56],
            [-7, -164],
            [-13, -175],
            [-75, -132],
            [-8, -174],
            [-65, -146],
            [-39, -111],
            [-60, -199],
            [-97, -120],
            [-41, -228],
            [23, -107],
            [-33, -138],
            [-74, -155],
            [-43, -159],
            [-106, -102],
            [-37, -94],
            [-45, -317],
            [-102, -61],
            [-125, -14],
            [-171, -19],
            [-175, -28],
            [-113, -49],
            [-57, -61],
            [10, -132],
            [-15, -122],
            [10, -45],
            [26, -67],
            [-19, -52],
            [-74, -43],
            [-431, 42],
            [-214, -9],
            [-54, -44],
            [6, -56],
            [30, -47],
            [24, -60],
            [52, -44],
            [60, -346],
            [5, -41],
            [1, -52],
            [-44, -98],
            [-178, -58],
            [-34, -55],
            [-40, -39],
            [16, -59],
            [-56, -71],
            [-116, -79],
            [20, -200],
            [-35, -30],
            [-76, 30],
            [-130, 53],
            [-233, 0],
            [-297, 33],
            [-83, -83],
            [-155, 39],
            [-166, -43],
            [-93, 40],
            [-144, -39],
            [-15, -191],
            [111, -224],
            [26, -76],
            [96, -137],
            [129, -46],
            [-144, -196],
            [50, -53],
            [161, -26],
            [9, -208],
            [-287, -47],
            [-139, 39],
            [-62, -62],
            [85, -152],
            [14, -29],
            [-25, -111],
            [-10, -173],
            [42, -103],
            [78, -138],
            [72, -49],
            [250, -77],
            [325, -11],
            [343, -70],
            [178, -25],
            [141, -53],
            [23, -60],
            [-57, -177],
            [-100, -180],
            [-8, -112],
            [-19, -135],
            [-30, -101],
            [76, -101],
            [130, -21],
            [69, -119],
            [48, -54],
            [96, -132],
            [102, -122],
            [152, -101],
            [28, -37],
            [-212, -271],
            [-253, -261],
            [-52, -101],
            [-117, 107],
            [-106, 92],
            [-81, -18],
            [-140, -200],
            [-301, -90],
            [-61, -40],
            [-25, -102],
            [-28, -120],
            [-104, -42],
            [-146, -11],
            [-137, -74],
            [-288, 164],
            [-194, 138],
            [-106, 229],
            [-14, 49],
            [12, 53],
            [20, 57],
            [11, 68],
            [-76, 127],
            [-1, 86],
            [-27, 3],
            [-34, -6],
            [-54, -72],
            [-116, -28],
            [-32, -10],
            [-145, -107],
            [-152, 9],
            [-335, -148],
            [-47, -67],
            [-5, -61],
            [-272, -184],
            [-157, -30],
            [24, 135],
            [208, 262],
            [53, 202],
            [-22, 110],
            [23, 105],
            [-52, 59],
            [-141, -41],
            [18, -78],
            [-156, -128],
            [-65, -75],
            [-45, -15],
            [-43, 19],
            [-44, 40],
            [-29, -11],
            [-23, -45],
            [-19, -19],
            [-55, 10],
            [-20, 32],
            [-52, 10],
            [-38, -50],
            [-62, -94],
            [-146, -1],
            [-115, -93],
            [-77, 190],
            [-47, 49],
            [-42, 8],
            [-63, 44],
            [12, 127],
            [26, 33],
            [3, 59],
            [-30, 74],
            [-81, 11],
            [-106, 133],
            [-121, 69],
            [-13, 112],
            [-34, 90],
            [-73, 78],
            [-85, 43],
            [-137, 60],
            [-93, -113],
            [-124, -39],
            [-104, -84],
            [-58, -50],
            [-155, -49],
            [-81, -40],
            [-120, 42],
            [-42, 20],
            [-61, -28],
            [-37, -51],
            [-25, -58],
            [-8, -42],
            [-47, -9],
            [-123, 135],
            [-143, 50],
            [-48, 56],
            [-52, 17],
            [-60, -31],
            [-52, 16],
            [-25, 22],
            [-100, 3],
            [-110, 89],
            [-103, 92],
            [-122, 88],
            [-64, 26],
            [-84, -11],
            [-84, 101],
            [-144, 138],
            [-118, 172],
            [30, 92],
            [54, 80],
            [-46, 45],
            [-93, 31],
            [-87, 13],
            [-52, -79],
            [-17, -63],
            [-121, -55],
            [-143, -3],
            [-129, 2],
            [-199, 20],
            [-160, -7],
            [-162, 18],
            [-164, -53],
            [-111, -86],
            [-64, -64],
            [-42, -110],
            [-6, -76],
            [-140, -69],
            [-143, -36],
            [-137, -13],
            [-57, -25],
            [-27, -47],
            [7, -50],
            [-77, -70],
            [-97, -60],
            [-93, -56],
            [-91, -65],
            [-86, -11],
            [-67, 63],
            [-107, 73],
            [-129, 13],
            [-70, -56],
            [-15, -78],
            [151, -143],
            [100, -82],
            [65, -105],
            [14, -74],
            [-58, -163],
            [3, -105],
            [121, -37],
            [-4, -51],
            [-154, -176],
            [-119, -158],
            [-31, -26],
            [-40, -28],
            [-77, -93],
            [-194, 22],
            [-112, -62],
            [-103, -8],
            [-91, -32],
            [-21, -163],
            [-96, -101],
            [-47, -94],
            [-47, -39],
            [-48, 17],
            [-86, 120],
            [-109, -46],
            [-90, 6],
            [-210, 70],
            [-106, 79],
            [-170, 95],
            [-86, 81],
            [-42, 78],
            [-114, -71],
            [-87, 123],
            [-176, 83],
            [-36, -13],
            [-37, -52],
            [-75, -35],
            [-56, -2],
            [-78, 46],
            [-151, 2],
            [-74, 73],
            [-103, 23],
            [-46, -40],
            [-54, -2],
            [-148, -20],
        ],
        [
            [16557, 84895],
            [1, 1],
            [246, -129],
            [26, -137],
            [106, 28],
            [131, 109],
            [131, 0],
            [157, 28],
            [0, 148],
            [51, 48],
            [29, 18],
            [42, 12],
            [73, 6],
            [45, -25],
            [19, -25],
            [24, -73],
            [4, -18],
            [21, -36],
            [27, -40],
            [24, -29],
            [24, -7],
            [13, 4],
            [-9, -29],
            [-20, -46],
            [-1, 0],
            [-64, -48],
            [39, -162],
            [-23, -47],
            [16, -81],
            [-9, -70],
            [-26, -24],
            [-35, -20],
            [-30, -8],
            [-25, -5],
            [-45, 16],
            [-23, -15],
            [-27, -10],
            [-32, -19],
            [-27, -56],
            [-3, -29],
            [0, -43],
            [-52, -55],
            [-19, -58],
            [-20, -4],
            [-46, 0],
            [-54, -5],
            [72, -5],
            [49, 1],
            [429, -52],
            [196, -162],
            [203, -67],
            [590, -30],
            [249, -127],
            [171, 75],
            [499, -48],
            [-49, -109],
            [267, -111],
            [239, -247],
            [-162, -256],
            [65, -282],
            [220, -34],
            [63, -140],
            [272, -101],
            [167, 128],
            [113, -42],
            [-23, -192],
            [68, -135],
            [95, -47],
            [75, -126],
            [253, -119],
            [-21, -132],
            [1, -1],
            [170, 46],
            [168, -17],
            [10, 64],
            [113, -12],
            [68, -193],
            [291, -276],
            [36, -102],
            [102, -74],
            [109, -12],
            [67, -74],
            [4, -191],
            [51, -54],
            [-152, -91],
            [27, -80],
            [-94, -87],
            [45, -61],
            [227, 44],
            [24, 124],
            [131, -18],
            [60, -149],
            [102, -39],
            [91, 71],
            [38, -95],
            [297, -196],
            [29, -106],
            [133, 25],
            [90, -232],
            [80, -73],
            [77, -5],
            [55, -197],
            [119, -65],
            [1, 1],
            [-119, 64],
            [-55, 197],
            [-78, 6],
            [-80, 72],
            [-89, 233],
            [-134, -25],
            [-28, 105],
            [-298, 197],
            [-38, 95],
            [-91, -72],
            [-102, 40],
            [-60, 149],
            [-131, 17],
            [-25, -123],
            [-226, -44],
            [-45, 60],
            [94, 86],
            [-26, 80],
            [152, 92],
            [-51, 54],
            [-5, 191],
            [-66, 74],
            [-110, 12],
            [-101, 75],
            [-37, 102],
            [-291, 276],
            [-68, 193],
            [-114, 12],
            [-9, -65],
            [-168, 18],
            [-170, -46],
            [20, 132],
            [-253, 119],
            [-75, 126],
            [-95, 47],
            [-67, 135],
            [22, 192],
            [-113, 43],
            [-167, -129],
            [-272, 102],
            [-63, 139],
            [-219, 34],
            [-65, 282],
            [162, 256],
            [0, 0],
            [-240, 248],
            [-266, 111],
            [49, 108],
            [0, 0],
            [-500, 49],
            [-171, -76],
            [-249, 128],
            [-590, 30],
            [-202, 66],
            [-197, 162],
            [-429, 52],
            [-49, 0],
            [-68, 4],
            [50, 4],
            [46, 0],
            [21, 5],
            [18, 58],
            [53, 55],
            [0, 43],
            [3, 28],
            [27, 57],
            [31, 19],
            [27, 9],
            [23, 15],
            [45, -16],
            [25, 6],
            [31, 8],
            [34, 19],
            [27, 25],
            [8, 70],
            [-15, 81],
            [22, 47],
            [-38, 162],
            [64, 47],
            [1, 0],
            [19, 47],
            [10, 29],
            [-14, -3],
            [-23, 7],
            [-25, 29],
            [-27, 39],
            [-21, 36],
            [-3, 18],
            [-24, 73],
            [-20, 25],
            [-45, 26],
            [-74, -6],
            [-41, -12],
            [-29, -19],
            [-51, -48],
            [0, -148],
            [-157, -27],
            [-132, 0],
            [-131, -110],
            [-104, -27],
            [-26, 137],
            [-247, 128],
            [-1, -1],
        ],
        [
            [35589, 5241],
            [5, -29],
            [1, -17],
            [5, -16],
            [-2, -11],
            [-8, -11],
            [0, -14],
            [9, -13],
            [7, -18],
            [4, -29],
            [2, -10],
            [0, -13],
            [-16, -16],
            [-8, -14],
            [-6, -14],
            [-5, -24],
            [-11, -24],
            [-1, -15],
        ],
        [
            [35565, 4953],
            [0, -26],
            [-10, -41],
            [4, -29],
            [-2, -11],
            [-9, -17],
            [-5, -21],
            [-1, -22],
            [1, -63],
            [-2, -35],
            [2, -10],
            [2, -42],
            [-1, -32],
            [5, -38],
            [-1, -15],
            [-3, -15],
            [-3, -32],
            [-1, -17],
            [8, -24],
            [0, -11],
            [-2, -25],
            [2, -37],
            [-10, -18],
            [-4, -13],
            [0, -11],
            [5, -10],
            [17, -10],
            [4, -7],
            [9, -23],
            [0, -9],
            [-5, -35],
            [-10, -24],
            [-1, -14],
            [2, -16],
            [9, -21],
            [10, -27],
            [9, -23],
            [4, -17],
            [-1, -25],
            [20, -59],
            [14, -23],
            [6, -16],
            [4, -25],
            [7, -35],
            [8, -16],
            [2, -15],
            [6, -18],
            [6, -36],
            [5, -10],
            [2, -12],
            [-2, -8],
            [-23, -41],
            [-20, -7],
            [-7, -1],
            [-24, 4],
            [-22, -4],
            [-5, -19],
            [-15, -18],
            [-5, -9],
            [-16, 3],
            [-14, -6],
            [-16, 10],
            [-22, 1],
            [-10, -2],
            [-3, -8],
            [13, -27],
            [7, -7],
            [-3, -15],
            [2, -4],
            [13, -4],
            [2, -13],
            [9, -6],
            [2, -19],
            [9, -12],
            [1, -8],
            [-7, -27],
            [7, -13],
            [-6, -4],
            [-10, 3],
            [-7, 9],
            [-6, -2],
            [-1, -8],
            [-6, -10],
            [6, -11],
            [-1, -18],
            [11, 2],
            [19, -13],
            [10, -9],
            [-2, -9],
            [-22, -14],
            [-2, -11],
            [2, -9],
            [-16, -19],
            [6, -5],
            [2, -12],
            [-5, -10],
            [0, -6],
            [12, -6],
            [10, -17],
            [10, -3],
            [1, -9],
            [-7, -5],
            [-13, -3],
            [-4, -11],
            [5, -15],
            [-1, -8],
            [-29, -17],
            [-14, -11],
            [-2, -4],
            [-4, -24],
            [-6, -7],
            [-17, -13],
            [-7, -19],
            [0, -25],
            [-3, -26],
            [-7, -13],
            [-1, -16],
            [-4, -18],
            [-5, -6],
            [-7, -15],
            [-10, -3],
            [-11, -9],
            [-11, -2],
            [-28, -24],
            [-13, 7],
            [-5, 0],
            [-19, -10],
            [-28, -3],
            [-20, 12],
            [-9, 0],
            [-4, -8],
            [-16, -15],
            [-24, -13],
            [-24, -27],
            [-10, -5],
            [-7, -24],
            [-1, -26],
            [-1, -17],
            [-10, -14],
            [-8, -8],
            [-32, -20],
            [-13, -2],
            [-44, 6],
            [-32, 6],
            [-9, -3],
            [-8, -16],
            [1, -12],
            [32, -9],
            [10, -1],
            [17, -7],
            [11, -16],
            [5, -12],
            [6, -4],
            [20, -4],
            [4, -5],
            [-7, -21],
            [-1, -10],
            [7, -5],
            [8, 9],
            [22, -18],
            [-7, -8],
            [1, -12],
            [-6, -12],
            [0, -8],
            [8, -6],
            [-1, -11],
            [8, 3],
            [15, -8],
            [-1, -11],
            [15, -12],
            [2, -15],
            [-7, -14],
            [-7, -7],
            [-14, -5],
            [-6, -6],
            [-10, 6],
            [-8, 0],
            [-10, -8],
            [4, -18],
            [12, -18],
            [14, -7],
            [23, 1],
            [7, -5],
            [3, -10],
            [-14, -11],
            [3, -17],
            [-8, -4],
            [-8, -13],
            [-6, -4],
            [-11, 0],
            [-15, 5],
            [-12, -7],
            [-10, -1],
        ],
        [
            [35089, 2539],
            [-18, -11],
            [-3, -5],
            [-16, -12],
            [-6, -11],
            [2, -10],
            [-12, -6],
            [-11, 2],
            [-15, 7],
            [-13, 9],
            [-10, -5],
            [-17, -13],
            [-13, -13],
            [-15, -18],
            [-17, -8],
            [-9, -6],
            [-7, 2],
            [-17, 10],
            [-8, 2],
            [-16, -4],
            [-18, -10],
            [-19, 7],
            [-7, -3],
            [-19, -12],
            [-9, 1],
            [-21, 12],
            [-7, 2],
            [-14, -1],
            [-23, 3],
            [-13, -4],
            [-12, 5],
            [-12, -1],
            [-13, 5],
            [-6, -5],
            [0, -12],
            [-7, -8],
            [-10, -5],
            [-9, 1],
            [-14, 5],
            [-31, 2],
            [-20, 3],
            [-15, 8],
            [-22, 4],
            [-9, -2],
            [-38, 9],
            [-45, 7],
            [-21, 0],
            [-7, 2],
            [-28, 17],
            [-17, -2],
            [-13, 5],
            [-15, 13],
            [-19, 1],
            [-20, -18],
            [-11, -7],
            [-27, 15],
            [-19, 12],
            [-28, 5],
            [-11, 9],
            [-16, 18],
            [-5, 14],
            [-6, 5],
            [-1, 10],
            [11, 6],
            [9, -1],
            [7, 6],
            [5, 12],
            [12, 7],
            [-6, 12],
            [-20, -2],
            [-5, 7],
            [-1, 16],
            [8, 15],
            [6, 19],
            [3, 6],
            [22, 11],
            [6, 7],
            [13, 17],
            [-2, 13],
            [-17, 13],
            [-14, 8],
            [3, 11],
            [-1, 10],
            [-8, 12],
            [-5, 4],
            [-3, 9],
            [10, 10],
            [7, 23],
            [-10, 7],
            [-14, 15],
            [-23, 6],
            [-7, 11],
            [-5, 2],
            [-12, -4],
            [-11, 3],
            [-12, 9],
            [-13, -7],
            [-6, 0],
            [-14, 18],
            [-19, 1],
            [-15, 14],
            [-16, 4],
            [-2, -5],
            [-14, 6],
            [7, 24],
            [-2, 11],
            [10, -5],
            [2, 18],
            [-3, 12],
            [4, 17],
            [-2, 6],
            [6, 14],
            [-6, 16],
            [2, 18],
            [10, 6],
            [11, 15],
            [-4, 11],
            [0, 17],
            [-6, 5],
            [-10, 4],
            [-8, 18],
            [-10, 7],
            [-2, 6],
            [7, 4],
            [-3, 11],
            [16, 15],
            [27, 2],
            [10, 5],
            [5, 13],
            [18, 22],
            [13, 6],
            [8, 7],
            [3, 10],
            [-2, 5],
            [-11, 5],
            [-4, 12],
            [-9, 5],
            [-4, 10],
            [3, 13],
            [2, 22],
            [8, 15],
            [11, 31],
            [14, 6],
            [-4, 10],
            [-10, -5],
            [-11, 29],
            [-5, 11],
            [11, 13],
            [0, 13],
            [-14, 11],
            [-8, 9],
            [-10, -6],
            [-23, -3],
            [-25, 2],
            [-7, 36],
            [14, 23],
            [24, -3],
            [-4, 11],
            [12, 3],
            [10, 12],
            [15, 3],
            [9, 6],
            [15, -3],
            [11, 4],
            [13, 9],
            [8, 1],
            [6, -4],
            [13, -3],
            [19, 7],
            [10, 5],
            [16, -2],
            [6, 9],
            [3, 9],
            [14, 8],
            [3, 6],
            [0, 27],
            [5, 5],
            [12, 0],
            [7, 4],
            [8, -3],
            [7, -9],
            [6, 3],
            [2, 9],
            [9, 7],
            [9, -1],
            [-5, 14],
            [-8, 2],
            [-5, 9],
            [4, 9],
            [5, 1],
            [4, 9],
            [18, -4],
            [8, -6],
            [11, 8],
            [8, 0],
            [-12, 21],
            [1, 12],
            [-4, 6],
            [-16, 13],
            [-3, -2],
            [-17, 22],
            [-14, 21],
            [-5, 2],
            [-10, 11],
            [-10, 1],
            [-9, 18],
            [-2, 8],
            [7, 3],
            [11, 10],
            [6, 12],
            [3, 14],
            [-2, 9],
            [1, 12],
            [-2, 10],
            [-6, 11],
            [-25, 16],
            [-4, 10],
            [0, 13],
            [-18, 14],
            [-16, -4],
            [-17, 13],
            [-16, 4],
            [-15, 11],
            [-1, 11],
            [4, 5],
            [3, 18],
            [5, 10],
            [-20, 23],
            [-33, 35],
            [-15, 10],
            [-8, 17],
            [-7, 8],
            [-13, 4],
            [-11, 8],
            [-7, 2],
            [-39, 27],
            [-2, 8],
            [-8, 12],
            [-4, 30],
            [-3, 3],
            [-1, 25],
        ],
        [
            [34011, 4181],
            [8, 12],
            [11, -1],
            [12, -8],
            [6, -8],
            [7, -3],
            [15, 9],
            [10, 3],
            [10, -11],
            [13, -10],
            [6, -1],
            [10, 9],
            [-6, 12],
            [-9, -1],
            [-9, 14],
            [12, 13],
            [6, 24],
            [19, 7],
            [10, 29],
            [6, 6],
            [-2, 10],
            [7, 3],
            [-1, 8],
            [-8, 4],
            [0, 17],
            [-8, -1],
            [-7, 14],
            [-6, 3],
            [7, 8],
            [17, 5],
            [9, -18],
            [11, 3],
            [9, -3],
            [17, -19],
            [6, -3],
            [10, -12],
            [15, -12],
            [27, -16],
            [10, 0],
            [11, 10],
            [11, -1],
            [-3, 19],
            [5, 11],
            [-11, 21],
            [0, 13],
            [4, 10],
            [10, -6],
            [20, -1],
            [6, 4],
            [-4, 13],
            [5, 4],
            [6, -5],
            [15, -5],
            [-1, 7],
            [10, 3],
            [20, -14],
            [20, 9],
            [4, 6],
            [-12, 25],
            [-13, 9],
            [-5, 14],
            [2, 10],
            [7, 2],
            [-15, 24],
            [-9, 3],
            [6, 8],
            [10, -5],
            [23, -3],
            [5, 9],
            [2, 12],
            [11, 1],
            [13, 5],
            [3, 7],
            [-4, 6],
            [-3, 15],
            [2, 8],
            [12, 11],
            [2, 11],
            [10, -4],
            [7, 7],
            [-6, 14],
            [-9, 5],
            [4, 30],
            [-5, 12],
            [1, 8],
            [6, 5],
            [2, 11],
            [-12, 5],
            [1, 12],
            [-4, 7],
            [-10, -9],
            [-2, 7],
            [4, 6],
            [0, 17],
            [-5, 5],
            [-22, 37],
            [-10, -1],
            [6, 31],
            [-6, 6],
            [3, 16],
            [8, 12],
            [1, 10],
            [14, 13],
            [1, 11],
            [7, 4],
            [7, -3],
            [5, 10],
            [7, 2],
            [8, -7],
            [-3, 19],
            [3, 14],
            [-15, 22],
            [2, 6],
            [23, 28],
            [-4, 9],
            [-14, 13],
            [-15, 22],
            [2, 14],
            [-1, 14],
            [-3, 6],
            [-12, 5],
            [-17, 4],
            [-2, 12],
            [-7, 7],
            [-1, 21],
            [-7, 2],
            [0, 15],
            [-7, 1],
            [-1, 21],
            [14, -2],
            [7, 8],
            [1, 15],
            [-1, 11],
            [-11, 15],
            [2, 24],
            [-5, 9],
            [2, 9],
            [-5, 16],
            [6, 20],
            [-15, 13],
            [-17, -4],
            [-3, 7],
            [-1, 28],
            [4, 10],
            [1, 18],
            [-2, 23],
            [-4, 10],
            [-7, 8],
            [-11, 1],
            [-7, 15],
            [-6, 19],
            [-11, 16],
            [-4, 33],
            [-8, 7],
            [-12, 1],
            [-18, -2],
            [-6, 2],
            [-5, 17],
            [-12, 7],
            [-2, 35],
            [2, 18],
            [-5, 19],
            [2, 7],
            [11, 9],
            [2, 9],
        ],
        [
            [34234, 5522],
            [4, 8],
            [-3, 18],
            [18, 5],
            [17, -4],
            [10, 2],
            [12, -3],
            [11, 3],
            [24, 14],
            [18, 13],
            [9, 3],
            [10, 8],
            [10, 4],
            [23, 17],
            [11, 4],
            [8, 12],
            [4, 18],
            [17, 23],
            [0, 6],
            [13, -3],
            [27, 1],
            [8, -4],
            [2, -15],
            [6, -12],
            [18, -6],
            [20, 17],
            [17, -2],
            [4, 16],
            [17, 5],
            [5, 10],
            [-3, 4],
            [-1, 22],
            [3, 10],
            [8, 0],
            [15, 7],
            [12, 1],
            [7, 6],
            [1, 7],
            [-2, 10],
            [-7, 1],
            [-9, 16],
            [7, 6],
            [12, 7],
            [22, 15],
            [12, 4],
            [8, 7],
            [1, 34],
            [-5, 54],
            [3, 6],
            [8, 6],
            [3, 33],
            [12, -1],
            [11, 7],
            [8, -1],
            [33, -16],
            [10, 7],
            [25, 41],
            [23, 35],
            [11, 19],
            [15, -2],
            [6, 2],
            [10, 13],
            [30, 16],
            [48, 12],
            [22, 17],
            [9, 3],
            [10, -19],
            [16, -12],
            [20, -8],
            [11, -1],
            [7, -9],
            [0, -22],
            [-4, -14],
            [2, -32],
            [-1, -13],
            [28, -16],
            [20, -4],
            [13, -5],
            [5, 14],
            [-1, 14],
            [-4, 21],
            [5, 6],
            [30, 10],
            [10, 5],
            [9, -5],
            [0, -15],
            [12, -16],
            [18, -1],
            [9, 3],
            [17, 1],
            [17, -8],
            [21, -17],
            [-39, -10],
            [-18, -12],
            [-4, -11],
            [-4, -24],
            [-8, -12],
            [-5, -12],
            [-12, -16],
            [-8, -16],
            [1, -23],
            [-2, -31],
            [-8, -32],
            [-6, -17],
            [0, -11],
            [-2, -29],
            [-5, -34],
            [-3, -6],
            [4, -22],
            [-10, -17],
            [-5, -16],
            [3, -29],
            [-7, -29],
            [-17, -10],
            [-10, 0],
            [5, -25],
            [3, -16],
            [-30, -14],
            [-5, 13],
            [-8, 6],
            [-12, -13],
            [-18, 2],
            [-6, -2],
            [-9, -12],
            [-9, -18],
            [11, -10],
            [1, -17],
            [-5, -10],
            [1, -18],
            [-4, -61],
            [1, -49],
            [6, -20],
            [11, -26],
            [3, -15],
            [7, -13],
            [2, -19],
            [-3, -24],
            [-7, -8],
            [26, -37],
            [4, -2],
            [68, 1],
            [8, -21],
            [8, 2],
            [18, 11],
            [8, 10],
            [11, -4],
            [34, 11],
            [11, 10],
            [-1, 15],
            [8, 10],
            [8, -1],
            [9, 17],
            [8, 7],
            [7, -1],
            [-1, -12],
            [-7, -8],
            [6, -6],
            [-1, -10],
            [23, 0],
            [13, 5],
            [9, -3],
            [15, -12],
            [8, -11],
            [4, 16],
            [3, 22],
            [5, 8],
            [-1, 17],
            [7, 32],
            [23, 2],
            [5, 2],
            [20, -1],
            [9, 2],
            [10, -4],
            [7, -8],
            [9, 0],
            [10, -6],
            [10, -4],
            [9, 5],
            [9, -4],
            [10, 1],
            [10, 4],
            [10, -1],
            [7, 11],
            [12, -1],
            [15, -4],
            [4, 6],
            [18, 0],
            [14, 6],
            [15, -3],
            [4, 5],
            [10, 1],
            [13, -5],
            [8, 2],
            [18, 12],
        ],
        [
            [37647, 4997],
            [-6, 12],
            [8, 3],
            [-2, -15],
        ],
        [
            [37583, 5034],
            [-12, -2],
            [-7, 11],
            [9, 11],
            [6, -2],
            [4, -18],
        ],
        [
            [37471, 5191],
            [5, -3],
            [-12, -17],
            [-9, 8],
            [0, 6],
            [8, 6],
            [8, 0],
        ],
        [
            [37564, 5193],
            [6, -4],
            [4, -9],
            [8, 6],
            [6, -7],
            [3, -22],
            [4, -9],
            [-2, -10],
            [-4, -6],
            [-12, -7],
            [-10, -23],
            [-12, -5],
            [-7, 15],
            [-2, 15],
            [-3, 15],
            [0, 25],
            [6, 17],
            [9, 9],
            [6, 0],
        ],
        [
            [37562, 5224],
            [15, -8],
            [-11, -17],
            [-8, 0],
            [-19, -6],
            [-1, 5],
            [-10, 11],
            [10, 0],
            [5, 10],
            [10, 5],
            [9, 0],
        ],
        [
            [37064, 5743],
            [11, -18],
            [-5, -4],
            [-5, 10],
            [-1, 12],
        ],
        [
            [37056, 5806],
            [7, -15],
            [-8, -5],
            [-5, 16],
            [6, 4],
        ],
        [
            [36851, 6373],
            [-4, 12],
            [5, 9],
            [7, -7],
            [-8, -14],
        ],
        [
            [35530, 8925],
            [2, -9],
            [15, -25],
            [17, -26],
            [23, -28],
            [11, -21],
            [18, -26],
            [15, -15],
            [10, 8],
            [6, -11],
            [3, -11],
            [6, -6],
            [2, -8],
            [8, -21],
            [14, -29],
            [14, -27],
            [17, -29],
            [15, -20],
            [21, -21],
            [23, -25],
            [18, -15],
            [16, -11],
            [12, 2],
            [27, 23],
            [-6, -11],
            [9, -8],
            [8, -2],
            [2, -10],
            [12, -12],
            [4, -10],
            [0, -19],
            [11, -29],
            [15, -28],
            [6, -4],
            [8, -15],
            [20, -35],
            [22, -33],
            [8, -2],
            [5, -9],
            [23, -32],
            [18, -20],
            [11, -9],
            [9, 1],
            [13, -20],
            [11, -23],
            [14, -20],
            [17, -19],
            [12, -10],
            [12, -7],
            [7, -19],
            [11, -19],
            [17, -26],
            [9, -18],
            [12, -31],
            [19, -39],
            [12, -19],
            [19, -22],
            [7, -5],
            [6, -13],
            [8, -2],
            [14, -18],
            [2, -15],
            [-2, -9],
            [-5, -8],
            [0, -12],
            [3, -14],
            [7, -15],
            [-21, -25],
            [0, -26],
            [7, -27],
            [8, -24],
            [7, -16],
            [11, -18],
            [9, -10],
            [17, -7],
            [12, 20],
            [3, -2],
            [-4, -20],
            [-12, -4],
            [-4, -13],
            [4, -13],
            [9, -4],
            [1, -11],
            [7, -2],
            [-6, -12],
            [5, -8],
            [-3, -5],
            [4, -7],
            [-4, -11],
            [-15, -4],
            [-18, -14],
            [-2, -7],
            [-12, -1],
            [-4, 10],
            [14, 24],
            [-20, -12],
            [-7, 3],
            [16, 15],
            [1, 8],
            [10, 11],
            [2, 11],
            [6, 3],
            [0, 7],
            [-11, 15],
            [-6, 12],
            [-7, 7],
            [-7, 2],
            [-18, -18],
            [-16, 0],
            [1, 9],
            [11, 5],
            [18, 21],
            [-5, 8],
            [-11, -7],
            [-21, -27],
            [-9, 1],
            [-2, 7],
            [-13, 10],
            [-9, -14],
            [-9, 0],
            [-3, -4],
            [0, -13],
            [7, 3],
            [10, -3],
            [-14, -16],
            [-7, -3],
            [6, -10],
            [-4, -4],
            [6, -9],
            [7, 4],
            [6, -3],
            [20, 5],
            [2, -5],
            [-17, -5],
            [-18, -9],
            [-4, -17],
            [14, 2],
            [3, 5],
            [12, -13],
            [-10, 0],
            [-2, -13],
            [16, 2],
            [3, -5],
            [-13, -5],
            [1, -13],
            [10, 0],
            [1, -7],
            [9, -11],
            [-3, -7],
            [7, -6],
            [3, -9],
            [-2, -5],
            [6, -12],
            [-5, -2],
            [-11, 2],
            [-22, -12],
            [-7, 6],
            [-10, 2],
            [-4, 11],
            [-16, 1],
            [4, 9],
            [-3, 22],
            [6, 6],
            [3, 15],
            [-11, 16],
            [-8, 4],
            [-6, -4],
            [-1, -6],
            [5, -6],
            [-6, -15],
            [-5, -3],
            [-9, 17],
            [-25, 25],
            [-10, 4],
            [-10, 9],
            [-13, -3],
            [-4, -13],
            [-11, -7],
            [2, -14],
            [-8, -8],
            [-9, -19],
            [-19, -11],
            [-7, -11],
            [1, -8],
            [-20, -6],
            [-10, 1],
            [-5, -6],
            [2, -13],
            [6, -14],
            [11, -17],
            [9, -2],
            [13, -12],
            [8, 0],
            [4, 10],
            [9, 1],
            [7, 7],
            [1, 10],
            [7, 6],
            [3, 20],
            [8, -4],
            [14, 11],
            [30, 8],
            [7, 4],
            [12, -7],
            [9, 0],
            [10, -11],
            [24, -6],
            [4, -9],
            [-1, -23],
            [5, -23],
            [10, -22],
            [8, -13],
            [15, -16],
            [34, -19],
            [14, -6],
            [14, 4],
            [7, -4],
            [12, 7],
            [39, -4],
            [24, 5],
            [36, 1],
            [22, 5],
            [20, 27],
            [6, 17],
            [2, 21],
            [-10, 21],
            [-1, 16],
            [-7, 20],
            [14, 2],
            [17, -1],
            [26, 5],
            [11, 9],
            [14, 5],
            [6, 6],
            [-1, 9],
            [7, 7],
            [5, 9],
            [13, 0],
            [9, -14],
            [6, -4],
            [9, -14],
            [21, -15],
            [5, -9],
            [10, -1],
            [9, -6],
            [7, -13],
            [12, -8],
            [-3, -19],
            [2, -15],
            [10, -31],
            [4, -9],
            [9, -26],
            [11, -67],
            [12, -42],
            [5, -28],
            [7, -31],
            [8, -26],
            [7, -33],
            [9, -29],
            [4, -12],
            [13, -19],
            [12, -10],
            [-3, -6],
            [-8, -4],
            [-3, -23],
            [0, -17],
            [1, -25],
            [4, -29],
            [10, -29],
            [4, -4],
            [0, -19],
            [5, -28],
            [9, -28],
            [3, -19],
            [6, -25],
            [5, -35],
            [8, -30],
            [11, -57],
            [5, -22],
            [8, -21],
            [18, -27],
            [-1, -10],
            [5, -28],
            [7, -19],
            [11, -17],
            [12, -7],
            [-3, -5],
            [-4, -18],
            [0, -15],
            [5, -28],
            [14, -49],
            [13, -32],
            [9, -18],
            [9, -12],
            [14, -14],
            [11, -4],
            [-6, -26],
            [2, -24],
            [11, -55],
            [14, -49],
            [11, -30],
            [19, -42],
            [14, -25],
            [17, -26],
            [13, -15],
            [21, -21],
            [18, -14],
            [16, -5],
            [5, 2],
            [8, -6],
            [2, -7],
            [15, -16],
            [13, -8],
            [9, -1],
            [13, 2],
            [17, 12],
            [6, 14],
            [4, 3],
            [16, -7],
            [4, -13],
            [-1, -7],
            [-16, -9],
            [-12, -10],
            [-6, -11],
            [-3, -13],
            [-1, -26],
            [4, -18],
            [11, -24],
            [13, -17],
            [-6, -3],
            [-3, -7],
            [-19, -15],
            [-18, -4],
            [-16, 0],
            [-4, 6],
            [-10, 1],
            [-5, -5],
            [-10, -20],
            [-6, -4],
            [-5, -9],
            [-1, -26],
            [6, -15],
            [-1, -4],
            [-15, -11],
            [6, -3],
            [4, -17],
            [9, -15],
            [4, 0],
            [5, -18],
            [-4, -15],
            [4, -7],
            [8, -6],
            [0, -8],
            [6, -14],
            [0, -21],
            [9, 3],
            [17, -20],
            [6, -11],
            [12, -30],
            [2, -11],
            [13, -9],
            [5, -11],
            [-2, -4],
            [-17, 3],
            [-5, 10],
            [-7, 4],
            [-8, -1],
            [-5, -6],
            [2, -11],
            [-27, 4],
            [-24, 9],
            [-3, -5],
            [-2, -14],
            [6, -4],
            [3, -14],
            [-3, -7],
            [4, -7],
            [10, 11],
            [5, -4],
            [10, 1],
            [8, 7],
            [2, 8],
            [17, -2],
            [18, -10],
            [9, 5],
            [4, 6],
            [9, 8],
            [6, 14],
            [1, 9],
            [-6, 11],
            [-14, 10],
            [-8, 10],
            [10, 8],
            [-4, 9],
            [-7, -5],
            [-6, 8],
            [-14, 26],
            [-22, 17],
            [-6, 18],
            [0, 15],
            [-11, 11],
            [3, 10],
            [6, 10],
            [-5, 8],
            [-7, 23],
            [-13, 9],
            [5, 12],
            [7, -4],
            [5, 11],
            [-1, 13],
            [-10, 23],
            [1, 12],
            [8, 2],
            [9, 10],
            [4, -7],
            [16, 8],
            [28, 2],
            [22, 13],
            [12, 2],
            [6, 27],
            [10, 0],
            [11, -11],
            [-3, -14],
            [3, -12],
            [6, -8],
            [11, -6],
            [7, 7],
            [15, -1],
            [16, -15],
            [1, -8],
            [-31, 0],
            [-7, -6],
            [-8, -22],
            [-3, -24],
            [0, -28],
            [2, -19],
            [4, -18],
            [13, -41],
            [15, -32],
            [8, -16],
            [13, -19],
            [16, -19],
            [20, -18],
            [23, -14],
            [11, -4],
            [11, 0],
            [5, 6],
            [11, 4],
            [5, 6],
            [6, -18],
            [11, -24],
            [28, -51],
            [8, -11],
            [21, -26],
            [17, -18],
            [18, -13],
            [15, -10],
            [22, -15],
            [26, -7],
            [8, -4],
            [0, -16],
            [5, -4],
            [0, -13],
            [15, -9],
            [1, -5],
            [24, -31],
            [4, -12],
            [9, -7],
            [0, -15],
            [-8, -19],
            [-8, -4],
            [-10, 10],
            [-11, 2],
            [-11, 7],
            [-9, 18],
            [-5, 4],
            [-17, 3],
            [-3, -9],
            [6, -9],
            [8, -5],
            [3, -7],
            [-1, -10],
            [-7, -12],
            [-1, -6],
            [14, -7],
            [8, -8],
            [0, -9],
            [-8, -25],
            [-14, -7],
            [-20, 6],
            [-5, 10],
            [1, 19],
            [-2, 11],
            [-8, 16],
            [-1, 11],
            [-7, 24],
            [7, 29],
            [-20, 11],
            [-10, 15],
            [-7, 4],
            [-8, 13],
            [-12, 0],
            [-8, 5],
            [-9, -9],
            [-12, 10],
            [-12, 8],
            [-6, 1],
            [-8, 10],
            [-13, 11],
            [-14, 15],
            [-16, 8],
            [-15, 3],
            [-6, 5],
            [-11, -2],
            [1, -8],
            [-12, 1],
            [3, -9],
            [-7, -9],
            [3, -9],
            [-16, 1],
            [-3, -13],
            [-8, 2],
            [0, -7],
            [7, -4],
            [0, -6],
            [-10, -7],
            [-4, -8],
            [-8, -2],
            [-2, -17],
            [3, -19],
            [-3, -8],
            [-9, 3],
            [-8, -11],
            [-9, -6],
            [-7, -8],
            [10, -4],
            [8, 20],
            [8, 7],
            [5, -5],
            [0, -8],
            [12, 0],
            [18, -5],
            [4, -8],
            [11, -5],
            [3, 2],
            [9, -10],
            [7, -1],
            [22, -11],
            [11, 12],
            [6, 10],
            [2, 14],
            [-3, 20],
            [3, 7],
            [12, 2],
            [7, -13],
            [10, -5],
            [20, -14],
            [9, 0],
            [27, -4],
            [6, -6],
            [18, 7],
            [6, -5],
            [-7, -16],
            [-11, -3],
            [-12, -12],
            [-15, -5],
            [-11, 1],
            [-7, -11],
            [10, -13],
            [13, 5],
            [-2, 12],
            [6, 5],
            [16, 1],
            [3, -7],
            [2, -17],
            [13, -9],
            [9, -3],
            [11, -12],
            [7, -12],
            [-6, -3],
            [4, -14],
            [7, -34],
            [8, -3],
            [6, 6],
            [-1, 10],
            [-7, 18],
            [4, 3],
            [30, -4],
            [6, -2],
            [11, -26],
            [7, -10],
            [9, -23],
            [4, -4],
            [2, -9],
            [-11, 2],
            [-1, -10],
            [-5, -3],
            [-8, 5],
            [0, -27],
            [-5, -9],
            [18, -4],
            [1, -11],
            [-11, -6],
            [32, 0],
            [8, 22],
            [9, -1],
            [6, -13],
            [8, 2],
            [6, 6],
            [5, 13],
            [9, 3],
            [5, 12],
            [5, -3],
            [6, -34],
            [6, -9],
            [9, -7],
            [5, -11],
            [10, 4],
            [10, -8],
            [14, -19],
            [1, -9],
            [29, -47],
            [0, -6],
            [-7, -8],
            [-8, 6],
            [-3, -11],
            [17, -6],
            [18, -10],
            [8, -12],
            [17, -7],
            [-2, -11],
            [-14, -5],
            [-6, -10],
            [-5, -15],
            [-21, -7],
            [-4, -5],
            [-12, 10],
            [-5, -7],
            [11, -6],
            [1, -13],
            [6, -6],
            [12, -6],
            [4, 2],
            [2, 16],
            [8, 7],
            [9, -1],
            [4, -6],
            [0, -10],
            [7, -1],
            [6, -10],
            [2, -13],
            [-2, -17],
            [-13, -14],
            [-10, -3],
            [-18, 6],
            [-14, 19],
            [-16, 11],
            [-13, -4],
            [-1, -21],
            [4, -7],
            [5, -17],
            [5, -6],
            [8, -1],
            [3, 11],
            [14, 9],
            [7, 0],
            [14, -8],
            [14, 4],
            [5, -6],
            [5, -11],
            [6, -6],
            [-2, -8],
            [9, -9],
            [9, -2],
            [7, -6],
            [-25, -10],
            [-9, -9],
            [-5, -13],
            [3, -9],
            [-18, -1],
            [-16, 5],
            [-12, 13],
            [-6, 1],
            [3, -16],
            [8, -3],
            [-1, -8],
            [7, -20],
            [12, -1],
            [-1, -14],
            [13, -1],
            [12, -6],
            [3, -5],
            [-1, -16],
            [-10, -3],
            [-9, 8],
            [-8, -10],
            [3, -16],
            [-7, -13],
            [-1, -11],
            [-14, -12],
            [4, -3],
            [17, 22],
            [28, 40],
            [14, 14],
            [36, -34],
            [20, -17],
            [-7, -17],
            [-17, -26],
            [3, -24],
            [3, -11],
            [20, 0],
            [-2, 27],
            [4, 11],
            [-2, 10],
            [5, 8],
            [6, 1],
            [9, -8],
            [6, -15],
            [9, 20],
            [-3, 9],
            [1, 8],
            [-2, 14],
            [4, 21],
            [3, 3],
            [-3, 12],
            [-9, -3],
            [-5, 13],
            [0, 25],
            [7, 7],
            [-2, 10],
            [-12, -7],
            [-5, -9],
            [1, -11],
            [-4, -26],
            [4, -10],
            [-3, -6],
            [-14, 0],
            [-7, 6],
            [-11, 0],
            [0, 6],
            [-14, 12],
            [-5, 8],
            [-9, 2],
            [-2, 7],
            [-7, 3],
            [0, 16],
            [-9, 2],
            [-1, 25],
            [2, 10],
            [11, 12],
            [9, 0],
            [10, 8],
            [4, 8],
            [0, 12],
            [-4, 6],
            [2, 6],
            [-1, 11],
            [-9, 10],
            [-5, 11],
            [-1, 12],
            [3, 16],
            [-14, 26],
            [3, 20],
            [11, 24],
            [-7, 18],
            [17, 18],
            [-7, 6],
            [-8, -2],
            [-11, 4],
            [-14, 16],
            [-5, 11],
            [1, 5],
            [-19, 38],
            [-5, 7],
            [-3, 15],
            [-7, 18],
            [-3, 20],
            [-2, 4],
            [3, 11],
            [-9, 0],
            [-3, 15],
            [-11, -8],
            [-14, 4],
            [-10, -4],
            [-6, 5],
            [-5, 10],
            [-10, 12],
            [-7, 16],
            [-12, 12],
            [11, 9],
            [5, 10],
            [1, 8],
            [-4, 16],
            [-8, 13],
            [-20, 47],
            [-9, 15],
            [-4, 13],
            [-2, 13],
            [-6, 15],
            [1, 16],
            [4, 7],
            [0, 19],
            [-3, 10],
            [-22, 41],
            [-7, 8],
            [-8, 16],
            [-15, 14],
            [13, -1],
            [12, -12],
            [11, -13],
            [16, -25],
            [16, -38],
            [11, -19],
            [17, -24],
            [17, -27],
            [23, -38],
            [11, -17],
            [29, -50],
            [21, -37],
            [45, -90],
            [28, -67],
            [11, -36],
            [7, -21],
            [9, -31],
            [3, -24],
            [8, -24],
            [2, -14],
            [8, -43],
            [6, -14],
            [0, -14],
            [5, -28],
            [2, -29],
            [3, -20],
            [6, -29],
            [12, -38],
            [5, -44],
            [6, -25],
            [5, -27],
            [6, -22],
            [13, -21],
            [26, -30],
            [8, -25],
            [15, -59],
            [13, -41],
            [15, -39],
            [12, -39],
            [11, -52],
            [3, -16],
            [-1, -45],
            [7, -47],
            [-3, -12],
            [3, -4],
            [-4, -16],
            [5, -3],
            [4, -12],
            [-7, -13],
            [-4, 0],
            [-3, -26],
            [-2, -54],
            [-5, -44],
            [-1, -43],
            [1, -46],
            [-1, -38],
            [-2, -26],
            [0, -47],
            [-2, -26],
            [-2, -9],
            [-6, -42],
            [-1, -33],
            [5, -15],
            [-3, -18],
            [1, -30],
            [3, -10],
            [10, -36],
            [2, -22],
            [3, -7],
            [9, -41],
            [2, -33],
            [4, -17],
            [6, -15],
            [6, -4],
            [3, -16],
            [9, -14],
            [1, -11],
            [-18, -46],
            [-1, -14],
            [-14, -35],
            [-6, -27],
            [2, -9],
            [6, -11],
            [-8, -22],
            [-3, -15],
            [-26, -62],
            [-6, -19],
            [-1, -13],
            [-7, -18],
            [-16, -47],
            [3, -3],
            [1, -18],
            [8, -7],
            [-11, -23],
            [-3, -14],
            [7, -8],
            [-2, -12],
            [3, -6],
            [0, -11],
            [-34, -36],
            [-3, -12],
            [-3, -25],
            [4, -14],
            [18, -4],
            [1, -13],
            [-16, -12],
            [-18, -17],
            [-6, -10],
            [-7, -18],
            [-1, -12],
            [6, -6],
            [-3, -7],
            [2, -9],
            [-3, -13],
            [-10, -21],
            [-3, -14],
            [2, -11],
            [9, -4],
            [4, -10],
            [-5, -17],
            [3, -15],
            [7, -2],
            [2, -7],
            [-4, -9],
            [-16, -13],
            [-19, -22],
            [-26, -44],
            [-7, -16],
            [1, -9],
            [7, -8],
            [-7, -14],
            [-1, -11],
            [3, -9],
            [-8, -16],
            [-20, -19],
            [-15, -19],
            [-9, -14],
            [-9, -18],
            [-3, -5],
            [-17, -28],
            [-5, -28],
            [3, -17],
            [5, -7],
            [7, -4],
            [-1, -9],
            [5, -5],
            [-5, -15],
            [-7, -14],
            [-6, -32],
            [-7, -10],
            [-16, -24],
            [-7, -13],
            [-2, -13],
            [9, -9],
            [0, -18],
            [-20, -21],
            [-23, -20],
            [-16, -17],
            [-7, -9],
            [3, -9],
            [-10, -16],
            [-17, -22],
            [-7, -14],
            [1, -10],
            [-6, -8],
            [-7, -19],
            [2, -9],
            [-8, -6],
            [-15, -19],
            [-3, -7],
            [-19, -30],
            [-4, -11],
        ],
        [
            [37589, 1722],
            [-9, 6],
            [-14, 6],
            [-16, -5],
            [-11, 1],
            [-16, 8],
            [-15, 11],
            [-14, 6],
            [-5, 10],
            [-4, 18],
            [-5, 10],
            [-12, 13],
            [-6, 3],
            [-11, -2],
            [-8, 6],
            [-6, 7],
            [-6, 2],
            [-19, -10],
            [-10, -1],
            [-9, 9],
            [-14, 29],
            [-13, 15],
            [-6, 3],
            [-10, 14],
            [0, 16],
            [-4, 5],
            [-11, -1],
            [-6, 6],
            [-7, 13],
            [-7, 3],
        ],
        [
            [37315, 1923],
            [2, 16],
            [4, 8],
            [-3, 18],
            [5, 75],
            [4, 70],
            [3, 68],
            [4, 51],
            [5, 18],
            [4, 119],
            [1, 76],
            [2, 34],
            [2, 19],
            [8, 136],
            [9, 111],
            [3, 61],
            [0, 82],
            [15, 86],
            [-2, 8],
            [10, 40],
            [6, 5],
            [-6, 13],
            [-7, 4],
            [-9, 13],
            [-10, 2],
            [-9, 9],
            [-16, 23],
            [1, 10],
            [10, 20],
            [2, 15],
            [-5, 13],
            [-16, 9],
            [4, 11],
            [-5, 21],
            [0, 9],
            [-3, 7],
            [-12, 13],
            [-4, 13],
            [4, 14],
            [13, 26],
            [8, 12],
            [6, 5],
            [1, 8],
            [-3, 16],
            [-5, 5],
            [-6, 15],
            [-2, 12],
            [-34, 11],
            [-11, -6],
            [-13, 5],
            [-6, 13],
            [-13, -2],
            [-8, 4],
            [-8, -1],
            [-11, 8],
            [-9, 10],
            [-10, -5],
            [-24, 4],
            [-34, 15],
            [-19, 10],
            [-36, 11],
            [-22, 13],
            [-15, 12],
            [3, 19],
            [-1, 6],
            [-12, 19],
            [6, 21],
            [-6, 19],
            [7, 11],
            [-9, 14],
            [-13, 8],
            [-11, 11],
            [-3, 9],
            [1, 23],
            [24, 59],
            [5, 10],
            [8, 7],
            [11, 19],
            [7, 10],
            [5, 11],
            [9, 7],
            [7, 10],
            [21, 16],
            [20, 17],
            [9, 1],
            [15, 7],
            [5, 9],
            [-9, 3],
            [-13, 14],
            [-4, 10],
            [-9, 8],
            [-12, 1],
            [-11, 20],
            [-7, -1],
            [-18, 3],
            [-9, 10],
            [4, 11],
            [14, 15],
            [-1, 7],
            [-9, 18],
            [3, 23],
            [-8, 9],
            [-10, 20],
            [-1, 6],
            [6, 13],
            [0, 6],
            [-14, 16],
            [-13, 18],
            [-12, 3],
            [-29, 12],
            [-2, 6],
            [-11, 13],
            [-17, 16],
            [-9, 5],
            [0, 6],
            [-9, 11],
            [12, 11],
            [10, 6],
            [13, 16],
            [-16, 59],
            [-17, 71],
            [-5, 12],
            [-9, 15],
            [-5, 11],
            [1, 32],
            [6, 29],
            [8, 12],
            [0, 7],
            [-4, 7],
            [-16, 0],
            [-14, 6],
            [-9, 7],
            [2, 9],
            [11, 14],
            [4, 9],
            [11, 17],
            [7, 4],
            [13, 24],
            [-4, 7],
            [-14, 0],
            [-8, 3],
            [-5, -2],
            [-15, 0],
            [-4, 4],
            [-18, 1],
            [-2, -5],
            [-8, -8],
            [-13, -4],
            [-8, -13],
            [-16, -14],
            [-18, -7],
            [-19, -2],
            [-7, -16],
            [-10, -3],
            [-17, -1],
            [-16, -6],
            [4, -13],
            [2, -19],
            [-5, -11],
            [-5, -4],
            [-14, -2],
            [-5, -6],
            [-6, -1],
            [-19, -14],
            [-16, -1],
            [-20, 7],
            [-6, -1],
            [-3, -18],
            [1, -19],
            [6, -15],
            [12, -13],
            [4, -36],
            [9, -7],
            [4, -8],
            [-11, -7],
            [-15, -14],
            [-9, -14],
            [-16, 2],
            [-13, -6],
            [-1, -17],
            [11, -16],
            [-1, -5],
            [-26, -13],
            [-29, -9],
            [-15, 2],
            [-19, -6],
            [-10, 0],
            [-8, 4],
            [-10, -4],
            [-16, 0],
            [2, -6],
            [0, -24],
            [-8, -11],
            [-2, -10],
            [3, -18],
            [8, -14],
            [0, -6],
            [12, -15],
            [-9, -15],
            [1, -13],
            [-7, -14],
            [-5, -17],
            [6, -14],
            [6, -24],
            [-17, -8],
            [-20, -6],
            [-18, -10],
            [-17, -23],
            [-26, -18],
            [-5, 2],
            [7, 10],
            [-2, 11],
            [-14, 7],
            [-11, 14],
            [8, 12],
            [-5, 10],
            [-20, 35],
            [-9, 22],
            [9, 17],
            [1, 5],
            [-12, 36],
            [-15, 25],
            [-19, 13],
            [-12, 18],
            [-13, 9],
            [-1, 9],
            [2, 13],
            [-10, 8],
            [-14, 16],
            [-15, -15],
            [-6, -12],
            [-16, -6],
            [-8, 0],
            [-7, 12],
            [2, 13],
            [-10, 6],
            [-6, 15],
            [-10, -1],
            [-4, 18],
            [-8, 18],
            [-8, 25],
            [-8, 7],
            [-4, 11],
            [-9, 2],
            [-3, 5],
            [0, 16],
            [17, 6],
            [5, 7],
            [-1, 14],
            [-6, 25],
            [-9, 13],
            [0, 9],
            [-6, 13],
            [-3, 16],
            [3, 14],
            [-8, 6],
            [0, 13],
            [-11, 31],
            [-13, 7],
            [3, 14],
            [12, 6],
            [5, 6],
            [-2, 6],
            [10, 9],
            [6, 14],
            [-10, 4],
            [2, 9],
            [12, 3],
            [7, 9],
            [3, 22],
            [-6, 21],
            [8, 14],
            [-6, 27],
            [12, 20],
            [0, 17],
            [12, 17],
            [-4, 12],
            [0, 31],
            [2, 20],
            [-8, 24],
            [1, 16],
            [3, 10],
            [-2, 7],
            [-7, 2],
            [-23, 25],
            [-2, 7],
            [-10, 8],
            [-11, 0],
            [-1, -4],
            [-14, 0],
            [-27, -12],
            [-11, 3],
            [-15, -3],
            [-17, -7],
            [-13, -3],
            [-19, -8],
            [-27, -9],
            [-34, -21],
            [-8, -8],
            [-32, -25],
            [-18, -18],
            [-7, -8],
            [-32, -31],
            [-12, -15],
            [-11, -9],
            [-7, -4],
            [-24, 4],
            [-15, -3],
            [-6, 1],
            [-7, -8],
            [-10, -5],
            [-9, 1],
            [-6, -5],
            [-11, -2],
            [-2, 6],
            [-9, -6],
            [-6, 6],
            [-5, 12],
            [3, 14],
            [-6, 4],
            [-2, 8],
            [-1, 16],
            [-8, 13],
            [-9, 3],
            [-1, 13],
            [3, 4],
            [-5, 7],
            [-18, 6],
            [-6, 11],
            [3, 6],
            [-15, 14],
            [-3, 28],
            [-3, 12],
            [1, 12],
            [5, 14],
            [1, 24],
            [-5, 19],
            [5, 15],
            [-10, 9],
            [-4, 10],
            [-10, 6],
            [-35, 15],
            [-8, 1],
            [-2, 5],
            [-10, 0],
            [-2, 9],
            [3, 5],
            [-3, 9],
            [-10, 2],
            [-6, 9],
        ],
        [
            [35589, 5241],
            [-1, 13],
            [13, -4],
            [8, 2],
            [14, -3],
            [12, 5],
            [5, 4],
            [3, 11],
            [13, -7],
            [5, -8],
            [-1, -8],
            [6, -5],
            [6, 1],
            [3, -12],
            [14, -8],
            [6, 13],
            [10, 0],
            [9, -3],
            [10, 1],
            [5, -8],
            [0, -7],
            [-6, -3],
            [5, -10],
            [-4, -6],
            [4, -8],
            [14, 8],
            [6, 18],
            [7, -1],
            [4, 16],
            [11, 10],
            [6, 9],
            [-1, 12],
            [-4, 12],
            [-8, 9],
            [5, 5],
            [17, -16],
            [1, -6],
            [9, -10],
            [-2, -8],
            [9, -15],
            [20, -5],
            [10, 0],
            [6, 10],
            [7, 2],
            [7, 18],
            [9, 5],
            [11, -1],
            [6, -5],
            [13, 6],
            [3, -13],
            [5, -6],
            [10, -2],
            [8, 2],
            [9, -21],
            [3, -27],
            [-3, -11],
            [2, -7],
            [-6, -4],
            [4, -12],
            [-19, -7],
            [-14, -8],
            [2, -8],
            [-2, -19],
            [18, -6],
            [-5, -14],
            [3, -18],
            [-4, -9],
            [8, -7],
            [9, 12],
            [4, -8],
            [6, -3],
            [8, 11],
            [13, -3],
            [7, -5],
            [12, -4],
            [18, -14],
            [11, -5],
            [8, 0],
            [13, 8],
            [9, 2],
            [5, -13],
            [-1, -11],
            [6, 0],
            [0, -11],
            [9, -4],
            [11, 0],
            [19, 12],
            [12, -10],
            [8, -4],
            [-2, 12],
            [1, 15],
            [21, 2],
            [-4, 7],
            [2, 11],
            [-8, 0],
            [0, 16],
            [9, 5],
            [16, -12],
            [11, -20],
            [3, -12],
            [15, 17],
            [22, -18],
            [28, -30],
            [9, 12],
            [-9, 15],
            [0, 5],
            [22, 1],
            [3, 9],
            [-6, 12],
            [4, 14],
            [3, 16],
            [5, 11],
            [20, 11],
            [18, 2],
            [9, 10],
            [-9, 22],
            [10, 12],
            [0, 10],
            [-5, 9],
            [-4, 25],
            [7, 10],
            [-1, 14],
            [-8, 4],
            [-8, 9],
            [1, 16],
            [6, 10],
            [0, 11],
            [10, 12],
            [-4, 18],
            [-6, 6],
            [-3, 14],
            [-1, 19],
            [6, 7],
            [-6, 9],
            [-3, 12],
            [-10, 7],
            [-5, 17],
            [-5, 4],
            [-3, 17],
            [6, 15],
            [-1, 10],
            [6, 8],
            [-3, 13],
            [2, 12],
            [-7, 4],
            [1, 11],
            [-4, 7],
            [2, 13],
            [-6, 19],
            [7, 10],
            [1, 18],
            [5, 5],
            [-1, 12],
            [6, 8],
            [3, 19],
            [-3, 8],
            [3, 13],
            [-5, 9],
            [2, 7],
            [-2, 11],
            [-8, 3],
            [-3, 11],
            [4, 11],
            [-1, 8],
            [3, 11],
            [7, 7],
            [5, 14],
            [7, 3],
            [3, 7],
            [-5, 11],
            [3, 16],
            [5, 7],
            [1, 9],
            [-6, 13],
            [3, 20],
            [-2, 9],
            [2, 11],
            [25, 21],
            [4, 9],
            [16, 14],
            [13, 7],
            [15, 3],
            [11, -9],
            [23, 3],
            [5, 9],
            [16, 5],
            [7, 9],
            [11, 6],
            [13, -5],
            [9, 2],
            [10, -9],
            [1, -5],
            [14, -15],
            [13, 2],
            [12, 5],
            [8, -2],
            [21, -12],
            [13, 0],
            [4, 6],
            [10, 3],
            [12, -2],
            [2, 23],
            [-3, 5],
            [1, 24],
            [-17, 40],
            [2, 6],
            [-7, 4],
            [2, 6],
            [-6, 6],
            [-10, 21],
            [-5, 20],
            [-3, 0],
            [-7, 18],
            [-41, 96],
            [9, 285],
            [6, 101],
            [1, 25],
            [5, 90],
            [1, 46],
            [0, 44],
            [-1, 48],
            [-8, 9],
            [-4, -1],
            [-25, 7],
            [-14, 7],
            [-14, 12],
            [-9, -3],
            [-6, -6],
            [-7, 3],
            [-7, -4],
            [-1, -13],
            [4, -6],
            [-22, -7],
            [-5, -6],
            [6, -9],
            [-7, -7],
            [-6, 6],
            [-7, 0],
            [0, -10],
            [8, 0],
            [1, -8],
            [-5, -7],
            [-7, 5],
            [-7, -14],
            [-13, 6],
            [-5, -5],
            [5, -12],
            [-3, -6],
            [-10, -1],
            [-5, 7],
            [-6, -3],
            [-2, -14],
            [8, -15],
            [-5, -8],
            [5, -6],
            [-8, -7],
            [0, -9],
            [-7, -7],
            [-8, -18],
            [-5, -5],
            [-9, 6],
            [-6, -13],
            [6, -11],
            [2, -14],
            [9, -5],
            [1, -8],
            [-5, -5],
            [-9, 5],
            [-4, -10],
            [-8, -8],
            [-2, -10],
            [7, -5],
            [-9, -14],
            [5, -7],
            [-7, -6],
            [2, -7],
            [-13, 3],
            [-4, -8],
            [-14, -3],
            [-6, 6],
            [-6, -1],
            [-5, -8],
            [-9, -1],
            [-3, -10],
            [-15, -4],
            [-2, 7],
            [-9, -2],
            [-17, 6],
            [-9, 1],
            [1, 12],
            [12, 6],
            [6, 10],
            [9, -3],
            [9, 3],
            [12, 22],
            [0, 14],
            [9, 15],
            [-3, 9],
            [-6, 3],
            [-15, -5],
            [-4, 7],
            [5, 13],
            [-1, 9],
            [-9, 12],
            [6, 13],
            [7, 36],
            [0, 9],
            [-8, 19],
            [0, 14],
            [-4, 4],
            [-14, 10],
            [-11, -2],
            [-8, -9],
            [-9, -3],
            [-13, 7],
            [-12, 1],
            [-4, 8],
            [-8, 5],
            [-7, -4],
            [-10, 0],
            [-12, 9],
            [-26, 32],
            [-32, 35],
            [-14, 4],
            [-7, -4],
            [-4, 8],
            [-17, 5],
            [-11, 0],
            [-13, -12],
            [-5, -3],
            [-11, 4],
            [-6, -5],
            [-4, -9],
            [-6, 6],
            [-8, 2],
            [-10, 12],
            [-13, 2],
            [-10, -1],
            [-12, 7],
            [-15, 16],
            [-10, 7],
            [-16, 1],
            [-20, -6],
            [-10, 4],
            [-7, -1],
            [-12, 7],
            [-17, -1],
            [-5, 10],
            [-11, -1],
            [-12, 5],
            [-16, -5],
            [-19, 0],
            [-2, -3],
            [-25, 7],
            [-8, -2],
            [-3, -6],
            [-14, -2],
            [-16, -7],
            [-39, 1],
            [-20, 116],
            [-44, 18],
            [1, 12],
            [-13, 6],
            [-9, -1],
            [-15, -8],
            [-36, 71],
            [-12, 11],
            [-12, -1],
            [-2, 4],
            [1, 13],
            [-3, 7],
            [-6, 24],
            [9, 22],
            [7, 15],
            [-3, 14],
            [-9, 11],
            [3, 9],
            [20, 30],
            [5, 16],
            [13, 7],
            [7, 14],
            [17, 50],
            [10, 21],
            [10, 17],
            [26, 38],
            [23, 12],
            [-7, 15],
            [4, 7],
            [7, 1],
            [19, -7],
            [14, 6],
            [9, 13],
            [1, 30],
            [-7, 6],
            [-14, 2],
            [-57, 3],
            [2, 9],
            [-2, 25],
            [-7, 7],
            [-5, -2],
            [-14, 7],
            [-18, 13],
            [-2, 19],
            [-5, 8],
            [3, 7],
            [-2, 24],
            [4, 28],
            [-3, 24],
            [2, 5],
            [-7, 14],
            [3, 12],
            [-15, 13],
            [-42, 41],
            [-21, 22],
            [-14, 24],
            [-3, 35],
            [3, 18],
            [-1, 5],
            [-26, 19],
            [-28, 15],
            [5, 7],
            [4, 13],
            [-1, 13],
            [-13, 6],
            [-3, 9],
            [6, 11],
            [0, 12],
            [6, 6],
            [2, 14],
            [6, 3],
            [3, 22],
            [8, 8],
            [9, 0],
            [9, 4],
            [10, 18],
            [-5, 15],
            [6, 15],
            [-3, 16],
            [-8, 7],
            [-13, 21],
            [-4, 17],
            [-6, 7],
            [-5, 17],
            [-13, 11],
            [-2, 13],
            [-6, 11],
            [4, 13],
            [-1, 9],
            [4, 5],
            [-3, 10],
            [-1, 16],
            [-19, 9],
            [-8, 3],
            [-1, 10],
            [-8, 7],
            [4, 14],
            [-3, 4],
            [2, 34],
            [5, 4],
            [1, 26],
            [3, 11],
            [-1, 9],
            [15, 21],
            [7, 1],
            [7, 25],
            [5, 9],
            [13, 13],
            [4, 13],
            [11, 5],
            [6, 10],
            [12, 6],
            [4, 13],
            [-3, 16],
            [-8, 8],
            [-8, 12],
            [-15, 14],
            [-36, 51],
            [-6, 19],
            [-7, 6],
            [-12, 4],
            [-18, 22],
            [-30, 18],
            [-23, 8],
            [-11, 8],
            [-27, 18],
            [-28, 7],
            [-15, 18],
            [-2, 7],
            [-13, 2],
            [-9, 15],
            [-15, 8],
            [-32, 8],
            [-16, -1],
            [-20, 7],
            [-6, -2],
            [-11, -12],
            [-26, -14],
            [-8, -2],
            [-36, -16],
        ],
        [
            [34940, 8689],
            [5, 13],
            [-2, 7],
            [4, 10],
            [-4, 6],
            [6, 11],
            [10, 42],
            [7, -1],
            [16, 9],
            [5, 9],
            [0, 7],
            [10, 8],
            [7, -4],
            [12, 2],
            [5, 5],
            [15, 7],
            [12, -7],
            [6, 0],
            [24, 13],
            [6, -4],
            [21, 8],
            [13, 0],
            [10, 5],
            [13, -2],
            [4, 11],
            [12, 5],
            [48, 2],
            [9, -6],
            [10, 2],
            [8, -6],
            [6, 3],
            [9, -1],
            [12, 6],
            [3, 8],
            [8, 2],
            [2, 14],
            [11, -2],
            [16, 5],
            [11, 13],
            [0, 12],
            [4, 6],
            [18, 3],
            [17, -1],
            [-1, 10],
            [20, 2],
            [22, -3],
            [19, 11],
            [21, -2],
            [20, -1],
            [31, -5],
            [17, -2],
            [19, 1],
            [13, 5],
        ],
        [
            [36676, 6585],
            [-2, -9],
            [2, -13],
            [-1, -9],
            [9, -16],
            [-1, -14],
            [4, -4],
            [-4, -22],
            [14, -18],
            [-3, -9],
            [2, -10],
            [10, -1],
            [3, -6],
            [25, -10],
            [10, 0],
            [5, -7],
            [-3, -7],
            [10, -11],
            [-1, -14],
            [-5, 0],
            [0, -11],
            [4, -7],
            [-1, -8],
            [2, -16],
            [15, -4],
            [4, -11],
            [12, -4],
            [2, -9],
            [12, -4],
            [18, 1],
            [17, -12],
            [2, -15],
            [-4, -17],
            [-13, -14],
            [2, -14],
            [9, -1],
            [11, 3],
            [-1, 12],
            [-6, 13],
            [7, 17],
            [-5, 17],
            [3, 9],
            [9, 7],
            [4, 12],
            [2, 17],
            [4, 3],
            [7, 23],
            [-6, 21],
            [-13, 24],
            [-8, 24],
            [-5, 23],
            [-9, 7],
            [7, -25],
            [5, -6],
            [-2, -10],
            [5, -15],
            [12, -25],
            [-8, -16],
            [-2, -25],
            [6, -9],
            [-1, -9],
            [-7, -9],
            [-10, -5],
            [-8, 1],
            [-12, 8],
            [-3, 14],
            [-7, 18],
            [-13, 22],
            [-6, 16],
            [-14, 27],
            [-22, 53],
            [-2, 10],
            [3, 5],
            [-2, 15],
            [-5, 36],
            [-1, 39],
            [-5, 14],
            [-8, 7],
            [-9, -1],
            [-5, 7],
            [-14, 2],
            [-8, 6],
            [-8, -16],
            [3, -11],
            [-7, -15],
            [-1, -9],
        ],
        [
            [37891, 4509],
            [-5, 8],
            [2, 8],
            [-6, 14],
            [0, 12],
            [-5, 6],
            [-7, 25],
            [-1, 12],
            [-7, 3],
            [4, -18],
            [10, -29],
            [-5, -2],
            [3, -14],
            [5, -4],
            [6, -23],
            [6, 2],
        ],
        [
            [34234, 5522],
            [-12, 4],
            [-15, 3],
            [-10, 7],
            [-9, 2],
            [-4, 8],
            [0, 12],
            [-10, 6],
            [1, 12],
            [-3, 16],
            [-6, 9],
            [-22, 16],
            [15, 16],
            [5, 27],
            [7, 19],
            [3, 29],
            [-7, 20],
            [-6, 11],
            [-1, 15],
            [-15, 10],
            [-3, 11],
            [-6, 5],
            [-4, 11],
            [-5, 24],
            [0, 17],
            [-6, 27],
            [1, 27],
            [-2, 10],
            [-8, 0],
            [-7, 7],
            [-4, 13],
            [-6, -4],
            [-8, 10],
            [-12, -1],
            [-17, -10],
            [0, -7],
            [-9, -7],
            [-18, 6],
            [-7, 6],
            [-6, -2],
            [-9, 4],
            [-8, -3],
            [5, 17],
            [14, 11],
            [4, 6],
            [0, 14],
            [-8, 28],
            [-1, 8],
            [5, 14],
            [-2, 12],
            [-7, 17],
            [-2, 11],
            [-7, 17],
            [-7, 9],
            [0, 6],
            [9, 22],
            [4, 53],
            [-1, 27],
            [-8, 2],
            [-17, 22],
            [-14, 13],
            [-13, -5],
            [-14, -2],
            [-7, -15],
            [-21, 7],
            [-11, 15],
            [-20, -1],
            [-14, 5],
            [-12, 23],
            [-1, 6],
            [-10, -1],
            [-11, 9],
            [-7, 13],
            [-16, 19],
            [-14, 5],
            [-6, -1],
            [-8, 15],
            [-9, 7],
            [-7, -1],
            [-2, 7],
            [-9, 4],
            [1, 5],
            [-16, 12],
            [-10, 27],
            [-4, 1],
            [-5, 14],
            [-11, 0],
            [-3, 6],
            [-9, 7],
            [-9, -1],
            [-8, -6],
            [-12, 0],
            [-10, 8],
            [-9, 1],
            [-8, -6],
            [-8, 6],
            [-8, -7],
            [-9, -2],
            [-12, 3],
            [-19, -5],
            [-12, 5],
            [-6, 7],
            [-3, 15],
            [-21, 20],
            [-3, 4],
            [-27, 8],
            [-11, 4],
            [-9, 7],
            [-23, 7],
            [-25, 2],
            [-18, 8],
            [-15, 23],
            [-4, 2],
            [-19, -1],
            [-9, -5],
            [-10, 3],
            [-8, -4],
            [-9, 4],
            [-3, 16],
            [2, 8],
            [-14, 7],
            [-22, -2],
            [-14, 2],
            [-19, 8],
            [-8, 9],
            [-7, 5],
            [-6, 9],
            [-12, 5],
            [-23, 2],
            [-19, 9],
            [-22, 2],
            [-11, 3],
            [-6, 11],
            [-21, 8],
            [-7, 7],
            [3, 9],
            [-14, 11],
            [-15, 3],
            [-5, 9],
            [-4, 19],
            [-5, 6],
            [-24, 4],
            [-12, 18],
            [-6, 17],
            [-19, -2],
            [-22, -9],
            [-15, 4],
            [-4, 5],
            [-14, 7],
            [-10, 6],
            [-30, 27],
            [-6, 1],
            [-7, -4],
            [-6, -11],
            [-11, -13],
            [-12, -6],
            [-14, 0],
            [-31, 12],
            [-21, -3],
            [-2, 4],
            [1, 27],
            [-10, 47],
            [-30, 55],
            [-7, 10],
            [0, 6],
            [6, 21],
            [-15, 53],
            [5, 14],
            [-15, 47],
            [-2, 34],
            [-2, 6],
            [-20, 30],
            [-10, 8],
            [-31, 45],
            [-2, 6],
            [3, 24],
            [6, 19],
            [1, 30],
            [10, 9],
            [17, 5],
            [12, 0],
            [11, -4],
            [22, 4],
            [9, 19],
            [3, 17],
            [14, 5],
            [2, 8],
            [-9, 7],
            [2, 9],
            [20, 14],
            [8, 9],
            [3, 9],
            [19, 4],
            [0, 8],
            [14, 8],
            [7, -1],
            [6, 5],
            [1, 9],
            [8, 2],
            [0, 11],
            [14, 21],
            [21, 30],
            [26, 73],
            [4, 22],
            [-12, 29],
            [-16, 39],
            [0, 12],
        ],
        [
            [32931, 7586],
            [21, -6],
            [21, 3],
            [13, -1],
            [1, 11],
            [-1, 13],
            [3, 88],
            [2, 54],
            [-1, 5],
            [0, 41],
            [1, 39],
            [4, 63],
            [0, 18],
            [-27, 84],
            [-5, 11],
            [5, 7],
            [10, -13],
            [8, 1],
            [13, -6],
            [15, -13],
            [-1, -4],
            [13, -5],
            [7, 3],
            [32, -21],
            [12, 8],
            [13, -3],
            [11, -9],
            [14, 4],
            [6, -1],
            [11, -9],
            [14, 3],
            [12, -7],
            [23, 7],
            [54, 13],
            [132, 36],
            [26, 7],
            [71, 19],
            [26, 8],
            [4, 0],
            [4, -15],
            [-3, -8],
            [5, -9],
            [-5, -7],
            [7, -7],
            [0, -10],
            [-8, -4],
            [-6, -23],
            [5, -8],
            [8, -19],
            [-2, -6],
            [4, -10],
            [-4, -5],
            [-4, -17],
            [12, -3],
            [2, -6],
            [13, -15],
            [-1, -7],
            [12, -10],
            [11, -4],
            [12, 11],
            [12, -4],
            [4, -5],
            [24, -7],
            [7, -9],
            [-2, -19],
            [0, -10],
            [8, -17],
            [3, -10],
            [8, -4],
            [-4, -16],
            [11, -5],
            [4, -5],
            [-1, -11],
            [5, -16],
            [-5, -8],
            [5, -13],
            [5, -8],
            [17, 2],
            [23, -9],
            [7, 2],
            [14, -9],
            [4, 0],
            [3, -14],
            [12, -3],
            [24, -2],
            [12, -4],
            [20, -2],
            [10, 4],
            [-2, 12],
            [5, 12],
            [8, 11],
            [5, 16],
            [15, 11],
            [3, 7],
            [22, 10],
            [8, -7],
            [7, 0],
            [12, 4],
            [15, 13],
            [9, 3],
            [6, 20],
            [1, 11],
            [-9, 15],
            [1, 4],
            [11, 7],
            [-1, 15],
            [-10, 15],
            [0, 9],
            [5, 14],
            [-1, 8],
            [5, 6],
            [13, 3],
            [11, 10],
            [25, 19],
            [3, 11],
            [10, 16],
            [7, 16],
            [6, 26],
            [4, 6],
            [10, 0],
            [8, 8],
            [8, -3],
            [11, 7],
            [5, 24],
            [6, 4],
            [30, 27],
            [17, 19],
            [10, 8],
            [16, 0],
            [2, -5],
            [11, -2],
            [7, -7],
            [21, -8],
            [12, -11],
            [7, -3],
            [7, -9],
            [15, -13],
            [7, -2],
            [2, -7],
            [8, -5],
            [5, 3],
            [16, -6],
            [2, -5],
            [18, -9],
            [5, -9],
            [11, 0],
            [9, 6],
            [18, -5],
            [4, 3],
            [11, 19],
            [3, 7],
            [9, 14],
            [11, 8],
            [18, 7],
            [11, 1],
            [11, 10],
            [6, 12],
            [3, 15],
            [9, 15],
            [12, 7],
            [18, 4],
            [12, -1],
            [2, 5],
            [0, 28],
            [4, 15],
            [6, 5],
            [41, 12],
            [6, 3],
            [15, 25],
            [10, 30],
            [8, 10],
            [12, 7],
            [24, 7],
            [8, 6],
            [8, 20],
            [7, 22],
            [7, 11],
            [12, 14],
            [16, 19],
            [33, 14],
            [5, 6],
            [8, 12],
            [1, 13],
            [-3, 9],
            [-24, 26],
            [-13, 11],
            [-10, 12],
            [-8, 23],
            [-1, 15],
            [-3, 7],
            [-13, 15],
            [-57, 8],
            [-31, 12],
            [-6, 6],
            [-3, 12],
            [6, 23],
            [9, 31],
            [45, -1],
            [17, 0],
            [58, -1],
            [17, -3],
            [54, 8],
            [16, 5],
            [18, 11],
            [29, 25],
            [0, 3],
            [26, 16],
            [28, 10],
            [17, 12],
            [9, 1],
            [13, -5],
            [12, 1],
            [10, 5],
            [20, 2],
            [53, 15],
        ],
        [
            [32298, 6564],
            [9, -8],
            [-1, -10],
            [10, -10],
            [1, -6],
            [-6, -5],
            [-6, 9],
            [-7, 30],
        ],
        [
            [32140, 6556],
            [-1, 13],
            [8, -3],
            [-7, -10],
        ],
        [
            [32224, 6605],
            [-2, -5],
            [-35, -22],
            [29, 23],
            [8, 4],
        ],
        [
            [32228, 6844],
            [6, -9],
            [9, -4],
            [-11, -14],
            [-10, 3],
            [-9, 11],
            [-1, 10],
            [7, 13],
            [9, -10],
        ],
        [
            [32175, 6865],
            [19, -1],
            [-3, -6],
            [-7, -5],
            [-9, 12],
        ],
        [
            [32273, 6943],
            [2, -17],
            [-10, -2],
            [0, 14],
            [8, 5],
        ],
        [
            [32290, 7040],
            [14, -19],
            [-6, -15],
            [9, -15],
            [5, -1],
            [9, 3],
            [0, -7],
            [-13, -5],
            [-7, 5],
            [-6, 21],
            [-5, 11],
            [-2, 17],
            [2, 5],
        ],
        [
            [32249, 7647],
            [-2, -11],
            [-7, -10],
            [6, -14],
            [-1, -11],
            [4, -16],
            [5, -2],
            [-4, -17],
            [-5, -14],
            [1, -16],
            [-7, -2],
            [-1, -19],
            [-6, -11],
            [3, -5],
            [-7, -10],
            [8, -20],
            [0, -15],
            [4, -12],
            [6, -5],
            [1, -12],
            [14, -4],
            [-3, -5],
            [0, -12],
            [13, -3],
            [0, -8],
            [7, -19],
            [4, -3],
            [25, -12],
            [-1, -25],
            [3, -4],
            [-2, -13],
            [3, -9],
            [-8, -17],
            [3, -11],
            [-8, -13],
            [1, 28],
            [-2, 10],
            [9, 20],
            [-5, 25],
            [-5, 4],
            [-13, 8],
            [-9, 11],
            [-5, 13],
            [-14, 15],
            [-16, 32],
            [-11, 29],
            [-11, 53],
            [5, 14],
            [11, 12],
            [2, 14],
            [6, 15],
            [4, 17],
            [-3, 15],
            [1, 13],
            [-8, 16],
            [6, 4],
            [2, 9],
            [7, 3],
        ],
        [
            [34011, 4181],
            [-21, 6],
            [4, 22],
            [-1, 7],
            [4, 18],
            [-14, 28],
            [-12, 9],
            [-18, 6],
            [-15, 12],
            [-8, 10],
            [-17, -5],
            [-15, -2],
            [-9, -7],
            [-25, -6],
            [-30, -10],
            [-29, 2],
            [-5, -15],
            [13, -12],
            [7, -17],
            [8, -31],
            [0, -12],
            [-31, -12],
            [-5, -5],
            [4, -20],
            [11, -8],
            [6, -7],
            [-4, -14],
            [-15, -15],
            [-13, -24],
            [-18, 6],
            [-23, 14],
            [-10, 1],
            [-5, -15],
            [-9, -7],
            [-11, 5],
            [-9, -7],
            [-17, -6],
            [-34, -8],
            [-6, -1],
            [-23, 10],
            [-14, -9],
            [-20, -9],
            [-11, -11],
            [-26, -13],
            [-13, -13],
            [-10, -5],
            [-11, -11],
            [-7, -4],
            [-10, -9],
            [-15, -10],
            [-6, -8],
            [-36, -23],
            [-10, -12],
            [-19, -33],
            [-16, -5],
            [-8, 0],
        ],
        [
            [33384, 3896],
            [-13, 14],
            [-6, 2],
            [-9, -7],
            [-7, 6],
            [-2, 12],
            [-12, 11],
            [-10, 18],
            [-14, 12],
            [-4, 14],
            [-12, 9],
            [-14, 3],
            [-11, 9],
            [0, 4],
            [-10, 9],
            [-11, 5],
            [-10, 11],
            [-2, 6],
            [12, 10],
            [4, 12],
            [-11, 7],
            [-13, 13],
            [-4, 10],
            [-25, 15],
            [-17, 5],
            [-10, -11],
            [-20, -8],
            [-16, -9],
            [-8, -10],
            [-4, -11],
            [-10, -4],
            [-12, -13],
            [-10, -4],
            [-10, -8],
            [-11, 3],
            [-4, -6],
            [1, -10],
            [-6, -6],
            [-37, -25],
            [-6, -6],
            [-16, -7],
            [-15, 2],
            [-1, 14],
            [-3, 7],
            [-13, 9],
            [-2, 13],
            [-11, 3],
            [-10, 8],
            [-7, 1],
            [-16, -4],
            [-7, 22],
            [-9, 5],
            [-2, 8],
            [7, 5],
            [-4, 6],
            [-14, -1],
            [-16, -9],
            [-4, 8],
            [-12, 2],
            [-4, 11],
            [-8, -7],
            [-1, -12],
            [-13, -1],
            [-6, -3],
            [-7, -11],
            [-14, -4],
            [-6, -5],
            [8, -10],
            [-14, -6],
            [-14, -1],
            [-4, -7],
            [-5, -16],
            [-5, -3],
            [-11, 3],
            [-8, -10],
            [-13, 2],
            [-7, 7],
            [-10, 4],
            [-6, -13],
            [-13, 2],
            [-3, -8],
            [3, -6],
            [12, -8],
            [-5, -7],
            [-14, 2],
            [-6, -8],
            [0, -8],
            [-4, -5],
            [-13, 9],
            [-9, 10],
            [-6, -7],
            [-11, 3],
            [-8, 10],
            [-9, 5],
            [-6, -4],
            [4, -10],
            [11, -13],
            [-1, -6],
            [-8, -5],
            [-3, 3],
            [2, 12],
            [-4, 5],
            [-13, -5],
            [-9, 2],
            [-2, -8],
            [5, -10],
            [-5, -6],
            [-13, -6],
            [-5, -4],
            [-7, 2],
            [-2, 9],
            [10, 14],
            [1, 15],
            [-7, 1],
            [2, -10],
            [-11, -5],
            [-8, 20],
            [-5, -2],
            [1, -11],
            [-4, -6],
            [-19, -4],
            [-15, 1],
            [-4, 4],
            [-8, 15],
            [-15, 7],
            [-11, -3],
            [-4, -7],
            [-1, -10],
            [-9, -13],
            [-18, -4],
            [-6, 5],
            [-4, 11],
            [-5, 5],
            [-8, -2],
            [2, -7],
            [-6, -5],
            [-26, 2],
        ],
        [
            [32397, 3939],
            [-5, 34],
            [0, 14],
            [-3, 22],
            [-11, 60],
            [-7, 39],
            [-6, 37],
            [-10, 53],
            [-13, 70],
            [-3, 24],
            [-8, 37],
            [-18, 69],
            [-9, 47],
            [-14, 52],
            [-8, 44],
            [-5, 21],
            [-10, 76],
            [-13, 72],
            [-3, 22],
            [-1, 32],
            [-6, 106],
            [0, 40],
            [17, 4],
            [8, 8],
            [8, 19],
            [4, 16],
            [3, 41],
            [-1, 21],
            [-3, 53],
            [0, 25],
            [1, 23],
            [0, 17],
            [-3, 26],
            [-5, 36],
            [-4, 32],
            [-8, 41],
            [-18, 107],
            [-22, 101],
            [-6, 25],
            [-9, 28],
            [-13, 45],
            [-19, 85],
            [-8, 43],
            [-12, 51],
            [-15, 59],
            [-10, 46],
            [-22, 70],
            [-7, 18],
            [-10, 31],
            [-11, 44],
            [-13, 53],
            [-17, 57],
            [-28, 85],
            [0, 26],
            [3, 15],
            [1, 18],
            [0, 37],
            [-5, 33],
            [-12, 32],
            [1, 10],
            [5, 5],
            [4, 13],
            [2, 74],
            [-2, 27],
            [-5, 18],
            [1, 16],
            [-5, 18],
            [-12, 29],
            [-4, 14],
            [-2, 19],
            [-4, 12],
            [-4, 22],
            [1, 9],
            [11, 14],
            [13, 14],
            [7, 0],
            [10, -7],
            [13, 0],
            [8, 2],
            [14, 11],
            [15, 16],
            [10, -1],
            [7, 7],
            [10, 18],
            [8, 25],
            [5, 38],
            [2, 23],
            [7, 24],
            [0, 8],
            [5, 15],
            [8, 12],
            [4, 12],
            [21, 36],
            [8, 24],
            [3, 0],
            [1, 20],
            [9, 30],
            [16, 33],
            [8, 27],
            [1, 7],
            [-3, 16],
            [2, 36],
            [5, 23],
            [7, 8],
            [8, -13],
            [14, -13],
            [-3, -17],
            [-6, -21],
            [-6, -5],
            [-11, -23],
            [4, -9],
            [-12, -15],
            [1, -7],
            [-5, -14],
            [1, -8],
            [-11, 2],
            [-4, -9],
            [0, -17],
            [-5, -5],
            [2, -8],
            [-2, -7],
            [-9, -7],
            [-6, -18],
            [-1, -11],
            [21, 1],
            [-3, -6],
            [-9, -2],
            [0, -7],
            [-11, -5],
            [-1, -8],
            [-11, -7],
            [2, -6],
            [-3, -16],
            [6, -13],
            [8, -4],
            [-1, -8],
            [-10, -3],
            [-3, -15],
            [-7, -4],
            [0, -9],
            [13, 0],
            [9, 12],
            [-4, 9],
            [13, 5],
            [5, -12],
            [-11, -7],
            [1, -11],
            [-7, -18],
            [-8, 0],
            [-13, -17],
            [-10, 3],
            [-10, -3],
            [-9, 1],
            [-3, -17],
            [2, -6],
            [-5, -4],
            [-4, -22],
            [-8, -10],
            [3, -7],
            [12, 6],
            [8, 12],
            [7, 6],
            [8, 11],
            [12, 7],
            [17, 20],
            [2, 6],
            [6, 3],
            [12, 11],
            [-2, 9],
            [6, 2],
            [4, 8],
            [26, -4],
            [3, -5],
            [-12, -18],
            [-5, -14],
            [-4, -3],
            [-13, -17],
            [5, -16],
            [-1, -7],
            [9, -15],
            [0, -21],
            [-3, -10],
            [-16, -5],
            [-4, 3],
            [-3, 14],
            [-13, 8],
            [-4, -8],
            [-13, -8],
            [-14, -25],
            [-9, -8],
            [4, -8],
            [-12, -4],
            [-4, -6],
            [7, -13],
            [-1, -7],
            [18, -14],
            [5, -6],
            [4, -17],
            [-12, -3],
            [-6, -17],
            [3, -15],
            [5, -6],
            [1, -7],
            [-7, -6],
            [-11, -16],
            [-1, -12],
            [5, -7],
            [-6, -7],
            [-6, -14],
            [-10, 0],
            [-7, -6],
            [-7, -11],
            [-1, -14],
            [4, -14],
            [-5, -10],
            [-1, -9],
            [15, -12],
            [5, -8],
            [5, -24],
            [-5, -28],
            [-5, 0],
            [-2, -17],
            [-2, -3],
            [0, -16],
            [16, -22],
            [-1, -11],
            [14, -14],
            [15, -7],
            [19, -5],
            [-24, -20],
            [-10, -12],
            [1, -21],
            [7, -18],
            [2, -17],
            [-7, -12],
            [-17, -35],
            [-1, -10],
            [14, -31],
            [7, -10],
            [2, -12],
            [5, -9],
            [13, -10],
            [39, -15],
            [31, -11],
            [22, -6],
            [23, -3],
            [20, 1],
            [27, -2],
            [15, -5],
            [10, 10],
            [18, 2],
            [-13, 14],
            [-7, 15],
            [4, 20],
            [17, 2],
            [5, 3],
            [4, 11],
            [12, 4],
            [-1, 11],
            [7, 2],
            [2, 16],
            [-7, 21],
            [-8, 18],
            [-19, 28],
            [-9, 11],
            [-35, 35],
            [-33, 32],
            [-9, 12],
            [2, 11],
            [6, 5],
            [12, -1],
            [3, 9],
            [-7, 12],
            [-15, 6],
            [10, 10],
            [11, -7],
            [11, 9],
            [5, 14],
            [7, 4],
            [5, 11],
            [13, 8],
            [2, 12],
            [5, 8],
            [1, 12],
            [7, 10],
            [-2, 21],
            [27, 16],
            [6, 11],
            [9, -1],
            [-2, 16],
            [2, 11],
            [-12, 20],
            [9, 11],
            [-1, 5],
            [-16, 20],
            [-9, 9],
            [-14, 4],
            [5, 15],
            [-6, 9],
            [5, 4],
            [1, 8],
            [-12, 17],
            [15, 0],
            [5, 3],
            [-5, 20],
            [-1, 12],
            [-4, 5],
            [2, 14],
            [-2, 11],
            [-10, 16],
            [-23, 22],
            [-27, 16],
            [12, 2],
            [4, 5],
            [16, -5],
            [-4, 9],
            [-9, 5],
            [-8, 12],
            [-15, 6],
            [-6, -9],
            [-16, 7],
            [24, 6],
            [20, 2],
            [13, 6],
            [4, 21],
            [4, 7],
            [2, 17],
            [5, 12],
            [5, 29],
            [6, 17],
            [7, 11],
            [6, 17],
            [13, 15],
            [14, 10],
            [0, 12],
            [-3, 19],
            [8, 22],
            [4, 2],
            [0, 14],
            [-11, 22],
            [2, 12],
            [7, 7],
            [10, 17],
            [-7, 11],
            [0, 7],
            [7, 15],
            [18, 14],
            [-3, 13],
            [-1, 65],
            [2, 6],
            [-2, 12],
            [4, 23],
            [-4, 10],
            [-2, 42],
            [0, 7],
            [-3, 12],
            [0, 16],
            [5, 15],
            [-1, 7],
            [9, 24],
            [2, 9],
            [9, 2],
            [11, 5],
            [8, 9],
            [9, 5],
            [6, 7],
            [2, 9],
            [0, 12],
            [-6, 23],
            [1, 20],
            [-2, 8],
            [7, 8],
            [2, 8],
            [2, 21],
            [-2, 6],
            [1, 22],
            [3, 6],
            [2, 26],
            [-1, 24],
            [4, 7],
            [0, 24],
            [7, 25],
            [9, 12],
            [5, -9],
            [8, -2],
            [16, 1],
            [10, 3],
            [8, 6],
            [17, 5],
            [11, 13],
            [16, 6],
            [16, -1],
            [10, 6],
            [8, 15],
            [2, 13],
            [-1, 18],
            [3, 4],
        ],
        [
            [32618, 7704],
            [20, -8],
            [8, -8],
            [8, -12],
            [16, -4],
            [4, 4],
            [12, 3],
            [6, 6],
            [5, -2],
            [4, -10],
            [14, -12],
            [12, -1],
            [9, -4],
            [12, 0],
            [14, 5],
            [11, 2],
            [10, -4],
            [21, -21],
            [6, -3],
            [17, 8],
            [8, 0],
            [18, -11],
            [8, -19],
            [15, -7],
            [13, -1],
            [13, -4],
            [8, -6],
            [21, -9],
        ],
        [
            [32607, 8734],
            [8, -19],
            [10, -1],
            [-2, -7],
            [-11, 12],
            [-5, 15],
        ],
        [
            [32573, 8781],
            [12, -6],
            [10, -32],
            [9, -8],
            [2, -8],
            [18, -26],
            [9, -16],
            [-3, -3],
            [-10, 18],
            [-14, 22],
            [-30, 47],
            [-3, 12],
        ],
        [
            [32570, 8852],
            [13, -10],
            [-3, -12],
            [-8, -14],
            [-7, -8],
            [-27, 24],
            [6, 7],
            [5, 10],
            [21, 3],
        ],
        [
            [32089, 9279],
            [0, -3],
            [27, -1],
            [40, -4],
            [75, -15],
            [29, -7],
            [35, -12],
            [20, -8],
            [35, -13],
            [47, -13],
            [20, -8],
            [16, -7],
            [26, -16],
            [38, -35],
            [19, -15],
            [-3, -2],
            [23, -25],
            [21, -17],
            [8, -8],
            [0, -6],
            [20, -23],
            [3, -11],
            [6, -3],
            [19, -17],
            [21, -12],
            [0, -7],
            [-20, 2],
            [-6, 3],
            [-22, 19],
            [-4, 7],
            [-8, 6],
            [-18, 9],
            [-47, 18],
            [-21, 11],
            [-7, -11],
            [15, -32],
            [24, -31],
            [34, -30],
            [6, -7],
            [11, -3],
            [0, -5],
            [16, -9],
            [17, -8],
            [30, -17],
            [3, -12],
            [-3, -9],
            [-10, 3],
            [-14, -3],
            [-14, 0],
            [-8, -8],
            [-20, -9],
            [-18, -7],
            [-4, -14],
            [-8, 2],
            [-15, 9],
            [-3, 11],
            [-8, -5],
            [-12, 21],
            [-25, 31],
            [-39, 43],
            [-47, 43],
            [-16, 13],
            [-33, 26],
            [-29, 21],
            [-36, 24],
            [-43, 24],
            [-52, 26],
            [-40, 17],
            [-60, 20],
            [-33, 6],
            [-25, 4],
            [-8, -1],
            [-20, 3],
            [7, 6],
            [-5, 31],
            [1, 8],
            [5, 14],
            [-3, 3],
            [22, 9],
            [27, 7],
            [41, 6],
            [0, 3],
        ],
        [
            [32806, 9856],
            [5, -8],
            [-2, -6],
            [12, -21],
            [-4, -5],
            [3, -12],
            [13, -14],
            [1, 14],
            [9, 2],
            [4, -6],
            [11, -2],
            [3, -16],
            [-5, -8],
            [4, -8],
            [-4, -6],
            [-13, -10],
            [-8, 15],
            [-2, 15],
            [-17, 11],
            [-6, 2],
            [-12, -3],
            [-7, -8],
            [-11, -4],
            [-8, 5],
            [-9, 16],
            [-1, 17],
            [6, 11],
            [14, 3],
            [7, 7],
            [10, 18],
            [7, 1],
        ],
        [
            [33045, 9901],
            [25, -4],
            [8, -16],
            [10, -6],
            [-4, -5],
            [-23, 3],
            [-18, 20],
            [2, 8],
        ],
        [
            [32996, 9970],
            [2, -13],
            [6, -4],
            [-5, -15],
            [0, -15],
            [-12, 7],
            [-3, 16],
            [4, 21],
            [8, 3],
        ],
        [
            [31527, 10078],
            [-11, 3],
            [-7, 10],
            [-2, 7],
            [5, 9],
            [8, 0],
            [11, -13],
            [-1, -12],
            [-3, -4],
        ],
        [
            [33642, 10325],
            [13, -5],
            [2, -8],
            [-4, -7],
            [-8, 1],
            [-3, 19],
        ],
        [
            [33576, 10323],
            [-7, -6],
            [-7, 1],
            [-1, 7],
            [7, 7],
            [8, -9],
        ],
        [
            [33654, 10337],
            [4, -9],
            [-11, 1],
            [7, 8],
        ],
        [
            [33684, 10370],
            [10, -14],
            [30, -25],
            [-5, -6],
            [-8, 6],
            [2, 5],
            [-5, 6],
            [-13, 9],
            [-10, 15],
            [-1, 4],
        ],
        [
            [33414, 10523],
            [9, 0],
            [1, -6],
            [-10, 0],
            [0, 6],
        ],
        [
            [31895, 10581],
            [2, -6],
            [15, -3],
            [10, 3],
            [24, -35],
            [3, -6],
            [11, -5],
            [2, -5],
            [18, -8],
            [4, -7],
            [16, -7],
            [34, 0],
            [22, 1],
            [13, -6],
            [6, -11],
            [3, -39],
            [5, -9],
            [3, -14],
            [0, -13],
            [6, -16],
            [-6, -26],
            [-14, -17],
            [-12, -8],
            [-20, 4],
            [-10, -1],
            [-37, 14],
            [-30, 16],
            [-18, 13],
            [-20, 13],
            [-37, 20],
            [-9, 10],
            [2, 21],
            [-6, 12],
            [1, 13],
            [-4, 3],
            [0, 23],
            [-5, 33],
            [1, 17],
            [3, 7],
            [10, 8],
            [0, 5],
            [14, 6],
        ],
        [
            [33376, 10711],
            [-2, -14],
            [-21, 0],
            [3, 10],
            [15, 9],
            [5, -5],
        ],
        [
            [33392, 10714],
            [-12, -7],
            [0, 7],
            [12, 0],
        ],
        [
            [33028, 10738],
            [7, -4],
            [11, -8],
            [-3, -5],
            [-20, 16],
            [5, 1],
        ],
        [
            [33370, 10749],
            [-1, -8],
            [5, -7],
            [-14, -10],
            [-7, -1],
            [-13, 8],
            [23, 18],
            [7, 0],
        ],
        [
            [33370, 10751],
            [-7, 0],
            [-12, -10],
            [-15, -6],
            [1, 9],
            [11, 2],
            [5, 10],
            [13, 5],
            [4, -10],
        ],
        [
            [32203, 10772],
            [9, -2],
            [0, -24],
            [-4, -23],
            [0, -21],
            [5, -5],
            [2, -14],
            [-4, -17],
            [-11, 0],
            [-14, 8],
            [-5, 5],
            [-2, 13],
            [2, 11],
            [-5, 26],
            [3, 19],
            [11, 16],
            [13, 8],
        ],
        [
            [32838, 10810],
            [17, -4],
            [2, -7],
            [-8, -4],
            [-11, 8],
            [0, 7],
        ],
        [
            [32437, 10810],
            [6, -7],
            [0, -8],
            [12, -8],
            [-3, -9],
            [1, -14],
            [-5, -3],
            [-16, 18],
            [1, 5],
            [-7, 20],
            [11, 6],
        ],
        [
            [33309, 10845],
            [5, -12],
            [-7, -9],
            [-9, 0],
            [-2, 8],
            [7, 11],
            [6, 2],
        ],
        [
            [32850, 10847],
            [11, 0],
            [17, -17],
            [-4, -1],
            [-14, 7],
            [-10, 7],
            [0, 4],
        ],
        [
            [32661, 10909],
            [-4, 13],
            [5, 11],
            [4, -2],
            [-5, -22],
        ],
        [
            [32236, 10961],
            [2, -6],
            [-3, -14],
            [4, -21],
            [-2, -6],
            [7, -13],
            [-11, -11],
            [-9, -13],
            [-15, -13],
            [8, -5],
            [3, -8],
            [-4, -14],
            [-13, 7],
            [1, 9],
            [-11, 8],
            [-8, 3],
            [-4, 11],
            [2, 10],
            [16, 38],
            [7, 11],
            [11, 8],
            [4, 11],
            [12, 9],
            [3, -1],
        ],
        [
            [32264, 10937],
            [-5, 10],
            [-1, 15],
            [6, 6],
            [0, -31],
        ],
        [
            [32320, 10969],
            [-1, -11],
            [-9, 3],
            [-4, 8],
            [-2, 18],
            [5, 26],
            [1, 11],
            [3, 11],
            [10, 12],
            [1, -15],
            [-9, -24],
            [3, -20],
            [3, -4],
            [-1, -15],
        ],
        [
            [32580, 11150],
            [5, -5],
            [3, -8],
            [-6, -5],
            [-5, 3],
            [-6, 11],
            [9, 4],
        ],
        [
            [32618, 7704],
            [0, 7],
            [-4, 5],
            [3, 20],
            [-5, 33],
            [10, 24],
            [11, 12],
            [7, 11],
            [7, 7],
            [7, 14],
            [21, 12],
            [6, 13],
            [11, 34],
            [-3, 17],
            [-9, 22],
            [0, 19],
            [3, 22],
            [10, 7],
            [5, 15],
            [2, 21],
            [5, 8],
            [1, 9],
            [-8, 25],
            [5, 9],
            [-4, 27],
            [-1, 20],
            [9, 20],
            [16, 16],
            [3, 14],
            [-19, 40],
            [-11, 18],
            [1, 2],
            [-20, 35],
            [-11, 32],
            [2, 5],
            [-18, 39],
            [-15, 24],
            [-19, 22],
            [-6, 9],
            [0, 10],
            [7, 2],
            [3, 11],
            [-14, 4],
            [-4, 13],
            [12, -1],
            [-3, 12],
            [3, 13],
            [2, 21],
            [3, 7],
            [-6, 3],
            [4, 7],
            [1, 13],
            [4, 7],
            [5, 32],
            [7, 21],
            [4, 21],
            [4, 7],
            [1, 19],
            [2, 7],
            [-1, 14],
            [-4, 26],
            [-7, 14],
            [10, 9],
            [8, -7],
            [-6, 29],
            [-6, -6],
            [-10, 9],
            [-8, 14],
            [3, 14],
            [-14, 14],
            [7, 2],
            [-2, 11],
            [-12, 10],
            [-1, 8],
            [-8, 2],
            [-2, 6],
            [-15, -5],
            [-8, 11],
            [6, 13],
            [14, -6],
            [42, -38],
            [-10, 28],
            [5, 11],
            [6, 4],
            [22, -5],
            [29, 2],
            [3, -4],
            [10, 5],
            [9, 0],
            [8, 20],
            [6, 3],
            [-6, 10],
            [19, 15],
            [7, 10],
            [11, 9],
            [15, 10],
            [10, -1],
            [8, 4],
            [8, -1],
            [13, 11],
            [5, 22],
            [7, 18],
            [-2, 13],
            [12, 4],
            [13, 9],
            [11, 3],
            [13, 13],
            [10, 16],
            [10, 5],
            [7, -1],
            [7, 7],
            [5, -1],
            [18, 8],
            [17, -5],
            [11, 3],
            [-2, 9],
            [10, 8],
            [7, 13],
            [11, 12],
            [1, 10],
            [5, 4],
            [0, 15],
            [6, 8],
            [12, 27],
            [9, 11],
            [10, 7],
            [8, 8],
            [6, 14],
            [0, 10],
            [10, 33],
            [-5, 7],
            [2, 23],
            [5, 3],
            [2, 14],
            [8, 5],
            [-4, 16],
            [0, 10],
            [4, 17],
            [-2, 9],
            [6, 12],
            [-7, 10],
            [4, 12],
            [6, -1],
            [1, 15],
            [8, 6],
            [-8, 4],
            [0, 6],
            [10, 13],
            [5, 10],
            [1, 11],
            [4, 5],
            [25, 5],
            [7, 16],
            [7, 9],
            [1, 26],
            [6, 9],
            [0, 13],
            [8, 11],
            [-4, 26],
            [3, 18],
            [5, 5],
            [7, -4],
            [4, 8],
            [19, 31],
            [-1, 16],
            [5, 9],
            [1, 13],
            [-10, 35],
            [1, 18],
            [11, 31],
            [-4, 24],
            [-26, 28],
            [-1, 12],
            [5, 14],
            [7, 14],
            [9, 9],
            [9, 4],
            [6, 17],
            [-2, 12],
            [-8, 11],
            [-11, 13],
            [-10, 8],
            [-12, 6],
            [-31, 22],
            [-12, 13],
            [-20, 12],
            [-12, -5],
            [-2, -7],
            [-26, 2],
            [-10, 4],
            [-10, 12],
            [-4, 14],
            [5, 14],
            [-2, 7],
            [-6, 5],
            [-1, 10],
            [-4, 4],
            [11, 18],
            [-4, 11],
            [-15, 12],
            [-5, 7],
            [-1, 12],
            [5, 15],
            [-6, 17],
            [0, 27],
            [4, 6],
            [19, 13],
            [20, 9],
            [10, 9],
            [16, 6],
            [18, 2],
            [10, 7],
            [29, 13],
            [13, 2],
            [15, 7],
            [9, 2],
            [15, 10],
            [13, 11],
            [4, 9],
            [39, 16],
            [27, 14],
            [14, 14],
            [11, 13],
            [8, 17],
            [22, 24],
            [7, 15],
            [14, 9],
            [15, 5],
            [7, 7],
            [8, 27],
            [1, 12],
            [-8, 17],
            [-15, 25],
            [-33, 45],
            [-19, 22],
            [-28, 28],
            [-26, 24],
            [-52, 44],
            [-17, 13],
            [-54, 40],
            [-26, 18],
            [-34, 20],
            [-48, 24],
            [-10, 6],
            [9, 14],
            [9, 7],
            [3, 15],
            [12, -8],
            [-9, -10],
            [0, -5],
            [9, -8],
            [9, -4],
            [8, 4],
            [11, -10],
            [9, -3],
            [8, 1],
            [13, -7],
            [18, -1],
            [8, -7],
            [6, -9],
            [9, -8],
            [5, 8],
            [9, 0],
            [8, -4],
            [-3, -8],
            [4, -10],
            [10, -7],
            [6, -10],
            [17, -2],
            [7, 10],
            [9, 1],
            [6, -5],
            [17, -22],
            [13, -23],
            [16, -3],
            [14, -13],
            [18, -8],
            [11, -6],
            [21, -16],
            [31, -12],
            [22, 12],
            [20, 3],
            [-2, -6],
            [5, -10],
            [8, 1],
            [10, -7],
            [14, -14],
            [18, -10],
            [17, 1],
            [8, 6],
            [5, 7],
            [7, -9],
            [40, -30],
            [12, -10],
            [26, -15],
            [14, -7],
            [2, 14],
            [12, -4],
            [26, -26],
            [5, -7],
            [16, -8],
            [4, -12],
            [-12, -7],
            [-8, 9],
            [-13, 0],
            [-2, 4],
            [-9, 1],
            [-12, 3],
            [-13, -1],
            [-14, 13],
            [-10, 7],
            [-3, -15],
            [2, -12],
            [5, -8],
            [8, -1],
            [3, -10],
            [6, -1],
            [4, -8],
            [10, -3],
            [9, -9],
            [19, -3],
            [16, -15],
            [-15, -1],
            [-9, 3],
            [-12, -1],
            [-1, -7],
            [-11, -1],
            [-8, -6],
            [-13, 7],
            [-6, -7],
            [-2, -9],
            [8, -2],
            [19, -13],
            [5, 1],
            [7, -8],
            [43, -17],
            [7, 2],
            [12, -5],
            [1, 7],
            [46, 6],
            [7, 5],
            [11, -1],
            [5, 7],
            [17, -3],
            [11, -10],
            [20, -8],
            [14, 5],
            [4, -1],
            [13, 23],
            [-7, 4],
            [3, 9],
            [6, 3],
            [11, -3],
            [16, -8],
            [9, 6],
            [11, 1],
            [9, -2],
            [13, 5],
            [17, 11],
            [8, 9],
            [21, 9],
            [12, 0],
            [11, 11],
            [6, -1],
            [3, 14],
            [-6, 5],
            [0, 15],
            [3, 12],
            [12, 14],
            [-2, 7],
            [-17, 27],
            [-7, 1],
            [-8, -6],
            [-7, 6],
            [-13, 23],
            [-22, 27],
            [-25, 29],
            [-9, 16],
            [-10, 7],
            [-4, 7],
            [-12, 9],
            [-32, 30],
            [-6, 1],
            [-48, 35],
            [-33, 22],
            [-12, 4],
            [-6, 7],
            [-24, 15],
            [-16, 7],
            [-34, 21],
            [-24, 16],
            [-19, 8],
            [-20, 16],
            [-63, 36],
            [-23, 11],
            [-32, 17],
            [-22, 6],
            [-13, 6],
            [-3, -2],
            [-12, 9],
            [-17, 11],
            [-31, 14],
            [-13, -4],
            [-8, 5],
            [-4, -3],
            [-7, -16],
            [-11, -5],
            [-1, -9],
            [18, -8],
            [-3, -9],
            [3, -7],
            [16, 1],
            [10, 3],
            [4, -9],
            [-6, -7],
            [5, -8],
            [-20, -14],
            [-12, 1],
            [-1, -8],
            [19, -8],
            [8, -1],
            [6, 4],
            [9, 2],
            [4, 7],
            [12, -3],
            [1, -5],
            [-5, -10],
            [-5, -14],
            [0, -9],
            [-9, -5],
            [-15, 1],
            [7, -14],
            [12, 3],
            [16, -7],
            [9, -1],
            [-6, -27],
            [13, 3],
            [13, -6],
            [-2, -9],
            [9, -4],
            [13, 3],
            [6, -4],
            [17, -4],
            [0, -4],
            [-11, -5],
            [-10, 2],
            [-1, -8],
            [-16, 11],
            [-41, 23],
            [-16, 11],
            [-32, 25],
            [-22, 20],
            [-6, 14],
            [-26, 21],
            [-17, 10],
            [-42, 21],
            [-26, 10],
            [-24, 12],
            [-30, 18],
            [-10, 4],
            [-13, 11],
            [-20, 8],
            [-5, -13],
            [8, -2],
            [4, -8],
            [-9, -3],
            [0, -7],
            [11, -10],
            [18, -4],
            [24, -13],
            [10, -16],
            [33, -18],
            [2, -12],
            [13, -14],
            [10, -6],
            [25, -8],
            [14, -3],
            [-12, -6],
            [-18, 6],
            [-19, 8],
            [-18, 6],
            [-12, 5],
            [-22, 8],
            [-9, 6],
            [-20, 15],
            [-17, 10],
            [-22, 22],
            [-9, 7],
            [-36, 20],
            [-19, 9],
            [-18, 6],
            [-13, -13],
            [-12, 4],
            [-3, -3],
            [-14, 3],
            [-12, 5],
            [-4, -2],
            [-12, 4],
            [-16, 13],
            [1, 8],
            [-17, 9],
            [-8, 7],
            [-27, -42],
            [11, -6],
            [12, -16],
            [1, -8],
            [-14, 5],
            [-3, -3],
            [0, -14],
            [20, -23],
            [-8, -25],
            [1, -9],
            [5, -8],
            [-8, -17],
            [-6, -7],
            [-17, -7],
            [-16, -10],
            [-13, 1],
            [-20, 7],
            [-24, 15],
            [16, 2],
            [13, -2],
            [10, 18],
            [6, 7],
            [-2, 8],
            [2, 13],
            [-10, -4],
            [-13, -12],
            [0, -8],
            [-14, 0],
            [-20, 4],
            [-29, 26],
            [-12, 7],
            [-35, 15],
            [-18, 5],
            [-18, 2],
            [-17, -2],
            [-9, -11],
            [-4, -18],
            [-11, -16],
            [-8, -3],
            [-13, 2],
            [-14, 10],
            [-9, 10],
            [-82, -48],
            [3, -8],
            [-10, -19],
            [-3, -16],
            [4, -11],
            [-4, -11],
            [5, -3],
            [-4, -18],
            [6, -19],
            [-11, -7],
            [-17, -3],
            [-21, 0],
            [-13, 3],
            [-20, 8],
            [-21, 2],
            [-38, 9],
            [-21, 7],
            [1, 33],
            [-4, -1],
            [-2, -18],
            [-4, -9],
            [-11, 4],
            [-11, 10],
            [2, 16],
            [-4, 9],
            [3, 15],
            [6, 5],
            [7, -7],
            [-2, -12],
            [10, -11],
            [5, -2],
            [13, 4],
            [9, 14],
            [2, 14],
            [-13, 7],
            [10, 12],
            [-3, 18],
            [3, 13],
            [-4, 4],
            [8, 21],
            [10, 4],
            [3, -13],
            [15, 10],
            [2, -11],
            [-5, -10],
            [7, -11],
            [-1, -8],
            [13, 7],
            [7, -5],
            [15, -5],
            [8, 7],
            [7, 2],
            [7, 7],
            [5, 9],
            [10, -4],
            [8, -9],
            [11, -6],
            [10, -9],
            [12, -7],
            [84, 48],
            [-6, 6],
            [-14, 8],
            [-14, 14],
            [-18, 8],
            [-4, -3],
            [-10, 11],
            [0, 16],
            [-8, 11],
            [-5, 12],
            [3, 11],
            [-2, 6],
            [-10, 1],
            [-11, 11],
            [-12, 16],
            [-4, 14],
            [-12, 13],
            [-8, 19],
            [4, 14],
            [12, 3],
            [-1, 8],
            [-6, 1],
            [-10, 13],
            [-6, 3],
            [-3, 9],
            [3, 6],
            [20, -7],
            [15, 1],
            [13, 7],
            [14, -3],
            [17, -25],
            [10, -2],
            [15, 4],
            [10, 11],
            [7, 4],
            [16, -9],
            [5, -20],
            [4, -6],
            [16, -9],
            [2, -8],
            [-10, -18],
            [3, -7],
            [12, -7],
            [7, 8],
            [11, 4],
            [10, -4],
            [20, 10],
            [-3, 9],
            [8, 1],
            [21, -3],
            [17, -8],
            [-6, -13],
            [2, -4],
            [-5, -9],
            [3, -14],
            [-18, 3],
            [-9, -6],
            [26, -15],
            [7, -8],
            [22, -17],
            [6, -6],
            [38, -25],
            [23, -13],
            [15, -4],
            [10, 0],
            [6, 4],
            [7, -3],
            [11, -11],
            [-3, -6],
            [4, -9],
            [8, 12],
            [2, 11],
            [-7, 18],
            [20, 24],
            [34, 52],
            [-17, 22],
            [-1, 5],
            [4, 13],
            [-4, 26],
            [-4, 7],
            [-14, 8],
            [-15, 0],
            [-4, -13],
            [-5, -5],
            [-11, 1],
            [3, 7],
            [-11, 5],
            [-17, 11],
            [-11, 15],
            [3, 9],
            [-4, 4],
            [-14, -9],
            [-15, -18],
            [-1, -8],
            [-15, 4],
            [-3, -5],
            [-11, -3],
            [-9, 9],
            [-1, 7],
            [-13, 13],
            [1, 17],
            [-5, 9],
            [-10, 10],
            [1, 15],
            [-4, 5],
            [6, 10],
            [-3, 11],
            [-12, 9],
            [-13, 23],
            [5, 8],
            [-14, 13],
            [-72, -10],
            [-14, -34],
            [0, -14],
            [4, -17],
            [7, -11],
            [-3, -7],
            [-21, -9],
            [-7, -11],
            [-7, -4],
            [-15, 1],
            [-11, 6],
            [-12, 2],
            [-8, 7],
            [-14, 3],
            [-17, 9],
            [-1, 7],
            [19, 18],
            [8, 11],
            [2, 13],
            [-1, 15],
            [-9, 19],
            [-5, 3],
            [-4, 14],
            [1, 10],
            [12, 19],
            [11, 13],
            [9, 1],
            [10, -3],
            [26, -1],
            [19, 4],
            [26, 12],
            [7, -2],
            [4, -7],
            [-14, -8],
            [6, -9],
            [2, -20],
            [-7, -4],
            [-2, -15],
            [2, -9],
            [69, 9],
            [1, 5],
            [-12, 10],
            [-6, 8],
            [-10, 3],
            [-10, 12],
            [-3, 11],
            [6, 14],
            [-4, 10],
            [12, 15],
            [9, 5],
            [11, 0],
            [13, -3],
            [19, 6],
            [9, 9],
            [9, 3],
            [20, 14],
            [4, 15],
            [8, 9],
            [23, 11],
            [27, 9],
            [18, 12],
            [9, 0],
            [11, 7],
            [21, 7],
            [5, 0],
            [17, 5],
            [9, -3],
            [18, 6],
            [11, 0],
            [16, 8],
            [21, 3],
            [12, -2],
            [11, 1],
            [3, -3],
            [24, 7],
            [35, -6],
            [4, -2],
            [23, -3],
            [27, -9],
            [6, 1],
            [24, -3],
            [10, -3],
            [22, 1],
            [20, -2],
            [29, 5],
            [17, 12],
            [34, 18],
            [13, -3],
            [15, 15],
            [15, 0],
            [10, 3],
            [59, -2],
            [32, 5],
            [20, 1],
            [14, 3],
            [34, 16],
            [14, 5],
            [15, -1],
            [27, -11],
            [22, -7],
            [12, -2],
            [25, 0],
            [10, -5],
            [9, -10],
            [3, -15],
            [18, -44],
            [20, -37],
            [33, -56],
            [25, -39],
            [20, -26],
            [28, -40],
            [16, -24],
            [14, -27],
            [6, -10],
            [29, -41],
            [28, -36],
            [59, -65],
            [27, -25],
            [45, -40],
            [44, -35],
            [15, -13],
            [52, -42],
            [20, -15],
            [19, -17],
            [59, -47],
            [39, -33],
            [27, -24],
            [64, -53],
            [56, -51],
            [45, -39],
            [46, -38],
            [59, -47],
            [17, -13],
            [13, -6],
            [29, -26],
            [30, -21],
            [21, -15],
            [16, -16],
            [67, -55],
            [24, -22],
            [30, -23],
            [26, -29],
            [17, -21],
            [35, -37],
            [46, -43],
            [16, -16],
            [26, -26],
            [19, -20],
            [32, -28],
            [34, -28],
            [37, -27],
            [19, -12],
            [21, -19],
            [9, -5],
            [20, -23],
            [13, -17],
            [14, -15],
            [15, -25],
            [4, -18],
            [10, -24],
            [45, -79],
            [18, -28],
            [7, -18],
            [9, -40],
            [17, -66],
            [10, -30],
            [11, -32],
            [35, -81],
            [15, -31],
            [8, -13],
            [4, -2],
            [22, -44],
            [15, -20],
            [5, -5],
            [11, -24],
            [19, -32],
            [11, -25],
            [24, -48],
            [15, -26],
            [16, -33],
            [30, -53],
            [41, -65],
            [21, -30],
        ],
        [
            [33287, 3723],
            [-6, 2],
            [5, 9],
            [14, 1],
            [4, -8],
            [-17, -4],
        ],
        [
            [35089, 2539],
            [6, -16],
            [1, -11],
            [-8, -22],
            [6, -15],
            [4, -19],
            [4, -9],
            [0, -11],
            [9, -5],
            [15, 11],
            [15, 4],
            [5, -3],
            [8, -23],
            [12, -19],
            [6, -2],
            [19, 0],
            [17, 5],
            [13, 0],
            [6, -14],
            [0, -11],
            [-8, -11],
            [-11, -9],
            [-2, -6],
            [8, -22],
            [-6, -6],
            [-18, -2],
            [-13, -7],
            [-14, -5],
            [-16, -9],
            [-34, -11],
            [-8, -7],
            [-2, -10],
            [5, -13],
            [6, -4],
            [16, -6],
            [5, -4],
            [5, -15],
            [-2, -17],
            [10, -34],
            [9, -1],
            [5, -4],
            [34, -2],
            [5, -2],
            [1, -11],
            [20, -13],
            [4, 1],
            [14, 23],
            [9, -10],
            [14, 3],
            [15, 0],
            [15, 3],
            [10, -1],
            [9, 11],
            [5, 15],
            [5, 6],
            [10, 3],
            [20, 2],
            [13, 3],
            [4, 18],
            [11, 4],
            [7, 11],
            [-4, 6],
            [2, 10],
            [8, 4],
            [6, -12],
            [14, -4],
            [8, -6],
            [-1, -6],
            [-9, -6],
            [-6, -9],
            [-12, -12],
            [-4, -8],
            [-11, -5],
            [0, -14],
            [-7, -9],
            [-7, -13],
            [0, -14],
            [-11, -11],
            [-1, -8],
            [6, -8],
            [-1, -14],
            [-6, -12],
            [-13, 0],
            [-6, -7],
            [-5, 0],
            [-9, -10],
            [-7, -13],
            [-1, -8],
            [-7, -9],
            [0, -5],
            [-14, -18],
            [-13, -5],
            [8, -19],
            [-10, -11],
            [4, -20],
            [-3, -5],
            [-10, 4],
            [-7, -1],
            [3, -11],
            [-3, -6],
            [9, -11],
            [-4, -9],
            [-15, -9],
            [3, -13],
            [-6, -5],
            [-3, -14],
            [-9, -18],
            [3, -6],
            [8, -2],
            [2, -13],
            [-6, -15],
            [-12, -21],
            [-4, -12],
            [-9, -10],
            [-5, -10],
            [-7, 0],
            [-14, -18],
            [-9, -20],
            [-17, -29],
            [-11, -20],
            [-4, -21],
            [-1, -8],
            [4, -16],
            [7, -17],
            [-2, -20],
            [3, -6],
            [13, -13],
            [6, -19],
            [-6, -26],
            [-1, -25],
            [3, -15],
            [6, -21],
            [-1, -7],
            [-7, -5],
            [0, -11],
            [11, -8],
            [5, -26],
            [0, -14],
            [-10, -1],
            [-4, -7],
            [7, -5],
            [13, -22],
            [-1, -19],
            [15, -2],
            [10, -14],
            [-1, -15],
            [8, -8],
            [11, -4],
            [4, -8],
            [2, -11],
            [8, -11],
            [1, -13],
            [4, -3],
            [12, 4],
            [14, -7],
            [13, 0],
            [9, -5],
            [16, -2],
            [12, -7],
            [9, -2],
            [5, -8],
            [8, -9],
            [-5, -16],
            [9, -6],
            [9, -2],
            [3, -9],
            [-8, -10],
            [-1, -12],
            [2, -7],
            [6, -4],
            [10, -14],
            [8, -15],
            [8, -22],
            [6, -13],
        ],
        [
            [35400, 1103],
            [7, -8],
            [12, -5],
            [11, -16],
            [8, -22],
            [-1, -20],
            [8, -5],
            [11, -1],
            [3, -8],
            [1, -12],
            [-2, -13],
            [17, -22],
            [1, -20],
            [5, -10],
            [10, -8],
            [1, -6],
            [-5, -7],
            [-7, -1],
            [-8, 5],
            [-17, -23],
            [-16, 10],
            [1, 11],
            [-6, 11],
            [-14, -4],
            [-17, 3],
            [-31, -2],
            [-11, 3],
            [-15, 16],
            [-11, 4],
            [-8, 7],
            [-13, 5],
            [-11, -4],
            [-10, -9],
            [-6, 6],
            [-8, 22],
            [-19, 21],
            [-33, 22],
            [-9, 5],
            [-8, -6],
            [-10, -3],
            [-4, 2],
            [-8, -4],
            [-8, 1],
            [-10, -3],
            [-14, 2],
            [-12, -8],
            [-12, -2],
            [-6, 6],
            [-17, -3],
            [-18, 0],
            [-11, 6],
            [-9, 1],
            [-11, -6],
            [-12, 1],
            [-4, 5],
            [-13, 5],
            [-11, 11],
            [-28, 7],
            [-12, 10],
            [-17, 2],
            [-15, 8],
            [-13, 3],
            [-10, 6],
            [-19, 7],
            [-10, 5],
            [-13, 14],
            [-17, 4],
            [-24, 10],
            [-24, -1],
            [-12, 11],
            [-6, 8],
            [-21, 6],
            [-29, -1],
            [-23, -6],
            [-14, -1],
            [-7, 2],
            [-2, 32],
            [4, 7],
            [8, 1],
            [3, 7],
            [-13, 15],
            [-14, -1],
            [-6, 14],
            [-8, 4],
            [-6, 15],
            [14, 1],
            [-3, 8],
            [5, 11],
            [-6, 26],
            [-25, -1],
            [-19, -2],
            [-15, -4],
            [-25, 1],
            [-11, -6],
            [-34, -7],
            [-11, -6],
            [-5, 25],
            [-11, 15],
            [13, 18],
            [15, 8],
            [7, 7],
            [-9, 19],
            [7, 18],
            [8, 2],
            [0, 26],
            [-3, 9],
            [-5, 1],
            [-11, -3],
            [-19, 4],
            [-14, -1],
            [-24, 3],
            [-4, -5],
            [-11, -1],
            [-14, -7],
            [-9, -6],
            [-33, 10],
            [-32, -9],
            [-6, -5],
            [-4, 8],
            [1, 9],
            [-12, 1],
            [-11, -3],
            [-2, -18],
            [-27, 2],
            [-2, -4],
            [-3, -25],
            [3, -12],
            [-23, -4],
            [-9, -5],
            [-15, -2],
            [-10, -4],
            [-15, 2],
            [-9, -8],
            [-26, 0],
            [-3, 5],
            [0, 21],
            [-3, 6],
            [-17, 5],
            [-12, 0],
            [-13, -4],
            [-10, 2],
            [-10, -2],
            [-15, 20],
            [-7, -2],
            [-12, 6],
            [-17, -2],
            [-11, 7],
            [-21, 15],
            [-7, 0],
            [-13, -13],
            [-9, -4],
            [-10, 6],
            [-17, 0],
            [-9, 7],
            [-16, 16],
            [-24, 15],
            [-29, 29],
            [-19, 17],
            [-15, 18],
        ],
        [
            [33899, 1475],
            [-6, 12],
            [4, 14],
            [-11, 14],
            [-36, 47],
            [-15, 22],
            [-5, 15],
            [-30, 42],
            [-27, 41],
            [-9, 17],
            [-8, 11],
            [-6, 12],
            [-40, 65],
            [-15, 9],
            [-5, 6],
            [-3, 20],
            [-15, 33],
            [-9, 12],
            [-2, 10],
            [7, 7],
            [12, 3],
            [8, -1],
            [16, 8],
            [10, 2],
            [-7, 12],
            [-5, 17],
            [-9, 13],
            [0, 7],
            [-8, 23],
            [-18, 11],
            [-6, 10],
            [-10, 7],
            [-18, 30],
            [-14, 9],
            [-12, 1],
            [-6, -4],
            [-9, 8],
            [-28, 8],
            [-16, 13],
            [-5, 16],
            [-10, 12],
            [-2, 16],
            [8, 10],
            [0, 24],
            [-6, 13],
            [-10, 16],
            [-6, 2],
            [-6, 17],
            [-8, 2],
            [-26, 19],
            [-10, 10],
            [-11, 25],
            [-4, 5],
            [-11, -1],
            [-7, 24],
            [-8, 17],
            [-2, 13],
            [-9, 13],
            [-6, 14],
            [-6, 6],
            [1, 6],
            [-6, 10],
            [-4, -1],
            [0, 15],
            [16, -3],
            [2, 5],
            [-5, 10],
            [-1, 11],
            [-7, 12],
            [-4, 13],
            [-16, 27],
            [-4, 10],
            [1, 11],
            [-6, 18],
            [1, 10],
            [7, 12],
            [-3, 16],
            [-16, 11],
            [-2, 17],
            [-9, 5],
            [-11, 37],
            [-3, 23],
            [7, -4],
            [15, 5],
            [4, 8],
            [6, -12],
            [27, -1],
            [6, 4],
            [3, 8],
            [3, 24],
            [-1, 10],
            [-10, 14],
            [-1, 16],
            [-8, 14],
            [-4, 18],
            [-8, 5],
            [-15, 6],
            [-30, 7],
            [-1, 6],
            [8, 16],
            [7, 6],
            [2, 24],
            [8, 33],
            [14, 15],
            [2, 6],
            [-4, 13],
            [16, 17],
            [13, -2],
            [3, 14],
            [12, 14],
            [7, 1],
            [10, 7],
            [5, 12],
            [1, 11],
            [-13, 38],
            [3, 3],
            [30, -7],
            [11, 20],
            [-4, 8],
            [2, 13],
            [-9, 13],
            [-6, 5],
            [2, 11],
            [-4, 10],
            [3, 28],
            [-3, 7],
            [-28, 10],
            [-17, -7],
            [-32, 6],
            [-9, -8],
            [-7, -1],
            [-12, 9],
            [5, 3],
            [3, 13],
            [-2, 10],
            [4, 3],
            [-3, 25],
            [-6, 13],
            [-14, 21],
            [-2, 19],
            [-9, 21],
            [-1, 38],
            [-7, 16],
            [0, 11],
            [3, 12],
            [-3, 10],
            [-10, 11],
            [2, 12],
            [10, 5],
            [-5, 12],
            [21, 18],
            [10, 33],
            [13, 19],
            [4, 11],
            [7, 3],
            [1, 15],
            [-3, 9],
            [12, -2],
            [13, -6],
            [4, 5],
            [2, 27],
            [6, 17],
            [13, 9],
            [11, 24],
            [13, 5],
            [0, 8],
            [-9, 6],
            [-10, 0],
            [-6, 5],
            [-12, -6],
            [-14, 8],
            [-12, -3],
            [-14, 2],
            [-28, -3],
            [-7, -6],
            [-5, -10],
            [-8, -6],
            [2, -7],
            [-14, -3],
            [-12, 32],
            [-1, 13],
            [-12, 28],
            [6, 30],
            [3, 20],
            [-5, 12],
            [-5, 34],
            [-21, 12],
            [5, 7],
            [7, 0],
            [2, 8],
            [7, -1],
            [6, -20],
            [10, -1],
            [6, 25],
            [11, -4],
            [9, 7],
            [15, 1],
            [6, -3],
            [17, 0],
            [6, 8],
            [-4, 9],
            [8, 12],
            [-5, 19],
            [2, 12],
            [9, 19],
            [-1, 13],
            [3, 16],
            [-6, 11],
            [1, 5],
            [9, 8],
            [15, 4],
            [13, 13],
            [0, 18],
            [-13, 13],
            [0, 7],
        ],
        [
            [37589, 1722],
            [-7, -28],
            [-18, -19],
            [-8, -12],
            [-6, -12],
            [-12, -7],
            [-45, -36],
            [-32, -23],
            [-2, -11],
            [-5, -5],
            [0, -11],
            [-13, -8],
            [-3, -9],
            [-23, -17],
            [-35, -13],
            [-24, -11],
            [-10, -7],
            [-10, -11],
            [-5, -11],
            [1, -8],
            [4, -5],
            [10, -4],
            [-10, -14],
            [-18, -10],
            [-14, -12],
            [-13, -20],
            [-17, -6],
            [-43, -25],
            [-5, -6],
            [-17, -8],
            [-11, -9],
            [-8, -13],
            [2, -7],
            [-22, -21],
            [-22, -1],
            [-18, -3],
            [-34, -8],
            [-22, -8],
            [-9, -5],
            [-25, -12],
            [-23, -17],
            [-11, -14],
            [1, -11],
            [8, -4],
            [-17, -20],
            [1, -7],
            [-17, -7],
            [-11, -7],
            [-5, -7],
            [5, -8],
            [-2, -12],
            [-10, -14],
            [-31, -8],
            [-8, -6],
            [-35, -9],
            [-9, -10],
            [-22, -8],
            [-17, -11],
            [-4, -10],
            [-20, -12],
            [-4, -9],
            [3, -9],
            [-16, -8],
            [-6, -6],
            [-10, -26],
            [-16, -8],
            [-8, -11],
            [-10, -6],
            [-9, -16],
            [-20, -9],
            [-34, -6],
            [-18, -11],
            [-10, -20],
            [-15, -10],
            [-17, -1],
            [-34, -12],
            [-18, -10],
            [-7, -6],
            [-1, -21],
            [-15, -9],
            [-4, -5],
            [-19, -15],
            [0, -13],
            [-11, -3],
            [-13, 0],
            [-57, -8],
            [-10, -8],
            [-34, -7],
            [-14, -13],
            [-9, -4],
            [-25, -3],
            [-44, -5],
            [-22, -5],
            [-11, -5],
            [-7, -7],
            [0, -10],
            [-18, -8],
            [1, -15],
            [-31, 0],
            [-16, -2],
            [-50, -12],
            [-29, -10],
            [-23, -17],
            [-2, -9],
            [-10, -7],
            [-12, -4],
            [-29, -3],
            [-14, -6],
            [-2, -7],
            [-10, -7],
            [-18, -3],
            [-13, -4],
            [-13, -11],
            [-4, -9],
            [6, -10],
            [-5, -10],
            [-18, 1],
            [-53, -3],
            [-5, -2],
            [-9, -12],
            [-49, -7],
            [-25, -7],
            [-17, 3],
            [-25, 1],
            [-10, -8],
            [-2, -5],
            [-38, -1],
            [-31, -3],
            [-43, -6],
            [-24, -5],
            [-21, -7],
            [-14, -13],
            [0, -6],
            [-13, -1],
            [-4, -7],
            [-8, -1],
            [-9, -7],
            [-7, 2],
            [-29, -6],
            [-33, 0],
            [-29, -6],
            [-9, -5],
            [-8, -9],
            [0, -8],
            [-10, -1],
            [-35, -11],
            [-26, -14],
            [-16, -9],
            [-29, -3],
            [-23, -1],
            [-22, -5],
            [-19, -8],
            [-9, -9],
            [-6, -13],
            [2, -8],
            [-13, -13],
            [-18, 5],
            [-24, 1],
            [-18, -4],
            [-5, 1],
            [-44, -9],
            [-10, 0],
            [-26, -8],
            [-15, -7],
            [-12, -13],
            [-5, -11],
            [5, -21],
            [-12, -4],
            [-13, -1],
            [-6, -9],
            [-7, -3],
            [0, -9],
            [-10, -3],
            [-6, -6],
            [2, -6],
            [-11, -5],
            [-10, 4],
            [-10, -10],
            [-20, -2],
            [-4, -5],
            [-12, 2],
            [-13, -2],
            [-13, -8],
            [-7, -11],
            [1, -13],
            [-20, -10],
            [0, -7],
            [-6, -5],
            [0, -9],
            [7, -5],
            [1, -7],
            [-4, -7],
            [-8, 2],
            [3, 9],
            [-19, 12],
            [-11, -1],
            [-8, -7],
            [2, -8],
            [-5, -9],
            [-8, -8],
            [2, -6],
            [-5, -7],
            [3, -10],
            [-13, -3],
            [-7, 2],
            [-7, 6],
            [-7, -9],
            [-12, -1],
            [-12, 13],
            [-23, -3],
            [-13, -7],
            [-10, -1],
            [-11, -4],
            [-8, 4],
            [-18, -4],
            [-24, -6],
            [-28, -4],
            [-7, -6],
            [-9, -2],
            [-5, -8],
            [-2, -13],
            [-9, 0],
            [-1, 7],
            [-7, 1],
            [-14, -6],
            [-1, 4],
            [-13, -2],
            [-9, -7],
            [2, -5],
            [-8, -8],
            [-6, -10],
            [6, -3],
            [2, -7],
            [-14, -2],
            [-13, -8],
            [-24, -10],
            [-11, -3],
            [-7, 4],
            [-6, -10],
            [4, -5],
            [-6, -6],
            [-19, 4],
            [-4, -1],
            [-6, 10],
            [8, 7],
            [-2, 9],
            [-11, 13],
            [-13, 7],
            [-5, 0],
            [-6, 7],
            [-38, 10],
            [-29, 4],
            [-11, 0],
            [-17, -10],
            [-14, -6],
            [-3, 4],
            [-13, -8],
            [-13, -2],
            [-46, 5],
            [-7, 5],
            [-26, 4],
            [-12, -3],
            [-10, -8],
            [-20, 1],
            [-28, 6],
            [-24, 17],
            [-12, -1],
            [-4, -9],
            [-8, 7],
            [-6, 0],
            [-4, 7],
            [10, 2],
            [6, 16],
            [-5, 12],
            [5, 2],
            [4, 9],
            [-3, 10],
            [-12, 12],
            [-21, 8],
            [-12, 7],
            [-25, -4],
            [-5, -2],
            [-13, -18],
            [0, -12],
            [-10, -16],
            [-5, 6],
            [-9, 5],
            [-11, -2],
            [-13, 4],
            [-16, 3],
            [-19, 9],
            [-6, 4],
            [-12, -2],
            [-7, 3],
            [-9, -2],
            [-16, 8],
            [-7, 0],
            [-22, 13],
            [-33, 13],
            [-14, 4],
            [-9, 8],
            [-9, -1],
            [-7, 5],
            [-10, 1],
            [-4, 4],
            [-24, 10],
            [-12, 7],
            [-18, 6],
            [-24, 4],
            [-11, 1],
            [-24, 4],
            [-5, -2],
            [-8, 5],
            [-8, 0],
            [-32, 8],
            [-7, -1],
            [-24, 6],
            [-15, 8],
            [-4, 8],
            [-11, 6],
            [-3, 5],
            [-10, 1],
            [-12, -9],
            [-5, 3],
            [-11, 16],
            [0, 11],
            [10, 8],
            [1, 18],
            [4, 6],
            [-4, 8],
            [-9, 7],
            [-7, -9],
            [-10, 15],
            [-17, 4],
            [-12, -4],
            [-9, -12],
            [6, -5],
            [1, -13],
            [-6, -3],
            [-11, 9],
            [-2, 14],
            [-12, 13],
            [-20, 10],
            [-6, -1],
            [-26, 14],
            [-9, 11],
            [-11, 6],
            [-8, 6],
            [-7, 13],
            [0, 5],
            [-12, 13],
            [-19, 18],
            [-21, 12],
            [-29, 23],
            [-2, 5],
            [-14, 17],
            [-21, 16],
            [-10, 5],
            [-20, 18],
            [2, 9],
            [-2, 14],
            [-8, 10],
            [-10, 8],
            [-12, 15],
            [-22, 14],
            [-16, 15],
            [-3, 21],
            [1, 24],
            [-2, 8],
            [-11, 16],
            [-8, 4],
            [0, 9],
            [-8, 22],
            [-8, 11],
            [-26, 24],
            [-34, 31],
            [-7, 12],
            [0, 9],
            [4, 4],
            [1, 13],
            [-2, 8],
            [-11, 22],
            [-9, 26],
            [-4, 8],
            [-7, 19],
            [0, 13],
            [-11, 14],
            [0, 6],
            [-25, 35],
            [-7, 17],
            [-1, 12],
            [-8, 14],
            [0, 13],
            [6, 3],
            [-2, 22],
            [-3, 6],
            [8, 6],
            [-1, 12],
            [5, 8],
            [-3, 16],
            [-9, 22],
            [-14, 26],
            [6, 16],
            [-6, 28],
            [-14, 36],
            [-17, 35],
            [-3, 10],
            [-15, 28],
            [0, 14],
            [-7, 12],
            [3, 10],
            [-4, 17],
            [-10, 24],
            [2, 8],
            [-7, 20],
            [-22, 39],
            [3, 5],
            [-5, 29],
            [-5, 13],
            [-13, 19],
            [2, 7],
        ],
        [
            [32806, 1523],
            [8, -5],
            [6, -7],
            [10, -32],
            [7, -3],
            [14, 7],
            [1, 9],
            [5, 13],
            [7, 3],
            [15, -4],
            [17, -9],
            [10, -8],
            [6, -20],
            [5, -7],
            [8, -4],
            [18, 0],
            [14, -11],
            [15, -2],
            [13, -8],
            [9, -11],
            [11, 0],
            [9, -3],
            [9, -19],
            [8, -8],
            [7, -2],
            [11, 2],
            [9, 9],
            [18, 2],
            [12, -5],
            [15, -13],
            [25, -14],
            [13, -5],
            [10, -8],
            [13, -13],
            [7, -12],
            [3, -11],
            [8, -11],
            [14, -2],
            [6, -11],
            [5, 6],
            [17, 0],
            [7, -4],
            [13, 3],
            [26, 0],
            [4, -3],
            [19, -9],
            [7, -2],
            [13, -8],
            [17, -1],
            [6, 1],
            [20, -4],
            [7, -5],
            [3, -6],
            [21, 10],
            [11, -1],
            [3, 5],
            [16, 1],
            [-3, 11],
            [4, 11],
            [-5, 9],
            [16, 3],
            [16, 25],
            [26, -5],
            [9, -4],
            [16, 1],
            [17, -6],
            [9, -8],
            [3, -13],
            [5, -5],
            [16, -5],
            [14, -17],
            [13, -11],
            [15, -16],
            [16, -1],
            [5, -5],
            [15, -18],
            [4, -13],
            [8, -10],
            [12, -7],
            [12, -18],
            [12, 4],
            [0, 18],
            [5, 7],
            [-6, 35],
            [-9, 17],
            [-15, 51],
            [-3, 56],
            [6, 14],
            [9, 2],
            [12, 7],
            [18, -4],
            [21, 2],
            [12, 11],
            [23, 14],
            [4, 4],
            [22, 13],
            [38, 16],
            [11, 9],
            [7, -6],
            [28, 20],
            [9, 8],
            [13, 1],
            [8, 4],
            [8, -12],
            [6, 3],
        ],
        [
            [35400, 1103],
            [6, 5],
            [18, 12],
            [6, -9],
            [5, 3],
            [23, 8],
            [3, 10],
            [27, 12],
            [13, 14],
            [10, 0],
            [10, 4],
            [7, -4],
            [6, 12],
            [0, 10],
            [10, 6],
            [25, 0],
            [21, 14],
            [16, 9],
            [16, 1],
            [8, 6],
            [7, -1],
            [10, 4],
            [2, 4],
            [24, 16],
            [3, 15],
            [7, 11],
            [1, 11],
            [-8, 18],
            [-15, 11],
            [-10, -2],
            [-8, 8],
            [-1, 23],
            [5, 11],
            [2, 19],
            [9, 20],
            [12, 1],
            [9, -4],
            [24, -3],
            [11, 1],
            [12, -6],
            [11, 7],
            [0, 3],
            [14, 12],
            [11, 19],
            [1, 17],
            [4, 10],
            [-6, 13],
            [28, -22],
            [7, -3],
            [11, 6],
            [9, 11],
            [6, 18],
            [31, 3],
            [15, -1],
            [20, -5],
            [15, -10],
            [8, -1],
            [19, 7],
            [10, -2],
            [19, 3],
            [49, 2],
            [-1, -15],
            [12, -11],
            [2, -7],
            [0, -16],
            [4, -8],
            [1, -11],
            [16, 7],
            [7, -6],
            [1, -10],
            [11, -5],
            [8, 3],
            [10, -3],
            [7, 4],
            [8, 14],
            [18, 11],
            [24, 11],
            [18, -13],
            [16, -23],
            [23, -32],
            [29, -44],
            [16, -22],
            [7, -6],
            [1, -10],
            [-8, -5],
            [-1, -9],
            [8, -9],
            [2, -8],
            [16, -1],
            [12, -11],
            [14, 6],
            [9, -5],
            [5, 6],
            [17, -7],
            [1, 6],
            [17, 12],
            [10, 3],
            [6, 8],
            [13, 11],
            [9, 3],
            [4, 15],
            [10, 18],
            [19, 6],
            [32, 14],
            [34, 13],
            [16, 10],
            [25, -2],
            [15, 7],
            [15, -8],
            [23, 7],
            [16, 10],
            [10, 11],
            [8, 3],
            [27, 2],
            [14, 0],
            [7, 14],
            [9, 6],
            [24, -3],
            [11, 13],
            [10, 25],
            [9, 15],
            [20, 1],
            [6, -5],
            [-2, -12],
            [3, -8],
            [12, 5],
            [27, 4],
            [11, -3],
            [-1, 8],
            [14, 3],
            [5, 14],
            [6, 3],
            [6, -5],
            [10, -3],
            [5, 2],
            [1, 8],
            [19, 2],
            [6, -1],
            [9, -17],
            [17, 1],
            [20, -2],
            [9, -7],
            [4, -10],
            [6, -1],
            [18, 3],
            [14, 31],
            [-10, 35],
            [1, 10],
            [-3, 4],
            [4, 13],
            [-5, 6],
            [3, 11],
            [-2, 10],
            [7, 6],
            [15, 20],
            [7, 13],
            [-3, 13],
            [-12, 3],
            [-5, 8],
            [-1, 10],
            [5, 13],
            [-6, 7],
            [-1, 11],
            [1, 13],
            [6, 16],
            [14, 7],
            [13, 19],
            [5, 1],
            [33, 22],
            [26, 12],
            [14, 2],
            [15, 8],
            [13, 28],
            [9, 16],
            [8, 7],
            [12, 3],
            [15, -7],
            [10, 4],
            [11, 13],
            [38, 36],
            [3, 2],
            [17, -8],
            [19, -4],
            [12, 5],
            [11, 14],
            [4, 10],
            [12, 4],
            [9, -5],
            [7, 0],
            [8, 7],
            [2, 10],
            [5, 4],
            [6, 15],
            [4, 21],
        ],
        [
            [32806, 1523],
            [-17, 15],
            [-6, 3],
            [-6, 10],
            [-1, 11],
            [-8, 12],
            [2, 21],
            [-5, 9],
            [8, 11],
            [18, 14],
            [0, 19],
            [-5, 29],
            [-11, 22],
            [13, 20],
            [-4, 19],
            [-8, 19],
            [-12, 33],
            [-18, 48],
            [-11, 30],
            [-8, 21],
            [0, 11],
            [-4, 5],
            [-8, 32],
            [-6, 17],
            [-8, 33],
            [-4, 8],
            [-7, 23],
            [-14, 33],
            [-26, 73],
            [-19, 47],
            [-28, 60],
            [-21, 38],
            [-14, 32],
            [-6, 13],
            [0, 15],
            [-5, 10],
            [-21, 61],
            [-14, 36],
            [-9, 20],
            [-2, 11],
            [-5, 9],
            [-4, 18],
            [-7, 16],
            [-5, 23],
            [-16, 51],
            [-10, 35],
            [-7, 17],
            [1, 6],
            [-6, 20],
            [6, 7],
            [-4, 27],
            [0, 20],
            [-2, 2],
            [-8, 51],
            [-5, 22],
            [-8, 38],
            [-17, 68],
            [-12, 39],
            [-5, -7],
            [-18, 4],
            [-7, 20],
            [-12, 12],
            [-10, 18],
            [9, 4],
            [41, 0],
            [7, 19],
            [4, -5],
            [-3, -24],
            [-6, -7],
            [12, -5],
            [13, 9],
            [-6, 4],
            [8, 15],
            [3, 17],
            [7, 2],
            [0, 21],
            [8, 5],
            [8, 1],
            [15, 14],
            [5, 18],
            [-2, 44],
            [-3, 15],
            [2, 19],
            [-8, 23],
            [-7, 30],
            [-12, 59],
            [-18, 75],
            [-3, 20],
            [-9, 36],
            [-8, 45],
            [-3, 12],
            [-4, 24],
            [-27, 75],
            [-11, 42],
            [-10, 41],
            [-12, 45],
            [-10, 61],
            [7, 11],
            [17, 3],
            [4, -11],
            [7, -4],
            [4, 9],
            [-7, 5],
            [3, 5],
            [11, 8],
            [11, 18],
            [4, 16],
            [6, 40],
            [2, 41],
            [0, 41],
            [-2, 20],
        ],
        [
            [32418, 3724],
            [-6, -7],
            [-10, -4],
            [-15, -3],
            [-2, -16],
            [-11, -11],
            [-15, 4],
            [-2, 10],
            [-5, -1],
            [-5, -18],
            [7, -9],
            [-2, -12],
            [8, -23],
            [3, -14],
            [9, -11],
            [0, -22],
            [9, -11],
            [10, -6],
            [-4, -6],
            [2, -7],
            [2, -24],
            [-1, -8],
            [12, -20],
            [12, -11],
            [-6, -10],
            [0, -10],
            [7, -12],
            [11, -6],
            [13, 7],
            [6, 6],
            [4, 30],
            [9, 6],
            [-2, -20],
            [13, -16],
            [4, 14],
            [8, 6],
            [2, 8],
            [-7, 6],
            [4, 12],
            [0, 12],
            [-5, 13],
            [-5, 19],
            [-1, 19],
            [-3, 19],
            [6, 3],
            [-1, 18],
            [-3, 12],
            [-11, 23],
            [-8, 14],
            [-11, 25],
            [-4, 3],
            [-2, 12],
            [-14, 17],
        ],
    ],
    transform: {
        scale: [0.00035942929933170057, 0.00034376375143717726],
        translate: [68.19458, 5.918528971000057],
    },
    objects: {
        countries: {
            type: 'GeometryCollection',
            geometries: [
                {
                    arcs: [
                        [[0]],
                        [[1]],
                        [[2]],
                        [[3]],
                        [[4]],
                        [[5]],
                        [[6]],
                        [[7]],
                        [[8]],
                        [[9]],
                        [[10]],
                        [[11]],
                        [[12]],
                        [[13]],
                        [[14]],
                        [[15]],
                        [[16]],
                        [[17]],
                        [[18]],
                        [[19]],
                        [[20]],
                        [[21]],
                        [[22]],
                        [[23]],
                        [[24]],
                        [[25]],
                        [[26]],
                        [[27]],
                        [[28]],
                    ],
                    type: 'MultiPolygon',
                    properties: { state: 'IN-AN', country: 'IN', name: 'Andaman and Nicobar' },
                },
                {
                    arcs: [[29, 30, 31]],
                    type: 'Polygon',
                    properties: { state: 'IN-AR', country: 'IN', name: 'Arunāchal Pradesh' },
                },
                {
                    arcs: [[[32, 33]], [[-31, 34, 35, 36, 37, 38, 39, 40, 41, 42]]],
                    type: 'MultiPolygon',
                    properties: { state: 'IN-AS', country: 'IN', name: 'Assam' },
                },
                {
                    arcs: [[43, 44, 45, 46]],
                    type: 'Polygon',
                    properties: { state: 'IN-BR', country: 'IN', name: 'Bihār' },
                },
                {
                    arcs: [[47, 48]],
                    type: 'Polygon',
                    properties: { state: 'IN-CH', country: 'IN', name: 'Chandīgarh' },
                },
                {
                    arcs: [[49, 50, 51, 52, 53, 54]],
                    type: 'Polygon',
                    properties: { state: 'IN-CT', country: 'IN', name: 'Chhattisgarh' },
                },
                {
                    arcs: [[55, 56]],
                    type: 'Polygon',
                    properties: { state: 'IN-DN', country: 'IN', name: 'Dādra, Nagar Haveli' },
                },
                {
                    arcs: [[[57, 58]], [[59, 60]], [[61, 62, 63, 64]]],
                    type: 'MultiPolygon',
                    properties: { state: 'IN-DD', country: 'IN', name: 'Daman and Diu' },
                },
                {
                    arcs: [[65, 66]],
                    type: 'Polygon',
                    properties: { state: 'IN-DL', country: 'IN', name: 'Delhi' },
                },
                {
                    arcs: [[67, 68, 69]],
                    type: 'Polygon',
                    properties: { state: 'IN-GA', country: 'IN', name: 'Goa' },
                },
                {
                    arcs: [
                        [[-63, 70]],
                        [[-61, 71, -65, 72, 73, 74, 75, -57, 76, 77, -58, 78]],
                        [[79]],
                        [[80]],
                        [[81]],
                        [[82]],
                        [[83]],
                        [[84]],
                        [[85]],
                        [[86]],
                        [[87]],
                        [[88]],
                        [[89]],
                        [[90]],
                        [[91]],
                        [[92]],
                        [[93]],
                        [[94]],
                    ],
                    type: 'MultiPolygon',
                    properties: { state: 'IN-GJ', country: 'IN', name: 'Gujarāt' },
                },
                {
                    arcs: [[-48, 95, 96, 97, -67, 98, 99, 100]],
                    type: 'Polygon',
                    properties: { state: 'IN-HR', country: 'IN', name: 'Haryana' },
                },
                {
                    arcs: [[-97, 101, 102, 103, 104]],
                    type: 'Polygon',
                    properties: { state: 'IN-HP', country: 'IN', name: 'Himachal Pradesh' },
                },
                {
                    arcs: [[-45, 105, 106, -54, 107]],
                    type: 'Polygon',
                    properties: { state: 'IN-JH', country: 'IN', name: 'Jharkhand' },
                },
                {
                    arcs: [[108, 109, 110, 111, -68, 112]],
                    type: 'Polygon',
                    properties: { state: 'IN-KA', country: 'IN', name: 'Karnātaka' },
                },
                {
                    arcs: [[-111, 113, 114, 115, 116]],
                    type: 'Polygon',
                    properties: { state: 'IN-KL', country: 'IN', name: 'Kerala' },
                },
                {
                    arcs: [[-52, 117, -75, 118, 119]],
                    type: 'Polygon',
                    properties: { state: 'IN-MP', country: 'IN', name: 'Madhya Pradesh' },
                },
                {
                    arcs: [[120, -113, -70, 121, -77, -56, -76, -118, -51]],
                    type: 'Polygon',
                    properties: { state: 'IN-MH', country: 'IN', name: 'Mahārāshtra' },
                },
                {
                    arcs: [[-36, 122, 123, 124]],
                    type: 'Polygon',
                    properties: { state: 'IN-MN', country: 'IN', name: 'Manipur' },
                },
                {
                    arcs: [[-33, 125, -40, 126]],
                    type: 'Polygon',
                    properties: { state: 'IN-ML', country: 'IN', name: 'Meghālaya' },
                },
                {
                    arcs: [[-37, -125, 127, 128]],
                    type: 'Polygon',
                    properties: { state: 'IN-MZ', country: 'IN', name: 'Mizoram' },
                },
                {
                    arcs: [[-30, 129, -123, -35]],
                    type: 'Polygon',
                    properties: { state: 'IN-NL', country: 'IN', name: 'Nāgāland' },
                },
                {
                    arcs: [[[130]], [[131]], [[132]], [[-55, -107, 133, 134]]],
                    type: 'MultiPolygon',
                    properties: { state: 'IN-OR', country: 'IN', name: 'Orissa' },
                },
                {
                    arcs: [[[135, 136]], [[137, 138]], [[-116, 139]], [[140]]],
                    type: 'MultiPolygon',
                    properties: { state: 'IN-PY', country: 'IN', name: 'Puducherry' },
                },
                {
                    arcs: [[-49, -101, 141, 142, -102, -96]],
                    type: 'Polygon',
                    properties: { state: 'IN-PB', country: 'IN', name: 'Punjab' },
                },
                {
                    arcs: [[-74, 143, -142, -100, 144, -119]],
                    type: 'Polygon',
                    properties: { state: 'IN-RJ', country: 'IN', name: 'Rajasthan' },
                },
                {
                    arcs: [[145, 146]],
                    type: 'Polygon',
                    properties: { state: 'IN-SK', country: 'IN', name: 'Sikkim' },
                },
                {
                    arcs: [[[147]], [[148, -139, 149, -137, 150, -114, -110]]],
                    type: 'MultiPolygon',
                    properties: { state: 'IN-TN', country: 'IN', name: 'Tamil Nādu' },
                },
                {
                    arcs: [[-38, -129, 151]],
                    type: 'Polygon',
                    properties: { state: 'IN-TR', country: 'IN', name: 'Tripura' },
                },
                {
                    arcs: [[-46, -108, -53, -120, -145, -99, -66, -98, -105, 152, 153]],
                    type: 'Polygon',
                    properties: { state: 'IN-UP', country: 'IN', name: 'Uttar Pradesh' },
                },
                {
                    arcs: [[-104, 154, -153]],
                    type: 'Polygon',
                    properties: { state: 'IN-UT', country: 'IN', name: 'Uttarakhand' },
                },
                {
                    arcs: [
                        [[155]],
                        [[156]],
                        [[157]],
                        [[158]],
                        [[159]],
                        [[160]],
                        [[161]],
                        [[162]],
                        [[163]],
                        [[164]],
                        [[165]],
                        [[166]],
                        [[167]],
                        [[168]],
                        [[169]],
                        [[170]],
                        [[171]],
                        [[172]],
                        [[173]],
                        [[174]],
                        [[175]],
                        [[176]],
                        [[177]],
                        [[178]],
                        [[179]],
                        [[180]],
                        [[181]],
                        [[182]],
                        [[183]],
                        [[184]],
                        [[185]],
                        [[186]],
                        [[187]],
                        [[188]],
                        [[189]],
                        [[190]],
                        [[191]],
                        [[192]],
                        [[193]],
                        [[194]],
                        [[195]],
                        [[-134, -106, -44, 196, -146, 197, -42, 198]],
                        [[199]],
                        [[200]],
                        [[201]],
                        [[202]],
                        [[203]],
                        [[204]],
                        [[205]],
                        [[206]],
                        [[207]],
                        [[208]],
                        [[209]],
                        [[210]],
                        [[211]],
                        [[212]],
                        [[213]],
                        [[214]],
                        [[215]],
                        [[216]],
                        [[217]],
                        [[218]],
                        [[219]],
                        [[220]],
                    ],
                    type: 'MultiPolygon',
                    properties: { state: 'IN-WB', country: 'IN', name: 'West Bengal' },
                },
                {
                    arcs: [[221, 222]],
                    type: 'Polygon',
                    properties: { state: 'IN-AP', country: 'IN', name: 'Andhra Pradesh' },
                },
                {
                    arcs: [[-222, 223]],
                    type: 'Polygon',
                    properties: { state: 'IN-TG', country: 'IN', name: 'Telangana' },
                },
                {
                    arcs: [
                        [[224]],
                        [[225]],
                        [[226]],
                        [[227]],
                        [[228]],
                        [[229]],
                        [[230]],
                        [[231]],
                        [[232]],
                        [[233]],
                        [[234]],
                        [[235]],
                        [[236]],
                        [[237]],
                        [[238]],
                        [[239]],
                        [[240]],
                        [[241]],
                    ],
                    type: 'MultiPolygon',
                    properties: { state: 'IN-LD', country: 'IN', name: 'Lakshadweep' },
                },
                {
                    arcs: [[242], [243]],
                    type: 'Polygon',
                    properties: { state: 'IN-JK', country: 'IN', name: 'Jammu and Kashmir' },
                },
                {
                    arcs: [[244, 245, 246, 247, 248]],
                    type: 'Polygon',
                    properties: { state: 'LK-2', country: 'LK', name: 'Central Provence' },
                },
                {
                    arcs: [
                        [[249]],
                        [[250]],
                        [[251]],
                        [[252]],
                        [[253]],
                        [[254]],
                        [[255]],
                        [[256]],
                        [[257, 258, 259, -245, 260, 261], [262], [263]],
                    ],
                    type: 'MultiPolygon',
                    properties: { state: 'LK-5', country: 'LK', name: 'Eastern Provence' },
                },
                {
                    arcs: [[-249, 264, 265, -261]],
                    type: 'Polygon',
                    properties: { state: 'LK-7', country: 'LK', name: 'North Central Provence' },
                },
                {
                    arcs: [
                        [[266]],
                        [[267]],
                        [[268]],
                        [[269]],
                        [[270]],
                        [[271]],
                        [[272]],
                        [[273]],
                        [[-265, -248, 274, 275, 276, 277]],
                    ],
                    type: 'MultiPolygon',
                    properties: { state: 'LK-6', country: 'LK', name: 'North Western Province' },
                },
                {
                    arcs: [
                        [[278]],
                        [[279]],
                        [[280]],
                        [[281]],
                        [[282]],
                        [[283]],
                        [[284]],
                        [[285]],
                        [[286]],
                        [[287]],
                        [[288]],
                        [[289]],
                        [[290]],
                        [[291]],
                        [[292]],
                        [[293]],
                        [[294]],
                        [[295]],
                        [[296]],
                        [[297]],
                        [[298]],
                        [[299]],
                        [[300]],
                        [[301]],
                        [[302]],
                        [[303]],
                        [[304]],
                        [[305]],
                        [[306]],
                        [[-262, -266, -278, 307]],
                    ],
                    type: 'MultiPolygon',
                    properties: { state: 'LK-4', country: 'LK', name: 'Northern Province' },
                },
                {
                    arcs: [[[308]], [[-247, 309, 310, 311, -275]]],
                    type: 'MultiPolygon',
                    properties: { state: 'LK-9', country: 'LK', name: 'Sabaragamuwa Province' },
                },
                {
                    arcs: [[312, 313, -311, 314, -259]],
                    type: 'Polygon',
                    properties: { state: 'LK-3', country: 'LK', name: 'Southern Province' },
                },
                {
                    arcs: [[-315, -310, -246, -260]],
                    type: 'Polygon',
                    properties: { state: 'LK-8', country: 'LK', name: 'Uva Province' },
                },
                {
                    arcs: [[-312, -314, 315, -276], [-309], [316]],
                    type: 'Polygon',
                    properties: { state: 'LK-1', country: 'LK', name: 'Western Province' },
                },
            ],
        },
    },
};

export default data;
