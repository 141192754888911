import i18n from 'i18next';

export const states = [
    { label: i18n.t('All'), value: 'all' },
    { label: i18n.t('Alabama'), value: 'AL' },
    { label: i18n.t('Alaska'), value: 'AK' },
    { label: i18n.t('Arizona'), value: 'AZ' },
    { label: i18n.t('Arkansas'), value: 'AR' },
    { label: i18n.t('California'), value: 'CA' },
    { label: i18n.t('Colorado'), value: 'CO' },
    { label: i18n.t('Connecticut'), value: 'CT' },
    { label: i18n.t('Delaware'), value: 'DE' },
    { label: i18n.t('District of Columbia'), value: 'DC' },
    { label: i18n.t('Florida'), value: 'FL' },
    { label: i18n.t('Georgia'), value: 'GA' },
    { label: i18n.t('Hawaii'), value: 'HI' },
    { label: i18n.t('Idaho'), value: 'ID' },
    { label: i18n.t('Illinois'), value: 'IL' },
    { label: i18n.t('Indiana'), value: 'IN' },
    { label: i18n.t('Iowa'), value: 'IA' },
    { label: i18n.t('Kansas'), value: 'KS' },
    { label: i18n.t('Kentucky'), value: 'KY' },
    { label: i18n.t('Louisiana'), value: 'LA' },
    { label: i18n.t('Maine'), value: 'ME' },
    { label: i18n.t('Maryland'), value: 'MD' },
    { label: i18n.t('Massachusetts'), value: 'MA' },
    { label: i18n.t('Michigan'), value: 'MI' },
    { label: i18n.t('Minnesota'), value: 'MN' },
    { label: i18n.t('Mississippi'), value: 'MS' },
    { label: i18n.t('Missouri'), value: 'MO' },
    { label: i18n.t('Montana'), value: 'MT' },
    { label: i18n.t('Nebraska'), value: 'NE' },
    { label: i18n.t('Nevada'), value: 'NV' },
    { label: i18n.t('New Hampshire'), value: 'NH' },
    { label: i18n.t('New Jersey'), value: 'NJ' },
    { label: i18n.t('New Mexico'), value: 'NM' },
    { label: i18n.t('New York'), value: 'NY' },
    { label: i18n.t('North Carolina'), value: 'NC' },
    { label: i18n.t('North Dakota'), value: 'ND' },
    { label: i18n.t('Ohio'), value: 'OH' },
    { label: i18n.t('Oklahoma'), value: 'OK' },
    { label: i18n.t('Oregon'), value: 'OR' },
    { label: i18n.t('Pennsylvania'), value: 'PA' },
    { label: i18n.t('Rhode Island'), value: 'RI' },
    { label: i18n.t('South Carolina'), value: 'SC' },
    { label: i18n.t('South Dakota'), value: 'SD' },
    { label: i18n.t('Tennessee'), value: 'TN' },
    { label: i18n.t('Texas'), value: 'TX' },
    { label: i18n.t('Utah'), value: 'UT' },
    { label: i18n.t('Vermont'), value: 'VT' },
    { label: i18n.t('Virginia'), value: 'VA' },
    { label: i18n.t('Washington'), value: 'WA' },
    { label: i18n.t('West Virginia'), value: 'WV' },
    { label: i18n.t('Wisconsin'), value: 'WI' },
    { label: i18n.t('Wyoming'), value: 'WY' },
    { label: i18n.t('Puerto Rico'), value: 'PR' },
];

const data = {
    type: 'Topology',
    arcs: [
        [
            [19733, 5613],
            [37, -283],
            [53, -416],
            [24, -92],
            [23, -52],
            [-9, -33],
            [25, -19],
            [-37, -42],
            [2, -41],
            [-19, -56],
            [21, -99],
            [-15, -87],
            [23, -90],
        ],
        [
            [19861, 4303],
            [-105, -2],
            [-449, 0],
            [-7, -43],
            [48, -63],
            [-8, -54],
            [16, -27],
            [-31, -49],
        ],
        [
            [19325, 4065],
            [-29, -11],
            [-54, 54],
            [-6, 81],
            [-16, 9],
            [-20, -61],
            [-7, -59],
            [-55, 16],
        ],
        [
            [19138, 4094],
            [-16, 503],
            [49, 625],
            [30, 361],
            [-22, 34],
        ],
        [
            [19179, 5617],
            [180, 2],
            [374, -6],
        ],
        [
            [9922, 12240],
            [7, 54],
            [46, -32],
            [-7, -56],
            [-55, 7],
            [9, 27],
        ],
        [
            [9873, 12341],
            [40, -40],
            [-22, -57],
            [-18, 19],
            [0, 78],
        ],
        [
            [9629, 12674],
            [52, 8],
            [22, -13],
            [-7, -49],
            [-46, -10],
            [-41, 30],
            [20, 34],
        ],
        [
            [9497, 12646],
            [92, -11],
            [24, -63],
            [92, -71],
            [32, -43],
            [2, -36],
            [22, -7],
            [4, -47],
            [37, -47],
            [5, -41],
            [24, 12],
            [11, -32],
            [4, -128],
            [-15, -29],
            [-59, 5],
            [-17, 65],
            [-21, -5],
            [-43, 49],
            [-13, -17],
            [-36, 17],
            [6, -49],
            [34, 12],
            [22, -18],
            [-11, -66],
            [-40, 7],
            [-62, 85],
            [-18, 43],
            [4, 45],
            [-49, -4],
            [0, 35],
            [37, 3],
            [37, 31],
            [-16, 54],
            [-44, 11],
            [-7, 86],
            [-17, 44],
            [-30, -31],
            [-12, 49],
            [32, 23],
            [-26, 56],
            [15, 13],
        ],
        [
            [9467, 12384],
            [41, -21],
            [28, 27],
            [28, -13],
            [-29, -49],
            [-46, 15],
            [-22, 41],
        ],
        [
            [9431, 12837],
            [-31, 32],
            [35, 22],
            [116, -31],
            [51, 2],
            [36, -61],
            [67, -51],
            [-7, -38],
            [-35, -20],
            [-48, 9],
            [-34, -23],
            [-46, 14],
            [-52, -14],
            [-10, 78],
            [4, 50],
            [-38, 2],
            [-8, 29],
        ],
        [
            [9386, 12689],
            [-29, 25],
            [-32, 54],
            [-3, 42],
            [26, 19],
            [26, -19],
            [6, 34],
            [84, -60],
            [9, -57],
            [-30, -8],
            [-18, -64],
            [21, -18],
            [-26, -76],
            [-33, 16],
            [-4, -47],
            [-21, 24],
            [-13, 92],
            [37, 43],
        ],
        [
            [9275, 12624],
            [-7, -38],
            [-29, 22],
            [-79, 146],
            [-51, 43],
            [18, 34],
            [-20, 83],
            [-56, 67],
            [16, 18],
            [-21, -18],
            [-38, 12],
            [-24, 46],
            [-44, 30],
            [-34, 63],
            [-43, 29],
            [2, 52],
            [29, 18],
            [10, 45],
            [21, -19],
            [64, -2],
            [42, 29],
            [61, -40],
            [-33, -43],
            [12, -16],
            [30, 48],
            [74, -16],
            [41, -36],
            [-24, -65],
            [19, -5],
            [24, -87],
            [-56, -13],
            [-104, 71],
            [4, -70],
            [29, 34],
            [26, -27],
            [50, -22],
            [46, -4],
            [1, -52],
            [44, -171],
            [0, -146],
        ],
        [
            [9259, 13262],
            [72, -25],
            [42, 3],
            [-2, -25],
            [61, -90],
            [-42, 14],
            [-11, -30],
            [46, -47],
            [14, -83],
            [-46, -22],
            [-15, -28],
            [-74, -60],
            [-22, 2],
            [-9, 63],
            [15, 38],
            [-7, 56],
            [-26, 68],
            [5, 36],
            [-16, 89],
            [-28, 37],
            [-9, 65],
            [52, -61],
        ],
        [
            [9015, 12968],
            [30, 0],
            [32, -59],
            [-14, -42],
            [-48, -9],
            [7, 65],
            [-7, 45],
        ],
        [
            [6621, 13913],
            [-90, -83],
            [-7, -33],
            [-72, -21],
            [15, 50],
            [78, 53],
            [49, 57],
            [27, -23],
        ],
        [
            [6518, 14037],
            [-11, -68],
            [-31, -71],
            [-42, 24],
            [35, 81],
            [49, 34],
        ],
        [
            [6470, 17219],
            [23, -15],
            [111, -14],
            [58, 9],
            [81, 0],
            [87, -13],
            [52, 8],
            [50, -27],
            [91, -31],
            [123, -7],
            [34, 18],
            [81, 11],
            [35, 23],
            [89, 4],
            [-1, -17],
            [53, 9],
            [108, -25],
            [74, -41],
            [69, -20],
            [15, -18],
            [63, -4],
            [60, -30],
            [11, -20],
            [36, 16],
            [45, -13],
            [0, -3075],
            [99, -27],
            [13, 29],
            [104, -42],
            [62, 53],
            [129, 5],
            [-23, -88],
            [33, -31],
            [73, -29],
            [17, -47],
            [217, -173],
            [34, -110],
            [38, 31],
            [94, 52],
            [52, 2],
            [25, 40],
            [-2, 59],
            [37, 0],
            [9, 53],
            [67, 12],
            [99, 45],
            [97, -77],
            [-9, -47],
            [23, -47],
            [55, -11],
            [69, -70],
            [-5, -20],
            [32, -38],
            [88, -43],
            [142, -189],
            [21, -51],
            [45, -50],
            [65, -112],
            [65, -96],
            [-24, -39],
            [68, -15],
            [-17, -57],
            [55, -24],
            [7, -67],
            [55, 4],
            [105, -68],
            [66, -13],
            [33, -32],
            [33, -9],
            [10, -33],
            [68, -9],
            [21, -40],
            [-31, -74],
            [5, -61],
            [30, -101],
            [-23, -25],
            [-52, -92],
            [-75, -67],
            [-21, 35],
            [-28, -11],
            [-19, 68],
            [5, 29],
            [-23, 34],
            [49, 36],
            [-14, 13],
            [-49, -45],
            [-27, 29],
            [-30, -16],
            [-89, 72],
            [30, 79],
            [-56, -27],
            [-2, -40],
            [-44, 31],
            [-10, 38],
            [31, 41],
            [-10, 42],
            [-42, -34],
            [-42, 73],
            [-26, -14],
            [-16, 61],
            [41, 40],
            [40, 0],
            [-12, 49],
            [21, 63],
            [-33, -2],
            [-67, 54],
            [-48, 65],
            [-113, 47],
            [3, 131],
            [-36, 17],
            [8, 54],
            [-38, 14],
            [-54, 72],
            [-16, 38],
            [-94, 13],
            [-104, 97],
            [-45, 227],
            [-21, -52],
            [10, -47],
            [42, -90],
            [-11, -15],
            [28, -73],
            [-6, -49],
            [-40, 11],
            [-33, 54],
            [-43, 9],
            [-61, -15],
            [1, 78],
            [-37, 65],
            [-34, -20],
            [-135, 68],
            [-11, -19],
            [73, -22],
            [58, -54],
            [22, -2],
            [7, -43],
            [29, -51],
            [-74, -29],
            [-43, 19],
            [-1, -46],
            [-56, 35],
            [-19, 25],
            [-35, 0],
            [-97, 65],
            [-71, 58],
            [-7, 34],
            [-40, 50],
            [-110, 36],
            [-60, 38],
            [-107, 43],
            [-70, 42],
            [6, 45],
            [20, -14],
            [23, 28],
            [-23, 65],
            [29, 36],
            [-14, 17],
            [-52, -69],
            [-110, -45],
            [-131, 16],
            [-110, 42],
            [-5, 32],
            [-51, -7],
            [-55, 23],
            [-122, 22],
            [-72, 2],
            [-160, -18],
            [-57, -13],
            [-72, 47],
            [-90, 22],
            [-28, 28],
            [-11, 49],
            [-62, -3],
            [-21, -44],
            [-110, 58],
            [-17, 25],
            [-114, -47],
            [-54, -56],
            [-24, 53],
            [24, 29],
            [32, -8],
            [108, 38],
            [-14, 29],
            [-50, -14],
            [-20, 37],
            [-47, 6],
            [-47, 95],
            [-17, -23],
            [-66, -13],
            [-21, 13],
            [-22, -31],
            [-82, 11],
            [-3, -34],
            [-55, -9],
            [-26, 13],
            [19, 61],
            [-23, -2],
            [-38, -65],
            [52, -22],
            [4, -46],
            [32, -51],
            [-19, -54],
            [-41, 18],
            [-12, -27],
            [40, -11],
            [22, -72],
            [-57, -14],
            [-31, 14],
            [-51, -20],
            [-25, 17],
            [-67, -2],
            [2, 27],
            [-31, -18],
            [-18, -35],
            [-28, 31],
            [-35, -43],
            [15, -20],
            [-49, -25],
            [-44, -4],
            [-18, -34],
            [-47, -31],
            [-33, 11],
            [-36, -36],
            [-30, 2],
            [-60, -74],
            [-66, -5],
            [-21, 23],
            [-39, -40],
            [-82, 31],
            [18, 56],
            [54, 20],
            [35, -4],
            [11, 22],
            [63, 43],
            [2, 36],
            [-83, -49],
            [-69, 29],
            [-8, 20],
            [35, 83],
            [60, 59],
            [9, 49],
            [18, 9],
            [7, 52],
            [-31, 58],
            [74, 22],
            [140, 83],
            [34, -33],
            [43, -9],
            [64, 34],
            [-71, 45],
            [-28, 35],
            [-56, -4],
            [-40, 16],
            [-11, -14],
            [-67, -24],
            [-30, -45],
            [-67, -10],
            [-69, -53],
            [-7, -34],
            [-52, -18],
            [-12, -38],
            [-37, -23],
            [-18, -67],
            [-72, -43],
            [35, -34],
            [-30, -51],
            [-67, -9],
            [-6, -65],
            [-63, -23],
            [-20, 27],
            [-32, -51],
            [-37, -2],
            [7, -36],
            [-83, -21],
            [-16, -99],
            [94, -6],
            [75, -27],
            [21, -32],
            [-30, -53],
            [-51, -32],
            [-46, -2],
            [-5, -29],
            [-30, -10],
            [14, -36],
            [-32, -55],
            [-68, -50],
            [-38, 0],
            [-37, -18],
            [-37, 4],
            [-28, -20],
            [8, -27],
            [-52, -15],
            [-11, -39],
            [-36, 25],
            [-40, -78],
            [-66, 6],
            [5, -42],
            [-40, 11],
            [-28, -18],
            [0, -56],
            [-45, -86],
            [-71, -11],
            [-58, -40],
            [-10, -21],
            [-37, 30],
            [-63, -83],
            [-18, 24],
            [-35, -7],
            [-8, -47],
            [-36, -18],
            [-43, 7],
            [-34, -47],
            [59, -14],
            [-64, -105],
            [-184, -34],
            [-52, -92],
            [-13, 20],
            [7, 57],
            [-36, 9],
            [-45, -21],
            [-7, -24],
            [-72, -38],
            [-30, -43],
            [-9, 31],
            [-19, -36],
            [-42, 23],
            [-78, -56],
            [-59, 2],
            [8, 43],
            [-25, 42],
            [-24, -34],
            [3, -36],
            [-79, -110],
            [-26, 27],
            [-9, -42],
            [-53, 7],
            [-9, 65],
            [-32, 15],
            [-12, -24],
            [29, -29],
            [-14, -45],
            [-43, -23],
            [-33, 50],
            [-39, 4],
            [-7, -20],
            [39, -29],
            [-72, -47],
            [49, -12],
            [1, -22],
            [-41, 16],
            [-55, -43],
            [-106, 2],
            [-53, -29],
            [-4, -22],
            [-61, -25],
            [-44, 9],
            [-16, 62],
            [45, 19],
            [34, 74],
            [44, 2],
            [96, 49],
            [77, 2],
            [29, -47],
            [22, 34],
            [-4, 40],
            [44, 18],
            [48, 0],
            [64, 86],
            [71, 78],
            [94, 68],
            [110, 31],
            [45, -15],
            [45, 20],
            [11, -29],
            [-24, -32],
            [27, -25],
            [8, 39],
            [55, 4],
            [15, -25],
            [34, -9],
            [1, 30],
            [-56, 27],
            [-5, 18],
            [38, 85],
            [50, 49],
            [68, 47],
            [107, 41],
            [78, 60],
            [38, -22],
            [30, 9],
            [-13, 38],
            [10, 36],
            [59, 76],
            [82, 48],
            [65, 65],
            [-3, 36],
            [52, 256],
            [82, 69],
            [-9, 54],
            [-196, -78],
            [-62, 9],
            [-15, 33],
            [-35, 14],
            [-12, 36],
            [-32, -16],
            [-20, -56],
            [34, -70],
            [-41, -31],
            [-36, 11],
            [-70, 112],
            [-48, 56],
            [-24, 0],
            [-17, -42],
            [-21, -7],
            [-31, 34],
            [-39, 6],
            [-17, 55],
            [-115, -63],
            [-103, -45],
            [-8, -25],
            [-80, -38],
            [-40, 34],
            [36, 40],
            [-13, 94],
            [-27, 99],
            [54, 41],
            [-44, 83],
            [-39, 47],
            [-30, 69],
            [-46, 29],
            [-11, -60],
            [-55, -13],
            [-88, -37],
            [-105, -17],
            [-57, 4],
            [-49, 20],
            [-6, 52],
            [-42, 16],
            [-67, 72],
            [-56, 15],
            [-65, 79],
            [44, 36],
            [9, 67],
            [-33, -14],
            [-5, 41],
            [16, 34],
            [-43, 31],
            [-5, -33],
            [-52, 13],
            [-7, 56],
            [-41, 7],
            [-22, 38],
            [1, 47],
            [-41, -22],
            [-7, 45],
            [50, 11],
            [-41, 51],
            [73, 3],
            [0, 61],
            [17, 42],
            [135, 132],
            [28, 39],
            [19, -7],
            [-11, 56],
            [51, 95],
            [45, 38],
            [83, 16],
            [64, -16],
            [90, -57],
            [58, 7],
            [81, 56],
            [83, 84],
            [42, 9],
            [10, -21],
            [101, 0],
            [88, 18],
            [80, 88],
            [0, 22],
            [-40, 83],
            [-4, 48],
            [-62, 53],
            [-24, 45],
            [63, -13],
            [60, 38],
            [3, 36],
            [-77, 67],
            [-57, -53],
            [-54, 9],
            [-50, -28],
            [-57, -8],
            [-14, -19],
            [-66, -29],
            [-19, -49],
            [-33, -20],
            [-15, 58],
            [-40, 13],
            [-28, -46],
            [-16, 22],
            [-74, 33],
            [-151, -2],
            [-107, -36],
            [-41, -6],
            [-88, 22],
            [-166, 25],
            [-43, 20],
            [-20, 32],
            [16, 45],
            [-60, 38],
            [12, 42],
            [37, 21],
            [-12, 53],
            [-59, 0],
            [-49, 14],
            [-96, 11],
            [-50, 27],
            [-77, 27],
            [-7, 34],
            [120, 45],
            [152, 76],
            [106, 45],
            [61, -25],
            [62, -6],
            [14, 37],
            [-38, 5],
            [-3, 31],
            [148, 50],
            [167, 38],
            [90, 4],
            [49, -13],
            [-25, -54],
            [9, -38],
            [-19, -27],
            [32, -43],
            [58, 7],
            [74, -7],
            [85, 9],
            [25, -17],
            [56, -3],
            [49, 16],
            [64, -18],
            [62, 72],
            [44, 4],
            [31, -15],
            [15, 42],
            [-85, 20],
            [-82, -17],
            [8, 54],
            [-64, 58],
            [-70, 18],
            [-21, 52],
            [54, 13],
            [71, -52],
            [-13, -42],
            [34, -32],
            [73, -38],
            [18, 40],
            [-84, 52],
            [36, 94],
            [-29, 16],
            [-83, -20],
            [-86, 6],
            [-12, 16],
            [-44, -16],
            [-178, 39],
            [-4, 42],
            [-54, 92],
            [-43, 34],
            [-69, 29],
            [-140, 79],
            [-64, 31],
            [-64, 7],
            [-96, 54],
            [-94, 31],
            [-4, 10],
            [70, 17],
            [31, 50],
            [3, 101],
            [189, -7],
            [229, 23],
            [59, 18],
            [95, 51],
            [90, 77],
            [19, 78],
            [38, 66],
            [80, 56],
            [33, 42],
            [99, 66],
            [15, -18],
            [82, -5],
            [119, 34],
            [74, 36],
            [179, 112],
            [68, 6],
            [7, -17],
            [69, 13],
            [68, -4],
            [133, 15],
            [131, 48],
            [129, 101],
            [53, 22],
            [9, -18],
            [200, -41],
            [16, -29],
            [-69, -38],
            [-31, -2],
            [1, -50],
            [101, 16],
            [5, 27],
            [46, 25],
            [15, -14],
            [34, 57],
            [99, -52],
            [-16, -40],
            [62, -10],
            [36, -24],
            [54, 38],
            [94, 2],
            [54, 12],
            [138, -12],
            [70, -15],
            [-34, -77],
            [129, -20],
            [9, -20],
            [125, -34],
            [1, 16],
            [90, 22],
            [87, -2],
            [1, -18],
            [50, -2],
            [53, 25],
            [86, 4],
            [61, -11],
            [83, -27],
            [38, 6],
            [55, -38],
            [32, 14],
            [51, -11],
            [37, -23],
        ],
        [
            [5371, 13197],
            [-1, -14],
            [-74, 21],
            [53, 85],
            [48, 7],
            [33, 78],
            [36, -69],
            [30, 24],
            [59, -38],
            [0, -52],
            [-86, -8],
            [-37, -23],
            [-61, -11],
        ],
        [
            [5154, 13037],
            [61, 43],
            [-55, 9],
            [-1, 38],
            [45, 25],
            [33, -18],
            [5, -38],
            [18, 25],
            [1, 56],
            [40, -25],
            [7, 36],
            [35, -21],
            [42, 0],
            [33, 19],
            [54, -34],
            [0, -95],
            [67, 7],
            [-44, -63],
            [-82, 25],
            [31, -42],
            [-24, -34],
            [-42, 16],
            [-1, -64],
            [-64, -18],
            [-21, -28],
            [-40, 26],
            [-42, -69],
            [-33, -7],
            [-35, -31],
            [-13, 74],
            [-49, -39],
            [-2, 23],
            [-44, 23],
            [-4, 67],
            [-43, 27],
            [24, 78],
            [86, 48],
            [52, -3],
            [5, -36],
        ],
        [
            [5031, 12728],
            [-44, -66],
            [-14, 11],
            [58, 55],
        ],
        [
            [4796, 12505],
            [34, -4],
            [-11, -68],
            [-56, 9],
            [-10, 23],
            [43, 40],
        ],
        [
            [3889, 12294],
            [-13, -70],
            [-65, -58],
            [31, 88],
            [22, -9],
            [25, 49],
        ],
        [
            [3754, 12319],
            [40, 0],
            [-1, -36],
            [-40, -39],
            [-35, 27],
            [-22, -26],
            [-13, 62],
            [12, 19],
            [59, -7],
        ],
        [
            [3384, 12195],
            [5, -29],
            [-25, -18],
            [-18, 31],
            [38, 16],
        ],
        [
            [3350, 15043],
            [-31, -32],
            [-11, 28],
            [42, 4],
        ],
        [
            [3267, 12033],
            [46, -13],
            [-5, -27],
            [-36, 2],
            [-5, 38],
        ],
        [
            [2682, 11970],
            [15, -38],
            [-32, -17],
            [-12, 40],
            [29, 15],
        ],
        [
            [2641, 11923],
            [-65, -36],
            [-14, 25],
            [28, 32],
            [51, -21],
        ],
        [
            [2508, 13965],
            [49, 13],
            [11, -24],
            [43, 6],
            [42, -22],
            [8, -94],
            [-22, -30],
            [-55, -18],
            [-12, -33],
            [-80, 36],
            [-38, -2],
            [-80, 47],
            [-28, 0],
            [-47, 27],
            [-16, 44],
            [23, 10],
            [79, -10],
            [38, 34],
            [39, 3],
            [18, 24],
            [28, -11],
        ],
        [
            [2506, 11876],
            [35, -25],
            [-72, -62],
            [2, -11],
            [90, 46],
            [1, -26],
            [-36, -30],
            [-59, -20],
            [-6, -31],
            [-63, -32],
            [-55, -2],
            [-36, -31],
            [-68, -27],
            [-36, 29],
            [71, 34],
            [23, -5],
            [53, 29],
            [-13, 32],
            [31, 34],
            [45, -16],
            [5, 22],
            [-49, 7],
            [-27, 24],
            [24, 39],
            [82, 24],
            [18, -45],
            [40, 43],
        ],
        [
            [1991, 11595],
            [81, 63],
            [5, 27],
            [32, 30],
            [49, 15],
            [25, -16],
            [10, -44],
            [-91, -47],
            [-50, -68],
            [-39, -23],
            [-22, 63],
        ],
        [
            [1786, 11508],
            [9, -32],
            [-54, -2],
            [-6, 21],
            [51, 13],
        ],
        [
            [1701, 12932],
            [-29, -30],
            [-6, 30],
            [35, 0],
        ],
        [
            [1590, 11443],
            [14, -30],
            [-40, -22],
            [-6, 32],
            [32, 20],
        ],
        [
            [1361, 15070],
            [169, -49],
            [98, 42],
            [45, -4],
            [40, -23],
            [10, -40],
            [85, -21],
            [27, -20],
            [111, -9],
            [67, -15],
            [-36, -48],
            [-54, 10],
            [-57, -9],
            [-30, -21],
            [-27, -49],
            [-40, 45],
            [-48, 31],
            [-45, 3],
            [-21, 35],
            [-107, 43],
            [-50, 2],
            [-78, -38],
            [-58, 20],
            [-24, 39],
            [23, 76],
        ],
        [
            [1214, 11342],
            [3, -38],
            [-41, -7],
            [9, 33],
            [29, 12],
        ],
        [
            [959, 11263],
            [28, -14],
            [80, 1],
            [3, -11],
            [-95, -16],
            [-16, 40],
        ],
        [
            [811, 11306],
            [-1, 33],
            [30, 12],
            [43, -32],
            [-16, -31],
            [-26, 2],
            [8, -34],
            [-41, -4],
            [-86, -36],
            [-49, 11],
            [14, 25],
            [52, -3],
            [72, 57],
        ],
        [
            [353, 11166],
            [38, 5],
            [0, -41],
            [-49, 5],
            [-60, -49],
            [-24, 65],
            [26, 2],
            [3, 49],
            [32, 1],
            [0, -36],
            [34, -1],
        ],
        [
            [207, 11196],
            [23, -16],
            [-16, -56],
            [-33, -16],
            [-1, 34],
            [27, 54],
        ],
        [
            [0, 11187],
            [36, -2],
            [33, -39],
            [-35, -47],
            [-34, 88],
        ],
        [
            [74913, 11541],
            [40, -22],
            [2, -34],
            [-85, -20],
            [-56, 54],
            [0, 25],
            [99, -3],
        ],
        [
            [14734, 6277],
            [-1, -1866],
        ],
        [
            [14733, 4411],
            [-432, 0],
            [-250, 123],
            [-548, 259],
            [20, 74],
        ],
        [
            [13523, 4867],
            [42, 13],
            [11, 29],
            [-11, 61],
            [-30, 2],
            [-14, 122],
            [44, 47],
            [6, 49],
            [-9, 77],
            [26, 58],
            [34, 22],
            [26, 43],
            [-42, 47],
            [-30, 86],
            [-35, 54],
            [0, 42],
        ],
        [
            [13541, 5619],
            [13, 45],
            [-5, 61],
            [-17, 63],
            [-13, 193],
            [78, 13],
            [26, -40],
            [21, 2],
            [22, 56],
            [0, 265],
        ],
        [
            [13666, 6277],
            [757, 2],
            [311, -2],
        ],
        [
            [18853, 5947],
            [-7, -61],
            [-32, -18],
            [-7, -51],
            [-39, -54],
            [3, -79],
            [-21, -58],
            [-21, -9],
        ],
        [
            [18729, 5617],
            [13, -29],
            [-34, -25],
            [-15, -56],
            [-22, -14],
            [3, -65],
            [-38, -18],
            [1, -22],
            [-45, -54],
            [13, -36],
            [-38, -52],
            [-34, -101],
            [37, -44],
            [-18, -27],
            [11, -68],
            [-16, -45],
        ],
        [
            [18547, 4961],
            [-521, 5],
            [-92, 0],
        ],
        [
            [17934, 4966],
            [0, 175],
            [-31, 15],
            [-42, -17],
            [-22, 31],
        ],
        [
            [17839, 5170],
            [12, 579],
            [-40, 364],
        ],
        [
            [17811, 6113],
            [30, 0],
            [922, -2],
            [19, -63],
            [-33, -40],
            [-34, -61],
            [138, 0],
        ],
        [
            [13523, 4867],
            [-283, -31],
            [-230, -28],
            [-26, 43],
            [-1, 68],
            [-16, 82],
            [-31, 57],
            [-66, 80],
            [-86, 73],
            [-16, -19],
            [-33, 12],
            [5, 33],
            [-37, 66],
            [-51, -14],
            [-88, 49],
            [-13, 39],
            [-60, 49],
            [-68, -2],
            [-56, 22],
            [-71, -9],
            [-37, 43],
            [8, 92],
            [-13, 14],
            [8, 65],
            [-56, 49],
            [-2, 67],
            [-21, 3],
            [-35, 58],
            [-25, 13],
            [-10, 36],
            [-82, 135],
            [-39, 39],
            [-8, 107],
            [17, -9],
            [15, 63],
            [-31, 58],
            [-37, -8],
            [-49, 53],
            [-18, 41],
            [4, 40],
            [-25, 52],
            [0, 87],
            [40, 0],
            [-16, 120],
            [-18, -12],
            [-3, -60],
            [-42, -12],
            [-51, 45],
            [-8, 77],
            [-33, 62],
            [-43, 37],
            [-23, 44],
            [-63, 84],
            [10, 26],
            [-29, 110],
            [13, 61],
            [-19, 92],
            [-55, 90],
            [-53, 50],
            [-11, 60],
            [54, 144],
            [10, 49],
            [-10, 38],
            [20, 99],
            [-18, 90],
            [-23, 22],
            [9, 72],
        ],
        [
            [11498, 7923],
            [209, 1],
            [183, 2],
            [286, -5],
            [221, 0],
        ],
        [
            [12397, 7921],
            [1, -570],
            [-1, -416],
            [274, -296],
            [259, -290],
            [205, -236],
            [147, -175],
            [259, -319],
        ],
        [
            [15798, 7595],
            [427, 0],
            [0, -330],
        ],
        [
            [16225, 7265],
            [2, -990],
        ],
        [
            [16227, 6275],
            [-205, 2],
        ],
        [
            [16022, 6277],
            [-285, -2],
            [-539, 0],
            [-118, 2],
            [-346, 0],
        ],
        [
            [14734, 6277],
            [0, 384],
            [-3, 36],
            [1, 279],
            [1, 617],
        ],
        [
            [14733, 7593],
            [240, 2],
            [468, -2],
            [357, 2],
        ],
        [
            [22677, 7924],
            [0, -194],
            [-12, -31],
        ],
        [
            [22665, 7699],
            [-19, 6],
            [-93, -26],
            [-111, 7],
            [-48, -45],
            [-52, -14],
            [-61, -38],
        ],
        [
            [22281, 7589],
            [-15, 38],
            [53, 36],
            [-15, 27],
            [14, 249],
        ],
        [
            [22318, 7939],
            [92, -4],
            [267, -5],
            [0, -6],
        ],
        [
            [21907, 7200],
            [-20, -40],
        ],
        [
            [21887, 7160],
            [-23, -22],
            [5, -52],
            [32, -48],
            [8, -82],
            [45, -84],
            [21, -4],
            [10, -113],
        ],
        [
            [21985, 6755],
            [-138, 3],
            [-20, 415],
        ],
        [
            [21827, 7173],
            [36, 36],
            [44, -9],
        ],
        [
            [21560, 6866],
            [-17, 47],
        ],
        [
            [21543, 6913],
            [18, 20],
            [27, -32],
            [-28, -35],
        ],
        [
            [19861, 4303],
            [29, -96],
            [292, -21],
            [274, -25],
            [10, -71],
            [26, 2],
            [9, 67],
            [-8, 61],
            [20, 25],
            [49, -27],
            [58, -12],
        ],
        [
            [20620, 4206],
            [13, -143],
            [27, -160],
            [62, -211],
            [95, -226],
            [-14, -16],
            [4, -104],
            [40, -117],
            [62, -237],
            [13, -74],
            [-1, -75],
            [-24, -272],
            [-20, -6],
            [-21, -85],
            [7, -27],
            [-41, -61],
            [-16, 15],
            [-40, -26],
            [-67, -14],
            [-20, 34],
            [9, 51],
            [-48, 146],
            [-37, 27],
            [-33, -20],
            [-26, 81],
            [-7, 67],
            [-44, 74],
            [-10, 48],
            [7, 71],
            [-25, 12],
            [6, -41],
            [-22, -11],
            [-68, 178],
            [-27, 45],
            [64, 132],
            [-42, -7],
            [-28, -42],
            [-28, 65],
            [38, 180],
            [7, 150],
            [-26, 36],
            [-8, 49],
            [-41, 11],
            [-48, 79],
            [-39, 32],
            [-2, 49],
            [-27, 18],
            [-22, 54],
            [-82, 72],
            [-71, -16],
            [3, -50],
            [-23, 9],
            [-89, -62],
            [-94, -14],
            [2, 36],
            [-22, 43],
            [-111, 98],
            [-80, 41],
            [-71, 11],
            [-59, -7],
            [-130, -31],
        ],
        [
            [19733, 5613],
            [274, 2],
        ],
        [
            [20007, 5615],
            [150, -2],
            [108, 6],
        ],
        [
            [20265, 5619],
            [-45, -70],
            [-4, -35],
            [71, -70],
            [23, 5],
            [32, -72],
            [7, -38],
            [34, -68],
            [49, -42],
            [28, -61],
            [58, -56],
            [-3, -38],
            [38, -61],
            [57, -50],
            [14, -54],
            [2, -71],
            [29, -23],
            [34, -88],
            [1, -56],
            [50, -29],
        ],
        [
            [20740, 4642],
            [-53, -112],
            [-9, -58],
            [-23, -50],
            [-2, -52],
            [-23, -24],
            [-10, -140],
        ],
        [
            [4796, 335],
            [-52, 29],
            [-8, 29],
            [7, 74],
            [-38, 126],
            [30, 42],
            [21, 57],
            [-16, 38],
            [5, 40],
            [60, -47],
            [66, -34],
            [41, -51],
            [0, -43],
            [61, -70],
            [-38, -58],
            [-66, -27],
            [-47, -43],
            [-26, -62],
        ],
        [
            [4593, 1020],
            [25, -45],
            [31, 20],
            [69, -52],
            [-10, -47],
            [-70, -24],
            [-14, 9],
            [-4, 58],
            [-36, 13],
            [-14, 32],
            [23, 36],
        ],
        [
            [4509, 1080],
            [-21, -34],
            [-49, 0],
            [15, 37],
            [55, -3],
        ],
        [
            [4302, 1240],
            [24, -77],
            [-12, -45],
            [-46, -8],
            [-30, 92],
            [27, 2],
            [37, 36],
        ],
        [
            [3979, 1415],
            [24, -4],
            [12, -34],
            [-7, -48],
            [-25, -31],
            [-67, 38],
            [8, 54],
            [55, 25],
        ],
        [
            [13240, 10226],
            [0, -337],
            [69, -92],
            [1, -90],
            [41, -39],
            [43, -15],
            [5, -23],
            [79, -89],
            [10, -36],
            [56, -34],
            [2, -22],
            [62, 2],
            [-30, -122],
            [-6, -78],
            [22, -50],
            [-39, -36],
            [16, -35],
            [-11, -36],
            [46, -34],
            [52, 45],
            [21, 36],
            [39, -32],
            [-6, -27],
            [21, -63],
            [35, -67],
            [26, -23],
            [-2, -64],
            [25, -27],
            [44, -3],
            [28, -107],
            [25, -18],
            [22, 31],
            [67, -2],
            [49, 29],
            [29, -16],
            [50, 14],
            [11, -18],
            [43, 13],
            [49, 68],
            [34, -57],
            [38, -35],
        ],
        [
            [14306, 8737],
            [0, -814],
        ],
        [
            [14306, 7923],
            [-238, -2],
            [-401, 0],
        ],
        [
            [13667, 7921],
            [-636, 2],
        ],
        [
            [13031, 7923],
            [0, 602],
            [28, 108],
            [-18, 27],
            [-41, 5],
            [-15, 45],
            [43, 118],
            [23, 10],
            [22, 49],
            [-4, 31],
            [25, 39],
            [13, 58],
            [44, 97],
            [-17, 45],
            [-51, 24],
            [-29, 56],
        ],
        [
            [13054, 9237],
            [-1, 57],
            [-28, 58],
            [3, 27],
            [-1, 440],
            [3, 407],
        ],
        [
            [13030, 10226],
            [210, 0],
        ],
        [
            [18659, 8090],
            [395, -5],
            [210, 0],
        ],
        [
            [19264, 8085],
            [-7, -63],
            [33, -74],
            [34, -121],
        ],
        [
            [19324, 7827],
            [-1, -777],
            [-24, -59],
            [27, -71],
            [4, -57],
            [-27, -47],
            [-7, -43],
            [-39, -71],
            [-24, -5],
            [5, -42],
            [-16, -16],
            [-13, -77],
            [7, -22],
        ],
        [
            [19216, 6540],
            [-28, -47],
            [20, -58],
            [-88, -30],
            [-8, -34],
            [20, -44],
            [-27, -27],
            [-78, 51],
            [-25, -4],
            [-32, -58],
            [10, -18],
        ],
        [
            [18980, 6271],
            [-34, 4],
            [-48, 94],
            [18, 21],
            [-18, 63],
            [0, 51],
            [-68, 70],
            [-24, -7],
            [-23, 43],
            [-63, 67],
            [1, 52],
            [36, 85],
            [-6, 31],
            [21, 39],
            [-30, 24],
            [-47, 14],
            [-24, -30],
            [-17, 19],
            [-14, 108],
            [-71, 71],
            [-65, 85],
            [-27, 100],
            [-3, 67],
            [19, 47],
        ],
        [
            [18493, 7389],
            [4, 60],
            [59, 36],
            [6, 50],
            [28, 33],
            [4, 57],
            [-35, 47],
            [14, 58],
            [83, 16],
            [66, 41],
            [7, 51],
            [28, 22],
            [8, 63],
            [-6, 41],
            [-47, 33],
            [-6, 34],
            [-47, 59],
        ],
        [
            [19324, 7827],
            [21, -22],
            [65, 0],
            [63, 38],
        ],
        [
            [19473, 7843],
            [178, 0],
            [252, 0],
            [0, -21],
        ],
        [
            [19903, 7822],
            [1, -393],
            [-3, -460],
        ],
        [
            [19901, 6969],
            [-17, -14],
            [18, -91],
            [-37, -1],
            [-40, -31],
            [-55, 14],
            [2, -64],
            [-36, -27],
            [-14, -42],
            [-37, -16],
            [-20, -83],
            [-25, -22],
            [-48, 31],
            [-8, 38],
            [-47, -42],
            [4, -36],
            [-48, -12],
            [-14, 32],
            [-54, -32],
            [-17, -35],
            [-54, 49],
            [-28, -11],
            [-19, 24],
            [-17, -24],
            [-54, -3],
            [-20, -31],
        ],
        [
            [18493, 7389],
            [-23, 11],
            [-43, 67],
            [-23, -2],
            [-303, -9],
            [-294, -4],
            [-241, 6],
        ],
        [
            [17566, 7458],
            [-25, 43],
            [12, 85],
            [-21, 73],
            [1, 83],
            [-37, 29],
            [-6, 45],
            [13, 40],
            [-14, 58],
            [-30, 21],
            [-38, 148],
        ],
        [
            [17421, 8083],
            [-40, 72],
            [19, 49],
            [7, 65],
            [16, 23],
            [-27, 33],
            [7, 57],
            [-11, 27],
            [28, 8],
        ],
        [
            [17420, 8417],
            [1084, 0],
            [32, 0],
        ],
        [
            [18536, 8417],
            [3, -49],
            [31, -33],
            [-25, -39],
            [7, -74],
            [16, -52],
            [76, -38],
            [15, -42],
        ],
        [
            [16225, 7265],
            [31, 0],
            [1408, 0],
        ],
        [
            [17664, 7265],
            [21, -31],
            [69, -25],
            [-48, -96],
            [27, -32],
            [34, -78],
            [45, -16],
            [-1, -710],
        ],
        [
            [17811, 6277],
            [-1167, 0],
            [-417, -2],
        ],
        [
            [19901, 6969],
            [82, 0],
            [43, -68],
            [3, -29],
            [67, -13],
            [48, -45],
            [34, 23],
            [80, -25],
            [24, 33],
            [30, 10],
            [9, -55],
            [25, -9],
            [29, -46],
        ],
        [
            [20375, 6745],
            [-6, -99],
            [26, -63],
            [34, -48],
            [10, -38],
            [41, -38],
            [28, -6],
        ],
        [
            [20508, 6453],
            [-82, -88],
            [-78, -49],
            [0, -25],
            [-31, -21],
            [-3, -29],
            [-41, -13],
            [-14, -36],
            [-114, -47],
        ],
        [
            [20145, 6145],
            [-4, -5],
            [-182, 3],
            [-159, 11],
            [-42, -3],
            [-236, 12],
            [-268, -7],
            [-47, 14],
            [3, -59],
            [-265, 4],
            [-25, -4],
        ],
        [
            [18920, 6111],
            [11, 41],
            [32, -14],
            [17, 133],
        ],
        [
            [18547, 4961],
            [20, -38],
            [-15, -14],
            [-3, -67],
            [32, -42],
            [4, -97],
            [-25, -76],
            [-51, -46],
            [-12, -74],
            [-21, 7],
            [-4, -121],
            [-26, -4],
            [16, -64],
            [-16, -24],
            [403, 0],
            [-21, -108],
            [35, -72],
            [9, -54],
            [25, -34],
        ],
        [
            [18897, 4033],
            [-63, -46],
            [-4, -32],
            [51, -22],
            [22, 53],
            [45, -53],
            [-4, -41],
            [-26, -18],
            [-48, 16],
            [6, -31],
            [-16, -46],
            [41, -42],
            [65, -12],
            [24, -49],
            [18, -7],
            [-34, -56],
            [-37, 11],
            [-31, 57],
            [-79, 31],
            [0, 56],
            [-38, -18],
            [2, -47],
            [-17, -43],
            [-29, -8],
            [-21, 49],
            [-49, 2],
            [-17, -51],
            [-33, -14],
            [-35, 31],
            [-28, 3],
            [-27, 81],
            [-48, 36],
            [-18, -5],
            [-19, 69],
            [-56, -10],
            [-1, 42],
            [-55, -40],
            [7, -30],
            [-42, -29],
            [-66, 14],
            [-76, 45],
            [-53, 20],
            [-116, -16],
            [-15, -13],
        ],
        [
            [17977, 3870],
            [-19, 33],
            [50, 117],
            [-16, 63],
            [15, 34],
            [-7, 45],
            [21, 35],
            [22, 84],
            [-3, 71],
            [-59, 133],
            [-1, 72],
            [-46, 72],
            [0, 337],
        ],
        [
            [22911, 8271],
            [-26, 23],
            [4, 32],
            [-34, 38],
            [-14, 433],
            [-10, 213],
        ],
        [
            [22831, 9010],
            [92, 45],
            [-15, 23],
            [35, 51],
            [36, 23],
            [-7, 20],
            [34, 31],
            [-11, 57],
            [21, 87],
            [33, 29],
            [13, 91],
            [165, 253],
            [38, -11],
            [2, -61],
            [28, -22],
            [69, 36],
            [44, 0],
            [30, 24],
            [60, -53],
            [35, -43],
            [2, -369],
            [-5, -89],
            [74, -23],
            [-11, -38],
            [19, -36],
            [-15, -33],
            [30, -50],
            [40, 11],
            [38, -117],
            [-44, -53],
            [-26, 20],
            [-21, -36],
            [-30, 9],
            [-4, -31],
            [-39, 4],
            [-63, -70],
            [-16, 48],
            [-22, 4],
            [11, -52],
            [-49, -25],
            [-12, 41],
            [-23, -22],
            [-55, 0],
            [-1, 49],
            [-33, -11],
            [6, -34],
            [-31, -72],
            [6, -20],
            [-39, -40],
            [-40, 15],
            [-24, -42],
            [-32, -5],
            [-27, -34],
            [-33, 7],
            [-9, 36],
            [-48, -58],
            [13, -36],
            [-35, -12],
            [-3, -31],
            [-39, -38],
            [-32, -86],
        ],
        [
            [21985, 6755],
            [-42, -139],
        ],
        [
            [21943, 6616],
            [-33, -6],
            [-58, -20],
        ],
        [
            [21852, 6590],
            [-46, -14],
            [1, 54],
            [-17, 22],
            [24, 23],
            [-32, 54],
            [-11, -23],
            [-44, 5],
            [-16, 60],
            [14, 0],
            [2, 77],
            [14, 31],
            [-19, 104],
            [23, 62],
            [37, 11],
            [5, 63],
            [-27, -8],
            [-1, -32],
            [-56, -41],
            [-16, -38],
            [-4, -96],
            [-21, -45],
            [10, -76],
            [28, -52],
            [-4, -40],
            [18, -39],
            [-10, -27],
            [-49, 52],
            [-70, 25],
            [-21, 51],
            [-39, -29],
            [-16, 40],
            [32, 50],
        ],
        [
            [21541, 6814],
            [19, 52],
        ],
        [
            [21543, 6913],
            [-28, 31],
            [-44, 16],
            [0, 49],
            [-24, 27],
            [-32, 5],
        ],
        [
            [21415, 7041],
            [-25, 92],
            [-36, 0],
            [-36, 31],
            [-20, -26],
            [-35, 2],
            [-8, -36],
            [-63, 24],
            [-42, -49],
            [-28, 11],
            [-43, -56],
            [-42, -31],
            [3, 170],
        ],
        [
            [21040, 7173],
            [787, 0],
        ],
        [
            [22318, 7939],
            [-5, 12],
            [51, 217],
        ],
        [
            [22364, 8168],
            [173, -6],
        ],
        [
            [22537, 8162],
            [248, -10],
            [23, 30],
            [58, 33],
            [21, -6],
        ],
        [
            [22887, 8209],
            [8, -57],
            [-10, -47],
            [-33, -44],
            [-2, -50],
            [47, -7],
            [28, -53],
            [-5, -41],
            [24, -11],
            [2, -38],
            [60, -32],
            [69, 31],
            [-16, -46],
            [-101, -39],
            [-38, -2],
            [-22, 31],
            [-35, -9],
            [-1, -24],
            [-40, -14],
        ],
        [
            [22822, 7757],
            [-16, 59],
        ],
        [
            [22806, 7816],
            [-6, 11],
        ],
        [
            [22800, 7827],
            [-22, 24],
            [-12, 77],
            [-31, 0],
            [-58, -4],
        ],
        [
            [19473, 7843],
            [43, 44],
            [30, 73],
            [27, 46],
            [19, 63],
            [12, 90],
            [-5, 97],
            [-63, 191],
            [20, 72],
            [-14, 87],
            [49, 88],
            [11, 74],
            [-7, 40],
            [35, 16],
            [4, 54],
            [55, 14],
            [42, 60],
            [-3, -119],
            [22, -5],
            [28, 59],
            [1, 101],
            [18, 25],
            [58, 16],
            [-18, 71],
            [38, 59],
            [48, 4],
            [54, -38],
            [52, -5],
            [26, -47],
            [40, -4],
            [66, -43],
            [24, 2],
            [36, -71],
            [-29, -38],
            [28, -48],
            [10, -56],
            [-13, -123],
            [-43, -30],
            [-10, -63],
            [-52, -22],
            [-28, -76],
            [11, -29],
            [51, -27],
            [40, 42],
            [47, 85],
            [73, 32],
            [36, -25],
            [23, -47],
            [22, -137],
            [3, -69],
            [24, -83],
            [-23, -118],
            [-35, -19],
            [-1, 44],
            [-23, -13],
            [-27, -99],
            [-43, -38],
            [-13, -76],
            [-54, -63],
            [-3, -27],
        ],
        [
            [20192, 7834],
            [-289, -12],
        ],
        [
            [19754, 9150],
            [3, -39],
            [-28, -8],
            [12, 56],
            [13, -9],
        ],
        [
            [19310, 8941],
            [-33, 34],
            [20, 47],
            [-50, 7],
            [19, 46],
            [3, 57],
            [-45, 40],
            [-24, 41],
            [-91, 33],
            [-28, -11],
            [-91, 49],
            [-220, 66],
            [-23, 56],
            [-40, 20],
        ],
        [
            [18707, 9426],
            [83, 34],
            [37, 40],
            [94, 16],
            [60, 49],
            [28, 2],
            [24, 34],
            [66, 49],
            [34, 41],
            [51, 27],
            [47, -23],
            [-84, -101],
            [-20, -35],
            [2, -61],
            [41, 47],
            [73, -7],
            [57, -33],
            [52, -90],
            [28, -16],
            [54, 14],
            [12, -19],
            [54, -11],
            [115, 75],
            [59, 8],
            [80, -4],
            [53, 25],
            [41, 2],
            [8, -92],
            [42, -12],
            [42, 14],
            [18, -22],
            [28, 27],
            [62, 9],
            [1, -115],
            [28, -49],
            [42, -12],
            [5, 32],
            [41, 0],
            [22, -34],
            [-19, -25],
            [-117, 21],
            [-56, -14],
            [-60, 40],
            [-18, -36],
            [8, -31],
            [-27, 7],
            [-39, 45],
            [-69, 27],
            [-35, 2],
            [-34, -43],
            [-56, -11],
            [-61, 9],
            [-24, -18],
            [-6, -36],
            [-67, -31],
            [4, 43],
            [-30, 9],
            [-11, -45],
            [-49, -1],
            [-23, -20],
            [-32, -78],
            [-61, -99],
            [5, -9],
        ],
        [
            [19050, 9889],
            [-53, -41],
            [-28, -6],
            [2, 35],
            [134, 77],
            [-25, -54],
            [-30, -11],
        ],
        [
            [17420, 8417],
            [0, 591],
            [-49, 38],
            [-38, 63],
            [59, 70],
            [4, 38],
        ],
        [
            [17396, 9217],
            [-8, 132],
            [-26, 34],
            [-17, 72],
            [3, 88],
            [-8, 15],
            [-7, 211],
            [-42, 111],
            [-16, 63],
            [-7, 134],
            [14, 45],
            [-28, 104],
        ],
        [
            [17254, 10226],
            [442, 0],
            [0, 127],
            [43, -4],
            [28, -25],
            [28, -172],
            [22, -19],
            [70, -6],
            [8, -16],
            [82, -7],
            [9, -36],
            [70, 9],
            [0, 14],
            [55, 18],
            [48, -7],
            [55, -27],
            [15, -34],
            [32, 3],
            [29, -74],
            [14, 31],
            [54, 14],
            [9, -30],
            [63, -22],
            [0, -29],
            [32, -23],
            [64, 13],
            [38, 32],
            [53, 20],
            [19, -49],
            [36, 11],
            [43, -11],
            [50, 7],
            [58, -41],
            [54, 7],
            [-4, -18],
            [-71, -41],
            [-100, -33],
            [-64, -34],
            [-92, -85],
            [-40, -52],
            [-61, -60],
            [-96, -79],
            [17, -27],
        ],
        [
            [18366, 9471],
            [-17, 15],
            [-43, -28],
            [0, -194],
            [-13, -20],
            [-60, -27],
            [-49, -70],
            [-4, -47],
            [25, -4],
            [26, -41],
            [-24, -51],
            [5, -56],
            [-16, -120],
            [57, -60],
            [44, -5],
            [22, -36],
            [66, -36],
            [10, -44],
            [61, -56],
            [34, -12],
            [41, -72],
            [-6, -53],
            [11, -37],
        ],
        [
            [19138, 4094],
            [-23, -15],
            [-52, 8],
            [-21, 21],
            [-51, -14],
            [-71, -38],
            [-23, -23],
        ],
        [
            [18729, 5617],
            [393, 0],
            [57, 0],
        ],
        [
            [17811, 6113],
            [0, 164],
        ],
        [
            [17664, 7265],
            [-53, 86],
            [-45, 107],
        ],
        [
            [18920, 6111],
            [-14, 0],
            [-12, 0],
            [1, -81],
            [-42, -83],
        ],
        [
            [13240, 10226],
            [970, -1],
            [436, 1],
            [1153, 0],
        ],
        [
            [15799, 10226],
            [2, -375],
            [-2, -630],
        ],
        [
            [15799, 9221],
            [2, -312],
            [-4, 0],
        ],
        [
            [15797, 8909],
            [-396, 1],
            [-675, 0],
            [-421, 0],
            [1, -173],
        ],
        [
            [15798, 7595],
            [0, 658],
        ],
        [
            [15798, 8253],
            [156, 0],
            [362, -2],
            [667, 0],
            [7, -17],
            [110, -59],
            [25, 32],
            [31, -7],
            [100, 0],
            [112, -61],
            [14, -47],
            [39, -9],
        ],
        [
            [12397, 7921],
            [278, -2],
            [356, 4],
        ],
        [
            [13667, 7921],
            [-1, -1644],
        ],
        [
            [22911, 8271],
            [-24, -62],
        ],
        [
            [22537, 8162],
            [-18, 26],
            [2, 48],
            [19, 18],
            [-3, 47],
            [17, 139],
            [37, 65],
            [19, 74],
            [18, 27],
            [-1, 81],
            [72, 31],
            [35, 55],
            [-20, 55],
            [29, 54],
            [-2, 32],
        ],
        [
            [22741, 8914],
            [30, 85],
            [49, -9],
            [11, 20],
        ],
        [
            [21907, 7200],
            [57, 27],
            [3, 25],
            [66, 54],
            [10, 29],
            [-61, 67],
            [-2, 41],
            [-27, 11],
            [-2, 38],
            [33, 57],
            [-18, 35],
            [54, 68],
            [12, 36],
            [28, 24],
        ],
        [
            [22060, 7712],
            [98, -72],
            [71, -47],
            [-26, -96],
        ],
        [
            [22203, 7497],
            [-35, -21],
            [-19, -51],
            [59, -25],
            [5, -38],
            [-26, -177],
            [-67, -131],
            [-43, -38],
            [-38, -83],
            [-20, 54],
            [-62, 27],
            [-76, 72],
            [-5, 49],
            [-1, 7],
            [12, 18],
        ],
        [
            [16022, 6277],
            [0, -164],
        ],
        [
            [16022, 6113],
            [-8, 0],
            [-1, -819],
            [-5, -333],
            [0, -330],
            [-757, 0],
            [-5, -32],
            [24, -38],
        ],
        [
            [15270, 4561],
            [-358, 0],
            [0, -150],
            [-179, 0],
        ],
        [
            [22281, 7589],
            [92, -27],
            [18, 20],
            [79, 0],
            [39, 11],
            [66, 52],
            [4, -38],
            [34, -16],
            [-78, -49],
            [-165, -72],
            [-69, -14],
            [-45, 3],
            [-34, -16],
            [-19, 54],
        ],
        [
            [22060, 7712],
            [-10, 23],
            [-33, 2],
            [-38, 56],
            [5, 49],
            [-26, 37],
            [-16, -1],
            [-24, 45],
            [-939, 0],
            [0, 83],
            [0, 5],
        ],
        [
            [20979, 8011],
            [131, 94],
            [21, 45],
            [42, 30],
            [-16, 56],
            [-18, 11],
            [-13, 90],
            [125, 38],
            [111, -2],
            [45, -9],
            [47, -36],
            [31, 15],
            [92, -2],
            [56, 23],
            [60, 60],
            [38, 2],
            [1, 90],
            [20, 52],
            [-48, 36],
            [11, 41],
            [85, 56],
            [32, 49],
            [103, 110],
            [97, 56],
            [144, -9],
            [172, 7],
        ],
        [
            [22348, 8914],
            [2, -68],
            [-11, -62],
            [20, -59],
            [-6, -63],
            [-25, -67],
            [19, -90],
            [-12, -27],
            [34, -54],
            [-7, -227],
            [2, -29],
        ],
        [
            [20007, 5615],
            [6, 78],
            [42, 7],
            [16, 54],
            [53, 49],
            [58, 2],
            [53, 50],
            [55, 18],
            [47, 74],
            [29, 22],
            [6, -33],
            [84, 63],
            [38, -12],
            [27, 61],
            [40, 16],
            [9, 78],
        ],
        [
            [20570, 6142],
            [150, -9],
            [146, -6],
            [167, -2],
            [777, 4],
        ],
        [
            [21810, 6129],
            [24, -132],
            [-59, 13],
            [-9, -16],
            [-72, -20],
            [-11, -18],
            [-48, -5],
            [3, -24],
            [58, 16],
            [8, -14],
            [65, 16],
            [21, -31],
            [38, 13],
            [14, -79],
            [-13, -38],
            [-25, -4],
            [-54, -81],
            [-71, -3],
            [-12, -56],
            [30, -56],
            [25, -11],
            [-46, -92],
            [-38, 11],
            [-68, -9],
            [-47, -20],
            [-73, -63],
            [-59, -83],
            [-30, -105],
            [-44, 24],
            [-78, -22],
        ],
        [
            [21239, 5240],
            [-241, 314],
            [-240, 5],
            [4, 38],
            [-33, 56],
            [-22, -20],
            [-1, 35],
            [-263, 16],
            [-59, -13],
            [-45, -31],
            [-74, -21],
        ],
        [
            [17396, 9217],
            [-1597, 4],
        ],
        [
            [15799, 10226],
            [1455, 0],
        ],
        [
            [20192, 7834],
            [83, -45],
            [28, -27],
            [21, 25],
            [46, -52],
            [30, -16],
            [99, 43],
            [58, -9],
            [63, 61],
            [93, 60],
            [105, 41],
        ],
        [
            [20818, 7915],
            [0, -441],
        ],
        [
            [20818, 7474],
            [-32, -18],
            [15, -36],
            [-1, -51],
            [-29, -79],
            [-20, -121],
            [-83, -106],
            [-27, -15],
            [-23, 22],
            [-25, -47],
            [-24, 2],
            [-27, -63],
            [6, -38],
            [-22, -31],
            [-32, 51],
            [-40, -80],
            [11, -50],
            [-26, -18],
            [-8, -43],
            [-56, -8],
        ],
        [
            [17839, 5170],
            [-82, 36],
            [-21, 38],
            [-55, 32],
            [-14, -29],
            [-55, 2],
            [-11, 18],
            [-51, -32],
            [-21, 18],
            [-45, -16],
            [-42, -51],
            [-17, 29],
            [-44, 23],
            [-47, 0],
            [-15, 38],
            [-54, -74],
            [-17, 42],
            [-25, -13],
            [-18, 27],
            [-51, 26],
            [-37, -44],
            [-16, 45],
            [-31, 6],
            [-17, 36],
            [-41, 14],
            [-27, -30],
            [-17, 27],
            [-43, -4],
            [-46, 29],
            [-43, -4],
            [-16, 63],
            [-67, 4],
            [-26, -11],
            [-48, 63],
            [-16, -3],
            [0, 638],
            [-387, 0],
            [-254, 0],
        ],
        [
            [11498, 7923],
            [-30, 37],
            [-17, 107],
            [4, 74],
            [-29, 57],
            [21, 55],
            [15, 88],
            [32, 94],
            [14, 82],
            [23, 280],
            [-4, 38],
            [21, 122],
            [9, 170],
            [-12, 94],
            [10, 55],
            [86, 49],
        ],
        [
            [11641, 9325],
            [37, -38],
            [34, 9],
            [20, 4],
            [45, -34],
            [20, -40],
            [11, -99],
            [110, -36],
            [93, 52],
            [58, 5],
            [68, -18],
            [7, -21],
            [117, 47],
            [28, -17],
            [63, 9],
            [53, 33],
            [93, 29],
            [85, 7],
            [30, 22],
            [441, -2],
        ],
        [
            [21040, 7173],
            [-222, 0],
            [0, 301],
        ],
        [
            [20818, 7915],
            [40, 18],
            [121, 78],
        ],
        [
            [22822, 7757],
            [-42, -7],
            [26, 66],
        ],
        [
            [22800, 7827],
            [-26, 5],
            [-22, -48],
            [-7, -69],
            [-80, -16],
        ],
        [
            [21239, 5240],
            [-37, -16],
            [-47, -54],
            [-45, -85],
            [-8, -68],
            [-37, -54],
            [-47, 0],
            [-11, -40],
            [-50, -43],
            [-28, -47],
            [-45, -20],
            [-48, -50],
            [-4, -24],
            [-45, -27],
            [-47, -70],
        ],
        [
            [15798, 8253],
            [-1, 656],
        ],
        [
            [20145, 6145],
            [425, -3],
        ],
        [
            [17977, 3870],
            [-35, -2],
            [-111, -45],
            [-40, 26],
            [-7, 54],
            [-28, -38],
            [-20, 9],
            [-10, -47],
            [22, -20],
            [3, -61],
            [-39, -65],
            [-65, -81],
            [-128, -87],
            [-13, 15],
            [-39, -22],
            [-1, 20],
            [-52, -14],
            [-25, 41],
            [-15, -9],
            [56, -85],
            [-41, -27],
            [-38, 16],
            [-6, -59],
            [-48, -61],
            [-49, -114],
            [-32, -119],
            [-23, 9],
            [-6, -43],
            [25, 11],
            [-12, -87],
            [-16, -3],
            [-2, -49],
            [20, -27],
            [6, -99],
            [24, -35],
            [5, -63],
            [19, -56],
            [-65, -34],
            [-27, 44],
            [-50, 16],
            [-67, -4],
            [-57, 54],
            [-43, 6],
            [-33, 43],
            [-45, 14],
            [-30, 42],
            [-20, 99],
            [-38, 59],
            [4, 51],
            [-17, 54],
            [6, 47],
            [-27, 52],
            [-22, 5],
            [-37, 47],
            [-11, 60],
            [-32, 54],
            [-45, 45],
            [-23, 99],
            [-21, 27],
            [-28, 80],
            [-9, 64],
            [-27, 47],
            [-45, 42],
            [-11, 29],
            [-42, 25],
            [-33, 72],
            [-93, 16],
            [-56, -3],
            [-48, 25],
            [-11, -34],
            [-51, -11],
            [-39, -69],
            [-23, -110],
            [-13, -2],
            [-29, -64],
            [-35, -2],
            [-53, 50],
            [-132, 81],
            [-25, 44],
            [-52, 41],
            [-36, 94],
            [-2, 85],
            [-37, 68],
            [-8, 60],
            [-23, 37],
            [-83, 56],
            [-44, 76],
            [-37, 27],
            [-38, 65],
            [-54, 34],
            [-37, 87],
            [-32, 18],
        ],
        [
            [14306, 7923],
            [0, -330],
            [427, 0],
        ],
        [
            [22348, 8914],
            [221, -4],
            [172, 4],
        ],
        [
            [21541, 6814],
            [-26, -14],
            [-16, -47],
            [9, -35],
            [57, 11],
            [11, -52],
            [75, -22],
            [21, -41],
            [59, -45],
            [-27, -92],
            [25, -72],
            [-29, -34],
            [-4, -42],
            [27, -25],
            [-29, -40],
            [-44, 52],
            [-11, -18],
            [39, -37],
            [105, -9],
            [27, -123],
        ],
        [
            [20508, 6453],
            [-3, -27],
            [29, -55],
            [36, -27],
            [27, 1],
            [41, 44],
            [29, -35],
            [55, 18],
            [97, 63],
            [8, -19],
            [38, 28],
            [1, 60],
            [23, 52],
            [40, 49],
            [16, 59],
            [42, 62],
            [17, 75],
            [36, -45],
            [35, -14],
            [22, 27],
            [46, 117],
            [26, -29],
            [100, 134],
            [11, 97],
            [111, -110],
            [24, 63],
        ],
        [
            [21943, 6616],
            [-28, -56],
            [-30, -20],
            [-17, -76],
            [-44, -122],
            [-37, -26],
            [-11, 46],
            [18, 100],
            [58, 128],
        ],
        [
            [11641, 9325],
            [-39, 13],
            [-31, -20],
            [-41, 29],
            [8, 45],
            [28, 23],
            [-43, 69],
            [-29, 178],
            [-18, 24],
            [-23, 126],
            [-42, 48],
            [-18, 98],
            [23, 65],
            [44, -31],
            [87, -41],
            [60, 1],
            [59, -16],
            [56, 16],
            [27, -28],
            [51, 1],
            [35, -72],
            [25, 6],
            [5, -98],
            [15, -88],
            [21, 9],
            [-21, 76],
            [6, 74],
            [35, 75],
            [-28, 31],
            [-2, 54],
            [-21, 60],
            [10, 43],
            [-14, 50],
            [-34, 7],
            [-31, 38],
            [8, 36],
            [1221, 0],
        ],
        [
            [11817, 9999],
            [28, 15],
            [-5, -67],
            [-33, 25],
            [10, 27],
        ],
        [
            [11752, 10089],
            [23, 44],
            [32, -53],
            [-10, -45],
            [-49, 13],
            [4, 41],
        ],
        [
            [18366, 9471],
            [48, -4],
            [148, 56],
            [55, 31],
            [19, -23],
            [-30, -44],
            [71, -56],
            [30, -5],
        ],
        [
            [19310, 8941],
            [-8, -40],
            [-41, -7],
            [-35, -75],
            [-13, -53],
            [24, -9],
            [33, 35],
            [35, 64],
            [44, 26],
            [35, 83],
            [45, 18],
            [-4, -44],
            [-30, -39],
            [-60, -137],
            [-16, -76],
            [1, -54],
            [-22, -18],
            [-20, -74],
            [7, -63],
            [-19, -42],
            [-25, -102],
            [6, -81],
            [25, -73],
            [-8, -95],
        ],
        [
            [23819, 18],
            [-69, 7],
            [-33, -25],
            [-13, 14],
            [-48, -5],
            [12, 78],
            [-24, 55],
            [37, 51],
            [29, -9],
            [117, 0],
            [121, -18],
            [45, -22],
            [1, -54],
            [-22, -5],
            [-22, -56],
            [-86, -29],
            [-45, 18],
        ],
    ],
    transform: {
        scale: [0.004688518150890534, 0.0030382799863504523],
        translate: [-178.123152, 17.929556],
    },
    objects: {
        states: {
            type: 'GeometryCollection',
            geometries: [
                {
                    arcs: [[0, 1, 2, 3, 4]],
                    type: 'Polygon',
                    properties: { name: 'Alabama', state: 'AL', country: 'US' },
                },
                {
                    arcs: [
                        [[5]],
                        [[6]],
                        [[7]],
                        [[8]],
                        [[9]],
                        [[10]],
                        [[11]],
                        [[12]],
                        [[13]],
                        [[14]],
                        [[15]],
                        [[16]],
                        [[17]],
                        [[18]],
                        [[19]],
                        [[20]],
                        [[21]],
                        [[22]],
                        [[23]],
                        [[24]],
                        [[25]],
                        [[26]],
                        [[27]],
                        [[28]],
                        [[29]],
                        [[30]],
                        [[31]],
                        [[32]],
                        [[33]],
                        [[34]],
                        [[35]],
                        [[36]],
                        [[37]],
                        [[38]],
                        [[39]],
                        [[40]],
                        [[41]],
                        [[42]],
                    ],
                    type: 'MultiPolygon',
                    properties: { name: 'Alaska', state: 'AK', country: 'US' },
                },
                {
                    arcs: [[43, 44, 45, 46, 47]],
                    type: 'Polygon',
                    properties: { name: 'Arizona', state: 'AZ', country: 'US' },
                },
                {
                    arcs: [[48, 49, 50, 51, 52, 53]],
                    type: 'Polygon',
                    properties: { name: 'Arkansas', state: 'AR', country: 'US' },
                },
                {
                    arcs: [[-46, 54, 55, 56]],
                    type: 'Polygon',
                    properties: { name: 'California', state: 'CA', country: 'US' },
                },
                {
                    arcs: [[57, 58, 59, 60, 61, 62]],
                    type: 'Polygon',
                    properties: { name: 'Colorado', state: 'CO', country: 'US' },
                },
                {
                    arcs: [[63, 64, 65, 66]],
                    type: 'Polygon',
                    properties: { name: 'Connecticut', state: 'CT', country: 'US' },
                },
                {
                    arcs: [[67, 68, 69, 70]],
                    type: 'Polygon',
                    properties: { name: 'Delaware', state: 'DE', country: 'US' },
                },
                {
                    arcs: [[71, 72]],
                    type: 'Polygon',
                    properties: { name: 'District of Columbia', state: 'DC', country: 'US' },
                },
                {
                    arcs: [[-2, 73, 74]],
                    type: 'Polygon',
                    properties: { name: 'Florida', state: 'FL', country: 'US' },
                },
                {
                    arcs: [[-1, 75, 76, 77, 78, -74]],
                    type: 'Polygon',
                    properties: { name: 'Georgia', state: 'GA', country: 'US' },
                },
                {
                    arcs: [[[79]], [[80]], [[81]], [[82]], [[83]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Hawaii', state: 'HI', country: 'US' },
                },
                {
                    arcs: [[84, 85, 86, 87, 88, 89, 90]],
                    type: 'Polygon',
                    properties: { name: 'Idaho', state: 'ID', country: 'US' },
                },
                {
                    arcs: [[91, 92, 93, 94, 95, 96]],
                    type: 'Polygon',
                    properties: { name: 'Illinois', state: 'IL', country: 'US' },
                },
                {
                    arcs: [[-94, 97, 98, 99, 100]],
                    type: 'Polygon',
                    properties: { name: 'Indiana', state: 'IN', country: 'US' },
                },
                {
                    arcs: [[-97, 101, 102, 103, 104, 105]],
                    type: 'Polygon',
                    properties: { name: 'Iowa', state: 'IA', country: 'US' },
                },
                {
                    arcs: [[-59, 106, 107, 108]],
                    type: 'Polygon',
                    properties: { name: 'Kansas', state: 'KS', country: 'US' },
                },
                {
                    arcs: [[-95, -101, 109, 110, 111, 112, 113]],
                    type: 'Polygon',
                    properties: { name: 'Kentucky', state: 'KY', country: 'US' },
                },
                {
                    arcs: [[-51, 114, 115, 116]],
                    type: 'Polygon',
                    properties: { name: 'Louisiana', state: 'LA', country: 'US' },
                },
                {
                    arcs: [[117, 118]],
                    type: 'Polygon',
                    properties: { name: 'Maine', state: 'ME', country: 'US' },
                },
                {
                    arcs: [[-70, 119, 120, 121, 122, -73, 123, 124, 125]],
                    type: 'Polygon',
                    properties: { name: 'Maryland', state: 'MD', country: 'US' },
                },
                {
                    arcs: [[-67, 126, 127, 128, 129, 130, 131, 132]],
                    type: 'Polygon',
                    properties: { name: 'Massachusetts', state: 'MA', country: 'US' },
                },
                {
                    arcs: [[[-99, 133, 134]], [[135]], [[136, 137]], [[138]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Michigan', state: 'MI', country: 'US' },
                },
                {
                    arcs: [[-105, 139, 140, 141, 142]],
                    type: 'Polygon',
                    properties: { name: 'Minnesota', state: 'MN', country: 'US' },
                },
                {
                    arcs: [[-4, 143, -115, -50, 144]],
                    type: 'Polygon',
                    properties: { name: 'Mississippi', state: 'MS', country: 'US' },
                },
                {
                    arcs: [[-54, 145, -108, 146, -102, -96, -114, 147]],
                    type: 'Polygon',
                    properties: { name: 'Missouri', state: 'MO', country: 'US' },
                },
                {
                    arcs: [[-85, 148, 149, 150, 151]],
                    type: 'Polygon',
                    properties: { name: 'Montana', state: 'MT', country: 'US' },
                },
                {
                    arcs: [[-58, 152, 153, -103, -147, -107]],
                    type: 'Polygon',
                    properties: { name: 'Nebraska', state: 'NE', country: 'US' },
                },
                {
                    arcs: [[-47, -57, 154, -88, 155]],
                    type: 'Polygon',
                    properties: { name: 'Nevada', state: 'NV', country: 'US' },
                },
                {
                    arcs: [[-118, 156, -129, 157, 158]],
                    type: 'Polygon',
                    properties: { name: 'New Hampshire', state: 'NH', country: 'US' },
                },
                {
                    arcs: [[-68, 159, 160, 161]],
                    type: 'Polygon',
                    properties: { name: 'New Jersey', state: 'NJ', country: 'US' },
                },
                {
                    arcs: [[-44, -61, 162, 163, 164]],
                    type: 'Polygon',
                    properties: { name: 'New Mexico', state: 'NM', country: 'US' },
                },
                {
                    arcs: [[-66, 165, -161, 166, 167, 168, -127]],
                    type: 'Polygon',
                    properties: { name: 'New York', state: 'NY', country: 'US' },
                },
                {
                    arcs: [[-77, 169, 170, 171, 172]],
                    type: 'Polygon',
                    properties: { name: 'North Carolina', state: 'NC', country: 'US' },
                },
                {
                    arcs: [[-141, 173, -150, 174]],
                    type: 'Polygon',
                    properties: { name: 'North Dakota', state: 'ND', country: 'US' },
                },
                {
                    arcs: [[-100, -135, 175, 176, 177, -110]],
                    type: 'Polygon',
                    properties: { name: 'Ohio', state: 'OH', country: 'US' },
                },
                {
                    arcs: [[-53, 178, -163, -60, -109, -146]],
                    type: 'Polygon',
                    properties: { name: 'Oklahoma', state: 'OK', country: 'US' },
                },
                {
                    arcs: [[-56, 179, 180, -89, -155]],
                    type: 'Polygon',
                    properties: { name: 'Oregon', state: 'OR', country: 'US' },
                },
                {
                    arcs: [[-71, -126, 181, -177, 182, -167, -160]],
                    type: 'Polygon',
                    properties: { name: 'Pennsylvania', state: 'PA', country: 'US' },
                },
                {
                    arcs: [[[-131, 183]], [[-64, -133, 184]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Rhode Island', state: 'RI', country: 'US' },
                },
                {
                    arcs: [[-78, -173, 185]],
                    type: 'Polygon',
                    properties: { name: 'South Carolina', state: 'SC', country: 'US' },
                },
                {
                    arcs: [[-104, -154, 186, -151, -174, -140]],
                    type: 'Polygon',
                    properties: { name: 'South Dakota', state: 'SD', country: 'US' },
                },
                {
                    arcs: [[-5, -145, -49, -148, -113, 187, -170, -76]],
                    type: 'Polygon',
                    properties: { name: 'Tennessee', state: 'TN', country: 'US' },
                },
                {
                    arcs: [[-52, -117, 188, -164, -179]],
                    type: 'Polygon',
                    properties: { name: 'Texas', state: 'TX', country: 'US' },
                },
                {
                    arcs: [[-48, -156, -87, 189, -62]],
                    type: 'Polygon',
                    properties: { name: 'Utah', state: 'UT', country: 'US' },
                },
                {
                    arcs: [[-128, -169, 190, -158]],
                    type: 'Polygon',
                    properties: { name: 'Vermont', state: 'VT', country: 'US' },
                },
                {
                    arcs: [[[-72, -123, 191, -171, -188, -112, 192, -124]], [[-121, 193]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Virginia', state: 'VA', country: 'US' },
                },
                {
                    arcs: [[[-90, -181, 194]], [[195]], [[196]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Washington', state: 'WA', country: 'US' },
                },
                {
                    arcs: [[-111, -178, -182, -125, -193]],
                    type: 'Polygon',
                    properties: { name: 'West Virginia', state: 'WV', country: 'US' },
                },
                {
                    arcs: [[-92, -106, -143, 197, -137, 198]],
                    type: 'Polygon',
                    properties: { name: 'Wisconsin', state: 'WI', country: 'US' },
                },
                {
                    arcs: [[-63, -190, -86, -152, -187, -153]],
                    type: 'Polygon',
                    properties: { name: 'Wyoming', state: 'WY', country: 'US' },
                },
                {
                    arcs: [[199]],
                    type: 'Polygon',
                    properties: { name: 'Puerto Rico', state: 'PR', country: 'US' },
                },
            ],
        },
    },
};

export default data;
