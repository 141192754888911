const data = {
    type: 'Topology',
    arcs: [
        [
            [19911, 11219],
            [84, -47],
            [62, 16],
            [18, 55],
            [65, 19],
            [47, 37],
            [16, 99],
            [69, 24],
            [14, 43],
            [38, -32],
            [25, -4],
        ],
        [
            [20349, 11429],
            [47, -1],
            [62, -26],
        ],
        [
            [20458, 11402],
            [25, -15],
            [60, 40],
            [28, -24],
            [26, 56],
            [50, -2],
            [12, 18],
            [8, 49],
            [37, 43],
            [44, -28],
            [-9, -38],
            [25, -6],
            [-7, -102],
            [32, -41],
            [29, 26],
            [36, 12],
            [52, 56],
            [57, -10],
            [85, 0],
        ],
        [
            [21048, 11436],
            [12, -29],
            [-157, -54],
        ],
        [
            [20903, 11353],
            [-25, -3],
            [-89, -26],
            [-48, -54],
            [20, -51],
            [9, -62],
            [-41, -51],
            [4, -48],
            [-23, -44],
            [-79, 3],
            [32, -80],
            [-52, -31],
            [-35, -75],
            [4, -74],
            [-32, -34],
            [-30, 12],
            [-63, -17],
            [-10, -34],
            [-61, 0],
            [-45, -70],
            [-4, -104],
            [-107, -51],
            [-57, 10],
            [-17, -27],
            [-50, 17],
            [-82, -19],
            [-139, 62],
        ],
        [
            [19883, 10502],
            [75, 112],
            [-6, 79],
            [-63, 21],
            [-7, 79],
            [-27, 97],
            [36, 67],
            [-36, 19],
            [23, 89],
            [33, 154],
        ],
        [
            [16833, 5493],
            [8, -38],
            [-9, -60],
            [14, -57],
            [-12, -47],
            [7, -42],
            [-172, 2],
            [-4, -392],
            [56, -101],
            [54, -76],
        ],
        [
            [16775, 4682],
            [-152, -50],
            [-200, 18],
            [-57, 59],
            [-335, -6],
            [-12, -9],
            [-49, 56],
            [-54, 3],
            [-49, -21],
            [-40, -22],
        ],
        [
            [15827, 4710],
            [-8, 76],
            [12, 108],
            [28, 113],
            [5, 53],
            [26, 111],
            [20, 50],
            [47, 80],
            [27, 55],
            [9, 92],
            [-4, 69],
            [-25, 44],
            [-22, 74],
            [-21, 74],
            [4, 26],
            [26, 48],
            [-25, 120],
            [-17, 81],
            [-41, 78],
            [7, 24],
        ],
        [
            [15875, 6086],
            [35, 17],
            [24, -2],
            [29, 14],
            [243, -1],
            [20, -92],
            [24, -74],
            [20, -39],
            [31, -64],
            [55, 9],
            [27, 17],
            [45, -17],
            [13, 31],
            [21, 71],
            [50, 6],
            [6, 20],
            [41, 1],
            [-7, -44],
            [100, 1],
            [1, -78],
            [17, -46],
            [-11, -75],
            [5, -76],
            [28, -45],
            [-4, -147],
            [19, 12],
            [37, -3],
            [50, 18],
            [39, -7],
        ],
        [
            [15864, 6124],
            [-22, 93],
        ],
        [
            [15842, 6217],
            [33, 52],
            [25, 21],
            [31, -41],
        ],
        [
            [15931, 6249],
            [-30, -26],
            [-13, -32],
            [-3, -54],
            [-21, -13],
        ],
        [
            [16593, 11857],
            [-1, -33],
            [-27, -18],
            [-5, -39],
            [-38, -60],
        ],
        [
            [16522, 11707],
            [-14, 8],
            [-2, 28],
            [-46, 40],
            [-7, 59],
            [7, 84],
            [12, 39],
            [-15, 18],
        ],
        [
            [16457, 11983],
            [-5, 40],
            [35, 60],
            [6, -23],
            [22, 12],
        ],
        [
            [16515, 12072],
            [18, -34],
            [20, -12],
            [5, -45],
        ],
        [
            [16558, 11981],
            [-10, -42],
            [11, -53],
            [34, -29],
        ],
        [
            [19117, 9816],
            [14, 6],
            [3, -34],
            [65, 20],
            [68, -3],
            [50, -4],
            [56, 84],
            [62, 78],
            [52, 76],
        ],
        [
            [19487, 10039],
            [16, -42],
        ],
        [
            [19503, 9997],
            [11, -97],
        ],
        [
            [19514, 9900],
            [-43, -1],
            [-6, -80],
            [15, -16],
            [-38, -25],
            [0, -50],
            [-24, -51],
            [-3, -49],
        ],
        [
            [19415, 9628],
            [-16, -26],
            [-248, 62],
            [-31, 124],
            [-3, 28],
        ],
        [
            [9331, 88],
            [-50, 3],
            [-88, 0],
            [0, 275],
        ],
        [
            [9193, 366],
            [32, -57],
            [41, -92],
            [107, -74],
            [115, -31],
            [-36, -61],
            [-79, -6],
            [-42, 43],
        ],
        [
            [9684, 4101],
            [152, -201],
            [67, -18],
            [101, -91],
            [85, -48],
            [12, -54],
            [-82, -187],
            [84, -34],
            [93, -19],
            [65, 20],
            [75, 94],
            [13, 109],
        ],
        [
            [10349, 3672],
            [41, 24],
            [41, -72],
            [-1, -98],
            [-69, -67],
            [-56, -50],
            [-93, -119],
            [-111, -168],
        ],
        [
            [10101, 3122],
            [-20, -99],
            [-22, -126],
            [1, -122],
            [-18, -27],
            [-6, -80],
        ],
        [
            [10036, 2668],
            [-6, -64],
            [105, -106],
            [-12, -84],
            [52, -54],
            [-4, -60],
            [-80, -158],
            [-122, -66],
            [-165, -25],
            [-92, 13],
            [18, -74],
            [-17, -92],
            [16, -62],
            [-50, -43],
            [-85, -18],
            [-79, 45],
            [-32, -32],
            [12, -122],
            [56, -37],
            [44, 39],
            [25, -64],
            [-76, -38],
            [-66, -77],
            [-12, -123],
            [-20, -66],
            [-78, 0],
            [-64, -64],
            [-24, -92],
            [81, -90],
            [79, -25],
            [-28, -110],
            [-98, -69],
            [-54, -144],
            [-75, -49],
            [-34, -57],
            [27, -127],
            [55, -72],
            [-35, 6],
        ],
        [
            [9198, 407],
            [-77, 19],
            [-199, 17],
            [-34, 71],
            [2, 92],
            [-55, -7],
            [-29, 45],
            [-7, 129],
            [63, 55],
            [26, 78],
            [-10, 62],
            [44, 105],
            [30, 162],
            [-9, 73],
            [36, 23],
            [-8, 46],
            [-39, 25],
            [27, 51],
            [-37, 47],
            [-20, 142],
            [34, 25],
            [-14, 150],
            [20, 125],
            [22, 110],
            [49, 45],
            [-26, 120],
            [0, 113],
            [63, 80],
            [-1, 102],
            [46, 121],
            [0, 113],
            [-20, 22],
            [-39, 213],
            [51, 126],
            [-8, 119],
            [30, 111],
            [54, 116],
            [58, 76],
            [-25, 49],
            [18, 39],
            [-4, 205],
            [91, 61],
            [28, 127],
            [-10, 31],
        ],
        [
            [9319, 4041],
            [69, 111],
            [108, -30],
            [48, -88],
            [33, 98],
            [94, -5],
            [13, -26],
        ],
        [
            [18456, 11887],
            [115, 20],
        ],
        [
            [18571, 11907],
            [17, -32],
            [31, -22],
            [-17, -31],
            [45, -42],
            [-24, -39],
            [35, -33],
            [38, -21],
            [2, -85],
        ],
        [
            [18698, 11602],
            [-30, -4],
        ],
        [
            [18668, 11598],
            [-35, 71],
            [1, 19],
            [-36, 0],
            [-25, 33],
            [-17, -3],
        ],
        [
            [18556, 11718],
            [-33, 35],
            [-61, 31],
            [9, 60],
            [-15, 43],
        ],
        [
            [20549, 859],
            [53, -39],
            [78, -16],
            [3, -23],
            [-23, -55],
            [-126, -9],
            [-2, 66],
            [11, 50],
            [6, 26],
        ],
        [
            [26861, 1822],
            [79, -42],
            [45, 16],
            [65, 24],
            [49, -8],
            [6, -146],
            [-28, -43],
            [-9, -98],
            [-28, 33],
            [-58, -85],
            [-17, 6],
            [-51, 4],
            [-50, 105],
            [-12, 81],
            [-47, 107],
            [2, 56],
            [54, -10],
        ],
        [
            [26709, 5144],
            [30, -96],
            [53, 46],
            [27, -52],
            [39, -48],
            [-8, -54],
            [18, -106],
            [12, -61],
            [21, -16],
            [23, -104],
            [-8, -64],
            [26, -84],
            [90, -64],
            [58, -58],
            [56, -54],
            [-12, -30],
            [48, -77],
            [32, -132],
            [33, 27],
            [34, -54],
            [19, 20],
            [15, -131],
            [58, -76],
            [38, -46],
            [66, -100],
            [22, -98],
            [3, -71],
            [-6, -75],
            [39, -105],
            [-4, -109],
            [-15, -57],
            [-22, -110],
            [1, -70],
            [-15, -88],
            [-38, -112],
            [-61, -60],
            [-30, -96],
            [-27, -61],
            [-25, -106],
            [-31, -61],
            [-22, -92],
            [-10, -85],
            [4, -38],
            [-47, -43],
            [-93, -5],
            [-76, -50],
            [-38, -47],
            [-50, -53],
            [-68, 54],
            [-50, 21],
            [12, 65],
            [-44, -23],
            [-73, -89],
            [-71, 33],
            [-47, 20],
            [-48, 8],
            [-80, 35],
            [-52, 77],
            [-16, 93],
            [-19, 62],
            [-40, 50],
            [-80, 14],
            [27, 60],
            [-20, 92],
            [-40, -86],
            [-74, -22],
            [44, 67],
            [12, 72],
            [32, 60],
            [-7, 91],
            [-66, -104],
            [-52, -43],
            [-32, -97],
            [-65, 49],
            [3, 66],
            [-51, 89],
            [-44, 46],
            [16, 28],
            [-106, 75],
            [-58, 3],
            [-80, 60],
            [-147, -11],
            [-107, -45],
            [-95, -40],
            [-78, 8],
            [-88, -63],
            [-71, -28],
            [-17, -65],
            [-30, -50],
            [-69, -3],
            [-52, -11],
            [-74, 22],
            [-59, -13],
            [-57, -5],
            [-49, -66],
            [-24, 5],
            [-41, -34],
            [-40, -39],
            [-60, 5],
            [-56, 0],
            [-87, 78],
            [-44, 24],
            [2, 70],
            [40, 17],
            [15, 28],
            [-3, 44],
            [10, 85],
            [-9, 73],
            [-44, 124],
            [-13, 71],
            [3, 70],
            [-34, 80],
            [-2, 36],
            [-36, 49],
            [-10, 96],
            [-47, 98],
            [-11, 52],
            [36, -53],
            [-28, 114],
            [40, -36],
            [25, -47],
            [-1, 62],
            [-41, 97],
            [-9, 39],
            [-18, 37],
            [9, 70],
            [17, 31],
            [11, 62],
            [-9, 71],
            [34, 88],
            [6, -93],
            [35, 85],
            [68, 40],
            [39, 53],
            [64, 45],
            [37, 10],
            [23, -15],
            [65, 45],
            [50, 14],
            [12, 27],
            [22, 11],
            [46, -3],
            [87, 36],
            [44, 55],
            [22, 66],
            [48, 62],
            [4, 49],
            [2, 67],
            [58, 104],
            [34, -105],
            [36, 24],
            [-29, 58],
            [26, 60],
            [36, -27],
            [9, 93],
            [46, 61],
            [20, 49],
            [41, 21],
            [1, 34],
            [36, -15],
            [3, 31],
            [36, 18],
            [39, 17],
            [61, -57],
            [46, -73],
            [52, -1],
            [53, -11],
            [-18, 67],
            [40, 99],
            [37, 32],
            [-14, 31],
            [37, 70],
            [50, 44],
            [42, -15],
            [70, 23],
            [-2, 62],
            [-61, 42],
            [44, 17],
            [55, -31],
            [43, -50],
            [70, -32],
            [24, 13],
            [51, -38],
            [48, 36],
            [31, -11],
            [19, 23],
            [39, -60],
            [-22, -66],
            [-31, -50],
            [-29, -4],
            [9, -49],
            [-24, -61],
            [-29, -61],
            [5, -34],
            [67, -69],
            [63, -39],
            [42, -43],
            [61, -72],
            [22, 0],
            [44, -31],
            [12, -39],
            [79, -41],
            [54, 42],
            [17, 66],
            [16, 55],
            [11, 67],
            [25, 97],
            [-12, 60],
            [6, 36],
            [-9, 70],
            [10, 92],
            [16, 25],
            [-12, 42],
            [19, 65],
            [16, 67],
            [2, 36],
            [31, 45],
            [23, -60],
            [6, -77],
            [21, -14],
            [3, -52],
            [30, -63],
            [6, -70],
            [-3, -44],
        ],
        [
            [16260, 12751],
            [-6, -49],
            [-47, -1],
            [16, -26],
            [-27, -80],
        ],
        [
            [16196, 12595],
            [-16, -21],
            [-72, -3],
            [-42, -28],
            [-68, 10],
        ],
        [
            [15998, 12553],
            [-118, 32],
            [-18, 43],
            [-82, -22],
            [-9, -24],
            [-50, 18],
        ],
        [
            [15721, 12600],
            [-43, 3],
            [-37, 23],
            [13, 30],
            [-4, 22],
        ],
        [
            [15650, 12678],
            [25, 7],
            [42, -34],
            [12, 32],
            [73, -5],
            [59, 22],
            [39, -3],
            [26, -26],
            [8, 20],
            [-12, 81],
            [29, 16],
            [30, 56],
        ],
        [
            [15981, 12844],
            [61, -40],
            [46, 51],
            [29, 9],
            [64, -38],
            [40, 7],
            [37, -24],
        ],
        [
            [16258, 12809],
            [-6, -15],
            [8, -43],
        ],
        [
            [18668, 11598],
            [-57, 16],
            [-42, 57],
            [-13, 47],
        ],
        [
            [18571, 11907],
            [20, 19],
            [61, -35],
            [45, -7],
            [11, 14],
            [-40, 67],
            [21, 16],
        ],
        [
            [18689, 11981],
            [23, -4],
            [57, -74],
            [37, -9],
            [14, 31],
            [49, 50],
        ],
        [
            [18869, 11975],
            [43, -64],
            [42, -87],
            [39, -6],
            [25, -34],
            [-67, -9],
            [-15, -96],
            [-14, -43],
            [-30, -29],
            [2, -61],
        ],
        [
            [18894, 11546],
            [-21, -5],
            [-52, 64],
            [29, 60],
            [-25, 37],
            [-30, -10],
            [-97, -90],
        ],
        [
            [17281, 6283],
            [-6, 148],
            [-21, 56],
        ],
        [
            [17254, 6487],
            [51, -9],
            [25, 69],
            [43, -8],
        ],
        [
            [17373, 6539],
            [5, -48],
            [18, -28],
            [1, -40],
            [-21, -26],
            [-32, -63],
            [-30, -45],
            [-33, -6],
        ],
        [
            [15366, 13081],
            [-9, -83],
        ],
        [
            [15357, 12998],
            [-21, -5],
            [-9, -68],
        ],
        [
            [15327, 12925],
            [-73, 55],
            [-41, -9],
            [-58, 58],
            [-39, 49],
            [-38, 2],
            [-12, 44],
        ],
        [
            [15066, 13124],
            [66, 23],
        ],
        [
            [15132, 13147],
            [61, -9],
            [76, 26],
            [53, -54],
            [44, -29],
        ],
        [
            [15081, 7605],
            [-68, -14],
        ],
        [
            [15013, 7591],
            [-21, 85],
            [4, 282],
            [-17, 26],
            [-3, 60],
            [-29, 42],
            [-25, 37],
            [11, 65],
        ],
        [
            [14933, 8188],
            [28, 13],
            [17, 54],
            [41, 12],
            [17, 37],
        ],
        [
            [15036, 8304],
            [28, 35],
            [29, 1],
            [64, -70],
        ],
        [
            [15157, 8270],
            [-3, -42],
            [18, -72],
            [-16, -50],
            [8, -33],
            [-39, -76],
            [-26, -38],
            [-16, -77],
            [2, -79],
            [-4, -198],
        ],
        [
            [14933, 8188],
            [-73, 2],
        ],
        [
            [14860, 8190],
            [-37, 10],
            [-27, -19],
            [-37, 8],
            [-144, -5],
            [-1, -70],
            [11, -93],
        ],
        [
            [14625, 8021],
            [-56, 32],
            [-39, -5],
            [-29, -31],
            [-37, 27],
            [-15, 40],
            [-37, 27],
        ],
        [
            [14412, 8111],
            [-5, 71],
            [23, 53],
            [-2, 41],
            [65, 102],
            [12, 85],
            [23, 29],
            [40, -17],
            [34, 26],
            [11, 31],
            [65, 55],
            [15, 38],
            [78, 52],
            [44, 17],
            [21, -23],
            [53, 0],
        ],
        [
            [14889, 8671],
            [-6, -59],
            [11, -56],
            [47, -81],
            [2, -59],
            [95, -28],
            [-2, -84],
        ],
        [
            [22508, 9546],
            [-2, -88],
            [-28, 18],
            [6, -99],
        ],
        [
            [22484, 9377],
            [-24, 64],
            [-6, 62],
            [-15, 60],
            [-34, 72],
            [-76, 4],
            [7, -51],
            [-26, -68],
            [-35, 25],
            [-11, -22],
            [-24, 13],
            [-32, 11],
            [-13, 101],
            [-29, 93],
            [15, 74],
            [-51, 33],
            [17, 45],
            [20, 16],
            [32, 30],
            [-32, 35],
            [-27, 30],
            [23, 66],
            [6, 17],
            [36, -29],
            [30, -24],
            [39, -6],
            [7, -85],
            [79, -18],
            [76, 2],
            [43, -19],
            [5, -2],
            [-22, -61],
            [-16, -42],
            [-14, -3],
        ],
        [
            [22432, 9800],
            [-23, -5],
            [-4, -11],
            [-22, -59],
            [34, -48],
            [12, -15],
            [4, 26],
        ],
        [
            [22433, 9688],
            [9, 52],
            [16, 1],
            [7, 0],
            [43, -195],
        ],
        [
            [16729, 12274],
            [24, -51],
            [31, 9],
            [64, -20],
            [121, -6],
            [41, 31],
            [98, 29],
            [60, -44],
            [48, -14],
        ],
        [
            [17216, 12208],
            [-43, -50],
            [-30, -89],
            [27, -69],
        ],
        [
            [17170, 12000],
            [-72, 17],
            [-84, -40],
        ],
        [
            [17014, 11977],
            [-1, -61],
            [-74, -11],
            [-58, 43],
            [-67, -34],
            [-61, 3],
        ],
        [
            [16753, 11917],
            [-5, 82],
            [-42, 39],
        ],
        [
            [16706, 12038],
            [14, 18],
            [-10, 14],
            [15, 39],
            [31, 38],
            [-40, 54],
            [-8, 45],
            [21, 28],
        ],
        [
            [8458, 9757],
            [-20, -6],
            [-21, 70],
            [-31, 36],
            [18, 78],
            [25, -5],
            [28, -102],
            [1, -71],
        ],
        [
            [8435, 10103],
            [-91, -19],
            [-5, 45],
            [39, 10],
            [54, -4],
            [3, -32],
        ],
        [
            [8502, 10104],
            [-14, -87],
            [-15, 16],
            [1, 64],
            [-36, 49],
            [-1, 14],
            [65, -56],
        ],
        [
            [16445, 12187],
            [-16, -12],
            [-27, -29],
            [-11, -67],
        ],
        [
            [16391, 12079],
            [-74, 46],
            [-31, 51],
            [-31, 28],
            [-38, 46],
            [-18, 38],
            [-40, 57],
            [17, 51],
            [29, -28],
            [18, 26],
            [39, 2],
            [71, -20],
            [57, 1],
            [37, -27],
        ],
        [
            [16427, 12350],
            [30, 0],
            [-20, -54],
            [39, -46],
            [-11, -58],
            [-20, -5],
        ],
        [
            [16798, 13463],
            [78, -1],
            [91, 47],
            [18, 70],
            [69, 38],
            [-9, 56],
        ],
        [
            [17045, 13673],
            [50, 21],
            [89, 46],
        ],
        [
            [17184, 13740],
            [88, -30],
            [11, -31],
            [44, 15],
            [80, -29],
            [8, -58],
            [-18, -34],
            [52, -79],
            [34, -23],
            [-5, -22],
            [56, -22],
            [23, -33],
            [-32, -27],
            [-67, 4],
            [-15, -11],
            [19, -41],
            [20, -78],
        ],
        [
            [17482, 13241],
            [-70, -8],
            [-26, -27],
            [-6, -62],
            [-32, 13],
            [-74, -7],
            [-22, 29],
            [-31, -21],
            [-31, 17],
            [-66, 3],
            [-91, 29],
            [-84, 10],
            [-63, -2],
            [-46, -34],
            [-39, -5],
        ],
        [
            [16801, 13176],
            [-2, 55],
            [-25, 57],
            [49, 25],
            [0, 49],
            [-22, 47],
            [-3, 54],
        ],
        [
            [7500, 9025],
            [-1, 18],
            [10, 6],
            [15, -14],
            [30, 73],
            [16, 2],
        ],
        [
            [7570, 9110],
            [0, -18],
            [15, 0],
            [-1, -33],
            [-13, -54],
            [7, -19],
            [-8, -45],
            [5, -11],
            [-10, -62],
            [-16, -32],
            [-15, -4],
            [-16, -43],
        ],
        [
            [7518, 8789],
            [-25, 0],
            [6, 139],
            [1, 97],
        ],
        [
            [9319, 4041],
            [-60, -16],
            [-32, 168],
            [-45, 138],
            [26, 120],
            [-42, 51],
            [-12, 89],
            [-40, 84],
        ],
        [
            [9114, 4675],
            [52, 133],
            [-35, 103],
            [18, 41],
            [-14, 46],
            [32, 61],
            [2, 105],
            [4, 87],
            [18, 41],
            [-72, 198],
        ],
        [
            [9119, 5490],
            [61, -10],
            [43, 2],
            [19, 37],
            [71, 50],
            [44, 46],
            [108, 21],
            [-9, -92],
            [11, -48],
            [-8, -82],
            [91, -109],
            [92, -21],
            [32, -46],
            [56, -25],
            [34, -35],
            [52, 1],
            [48, -36],
            [4, -72],
            [16, -35],
            [1, -54],
            [-24, -2],
            [32, -143],
            [158, -5],
            [-12, -71],
            [8, -48],
            [46, -34],
            [19, -77],
            [-15, -97],
            [-23, -53],
            [9, -71],
            [-26, -25],
        ],
        [
            [10057, 4356],
            [-1, 38],
            [-77, 63],
            [-77, 2],
            [-144, -36],
            [-39, -108],
            [-2, -67],
            [-33, -147],
        ],
        [
            [10349, 3672],
            [17, 71],
            [10, 72],
            [1, 69],
            [-30, 21],
            [-31, -19],
            [-31, 5],
            [-9, 48],
            [-8, 112],
            [-15, 37],
            [-56, 33],
            [-33, -24],
            [-88, 24],
            [6, 167],
            [-25, 68],
        ],
        [
            [9119, 5490],
            [-47, -22],
            [-37, 15],
            [5, 186],
            [-67, -72],
            [-73, 3],
            [-32, 66],
            [-54, 6],
            [17, 53],
            [-46, 75],
            [-34, 111],
            [22, 23],
            [0, 51],
            [49, 36],
            [-8, 66],
            [22, 44],
            [5, 57],
            [95, 84],
            [68, 23],
            [10, 18],
            [75, -5],
        ],
        [
            [9089, 6308],
            [37, 337],
            [2, 53],
            [-13, 70],
            [-36, 46],
            [0, 89],
            [46, 19],
            [17, -12],
            [3, 48],
            [-49, 12],
            [-1, 77],
            [161, -3],
            [27, 42],
            [24, -38],
            [15, -73],
            [16, 15],
        ],
        [
            [9338, 6990],
            [46, -64],
            [64, 7],
            [15, 38],
            [62, 28],
            [34, 21],
            [9, 52],
            [59, 35],
            [-4, 26],
            [-69, 10],
            [-13, 77],
            [4, 83],
            [-37, 32],
            [16, 11],
            [61, -16],
            [65, -30],
            [24, 29],
            [59, 18],
            [92, 47],
            [31, 47],
            [-11, 34],
        ],
        [
            [9845, 7475],
            [43, 6],
            [19, -29],
            [-11, -54],
            [29, -18],
            [19, -57],
            [-23, -44],
            [-14, -105],
            [22, -62],
            [6, -56],
            [50, -58],
            [42, -6],
            [8, 24],
            [26, 5],
            [37, 22],
            [27, 33],
            [46, -11],
            [21, 5],
        ],
        [
            [10192, 7070],
            [44, -11],
            [7, 25],
            [-13, 25],
            [8, 35],
            [33, -10],
            [39, 12],
            [48, -26],
        ],
        [
            [10358, 7120],
            [36, -26],
            [25, 34],
            [18, -4],
            [12, -36],
            [39, 10],
            [32, 46],
            [25, 90],
            [49, 113],
        ],
        [
            [10594, 7347],
            [28, 6],
            [21, -68],
            [46, -215],
            [44, -20],
            [2, -85],
            [-61, -102],
            [25, -37],
            [146, -19],
            [3, -123],
            [62, 81],
            [104, -45],
            [138, -74],
            [39, -73],
            [-13, -68],
            [96, 38],
            [161, -65],
            [124, 5],
            [121, -102],
            [106, -137],
            [63, -36],
            [71, -5],
            [30, -39],
            [28, -157],
            [13, -73],
            [-32, -203],
            [-43, -81],
            [-116, -171],
            [-53, -139],
            [-61, -107],
            [-20, -2],
            [-23, -90],
            [6, -231],
            [-23, -189],
            [-9, -82],
            [-26, -48],
            [-15, -164],
            [-84, -161],
            [-13, -126],
            [-68, -54],
            [-18, -74],
            [-91, 1],
            [-129, -48],
            [-58, -54],
            [-93, -36],
            [-97, -98],
            [-70, -121],
            [-12, -93],
            [14, -67],
            [-15, -124],
            [-19, -61],
            [-58, -67],
            [-91, -217],
            [-73, -97],
            [-56, -58],
            [-38, -117],
            [-54, -70],
        ],
        [
            [10453, 2685],
            [-23, 70],
            [36, 58],
            [-47, 84],
            [-66, 67],
            [-85, 79],
            [-30, -3],
            [-83, 95],
            [-54, -13],
        ],
        [
            [24286, 7392],
            [32, 47],
            [71, 67],
        ],
        [
            [24389, 7506],
            [-4, -61],
            [-4, -78],
            [-40, 3],
            [-17, -41],
            [-38, 63],
        ],
        [
            [22462, 10211],
            [-6, -76],
            [-68, -4],
            [-32, 4],
            [-37, 5],
            [-18, -7],
            [-34, -12],
            [-75, 47],
            [-1, 14],
            [-1, 9],
            [55, 93],
            [45, 31],
            [58, -29],
            [43, -3],
            [37, -34],
            [16, -19],
            [18, -19],
        ],
        [
            [17288, 4121],
            [-117, -91],
            [-74, -91],
            [-28, -82],
            [-25, -47],
            [-44, -9],
            [-15, -59],
            [-8, -39],
            [-53, -29],
            [-67, 6],
            [-40, 35],
            [-34, 15],
            [-40, -29],
            [-20, -59],
            [-40, -36],
            [-41, -55],
            [-59, -13],
            [-19, 43],
            [8, 76],
            [-49, 116],
            [-22, 18],
        ],
        [
            [16501, 3791],
            [0, 359],
            [81, 4],
            [3, 439],
            [61, 4],
            [128, 42],
            [31, -51],
            [53, 49],
            [25, 0],
            [46, 28],
        ],
        [
            [16929, 4665],
            [15, -9],
        ],
        [
            [16944, 4656],
            [32, -99],
            [17, -21],
            [25, -72],
            [94, -135],
            [35, -13],
            [0, -43],
            [25, -78],
            [63, -19],
            [53, -55],
        ],
        [
            [16120, 7748],
            [68, 10],
            [15, 31],
            [14, -2],
            [20, -28],
            [104, 47],
            [35, 48],
            [44, 43],
            [-8, 43],
            [23, 12],
            [79, -8],
            [78, 56],
            [59, 135],
            [43, 50],
            [52, 21],
        ],
        [
            [16746, 8206],
            [9, -53],
            [48, -77],
            [0, -50],
            [-14, -52],
            [6, -37],
            [28, -36],
            [8, -5],
        ],
        [
            [16831, 7896],
            [56, -48],
            [44, -50],
            [1, -40],
            [56, -64],
            [35, -53],
            [20, -74],
            [62, -49],
            [13, -39],
        ],
        [
            [17118, 7479],
            [-27, -12],
            [-53, 3],
            [-62, 12],
            [-31, -10],
            [-13, -30],
            [-26, -4],
            [-33, 27],
            [-91, -63],
            [-38, 13],
            [-12, -10],
            [-24, -74],
            [-62, 25],
            [-60, 11],
            [-53, 46],
            [-67, 41],
            [-45, -39],
            [-32, -62],
            [-7, -86],
        ],
        [
            [16382, 7267],
            [-53, 7],
            [-56, 21],
            [-49, -66],
            [-44, -114],
        ],
        [
            [16180, 7115],
            [-8, 35],
            [-4, 56],
            [-37, 40],
            [-31, 63],
            [-8, 45],
            [-38, 64],
            [6, 36],
            [-8, 52],
            [6, 95],
            [21, 23],
            [41, 124],
        ],
        [
            [9603, 12558],
            [60, -16],
            [76, 3],
            [-40, -51],
            [-30, -8],
            [-105, 53],
            [-21, 41],
            [31, 38],
            [29, -60],
        ],
        [
            [9757, 12873],
            [-40, -3],
            [-107, 39],
            [-77, 58],
            [28, 10],
            [109, -31],
            [84, -52],
            [3, -21],
        ],
        [
            [4663, 12799],
            [-41, -18],
            [-136, 56],
            [-25, 44],
            [-74, 44],
            [-14, 35],
            [-85, 22],
            [-32, 67],
            [7, 28],
            [87, -27],
            [50, -19],
            [78, -12],
            [28, -43],
            [41, -59],
            [82, -51],
            [34, -67],
        ],
        [
            [10225, 13067],
            [-55, -108],
            [54, 41],
            [56, -26],
            [-29, -42],
            [72, -34],
            [39, 30],
            [83, -38],
            [-26, -90],
            [58, 21],
            [10, -65],
            [25, -77],
            [-34, -108],
            [-37, -4],
            [-54, 23],
            [17, 100],
            [-23, 16],
            [-96, -107],
            [-49, 4],
            [58, 58],
            [-79, 30],
            [-89, -7],
            [-159, 3],
            [-13, 37],
            [51, 43],
            [-36, 34],
            [69, 74],
            [86, 196],
            [51, 69],
            [72, 43],
            [38, -6],
            [-17, -33],
            [-43, -77],
        ],
        [
            [3904, 13479],
            [79, 9],
            [-25, -139],
            [72, -99],
            [-33, 0],
            [-49, 57],
            [-32, 56],
            [-41, 38],
            [-16, 54],
            [6, 40],
            [39, -16],
        ],
        [
            [8315, 14477],
            [-32, -65],
            [-36, 10],
            [-22, 38],
            [4, 8],
            [31, 37],
            [35, -3],
            [20, -25],
        ],
        [
            [8099, 14544],
            [-98, -67],
            [-58, 2],
            [-18, 34],
            [61, 56],
            [114, -1],
            [-1, -24],
        ],
        [
            [7829, 14907],
            [15, -55],
            [42, 20],
            [49, -32],
            [90, -43],
            [94, -38],
            [8, -58],
            [61, 9],
            [58, -40],
            [-73, -39],
            [-128, 30],
            [-47, 55],
            [-82, -66],
            [-117, -63],
            [-28, 72],
            [-112, -12],
            [71, 60],
            [11, 98],
            [28, 112],
            [60, -10],
        ],
        [
            [8596, 15091],
            [-93, -7],
            [-20, 61],
            [35, 68],
            [75, 18],
            [65, -35],
            [1, -52],
            [-10, -17],
            [-53, -36],
        ],
        [
            [6963, 15331],
            [-51, -43],
            [-111, 37],
            [-67, -14],
            [-114, 56],
            [73, 38],
            [58, 54],
            [87, -36],
            [50, -21],
            [25, -24],
            [50, -47],
        ],
        [
            [9316, 12385],
            [-54, 69],
            [0, 168],
            [-36, 35],
            [-55, -21],
            [-28, 33],
            [-63, -94],
            [-25, -95],
            [-29, -56],
            [-35, -19],
            [-26, -6],
            [-9, -30],
            [-152, 0],
            [-126, -1],
            [-37, -23],
            [-87, -89],
            [-11, -9],
            [-25, -48],
            [-76, 0],
            [-82, 0],
            [-36, -20],
            [12, -25],
            [8, -37],
            [-2, -12],
            [-108, -61],
            [-85, -19],
            [-96, -66],
            [-20, 0],
            [-29, 19],
            [-8, 18],
            [1, 12],
            [19, 44],
            [38, 67],
            [24, 73],
            [-16, 107],
            [-17, 111],
            [-87, 58],
            [10, 22],
            [-12, 15],
            [-22, 0],
            [-17, 19],
            [-4, 29],
            [-17, -12],
            [-22, 3],
            [6, 13],
            [-20, 12],
            [-7, 32],
            [-65, 40],
            [-67, 40],
            [-80, 48],
            [-78, 44],
            [-73, -34],
            [-27, -1],
            [-102, 31],
            [-67, -15],
            [-80, 38],
            [-84, 20],
            [-58, 7],
            [-26, 21],
            [-15, 67],
            [-28, 0],
            [0, -48],
            [-171, 0],
            [-282, 0],
            [-280, 0],
            [-248, 0],
            [-248, 0],
            [-244, 0],
            [-251, 0],
            [-82, 0],
            [-244, 0],
            [-235, 0],
        ],
        [
            [4718, 12859],
            [-11, 0],
            [-160, 121],
            [-58, 53],
            [-150, 51],
            [-46, 109],
            [11, 76],
            [-104, 51],
            [-15, 100],
            [-100, 89],
            [-2, 63],
        ],
        [
            [4083, 13572],
            [46, 59],
            [-2, 78],
            [-141, 79],
            [-84, 140],
            [-52, 88],
            [-76, 56],
            [-55, 50],
            [-43, 63],
            [-83, -40],
            [-81, -68],
            [-74, 81],
            [-57, 54],
            [-81, 33],
            [-81, 4],
            [0, 700],
            [2, 457],
        ],
        [
            [3221, 15406],
            [153, -30],
            [131, -59],
            [86, -12],
            [72, 51],
            [100, 38],
            [122, -14],
            [124, 54],
            [136, 31],
            [56, -52],
            [62, 29],
            [18, 58],
            [57, -13],
            [140, -110],
            [110, 83],
            [11, -94],
            [102, 21],
            [31, 36],
            [99, -7],
            [127, -52],
            [193, -45],
            [113, -21],
            [81, 8],
            [112, -62],
            [-116, -61],
            [149, -27],
            [223, 15],
            [69, 21],
            [89, -73],
            [90, 62],
            [-85, 52],
            [54, 42],
            [100, 5],
            [66, 13],
            [67, -29],
            [83, -67],
            [92, 10],
            [146, -55],
            [128, 19],
            [121, -3],
            [-10, 76],
            [73, 22],
            [128, -42],
            [-1, -116],
            [53, 98],
            [67, -3],
            [37, 123],
            [-88, 76],
            [-97, 49],
            [7, 136],
            [97, 89],
            [109, -19],
            [84, -54],
            [112, -139],
            [-74, -60],
            [154, -25],
            [0, -126],
            [110, 97],
            [98, -80],
            [-24, -91],
            [80, -83],
            [86, 89],
            [61, 107],
            [4, 135],
            [117, -10],
            [122, -18],
            [111, -62],
            [6, -61],
            [-63, -65],
            [58, -65],
            [-10, -60],
            [-162, -87],
            [-114, -18],
            [-86, 36],
            [-24, -61],
            [-80, -104],
            [-24, -54],
            [-96, -82],
            [-117, -9],
            [-66, -52],
            [-6, -79],
            [-95, -16],
            [-102, -99],
            [-89, -139],
            [-32, -97],
            [-4, -143],
            [121, -20],
            [37, -116],
            [39, -93],
            [115, 25],
            [153, -54],
            [83, -46],
            [59, -59],
            [103, -33],
            [88, -52],
            [136, -7],
            [90, -12],
            [-13, -106],
            [25, -124],
            [60, -137],
            [123, -117],
            [64, 40],
            [44, 127],
            [-43, 193],
            [-58, 65],
            [132, 58],
            [94, 86],
            [45, 86],
            [-7, 82],
            [-56, 104],
            [-99, 93],
            [97, 128],
            [-36, 112],
            [-28, 192],
            [58, 28],
            [141, -33],
            [85, -13],
            [68, 33],
            [77, -42],
            [102, -71],
            [25, -47],
            [147, -10],
            [-2, -103],
            [27, -156],
            [76, -19],
            [60, -72],
            [119, 67],
            [79, 136],
            [55, 57],
            [64, -109],
            [107, -157],
            [91, -147],
            [-33, -77],
            [110, -69],
            [75, -71],
            [130, -32],
            [54, -39],
            [32, -104],
            [65, -17],
            [33, -45],
            [6, -139],
            [-60, -46],
            [-59, -43],
            [-136, -44],
            [-104, -101],
            [-140, -20],
            [-176, 26],
            [-125, 1],
            [-85, -8],
            [-69, -89],
            [-105, -54],
            [-119, -163],
            [-95, -113],
            [70, 20],
            [133, 161],
            [173, 103],
            [123, 12],
            [73, -60],
            [-78, -83],
            [26, -132],
            [27, -93],
            [108, -62],
            [136, 18],
            [83, 139],
            [6, -90],
            [53, -44],
            [-102, -81],
            [-183, -72],
            [-82, -50],
            [-93, -89],
            [-62, 9],
            [-3, 104],
            [143, 101],
            [-132, -4],
            [-92, -14],
        ],
        [
            [5434, 15825],
            [-41, -59],
            [184, 38],
            [114, -62],
            [93, 63],
            [76, -41],
            [68, -121],
            [41, 52],
            [-58, 125],
            [73, 18],
            [82, -20],
            [92, -48],
            [52, -121],
            [26, -87],
            [138, -60],
            [149, -58],
            [-9, -55],
            [-135, -9],
            [52, -48],
            [-28, -44],
            [-150, 18],
            [-141, 33],
            [-96, -7],
            [-155, -41],
            [-209, -19],
            [-148, -11],
            [-44, 58],
            [-112, 33],
            [-74, -14],
            [-101, 98],
            [55, 13],
            [127, 21],
            [116, -6],
            [108, 22],
            [-160, 29],
            [-176, -11],
            [-117, 3],
            [-44, 46],
            [192, 49],
            [-127, -2],
            [-145, 33],
            [70, 91],
            [57, 50],
            [221, 74],
            [84, -23],
        ],
        [
            [6233, 15861],
            [-73, -81],
            [-129, 86],
            [28, 18],
            [110, 4],
            [64, -27],
        ],
        [
            [8557, 15822],
            [7, -34],
            [-88, 3],
            [-88, 3],
            [-91, -16],
            [-24, 7],
            [-90, 65],
            [3, 45],
            [40, 8],
            [189, -13],
            [142, -68],
        ],
        [
            [7713, 15829],
            [65, -77],
            [77, 99],
            [208, 51],
            [142, -127],
            [-12, -81],
            [163, 36],
            [79, 49],
            [182, -62],
            [114, -59],
            [11, -53],
            [153, 27],
            [86, -78],
            [199, -49],
            [72, -49],
            [79, -116],
            [-152, -57],
            [194, -80],
            [131, -27],
            [119, -113],
            [130, -8],
            [-26, -87],
            [-145, -142],
            [-101, 52],
            [-131, 118],
            [-106, -15],
            [-11, -71],
            [87, -70],
            [112, -57],
            [35, -33],
            [54, -122],
            [-30, -88],
            [-103, 34],
            [-208, 98],
            [118, -106],
            [86, -74],
            [13, -43],
            [-224, 49],
            [-177, 71],
            [-100, 60],
            [28, 35],
            [-122, 63],
            [-121, 60],
            [1, -36],
            [-238, -20],
            [-70, 43],
            [54, 90],
            [156, 2],
            [169, 16],
            [-27, 44],
            [29, 61],
            [106, 120],
            [-22, 54],
            [-32, 43],
            [-127, 59],
            [-167, 42],
            [53, 31],
            [-88, 76],
            [-72, 7],
            [-65, 42],
            [-44, -37],
            [-150, -15],
            [-301, 28],
            [-174, 36],
            [-134, 18],
            [-69, 43],
            [87, 56],
            [-117, 1],
            [-27, 125],
            [64, 110],
            [84, 49],
            [214, 33],
            [-61, -79],
        ],
        [
            [6575, 15914],
            [97, -26],
            [148, 15],
            [21, -36],
            [-76, -59],
            [124, -53],
            [-14, -110],
            [-136, -47],
            [-79, 10],
            [-57, 47],
            [-206, 94],
            [3, 39],
            [168, -14],
            [-92, 80],
            [99, 60],
        ],
        [
            [7165, 15781],
            [-88, -91],
            [-95, 4],
            [-50, 108],
            [1, 61],
            [42, 53],
            [82, 33],
            [172, -4],
            [158, -30],
            [-123, -110],
            [-99, -24],
        ],
        [
            [4915, 15611],
            [-217, -59],
            [-44, 54],
            [-191, 64],
            [35, 53],
            [58, 89],
            [72, 81],
            [-81, 76],
            [279, 18],
            [117, -25],
            [212, -7],
            [80, -35],
            [88, -52],
            [-104, -31],
            [-202, -86],
            [-102, -86],
            [0, -54],
        ],
        [
            [7131, 16053],
            [-45, -48],
            [-120, 9],
            [-100, 32],
            [44, 55],
            [119, 33],
            [72, -42],
            [30, -39],
        ],
        [
            [6727, 16267],
            [64, -57],
            [3, -63],
            [-39, -91],
            [-136, -13],
            [-88, 19],
            [1, 72],
            [-135, -9],
            [-5, 94],
            [88, -4],
            [125, 43],
            [116, -8],
            [6, 17],
        ],
        [
            [5926, 16203],
            [33, -44],
            [72, 21],
            [87, -5],
            [15, -60],
            [-51, -59],
            [-279, -19],
            [-208, -53],
            [-126, -3],
            [-10, 40],
            [171, 54],
            [-372, -14],
            [-117, 22],
            [113, 120],
            [78, 34],
            [233, -41],
            [146, -73],
            [144, -9],
            [-118, 117],
            [76, 44],
            [86, -13],
            [27, -59],
        ],
        [
            [7043, 16313],
            [91, -40],
            [163, 1],
            [71, -40],
            [-18, -47],
            [94, -28],
            [52, -29],
            [112, -5],
            [120, -10],
            [131, 27],
            [168, 10],
            [135, -9],
            [88, -46],
            [19, -51],
            [-52, -33],
            [-123, -26],
            [-105, 15],
            [-238, -19],
            [-169, -2],
            [-134, 15],
            [-219, 39],
            [-29, 68],
            [-9, 60],
            [-83, 54],
            [-171, 16],
            [-96, 37],
            [32, 51],
            [170, -8],
        ],
        [
            [5267, 16380],
            [-11, -94],
            [-65, -43],
            [-77, -6],
            [-153, -52],
            [-132, -19],
            [-112, 26],
            [140, 93],
            [169, 80],
            [128, -3],
            [113, 18],
        ],
        [
            [7112, 16366],
            [-37, -5],
            [-155, 9],
            [-22, 34],
            [166, -2],
            [58, -23],
            [-10, -13],
        ],
        [
            [5763, 16387],
            [-154, -36],
            [-122, 40],
            [66, 40],
            [121, 12],
            [117, -19],
            [-28, -37],
        ],
        [
            [5806, 16498],
            [-100, -24],
            [-137, 0],
            [1, 18],
            [85, 36],
            [43, -5],
            [108, -25],
        ],
        [
            [6948, 16431],
            [-122, -25],
            [-68, 28],
            [-35, 47],
            [-6, 50],
            [107, -5],
            [47, -8],
            [99, -43],
            [-22, -44],
        ],
        [
            [6599, 16464],
            [32, -51],
            [-135, 14],
            [-136, 39],
            [-183, 5],
            [79, 37],
            [-99, 29],
            [-6, 47],
            [161, -16],
            [223, -46],
            [64, -58],
        ],
        [
            [7675, 16628],
            [100, -40],
            [-113, -36],
            [-153, -92],
            [-146, -9],
            [-171, 15],
            [-89, 51],
            [2, 44],
            [64, 33],
            [-150, -1],
            [-91, 41],
            [-52, 55],
            [57, 55],
            [57, 37],
            [85, 9],
            [-37, 28],
            [192, 7],
            [106, -67],
            [139, -26],
            [135, -22],
            [65, -82],
        ],
        [
            [9204, 17052],
            [221, -10],
            [176, -15],
            [152, -34],
            [-3, -33],
            [-203, -53],
            [-199, -25],
            [-74, -27],
            [179, 0],
            [-195, -75],
            [-134, -34],
            [-141, -101],
            [-171, -20],
            [-52, -25],
            [-250, -14],
            [113, -14],
            [-57, -23],
            [69, -60],
            [-78, -43],
            [-128, -34],
            [-39, -49],
            [-115, -36],
            [11, -28],
            [141, 4],
            [2, -29],
            [-221, -74],
            [-215, 34],
            [-243, -19],
            [-122, 15],
            [-157, 6],
            [-10, 59],
            [152, 28],
            [-40, 89],
            [50, 8],
            [221, -52],
            [-113, 78],
            [-133, 24],
            [66, 47],
            [147, 30],
            [23, 42],
            [-116, 48],
            [-35, 64],
            [225, -6],
            [65, -12],
            [130, 44],
            [-187, 14],
            [-288, -8],
            [-147, 42],
            [-68, 50],
            [-97, 36],
            [-18, 41],
            [123, 24],
            [97, 4],
            [161, 20],
            [122, 46],
            [103, -7],
            [89, -34],
            [62, 67],
            [109, 19],
            [148, 14],
            [252, 5],
            [44, -14],
            [238, 21],
            [179, -7],
            [179, -8],
        ],
        [
            [15721, 12600],
            [-7, -50],
            [-37, -20],
            [-61, 14],
            [-17, -49],
            [-40, -4],
            [-14, 20],
            [-46, -43],
            [-40, -5],
            [-36, 26],
        ],
        [
            [15423, 12489],
            [-28, 54],
            [-39, -19],
            [1, 55],
            [60, 70],
            [-2, 31],
            [37, -11],
            [23, 20],
        ],
        [
            [15475, 12689],
            [70, 0],
            [17, 26],
            [88, -37],
        ],
        [
            [9331, 88],
            [-27, -50],
            [-71, -38],
            [-40, 4],
            [-49, 9],
            [-60, 38],
            [-87, 17],
            [-104, 69],
            [-84, 66],
            [-114, 138],
            [68, -26],
            [117, -82],
            [108, -44],
            [43, 57],
            [27, 84],
            [77, 50],
            [58, -14],
        ],
        [
            [9198, 407],
            [-74, 1],
            [-39, -30],
            [-75, -44],
            [-13, -115],
            [-34, -3],
            [-94, 40],
            [-94, 85],
            [-103, 70],
            [-26, 78],
            [23, 73],
            [-42, 82],
            [-10, 209],
            [35, 118],
            [88, 96],
            [-126, 35],
            [79, 109],
            [28, 204],
            [92, -44],
            [43, 255],
            [-55, 32],
            [-26, -153],
            [-52, 18],
            [26, 176],
            [28, 227],
            [37, 84],
            [-24, 120],
            [-6, 139],
            [34, 4],
            [51, 198],
            [57, 197],
            [35, 183],
            [-18, 184],
            [24, 101],
            [-10, 153],
            [48, 149],
            [16, 238],
            [26, 255],
            [25, 275],
            [-6, 201],
            [-16, 174],
        ],
        [
            [9050, 4581],
            [42, 31],
            [22, 63],
        ],
        [
            [9319, 4041],
            [-269, 540],
        ],
        [
            [14625, 8021],
            [22, -175],
            [-35, -103],
            [-22, -139],
            [37, -105],
            [-4, -49],
        ],
        [
            [14623, 7450],
            [-38, -1],
            [-58, 24],
            [-53, -1],
            [-97, -22],
            [-57, -35],
            [-82, -46],
            [-16, 3],
        ],
        [
            [14222, 7372],
            [6, 102],
            [8, 15],
            [-2, 49],
            [-36, 51],
            [-26, 8],
            [-23, 34],
            [17, 54],
            [-8, 60],
            [4, 36],
        ],
        [
            [14162, 7781],
            [12, 0],
            [6, 54],
            [-7, 23],
            [9, 17],
            [30, 15],
            [-20, 99],
            [-20, 51],
            [8, 41],
            [15, 10],
        ],
        [
            [14195, 8091],
            [12, 11],
            [22, -19],
            [64, -1],
            [15, 37],
            [15, -4],
            [24, 15],
            [12, -53],
            [20, 16],
            [33, 18],
        ],
        [
            [16180, 7115],
            [-6, -66],
            [-65, 29],
            [-67, 32],
            [-104, 4],
        ],
        [
            [15938, 7114],
            [-11, 7],
            [-48, -15],
            [-50, 16],
            [-40, -8],
        ],
        [
            [15789, 7114],
            [-134, 2],
        ],
        [
            [15655, 7116],
            [12, 98],
            [-32, 82],
            [-37, 20],
            [-18, 55],
            [-21, 18],
            [1, 34],
        ],
        [
            [15560, 7423],
            [21, 87],
            [39, 118],
            [24, 1],
            [50, 72],
            [31, 2],
            [47, -50],
            [56, 42],
            [8, 50],
            [19, 50],
            [12, 62],
            [45, 51],
            [16, 86],
            [17, 27],
            [12, 64],
            [22, 78],
            [70, 95],
            [4, 42],
            [9, 22],
            [-33, 48],
        ],
        [
            [16029, 8370],
            [3, 40],
            [23, 7],
        ],
        [
            [16055, 8417],
            [33, -79],
            [5, -82],
            [-3, -82],
            [46, -110],
            [-47, 1],
            [-23, -10],
            [-38, 13],
            [-18, -58],
            [49, -72],
            [36, -21],
            [12, -50],
            [26, -85],
            [-13, -34],
        ],
        [
            [15875, 6086],
            [-11, 38],
        ],
        [
            [15931, 6249],
            [22, -14],
            [28, 48],
            [45, -1],
            [5, -35],
            [31, -22],
            [49, 77],
            [48, 60],
            [20, 39],
            [-2, 102],
            [36, 119],
            [38, 63],
            [54, 59],
            [9, 40],
            [2, 45],
            [14, 43],
            [-4, 69],
            [10, 109],
            [17, 77],
            [23, 66],
            [6, 74],
        ],
        [
            [17118, 7479],
            [50, -102],
            [37, -14],
            [22, 21],
            [38, -9],
            [46, 26],
            [20, -52],
            [72, -81],
        ],
        [
            [17403, 7268],
            [-4, -144],
            [33, -17],
            [-27, -44],
            [-32, -33],
            [-31, -63],
            [-18, -58],
            [-4, -98],
            [-19, -47],
            [0, -92],
        ],
        [
            [17301, 6672],
            [-24, -36],
            [-3, -72],
            [-12, -9],
            [-8, -68],
        ],
        [
            [17281, 6283],
            [14, -113],
            [-8, -64],
            [17, -72],
            [47, -68],
            [45, -156],
        ],
        [
            [17396, 5810],
            [-32, 14],
            [-111, -21],
            [-23, -15],
            [-23, -78],
            [19, -55],
            [-16, -145],
            [-9, -124],
            [22, -21],
            [58, -48],
            [23, 22],
            [6, -133],
            [-63, 1],
            [-34, 69],
            [-31, 51],
            [-63, 18],
            [-19, 64],
            [-49, -39],
            [-67, 17],
            [-28, 56],
            [-52, 12],
            [-38, -3],
            [-5, 38],
            [-28, 3],
        ],
        [
            [15842, 6217],
            [-68, 130],
        ],
        [
            [15774, 6347],
            [63, 68],
            [-31, 81],
            [28, 31],
            [56, 15],
            [6, 55],
            [45, -59],
            [72, -5],
            [26, 58],
            [11, 81],
            [-10, 96],
            [-39, 73],
            [36, 143],
            [-20, 23],
            [-62, -10],
            [-24, 64],
            [7, 53],
        ],
        [
            [9089, 6308],
            [-41, 65],
            [-25, 3],
            [54, 125],
            [-64, 58],
            [-49, -11],
            [-31, 21],
            [-45, -32],
            [-61, 15],
            [-49, 129],
            [-38, 32],
            [-26, 58],
            [-55, 58],
            [-22, -11],
        ],
        [
            [8637, 6818],
            [-35, 29],
            [-41, 40],
            [-24, -19],
            [-69, 17],
            [-21, 53],
            [-16, -2],
            [-81, 69],
        ],
        [
            [8350, 7005],
            [-12, 39],
            [31, 9],
            [-4, 62],
            [20, 44],
            [40, 8],
            [35, 78],
            [32, 64],
            [-30, 29],
            [15, 72],
            [-19, 113],
            [18, 32],
            [-13, 103],
            [-34, 66],
        ],
        [
            [8429, 7724],
            [11, 60],
            [27, -10],
            [15, 38],
            [-18, 72],
            [9, 18],
        ],
        [
            [8473, 7902],
            [42, -4],
            [63, 86],
            [34, 13],
            [1, 41],
            [14, 103],
            [48, 57],
            [52, 2],
            [6, 26],
            [66, -10],
            [64, 62],
            [32, 28],
            [41, 59],
            [29, -8],
            [21, -32],
            [-16, -42],
        ],
        [
            [8970, 8283],
            [-53, -20],
            [-21, -62],
            [-32, -35],
            [-24, -45],
            [-9, -89],
            [-24, -71],
            [43, -8],
            [11, -56],
            [17, -28],
            [8, -49],
            [-11, -46],
            [3, -26],
            [21, -10],
            [19, -42],
            [107, 11],
            [47, -16],
            [59, -105],
            [33, 13],
            [60, -7],
            [47, 15],
            [29, -21],
            [-15, -67],
            [-19, -41],
            [-6, -88],
            [17, -82],
            [23, -36],
            [3, -28],
            [-42, -61],
            [30, -27],
            [22, -43],
            [25, -123],
        ],
        [
            [8010, 7847],
            [-45, 27],
            [-17, 26],
            [9, 22],
            [-2, 27],
            [-23, 30],
            [-33, 23],
            [-28, 16],
            [-6, 36],
            [-21, 22],
            [6, -36],
            [-17, -29],
            [-19, 34],
            [-27, 12],
            [-11, 25],
            [1, 38],
            [10, 38],
            [-22, 18],
            [18, 23],
        ],
        [
            [7783, 8199],
            [13, 16],
            [54, -32],
            [19, 15],
            [26, -10],
            [14, -25],
            [25, -8],
            [19, 26],
        ],
        [
            [7953, 8181],
            [21, -67],
            [32, -49],
            [39, -53],
        ],
        [
            [8045, 8012],
            [-33, -11],
            [1, -49],
            [17, -19],
            [-12, -15],
            [3, -21],
            [-7, -25],
            [-4, -25],
        ],
        [
            [8067, 9687],
            [72, -10],
            [64, -1],
            [78, -41],
            [33, -46],
            [77, 15],
            [29, -29],
            [71, -77],
            [50, -55],
            [27, 1],
            [50, -25],
            [-6, -34],
            [61, -5],
            [62, -51],
            [-9, -28],
            [-55, -15],
            [-56, -7],
            [-57, 10],
            [-118, -13],
            [56, 70],
            [-34, 31],
            [-53, 8],
            [-29, 36],
            [-20, 70],
            [-46, -4],
            [-78, 32],
            [-24, 26],
            [-107, 19],
            [-29, 24],
            [31, 30],
            [-81, 6],
            [-59, -63],
            [-34, -2],
            [-13, -30],
            [-40, -13],
            [-36, 12],
            [44, 37],
            [18, 45],
            [37, 27],
            [42, 24],
            [63, 12],
            [19, 14],
        ],
        [
            [17560, 11155],
            [6, 1],
            [13, 30],
            [59, -2],
            [74, 37],
            [-55, -52],
            [6, -23],
        ],
        [
            [17663, 11146],
            [-9, 4],
            [-15, -9],
            [-13, 2],
            [-4, -4],
            [-2, 12],
            [-6, 7],
            [-16, 1],
            [-23, -10],
            [-15, 6],
        ],
        [
            [17663, 11146],
            [3, -10],
            [-85, -50],
            [-41, 15],
            [-18, 50],
            [38, 4],
        ],
        [
            [15981, 12844],
            [-46, 53],
            [-43, 30],
            [-8, 51],
            [-15, 37],
            [59, 27],
            [31, 31],
            [60, 24],
            [20, 22],
            [22, -13],
            [37, 12],
        ],
        [
            [16098, 13118],
            [39, -39],
            [62, -11],
            [-5, -34],
            [45, -26],
            [12, 33],
            [57, -14],
            [7, -39],
            [61, -8],
            [39, -60],
        ],
        [
            [16415, 12920],
            [-25, 0],
            [-13, -22],
            [-18, -6],
            [-6, -28],
            [-16, -5],
            [-2, -11],
            [-28, -14],
            [-36, 2],
            [-13, -27],
        ],
        [
            [15475, 12689],
            [10, 88],
            [42, 84],
            [-119, 23],
            [-39, 32],
        ],
        [
            [15369, 12916],
            [4, 54],
            [-16, 28],
        ],
        [
            [15366, 13081],
            [-13, 129],
            [50, 0],
            [20, 46],
            [21, 113],
            [-16, 42],
        ],
        [
            [15428, 13411],
            [17, 26],
            [68, 6],
            [16, -27],
            [56, 61],
            [-19, 46],
            [-4, 69],
        ],
        [
            [15562, 13592],
            [62, -16],
            [53, 19],
        ],
        [
            [15677, 13595],
            [2, -48],
            [83, -29],
            [-1, -43],
            [84, 23],
            [47, 34],
            [93, -49],
            [39, -39],
        ],
        [
            [16024, 13444],
            [20, -62],
            [-23, -34],
            [30, -43],
            [19, -65],
            [-6, -43],
            [34, -79],
        ],
        [
            [18415, 8397],
            [19, -38],
            [-3, -51],
            [-47, -30],
            [36, -33],
        ],
        [
            [18420, 8245],
            [-30, -65],
        ],
        [
            [18390, 8180],
            [-19, 21],
            [-20, -9],
            [-45, 2],
            [-2, 37],
            [-7, 35],
            [28, 58],
            [29, 54],
        ],
        [
            [18354, 8378],
            [36, -11],
            [25, 30],
        ],
        [
            [15906, 13672],
            [-49, -100],
            [-87, 70],
            [-12, 51],
            [122, 40],
            [26, -61],
        ],
        [
            [15562, 13592],
            [-33, 68],
            [-3, 126],
            [14, 33],
            [23, 37],
            [74, 8],
            [28, 34],
            [67, 34],
            [-3, -63],
            [-25, -40],
            [11, -34],
            [44, -19],
            [-19, -46],
            [-25, 13],
            [-59, -89],
            [21, -59],
        ],
        [
            [8939, 9260],
            [10, 20],
            [64, -1],
            [49, -31],
            [22, 3],
            [15, -43],
            [45, 2],
            [-2, -36],
            [36, -5],
            [41, -45],
            [-30, -50],
            [-40, 27],
            [-38, -5],
            [-27, 5],
            [-15, -22],
            [-32, -8],
            [-12, 30],
            [-27, -18],
            [-33, -84],
            [-21, 20],
            [-4, 35],
        ],
        [
            [8940, 9054],
            [1, 34],
            [-21, 37],
            [20, 21],
            [6, 46],
            [-7, 68],
        ],
        [
            [15849, 9721],
            [-283, -234],
            [-239, -242],
            [-116, -55],
        ],
        [
            [15211, 9190],
            [-92, -11],
            [-1, 78],
            [-38, 20],
            [-51, 34],
            [-20, 59],
            [-279, 268],
            [-278, 268],
        ],
        [
            [14452, 9906],
            [-311, 298],
        ],
        [
            [14141, 10204],
            [2, 24],
            [0, 8],
        ],
        [
            [14143, 10236],
            [-1, 145],
            [134, 91],
            [82, 19],
            [67, 33],
            [33, 61],
            [96, 49],
            [3, 91],
            [48, 11],
            [37, 45],
            [108, 21],
            [16, 48],
            [-22, 25],
            [-28, 131],
            [-5, 75],
            [-31, 78],
        ],
        [
            [14680, 11159],
            [78, 67],
            [90, 22],
            [53, 50],
            [78, 38],
            [140, 21],
            [137, 11],
            [42, -19],
            [78, 49],
            [88, 1],
            [33, -29],
            [57, 7],
        ],
        [
            [15554, 11377],
            [-17, -62],
            [13, -117],
            [-20, -102],
            [-51, -68],
            [8, -93],
            [67, -73],
            [1, -30],
            [51, -50],
            [35, -221],
        ],
        [
            [15641, 10561],
            [27, -108],
            [4, -57],
            [-14, -100],
            [6, -57],
            [-10, -66],
            [7, -78],
            [-33, -52],
            [48, -89],
            [3, -53],
            [31, -68],
            [38, 23],
            [65, -57],
            [36, -78],
        ],
        [
            [8637, 6818],
            [11, -94],
            [-26, -80],
            [-90, -129],
            [-98, -49],
            [-51, -106],
            [-16, -83],
            [-47, -51],
            [-35, 62],
            [-33, 14],
            [-34, -10],
            [-2, 45],
            [24, 29],
            [-10, 52],
        ],
        [
            [8230, 6418],
            [44, 91],
            [-18, 54],
            [-32, -57],
            [-49, 54],
            [17, 35],
            [-15, 111],
            [29, 18],
            [16, 77],
            [31, 79],
            [-6, 51],
            [45, 26],
            [58, 48],
        ],
        [
            [17902, 9541],
            [-328, 0],
            [-320, 0],
            [-332, 0],
        ],
        [
            [16922, 9541],
            [0, 452],
            [0, 437],
            [-25, 99],
            [22, 76],
            [-14, 53],
            [31, 59],
        ],
        [
            [16936, 10717],
            [109, 2],
            [80, -33],
            [82, -36],
            [39, -19],
            [63, 38],
            [34, 35],
            [73, 11],
            [58, -16],
            [23, -61],
            [19, 41],
            [66, -29],
            [64, -8],
            [41, 31],
        ],
        [
            [17687, 10673],
            [44, -172],
            [10, -39],
        ],
        [
            [17741, 10462],
            [-22, -49],
            [-19, -93],
            [-22, -64],
            [-20, -21],
            [-27, 39],
            [-37, 55],
            [-59, 176],
            [-8, -11],
            [34, -130],
            [51, -123],
            [62, -192],
            [30, -66],
            [27, -69],
            [74, -137],
            [-17, -21],
            [3, -80],
            [97, -111],
            [14, -24],
        ],
        [
            [18354, 8378],
            [-28, 40],
            [-34, 72],
            [-36, 39],
            [-22, 42],
            [-71, 50],
            [-58, 1],
            [-19, 26],
            [-48, -29],
            [-51, 56],
            [-26, -92],
            [-95, 26],
        ],
        [
            [17866, 8609],
            [-9, 49],
            [36, 181],
            [7, 82],
            [27, 37],
            [60, 21],
            [42, 69],
        ],
        [
            [18029, 9048],
            [48, -142],
            [23, -113],
            [45, -60],
            [113, -116],
            [46, -69],
            [44, -72],
            [26, -42],
            [41, -37],
        ],
        [
            [14112, 11984],
            [4, 88],
            [-33, 53],
            [117, 89],
            [101, -22],
            [111, 1],
            [87, -22],
            [70, 7],
            [132, -4],
        ],
        [
            [14701, 12174],
            [34, -49],
            [151, -55],
            [30, 26],
            [93, -55],
            [96, 16],
        ],
        [
            [15105, 12057],
            [4, -71],
            [-78, -82],
            [-106, -26],
            [-7, -42],
            [-51, -68],
            [-32, -100],
            [32, -70],
            [-47, -55],
            [-18, -80],
            [-62, -25],
            [-58, -94],
            [-105, -2],
            [-79, 3],
            [-52, -44],
            [-31, -46],
            [-40, 10],
            [-31, 42],
            [-23, 70],
            [-78, 19],
        ],
        [
            [14243, 11396],
            [-6, 41],
            [30, 46],
            [11, 33],
            [-28, 37],
            [23, 81],
            [-33, 74],
            [35, 9],
            [3, 59],
            [14, 18],
            [1, 95],
            [38, 34],
            [-23, 61],
            [-48, 5],
            [-14, -16],
            [-49, 0],
            [-20, 60],
            [-35, -17],
            [-30, -32],
        ],
        [
            [16865, 13940],
            [10, 73],
            [-31, -16],
            [-52, 44],
            [-7, 70],
            [105, 34],
            [104, 19],
            [89, -21],
            [85, 3],
        ],
        [
            [17168, 14146],
            [12, -20],
            [-58, -72],
            [24, -114],
            [-35, -39],
        ],
        [
            [17111, 13901],
            [-68, 0],
            [-71, 45],
            [-36, 16],
            [-71, -22],
        ],
        [
            [18390, 8180],
            [-18, -44],
            [30, -68],
            [30, -59],
            [32, -44],
            [270, -146],
            [70, 1],
        ],
        [
            [18804, 7820],
            [-234, -369],
            [-108, -5],
            [-73, -87],
            [-53, -2],
            [-22, -40],
        ],
        [
            [18314, 7317],
            [-57, 0],
            [-33, 42],
            [-76, -51],
            [-24, -52],
            [-55, 11],
            [-18, 13],
            [-19, -3],
            [-27, 1],
            [-104, 105],
            [-58, 0],
            [-28, 40],
            [0, 69],
            [-42, 21],
        ],
        [
            [17773, 7513],
            [-49, 134],
            [-39, 28],
            [-14, 50],
            [-42, 59],
            [-50, 9],
            [28, 70],
            [44, 3],
            [12, 38],
            [0, 95],
        ],
        [
            [17663, 7999],
            [-1, 15],
            [24, 128],
            [40, 36],
            [8, 49],
            [35, 95],
            [50, 60],
            [33, 121],
            [14, 106],
        ],
        [
            [17219, 15326],
            [-13, -86],
            [127, -82],
            [-76, -93],
            [95, -140],
            [-55, -105],
            [74, -92],
            [-33, -80],
            [122, -84],
            [-31, -63],
            [-77, -71],
            [-177, -157],
        ],
        [
            [17175, 14273],
            [-149, -9],
            [-145, -46],
            [-134, -26],
            [-48, 67],
            [-81, 41],
            [19, 121],
            [-40, 111],
            [39, 72],
            [75, 76],
            [189, 134],
            [55, 26],
            [-8, 52],
            [-115, 58],
        ],
        [
            [16832, 14950],
            [-28, 47],
            [-2, 190],
            [-129, 84],
            [-110, 60],
        ],
        [
            [16563, 15331],
            [49, 32],
            [92, -65],
            [108, 6],
            [88, -29],
            [79, 54],
            [41, 90],
            [128, 42],
            [106, -49],
            [-35, -86],
        ],
        [
            [29583, 4704],
            [28, -35],
            [-13, -64],
            [-52, -17],
            [-45, 15],
            [-8, 54],
            [32, 42],
            [37, -14],
            [21, 19],
        ],
        [
            [29664, 4771],
            [-52, -26],
            [-11, 45],
            [41, 26],
            [26, 7],
            [49, 38],
            [0, -60],
            [-53, -30],
        ],
        [
            [7, 4807],
            [-7, -6],
            [0, 60],
            [17, 6],
            [-10, -60],
        ],
        [
            [9807, 462],
            [98, 74],
            [71, -30],
            [50, 49],
            [65, -55],
            [-24, -44],
            [-112, -36],
            [-37, 42],
            [-70, -55],
            [-41, 55],
        ],
        [
            [10358, 7120],
            [20, 52],
            [8, 56],
            [14, 53],
            [-32, 73],
            [-6, 84],
            [42, 105],
        ],
        [
            [10404, 7543],
            [28, -13],
            [61, -29],
            [88, -104],
            [13, -50],
        ],
        [
            [15647, 12018],
            [-27, -96],
            [-37, 25],
            [-19, 83],
            [17, 46],
            [53, 47],
            [13, -105],
        ],
        [
            [15327, 12925],
            [18, -11],
            [24, 2],
        ],
        [
            [15423, 12489],
            [-3, -34],
            [24, -47],
            [-28, -37],
            [21, -95],
            [44, -16],
            [-9, -53],
        ],
        [
            [15472, 12207],
            [-75, -69],
            [-163, 33],
            [-120, -40],
            [-9, -74],
        ],
        [
            [14701, 12174],
            [43, 74],
            [15, 244],
            [-85, 129],
            [-60, 62],
            [-126, 48],
            [-9, 90],
            [107, 26],
            [139, -31],
            [-26, 139],
            [78, -53],
            [192, 96],
            [25, 101],
            [72, 25],
        ],
        [
            [15774, 6347],
            [-84, 124],
            [-55, 101],
            [-50, 128],
            [2, 40],
            [19, 39],
            [20, 90],
            [16, 91],
        ],
        [
            [15642, 6960],
            [28, 7],
            [120, -1],
            [-1, 148],
        ],
        [
            [14347, 13457],
            [-63, 26],
            [-50, -2],
            [16, 66],
            [-16, 66],
        ],
        [
            [14234, 13613],
            [69, 5],
            [88, -76],
            [-44, -85],
        ],
        [
            [14610, 14044],
            [-88, -134],
            [84, 18],
            [91, -1],
            [-22, -101],
            [-74, -110],
            [86, -7],
            [6, -13],
            [74, -145],
            [56, -20],
            [51, -140],
            [23, -49],
            [100, -23],
            [-9, -78],
            [-43, -37],
            [33, -63],
            [-74, -65],
            [-110, 2],
            [-141, -34],
            [-39, 24],
            [-54, -57],
            [-76, 13],
            [-59, -47],
            [-43, 25],
            [120, 129],
            [75, 27],
            [-1, 0],
            [-129, 20],
            [-24, 49],
            [86, 39],
            [-44, 65],
            [15, 81],
            [123, -12],
            [12, 72],
            [-55, 75],
            [-1, 3],
            [-100, 21],
            [-20, 33],
            [30, 55],
            [-27, 35],
            [-45, -58],
            [-5, 118],
            [-41, 62],
            [30, 127],
            [64, 100],
            [66, -9],
            [99, 10],
        ],
        [
            [18456, 11887],
            [-80, 60],
            [-87, -5],
        ],
        [
            [18289, 11942],
            [11, 52],
            [-19, 84],
            [-48, 45],
            [-46, 15],
            [-31, 37],
        ],
        [
            [18156, 12175],
            [11, 15],
            [69, -21],
            [123, -20],
            [112, -59],
            [14, -23],
            [50, 19],
            [77, -26],
            [26, -50],
            [51, -29],
        ],
        [
            [14860, 8190],
            [-5, -38],
            [34, -64],
            [0, -89],
            [7, -96],
            [21, -46],
            [-18, -110],
            [7, -61],
            [21, -78],
            [19, -43],
        ],
        [
            [14946, 7565],
            [-130, -73],
            [-45, -41],
            [-75, -36],
            [-73, 35],
        ],
        [
            [14162, 7781],
            [-24, 3],
            [-16, -50],
            [-23, 1],
            [-17, 26],
            [5, 50],
            [-34, 75],
            [-22, -14],
            [-17, -2],
        ],
        [
            [14014, 7870],
            [-23, -7],
            [1, 44],
            [-13, 32],
            [3, 36],
            [-18, 52],
            [-23, 44],
            [-66, 0],
            [-19, -23],
            [-23, -3],
            [-14, -26],
            [-10, -34],
            [-44, -54],
        ],
        [
            [13765, 7931],
            [-37, 72],
            [-32, 48],
            [-20, 16],
            [-21, 25],
            [-10, 53],
            [-12, 28],
            [-24, 20],
        ],
        [
            [13609, 8193],
            [38, 60],
            [24, -2],
            [22, 21],
            [18, 0],
            [13, 15],
            [-7, 42],
            [9, 12],
            [1, 42],
        ],
        [
            [13727, 8383],
            [41, -1],
            [59, -30],
            [18, 3],
            [6, 13],
            [45, -9],
            [12, 6],
        ],
        [
            [13908, 8365],
            [5, -44],
            [13, 0],
            [21, 16],
            [14, -4],
            [23, -31],
            [35, -10],
            [23, 27],
            [27, 16],
            [19, 17],
            [17, -3],
            [19, -27],
            [9, -34],
            [34, -51],
            [-16, -32],
            [-5, -40],
            [18, 12],
            [10, -14],
            [-4, -37],
            [25, -35],
        ],
        [
            [13468, 8452],
            [10, 55],
        ],
        [
            [13478, 8507],
            [91, 3],
            [18, 29],
            [26, 2],
            [34, -30],
            [24, 0],
            [28, 21],
            [17, -36],
            [-36, -27],
            [-36, 2],
            [-36, 26],
            [-30, -29],
            [-14, -1],
            [-20, -17],
            [-76, 2],
        ],
        [
            [13609, 8193],
            [-43, 52],
            [-36, 7],
            [-18, 35],
            [1, 19],
            [-26, 26],
            [-5, 26],
        ],
        [
            [13482, 8358],
            [43, 21],
            [28, -4],
            [22, 13],
            [152, -5],
        ],
        [
            [15642, 6960],
            [-15, 18],
            [28, 138],
        ],
        [
            [16815, 11225],
            [45, -42],
            [64, 8],
            [61, -9],
            [-2, -22],
            [46, 15],
            [-11, -36],
            [-119, -11],
            [1, 21],
            [-100, 24],
            [15, 52],
        ],
        [
            [16593, 11857],
            [54, 10],
            [32, 27],
            [45, -2],
            [13, 21],
            [16, 4],
        ],
        [
            [17014, 11977],
            [41, -32],
            [-26, -77],
            [-20, -14],
        ],
        [
            [17009, 11854],
            [-50, 4],
            [-43, 11],
            [-100, -32],
            [57, -69],
            [-41, -20],
            [-47, 0],
            [-43, 63],
            [-16, -27],
            [19, -73],
            [41, -57],
            [-31, -27],
            [46, -57],
            [41, -35],
            [1, -70],
            [-77, 32],
            [25, -63],
            [-53, -12],
            [32, -109],
            [-55, -1],
            [-68, 53],
            [-31, 98],
            [-14, 82],
            [-33, 57],
            [-41, 69],
            [-6, 36],
        ],
        [
            [10999, 16993],
            [276, 73],
            [290, -5],
            [106, 46],
            [291, 11],
            [660, -16],
            [515, -97],
            [-151, -48],
            [-317, -5],
            [-443, -11],
            [41, -23],
            [291, 14],
            [249, -42],
            [161, 37],
            [69, -44],
            [-91, -72],
            [210, 47],
            [401, 48],
            [247, -24],
            [47, -53],
            [-337, -88],
            [-47, -28],
            [-263, -21],
            [190, -6],
            [-96, -89],
            [-66, -80],
            [3, -137],
            [98, -81],
            [-128, -5],
            [-136, -38],
            [152, -66],
            [20, -104],
            [-88, -12],
            [107, -105],
            [-184, -9],
            [95, -51],
            [-26, -42],
            [-117, -20],
            [-115, 0],
            [104, -83],
            [1, -55],
            [-164, 51],
            [-42, -33],
            [112, -31],
            [107, -75],
            [32, -99],
            [-148, -23],
            [-63, 47],
            [-102, 70],
            [28, -83],
            [-96, -64],
            [218, -6],
            [114, -6],
            [-221, -107],
            [-225, -98],
            [-242, -42],
            [-90, 0],
            [-86, -48],
            [-115, -129],
            [-178, -86],
            [-57, -6],
            [-110, -30],
            [-118, -29],
            [-70, -75],
            [-1, -86],
            [-43, -81],
            [-134, -99],
            [33, -96],
            [-37, -102],
            [-42, -120],
            [-117, -7],
            [-121, 100],
            [-166, 0],
            [-80, 69],
            [-55, 119],
            [-143, 153],
            [-41, 80],
            [-12, 111],
            [-114, 113],
            [29, 90],
            [-55, 44],
            [82, 144],
            [125, 46],
            [32, 51],
            [17, 96],
            [-94, -44],
            [-45, -17],
            [-74, -18],
            [-102, 39],
            [-5, 84],
            [32, 66],
            [77, 2],
            [169, -34],
            [-142, 79],
            [-74, 42],
            [-83, -18],
            [-68, 31],
            [92, 114],
            [-51, 47],
            [-65, 85],
            [-100, 129],
            [-105, 48],
            [2, 52],
            [-222, 71],
            [-176, 10],
            [-220, -5],
            [-201, -9],
            [-96, 39],
            [-143, 77],
            [216, 39],
            [166, 6],
            [-353, 32],
            [-186, 51],
            [11, 47],
            [313, 60],
            [302, 59],
            [33, 44],
            [-223, 45],
            [71, 48],
            [286, 87],
            [121, 12],
            [-36, 56],
            [197, 32],
            [254, 20],
            [253, 1],
            [90, -39],
            [219, 68],
            [197, -46],
            [116, -10],
            [171, -40],
            [-196, 67],
            [12, 52],
        ],
        [
            [7518, 8789],
            [27, -23],
            [7, 19],
            [24, -16],
        ],
        [
            [7576, 8769],
            [-38, -46],
            [-39, -34],
            [-6, -24],
            [7, -25],
            [-18, -31],
        ],
        [
            [7482, 8609],
            [-18, -8],
            [4, -14],
            [-16, -13],
            [-28, -32],
            [-3, -17],
        ],
        [
            [7421, 8525],
            [-42, 21],
            [-51, 2],
            [-39, 24],
            [-44, 51],
        ],
        [
            [7245, 8623],
            [2, 35],
            [10, 30],
            [-12, 22],
            [40, 101],
            [106, 0],
            [2, 42],
            [-13, 8],
            [-10, 27],
            [-30, 28],
            [-31, 41],
            [38, 0],
            [0, 69],
            [76, 0],
            [77, -1],
        ],
        [
            [9845, 7475],
            [-56, 93],
            [23, 34],
            [-2, 57],
            [51, 20],
            [20, 23],
            [-28, 45],
            [8, 45],
            [65, 73],
        ],
        [
            [9926, 7865],
            [54, -46],
            [51, -80],
            [2, -63],
            [31, -3],
            [45, -60],
            [32, -43],
        ],
        [
            [10141, 7570],
            [-13, -111],
            [-51, -32],
            [6, -29],
            [-16, -63],
            [36, -89],
            [27, 0],
            [11, -70],
            [51, -106],
        ],
        [
            [7576, 8769],
            [8, -5],
            [19, 22],
            [24, 2],
            [7, -10],
            [12, 6],
            [39, -11],
            [38, 3],
            [27, 14],
            [9, 14],
            [26, -7],
            [20, -8],
            [22, 2],
            [16, 12],
            [38, -18],
            [13, -2],
            [25, -23],
            [24, -28],
            [30, -19],
            [22, -34],
        ],
        [
            [7995, 8679],
            [-28, 3],
            [-12, -17],
            [-29, -16],
            [-20, 0],
            [-18, -15],
            [-17, 5],
            [-14, 18],
            [-8, -3],
            [-12, -30],
            [-7, 1],
            [-1, -25],
            [-29, -34],
            [-16, -14],
            [-8, -15],
            [-25, 25],
            [-18, -33],
            [-16, 1],
            [-20, -3],
            [2, -60],
            [-12, -1],
            [-11, -29],
            [-26, -4],
        ],
        [
            [7650, 8433],
            [-13, 38],
            [-26, 10],
        ],
        [
            [7611, 8481],
            [6, 50],
            [-11, 12],
            [-17, 10],
            [-36, -15],
            [-3, 17],
            [-25, 20],
            [-18, 23],
            [-25, 11],
        ],
        [
            [16391, 12079],
            [-9, -21],
        ],
        [
            [16382, 12058],
            [-78, 46],
            [-48, 43],
            [-76, 38],
            [-69, 90],
            [17, 9],
            [-38, 52],
            [-2, 41],
            [-53, 19],
            [-25, -53],
            [-25, 42],
            [2, 42],
            [4, 2],
        ],
        [
            [15991, 12429],
            [57, -4],
            [15, 21],
            [28, -20],
            [32, -3],
            [0, 34],
            [29, 14],
            [8, 48],
            [66, 33],
        ],
        [
            [16226, 12552],
            [26, -15],
            [62, -52],
            [68, -24],
            [31, 18],
        ],
        [
            [16413, 12479],
            [19, -47],
            [27, -35],
            [-32, -47],
        ],
        [
            [8940, 9054],
            [-55, 21],
            [-40, -8],
            [-49, 8],
            [-40, -23],
            [-43, 39],
            [7, 39],
            [76, -16],
            [62, -11],
            [30, 28],
            [-39, 53],
            [1, 47],
            [-51, 19],
            [18, 35],
            [50, -7],
            [72, -18],
        ],
        [
            [16260, 12751],
            [42, -31],
            [31, -13],
            [68, 14],
            [8, 25],
            [33, 4],
            [39, 19],
            [10, -8],
            [38, 15],
            [20, 29],
            [27, 8],
            [88, -38],
            [17, 14],
        ],
        [
            [16681, 12789],
            [46, -34],
            [6, -34],
        ],
        [
            [16733, 12721],
            [-51, -24],
            [-38, -84],
            [-50, -83],
            [-66, -24],
        ],
        [
            [16528, 12506],
            [-52, 6],
            [-63, -33],
        ],
        [
            [16226, 12552],
            [-17, 42],
            [-13, 1],
        ],
        [
            [24823, 5577],
            [-35, -2],
            [-109, 86],
            [77, 24],
            [44, -37],
            [29, -38],
            [-6, -33],
        ],
        [
            [25174, 5743],
            [8, -24],
            [2, -37],
        ],
        [
            [25184, 5682],
            [-54, -92],
            [-70, -27],
            [-10, 14],
            [7, 42],
            [36, 75],
            [81, 49],
        ],
        [
            [24591, 5840],
            [29, -32],
            [52, 11],
            [20, -53],
            [-96, -25],
            [-57, -17],
            [-44, 1],
            [29, 72],
            [44, 1],
            [23, 42],
        ],
        [
            [25004, 5841],
            [-13, -68],
            [-123, -35],
            [-110, 15],
            [0, 45],
            [65, 26],
            [52, -38],
            [55, 10],
            [74, 45],
        ],
        [
            [23825, 6003],
            [158, -12],
            [18, 50],
            [153, -59],
            [31, -79],
            [123, -23],
            [102, -73],
            [-95, -46],
            [-90, 48],
            [-75, -3],
            [-86, 10],
            [-77, 21],
            [-96, 48],
            [-60, 11],
            [-34, -15],
            [-151, 51],
            [-14, 52],
            [-76, 10],
            [57, 117],
            [100, -7],
            [67, -48],
            [34, -9],
            [11, -44],
        ],
        [
            [25980, 6073],
            [-43, -84],
            [-8, 92],
            [15, 44],
            [17, 42],
            [19, -36],
            [0, -58],
        ],
        [
            [25363, 6410],
            [-31, -40],
            [-57, 23],
            [-17, 53],
            [84, 5],
            [21, -41],
        ],
        [
            [25628, 6456],
            [30, -94],
            [-69, 51],
            [-70, 10],
            [-45, -8],
            [-58, 4],
            [19, 67],
            [103, 5],
            [90, -35],
        ],
        [
            [26498, 6516],
            [1, -400],
            [2, -401],
        ],
        [
            [26501, 5715],
            [-74, 102],
            [-84, 23],
            [-20, -34],
            [-104, -4],
            [34, 100],
            [53, 34],
            [-22, 133],
            [-40, 104],
            [-160, 104],
            [-68, 10],
            [-124, 114],
            [-24, -60],
            [-32, -10],
            [-19, 44],
            [0, 54],
            [-63, 60],
            [89, 45],
            [59, -2],
            [-7, 32],
            [-121, 0],
            [-33, 73],
            [-73, 23],
            [-36, 60],
            [111, 30],
            [43, 41],
            [133, -51],
            [12, -46],
            [24, -199],
            [85, -73],
            [70, 130],
            [94, 75],
            [74, 0],
            [70, -43],
            [61, -44],
            [89, -24],
        ],
        [
            [25197, 7011],
            [-67, -123],
            [-62, -23],
            [-79, 24],
            [-138, -6],
            [-72, -18],
            [-12, -93],
            [74, -109],
            [45, 55],
            [154, 43],
            [-7, -57],
            [-36, 17],
            [-36, -71],
            [-72, -48],
            [78, -157],
            [-15, -43],
            [74, -142],
            [0, -81],
            [-45, -36],
            [-32, 44],
            [39, 100],
            [-81, -48],
            [-20, 35],
            [11, 47],
            [-60, 72],
            [6, 120],
            [-55, -37],
            [7, -143],
            [4, -176],
            [-52, -18],
            [-37, 36],
            [24, 113],
            [-12, 118],
            [-35, 1],
            [-25, 84],
            [34, 81],
            [11, 98],
            [42, 185],
            [17, 51],
            [70, 91],
            [64, -36],
            [105, -18],
            [94, 5],
            [82, 91],
            [15, -28],
        ],
        [
            [25481, 6975],
            [-4, -107],
            [-43, 12],
            [-12, -75],
            [34, -65],
            [-24, -14],
            [-33, 77],
            [-24, 158],
            [17, 97],
            [27, 46],
            [6, -68],
            [49, -11],
            [7, -50],
        ],
        [
            [23911, 7083],
            [13, -83],
            [57, -69],
            [53, 25],
            [53, -9],
            [48, 63],
            [40, 10],
            [78, -34],
            [67, 26],
            [43, 171],
            [31, 42],
            [29, 140],
            [94, 0],
            [72, -21],
        ],
        [
            [24589, 7344],
            [-47, -111],
            [62, -116],
            [-15, -57],
            [92, -113],
            [-97, -14],
            [-28, -84],
            [4, -112],
            [-80, -84],
            [-2, -122],
            [-32, -188],
            [-11, 44],
            [-95, -55],
            [-32, 74],
            [-59, 8],
            [-42, 39],
            [-97, -45],
            [-30, 59],
            [-55, -6],
            [-68, 15],
            [-12, 165],
            [-42, 34],
            [-39, 104],
            [-11, 108],
            [9, 114],
            [49, 82],
        ],
        [
            [23593, 6117],
            [-91, -3],
            [-69, 103],
            [-106, 100],
            [-35, 75],
            [-64, 100],
            [-40, 92],
            [-63, 172],
            [-72, 102],
            [-25, 106],
            [-30, 96],
            [-75, 78],
            [-43, 105],
            [-61, 68],
            [-88, 136],
            [-6, 63],
            [53, -5],
            [128, -24],
            [72, -120],
            [65, -84],
            [45, -51],
            [78, -132],
            [84, -2],
            [70, -85],
            [47, -102],
            [63, -56],
            [-33, -101],
            [47, -42],
            [30, -3],
            [14, -86],
            [29, -69],
            [61, -10],
            [39, -79],
            [-21, -152],
            [-3, -190],
        ],
        [
            [14347, 13457],
            [13, -87],
            [-62, -110],
            [-146, -72],
            [-118, 18],
            [68, 128],
            [-44, 125],
            [113, 97],
            [63, 57],
        ],
        [
            [19883, 10502],
            [42, -64],
            [33, -75],
            [79, -53],
            [2, -108],
            [39, -20],
            [8, -57],
            [-120, -63],
            [-31, -143],
        ],
        [
            [19935, 9919],
            [-155, 37],
            [-91, 28],
            [-92, 16],
            [-35, 151],
            [-41, 22],
            [-63, -22],
            [-83, -60],
            [-101, 41],
            [-84, 95],
            [-78, 35],
            [-55, 116],
            [-62, 164],
            [-44, -20],
            [-52, 40],
            [-31, -47],
        ],
        [
            [18868, 10515],
            [-46, 64],
            [-1, 65],
            [-26, 0],
            [14, 89],
            [-43, 94],
            [-101, 67],
            [-58, 117],
            [19, 97],
            [43, 42],
            [-7, 72],
            [-55, 37],
            [-52, 146],
        ],
        [
            [18555, 11405],
            [-46, 99],
            [17, 38],
            [-26, 141],
            [56, 35],
        ],
        [
            [18894, 11546],
            [26, -90],
            [79, -26],
            [57, -61],
            [117, -22],
            [128, 33],
            [9, 28],
        ],
        [
            [19310, 11408],
            [72, 24],
            [58, 71],
            [56, -4],
            [37, 23],
            [58, -11],
            [91, -62],
            [66, -14],
            [95, -109],
            [61, -4],
            [7, -103],
        ],
        [
            [18868, 10515],
            [-49, 6],
        ],
        [
            [18819, 10521],
            [-56, 10],
            [-60, -118],
        ],
        [
            [18703, 10413],
            [-154, 10],
            [-233, 247],
            [-122, 86],
            [-100, 33],
        ],
        [
            [18094, 10789],
            [-33, 150],
        ],
        [
            [18061, 10939],
            [182, 128],
            [32, 148],
            [-8, 91],
            [45, 30],
            [42, 76],
        ],
        [
            [18354, 11412],
            [36, 20],
            [96, -17],
            [29, -31],
            [40, 21],
        ],
        [
            [13661, 15005],
            [-20, -79],
            [94, -84],
            [-107, -94],
            [-239, -84],
            [-70, -23],
            [-109, 19],
            [-230, 38],
            [81, 55],
            [-180, 60],
            [146, 24],
            [-3, 36],
            [-174, 28],
            [56, 80],
            [125, 18],
            [129, -82],
            [126, 66],
            [103, -34],
            [135, 65],
            [137, -9],
        ],
        [
            [17687, 10673],
            [24, 41],
            [-6, 7],
            [22, 57],
            [16, 93],
            [13, 31],
            [2, 1],
        ],
        [
            [17758, 10903],
            [28, 0],
            [7, 22],
            [22, 1],
        ],
        [
            [17815, 10926],
            [2, -49],
            [-11, -19],
            [1, -1],
        ],
        [
            [17807, 10857],
            [-15, -39],
        ],
        [
            [17792, 10818],
            [-29, 17],
            [-17, -82],
            [20, -13],
            [-20, -18],
            [-5, -32],
            [40, 17],
        ],
        [
            [17781, 10707],
            [2, -48],
            [-42, -197],
        ],
        [
            [16140, 11536],
            [-30, -97],
            [12, -38],
            [-17, -63],
            [-64, 45],
            [-41, 14],
            [-115, 63],
            [11, 62],
            [97, -10],
            [84, 13],
            [63, 11],
        ],
        [
            [15618, 11902],
            [50, -88],
            [-11, -162],
            [-38, 8],
            [-34, -41],
            [-31, 32],
            [-3, 149],
            [-19, 69],
            [46, -6],
            [40, 39],
        ],
        [
            [15998, 12553],
            [-8, -61],
            [19, -51],
        ],
        [
            [16009, 12441],
            [-66, 17],
            [-66, -43],
            [4, -62],
            [-11, -35],
            [27, -62],
            [78, -62],
            [42, -102],
            [92, -98],
            [64, 0],
            [20, -27],
            [-23, -25],
            [74, -44],
            [61, -37],
            [70, -64],
            [9, -23],
            [-16, -45],
            [-45, 59],
            [-72, 19],
            [-34, -80],
            [59, -45],
            [-9, -63],
            [-36, -9],
            [-43, -104],
            [-35, -10],
            [0, 38],
            [17, 66],
            [19, 26],
            [-33, 71],
            [-25, 62],
            [-35, 15],
            [-23, 53],
            [-53, 23],
            [-37, 49],
            [-61, 8],
            [-64, 56],
            [-76, 80],
            [-56, 70],
            [-26, 122],
            [-40, 14],
            [-68, 40],
            [-38, -16],
            [-48, -57],
            [-34, -9],
        ],
        [
            [8455, 9109],
            [56, -11],
            [44, -30],
            [13, -33],
            [-58, -2],
            [-25, -21],
            [-46, 20],
            [-47, 44],
            [9, 28],
            [36, 10],
            [18, -5],
        ],
        [
            [18094, 10789],
            [-15, -18],
            [-166, -61],
            [83, -124],
            [-28, -20],
            [-14, -42],
            [-62, -16],
            [-21, -45],
            [-35, -38],
            [-91, 19],
        ],
        [
            [17745, 10444],
            [-4, 18],
        ],
        [
            [17781, 10707],
            [11, 36],
            [0, 75],
        ],
        [
            [17807, 10857],
            [92, -49],
            [162, 131],
        ],
        [
            [25973, 11034],
            [10, -42],
            [-47, -75],
            [-33, 39],
            [-42, -28],
            [-22, -72],
            [-54, 35],
            [0, 58],
            [46, 74],
            [47, -15],
            [33, 52],
            [62, -26],
        ],
        [
            [26495, 11402],
            [-31, -98],
            [15, -62],
            [-43, -87],
            [-105, -58],
            [-146, -7],
            [-117, -140],
            [-56, 47],
            [-3, 92],
            [-143, -27],
            [-99, -58],
            [-96, -3],
            [84, -90],
            [-55, -209],
            [-54, -51],
            [-39, 47],
            [19, 111],
            [-52, 35],
            [-33, 85],
            [78, 38],
            [44, 77],
            [83, 64],
            [60, 84],
            [164, 36],
            [89, -25],
            [86, 219],
            [55, -59],
            [121, 123],
            [47, 48],
            [52, 150],
            [-15, 138],
            [35, 77],
            [89, 23],
            [44, -170],
            [-2, -100],
            [-77, -124],
            [1, -126],
        ],
        [
            [26738, 12266],
            [58, -27],
            [58, 53],
            [19, -138],
            [-122, -34],
            [-73, -122],
            [-130, 84],
            [-45, -134],
            [-92, -2],
            [-11, 122],
            [40, 95],
            [89, 7],
            [25, 170],
            [23, 95],
            [98, -127],
            [63, -42],
        ],
        [
            [22070, 12886],
            [-63, -82],
            [-69, -11],
            [-3, -124],
        ],
        [
            [21935, 12669],
            [-47, -55],
            [-163, 40],
            [-60, -220],
            [-42, -28],
            [-163, -48],
            [73, -214],
            [-56, -32],
            [7, -71],
        ],
        [
            [21484, 12041],
            [-51, 19],
            [-42, 44],
            [-122, 13],
            [-137, 3],
            [-30, -13],
            [-117, 52],
            [-47, -26],
            [-13, -73],
            [-136, 43],
            [-54, -18],
            [-19, -54],
        ],
        [
            [20716, 12031],
            [-48, -23],
            [-107, -85],
            [-37, -88],
            [-31, -1],
            [-23, 58],
            [-104, 4],
            [-17, 101],
            [-40, 1],
            [6, 123],
            [-99, 90],
            [-142, -9],
            [-96, -19],
            [-79, 111],
            [-68, 47],
            [-128, 88],
            [-15, 10],
            [-212, -72],
            [3, -453],
        ],
        [
            [19479, 11914],
            [-43, -6],
            [-58, 96],
            [-56, 34],
            [-93, -25],
            [-37, -41],
        ],
        [
            [19192, 11972],
            [-4, 30],
            [20, 51],
            [-16, 43],
            [-95, 42],
            [-37, 111],
            [-46, 31],
            [-3, 39],
            [81, -11],
            [3, 90],
            [69, 20],
            [73, -19],
            [15, 120],
            [-15, 76],
            [-83, -5],
            [-69, 30],
            [-96, -55],
            [-78, -25],
        ],
        [
            [18911, 12540],
            [-41, 20],
            [8, 63],
            [-53, 82],
            [-61, -3],
            [-69, 83],
            [47, 93],
            [-25, 25],
            [67, 136],
            [85, -72],
            [10, 89],
            [170, 134],
            [129, 3],
            [182, -85],
            [98, -50],
            [87, 52],
            [131, 3],
            [106, -64],
            [23, 37],
            [117, -6],
            [20, 59],
            [-133, 84],
            [78, 60],
            [-14, 33],
            [79, 32],
            [-59, 84],
            [37, 43],
            [309, 42],
            [40, 30],
            [207, 46],
            [74, 51],
            [148, -26],
            [26, -128],
            [87, 30],
            [106, -42],
            [-8, -66],
            [79, 6],
            [208, 116],
            [-30, -38],
            [104, -96],
            [185, -312],
            [45, 65],
            [113, -71],
            [119, 31],
            [46, -22],
            [39, -70],
            [58, -24],
            [34, -53],
            [107, 17],
            [44, -75],
        ],
        [
            [18314, 7317],
            [-73, -139],
            [1, -447],
            [49, -102],
        ],
        [
            [18291, 6629],
            [-58, -49],
            [-20, -51],
            [-31, -10],
            [-11, -86],
            [-27, -50],
            [-17, -81],
            [-33, -41],
        ],
        [
            [18094, 6261],
            [-118, 124],
            [-5, 70],
            [-300, 251],
            [-14, 13],
        ],
        [
            [17657, 6719],
            [-1, 131],
            [24, 50],
            [41, 80],
            [30, 91],
            [-37, 141],
            [-9, 61],
            [-39, 86],
        ],
        [
            [17666, 7359],
            [51, 72],
            [56, 82],
        ],
        [
            [21484, 12041],
            [-12, -26],
            [-130, -68],
            [-29, -49],
            [-106, -14],
            [-31, -79],
            [-88, 17],
            [-57, -24],
            [-78, -58],
            [11, -29],
        ],
        [
            [20964, 11711],
            [-24, -28],
        ],
        [
            [20940, 11683],
            [-155, -19],
            [-103, 41],
            [-89, -10],
            [7, 71],
            [90, -21],
            [30, 38],
        ],
        [
            [20720, 11783],
            [64, -12],
            [105, 88],
            [-98, 65],
            [-58, -31],
            [-61, 47],
            [69, 80],
            [-25, 11],
        ],
        [
            [23327, 8334],
            [-20, 148],
            [53, 103],
            [107, 23],
            [77, -18],
        ],
        [
            [23544, 8590],
            [68, -48],
            [37, 85],
            [74, -45],
        ],
        [
            [23723, 8582],
            [19, -82],
            [-11, -147],
            [-138, -95],
            [36, -74],
            [-86, -10],
            [-72, -49],
        ],
        [
            [23471, 8125],
            [-70, 18],
            [-33, 64],
            [-41, 127],
        ],
        [
            [25453, 11582],
            [72, -145],
            [20, -80],
            [0, -141],
            [-31, -68],
            [-74, -23],
            [-66, -52],
            [-74, -10],
            [-10, 67],
            [16, 93],
            [-37, 127],
            [62, 21],
            [-57, 106],
        ],
        [
            [25274, 11477],
            [5, 11],
            [37, -5],
            [32, 56],
            [58, 6],
            [36, 7],
            [11, 30],
        ],
        [
            [16515, 12072],
            [16, 26],
        ],
        [
            [16531, 12098],
            [20, 10],
            [11, 40],
            [15, 7],
            [11, -17],
            [16, -7],
            [11, -20],
            [13, -6],
            [16, -23],
            [11, 1],
            [-8, -30],
            [-10, -15],
            [3, -9],
        ],
        [
            [16640, 12029],
            [-19, -5],
            [-49, -19],
            [-4, -25],
            [-10, 1],
        ],
        [
            [18819, 10521],
            [17, -55],
            [-8, -28],
            [27, -92],
        ],
        [
            [18855, 10346],
            [-59, -3],
            [-19, 58],
            [-74, 12],
        ],
        [
            [23211, 9471],
            [7, -29],
            [44, -3],
            [-12, 141],
            [43, 18],
        ],
        [
            [23293, 9598],
            [47, -98],
            [38, -111],
            [101, -1],
            [32, -108],
            [-53, -32],
            [-23, -44],
            [99, -73],
            [69, -146],
            [52, -108],
            [62, -86],
            [21, -86],
            [-15, -123],
        ],
        [
            [23544, 8590],
            [27, 56],
            [4, 104],
            [-68, 107],
            [-4, 122],
            [-63, 99],
            [-62, 9],
            [-17, -43],
            [-49, -4],
            [-25, 22],
            [-87, -73],
            [-1, 110],
            [20, 130],
            [-56, 5],
            [-4, 74],
            [-37, 38],
        ],
        [
            [23122, 9346],
            [18, 45],
            [71, 80],
        ],
        [
            [17758, 10903],
            [29, 101],
            [42, 86],
            [1, 5],
        ],
        [
            [17830, 11095],
            [37, -7],
            [13, -48],
            [-44, -46],
            [-21, -68],
        ],
        [
            [14222, 7372],
            [-22, -1],
            [-85, 59],
            [-74, 94],
            [-71, 67],
            [-56, 79],
        ],
        [
            [13914, 7670],
            [20, 39],
            [4, 36],
            [37, 67],
            [39, 58],
        ],
        [
            [15641, 10561],
            [41, 29],
            [7, 52],
            [-10, 51],
            [57, 48],
            [26, 39],
            [40, 35],
            [5, 95],
        ],
        [
            [15807, 10910],
            [96, -43],
            [36, 11],
            [68, -21],
            [110, -55],
            [39, -109],
            [74, -24],
            [116, -52],
            [89, -61],
            [39, 32],
            [39, 57],
            [-18, 94],
            [26, 60],
            [59, 58],
            [57, 16],
            [112, -24],
            [28, -55],
            [30, -1],
            [27, -21],
            [82, -15],
            [20, -40],
        ],
        [
            [16922, 9541],
            [0, -246],
            [-94, 0],
            [-1, -52],
        ],
        [
            [16827, 9243],
            [-330, 235],
            [-329, 236],
            [-84, -68],
        ],
        [
            [16084, 9646],
            [-58, -45],
            [-47, 67],
            [-130, 53],
        ],
        [
            [21610, 7761],
            [-12, -129],
            [-36, -34],
            [-71, -29],
            [-39, 99],
            [-15, 176],
            [37, 200],
            [57, -69],
            [39, -87],
            [40, -127],
        ],
        [
            [17251, 3276],
            [28, -36],
            [-25, -60],
            [-15, -41],
            [-45, -18],
            [-16, -40],
            [-29, -12],
            [-62, 94],
            [45, 78],
            [44, 49],
            [39, 25],
            [36, -39],
        ],
        [
            [16798, 13463],
            [-21, 38],
            [-42, 13],
        ],
        [
            [16735, 13514],
            [-7, 31],
            [9, 35],
            [-36, 18],
            [-87, 22],
        ],
        [
            [16614, 13620],
            [-18, 104],
        ],
        [
            [16596, 13724],
            [95, 37],
            [139, -7],
            [81, 11],
            [11, -25],
            [45, -8],
            [78, -59],
        ],
        [
            [16596, 13724],
            [3, 92],
            [41, 78],
            [78, 41],
            [65, -91],
            [66, 2],
            [16, 94],
        ],
        [
            [17111, 13901],
            [40, -28],
            [6, -60],
            [27, -73],
        ],
        [
            [14143, 10236],
            [-12, 0],
            [2, -66],
            [-52, -5],
            [-26, -27],
            [-37, 0],
            [-30, 16],
            [-70, -13],
            [-27, -96],
            [-26, -9],
            [-38, -156],
            [-115, -132],
            [-27, -169],
            [-34, -56],
            [-10, -44],
            [-186, -10],
            [-1, 0],
        ],
        [
            [13454, 9469],
            [4, 57],
            [31, 34],
            [27, 63],
            [-5, 43],
            [29, 86],
            [45, 79],
            [28, 19],
            [22, 72],
            [2, 65],
            [29, 76],
            [55, 45],
            [53, 126],
            [1, 1],
            [42, 48],
            [76, 13],
            [66, 84],
            [41, 33],
            [69, 103],
            [-21, 152],
            [32, 106],
            [10, 65],
            [53, 83],
            [83, 56],
            [62, 51],
            [56, 127],
            [25, 76],
            [61, -1],
            [49, -52],
            [79, 8],
            [85, -27],
            [37, -1],
        ],
        [
            [17056, 12764],
            [20, 17],
            [55, 13],
            [61, -38],
            [33, -6],
            [37, -32],
            [-5, -42],
            [30, -20],
            [12, -52],
            [29, -31],
            [-7, -17],
            [16, -13],
            [-22, -9],
            [-49, 3],
            [-8, 18],
            [-17, -11],
            [6, -22],
            [-23, -39],
            [-14, -42],
            [-21, -13],
        ],
        [
            [17189, 12428],
            [-15, 56],
            [9, 52],
            [-3, 54],
            [-47, 73],
            [-26, 52],
            [-26, 36],
            [-25, 13],
        ],
        [
            [18948, 5304],
            [23, -53],
            [19, -81],
            [14, -148],
            [21, -57],
            [-8, -59],
            [-14, -37],
            [-28, 72],
            [-16, -37],
            [16, -90],
            [-8, -53],
            [-22, -28],
            [-6, -104],
            [-32, -143],
            [-41, -169],
            [-51, -233],
            [-31, -171],
            [-38, -142],
            [-67, -30],
            [-73, -52],
            [-46, 32],
            [-67, 43],
            [-22, 66],
            [-6, 109],
            [-29, 97],
            [-7, 89],
            [15, 88],
            [38, 22],
            [0, 40],
            [39, 94],
            [8, 78],
            [-19, 58],
            [-16, 77],
            [-7, 114],
            [29, 69],
            [12, 77],
            [40, 5],
            [47, 25],
            [30, 22],
            [36, 2],
            [48, 71],
            [67, 75],
            [25, 61],
            [-11, 53],
            [35, -14],
            [46, 85],
            [1, 74],
            [28, 55],
            [28, -52],
        ],
        [
            [7245, 8623],
            [-93, 133],
            [-43, 39],
            [-67, 32],
            [-46, -9],
            [-66, -45],
            [-42, -13],
            [-58, 32],
            [-62, 24],
            [-77, 56],
            [-62, 18],
            [-94, 57],
            [-69, 59],
            [-21, 32],
            [-45, 8],
            [-85, 38],
            [-35, 56],
            [-88, 71],
            [-41, 77],
            [-20, 60],
            [27, 12],
            [-8, 35],
            [18, 32],
            [1, 42],
            [-28, 56],
            [-7, 49],
            [-28, 62],
            [-73, 121],
            [-83, 97],
            [-40, 76],
            [-71, 50],
            [-15, 30],
            [12, 77],
            [-41, 28],
            [-49, 60],
            [-21, 86],
            [-44, 9],
            [-48, 65],
            [-38, 61],
            [-4, 38],
            [-45, 92],
            [-29, 94],
            [1, 48],
            [-59, 49],
            [-28, -6],
            [-47, 34],
            [-13, -50],
            [13, -59],
            [8, -92],
            [28, -51],
            [62, -85],
            [14, -29],
            [13, -8],
            [10, -43],
            [14, 2],
            [17, -80],
            [25, -31],
            [18, -43],
            [51, -62],
            [28, -114],
            [24, -54],
            [24, -58],
            [4, -66],
            [40, -3],
            [33, -56],
            [30, -55],
            [-2, -22],
            [-34, -45],
            [-15, 0],
            [-22, 76],
            [-55, 69],
            [-59, 59],
            [-42, 32],
            [3, 89],
            [-13, 68],
            [-39, 37],
            [-57, 55],
            [-10, -15],
            [-21, 32],
            [-51, 30],
            [-49, 71],
            [7, 10],
            [33, -8],
            [31, 46],
            [3, 56],
            [-63, 87],
            [-49, 34],
            [-31, 77],
            [-30, 81],
            [-39, 98],
            [-33, 110],
        ],
        [
            [5190, 10835],
            [94, 9],
            [104, 14],
            [-7, -24],
            [124, -60],
            [189, -86],
            [164, 1],
            [65, 0],
            [0, 51],
            [144, 0],
            [30, -44],
            [42, -39],
            [49, -54],
            [27, -64],
            [21, -68],
            [42, -37],
            [69, -37],
            [53, 97],
            [67, 2],
            [58, -49],
            [42, -84],
            [28, -72],
            [48, -70],
            [19, -86],
            [23, -58],
            [64, -38],
            [58, -27],
            [33, 4],
        ],
        [
            [6840, 10016],
            [-32, -108],
            [-14, -89],
            [-7, -164],
            [-8, -60],
            [15, -67],
            [26, -60],
            [16, -95],
            [54, -92],
            [20, -70],
            [32, -60],
            [88, -33],
            [34, -51],
            [72, 34],
            [63, 13],
            [62, 21],
            [52, 21],
            [52, 51],
            [20, 71],
            [7, 104],
            [15, 35],
            [55, 32],
            [88, 29],
            [72, -4],
            [50, 11],
            [20, -26],
            [-2, -60],
            [-45, -73],
            [-19, -75],
            [15, -21],
            [-12, -53],
            [-21, -97],
            [-21, 32],
            [-17, -2],
        ],
        [
            [16640, 12029],
            [28, 7],
            [38, 2],
        ],
        [
            [13908, 8365],
            [4, 39],
            [-7, 47],
            [-31, 34],
            [-16, 71],
            [-4, 77],
        ],
        [
            [13854, 8633],
            [28, 22],
            [13, 73],
            [26, 2],
            [58, -34],
            [47, 25],
            [31, -9],
            [13, 27],
            [332, 2],
            [17, 86],
            [-13, 16],
            [-41, 531],
            [-39, 529],
            [126, 3],
        ],
        [
            [15211, 9190],
            [0, -283],
            [-45, -82],
            [-7, -75],
            [-74, -20],
            [-112, -10],
            [-31, -44],
            [-53, -5],
        ],
        [
            [22508, 9546],
            [14, 9],
            [27, 20],
            [-8, 52],
            [1, 2],
            [17, 40],
            [2, 99],
            [1, 27],
            [18, -7],
            [47, -20],
            [15, 43],
            [22, 58],
            [4, 60],
            [12, 26],
            [34, 77],
            [-2, 42],
        ],
        [
            [22712, 10074],
            [-1, 28],
        ],
        [
            [22711, 10102],
            [43, 36],
            [64, 49],
            [59, -22],
            [-8, 76],
            [15, 12],
            [14, 11],
            [-6, 46],
            [49, 9],
            [28, -73],
            [35, -29],
            [3, -94],
            [-4, -101],
            [-78, -102],
            [-10, -146],
            [87, 20],
            [20, -114],
            [53, -23],
            [-24, -102],
            [61, -47],
            [35, -21],
            [62, 35],
            [2, -51],
        ],
        [
            [23122, 9346],
            [-46, -28],
            [-49, -54],
            [-58, -5],
            [-37, -133],
            [-36, -23],
            [41, -108],
            [52, -89],
            [34, -82],
            [-30, -106],
            [-29, -23],
            [19, -61],
            [55, -98],
            [10, -69],
            [-1, -57],
            [32, -112],
            [-45, -114],
            [-40, -127],
        ],
        [
            [22994, 8057],
            [-8, 92],
            [25, 94],
            [-28, 72],
            [7, 134],
            [-34, 63],
            [-26, 148],
            [-15, 155],
            [-36, 102],
            [-54, -62],
            [-94, -88],
            [-46, 12],
            [-51, 29],
            [28, 152],
            [-18, 115],
            [-64, 141],
            [10, 45],
            [-48, 15],
            [-58, 101],
        ],
        [
            [16457, 11983],
            [-16, 11],
            [-24, 39],
            [-35, 25],
        ],
        [
            [16445, 12187],
            [22, -22],
            [12, -17],
            [27, -13],
            [31, -26],
            [-6, -11],
        ],
        [
            [24491, 12968],
            [-41, -92],
            [-58, -122],
            [21, -51],
            [46, 15],
            [82, -18],
            [64, 44],
            [66, -39],
            [75, -85],
            [-10, -45],
            [-65, 15],
            [-119, -17],
            [-58, -35],
            [-62, -81],
            [-125, -48],
            [-82, -65],
            [-85, 25],
            [-47, 11],
            [-43, -79],
            [27, -47],
        ],
        [
            [24077, 12254],
            [13, -40],
            [-58, -42],
            [-59, -66],
            [-97, -44],
        ],
        [
            [23876, 12062],
            [-124, -4],
            [-133, -42],
            [-96, -67],
            [-36, 39],
        ],
        [
            [23487, 11988],
            [-100, 0],
            [-122, 74],
            [-82, 19],
            [-109, -18],
        ],
        [
            [23074, 12063],
            [-171, 28],
        ],
        [
            [22903, 12091],
            [-91, -3],
            [-48, 73],
            [-38, 114],
            [-52, 13],
            [-99, 77],
        ],
        [
            [22575, 12365],
            [-111, 16],
            [-98, 22],
            [-30, 53],
            [32, 144],
            [-57, 99],
            [-118, 46],
            [-69, 66],
            [-22, 85],
        ],
        [
            [22102, 12896],
            [87, 21],
            [158, 106],
            [125, 58],
            [72, -38],
            [86, -1],
            [56, -58],
            [82, -5],
            [119, -31],
            [80, 86],
            [-33, 73],
            [86, 126],
            [92, -50],
            [75, -14],
            [96, -32],
            [17, -92],
            [117, -52],
            [78, 23],
            [105, 16],
            [81, -16],
            [81, -59],
            [51, -63],
            [76, 2],
            [105, -21],
            [75, 31],
            [109, 21],
            [121, 86],
            [49, -14],
            [44, -40],
            [99, 9],
        ],
        [
            [17711, 5420],
            [63, 10],
            [99, -35],
            [21, 15],
            [58, 4],
            [29, 37],
            [50, -2],
            [90, 47],
            [65, 72],
        ],
        [
            [18186, 5568],
            [14, -55],
            [-3, -122],
            [10, -108],
            [3, -193],
            [15, -60],
            [-25, -88],
            [-32, -85],
            [-53, -76],
            [-75, -48],
            [-93, -59],
            [-94, -131],
            [-32, -23],
            [-57, -87],
            [-34, -29],
            [-7, -87],
            [39, -93],
            [17, -73],
            [1, -36],
            [14, 6],
            [-2, -121],
            [-13, -57],
            [18, -21],
            [-11, -51],
            [-35, -44],
            [-68, -42],
            [-99, -66],
            [-37, -46],
            [8, -52],
            [20, -8],
            [-7, -64],
        ],
        [
            [17568, 3549],
            [-62, 1],
        ],
        [
            [17506, 3550],
            [-7, 54],
            [-13, 56],
        ],
        [
            [17486, 3660],
            [-6, 43],
            [15, 137],
            [-22, 88],
            [-40, 173],
        ],
        [
            [17433, 4101],
            [87, 139],
            [22, 89],
            [13, 12],
            [9, 71],
            [-13, 36],
            [3, 93],
            [16, 85],
            [0, 155],
            [-43, 40],
            [-40, 9],
            [-17, 30],
            [-39, 26],
            [-68, -2],
            [-5, 45],
        ],
        [
            [17358, 4929],
            [-9, 88],
            [251, 102],
        ],
        [
            [17600, 5119],
            [48, -59],
            [23, 11],
            [32, -32],
            [5, -48],
            [-17, -58],
            [6, -87],
            [54, -75],
            [25, 85],
            [35, 26],
            [-6, 158],
            [-36, 89],
            [-29, 40],
            [-29, -2],
            [-22, 159],
            [22, 94],
        ],
        [
            [13854, 8633],
            [-55, 85],
            [-50, 90],
            [-55, 32],
            [-39, 36],
            [-46, -1],
            [-40, -27],
            [-41, 11],
            [-29, -40],
        ],
        [
            [13499, 8819],
            [-7, 66],
            [24, 62],
            [9, 115],
            [-8, 122],
            [-10, 60],
            [8, 62],
            [-22, 58],
            [-44, 53],
        ],
        [
            [13449, 9417],
            [19, 42],
            [324, -1],
            [-17, 177],
            [21, 63],
            [77, 11],
            [-2, 315],
            [270, -7],
            [0, 187],
        ],
        [
            [17600, 5119],
            [-43, 31],
            [25, 114],
            [26, 44],
            [-16, 101],
            [17, 100],
            [13, 33],
            [-21, 105],
            [-38, 55],
        ],
        [
            [17563, 5702],
            [81, -23],
            [17, -34],
            [28, -58],
            [22, -167],
        ],
        [
            [23290, 7601],
            [19, -12],
            [49, -74],
            [34, -83],
            [5, -82],
            [-9, -55],
            [8, -43],
            [6, -73],
            [30, -34],
            [32, -109],
            [-1, -41],
            [-59, -8],
            [-78, 91],
            [-98, 97],
            [-10, 63],
            [-48, 82],
            [-11, 102],
            [-29, 67],
            [8, 90],
            [-18, 51],
        ],
        [
            [23120, 7630],
            [15, 22],
            [67, -53],
            [7, -63],
            [55, 15],
            [26, 50],
        ],
        [
            [24389, 7506],
            [63, 85],
            [42, 96],
            [33, 0],
            [42, -62],
            [4, -53],
            [54, -34],
            [70, -37],
            [-6, -48],
            [-55, -6],
            [14, -60],
            [-61, -43],
        ],
        [
            [23911, 7083],
            [60, -42],
            [64, 22],
            [17, 105],
            [35, 23],
            [99, 27],
            [59, 96],
            [41, 78],
        ],
        [
            [16775, 4682],
            [67, 28],
            [54, -8],
            [32, -27],
            [1, -10],
        ],
        [
            [16501, 3791],
            [0, -454],
            [-74, -63],
            [-44, -8],
            [-52, 23],
            [-38, 9],
            [-13, 53],
            [-33, 33],
            [-40, -61],
        ],
        [
            [16207, 3323],
            [-61, 93],
            [-32, 90],
            [-19, 120],
            [-19, 89],
            [-28, 189],
            [-2, 147],
            [-11, 67],
            [-32, 51],
            [-42, 101],
            [-44, 147],
            [-18, 78],
            [-67, 120],
            [-5, 95],
        ],
        [
            [28543, 4245],
            [68, -76],
            [43, -57],
            [-31, -29],
            [-46, 33],
            [-59, 55],
            [-53, 65],
            [-55, 87],
            [-11, 41],
            [35, -2],
            [47, -41],
            [36, -41],
            [26, -35],
        ],
        [
            [16084, 9646],
            [21, -190],
            [31, -33],
            [1, -39],
            [34, -41],
            [-18, -53],
            [-32, -250],
            [-4, -160],
            [-106, -116],
            [-35, -161],
            [34, -46],
            [0, -80],
            [53, -2],
            [-8, -58],
        ],
        [
            [16029, 8370],
            [-16, -2],
            [-56, 134],
            [-18, 5],
            [-65, -68],
            [-64, 35],
            [-44, 7],
            [-24, -16],
            [-49, 3],
            [-48, -52],
            [-42, -3],
            [-100, 63],
            [-39, -30],
            [-43, 2],
            [-31, 47],
            [-82, 45],
            [-89, -14],
            [-22, -27],
            [-11, -71],
            [-24, -49],
            [-5, -109],
        ],
        [
            [15560, 7423],
            [-86, -45],
            [-31, 7],
            [-31, -27],
            [-67, 2],
            [-44, 77],
            [-27, 89],
            [-58, 81],
            [-62, -2],
            [-73, 0],
        ],
        [
            [7783, 8199],
            [-29, 39],
            [-38, 49],
            [-18, 42],
            [-35, 38],
            [-42, 56],
            [10, 19],
            [13, -19],
            [6, 10],
        ],
        [
            [7995, 8679],
            [-7, -11],
            [-5, -28],
            [9, -45],
            [-19, -42],
            [-9, -49],
            [-2, -55],
            [4, -31],
            [2, -55],
            [-13, -12],
            [-7, -53],
            [5, -32],
            [-16, -32],
            [3, -34],
            [13, -19],
        ],
        [
            [15132, 13147],
            [42, 35],
            [73, 180],
            [113, 52],
            [68, -3],
        ],
        [
            [16563, 15331],
            [-52, -5],
            [-11, -81],
            [-156, 20],
            [-23, -69],
            [-78, 0],
            [-54, -87],
            [-83, -136],
            [-129, -174],
            [30, -41],
            [-28, -49],
            [-82, 2],
            [-54, -115],
            [6, -163],
            [52, -63],
            [-28, -144],
            [-68, -85],
            [-36, -70],
        ],
        [
            [15769, 14071],
            [-55, 75],
            [-163, -141],
            [-111, -30],
            [-114, 63],
            [-29, 132],
            [-26, 283],
            [76, 80],
            [217, 103],
            [163, 127],
            [152, 171],
            [198, 238],
            [139, 92],
            [226, 154],
            [181, 54],
            [136, -6],
            [126, 101],
            [150, -5],
            [148, 24],
            [259, -90],
            [-106, -32],
            [90, -78],
        ],
        [
            [17426, 15386],
            [-141, -49],
            [-66, -11],
        ],
        [
            [16899, 16406],
            [-184, -50],
            [-146, 28],
            [57, 32],
            [-49, 40],
            [171, 24],
            [32, -46],
            [119, -28],
        ],
        [
            [16365, 16633],
            [272, -91],
            [-208, -49],
            [-45, -90],
            [-73, -23],
            [-39, -103],
            [-100, -4],
            [-177, 75],
            [74, 43],
            [-123, 36],
            [-161, 104],
            [-64, 96],
            [225, 44],
            [45, -43],
            [118, 2],
            [31, 42],
            [121, 4],
            [104, -43],
        ],
        [
            [16959, 16720],
            [162, -44],
            [-122, -66],
            [-240, -13],
            [-244, 19],
            [-14, 35],
            [-118, 2],
            [-91, 57],
            [256, 34],
            [119, -30],
            [84, 37],
            [208, -31],
        ],
        [
            [21655, 10538],
            [83, -80],
        ],
        [
            [21738, 10458],
            [46, -18],
            [27, -59],
            [65, -24],
            [67, -54],
            [93, -29],
            [97, -11],
            [-1, -6],
            [-5, -47],
        ],
        [
            [22127, 10210],
            [10, -79],
            [-9, -48],
            [-69, -2],
            [-99, 29],
            [-48, 9],
            [-17, 2],
            [-47, 63],
        ],
        [
            [21848, 10184],
            [-113, 16],
            [-107, 69],
        ],
        [
            [21628, 10269],
            [-2, 1],
        ],
        [
            [21626, 10270],
            [-76, 59],
            [-81, 47],
            [32, 115],
            [53, 55],
            [34, 30],
            [67, -38],
        ],
        [
            [29141, 1807],
            [19, -51],
            [58, 49],
            [24, -51],
            [0, -52],
            [-30, -57],
            [-54, -91],
            [-43, -49],
            [30, -59],
            [-63, -2],
            [-70, -47],
            [-23, -80],
            [-47, -124],
            [-64, -55],
            [-42, -35],
            [-75, 2],
            [-54, 41],
            [-89, 9],
            [-15, 44],
            [45, 91],
            [103, 122],
            [54, 23],
            [59, 47],
            [71, 64],
            [50, 65],
            [36, 91],
            [31, 31],
            [13, 68],
            [58, 57],
            [18, -51],
        ],
        [
            [29272, 2391],
            [60, -128],
            [2, 84],
            [37, -35],
            [12, -92],
            [66, -40],
            [56, -11],
            [48, 48],
            [42, -15],
            [-20, -108],
            [-26, -73],
            [-62, 3],
            [-23, -37],
            [8, -53],
            [-12, -23],
            [-31, -66],
            [-41, -84],
            [-64, -50],
            [-15, 32],
            [-34, 19],
            [48, 100],
            [-27, 68],
            [-89, 50],
            [3, 44],
            [59, 43],
            [14, 94],
            [-4, 80],
            [-33, 83],
            [2, 21],
            [-39, 51],
            [-66, 108],
            [-34, 87],
            [30, 10],
            [46, -68],
            [64, -32],
            [23, -110],
        ],
        [
            [19514, 9900],
            [37, -84],
            [46, -44],
            [60, -17],
            [50, -22],
            [36, -70],
            [23, -42],
            [29, -15],
            [0, -27],
            [-30, -74],
            [-12, -34],
            [-36, -39],
            [-31, -84],
            [-37, 6],
            [-17, -30],
            [-13, -62],
            [10, -82],
            [-8, -16],
            [-38, 1],
            [-51, -45],
            [-9, -60],
            [-18, -26],
            [-52, 1],
            [-32, -31],
            [1, -50],
            [-41, -34],
            [-45, 11],
            [-55, -41],
            [-39, -8],
        ],
        [
            [19242, 8882],
            [-27, 86],
            [-64, 204],
        ],
        [
            [19151, 9172],
            [248, 123],
            [55, 246],
            [-39, 87],
        ],
        [
            [19487, 10039],
            [24, 42],
            [10, -11],
            [-8, -51],
            [-10, -22],
        ],
        [
            [20903, 11353],
            [-19, -6],
            [-41, -98],
            [99, -49],
            [25, -61],
            [-41, -62],
            [25, -185],
            [53, -28],
            [67, -61],
            [-20, -20],
            [-51, -52],
            [1, -87],
            [-80, -123],
            [-20, -51],
            [-31, -74],
            [-86, -129],
            [-17, 2],
        ],
        [
            [20767, 10269],
            [-79, 7],
            [-46, -64],
            [-45, -64],
            [54, -55],
            [9, -95],
            [16, -20],
            [31, -43],
        ],
        [
            [20707, 9935],
            [16, -105],
            [-182, 1],
        ],
        [
            [20541, 9831],
            [-55, -82],
        ],
        [
            [20486, 9749],
            [-60, 30],
            [-25, 89],
            [-63, 93],
            [-153, -22],
            [-134, -3],
            [-116, -17],
        ],
        [
            [8429, 7724],
            [-27, 36],
            [-17, 66],
            [19, 32],
            [-20, 9],
            [-16, 41],
            [-40, 34],
            [-37, -8],
            [-16, -42],
            [-33, -32],
            [-19, -4],
            [-8, -26],
            [39, -66],
            [-22, -16],
            [-12, -18],
            [-39, -6],
            [-14, 73],
            [-10, -21],
            [-27, 8],
            [-17, 48],
            [-34, 9],
            [-22, 14],
            [-35, 0],
            [-2, -27],
            [-10, 19],
        ],
        [
            [8045, 8012],
            [30, -44],
            [-2, -27],
            [33, -5],
            [7, 10],
            [23, -30],
            [40, 9],
            [36, 31],
            [49, 25],
            [29, 37],
            [46, -7],
            [-3, -13],
            [46, -4],
            [36, -22],
            [27, -36],
            [31, -34],
        ],
        [
            [9050, 4581],
            [-83, 71],
            [-8, 49],
            [-163, 124],
            [-148, 134],
            [-64, 76],
            [-34, 102],
            [13, 35],
            [-69, 162],
            [-82, 227],
            [-78, 244],
            [-33, 56],
            [-26, 90],
            [-64, 81],
            [-60, 50],
            [27, 55],
            [-40, 117],
            [26, 86],
            [66, 78],
        ],
        [
            [25290, 7871],
            [9, -82],
            [5, -70],
            [-28, -111],
            [-30, 125],
            [-39, -63],
            [26, -90],
            [-24, -57],
            [-96, 70],
            [-24, 89],
            [26, 59],
            [-53, 58],
            [-26, -52],
            [-38, 6],
            [-61, -69],
            [-14, 37],
            [32, 103],
            [52, 34],
            [45, 47],
            [30, -56],
            [62, 33],
            [13, 55],
            [59, 3],
            [-5, 96],
            [67, -58],
            [7, -63],
            [5, -44],
        ],
        [
            [25093, 8100],
            [-30, -41],
            [-26, -77],
            [-26, -37],
            [-51, 85],
            [18, 33],
            [21, 35],
            [8, 75],
            [46, 8],
            [-14, -83],
            [61, 119],
            [-7, -117],
        ],
        [
            [24641, 7982],
            [-110, -117],
            [40, 86],
            [60, 75],
            [49, 85],
            [44, 123],
            [14, -101],
            [-54, -67],
            [-43, -84],
        ],
        [
            [24920, 8298],
            [50, -39],
            [52, 0],
            [-1, -50],
            [-39, -52],
            [-53, -37],
            [-3, 57],
            [6, 63],
            [-12, 58],
        ],
        [
            [25219, 8331],
            [22, -137],
            [-63, 32],
            [1, -41],
            [21, -76],
            [-39, -27],
            [-4, 86],
            [-24, 6],
            [-14, 75],
            [49, -9],
            [-1, 45],
            [-51, 95],
            [80, -3],
            [23, -46],
        ],
        [
            [24890, 8443],
            [-22, -107],
            [-35, 61],
            [-42, 95],
            [71, -6],
            [28, -43],
        ],
        [
            [24873, 9110],
            [51, -35],
            [26, 32],
            [7, -31],
            [-13, -51],
            [28, -88],
            [-22, -102],
            [-49, -41],
            [-12, -99],
            [17, -98],
            [45, -13],
            [36, 14],
            [103, -68],
            [-7, -66],
            [27, -30],
            [-10, -57],
            [-64, 61],
            [-30, 64],
            [-22, -46],
            [-52, 74],
            [-75, -18],
            [-41, 27],
            [4, 51],
            [26, 31],
            [-25, 29],
            [-10, -44],
            [-41, 70],
            [-12, 54],
            [-3, 117],
            [33, -40],
            [8, 192],
            [27, 111],
            [50, 0],
        ],
        [
            [27726, 5998],
            [-23, -13],
            [-36, 47],
            [-36, 79],
            [-17, 93],
            [11, 13],
            [8, -38],
            [25, -28],
            [41, -77],
            [39, -42],
            [-12, -34],
        ],
        [
            [27404, 6163],
            [-42, -11],
            [-14, -34],
            [-46, -30],
            [-41, -29],
            [-45, 0],
            [-67, 36],
            [-47, 35],
            [6, 38],
            [75, -19],
            [45, 11],
            [12, 59],
            [13, 2],
            [7, -66],
            [48, 11],
            [22, 41],
            [47, 44],
            [-9, 72],
            [48, 3],
            [18, -20],
            [-2, -69],
            [-28, -74],
        ],
        [
            [26498, 6516],
            [143, -84],
            [152, -70],
            [57, -63],
            [47, -63],
            [12, -72],
            [137, -76],
            [21, -65],
            [-77, -14],
            [19, -82],
            [73, -79],
            [54, -131],
            [47, 4],
            [-3, -55],
            [63, -20],
            [-24, -23],
            [88, -52],
            [-10, -36],
            [-54, -9],
            [-20, 32],
            [-71, 14],
            [-84, 19],
            [-64, 78],
            [-47, 68],
            [-43, 108],
            [-107, 53],
            [-70, -35],
            [-51, -40],
            [11, -91],
            [-65, -43],
            [-46, 21],
            [-85, 5],
        ],
        [
            [27499, 6283],
            [-25, -33],
            [-16, 72],
            [-19, 48],
            [-37, 40],
            [-47, 53],
            [-60, 36],
            [23, 30],
            [45, -35],
            [28, -27],
            [35, -30],
            [33, -50],
            [31, -40],
            [9, -64],
        ],
        [
            [16801, 13176],
            [41, -107],
            [-9, -35],
            [-41, -14],
            [-74, -102],
            [20, -56],
            [-17, 8],
        ],
        [
            [16721, 12870],
            [-79, 47],
            [-59, -17],
            [-40, 12],
            [-48, -26],
            [-42, 44],
            [-34, -17],
            [-4, 7],
        ],
        [
            [16024, 13444],
            [57, 36],
            [128, 57],
            [104, 42],
            [82, -21],
            [6, -30],
            [80, -1],
        ],
        [
            [16481, 13527],
            [102, -15],
            [152, 2],
        ],
        [
            [9387, 9111],
            [42, -10],
            [15, -25],
            [-21, -30],
            [-62, 0],
            [-48, -4],
            [-5, 53],
            [11, 17],
            [68, -1],
        ],
        [
            [25183, 11823],
            [92, 66],
            [57, 87],
            [38, -38],
            [72, -4],
            [-13, 64],
            [128, 53],
            [32, 69],
            [54, -72],
        ],
        [
            [25643, 12048],
            [11, -22],
        ],
        [
            [25654, 12026],
            [-31, 7],
            [-36, -41],
            [-25, -42],
            [3, -89],
            [-42, -27],
            [-15, -22],
            [-31, -36],
            [-55, -20],
            [-36, -33],
            [-2, -54],
            [-10, -13],
            [32, -20],
            [47, -54],
        ],
        [
            [25274, 11477],
            [-40, 22],
            [-10, -22],
            [-24, -11],
            [-3, 24],
            [-22, 10],
            [-22, 20],
            [23, 54],
            [19, 15],
            [-7, 22],
            [21, 67],
            [-6, 19],
            [-48, 14],
            [-39, 33],
            [67, 79],
        ],
        [
            [14243, 11396],
            [-33, -31],
            [-44, 16],
            [-42, -13],
            [12, 96],
            [-7, 76],
            [-38, 11],
            [-19, 47],
            [7, 81],
            [32, 44],
            [6, 50],
            [18, 74],
            [-2, 51],
            [-17, 45],
            [-4, 41],
        ],
        [
            [19052, 9879],
            [-5, 90],
            [22, 64],
            [23, 13],
            [25, -38],
            [2, -73],
            [-19, -72],
        ],
        [
            [19100, 9863],
            [-23, -8],
            [-25, 24],
        ],
        [
            [16729, 12274],
            [-15, 21],
            [19, 21],
            [-20, 15],
            [-26, -27],
            [-48, 35],
            [-7, 51],
            [-50, 29],
            [-10, 39],
            [-44, 48],
        ],
        [
            [16733, 12721],
            [36, 27],
            [51, -13],
            [53, -1],
            [39, -30],
            [28, 19],
            [60, 12],
            [21, 29],
            [35, 0],
        ],
        [
            [17189, 12428],
            [37, -23],
            [38, 20],
            [39, -22],
        ],
        [
            [17303, 12403],
            [1, -31],
            [-40, -27],
            [-25, 12],
            [-23, -149],
        ],
        [
            [26716, 13074],
            [83, -218],
            [-122, 41],
            [-51, -178],
            [81, -126],
            [-2, -85],
            [-63, 73],
            [-54, -95],
            [-16, 104],
            [10, 119],
            [-10, 133],
            [20, 93],
            [3, 165],
            [-49, 120],
            [8, 168],
            [76, 57],
            [-33, 57],
            [37, 16],
            [22, -80],
            [28, -119],
            [-2, -121],
            [34, -124],
        ],
        [
            [16481, 13527],
            [19, 54],
            [114, 39],
        ],
        [
            [18156, 12175],
            [-104, 104],
            [-95, 46],
            [-71, 73],
            [60, 20],
            [68, 102],
            [-45, 49],
            [121, 50],
            [-2, 27],
            [-74, -20],
        ],
        [
            [18014, 12626],
            [2, 55],
            [43, 34],
            [80, 8],
            [13, 42],
            [-19, 67],
            [34, 66],
            [-1, 35],
            [-122, 40],
            [-47, -1],
            [-51, 57],
            [-65, -19],
            [-104, 43],
            [2, 24],
            [-29, 54],
            [-67, 6],
            [-6, 37],
            [21, 25],
            [-53, 71],
            [-86, -13],
            [-25, 6],
            [-21, -28],
            [-31, 6],
        ],
        [
            [17168, 14146],
            [94, 69],
            [-87, 58],
        ],
        [
            [17426, 15386],
            [85, 44],
            [136, -75],
            [226, -29],
            [311, -140],
            [64, -58],
            [6, -82],
            [-93, -64],
            [-134, -33],
            [-369, 93],
            [-60, -16],
            [134, -90],
            [5, -57],
            [6, -125],
            [106, -37],
            [65, -33],
            [10, 61],
            [-49, 52],
            [52, 47],
            [200, -77],
            [70, 30],
            [-56, 91],
            [193, 120],
            [75, -7],
            [77, -44],
            [49, 85],
            [-70, 74],
            [41, 73],
            [-60, 76],
            [230, -40],
            [48, -68],
            [-105, -16],
            [1, -68],
            [64, -42],
            [128, 27],
            [21, 78],
            [172, 58],
            [288, 106],
            [62, -6],
            [-82, -75],
            [103, -12],
            [59, 41],
            [155, 4],
            [122, 51],
            [94, -74],
            [94, 81],
            [-86, 72],
            [42, 40],
            [245, -37],
            [114, -38],
            [299, -141],
            [55, 64],
            [-84, 65],
            [-2, 26],
            [-100, 13],
            [27, 58],
            [-43, 96],
            [-4, 39],
            [153, 111],
            [55, 112],
            [61, 24],
            [218, -32],
            [18, -68],
            [-78, -100],
            [51, -39],
            [27, -86],
            [-19, -168],
            [90, -76],
            [-35, -82],
            [-162, -175],
            [94, -18],
            [34, 44],
            [91, 32],
            [22, 61],
            [71, 58],
            [-48, 70],
            [38, 82],
            [-90, 10],
            [-20, 68],
            [66, 124],
            [-108, 99],
            [149, 83],
            [-20, 88],
            [42, 2],
            [43, -67],
            [-33, -119],
            [89, -23],
            [-38, 89],
            [139, 49],
            [171, 6],
            [152, -70],
            [-73, 102],
            [-9, 131],
            [144, 25],
            [199, -6],
            [178, 17],
            [-66, 64],
            [95, 80],
            [95, 4],
            [160, 61],
            [218, 16],
            [28, 34],
            [217, 12],
            [67, -28],
            [185, 65],
            [152, -2],
            [23, 54],
            [79, 52],
            [194, 50],
            [142, -40],
            [-112, -30],
            [187, -19],
            [22, -61],
            [75, 30],
            [242, -2],
            [186, -59],
            [66, -47],
            [-21, -63],
            [-91, -37],
            [-217, -68],
            [-62, -37],
            [103, -17],
            [122, -31],
            [74, 23],
            [42, -79],
            [36, 33],
            [132, 19],
            [265, -20],
            [20, -57],
            [346, -19],
            [4, 94],
            [175, -22],
            [132, 1],
            [134, -65],
            [37, -79],
            [-49, -50],
            [104, -98],
            [130, -50],
            [80, 129],
            [132, -55],
            [140, 33],
            [160, -37],
            [61, 34],
            [135, -18],
            [-59, 114],
            [109, 54],
            [746, -79],
            [69, -74],
            [217, -93],
            [333, 22],
            [165, -20],
            [68, -51],
            [-10, -89],
            [102, -35],
            [111, 24],
            [146, 4],
            [155, -24],
            [157, 13],
            [144, -109],
            [102, 40],
            [-67, 77],
            [37, 55],
            [263, -34],
            [172, 7],
            [238, -58],
            [115, -53],
            [0, -491],
            [-1, 0],
            [-106, -54],
            [-107, 9],
            [75, -66],
            [49, -101],
            [38, -33],
            [9, -51],
            [-20, -32],
            [-155, 27],
            [-230, -94],
            [-74, -13],
            [-126, -87],
            [-120, -75],
            [-31, -56],
            [-117, 85],
            [-216, -96],
            [-37, 45],
            [-80, -52],
            [-110, 16],
            [-27, -81],
            [-98, -119],
            [3, -50],
            [93, -27],
            [-10, -179],
            [-77, -4],
            [-35, -103],
            [34, -53],
            [-144, -63],
            [-29, -141],
            [-124, -29],
            [-23, -125],
            [-120, -114],
            [-31, 84],
            [-35, 179],
            [-46, 274],
            [40, 170],
            [69, 73],
            [4, 58],
            [129, 27],
            [147, 155],
            [142, 126],
            [149, 99],
            [66, 173],
            [-100, -11],
            [-49, -101],
            [-210, -135],
            [-67, 151],
            [-214, -41],
            [-206, -206],
            [68, -76],
            [-185, -32],
            [-127, -12],
            [6, 89],
            [-128, 18],
            [-102, -60],
            [-253, 21],
            [-272, -36],
            [-267, -241],
            [-316, -289],
            [130, -16],
            [40, -77],
            [80, -27],
            [53, 61],
            [91, -7],
            [120, -136],
            [2, -105],
            [-65, -122],
            [-7, -147],
            [-37, -197],
            [-124, -177],
            [-28, -85],
            [-112, -143],
            [-111, -142],
            [-54, -73],
            [-110, -73],
            [-51, -1],
            [-52, 60],
            [-111, -91],
            [-13, -40],
        ],
        [
            [25643, 12048],
            [-1, 62],
            [42, 3],
            [12, 145],
            [-22, 106],
            [72, 43],
            [99, -21],
            [56, 119],
            [28, 135],
            [32, 44],
            [44, 111],
            [-137, -36],
            [-72, -49],
            [-125, 1],
            [-33, 115],
            [-98, 87],
            [-144, 40],
            [-30, 120],
            [-29, 75],
            [-31, 53],
            [-51, 125],
            [-73, 45],
            [-123, 37],
            [-109, -4],
            [-103, -22],
            [-68, -62],
            [45, -28],
        ],
        [
            [24824, 13292],
            [2, -68],
            [-47, -39],
            [-75, -131],
            [1, -54],
            [-116, -78],
            [-98, 46],
        ],
        [
            [22102, 12896],
            [-32, -10],
        ],
        [
            [18911, 12540],
            [-37, -74],
            [-80, -19],
            [-82, -128],
            [75, -116],
            [-8, -83],
            [90, -145],
        ],
        [
            [23532, 16462],
            [-466, -48],
            [152, 162],
            [67, 13],
            [63, -8],
            [209, -69],
            [-25, -50],
        ],
        [
            [19079, 16738],
            [-111, -17],
            [-73, -9],
            [-12, -21],
            [-96, -20],
            [-90, 29],
            [47, 39],
            [-183, 4],
            [161, 22],
            [125, 2],
            [17, -34],
            [47, 29],
            [78, 21],
            [123, -27],
            [-33, -18],
        ],
        [
            [23108, 16532],
            [-180, -15],
            [-230, 35],
            [-137, 48],
            [-63, 88],
            [-113, 24],
            [215, 84],
            [178, 28],
            [161, -63],
            [190, -118],
            [-21, -111],
        ],
        [
            [412, 15021],
            [55, -31],
            [-19, 89],
            [224, -17],
            [162, -115],
            [-82, -54],
            [-136, -13],
            [-1, -120],
            [-33, -26],
            [-78, 4],
            [-63, 43],
            [-109, 36],
            [-19, 53],
            [-84, 20],
            [-93, -16],
            [-46, 43],
            [19, 46],
            [-100, -30],
            [38, -57],
            [-47, -53],
            [0, 491],
            [202, -95],
            [217, -122],
            [-7, -76],
        ],
        [
            [29717, 15543],
            [-90, -7],
            [-15, 40],
            [105, 51],
            [0, -84],
        ],
        [
            [108, 15551],
            [-108, -8],
            [0, 84],
            [10, 5],
            [71, 0],
            [119, -36],
            [-7, -16],
            [-85, -29],
        ],
        [
            [26712, 15836],
            [-124, -1],
            [-169, 13],
            [-15, 7],
            [78, 48],
            [104, 12],
            [117, -47],
            [9, -32],
        ],
        [
            [27301, 16066],
            [-95, -49],
            [-133, 11],
            [-152, 48],
            [19, 41],
            [154, -19],
            [207, -32],
        ],
        [
            [26835, 16124],
            [-65, -91],
            [-305, 4],
            [-137, -29],
            [-162, 80],
            [44, 84],
            [109, 24],
            [218, -7],
            [298, -65],
        ],
        [
            [19608, 15529],
            [-48, -10],
            [-271, 15],
            [-22, 55],
            [-149, 33],
            [-12, 67],
            [84, 26],
            [-3, 67],
            [164, 105],
            [-76, 14],
            [199, 108],
            [-23, 56],
            [184, 65],
            [273, 79],
            [275, 23],
            [141, 45],
            [161, 17],
            [57, -49],
            [-55, -38],
            [-293, -61],
            [-252, -58],
            [-257, -117],
            [-122, -121],
            [-130, -118],
            [17, -102],
            [158, -101],
        ],
        [
            [17301, 6672],
            [19, -14],
            [49, 39],
        ],
        [
            [17369, 6697],
            [33, -70],
            [-5, -72],
            [-24, -16],
        ],
        [
            [13449, 9417],
            [5, 52],
        ],
        [
            [19151, 9172],
            [-238, -47],
            [-77, -56],
            [-59, -129],
            [-39, -20],
            [-21, 40],
            [-31, -6],
            [-80, 12],
            [-15, 13],
            [-96, -3],
            [-21, -11],
            [-35, 32],
            [-21, -61],
            [8, -52],
            [-36, -39],
        ],
        [
            [18390, 8845],
            [-11, 53],
            [-25, 37],
            [-6, 49],
            [-42, 45],
            [-45, 102],
            [-23, 101],
            [-57, 84],
            [-37, 20],
            [-55, 117],
            [-9, 85],
            [4, 74],
            [-48, 136],
            [-38, 47],
            [-45, 26],
            [-27, 71],
            [4, 28],
            [-23, 63],
            [-23, 28],
            [-34, 91],
            [-49, 99],
            [-43, 85],
            [-40, -1],
            [12, 67],
            [4, 44],
            [11, 48],
        ],
        [
            [18855, 10346],
            [33, -106],
            [40, -28],
            [15, -43],
            [56, -52],
            [5, -51],
            [-9, -40],
            [11, -42],
            [24, -34],
            [10, -41],
            [12, -30],
        ],
        [
            [19100, 9863],
            [17, -47],
        ],
        [
            [17663, 7999],
            [-13, 2],
            [2, 62],
            [-10, 42],
            [-43, 49],
            [-9, 89],
            [9, 90],
            [-38, 8],
            [-5, -27],
            [-50, -6],
            [20, -36],
            [7, -74],
            [-46, -67],
            [-40, -89],
            [-43, -13],
            [-69, 72],
            [-31, -25],
            [-9, -36],
            [-42, -23],
            [-3, -26],
            [-82, 0],
            [-12, 26],
            [-60, 4],
            [-29, -22],
            [-23, 12],
            [-42, 71],
            [-15, 33],
            [-59, -16],
            [-23, -57],
            [-21, -110],
            [-28, -23],
            [-25, -13],
        ],
        [
            [16746, 8206],
            [1, 30],
            [-30, 36],
            [-1, 71],
            [-18, 47],
            [-29, -6],
            [8, 44],
            [22, 52],
            [-9, 51],
            [27, 37],
            [-17, 29],
            [22, 76],
            [37, 90],
            [72, -8],
            [-4, 488],
        ],
        [
            [17902, 9541],
            [26, -122],
            [-18, -22],
            [12, -126],
            [31, -147],
            [31, -30],
            [45, -46],
        ],
        [
            [17666, 7359],
            [-51, -57],
            [-58, 0],
            [-67, -28],
            [-52, 27],
            [-35, -33],
        ],
        [
            [13482, 8358],
            [-14, 94],
        ],
        [
            [13478, 8507],
            [-34, 96],
            [-40, 44],
            [36, 23],
            [40, 86],
            [19, 63],
        ],
        [
            [28241, 5547],
            [23, -41],
            [-57, 0],
            [-32, 75],
            [49, -29],
            [17, -5],
        ],
        [
            [28136, 5623],
            [-32, -3],
            [-51, 12],
            [-16, 19],
            [5, 48],
            [54, -18],
            [27, -26],
            [13, -32],
        ],
        [
            [28205, 5656],
            [-13, -23],
            [-61, 107],
            [-18, 73],
            [28, 0],
            [31, -98],
            [33, -59],
        ],
        [
            [28055, 5811],
            [5, -25],
            [-66, 52],
            [-44, 45],
            [-32, 40],
            [13, 13],
            [37, -29],
            [69, -57],
            [18, -39],
        ],
        [
            [27862, 5933],
            [-15, -8],
            [-36, 28],
            [-35, 51],
            [5, 21],
            [49, -52],
            [32, -40],
        ],
        [
            [13914, 7670],
            [-22, 9],
            [-60, 50],
            [-42, 66],
            [-15, 45],
            [-10, 91],
        ],
        [
            [7611, 8481],
            [-8, -29],
            [-49, 2],
            [-29, 12],
            [-34, 25],
            [-46, 7],
            [-24, 27],
        ],
        [
            [18420, 8245],
            [27, -23],
            [16, -51],
            [38, -51],
            [40, 0],
            [78, 31],
            [90, 14],
            [73, 38],
            [40, 9],
            [30, 23],
            [47, 4],
        ],
        [
            [18899, 8239],
            [0, -2],
            [-1, -51],
            [0, -124],
            [0, -64],
            [-37, -75],
            [-57, -103],
        ],
        [
            [18899, 8239],
            [27, 2],
            [37, 18],
            [44, 13],
            [39, 41],
            [31, 1],
            [2, -34],
            [-7, -71],
            [0, -65],
            [-17, -44],
            [-23, -133],
            [-41, -138],
            [-51, -156],
            [-70, -181],
            [-71, -137],
            [-97, -168],
            [-82, -100],
            [-123, -122],
            [-77, -93],
            [-92, -149],
            [-18, -65],
            [-19, -29],
        ],
        [
            [10141, 7570],
            [99, -25],
            [9, 23],
            [67, 8],
            [88, -33],
        ],
        [
            [16721, 12870],
            [-23, -33],
            [-17, -48],
        ],
        [
            [15991, 12429],
            [18, 12],
        ],
        [
            [16832, 14950],
            [-142, -34],
            [-80, -86],
            [12, -76],
            [-130, -99],
            [-160, -105],
            [-60, -173],
            [59, -87],
            [79, -69],
            [-76, -138],
            [-86, -29],
            [-31, -206],
            [-48, -115],
            [-100, 11],
            [-46, -97],
            [-96, -5],
            [-27, 116],
            [-68, 139],
            [-63, 174],
        ],
        [
            [17506, 3550],
            [-17, -55],
            [-48, -14],
            [-49, 68],
            [-1, 42],
            [23, 46],
            [7, 36],
            [24, 8],
            [41, -21],
        ],
        [
            [17830, 11095],
            [-8, 93],
            [21, 51],
        ],
        [
            [17843, 11239],
            [22, 27],
            [22, 27],
            [4, 69],
            [27, -24],
            [91, 34],
            [44, -23],
            [68, 0],
            [95, 47],
            [44, -2],
            [94, 18],
        ],
        [
            [15013, 7591],
            [-67, -26],
        ],
        [
            [23327, 8334],
            [-75, 56],
            [-70, -2],
            [12, 96],
            [-73, 0],
            [-6, -135],
            [-44, -180],
            [-27, -109],
            [5, -89],
            [54, -3],
            [33, -113],
            [15, -105],
            [46, -71],
            [50, -14],
            [43, -64],
        ],
        [
            [23120, 7630],
            [-33, 48],
            [-13, 61],
            [-44, 70],
            [-40, 58],
            [-14, -73],
            [-15, 69],
            [9, 76],
            [24, 118],
        ],
        [
            [20458, 11402],
            [47, 124],
            [-19, 92],
            [-60, 30],
            [21, 54],
            [69, -7],
            [39, 69],
            [26, 78],
            [111, 29],
            [-17, -57],
            [11, -34],
            [34, 3],
        ],
        [
            [20940, 11683],
            [21, -114],
            [27, 12],
        ],
        [
            [20988, 11581],
            [51, -28],
            [-3, -47],
            [12, -70],
        ],
        [
            [19310, 11408],
            [-16, 87],
            [13, 129],
            [-65, 42],
            [21, 84],
            [-55, 7],
            [19, 104],
            [77, -31],
            [73, 39],
            [-60, 75],
            [-24, 69],
            [-66, -31],
            [-9, -90],
            [-26, 80],
        ],
        [
            [19479, 11914],
            [93, 1],
            [-13, 62],
            [70, 43],
            [70, 71],
            [111, -65],
            [8, -98],
            [32, -25],
            [89, 6],
            [28, -23],
            [41, -126],
            [94, -85],
            [54, -58],
            [86, -61],
            [110, -52],
            [-3, -75],
        ],
        [
            [25174, 5743],
            [10, 29],
            [71, 28],
            [58, 4],
            [26, 15],
            [31, -15],
            [-31, -33],
            [-86, -54],
            [-69, -35],
        ],
        [
            [9767, 8159],
            [47, 15],
            [18, -4],
            [-3, -91],
            [-70, -13],
            [-14, 10],
            [23, 34],
            [-1, 49],
        ],
        [
            [15554, 11377],
            [89, 50],
            [58, -15],
            [-2, -62],
            [70, 46],
            [6, -24],
            [-42, -60],
            [0, -57],
            [28, -30],
            [-10, -107],
            [-55, -62],
            [16, -67],
            [43, -2],
            [21, -59],
            [31, -18],
        ],
        [
            [17843, 11239],
            [-31, 56],
            [31, 46],
            [-50, -11],
            [-69, 29],
            [-56, -71],
            [-126, -14],
            [-67, 67],
            [-88, 4],
            [-20, -52],
            [-57, -14],
            [-80, 66],
            [-90, -3],
            [-49, 122],
            [-60, 69],
            [40, 95],
            [-53, 59],
            [93, 117],
            [126, 5],
            [36, 94],
            [156, -16],
            [100, 80],
            [96, 34],
            [136, 3],
            [144, -87],
            [119, -48],
            [96, 19],
            [72, -10],
            [97, 64],
        ],
        [
            [17170, 12000],
            [9, -48],
            [73, -39],
            [-16, -30],
            [-97, -7],
            [-35, -38],
            [-70, -66],
            [-26, 57],
            [1, 25],
        ],
        [
            [24911, 9835],
            [-50, -197],
            [-35, -102],
            [-44, 104],
            [-9, 91],
            [48, 121],
            [67, 94],
            [37, -38],
            [-14, -73],
        ],
        [
            [17369, 6697],
            [29, 14],
            [91, -1],
            [168, 9],
        ],
        [
            [18094, 6261],
            [-37, -151],
            [4, -70],
            [53, -45],
            [3, -31],
            [-23, -75],
            [4, -37],
            [-5, -59],
            [29, -77],
            [34, -121],
            [30, -27],
        ],
        [
            [17563, 5702],
            [-47, 36],
            [-53, 21],
            [-33, 20],
            [-34, 31],
        ],
        [
            [18014, 12626],
            [-66, -10],
            [-55, -40],
            [-77, -6],
            [-71, -46],
            [5, -76],
            [40, -30],
            [84, 7],
            [-16, -44],
            [-91, -20],
            [-112, -72],
            [-45, 25],
            [17, 58],
            [-90, 35],
            [15, 24],
            [79, 42],
            [-23, 28],
            [-129, 31],
            [-6, 45],
            [-76, -15],
            [-31, -68],
            [-63, -91],
        ],
        [
            [10453, 2685],
            [-36, -77],
            [-94, -69],
            [-60, 25],
            [-44, -13],
            [-77, 53],
            [-56, -5],
            [-50, 69],
        ],
        [
            [2019, 9182],
            [-12, -21],
            [-21, 18],
            [2, 34],
            [-13, 45],
            [4, 14],
            [15, 20],
            [-6, 24],
            [4, 11],
            [7, -2],
            [32, -21],
            [14, -10],
            [14, -17],
            [21, -43],
            [-2, -6],
            [-33, -27],
            [-26, -19],
        ],
        [
            [1975, 9374],
            [-28, -9],
            [-15, 25],
            [-9, 11],
            [0, 8],
            [7, 10],
            [30, -12],
            [21, -19],
            [-6, -14],
        ],
        [
            [1919, 9439],
            [-3, -14],
            [-44, 5],
            [6, 14],
            [41, -5],
        ],
        [
            [1845, 9457],
            [-5, -7],
            [-5, 1],
            [-29, 5],
            [-11, 28],
            [-3, 5],
            [22, 16],
            [7, -7],
            [24, -41],
        ],
        [
            [1705, 9538],
            [-10, -12],
            [-27, 23],
            [4, 8],
            [12, 13],
            [19, -4],
            [2, -28],
        ],
        [
            [9316, 12385],
            [15, -41],
            [-88, -59],
            [-86, -42],
            [-87, -37],
            [-43, -73],
            [-14, -28],
            [-1, -65],
            [27, -65],
            [34, -3],
            [-8, 45],
            [25, -27],
            [-7, -36],
            [-55, -19],
            [-41, 2],
            [-61, -22],
            [-35, -6],
            [-48, -6],
            [-69, -36],
            [121, 23],
            [25, -23],
            [-116, -37],
            [-52, 0],
            [2, 16],
            [-25, -35],
            [24, -6],
            [-18, -88],
            [-60, -94],
            [-6, 31],
            [-18, 7],
            [-27, 30],
            [17, -66],
            [21, -22],
            [1, -47],
            [-26, -47],
            [-47, -98],
            [-8, 4],
            [26, 84],
            [-43, 47],
            [-9, 103],
            [-16, -53],
            [18, -79],
            [-55, 20],
            [57, -40],
            [3, -117],
            [24, -8],
            [8, -43],
            [13, -123],
            [-53, -91],
            [-85, -36],
            [-55, -73],
            [-41, -7],
            [-42, -46],
            [-12, -41],
            [-90, -80],
            [-46, -58],
            [-40, -74],
            [-12, -87],
            [14, -85],
            [28, -105],
            [37, -88],
            [0, -52],
            [39, -142],
            [-2, -83],
            [-4, -48],
            [-21, -76],
            [-25, -15],
            [-40, 15],
            [-13, 54],
            [-31, 28],
            [-45, 106],
            [-38, 94],
            [-12, 48],
            [17, 82],
            [-24, 67],
            [-64, 103],
            [-32, 18],
            [-83, -55],
            [-15, 6],
            [-40, 58],
            [-52, 30],
            [-93, -16],
            [-73, 14],
            [-63, -9],
            [-34, -18],
            [14, -33],
            [-1, -50],
            [18, -24],
            [-16, -17],
            [-31, 18],
            [-31, -23],
            [-59, 4],
            [-62, 65],
            [-72, -15],
            [-60, 28],
            [-52, -8],
            [-69, -29],
            [-75, -91],
            [-82, -53],
            [-45, -59],
            [-19, -55],
            [-1, -85],
            [4, -59],
            [16, -42],
        ],
        [
            [5190, 10835],
            [-14, 63],
            [-53, 71],
            [-39, 14],
            [-9, 35],
            [-47, 7],
            [-29, 33],
            [-76, 12],
            [-21, 20],
            [-11, 67],
            [-79, 124],
            [-70, 171],
            [3, 29],
            [-36, 40],
            [-63, 103],
            [-12, 100],
            [-44, 68],
            [18, 101],
            [-3, 106],
            [-27, 94],
            [33, 115],
            [10, 112],
            [10, 112],
            [-15, 165],
            [-26, 105],
            [-24, 57],
            [10, 24],
            [119, -42],
            [44, -116],
            [21, 33],
            [-14, 101],
            [-28, 100],
        ],
        [
            [2228, 13857],
            [-82, -47],
            [-43, 32],
            [-12, 58],
            [75, 43],
            [44, 19],
            [54, -8],
            [35, -39],
            [-71, -58],
        ],
        [
            [1191, 14200],
            [-51, -18],
            [-54, 23],
            [-51, 33],
            [82, 20],
            [65, -11],
            [9, -47],
        ],
        [
            [682, 14676],
            [51, -23],
            [52, 13],
            [67, -32],
            [81, -17],
            [-6, -13],
            [-63, -26],
            [-62, 27],
            [-32, 21],
            [-73, -7],
            [-20, 12],
            [5, 45],
        ],
        [
            [4083, 13572],
            [-45, 47],
            [-73, 39],
            [-24, 107],
            [-105, 100],
            [-45, 116],
            [-80, 8],
            [-131, 3],
            [-96, 35],
            [-171, 128],
            [-79, 23],
            [-144, 44],
            [-115, -10],
            [-162, 56],
            [-99, 53],
            [-91, -27],
            [17, -85],
            [-46, -9],
            [-95, -24],
            [-73, -42],
            [-91, -26],
            [-12, 72],
            [37, 121],
            [88, 37],
            [-23, 31],
            [-105, -68],
            [-57, -82],
            [-118, -87],
            [60, -60],
            [-78, -88],
            [-89, -52],
            [-82, -37],
            [-21, -55],
            [-128, -64],
            [-27, -57],
            [-97, -52],
            [-56, 9],
            [-77, -34],
            [-83, -42],
            [-70, -41],
            [-141, -35],
            [-13, 20],
            [90, 57],
            [81, 39],
            [88, 67],
            [103, 14],
            [40, 50],
            [114, 74],
            [19, 25],
            [61, 42],
            [14, 94],
            [42, 72],
            [-95, -37],
            [-26, 22],
            [-45, -45],
            [-54, 62],
            [-23, -44],
            [-31, 62],
            [-82, -50],
            [-51, 0],
            [-7, 74],
            [16, 44],
            [-54, 44],
            [-107, -23],
            [-70, 57],
            [-57, 30],
            [0, 69],
            [-63, 53],
            [31, 71],
            [68, 68],
            [30, 63],
            [66, 9],
            [57, -19],
            [67, 59],
            [60, -10],
            [62, 37],
            [-16, 56],
            [-45, 23],
            [61, 47],
            [-51, -2],
            [-88, -27],
            [-25, -26],
            [-65, 26],
            [-116, -13],
            [-122, 30],
            [-34, 49],
            [-105, 71],
            [116, 52],
            [185, 60],
            [67, 0],
            [-11, -62],
            [174, 5],
            [-66, 77],
            [-102, 47],
            [-59, 61],
            [-79, 53],
            [-114, 38],
            [47, 64],
            [146, 4],
            [105, 56],
            [19, 60],
            [85, 58],
            [79, 15],
            [157, 54],
            [76, -8],
            [127, 66],
            [125, -26],
            [60, -56],
            [36, 24],
            [140, -7],
            [-5, -28],
            [127, -21],
            [84, 12],
            [174, -39],
            [159, -11],
            [63, -17],
            [110, 21],
            [125, -38],
            [90, -16],
        ],
        [
            [8970, 8283],
            [-2, -29],
            [-48, -14],
            [26, -55],
            [0, -64],
            [-37, -72],
            [32, -97],
            [35, 7],
            [19, 89],
            [-26, 44],
            [-4, 93],
            [102, 50],
            [-11, 58],
            [29, 38],
            [30, -86],
            [57, -2],
            [54, -69],
            [3, -41],
            [75, -1],
            [88, 13],
            [47, -56],
            [63, -14],
            [47, 38],
            [1, 31],
            [101, 8],
            [100, 1],
            [-71, -37],
            [28, -58],
            [67, -8],
            [62, -61],
            [13, -99],
            [43, 4],
            [33, -29],
        ],
        [
            [23293, 9598],
            [44, 30],
            [65, -1],
            [80, 14],
            [71, 65],
            [40, -45],
            [75, -23],
            [-12, -71],
            [39, -49],
            [83, -32],
            [-110, -106],
            [-69, -116],
            [-18, -85],
            [63, -130],
            [77, -160],
            [75, -76],
            [50, -100],
            [38, -227],
            [-12, -216],
            [-68, -81],
            [-94, -79],
            [-68, -102],
            [-103, -114],
            [-30, 78],
            [23, 83],
            [-61, 70],
        ],
        [
            [28714, 4812],
            [-28, -16],
            [-27, 54],
            [3, 33],
            [52, -71],
        ],
        [
            [28653, 5000],
            [13, -99],
            [-23, 16],
            [-16, -7],
            [-12, 34],
            [-2, 94],
            [40, -38],
        ],
        [
            [19242, 8882],
            [-59, -32],
            [-16, -55],
            [-3, -42],
            [-81, -52],
            [-132, -57],
            [-74, -87],
            [-36, -6],
            [-25, 7],
            [-49, -51],
            [-52, -24],
            [-69, -6],
            [-21, -7],
            [-19, -32],
            [-21, -10],
            [-13, -31],
            [-40, 2],
            [-27, -16],
            [-57, 6],
            [-22, 73],
            [3, 66],
            [-13, 36],
            [-17, 91],
            [-24, 51],
            [17, 6],
            [-9, 56],
            [11, 24],
            [-4, 53],
        ],
        [
            [17288, 4121],
            [33, -2],
            [41, -21],
            [28, 15],
            [43, -12],
        ],
        [
            [17568, 3549],
            [-21, -89],
            [-9, -103],
            [-22, -55],
            [-56, -62],
            [-15, -18],
            [-35, -63],
            [-23, -63],
            [-47, -88],
            [-93, -127],
            [-59, -73],
            [-63, -56],
            [-86, -48],
            [-41, -6],
            [-12, -34],
            [-49, 17],
            [-41, -23],
            [-90, 24],
            [-50, -15],
            [-34, 6],
            [-85, -49],
            [-71, -18],
            [-51, -47],
            [-37, -3],
            [-35, 43],
            [-28, 2],
            [-35, 55],
            [-5, -16],
            [-10, 33],
            [0, 72],
            [-27, 82],
            [27, 22],
            [-2, 96],
            [-54, 115],
            [-41, 103],
            [-1, 0],
            [-60, 160],
        ],
        [
            [17358, 4929],
            [-63, -16],
            [-47, -49],
            [-10, -42],
            [-30, -11],
            [-71, -100],
            [-46, -80],
            [-28, -3],
            [-27, 14],
            [-92, 14],
        ],
        [
            [23967, 9132],
            [-71, -59],
            [-68, 39],
            [-2, 106],
            [41, 54],
            [90, 35],
            [47, -3],
            [18, -47],
            [-36, -54],
            [-19, -71],
        ],
        [
            [21061, 11407],
            [-13, 29],
            [-12, 69],
            [3, 48],
            [-51, 28],
        ],
        [
            [20988, 11581],
            [-27, -12],
            [-21, 114],
            [24, 28],
        ],
        [
            [20964, 11711],
            [-11, 29],
            [79, 58],
            [57, 24],
            [87, -17],
            [31, 79],
            [106, 15],
            [29, 49],
            [130, 66],
            [12, 27],
            [-6, 71],
            [56, 32],
            [-74, 214],
            [163, 48],
            [42, 28],
            [61, 220],
            [162, -40],
            [47, 55],
        ],
        [
            [21935, 12669],
            [3, 124],
            [70, 11],
            [62, 82],
        ],
        [
            [22070, 12886],
            [32, 9],
            [22, -85],
            [69, -65],
            [119, -47],
            [57, -97],
            [-33, -145],
            [31, -53],
            [97, -21],
            [111, -17],
        ],
        [
            [22575, 12365],
            [100, -77],
            [52, -13],
            [37, -115],
            [49, -72],
            [90, 3],
        ],
        [
            [23074, 12063],
            [109, 17],
            [82, -18],
            [122, -75],
            [100, 1],
        ],
        [
            [23487, 11988],
            [36, -38],
            [97, 65],
            [132, 43],
            [124, 4],
        ],
        [
            [23876, 12062],
            [97, 43],
            [59, 67],
            [59, 41],
            [-14, 41],
        ],
        [
            [24077, 12254],
            [-27, 47],
            [44, 79],
            [46, -11],
            [85, -25],
            [82, 66],
            [126, 47],
            [61, 81],
            [58, 36],
            [119, 16],
            [66, -14],
            [9, 44],
            [-75, 84],
            [-66, 40],
            [-63, -45],
            [-82, 19],
            [-47, -16],
            [-21, 51],
            [59, 123],
            [40, 92],
        ],
        [
            [24491, 12968],
            [99, -46],
            [116, 78],
            [-1, 54],
            [73, 131],
            [47, 39],
            [-1, 68],
        ],
        [
            [24824, 13292],
            [-46, 29],
            [69, 60],
            [103, 23],
            [108, 3],
            [124, -36],
            [72, -45],
            [51, -124],
            [31, -53],
            [29, -76],
            [31, -121],
            [144, -39],
            [97, -87],
            [33, -115],
            [126, -1],
            [72, 48],
            [136, 37],
            [-43, -111],
            [-32, -44],
            [-28, -135],
            [-56, -119],
            [-100, 21],
            [-71, -43],
            [21, -106],
            [-11, -145],
            [-43, -3],
            [1, -63],
            [-53, 73],
            [-33, -70],
            [-127, -53],
            [12, -63],
            [-71, 4],
            [-39, 39],
            [-57, -88],
            [-91, -66],
        ],
        [
            [25183, 11823],
            [-68, -79],
            [-115, -35],
            [-61, -58],
            [-88, -33],
            [44, 57],
            [-18, 47],
            [65, 83],
            [-43, 65],
            [-72, -44],
            [-92, -85],
            [-51, -80],
            [-81, -6],
            [-43, -57],
            [44, -83],
            [69, -21],
            [2, -55],
            [65, -36],
            [91, 88],
            [74, -48],
            [53, -3],
            [13, -64],
            [-116, -36],
            [-38, -65],
            [-80, -61],
            [-43, -86],
            [89, -68],
            [32, -121],
            [50, -113],
            [57, -95],
            [-3, -91],
            [-51, -32],
            [19, -66],
            [49, -38],
            [-12, -101],
            [-21, -98],
            [-47, -10],
            [-60, -134],
            [-65, -162],
            [-77, -145],
            [-114, -114],
            [-114, -103],
            [-94, -15],
            [-51, -55],
            [-28, 41],
            [-46, -61],
            [-115, -62],
            [-89, -19],
            [-28, -130],
            [-45, -7],
            [-22, 89],
            [20, 48],
            [-111, 39],
            [-39, -20],
            [-83, 33],
            [-39, 49],
            [12, 71],
            [-74, 23],
            [-41, 46],
            [-70, -66],
            [-80, -14],
            [-66, 1],
            [-44, -30],
            [-43, -18],
            [13, -141],
            [-45, 3],
            [-7, 29],
        ],
        [
            [23211, 9471],
            [-2, 51],
            [-61, -36],
            [-36, 22],
            [-61, 47],
            [24, 102],
            [-53, 24],
            [-20, 114],
            [-87, -21],
            [11, 146],
            [77, 102],
            [5, 101],
            [-3, 94],
            [-36, 29],
            [-28, 73],
            [-49, -9],
        ],
        [
            [22892, 10310],
            [-42, 9],
            [-46, 9],
            [27, 52],
            [-38, 77],
            [-59, -52],
            [-69, 30],
            [-36, -29],
            [-59, -50],
            [-44, -52],
            [-32, -39],
            [-52, -11],
            [-14, -5],
        ],
        [
            [22428, 10249],
            [-37, 34],
            [-43, 3],
            [-58, 29],
            [-45, -31],
            [-55, -93],
        ],
        [
            [22190, 10191],
            [-2, 24],
            [-5, 74],
            [-31, -17],
            [-19, -9],
        ],
        [
            [22133, 10263],
            [-96, 11],
            [-94, 29],
            [-67, 54],
            [-64, 24],
            [-27, 59],
            [-47, 18],
        ],
        [
            [21655, 10538],
            [-66, 38],
            [-35, -30],
        ],
        [
            [21554, 10546],
            [-115, 85],
            [-28, 28],
            [-53, 50],
            [0, 1],
            [-22, 132],
            [4, 2],
            [12, -3],
            [3, -1],
            [6, -2],
            [1, -1],
            [3, -10],
            [6, -6],
            [8, -7],
            [4, 3],
            [5, 1],
            [3, 2],
            [1, 5],
            [0, 4],
            [3, 0],
            [0, 2],
            [8, 4],
            [4, 8],
            [3, 1],
            [3, -4],
            [3, -5],
            [2, 5],
            [7, 11],
            [7, 11],
            [-1, 2],
            [-5, 4],
            [-3, 5],
            [-3, 6],
            [-1, 2],
            [-2, 5],
            [-4, 1],
            [-2, 4],
            [1, 5],
            [0, 5],
            [0, 5],
            [3, 8],
            [2, 7],
            [-1, 3],
            [-4, 2],
            [-5, 1],
            [-10, 3],
            [-10, 0],
            [-7, 4],
            [-2, 2],
            [-3, 6],
            [-1, 4],
            [0, 7],
            [1, 5],
            [0, 1],
            [-3, 7],
            [2, 2],
            [4, -1],
            [9, 2],
            [-1, 8],
            [-4, 1],
            [-2, 3],
            [4, 8],
            [-4, 2],
            [-2, 6],
            [-1, 3],
            [-3, 9],
            [0, 8],
            [4, 2],
            [3, -2],
            [5, 2],
            [5, -1],
            [2, 3],
            [9, -1],
            [7, 0],
            [4, -3],
            [2, -1],
            [1, 1],
            [-1, 9],
            [4, 3],
            [1, 3],
            [0, 3],
            [1, 1],
            [1, 3],
            [5, 2],
            [2, 4],
            [0, 3],
            [0, 1],
            [-2, 15],
            [-2, 2],
            [0, 2],
            [-1, 2],
            [-1, 3],
            [2, 2],
            [6, 0],
            [13, -2],
            [2, 2],
            [1, 2],
            [-1, 3],
            [0, 2],
            [0, 5],
            [0, 6],
            [2, 2],
            [3, 2],
            [5, 2],
            [5, 0],
            [4, 1],
            [3, 3],
            [1, 13],
            [2, 4],
            [3, 4],
            [1, 7],
            [2, 7],
            [1, 5],
            [-1, 5],
            [2, 10],
            [3, 5],
            [1, 8],
            [1, 5],
            [2, 6],
            [1, 7],
            [2, 6],
            [0, 7],
            [0, 7],
            [-1, 3],
            [-3, 2],
            [-7, 5],
            [-4, 2],
            [-3, 3],
            [-1, 2],
            [-3, 7],
            [-3, 6],
            [-4, 3],
            [-8, -6],
            [-2, 0],
            [-3, 0],
            [-6, 3],
            [-7, 4],
            [-8, 4],
            [-6, 2],
            [-5, 4],
            [-1, 6],
            [-2, 1],
            [-3, 1],
            [-5, 0],
            [-4, -1],
            [-3, -7],
            [1, -4],
            [-1, -3],
            [-2, -3],
            [-5, -3],
            [-6, 3],
            [-5, 5],
            [-3, 0],
            [-3, -4],
            [-11, 0],
            [-7, 0],
            [-3, -1],
            [-3, -4],
            [-3, -3],
            [-6, -3],
            [-3, 2],
            [-2, 0],
            [-1, -2],
            [-5, -4],
            [-4, -1],
            [-4, -1],
            [-3, -7],
            [-2, 0],
            [-1, 0],
            [-3, -4],
            [0, -5],
            [-1, -4],
            [-4, -1],
            [-2, 2],
            [-3, -1],
            [-2, 5],
            [-3, 1],
            [-3, 0],
            [-1, -7],
            [0, -6],
            [-3, 0],
            [-3, 0],
            [-2, 2],
            [-2, 1],
            [-3, 1],
            [-4, 1],
            [-1, -3],
        ],
        [
            [21275, 11202],
            [-17, 7],
            [-4, 1],
        ],
        [
            [21254, 11210],
            [0, 3],
            [-2, 2],
            [-1, 4],
            [0, 3],
            [-2, 2],
            [-3, 3],
            [-5, 1],
            [-5, 1],
            [-5, 1],
            [-4, 5],
            [-4, 1],
            [-3, 0],
            [-4, -1],
            [-4, 0],
            [-5, 2],
            [-3, 4],
            [-4, 1],
            [-3, 7],
            [-1, 2],
            [-1, 4],
            [1, 2],
            [4, 7],
            [0, 2],
            [0, 3],
            [-3, 2],
            [-3, 3],
            [-2, 3],
            [-1, 5],
            [-2, 1],
            [-1, 0],
            [-1, 1],
            [-3, -2],
            [-4, 2],
            [-5, 1],
            [-1, 2],
            [-3, 6],
            [-1, 4],
            [-9, 8],
            [-5, 0],
            [-3, 0],
            [-2, 0],
            [-3, 3],
            [-3, 8],
            [-1, 2],
            [-2, 2],
            [-1, 1],
            [-4, 1],
            [-8, 2],
            [-1, 1],
            [-4, 17],
            [-46, 44],
            [-1, 6],
            [-2, 1],
            [-4, -1],
            [-8, 9],
            [-2, 6],
        ],
        [
            [22605, 7666],
            [-14, 32],
            [13, 28],
            [13, -30],
            [-12, -30],
        ],
        [
            [22590, 7729],
            [-2, 16],
            [10, -2],
            [-8, -14],
        ],
        [
            [22515, 8348],
            [-4, 10],
            [4, 54],
            [14, 8],
            [6, -49],
            [-9, -21],
            [-11, -2],
        ],
        [
            [22512, 8399],
            [-4, 30],
            [5, 2],
            [-1, -32],
        ],
        [
            [22522, 8420],
            [-1, 50],
            [8, 29],
            [11, 2],
            [-1, -60],
            [-6, -7],
            [4, -1],
            [-2, -7],
            [-2, 7],
            [-11, -13],
        ],
        [
            [22516, 8332],
            [-1, 12],
            [13, -5],
            [-11, -19],
            [2, -19],
            [-7, -56],
            [-15, 47],
            [8, 7],
            [3, 35],
            [8, -2],
        ],
        [
            [22540, 8295],
            [-8, 12],
            [7, 22],
            [1, -34],
        ],
        [
            [22543, 8318],
            [-3, 5],
            [3, 9],
            [0, -14],
        ],
        [
            [22573, 7803],
            [-12, 11],
            [7, 9],
            [5, -20],
        ],
        [
            [22582, 7809],
            [-6, 9],
            [2, 18],
            [4, -27],
        ],
        [
            [22550, 7841],
            [-6, 6],
            [3, 4],
            [3, -10],
        ],
        [
            [22588, 7871],
            [1, 15],
            [2, -9],
            [-3, -6],
        ],
        [
            [22518, 7955],
            [-4, 11],
            [6, 6],
            [-2, -17],
        ],
        [
            [22496, 8127],
            [-11, 6],
            [-1, 25],
            [11, 17],
            [9, -22],
            [-8, -26],
        ],
        [
            [22510, 8216],
            [2, 11],
            [1, -1],
            [-3, -10],
        ],
        [
            [22500, 8228],
            [2, 16],
            [8, -14],
            [-10, -2],
        ],
        [
            [21554, 10546],
            [-53, -55],
            [-32, -115],
            [81, -47],
            [76, -59],
        ],
        [
            [21628, 10269],
            [107, -69],
            [113, -16],
        ],
        [
            [21848, 10184],
            [48, -63],
            [16, -2],
            [48, -10],
            [100, -29],
            [68, 2],
            [9, 49],
            [-10, 79],
        ],
        [
            [22127, 10210],
            [5, 47],
            [1, 6],
        ],
        [
            [22190, 10191],
            [1, -9],
            [1, -15],
            [75, -46],
            [34, 12],
            [18, 6],
            [37, -4],
            [32, -4],
            [68, 4],
            [6, 76],
        ],
        [
            [22462, 10211],
            [-18, 19],
            [-16, 19],
        ],
        [
            [22892, 10310],
            [7, -46],
            [-15, -11],
            [-14, -12],
            [7, -76],
            [-59, 22],
            [-63, -50],
            [-44, -35],
        ],
        [
            [22712, 10074],
            [2, -43],
            [-34, -76],
            [-12, -26],
            [-4, -60],
            [-21, -58],
            [-16, -43],
            [-47, 21],
            [-17, 7],
            [-1, -28],
            [-2, -99],
            [-18, -40],
            [-1, -2],
            [8, -52],
            [-27, -20],
            [-13, -9],
            [-44, 195],
            [-7, 0],
            [-16, -1],
            [-9, -52],
        ],
        [
            [22433, 9688],
            [-4, -26],
            [-12, 15],
            [-34, 48],
            [22, 59],
            [4, 12],
            [23, 4],
        ],
        [
            [22432, 9800],
            [14, 3],
            [16, 43],
            [22, 61],
            [-4, 2],
            [-44, 19],
            [-76, -2],
            [-78, 16],
            [-8, 85],
            [-39, 7],
            [-30, 23],
            [-36, 29],
            [-6, -16],
            [-23, -67],
            [27, -30],
            [33, -35],
            [-33, -28],
            [-19, -17],
            [-18, -44],
            [51, -33],
            [-15, -75],
            [29, -93],
            [13, -101],
            [-12, -45],
            [-56, 2],
            [-101, -26],
            [4, -92],
            [-44, -73],
            [-119, -82],
            [-92, -145],
            [-62, -78],
            [-82, -80],
            [0, -57],
            [-42, -30],
            [-75, -44],
            [-38, -7],
            [-25, -93],
            [18, -159],
            [4, -103],
            [-35, -117],
            [0, -209],
            [-43, -6],
            [-37, -94],
            [25, -40],
            [-76, -35],
            [-27, -83],
            [-33, -36],
            [-79, 114],
            [-38, 173],
            [-32, 124],
            [-29, 58],
            [-43, 119],
            [-21, 153],
            [-14, 76],
            [-75, 169],
            [-34, 239],
            [-25, 158],
            [0, 147],
            [-16, 116],
            [-120, -74],
            [-58, 15],
            [-108, 149],
            [39, 44],
            [-24, 49],
            [-97, 105],
        ],
        [
            [20541, 9831],
            [182, -1],
            [-16, 105],
        ],
        [
            [20707, 9935],
            [-31, 42],
            [-16, 20],
            [-9, 96],
            [-54, 55],
            [46, 64],
            [45, 64],
            [79, -7],
        ],
        [
            [20767, 10269],
            [17, -2],
            [86, 129],
            [31, 75],
            [21, 50],
            [79, 123],
            [-1, 87],
            [51, 52],
            [20, 20],
            [-28, 25],
            [-2, 0],
            [-2, 3],
            [-3, 1],
            [-2, -1],
            [-1, -1],
            [-5, 0],
            [-1, 0],
            [-2, 3],
            [-1, 3],
            [-2, 5],
            [0, 3],
            [0, 3],
            [0, 1],
            [-17, 16],
            [-4, 2],
            [-6, 2],
            [-1, 1],
            [-41, 22],
            [0, -1],
            [-1, 1],
            [-1, 1],
            [-1, 2],
            [0, 1],
            [-4, 2],
            [0, 2],
            [-2, 3],
            [-5, 2],
            [-3, 2],
            [-1, 1],
            [-1, 3],
            [-1, 6],
            [1, 7],
            [-1, 8],
            [-1, 7],
            [0, 5],
            [2, 8],
            [0, 5],
            [0, 9],
            [-3, 8],
            [-1, 4],
            [1, 5],
            [1, 4],
            [-2, 4],
            [0, 5],
            [0, 5],
            [1, 4],
            [-1, 4],
            [-2, 6],
            [-1, 6],
            [-1, 2],
            [0, 8],
            [-1, 10],
            [0, 8],
            [0, 5],
            [0, 2],
            [-6, 9],
            [1, 4],
            [1, 12],
            [1, 4],
            [2, 2],
            [5, 1],
            [4, 1],
            [8, 12],
            [4, 7],
            [2, 3],
            [1, 2],
            [2, 0],
            [17, 27],
            [-11, 28],
            [-2, 1],
            [-2, 2],
            [-2, -1],
            [-2, -2],
            [-2, -1],
            [-1, 2],
            [0, 2],
            [0, 3],
            [-1, 5],
            [-1, 4],
            [1, 7],
            [1, 1],
            [1, 1],
            [-4, 9],
            [0, 1],
            [-2, -2],
            [-12, 0],
            [-3, 1],
            [-5, 8],
            [-2, 3],
            [-3, 2],
            [-5, 2],
            [-1, 2],
            [-51, 25],
            [-1, 0],
            [-3, 1],
            [-1, 1],
            [-10, 5],
            [7, 17],
            [-3, 1],
            [0, 5],
            [1, 3],
            [1, 3],
            [-1, 2],
            [-1, 2],
            [1, 5],
            [5, 6],
            [2, 0],
            [2, -1],
            [2, 1],
            [3, 3],
            [1, 0],
            [21, 51],
            [19, 6],
        ],
        [
            [20903, 11353],
            [41, 14],
            [-2, 1],
            [0, 4],
            [4, -1],
            [6, -1],
            [109, 37],
        ],
        [
            [21254, 11210],
            [21, -8],
        ],
    ],
    transform: {
        scale: [0.01211427802268062, 0.008135118588620165],
        translate: [-180, -55.611830000000005],
    },
    objects: {
        countries: {
            type: 'GeometryCollection',
            geometries: [
                {
                    arcs: [[0, 1, 2, 3, 4, 5]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Afghanistan', country: 'AF' },
                },
                {
                    arcs: [[[6, 7, 8, 9]], [[10, 11, 12]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Angola', country: 'AO' },
                },
                {
                    arcs: [[13, 14, 15, 16, 17]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Albania', country: 'AL' },
                },
                {
                    arcs: [[18, 19, 20, 21, 22]],
                    type: 'Polygon',
                    properties: { id: null, name: 'United Arab Emirates', country: 'AE' },
                },
                {
                    arcs: [[[23, 24]], [[25, 26, 27, 28, 29, 30]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Argentina', country: 'AR' },
                },
                {
                    arcs: [[31, 32, 33, 34, 35]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Armenia', country: 'AM' },
                },
                {
                    arcs: [[36]],
                    type: 'Polygon',
                    properties: {
                        id: null,
                        name: 'French Southern and Antarctic Lands',
                        country: 'TF',
                    },
                },
                {
                    arcs: [[[37]], [[38]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Australia', country: 'AU' },
                },
                {
                    arcs: [[39, 40, 41, 42, 43, 44, 45]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Austria', country: 'AT' },
                },
                {
                    arcs: [[[-35, 46]], [[-33, 47, 48, 49, 50]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Azerbaijan', country: 'AZ' },
                },
                {
                    arcs: [[51, 52, 53]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Burundi', country: 'BI' },
                },
                {
                    arcs: [[54, 55, 56, 57, 58]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Belgium', country: 'BE' },
                },
                {
                    arcs: [[59, 60, 61, 62, 63]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Benin', country: 'BJ' },
                },
                {
                    arcs: [[-62, 64, 65, 66, 67, 68]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Burkina Faso', country: 'BF' },
                },
                {
                    arcs: [[69, 70, 71, 72]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Bangladesh', country: 'BD' },
                },
                {
                    arcs: [[73, 74, 75, 76, 77, 78]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Bulgaria', country: 'BG' },
                },
                {
                    arcs: [[[79]], [[80]], [[81]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'The Bahamas', country: 'BS' },
                },
                {
                    arcs: [[82, 83, 84]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Bosnia and Herzegovina', country: 'BA' },
                },
                {
                    arcs: [[85, 86, 87, 88, 89]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Belarus', country: 'BY' },
                },
                {
                    arcs: [[90, 91, 92]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Belize', country: 'BZ' },
                },
                {
                    arcs: [[-31, 93, 94, 95, 96]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Bolivia', country: 'BO' },
                },
                {
                    arcs: [[-27, 97, -96, 98, 99, 100, 101, 102, 103, 104, 105]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Brazil', country: 'BR' },
                },
                {
                    arcs: [[106, 107]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Brunei', country: 'BN' },
                },
                {
                    arcs: [[108]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Bhutan', country: 'BT' },
                },
                {
                    arcs: [[109, 110, 111, 112]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Botswana', country: 'BW' },
                },
                {
                    arcs: [[113, 114, 115, 116, 117, 118]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Central African Republic', country: 'CF' },
                },
                {
                    arcs: [
                        [[119]],
                        [[120]],
                        [[121]],
                        [[122]],
                        [[123]],
                        [[124]],
                        [[125]],
                        [[126]],
                        [[127]],
                        [[128]],
                        [[129, 130, 131, 132]],
                        [[133]],
                        [[134]],
                        [[135]],
                        [[136]],
                        [[137]],
                        [[138]],
                        [[139]],
                        [[140]],
                        [[141]],
                        [[142]],
                        [[143]],
                        [[144]],
                        [[145]],
                        [[146]],
                        [[147]],
                        [[148]],
                        [[149]],
                        [[150]],
                        [[151]],
                    ],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Canada', country: 'CA' },
                },
                {
                    arcs: [[-43, 152, 153, 154]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Switzerland', country: 'CH' },
                },
                {
                    arcs: [[[-24, 155]], [[-30, 156, 157, -94]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Chile', country: 'CL' },
                },
                // CX is for Geostream widget to adjust line drawn to Chile
                {
                    arcs: [[157, -94, 158]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Chile', country: 'CX' },
                },
                {
                    arcs: [[-67, 159, 160, 161, 162, 163]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Ivory Coast', country: 'CI' },
                },
                {
                    arcs: [[-119, 164, 165, 166, 167, 168, 169, 170]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Cameroon', country: 'CM' },
                },
                {
                    arcs: [[-10, 171, -13, 172, -117, 173, 174, 175, -52, 176, 177]],
                    type: 'Polygon',
                    properties: {
                        id: null,
                        name: 'Democratic Republic of the Congo',
                        country: 'CD',
                    },
                },
                {
                    arcs: [[-12, 178, 179, -165, -118, -173]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Republic of the Congo', country: 'CG' },
                },
                {
                    arcs: [[-100, 180, 181, 182, 183, 184, 185]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Colombia', country: 'CO' },
                },
                {
                    arcs: [[186, 187, 188, 189]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Costa Rica', country: 'CR' },
                },
                {
                    arcs: [[190]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Cuba', country: 'CU' },
                },
                {
                    arcs: [[191, 192]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Northern Cyprus', country: '-99' },
                },
                {
                    arcs: [[-193, 193]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Cyprus', country: 'CY' },
                },
                {
                    arcs: [[-45, 194, 195, 196]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Czech Republic', country: 'CZ' },
                },
                {
                    arcs: [[-44, -155, 197, 198, -55, 199, 200, 201, 202, 203, -195]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Germany', country: 'DE' },
                },
                {
                    arcs: [[204, 205, 206, 207]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Djibouti', country: 'DJ' },
                },
                {
                    arcs: [[[208]], [[-202, 209]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Denmark', country: 'DK' },
                },
                {
                    arcs: [[210, 211]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Dominican Republic', country: 'DO' },
                },
                {
                    arcs: [[212, 213, 214, 215, 216, 217, 218, 219]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Algeria', country: 'DZ' },
                },
                {
                    arcs: [[-182, 220, 221]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Ecuador', country: 'EC' },
                },
                {
                    arcs: [[222, 223, 224, 225, 226]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Egypt', country: 'EG' },
                },
                {
                    arcs: [[-208, 227, 228, 229]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Eritrea', country: 'ER' },
                },
                {
                    arcs: [[230, 231, 232, 233]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Spain', country: 'ES' },
                },
                {
                    arcs: [[234, 235, 236]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Estonia', country: 'EE' },
                },
                {
                    arcs: [[-228, -207, 237, 238, 239, 240, 241]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Ethiopia', country: 'ET' },
                },
                {
                    arcs: [[242, 243, 244, 245]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Finland', country: 'FI' },
                },
                {
                    arcs: [[[246]], [[247]], [[248]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Fiji', country: 'FJ' },
                },
                {
                    arcs: [[249]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Falkland Islands', country: 'FK' },
                },
                {
                    arcs: [
                        [[250, 251, -104]],
                        [[252]],
                        [[-57, 253, -198, -154, 254, 255, -232, 256]],
                    ],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'France', country: 'FR' },
                },
                // FX is for Geostream widget to adjust line drawn to France
                {
                    arcs: [[[252]], [[-57, 253, -198, -154, 254, 255, -232, 256]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'FranceLine', country: 'FX' },
                },
                {
                    arcs: [[-166, -180, 257, 258]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Gabon', country: 'GA' },
                },
                {
                    arcs: [[[259, 260]], [[261]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'United Kingdom', country: 'GB' },
                },
                {
                    arcs: [[-32, 262, 263, 264, -48]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Georgia', country: 'GE' },
                },
                {
                    arcs: [[-66, 265, 266, -160]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Ghana', country: 'GH' },
                },
                {
                    arcs: [[-163, 267, 268, 269, 270, 271, 272]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Guinea', country: 'GN' },
                },
                {
                    arcs: [[273, 274]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Gambia', country: 'GM' },
                },
                {
                    arcs: [[-271, 275, 276]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Guinea Bissau', country: 'GW' },
                },
                {
                    arcs: [[-167, -259, 277]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Equatorial Guinea', country: 'GQ' },
                },
                {
                    arcs: [[[278]], [[-14, 279, -77, 280, 281]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Greece', country: 'GR' },
                },
                {
                    arcs: [[282]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Greenland', country: 'GL' },
                },
                {
                    arcs: [[-93, 283, 284, 285, 286, 287]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Guatemala', country: 'GT' },
                },
                {
                    arcs: [[-102, 288, 289, 290]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Guyana', country: 'GY' },
                },
                {
                    arcs: [[-285, 291, 292, 293, 294]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Honduras', country: 'HN' },
                },
                {
                    arcs: [[-84, 295, 296, 297, 298, 299]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Croatia', country: 'HR' },
                },
                {
                    arcs: [[-212, 300]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Haiti', country: 'HT' },
                },
                {
                    arcs: [[-40, 301, 302, 303, 304, -299, 305]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Hungary', country: 'HU' },
                },
                {
                    arcs: [
                        [[306]],
                        [[307, 308]],
                        [[309]],
                        [[310]],
                        [[311]],
                        [[312]],
                        [[313]],
                        [[314]],
                        [[315, 316]],
                        [[317]],
                        [[318]],
                        [[319, 320]],
                        [[321]],
                    ],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Indonesia', country: 'ID' },
                },
                {
                    arcs: [[-260, 322]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Ireland', country: 'IE' },
                },
                {
                    arcs: [[-6, 323, 324, 325, 326, -47, -34, -51, 327, 328]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Iran', country: 'IR' },
                },
                {
                    arcs: [[-326, 329, 330, 331, 332, 333, 334]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Iraq', country: 'IQ' },
                },
                {
                    arcs: [[335]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Iceland', country: 'IS' },
                },
                {
                    arcs: [[336, 337, 338, 339, 340, 341, -226]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Israel', country: 'IL' },
                },
                {
                    arcs: [[[342]], [[343]], [[-42, 344, 345, -255, -153]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Italy', country: 'IT' },
                },
                {
                    arcs: [[346]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Jamaica', country: 'JM' },
                },
                {
                    arcs: [[-333, 347, 348, -342, 349, -340, 350]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Jordan', country: 'JO' },
                },
                {
                    arcs: [[[351]], [[352]], [[353]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Japan', country: 'JP' },
                },
                {
                    arcs: [[354, 355, 356, 357, 358, 359, 360]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Kazakhstan', country: 'KZ' },
                },
                {
                    arcs: [[-240, 361, 362, 363, 364, 365]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Kenya', country: 'KE' },
                },
                {
                    arcs: [[366, 367, 368, 369, -357]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Kyrgyzstan', country: 'KG' },
                },
                {
                    arcs: [[370, 371, 372, 373]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Cambodia', country: 'KH' },
                },
                {
                    arcs: [[374, 375]],
                    type: 'Polygon',
                    properties: { id: null, name: 'South Korea', country: 'KR' },
                },
                {
                    arcs: [[-17, 376, 377, 378]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Kosovo', country: '-99' },
                },
                {
                    arcs: [[-331, 379, 380]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Kuwait', country: 'KW' },
                },
                {
                    arcs: [[381, 382, -372, 383, 384]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Laos', country: 'LA' },
                },
                {
                    arcs: [[-338, 385, 386]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Lebanon', country: 'LB' },
                },
                {
                    arcs: [[-162, 387, 388, -268]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Liberia', country: 'LR' },
                },
                {
                    arcs: [[-220, 389, 390, -224, 391, 392, 393]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Libya', country: 'LY' },
                },
                {
                    arcs: [[394]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Sri Lanka', country: 'LK' },
                },
                {
                    arcs: [[395]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Lesotho', country: 'LS' },
                },
                {
                    arcs: [[-86, 396, 397, 398, 399]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Lithuania', country: 'LT' },
                },
                {
                    arcs: [[-56, -199, -254]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Luxembourg', country: 'LU' },
                },
                {
                    arcs: [[-87, -400, 400, -237, 401]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Latvia', country: 'LV' },
                },
                {
                    arcs: [[-217, 402, 403]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Morocco', country: 'MA' },
                },
                {
                    arcs: [[404, 405]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Moldova', country: 'MD' },
                },
                {
                    arcs: [[406]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Madagascar', country: 'MG' },
                },
                {
                    arcs: [[-91, -288, 407, 408, 409]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Mexico', country: 'MX' },
                },
                {
                    arcs: [[-18, -379, 410, -78, -280]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Macedonia', country: 'MK' },
                },
                {
                    arcs: [[-68, -164, -273, 411, 412, -214, 413]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Mali', country: 'ML' },
                },
                {
                    arcs: [[-70, 414, 415, 416, -385, 417, 418]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Myanmar', country: 'MM' },
                },
                {
                    arcs: [[-16, 419, -296, -83, 420, -377]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Montenegro', country: 'ME' },
                },
                {
                    arcs: [[421, 422, 423, 424, 425, 426, 427, 428]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Mongolia', country: 'MN' },
                },
                {
                    arcs: [[429, 430, 431, 432, 433, 434, 435, 436]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Mozambique', country: 'MZ' },
                },
                {
                    arcs: [[-215, -413, 437, 438, 439]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Mauritania', country: 'MR' },
                },
                {
                    arcs: [[-437, 440, 441]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Malawi', country: 'MW' },
                },
                {
                    arcs: [[[442, 443]], [[-108, 444, -320, 445]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Malaysia', country: 'MY' },
                },
                {
                    arcs: [[-8, 446, -111, 447, 448]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Namibia', country: 'NA' },
                },
                {
                    arcs: [[449]],
                    type: 'Polygon',
                    properties: { id: null, name: 'New Caledonia', country: 'NC' },
                },
                {
                    arcs: [[-63, -69, -414, -213, -394, 450, -170, 451]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Niger', country: 'NE' },
                },
                {
                    arcs: [[-64, -452, -169, 452]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Nigeria', country: 'NG' },
                },
                {
                    arcs: [[-188, 453, -293, 454]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Nicaragua', country: 'NI' },
                },
                {
                    arcs: [[-59, 455, -200]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Netherlands', country: 'NL' },
                },
                {
                    arcs: [[[-246, 456, 457, 458]], [[459]], [[460]], [[461]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Norway', country: 'NO' },
                },
                {
                    arcs: [[462, 463, 464, 465, 466, 467]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Nepal', country: 'NP' },
                },
                {
                    arcs: [[[468]], [[469]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'New Zealand', country: 'NZ' },
                },
                {
                    arcs: [[[-22, 470, 471, 472]], [[-20, 473]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Oman', country: 'OM' },
                },
                {
                    arcs: [[-5, 474, 475, 476, 477, 478, -324]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Pakistan', country: 'PK' },
                },
                {
                    arcs: [[-184, 479, -190, 480]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Panama', country: 'PA' },
                },
                {
                    arcs: [[-95, -158, 481, -221, -181, -99]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Peru', country: 'PE' },
                },
                {
                    arcs: [[[482]], [[483]], [[484]], [[485]], [[486]], [[487]], [[488]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Philippines', country: 'PH' },
                },
                {
                    arcs: [[[489]], [[490]], [[-316, 491]], [[492]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Papua New Guinea', country: 'PG' },
                },
                {
                    arcs: [[-90, 493, 494, -196, -204, 495, 496, -397]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Poland', country: 'PL' },
                },
                {
                    arcs: [[497]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Puerto Rico', country: 'PR' },
                },
                {
                    arcs: [[498, 499, 500, -376, 501]],
                    type: 'Polygon',
                    properties: { id: null, name: 'North Korea', country: 'KP' },
                },
                {
                    arcs: [[-234, 502]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Portugal', country: 'PT' },
                },
                {
                    arcs: [[-26, -97, -98]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Paraguay', country: 'PY' },
                },
                {
                    arcs: [[503, 504]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Qatar', country: 'QA' },
                },
                {
                    arcs: [[-74, 505, -304, 506, -406, 507, 508]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Romania', country: 'RO' },
                },
                // RX is for Geostream widget to adjust line drawn to Russia
                {
                    arcs: [
                        [[509]],
                        [[-398, -497, 510]],
                        [
                            [
                                -49,
                                -265,
                                511,
                                512,
                                -88,
                                -402,
                                -236,
                                513,
                                -243,
                                -459,
                                514,
                                -500,
                                515,
                                516,
                                -429,
                                517,
                                -361,
                                518,
                            ],
                        ],
                        [[519]],
                        [[520]],
                        [[521]],
                    ],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Russia', country: 'RX' },
                },
                {
                    arcs: [
                        [[509]],
                        [[-398, -497, 510]],
                        [[522]],
                        [[523]],
                        [[524]],
                        [[525]],
                        [[526]],
                        [[527]],
                        [[528]],
                        [
                            [
                                -49,
                                -265,
                                511,
                                512,
                                -88,
                                -402,
                                -236,
                                513,
                                -243,
                                -459,
                                514,
                                -500,
                                515,
                                516,
                                -429,
                                517,
                                -361,
                                518,
                            ],
                        ],
                        [[519]],
                        [[520]],
                        [[521]],
                    ],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Russia', country: 'RU' },
                },
                {
                    arcs: [[-53, -176, 529, 530]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Rwanda', country: 'RW' },
                },
                {
                    arcs: [[-216, -440, 531, -403]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Western Sahara', country: '-99' },
                },
                {
                    arcs: [[-23, -473, 532, 533, -348, -332, -381, 534, -505, 535]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Saudi Arabia', country: 'SA' },
                },
                {
                    arcs: [[-242, 536, -115, 537, -392, -223, 538, -229]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Sudan', country: 'SD' },
                },
                {
                    arcs: [[-116, -537, -241, -366, 539, -174]],
                    type: 'Polygon',
                    properties: { id: null, name: 'South Sudan', country: 'SDS' },
                },
                {
                    arcs: [[-272, -277, 540, -275, 541, -438, -412]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Senegal', country: 'SN' },
                },
                {
                    arcs: [[[542]], [[543]], [[544]], [[545]], [[546]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Solomon Islands', country: 'SB' },
                },
                {
                    arcs: [[-269, -389, 547]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Sierra Leone', country: 'SL' },
                },
                {
                    arcs: [[-286, -295, 548]],
                    type: 'Polygon',
                    properties: { id: null, name: 'El Salvador', country: 'SV' },
                },
                {
                    arcs: [[-206, 549, 550, -238]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Somaliland', country: '-99' },
                },
                {
                    arcs: [[-239, -551, 551, -362]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Somalia', country: 'SO' },
                },
                {
                    arcs: [[-79, -411, -378, -421, -85, -300, -305, -506]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Republic of Serbia', country: 'RS' },
                },
                {
                    arcs: [[-103, -291, 552, -251]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Suriname', country: 'SR' },
                },
                {
                    arcs: [[-46, -197, -495, 553, -302]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Slovakia', country: 'SK' },
                },
                {
                    arcs: [[-41, -306, -298, 554, -345]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Slovenia', country: 'SI' },
                },
                {
                    arcs: [[-245, 555, -457]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Sweden', country: 'SE' },
                },
                {
                    arcs: [[-433, 556]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Swaziland', country: 'SZ' },
                },
                {
                    arcs: [[-334, -351, -339, -387, 557, 558]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Syria', country: 'SY' },
                },
                {
                    arcs: [[-114, -171, -451, -393, -538]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Chad', country: 'TD' },
                },
                {
                    arcs: [[-61, 559, -266, -65]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Togo', country: 'TG' },
                },
                {
                    arcs: [[-371, 560, -444, 561, -418, -384]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Thailand', country: 'TH' },
                },
                {
                    arcs: [[-3, 562, -369, 563, 564]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Tajikistan', country: 'TJ' },
                },
                {
                    arcs: [[-1, -329, 565, -359, 566]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Turkmenistan', country: 'TM' },
                },
                {
                    arcs: [[-308, 567]],
                    type: 'Polygon',
                    properties: { id: null, name: 'East Timor', country: 'TL' },
                },
                {
                    arcs: [[568]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Trinidad and Tobago', country: 'TT' },
                },
                {
                    arcs: [[-219, 569, -390]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Tunisia', country: 'TN' },
                },
                {
                    arcs: [[[-36, -327, -335, -559, 570, -263]], [[-76, 571, -281]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Turkey', country: 'TR' },
                },
                {
                    arcs: [[572]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Taiwan', country: 'TW' },
                },
                {
                    arcs: [[-531, 573, -364, 574, -430, -442, 575, -177, -54]],
                    type: 'Polygon',
                    properties: { id: null, name: 'United Republic of Tanzania', country: 'TZ' },
                },
                {
                    arcs: [[-175, -540, -365, -574, -530]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Uganda', country: 'UG' },
                },
                {
                    arcs: [[-89, -513, 576, -508, -405, -507, -303, -554, -494]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Ukraine', country: 'UA' },
                },
                {
                    arcs: [[-28, -106, 577]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Uruguay', country: 'UY' },
                },
                {
                    arcs: [
                        [[578]],
                        [[579]],
                        [[580]],
                        [[581]],
                        [[582]],
                        [[-130, 583, -409, 584]],
                        [[585]],
                        [[586]],
                        [[587]],
                        [[-132, 588]],
                    ],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'United States of America', country: 'US' },
                },
                {
                    arcs: [[-2, -567, -358, -370, -563]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Uzbekistan', country: 'UZ' },
                },
                {
                    arcs: [[-101, -186, 589, -289]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Venezuela', country: 'VE' },
                },
                {
                    arcs: [[590, -373, -383]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Vietnam', country: 'VN' },
                },
                {
                    arcs: [[[591]], [[592]]],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'Vanuatu', country: 'VU' },
                },
                {
                    arcs: [[-341, -350]],
                    type: 'Polygon',
                    properties: { id: null, name: 'West Bank', country: 'PS' },
                },
                {
                    arcs: [[-472, 593, -533]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Yemen', country: 'YE' },
                },
                {
                    arcs: [[-110, 594, -434, -557, -432, 595, -448], [-396]],
                    type: 'Polygon',
                    properties: { id: null, name: 'South Africa', country: 'ZA' },
                },
                {
                    arcs: [[-7, -178, -576, -441, -436, 596, -112, -447]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Zambia', country: 'ZM' },
                },
                {
                    arcs: [[-113, -597, -435, -595]],
                    type: 'Polygon',
                    properties: { id: null, name: 'Zimbabwe', country: 'ZW' },
                },
                {
                    arcs: [
                        [[597]],
                        [
                            [
                                598,
                                599,
                                600,
                                601,
                                602,
                                603,
                                -426,
                                604,
                                605,
                                606,
                                607,
                                608,
                                609,
                                610,
                                611,
                                612,
                                613,
                                614,
                                615,
                                -463,
                                616,
                                617,
                                618,
                                619,
                            ],
                        ],
                    ],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'China', country: 'CN' },
                },
                {
                    arcs: [
                        [[620]],
                        [[621]],
                        [[622]],
                        [[623]],
                        [[624]],
                        [[625]],
                        [[626]],
                        [[627]],
                        [[628]],
                        [[629]],
                        [[630]],
                        [[631]],
                        [[632]],
                        [[633]],
                        [[634]],
                        [[635]],
                        [
                            [
                                636,
                                -467,
                                637,
                                638,
                                639,
                                -615,
                                640,
                                641,
                                -613,
                                642,
                                -416,
                                643,
                                644,
                                645,
                                -478,
                                646,
                                647,
                                648,
                                649,
                                -620,
                                650,
                                -618,
                            ],
                        ],
                    ],
                    type: 'MultiPolygon',
                    properties: { id: null, name: 'India', country: 'IN' },
                },
            ],
        },
    },
};

export default data;
