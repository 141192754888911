const data = {
    type: 'Topology',
    arcs: [
        [
            [1158, 3610],
            [62, 102],
            [-42, 80],
            [22, 32],
            [-17, 35],
            [38, 48],
            [2, 80],
            [5, 67],
            [21, 32],
            [-85, 153],
        ],
        [
            [1164, 4239],
            [73, -8],
            [50, 2],
            [22, 29],
            [86, 38],
            [51, 36],
            [129, 16],
            [-11, -71],
            [12, -37],
            [-8, -63],
            [107, -85],
            [109, -16],
            [39, -35],
            [66, -19],
            [41, -28],
            [61, 1],
            [57, -28],
            [4, -55],
            [20, -28],
            [1, -41],
            [-29, -1],
            [38, -111],
            [188, -4],
            [-15, -54],
            [11, -38],
            [53, -27],
            [24, -59],
            [-18, -74],
            [-27, -42],
            [10, -54],
            [-31, -20],
        ],
        [
            [2277, 3363],
            [-2, 30],
            [-91, 48],
            [-91, 2],
            [-171, -28],
            [-47, -84],
            [-2, -51],
            [-39, -113],
        ],
        [
            [1834, 3167],
            [-15, 20],
            [-112, 4],
            [-38, -76],
            [-58, 68],
            [-128, 23],
            [-82, -86],
        ],
        [
            [1401, 3120],
            [-70, -13],
            [-39, 131],
            [-52, 107],
            [30, 92],
            [-51, 40],
            [-13, 68],
            [-48, 65],
        ],
        [
            [1158, 3610],
            [-26, -49],
            [-51, -24],
        ],
        [
            [1081, 3537],
            [-98, 54],
            [-8, 39],
            [-195, 96],
            [-175, 104],
            [-76, 58],
            [-40, 78],
            [16, 28],
            [-83, 124],
            [-97, 175],
            [-92, 189],
            [-41, 44],
            [-30, 70],
            [-76, 62],
            [-70, 38],
            [31, 42],
            [-47, 91],
            [30, 66],
            [79, 60],
        ],
        [
            [109, 4955],
            [11, -39],
            [-28, -23],
            [3, -35],
            [40, 8],
            [40, -10],
            [41, -48],
            [55, 39],
            [19, 64],
            [60, 82],
            [118, 38],
            [107, 99],
            [30, 62],
            [-14, 72],
        ],
        [
            [591, 5264],
            [27, 9],
            [65, -45],
            [31, -45],
            [45, -24],
            [58, -100],
            [73, -12],
            [54, 26],
            [35, -17],
            [59, 8],
            [75, -44],
            [-63, -97],
            [29, -2],
            [49, -50],
        ],
        [
            [1128, 4871],
            [-88, 4],
            [-13, -14],
            [-80, -19],
            [-112, -64],
            [-8, -44],
            [-25, -33],
            [10, -52],
            [-59, -27],
            [0, -40],
            [-26, -18],
            [41, -85],
            [54, -58],
            [-20, -41],
            [65, -5],
            [37, -51],
            [86, -2],
            [81, 56],
            [-7, -144],
            [45, -11],
            [55, 16],
        ],
        [
            [109, 4955],
            [52, 71],
            [-21, 42],
            [-38, -44],
            [-59, 41],
            [20, 27],
            [-16, 86],
            [34, 14],
            [18, 60],
            [37, 61],
            [-7, 38],
            [54, 21],
            [67, 38],
        ],
        [
            [250, 5410],
            [98, -55],
            [19, 2],
            [23, -41],
            [84, -13],
            [27, 15],
            [49, -31],
            [41, -23],
        ],
        [
            [1980, 357],
            [117, 57],
            [84, -24],
            [59, 38],
            [78, -42],
            [-29, -34],
            [-133, -28],
            [-44, 33],
            [-83, -43],
            [-49, 43],
        ],
        [
            [1128, 4871],
            [44, 260],
            [3, 41],
            [-16, 54],
            [-43, 35],
            [0, 69],
            [55, 16],
            [20, -10],
            [3, 36],
            [-57, 10],
            [-1, 59],
            [191, -2],
            [32, 33],
            [27, -30],
            [19, -56],
            [19, 11],
        ],
        [
            [1424, 5397],
            [54, -50],
            [76, 6],
            [19, 29],
            [73, 23],
            [40, 15],
            [11, 40],
            [70, 27],
            [-5, 20],
            [-83, 8],
            [-13, 60],
            [4, 64],
            [-44, 25],
            [18, 8],
            [73, -12],
            [77, -24],
            [29, 23],
            [70, 15],
            [110, 35],
            [36, 36],
            [-13, 27],
        ],
        [
            [2026, 5772],
            [51, 4],
            [22, -22],
            [-12, -41],
            [33, -15],
            [23, -44],
            [-27, -33],
            [-16, -81],
            [25, -48],
            [7, -44],
            [60, -44],
            [49, -5],
            [10, 18],
            [31, 5],
            [45, 16],
            [31, 25],
            [55, -8],
            [23, 4],
        ],
        [
            [2436, 5459],
            [54, -8],
            [9, 19],
            [-17, 19],
            [10, 28],
            [39, -9],
            [47, 10],
            [56, -20],
        ],
        [
            [2634, 5498],
            [43, -20],
            [30, 26],
            [22, -4],
            [13, -27],
            [47, 7],
            [38, 36],
            [30, 70],
            [58, 87],
            [33, 4],
            [24, -52],
            [55, -166],
            [53, -16],
            [2, -65],
            [-73, -78],
            [30, -29],
            [173, -15],
            [4, -95],
            [74, 62],
            [123, -34],
            [163, -58],
            [48, -55],
            [-16, -53],
            [114, 29],
            [190, -50],
            [147, 4],
            [145, -79],
            [125, -106],
            [75, -27],
            [84, -4],
            [35, -30],
            [34, -121],
            [16, -58],
            [-39, -156],
            [-50, -62],
            [-138, -132],
            [-62, -108],
            [-73, -82],
            [-24, -2],
            [-28, -70],
            [7, -178],
            [-27, -146],
            [-10, -62],
            [-31, -38],
            [-18, -127],
            [-99, -124],
            [-17, -98],
            [-79, -41],
            [-23, -57],
            [-106, 0],
            [-155, -36],
            [-69, -42],
            [-109, -28],
            [-116, -76],
            [-83, -94],
            [-14, -71],
            [17, -52],
            [-19, -96],
            [-22, -46],
            [-68, -52],
            [-109, -167],
            [-86, -76],
            [-67, -44],
            [-45, -90],
            [-64, -55],
        ],
        [
            [2747, 2073],
            [-28, 54],
            [44, 45],
            [-57, 65],
            [-77, 52],
            [-101, 61],
            [-36, -3],
            [-98, 74],
            [-64, -11],
        ],
        [
            [2330, 2410],
            [131, 130],
            [110, 92],
            [66, 39],
            [83, 52],
            [2, 76],
            [-49, 55],
            [-49, -19],
        ],
        [
            [2624, 2835],
            [19, 55],
            [14, 56],
            [0, 52],
            [-36, 18],
            [-36, -16],
            [-37, 5],
            [-11, 36],
            [-10, 87],
            [-18, 28],
            [-66, 26],
            [-40, -18],
            [-103, 18],
            [6, 129],
            [-29, 52],
        ],
        [
            [1416, 68],
            [-59, 2],
            [-105, 0],
            [0, 212],
        ],
        [
            [1252, 282],
            [37, -44],
            [49, -71],
            [128, -57],
            [137, -23],
            [-44, -48],
            [-93, -5],
            [-50, 34],
        ],
        [
            [1834, 3167],
            [181, -155],
            [80, -15],
            [119, -70],
            [101, -37],
            [14, -42],
            [-96, -144],
            [99, -26],
            [110, -14],
            [77, 15],
            [89, 73],
            [16, 83],
        ],
        [
            [2330, 2410],
            [-24, -75],
            [-27, -98],
            [1, -95],
            [-21, -21],
            [-7, -61],
        ],
        [
            [2252, 2060],
            [-7, -50],
            [124, -81],
            [-13, -65],
            [61, -42],
            [-5, -46],
            [-94, -122],
            [-145, -51],
            [-197, -19],
            [-107, 9],
            [20, -57],
            [-20, -71],
            [18, -47],
            [-59, -34],
            [-100, -13],
            [-94, 35],
            [-38, -25],
            [14, -95],
            [66, -28],
            [54, 30],
            [29, -49],
            [-90, -30],
            [-79, -59],
            [-15, -95],
            [-23, -51],
            [-92, -1],
            [-77, -48],
            [-28, -71],
            [96, -70],
            [94, -19],
            [-34, -85],
            [-116, -54],
            [-63, -111],
            [-90, -37],
            [-40, -45],
            [32, -98],
            [65, -55],
            [-41, 4],
        ],
        [
            [1258, 314],
            [-91, 15],
            [-237, 13],
            [-40, 55],
            [2, 71],
            [-66, -6],
            [-34, 35],
            [-9, 100],
            [75, 42],
            [31, 60],
            [-11, 48],
            [52, 81],
            [36, 126],
            [-11, 56],
            [43, 18],
            [-10, 35],
            [-46, 19],
            [32, 40],
            [-44, 36],
            [-23, 110],
            [40, 19],
            [-17, 116],
            [23, 97],
            [26, 85],
            [59, 34],
            [-30, 93],
            [0, 87],
            [74, 62],
            [-2, 79],
            [56, 93],
            [0, 87],
            [-25, 18],
            [-46, 163],
            [61, 98],
            [-9, 92],
            [35, 86],
            [64, 89],
            [69, 59],
            [-29, 38],
            [20, 30],
            [-3, 158],
            [107, 47],
            [33, 99],
            [-12, 23],
        ],
        [
            [250, 5410],
            [-13, 29],
            [37, 7],
            [-5, 48],
            [23, 34],
            [49, 6],
            [41, 60],
            [38, 50],
            [-37, 22],
            [19, 56],
            [-22, 86],
            [21, 25],
            [-16, 81],
            [-39, 50],
            [12, 46],
            [32, -7],
            [18, 29],
            [-22, 56],
            [11, 13],
            [51, -3],
            [74, 67],
            [40, 10],
            [1, 31],
            [18, 80],
            [56, 44],
            [62, 2],
            [8, 20],
            [76, -8],
            [77, 48],
            [39, 21],
            [47, 46],
            [35, -6],
            [25, -25],
            [-19, -32],
        ],
        [
            [987, 6396],
            [-63, -16],
            [-24, -47],
            [-38, -27],
            [-29, -36],
            [-12, -67],
            [-27, -56],
            [51, -6],
            [12, -44],
            [22, -21],
            [8, -38],
            [-12, -35],
            [3, -20],
            [24, -8],
            [24, -33],
            [126, 9],
            [57, -12],
            [69, -82],
            [39, 11],
            [71, -5],
            [56, 10],
            [34, -16],
            [-17, -51],
            [-22, -32],
            [-8, -68],
            [20, -63],
            [28, -28],
            [3, -22],
            [-50, -47],
            [36, -21],
            [26, -33],
            [30, -95],
        ],
        [
            [1416, 68],
            [-33, -39],
            [-84, -29],
            [-48, 3],
            [-58, 8],
            [-71, 28],
            [-103, 14],
            [-123, 53],
            [-100, 51],
            [-135, 106],
            [81, -20],
            [137, -63],
            [130, -34],
            [51, 43],
            [32, 65],
            [90, 40],
            [70, -12],
        ],
        [
            [1258, 314],
            [-87, 1],
            [-48, -23],
            [-88, -35],
            [-16, -88],
            [-41, -2],
            [-111, 30],
            [-112, 67],
            [0, 0],
            [-122, 54],
            [-30, 60],
            [27, 56],
            [-49, 63],
            [-13, 162],
            [42, 91],
            [104, 73],
            [-149, 28],
            [93, 84],
            [34, 157],
            [108, -33],
            [52, 197],
            [-66, 25],
            [-31, -119],
            [-62, 14],
            [31, 135],
            [34, 176],
            [45, 65],
            [-29, 93],
            [-8, 107],
            [42, 3],
            [60, 153],
            [67, 152],
            [42, 141],
            [-23, 143],
            [30, 78],
            [-12, 117],
            [57, 116],
            [18, 184],
            [31, 197],
            [31, 212],
            [-7, 155],
            [-21, 134],
        ],
        [
            [2026, 5772],
            [-67, 72],
            [27, 26],
            [-2, 44],
            [60, 15],
            [25, 18],
            [-34, 35],
            [9, 35],
            [77, 56],
        ],
        [
            [2121, 6073],
            [64, -35],
            [61, -62],
            [3, -49],
            [37, -2],
            [52, -47],
            [39, -33],
        ],
        [
            [2377, 5845],
            [-16, -85],
            [-59, -25],
            [5, -22],
            [-18, -49],
            [43, -69],
            [32, 0],
            [13, -54],
            [59, -82],
        ],
        [
            [2747, 2073],
            [-43, -59],
            [-110, -53],
            [-73, 19],
            [-53, -10],
            [-90, 40],
            [-67, -3],
            [-59, 53],
        ],
        [
            [2377, 5845],
            [117, -19],
            [11, 18],
            [79, 6],
            [105, -25],
            [-51, -82],
            [8, -65],
            [39, -56],
            [-18, -41],
            [-8, -43],
            [-25, -40],
        ],
        [
            [987, 6396],
            [-2, -22],
            [-58, -11],
            [32, -43],
            [-1, -50],
            [-43, -55],
            [37, -75],
            [42, 6],
            [22, 68],
            [-30, 34],
            [-5, 72],
            [122, 38],
            [-14, 45],
            [34, 30],
            [36, -67],
            [68, -1],
            [64, -53],
            [4, -32],
            [88, -1],
            [104, 10],
            [56, -42],
            [75, -12],
            [55, 30],
            [1, 24],
            [122, 5],
            [117, 2],
            [-83, -28],
            [33, -45],
            [79, -7],
            [74, -47],
            [16, -76],
            [51, 2],
            [38, -22],
        ],
    ],
    transform: {
        scale: [0.010207948632597075, 0.01053555243353109],
        translate: [-81.41094255239946, -55.61183],
    },
    objects: {
        countries: {
            type: 'GeometryCollection',
            geometries: [
                {
                    arcs: [[0, 1, 2, 3, 4]],
                    type: 'Polygon',
                    properties: { name: 'Bolivia', country: 'BO' },
                },
                {
                    arcs: [[-1, 5, 6, 7, 8, 9]],
                    type: 'Polygon',
                    properties: { name: 'Peru', country: 'PE' },
                },
                {
                    arcs: [[-8, 10, 11]],
                    type: 'Polygon',
                    properties: { name: 'Ecuador', country: 'EC' },
                },
                {
                    arcs: [[12]],
                    type: 'Polygon',
                    properties: { name: 'Falkland Is.', country: 'FK' },
                },
                {
                    arcs: [[-2, -10, 13, 14, 15, 16, 17, 18, 19, 20]],
                    type: 'Polygon',
                    properties: { name: 'Brazil', country: 'BR' },
                },
                {
                    arcs: [[[21, 22]], [[-4, 23, -20, 24, 25, 26]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Argentina', country: 'AR' },
                },
                {
                    arcs: [[-9, -12, 27, 28, -14]],
                    type: 'Polygon',
                    properties: { name: 'Colombia', country: 'CO' },
                },
                {
                    arcs: [[[-22, 29]], [[-5, -27, 30, -6]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Chile', country: 'CL' },
                },
                {
                    arcs: [[-16, 31, 32, 33]],
                    type: 'Polygon',
                    properties: { name: 'Guyana', country: 'GY' },
                },
                {
                    arcs: [[-3, -21, -24]],
                    type: 'Polygon',
                    properties: { name: 'Paraguay', country: 'PY' },
                },
                {
                    arcs: [[-19, 34, -25]],
                    type: 'Polygon',
                    properties: { name: 'Uruguay', country: 'UY' },
                },
                {
                    arcs: [[-17, -34, 35]],
                    type: 'Polygon',
                    properties: { name: 'Suriname', country: 'SR' },
                },
                {
                    arcs: [[-15, -29, 36, -32]],
                    type: 'Polygon',
                    properties: { name: 'Venezuela', country: 'VE' },
                },
            ],
        },
    },
};

export default data;
