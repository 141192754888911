const data = {
    type: 'Topology',
    arcs: [
        [
            [3503, 3567],
            [93, -69],
            [140, -99],
            [123, 1],
            [48, 0],
            [0, 58],
            [107, 0],
            [22, -50],
            [32, -44],
            [36, -62],
            [21, -74],
            [15, -77],
            [32, -43],
            [51, -42],
            [39, 111],
            [50, 3],
            [43, -56],
            [31, -97],
            [21, -82],
            [37, -81],
            [13, -98],
            [17, -67],
            [48, -43],
            [44, -31],
        ],
        [
            [4566, 2625],
            [24, 4],
            [-24, -124],
            [-11, -101],
            [-4, -189],
            [-6, -69],
            [11, -77],
            [19, -69],
            [12, -109],
            [41, -105],
            [14, -80],
            [24, -70],
            [65, -37],
            [26, -59],
            [54, 39],
            [47, 14],
            [46, 26],
            [38, 24],
            [40, 58],
            [14, 82],
            [5, 118],
            [11, 41],
            [41, 37],
            [65, 33],
            [55, -5],
            [37, 12],
            [15, -30],
            [-2, -68],
            [-33, -84],
            [-15, -85],
            [12, -25],
            [-10, -61],
            [-15, -110],
            [-16, 36],
            [-13, -2],
        ],
        [
            [5133, 1590],
            [-11, -2],
            [-22, -85],
            [-11, 17],
            [-8, -7],
            [1, -21],
        ],
        [
            [5082, 1492],
            [-57, 2],
            [-58, 0],
            [0, -80],
            [-27, 0],
            [22, -47],
            [23, -33],
            [7, -30],
            [10, -9],
            [-2, -48],
            [-79, 0],
            [-29, -115],
            [9, -26],
            [-8, -33],
            [-1, -41],
        ],
        [
            [4892, 1032],
            [-70, 151],
            [-31, 46],
            [-51, 37],
            [-34, -10],
            [-49, -53],
            [-31, -14],
            [-44, 37],
            [-46, 27],
            [-57, 64],
            [-46, 20],
            [-70, 66],
            [-51, 67],
            [-16, 38],
            [-34, 8],
            [-63, 45],
            [-26, 64],
            [-66, 80],
            [-31, 89],
            [-14, 69],
            [20, 13],
            [-6, 41],
            [14, 36],
            [0, 49],
            [-20, 63],
            [-6, 56],
            [-21, 71],
            [-54, 141],
            [-62, 110],
            [-29, 88],
            [-53, 57],
            [-12, 35],
            [10, 87],
            [-32, 33],
            [-36, 68],
            [-15, 98],
            [-33, 12],
            [-36, 74],
            [-29, 69],
            [-2, 44],
            [-33, 106],
            [-22, 108],
            [1, 54],
            [-45, 56],
            [-20, -6],
            [-35, 39],
            [-10, -58],
            [10, -67],
            [6, -106],
            [21, -58],
            [46, -97],
            [10, -33],
            [9, -10],
            [8, -49],
            [11, 2],
            [12, -91],
            [19, -36],
            [13, -50],
            [39, -71],
            [20, -131],
            [19, -62],
            [17, -66],
            [3, -75],
            [30, -4],
            [24, -64],
            [23, -63],
            [-2, -26],
            [-26, -51],
            [-11, 0],
            [-16, 86],
            [-40, 80],
            [-44, 69],
            [-32, 35],
            [2, 104],
            [-9, 76],
            [-30, 44],
            [-42, 62],
            [-8, -18],
            [-15, 37],
            [-38, 34],
            [-37, 82],
            [5, 11],
            [25, -8],
            [23, 52],
            [2, 64],
            [-47, 100],
            [-36, 40],
            [-23, 88],
            [-23, 92],
            [-28, 113],
            [-25, 126],
        ],
        [
            [3361, 3568],
            [70, 11],
            [78, 16],
            [-6, -28],
        ],
        [
            [5795, 2331],
            [-15, -7],
            [-15, 82],
            [-23, 40],
            [13, 90],
            [19, -6],
            [21, -117],
            [0, -82],
        ],
        [
            [5778, 2729],
            [-67, -23],
            [-4, 53],
            [28, 11],
            [41, -4],
            [2, -37],
        ],
        [
            [5828, 2730],
            [-10, -100],
            [-12, 18],
            [1, 74],
            [-27, 56],
            [0, 16],
            [48, -64],
        ],
        [
            [5133, 1590],
            [1, -21],
            [11, 0],
            [-1, -39],
            [-10, -61],
            [6, -21],
            [-7, -51],
            [4, -13],
            [-7, -72],
            [-12, -37],
            [-11, -4],
            [-12, -49],
        ],
        [
            [5095, 1222],
            [-19, 0],
            [5, 159],
            [1, 111],
        ],
        [
            [5504, 2251],
            [53, -10],
            [49, -2],
            [58, -48],
            [24, -51],
            [57, 16],
            [22, -34],
            [52, -87],
            [39, -63],
            [20, 1],
            [36, -28],
            [-4, -40],
            [45, -6],
            [47, -57],
            [-8, -33],
            [-40, -18],
            [-42, -7],
            [-42, 11],
            [-88, -14],
            [41, 79],
            [-25, 36],
            [-40, 10],
            [-21, 40],
            [-14, 81],
            [-35, -6],
            [-58, 38],
            [-18, 30],
            [-80, 21],
            [-22, 28],
            [23, 35],
            [-60, 7],
            [-44, -73],
            [-26, -2],
            [-8, -34],
            [-31, -16],
            [-26, 14],
            [32, 43],
            [14, 51],
            [28, 31],
            [31, 28],
            [46, 13],
            [15, 16],
        ],
        [
            [5461, 142],
            [-33, 31],
            [-12, 29],
            [7, 25],
            [-3, 31],
            [-17, 34],
            [-24, 28],
            [-21, 18],
            [-4, 41],
            [-16, 25],
            [4, -41],
            [-12, -34],
            [-14, 39],
            [-20, 14],
            [-9, 29],
            [1, 43],
            [8, 44],
            [-18, 20],
            [15, 27],
        ],
        [
            [5293, 545],
            [9, 18],
            [40, -37],
            [14, 18],
            [20, -11],
            [10, -29],
            [18, -10],
            [15, 30],
        ],
        [
            [5419, 524],
            [16, -76],
            [23, -57],
            [29, -60],
        ],
        [
            [5487, 331],
            [-24, -13],
            [1, -57],
            [13, -21],
            [-10, -16],
            [3, -26],
            [-5, -28],
            [-4, -28],
        ],
        [
            [6648, 5544],
            [45, -19],
            [57, 4],
            [-30, -58],
            [-23, -9],
            [-78, 60],
            [-16, 47],
            [24, 43],
            [21, -68],
        ],
        [
            [6762, 5904],
            [-29, -2],
            [-80, 44],
            [-57, 66],
            [21, 12],
            [81, -35],
            [63, -59],
            [1, -26],
        ],
        [
            [2969, 5820],
            [-31, -19],
            [-101, 64],
            [-19, 50],
            [-55, 49],
            [-11, 40],
            [-63, 26],
            [-24, 76],
            [5, 33],
            [65, -31],
            [38, -21],
            [58, -15],
            [20, -49],
            [31, -67],
            [61, -58],
            [26, -78],
        ],
        [
            [7111, 6127],
            [-40, -123],
            [40, 47],
            [41, -30],
            [-22, -49],
            [55, -39],
            [28, 35],
            [62, -44],
            [-19, -103],
            [43, 24],
            [7, -74],
            [20, -88],
            [-26, -124],
            [-28, -5],
            [-41, 26],
            [14, 116],
            [-17, 17],
            [-72, -122],
            [-36, 5],
            [43, 66],
            [-59, 35],
            [-66, -9],
            [-119, 4],
            [-10, 42],
            [39, 50],
            [-27, 38],
            [51, 85],
            [64, 225],
            [38, 80],
            [53, 48],
            [29, -6],
            [-12, -38],
            [-33, -89],
        ],
        [
            [2374, 6618],
            [29, -18],
            [59, 11],
            [-18, -160],
            [53, -113],
            [-24, 0],
            [-38, 64],
            [-22, 65],
            [-31, 44],
            [-12, 62],
            [4, 45],
        ],
        [
            [5689, 7744],
            [-24, -74],
            [-27, 12],
            [-16, 42],
            [2, 10],
            [24, 42],
            [25, -3],
            [16, -29],
        ],
        [
            [5527, 7822],
            [-72, -78],
            [-43, 4],
            [-14, 38],
            [46, 65],
            [84, -2],
            [-1, -27],
        ],
        [
            [5326, 8237],
            [12, -62],
            [31, 22],
            [36, -37],
            [67, -48],
            [71, -44],
            [5, -67],
            [46, 11],
            [43, -47],
            [-54, -44],
            [-96, 34],
            [-34, 63],
            [-61, -75],
            [-88, -73],
            [-21, 83],
            [-83, -14],
            [53, 70],
            [8, 111],
            [21, 129],
            [44, -12],
        ],
        [
            [5898, 8448],
            [-69, -7],
            [-15, 69],
            [26, 79],
            [56, 19],
            [48, -39],
            [1, -60],
            [-7, -20],
            [-40, -41],
        ],
        [
            [4682, 8724],
            [-39, -50],
            [-82, 43],
            [-50, -15],
            [-84, 63],
            [54, 44],
            [43, 61],
            [65, -40],
            [37, -26],
            [18, -26],
            [38, -54],
        ],
        [
            [6435, 5345],
            [-41, 80],
            [1, 192],
            [-28, 40],
            [-41, -24],
            [-20, 38],
            [-47, -107],
            [-19, -110],
            [-22, -64],
            [-26, -21],
            [-20, -8],
            [-6, -34],
            [-113, 0],
            [-94, -1],
            [-27, -26],
            [-65, -102],
            [-8, -11],
            [-20, -55],
            [-56, 0],
            [-61, 0],
            [-27, -23],
            [10, -27],
            [5, -43],
            [-1, -15],
            [-80, -70],
            [-64, -22],
            [-71, -75],
            [-16, 0],
            [-20, 22],
            [-7, 20],
            [1, 15],
            [14, 49],
            [28, 78],
            [18, 83],
            [-12, 123],
            [-13, 127],
            [-64, 67],
            [7, 25],
            [-9, 17],
            [-16, 0],
            [-13, 22],
            [-3, 34],
            [-12, -15],
            [-16, 4],
            [3, 14],
            [-14, 14],
            [-6, 37],
            [-48, 45],
            [-50, 47],
            [-60, 55],
            [-57, 51],
            [-55, -40],
            [-20, -1],
            [-76, 36],
            [-50, -18],
            [-60, 44],
            [-62, 22],
            [-43, 9],
            [-19, 24],
            [-11, 77],
            [-21, 0],
            [0, -55],
            [-128, 0],
            [-210, 0],
            [-209, 0],
            [-184, 0],
            [-185, 0],
            [-181, 0],
            [-188, 0],
            [-60, 0],
            [-183, 0],
            [-174, 0],
        ],
        [
            [3010, 5889],
            [-8, 1],
            [-120, 138],
            [-43, 61],
            [-112, 58],
            [-34, 125],
            [9, 87],
            [-79, 60],
            [-11, 113],
            [-74, 103],
            [-1, 72],
        ],
        [
            [2537, 6707],
            [0, 0],
            [34, 68],
            [-2, 89],
            [-104, 90],
            [-63, 161],
            [-39, 101],
            [-56, 63],
            [-42, 58],
            [-32, 73],
            [-62, -46],
            [-60, -78],
            [-54, 92],
            [-43, 62],
            [-60, 39],
            [-61, 4],
            [1, 803],
            [0, 523],
            [0, 0],
        ],
        [
            [1894, 8809],
            [115, -34],
            [97, -68],
            [64, -13],
            [54, 59],
            [74, 44],
            [91, -17],
            [93, 62],
            [100, 35],
            [42, -59],
            [46, 33],
            [14, 67],
            [43, -15],
            [103, -127],
            [82, 96],
            [9, -107],
            [75, 23],
            [23, 41],
            [75, -8],
            [94, -59],
            [144, -52],
            [84, -24],
            [61, 9],
            [83, -71],
            [-87, -70],
            [111, -30],
            [166, 16],
            [53, 25],
            [65, -85],
            [67, 72],
            [-63, 60],
            [40, 48],
            [75, 6],
            [49, 14],
            [50, -33],
            [61, -77],
            [69, 11],
            [109, -63],
            [95, 22],
            [90, -3],
            [-7, 88],
            [54, 24],
            [96, -48],
            [-1, -133],
            [39, 112],
            [50, -3],
            [28, 141],
            [-66, 87],
            [-72, 57],
            [5, 156],
            [73, 102],
            [81, -22],
            [62, -63],
            [84, -158],
            [-55, -70],
            [114, -28],
            [0, -144],
            [82, 110],
            [74, -91],
            [-19, -104],
            [60, -95],
            [64, 102],
            [45, 121],
            [3, 155],
            [88, -11],
            [91, -21],
            [82, -70],
            [4, -70],
            [-46, -75],
            [43, -75],
            [-7, -69],
            [-121, -98],
            [-85, -22],
            [-64, 42],
            [-18, -70],
            [-60, -119],
            [-17, -62],
            [-72, -95],
            [-88, -10],
            [-48, -59],
            [-4, -92],
            [-72, -17],
            [-75, -115],
            [-67, -158],
            [-24, -112],
            [-3, -163],
            [90, -24],
            [28, -132],
            [29, -107],
            [86, 28],
            [114, -61],
            [61, -53],
            [44, -67],
            [78, -39],
            [65, -59],
            [101, -8],
            [67, -14],
            [-10, -122],
            [19, -142],
            [45, -158],
            [91, -133],
            [48, 46],
            [33, 144],
            [-32, 223],
            [-44, 74],
            [99, 66],
            [69, 99],
            [34, 98],
            [-5, 95],
            [-41, 119],
            [-75, 106],
            [73, 148],
            [-27, 128],
            [-21, 220],
            [43, 32],
            [105, -38],
            [64, -14],
            [51, 37],
            [57, -47],
            [76, -82],
            [18, -55],
            [110, -10],
            [-2, -119],
            [20, -178],
            [57, -22],
            [44, -83],
            [89, 78],
            [59, 156],
            [41, 65],
            [48, -126],
            [80, -179],
            [68, -170],
            [-25, -88],
            [82, -80],
            [55, -80],
            [98, -37],
            [39, -45],
            [25, -119],
            [48, -19],
            [24, -53],
            [5, -158],
            [-45, -53],
            [-44, -50],
            [-101, -50],
            [-78, -115],
            [-103, -23],
            [-132, 29],
            [-92, 1],
            [-64, -9],
            [-51, -102],
            [-79, -62],
            [-88, -187],
            [-71, -130],
            [52, 23],
            [99, 186],
            [129, 117],
            [92, 14],
            [54, -69],
            [-58, -95],
            [19, -152],
            [21, -106],
            [79, -70],
            [102, 20],
            [62, 159],
            [4, -103],
            [40, -51],
            [-76, -92],
            [-137, -84],
            [-61, -57],
            [-68, -102],
            [-47, 11],
            [-2, 119],
            [106, 117],
            [-98, -5],
            [-68, -17],
        ],
        [
            [3543, 9290],
            [-31, -67],
            [137, 43],
            [86, -71],
            [69, 72],
            [56, -46],
            [51, -139],
            [31, 59],
            [-44, 144],
            [54, 21],
            [61, -23],
            [69, -57],
            [39, -137],
            [19, -99],
            [103, -70],
            [111, -67],
            [-7, -62],
            [-101, -11],
            [39, -55],
            [-20, -51],
            [-112, 22],
            [-105, 38],
            [-72, -9],
            [-115, -47],
            [-156, -22],
            [-109, -13],
            [-34, 67],
            [-84, 38],
            [-54, -16],
            [-76, 112],
            [41, 15],
            [95, 24],
            [86, -6],
            [81, 24],
            [-119, 33],
            [-132, -11],
            [-87, 3],
            [-32, 52],
            [142, 56],
            [-94, -2],
            [-108, 37],
            [52, 106],
            [43, 56],
            [164, 86],
            [63, -27],
        ],
        [
            [4137, 9332],
            [-54, -93],
            [-96, 98],
            [21, 20],
            [83, 6],
            [46, -31],
        ],
        [
            [5869, 9287],
            [5, -39],
            [-65, 4],
            [-66, 3],
            [-68, -19],
            [-17, 9],
            [-68, 74],
            [3, 51],
            [29, 10],
            [141, -16],
            [106, -77],
        ],
        [
            [5240, 9295],
            [49, -88],
            [57, 113],
            [155, 58],
            [106, -146],
            [-9, -92],
            [121, 41],
            [59, 56],
            [136, -71],
            [85, -67],
            [8, -62],
            [114, 32],
            [64, -90],
            [148, -55],
            [54, -57],
            [58, -132],
            [-113, -66],
            [145, -92],
            [98, -31],
            [88, -129],
            [97, -10],
            [-19, -99],
            [-108, -163],
            [-76, 60],
            [-97, 135],
            [-79, -17],
            [-8, -81],
            [65, -82],
            [83, -65],
            [25, -37],
            [40, -140],
            [-21, -101],
            [-77, 38],
            [-155, 113],
            [87, -122],
            [64, -85],
            [10, -49],
            [-166, 56],
            [-132, 82],
            [-75, 69],
            [22, 40],
            [-92, 72],
            [-90, 68],
            [1, -40],
            [-177, -23],
            [-52, 49],
            [40, 103],
            [116, 3],
            [126, 18],
            [-20, 50],
            [21, 70],
            [80, 138],
            [-17, 62],
            [-24, 48],
            [-94, 69],
            [-125, 48],
            [40, 35],
            [-65, 88],
            [-55, 8],
            [-48, 48],
            [-33, -42],
            [-111, -18],
            [-224, 32],
            [-130, 41],
            [-100, 21],
            [-51, 50],
            [64, 64],
            [-87, 1],
            [-20, 143],
            [48, 126],
            [63, 57],
            [159, 38],
            [-46, -91],
        ],
        [
            [4392, 9391],
            [74, -29],
            [109, 18],
            [16, -41],
            [-57, -68],
            [93, -61],
            [-11, -126],
            [-101, -55],
            [-59, 12],
            [-43, 54],
            [-153, 108],
            [2, 45],
            [125, -17],
            [-68, 92],
            [73, 68],
        ],
        [
            [4832, 9240],
            [-66, -105],
            [-70, 5],
            [-38, 124],
            [1, 70],
            [32, 60],
            [61, 38],
            [128, -5],
            [118, -34],
            [-92, -125],
            [-74, -28],
        ],
        [
            [3156, 9045],
            [-162, -68],
            [-32, 61],
            [-142, 75],
            [26, 60],
            [43, 103],
            [53, 92],
            [-60, 87],
            [208, 22],
            [88, -30],
            [157, -7],
            [59, -41],
            [66, -60],
            [-77, -35],
            [-151, -99],
            [-76, -99],
            [0, -61],
        ],
        [
            [4807, 9552],
            [-34, -55],
            [-89, 10],
            [-74, 37],
            [32, 64],
            [89, 38],
            [53, -50],
            [23, -44],
        ],
        [
            [4506, 9797],
            [47, -66],
            [2, -72],
            [-28, -105],
            [-101, -14],
            [-66, 22],
            [1, 83],
            [-101, -11],
            [-4, 109],
            [67, -5],
            [92, 48],
            [87, -8],
            [4, 19],
        ],
        [
            [3909, 9724],
            [24, -50],
            [55, 23],
            [65, -6],
            [10, -69],
            [-37, -67],
            [-208, -22],
            [-155, -61],
            [-94, -3],
            [-8, 46],
            [128, 62],
            [-278, -17],
            [-86, 26],
            [84, 137],
            [58, 40],
            [173, -48],
            [109, -83],
            [108, -11],
            [-88, 135],
            [56, 51],
            [63, -16],
            [21, -67],
        ],
        [
            [4741, 9850],
            [68, -45],
            [121, 0],
            [53, -46],
            [-14, -53],
            [71, -32],
            [39, -34],
            [83, -6],
            [90, -12],
            [97, 31],
            [126, 12],
            [99, -10],
            [66, -53],
            [14, -58],
            [-38, -38],
            [-92, -30],
            [-79, 17],
            [-176, -22],
            [-126, -2],
            [-100, 17],
            [-163, 45],
            [-21, 78],
            [-8, 70],
            [-61, 61],
            [-128, 18],
            [-71, 43],
            [23, 58],
            [127, -9],
        ],
        [
            [3418, 9927],
            [-8, -108],
            [-48, -49],
            [-57, -7],
            [-114, -60],
            [-99, -22],
            [-83, 31],
            [0, 0],
            [104, 105],
            [126, 91],
            [95, -1],
            [84, 20],
        ],
        [
            [4793, 9910],
            [-28, -4],
            [-115, 9],
            [-17, 39],
            [124, -2],
            [43, -26],
            [-7, -16],
        ],
        [
            [3788, 9935],
            [-115, -41],
            [-91, 45],
            [50, 45],
            [90, 15],
            [86, -22],
            [-20, -42],
        ],
        [
            [3820, 10062],
            [-75, -27],
            [-102, 0],
            [1, 20],
            [63, 42],
            [33, -6],
            [80, -29],
        ],
        [
            [4670, 9985],
            [-90, -29],
            [-51, 33],
            [-26, 53],
            [-5, 58],
            [80, -5],
            [36, -10],
            [73, -49],
            [-17, -51],
        ],
        [
            [4410, 10023],
            [24, -59],
            [-100, 16],
            [-101, 46],
            [-137, 5],
            [59, 42],
            [-74, 34],
            [-5, 54],
            [121, -19],
            [166, -52],
            [47, -67],
        ],
        [
            [5212, 10211],
            [74, -45],
            [-84, -42],
            [-114, -106],
            [-108, -10],
            [-128, 18],
            [-66, 57],
            [1, 51],
            [49, 38],
            [-113, -1],
            [-68, 47],
            [-39, 63],
            [43, 63],
            [43, 43],
            [63, 10],
            [-27, 32],
            [143, 7],
            [78, -75],
            [104, -30],
            [101, -27],
            [48, -93],
        ],
        [
            [6351, 10697],
            [164, -11],
            [132, -18],
            [113, -38],
            [-3, -38],
            [-150, -61],
            [-149, -29],
            [-55, -31],
            [134, 1],
            [-146, -86],
            [-100, -40],
            [-105, -115],
            [-127, -23],
            [-39, -29],
            [-186, -15],
            [85, -18],
            [-43, -25],
            [51, -70],
            [-58, -48],
            [-95, -40],
            [-30, -55],
            [-85, -42],
            [8, -32],
            [105, 5],
            [2, -34],
            [-165, -85],
            [-160, 39],
            [-181, -22],
            [-91, 18],
            [-117, 7],
            [-7, 68],
            [113, 31],
            [-30, 102],
            [37, 10],
            [165, -61],
            [-84, 91],
            [-100, 27],
            [50, 54],
            [109, 34],
            [18, 49],
            [-87, 55],
            [-26, 73],
            [168, -6],
            [48, -16],
            [96, 52],
            [-138, 16],
            [-215, -9],
            [-109, 48],
            [-51, 57],
            [-72, 41],
            [-14, 48],
            [92, 27],
            [72, 5],
            [120, 23],
            [91, 52],
            [76, -7],
            [66, -40],
            [47, 76],
            [81, 23],
            [110, 15],
            [188, 6],
            [33, -15],
            [178, 24],
            [133, -9],
            [133, -9],
        ],
        [
            [7687, 10630],
            [207, 84],
            [216, -7],
            [78, 52],
            [217, 14],
            [491, -18],
            [385, -111],
            [-114, -55],
            [-235, -6],
            [-331, -14],
            [31, -25],
            [218, 16],
            [185, -49],
            [119, 43],
            [52, -50],
            [-68, -82],
            [157, 52],
            [298, 55],
            [184, -28],
            [35, -60],
            [-251, -100],
            [-34, -32],
            [-197, -25],
            [142, -7],
            [-71, -102],
            [-50, -92],
            [2, -157],
            [74, -92],
            [-96, -6],
            [-101, -44],
            [113, -75],
            [15, -120],
            [-66, -13],
            [80, -121],
            [-137, -10],
            [71, -57],
            [-20, -50],
            [-87, -22],
            [-85, 0],
            [77, -96],
            [1, -63],
            [-122, 59],
            [-32, -38],
            [83, -35],
            [81, -86],
            [23, -114],
            [-109, -27],
            [-48, 54],
            [-76, 81],
            [21, -95],
            [-71, -75],
            [162, -6],
            [85, -7],
            [-165, -123],
            [-167, -111],
            [-180, -49],
            [-68, 0],
            [-64, -55],
            [-85, -148],
            [-133, -99],
            [-42, -6],
            [-82, -35],
            [-88, -33],
            [-53, -87],
            [-1, -99],
            [-31, -92],
            [-100, -113],
            [25, -110],
            [-28, -117],
            [-31, -137],
            [-87, -9],
            [-91, 115],
            [-123, 1],
            [-59, 77],
            [-41, 138],
            [-107, 175],
            [-31, 92],
            [-9, 127],
            [-85, 130],
            [22, 104],
            [-41, 49],
            [61, 165],
            [93, 52],
            [24, 59],
            [13, 111],
            [-70, -50],
            [-34, -21],
            [-55, -20],
            [-76, 46],
            [-4, 95],
            [24, 75],
            [57, 2],
            [126, -37],
            [-106, 89],
            [-55, 49],
            [-61, -20],
            [-51, 35],
            [68, 131],
            [-37, 53],
            [-49, 97],
            [-74, 150],
            [-78, 54],
            [1, 59],
            [-165, 83],
            [-131, 10],
            [-164, -6],
            [-150, -10],
            [-72, 45],
            [-107, 88],
            [162, 45],
            [124, 7],
            [-263, 37],
            [-139, 57],
            [8, 55],
            [233, 68],
            [225, 68],
            [24, 51],
            [-166, 51],
            [54, 56],
            [213, 98],
            [89, 15],
            [-25, 64],
            [145, 37],
            [189, 22],
            [189, 1],
            [67, -44],
            [163, 78],
            [147, -53],
            [86, -11],
            [128, -46],
            [-146, 76],
            [8, 61],
        ],
        [
            [6153, 1761],
            [8, 24],
            [48, 0],
            [36, -37],
            [17, 4],
            [11, -50],
            [33, 3],
            [-1, -42],
            [27, -5],
            [30, -52],
            [-23, -58],
            [-29, 31],
            [-28, -6],
            [-20, 7],
            [-12, -26],
            [-23, -9],
            [-9, 35],
            [-21, -21],
            [-24, -96],
            [-16, 22],
            [-3, 41],
        ],
        [
            [6154, 1526],
            [1, 38],
            [-16, 42],
            [15, 24],
            [5, 54],
            [-6, 77],
        ],
        [
            [5194, 813],
            [-11, 44],
            [-18, 12],
        ],
        [
            [5165, 869],
            [4, 56],
            [-8, 16],
            [-13, 10],
            [-27, -17],
            [-2, 19],
            [-19, 22],
            [-13, 29],
            [-18, 11],
        ],
        [
            [5069, 1015],
            [12, 36],
            [-4, 28],
            [4, 27],
            [29, 39],
            [28, 54],
        ],
        [
            [5138, 1199],
            [6, -5],
            [14, 24],
            [18, 2],
            [5, -11],
            [10, 7],
            [28, -13],
            [29, 4],
            [20, 16],
            [7, 15],
            [19, -7],
            [15, -10],
            [16, 4],
            [12, 12],
            [28, -20],
            [10, -3],
            [19, -26],
            [18, -32],
            [22, -21],
            [16, -39],
        ],
        [
            [5450, 1096],
            [-21, 3],
            [-8, -19],
            [-22, -19],
            [-15, 0],
            [-14, -18],
            [-12, 7],
            [-11, 21],
            [-6, -4],
            [-8, -34],
            [-6, 2],
            [-1, -29],
            [-21, -39],
            [-12, -17],
            [-6, -17],
            [-18, 28],
            [-13, -38],
            [-13, 1],
            [-15, -3],
            [2, -69],
            [-9, -1],
            [-8, -33],
            [-19, -5],
        ],
        [
            [5095, 1222],
            [20, -26],
            [5, 21],
            [18, -18],
        ],
        [
            [5069, 1015],
            [-15, -8],
            [4, -17],
            [-12, -15],
            [-21, -36],
            [-2, -21],
        ],
        [
            [5023, 918],
            [-31, 25],
            [-39, 2],
            [-28, 28],
            [-33, 59],
        ],
        [
            [5793, 1589],
            [42, -13],
            [32, -34],
            [10, -38],
            [-43, -3],
            [-18, -24],
            [-35, 23],
            [-35, 51],
            [7, 33],
            [26, 9],
            [14, -4],
        ],
        [
            [6154, 1526],
            [-41, 24],
            [-29, -10],
            [-38, 10],
            [-29, -26],
            [-32, 44],
            [5, 45],
            [57, -19],
            [46, -12],
            [22, 32],
            [-28, 61],
            [0, 54],
            [-38, 22],
            [14, 39],
            [37, -7],
            [53, -22],
        ],
        [
            [5293, 545],
            [-22, 45],
            [-28, 57],
            [-14, 47],
            [-26, 44],
            [-31, 64],
            [7, 22],
            [10, -21],
            [5, 10],
        ],
        [
            [5450, 1096],
            [-5, -14],
            [-3, -31],
            [6, -52],
            [-14, -48],
            [-7, -56],
            [-2, -62],
            [4, -37],
            [1, -63],
            [-9, -14],
            [-6, -60],
            [4, -38],
            [-12, -36],
            [3, -38],
            [9, -23],
        ],
        [
            [5487, 331],
            [22, -51],
            [-1, -30],
            [25, -6],
            [5, 11],
            [17, -34],
            [31, 10],
            [26, 35],
            [37, 29],
            [21, 42],
            [34, -8],
            [-2, -14],
            [34, -5],
            [27, -24],
            [20, -43],
            [24, -39],
            [-8, -20],
            [14, -83],
            [-11, -42],
            [-20, 10],
            [-8, -69],
            [-20, 41],
            [-14, 76],
            [16, 38],
            [-16, 10],
            [-11, 46],
            [-31, 39],
            [-27, -9],
            [-12, -49],
            [-25, -35],
            [-14, -5],
            [-6, -29],
            [30, -77],
            [-17, -18],
            [-9, -21],
            [-29, -7],
            [-10, 84],
            [-8, -24],
            [-21, 9],
            [-12, 56],
            [-25, 10],
            [-16, 16],
            [-27, 0],
            [-1, -31],
            [-8, 22],
        ],
        [
            [6487, 1592],
            [32, -12],
            [11, -28],
            [-16, -36],
            [-46, 1],
            [-36, -5],
            [-4, 60],
            [9, 21],
            [50, -1],
        ],
        [
            [5165, 869],
            [-7, -33],
            [-36, 2],
            [-22, 13],
            [-25, 28],
            [-35, 9],
            [-17, 30],
        ],
        [
            [6770, 499],
            [36, 18],
            [12, -5],
            [-2, -105],
            [-51, -15],
            [-11, 12],
            [17, 39],
            [-1, 56],
        ],
        [
            [999, 1672],
            [-9, -23],
            [-15, 20],
            [2, 39],
            [-11, 52],
            [3, 15],
            [11, 23],
            [-4, 28],
            [3, 13],
            [5, -3],
            [24, -23],
            [11, -13],
            [10, -18],
            [15, -50],
            [-1, -8],
            [-24, -30],
            [-20, -22],
        ],
        [
            [966, 1892],
            [-21, -10],
            [-10, 30],
            [-7, 11],
            [-1, 9],
            [6, 12],
            [22, -13],
            [16, -22],
            [-5, -17],
        ],
        [
            [924, 1967],
            [-2, -15],
            [-33, 4],
            [5, 17],
            [30, -6],
        ],
        [
            [869, 1988],
            [-3, -8],
            [-4, 1],
            [-22, 5],
            [-8, 32],
            [-2, 6],
            [16, 19],
            [6, -9],
            [17, -46],
        ],
        [
            [765, 2081],
            [-7, -14],
            [-21, 26],
            [3, 10],
            [10, 14],
            [14, -3],
            [1, -33],
        ],
        [
            [3361, 3568],
            [-10, 72],
            [-40, 82],
            [-29, 16],
            [-7, 41],
            [-34, 7],
            [-22, 38],
            [-57, 14],
            [-16, 23],
            [-7, 77],
            [-60, 142],
            [-51, 196],
            [2, 32],
            [-27, 47],
            [-48, 118],
            [-8, 115],
            [-33, 77],
            [13, 117],
            [-2, 121],
            [-19, 108],
            [24, 132],
            [7, 128],
            [8, 128],
            [-11, 189],
            [-20, 121],
            [-18, 65],
            [8, 28],
            [89, -48],
            [32, -133],
            [16, 37],
            [-10, 116],
            [-21, 115],
        ],
        [
            [6435, 5345],
            [10, -46],
            [-65, -69],
            [-64, -48],
            [-64, -42],
            [-33, -84],
            [-10, -31],
            [-1, -75],
            [20, -75],
            [26, -3],
            [-7, 51],
            [19, -31],
            [-5, -41],
            [-42, -22],
            [-29, 2],
            [-46, -24],
            [-26, -7],
            [-36, -7],
            [-51, -41],
            [90, 26],
            [18, -26],
            [-86, -43],
            [-39, 0],
            [2, 18],
            [-19, -40],
            [18, -6],
            [-13, -101],
            [-45, -109],
            [-5, 36],
            [-13, 8],
            [-20, 35],
            [13, -76],
            [15, -25],
            [1, -53],
            [-20, -55],
            [-35, -113],
            [-5, 6],
            [19, 96],
            [-32, 54],
            [-7, 117],
            [-12, -61],
            [14, -90],
            [-41, 23],
            [42, -46],
            [3, -134],
            [17, -10],
            [7, -48],
            [9, -142],
            [-40, -104],
            [-63, -42],
            [-41, -83],
            [-30, -9],
            [-31, -51],
            [-9, -48],
            [-68, -91],
            [-34, -67],
            [-29, -84],
            [-10, -100],
            [11, -98],
            [21, -121],
            [27, -99],
            [0, -61],
            [29, -164],
            [-2, -95],
            [-2, -55],
            [-16, -86],
            [-18, -17],
            [-30, 17],
            [-10, 61],
            [-23, 33],
            [-33, 121],
            [-29, 108],
            [-9, 55],
            [13, 94],
            [-17, 77],
            [-48, 118],
            [-24, 22],
            [-62, -64],
            [-11, 7],
            [-30, 66],
            [-39, 34],
            [-69, -17],
            [-55, 15],
            [-47, -9],
            [-25, -22],
            [11, -38],
            [-1, -57],
            [13, -28],
            [-12, -18],
            [-22, 20],
            [-24, -26],
            [-44, 4],
            [-46, 75],
            [-54, -18],
            [-44, 33],
            [-39, -10],
            [-51, -33],
            [-56, -105],
            [-61, -61],
            [-34, -67],
            [-14, -63],
            [-1, -98],
            [3, -67],
            [12, -48],
            [-24, -4],
        ],
        [
            [1155, 7033],
            [-61, -53],
            [-32, 36],
            [-9, 66],
            [55, 50],
            [33, 22],
            [41, -10],
            [26, -44],
            [-53, -67],
        ],
        [
            [382, 7427],
            [-38, -22],
            [-40, 27],
            [-37, 38],
            [60, 24],
            [49, -13],
            [6, -54],
        ],
        [
            [4, 7973],
            [38, -27],
            [38, 15],
            [50, -37],
            [61, -19],
            [-5, -16],
            [-47, -29],
            [-47, 30],
            [-23, 26],
            [-54, -8],
            [-15, 12],
            [4, 53],
        ],
        [
            [2537, 6707],
            [0, 0],
            [-34, 53],
            [-54, 45],
            [-18, 123],
            [-79, 114],
            [-33, 133],
            [-59, 9],
            [-98, 4],
            [-72, 40],
            [-127, 147],
            [-59, 27],
            [-108, 50],
            [-85, -12],
            [-121, 65],
            [-73, 60],
            [-68, -30],
            [13, -98],
            [-34, -9],
            [-71, -30],
            [-55, -47],
            [-68, -30],
            [-8, 83],
            [27, 138],
            [66, 44],
            [-17, 35],
            [-79, -79],
            [-42, -93],
            [-88, -101],
            [45, -68],
            [-58, -101],
            [-66, -59],
            [-62, -43],
            [-15, -63],
            [-96, -73],
            [-20, -66],
            [-72, -60],
            [-42, 11],
            [-57, -39],
            [-63, -48],
            [-51, -48],
            [-105, -40],
            [-10, 24],
            [67, 66],
            [61, 43],
            [65, 77],
            [76, 16],
            [31, 58],
            [85, 84],
            [14, 29],
            [45, 49],
            [11, 107],
            [31, 84],
            [-71, -43],
            [-20, 24],
            [-33, -51],
            [-40, 71],
            [-17, -50],
            [-23, 70],
            [-61, -56],
            [-38, 0],
            [-5, 84],
            [11, 51],
            [-40, 50],
            [-80, -27],
            [-52, 67],
            [-42, 33],
            [0, 80],
            [-47, 60],
            [23, 81],
            [51, 79],
            [21, 72],
            [50, 11],
            [42, -23],
            [50, 68],
            [45, -12],
            [46, 44],
            [-11, 64],
            [-34, 26],
            [45, 54],
            [-38, -2],
            [-65, -30],
            [-19, -31],
            [-48, 31],
            [-87, -16],
            [-90, 34],
            [-26, 56],
            [-78, 82],
            [87, 59],
            [137, 69],
            [50, 0],
            [-8, -70],
            [130, 5],
            [-50, 87],
            [-76, 54],
            [-43, 71],
            [-59, 60],
            [-85, 44],
            [34, 74],
            [110, 5],
            [77, 64],
            [15, 69],
            [63, 67],
            [60, 16],
            [116, 62],
            [57, -9],
            [94, 75],
            [93, -30],
            [45, -63],
            [27, 27],
            [104, -8],
            [-4, -33],
            [94, -24],
            [63, 14],
            [130, -44],
            [118, -14],
            [47, -18],
            [82, 23],
            [93, -42],
            [67, -20],
            [0, 0],
        ],
    ],
    transform: {
        scale: [0.016264019629320343, 0.007094086002961427],
        translate: [-171.7911106028912, 7.220541490096537],
    },
    objects: {
        countries: {
            type: 'GeometryCollection',
            geometries: [
                {
                    arcs: [[0, 1, 2, 3, 4, 5]],
                    type: 'Polygon',
                    properties: { name: 'Mexico', country: 'MX' },
                },
                {
                    arcs: [[[6]], [[7]], [[8]]],
                    type: 'MultiPolygon',
                    properties: { name: 'Bahamas', country: 'BS' },
                },
                {
                    arcs: [[-3, 9, 10]],
                    type: 'Polygon',
                    properties: { name: 'Belize', country: 'BZ' },
                },
                { arcs: [[11]], type: 'Polygon', properties: { name: 'Cuba', country: 'CU' } },
                {
                    arcs: [[12, 13, 14, 15]],
                    type: 'Polygon',
                    properties: { name: 'Costa Rica', country: 'CR' },
                },
                {
                    arcs: [
                        [[16]],
                        [[17]],
                        [[18]],
                        [[19]],
                        [[20]],
                        [[21]],
                        [[22]],
                        [[23]],
                        [[24]],
                        [[25]],
                        [[26, 27, 28, 29]],
                        [[30]],
                        [[31]],
                        [[32]],
                        [[33]],
                        [[34]],
                        [[35]],
                        [[36]],
                        [[37]],
                        [[38]],
                        [[39]],
                        [[40]],
                        [[41]],
                        [[42]],
                        [[43]],
                        [[44]],
                        [[45]],
                        [[46]],
                        [[47]],
                        [[48]],
                    ],
                    type: 'MultiPolygon',
                    properties: { name: 'Canada', country: 'CA' },
                },
                { arcs: [[49]], type: 'Polygon', properties: { name: 'Greenland', country: 'GL' } },
                {
                    arcs: [[50, 51]],
                    type: 'Polygon',
                    properties: {
                        name: 'Dominican Rep.',
                        name_long: 'Dominican Republic',
                        country: 'DO',
                    },
                },
                {
                    arcs: [[52, 53, 54, 55, 56]],
                    type: 'Polygon',
                    properties: { name: 'Honduras', country: 'HN' },
                },
                {
                    arcs: [[-4, -11, 57, -55, 58, 59]],
                    type: 'Polygon',
                    properties: { name: 'Guatemala', country: 'GT' },
                },
                { arcs: [[60]], type: 'Polygon', properties: { name: 'Jamaica', country: 'JM' } },
                {
                    arcs: [[-52, 61]],
                    type: 'Polygon',
                    properties: { name: 'Haiti', country: 'HT' },
                },
                {
                    arcs: [[-14, 62, -57, 63]],
                    type: 'Polygon',
                    properties: { name: 'Nicaragua', country: 'NI' },
                },
                {
                    arcs: [[-16, 64]],
                    type: 'Polygon',
                    properties: { name: 'Panama', country: 'PA' },
                },
                {
                    arcs: [[65]],
                    type: 'Polygon',
                    properties: { name: 'Puerto Rico', country: 'PR' },
                },
                {
                    arcs: [[-54, 66, -59]],
                    type: 'Polygon',
                    properties: { name: 'El Salvador', country: 'SV' },
                },
                {
                    arcs: [[67]],
                    type: 'Polygon',
                    properties: { name: 'Trinidad and Tobago', country: 'TT' },
                },
                {
                    arcs: [
                        [[68]],
                        [[69]],
                        [[70]],
                        [[71]],
                        [[72]],
                        [[-1, -6, 73, -27, 74]],
                        [[75]],
                        [[76]],
                        [[77]],
                        [[-29, 78]],
                    ],
                    type: 'MultiPolygon',
                    properties: { name: 'United States', country: 'US' },
                },
            ],
        },
    },
};

export default data;
